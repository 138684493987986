import React, { useState, useEffect, useContext } from "react";
import Tab from "react-bootstrap/Tab";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "./JPforyou.css";
import Opportunities from "./JPjobforyouTabContant/JPjobforyouITTab/Opportunities/Opportunities";
import { Col, Nav, Row } from "react-bootstrap";
// import JobsJobFilter from "../../JP-Common-Elements/JP-Job-Filter/Jobs_Job_Filter/JobsJobFilter";
import ITCard from "../../JP-Common-Elements/JP-Cards/JP_Job_Card/JobITCard/ITCard";
import PaginationSection from "../../JP-Common-Elements/JP-Pegination/PaginationSection/PaginationSection";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { useParams } from "react-router-dom";
import JobsJobFilter from "./jpjobsfilter";
const JPforyou = ({
  job_title,
  city_id,
  job_type_id,
  from,
  setJobTitle,
  setCityId,
  setJobTypeId,
  setFrom,
}) => {
  // Array of objects representing Nav items

  const { getData, postData, IMG_URL, signin, usertype, userdata, setUsertype } = useContext(Context);
  const [industryData, setIndustry] = useState([]);

  const [selectedId, setSelectedId] = useState();

  const [max_experience, setMaxExperience] = useState();
  const [min_experience, setMinExperience] = useState();
  const [maxSalary, setMaxSalary] = useState();
  const [minSalary, setMinSalary] = useState();
  const [category, setCategory] = useState();
  const [date, setDate] = useState();

  const { id } = useParams();

  const getIndustry = async () => {
    try {
      const res = await getData("/without-login/master/all-industry");
      if (res?.success) {
        setIndustry(res?.data)
      } else {
        console.log("Error");
      }
    } catch (error) {

    }
  }

  console.log("industryData:--", industryData);

  const handleNavItemClick = (id) => {
    // Do something with the selected id, such as setting it to state
    // alert(id, "icdsbjdbhdbhs")
    setSelectedId(id);
  };

  console.log("job_type_id :-- ", job_type_id);

  useEffect(() => {
    getIndustry();
  }, []);
  return (
    <div>
      <section className="JPforyou">
        <div className="container">
          <div className="heading-holder">
            <div className="row">
              <div className="col-xl-2 col-md-3">
                <div className="BacktoCompanies">
                  <Link to={signin ? "/job-portal/jobseeker/dashboard" : "/job-portal/sign-in"}>
                    <p className="">Back to Dashboard</p>
                  </Link>
                </div>
              </div>
              <div className="col-xl-8 col-md-6">
                <div>
                  <h1 className="jpsect_title">Jobs for You</h1>
                  <p className="jpsect_subtitle ">20+ Recently Added Jobs</p>
                </div>
              </div>
            </div>
          </div>

          {/*JPforyou tab section start */}
          <div className="JPforyou-tabs">

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <div className="row">
                <div className="col-md-12  ">
                  <Nav variant="pills" className="">
                    <Swiper
                      modules={[Navigation, Autoplay]}
                      pagination={false}
                      navigation={false}
                      //  slidesPerView={5}
                      loop={true}
                      breakpoints={{
                        320: {
                          slidesPerView: 2,
                        },
                        768: {
                          slidesPerView: 3,
                        },
                        992: {
                          slidesPerView: 5,
                        },
                        1024: {
                          slidesPerView: 5,
                        },
                        1600: {
                          slidesPerView: 5,
                        },
                      }}
                      className="suportres-slider"
                    >
                      <SwiperSlide className="swiper-slide1">
                        <Nav.Item >
                          <Nav.Link eventKey="first"
                            onClick={() => setSelectedId("")}
                          >
                            All Jobs
                          </Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>

                      {industryData?.map((navItems, index) => (
                        <SwiperSlide className="swiper-slide1">
                          <Nav.Item key={index}>
                            <Nav.Link eventKey={navItems?.name + navItems?.id} onClick={() => handleNavItemClick(navItems?.id)}>
                              {navItems.name}
                            </Nav.Link>
                          </Nav.Item>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Nav>
                </div>
              </div>

              <div className="row tabs-content">
                <div className=" col-xl-9 col-md-8 col-12">

                  <Tab.Content>

                    <Tab.Pane eventKey="first">
                      <div className="jobs_tab">
                        <div>
                          <ITCard
                            job_type_id={job_type_id}
                            city_id={city_id}
                            job_title={job_title}
                            max_experience={max_experience}
                            min_experience={min_experience}
                            maxSalary={maxSalary}
                            minSalary={minSalary}
                            category={category}
                            date={date}
                            from={from}
                          />
                          <Opportunities />
                          {/* <ITCard /> */}
                        </div>

                        <PaginationSection />
                      </div>
                    </Tab.Pane>

                    {industryData?.map((navItems, index) => (
                      <Tab.Pane eventKey={navItems?.name + navItems?.id}>
                        <div className="jobs_tab">
                          <div>
                            <ITCard
                              selectedId={selectedId}
                              job_type_id={job_type_id}
                              city_id={city_id}
                              job_title={job_title}
                              max_experience={max_experience}
                              min_experience={min_experience}
                              maxSalary={maxSalary}
                              minSalary={minSalary}
                              category={category}
                              date={date}
                              from={from}
                            />
                            <Opportunities />
                            {/* <ITCard /> */}
                          </div>
                          <PaginationSection />
                        </div>
                      </Tab.Pane>
                    ))}

                  </Tab.Content>

                </div>

                <div className="col-xl-3 col-md-4 col-12">
                  <div>
                    <JobsJobFilter
                      job_type_id={job_type_id}
                      city_id={city_id}
                      job_title={job_title}

                      setJobTypeId={setJobTypeId}
                      setCityId={setCityId}
                      setJobTitle={setJobTitle}
                      setMaxExperience={setMaxExperience}
                      setMinExperience={setMinExperience}
                      setMaxSalary={setMaxSalary}
                      setMinSalary={setMinSalary}
                      setCategory={setCategory}
                      setDate={setDate}
                      setFrom={setFrom}
                    />
                  </div>
                </div>

              </div>
            </Tab.Container>
          </div>
          {/*JPforyou tab section end */}
        </div>
      </section>
    </div>
  );
};

export default JPforyou;
