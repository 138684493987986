import React, { useState, useContext, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../../../../Jp-Employer/EmpSetting/AccountManagement/EmpAccountManagement.css'
import ResetModal from '../../../../JP-Common-Elements/Jp-modals/ResetModal/ResetModal';
import "react-phone-input-2/lib/style.css";
import { Context } from '../../../../../../utils/context';
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import classNames from "classnames";

const ChangeEmail = ({ toggleBack }) => {

    const [showPassword, setShowPassword] = useState(false);
    const [modalEmail, setModalEmail] = useState(false);
    const [emailerror, setemailErrors] = useState("");

    const { getData, postData, IMG_URL, signin, usertype, userdata, setUserData } = useContext(Context);

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const onEmailChange = async (data) => {
        data.user_id = userdata?.id;
        console.log(data.user_id, "bjhdsvcdschsdch");
        const res = await postData(`/job-portal/job-seeker/setting/change-email/${data.user_id}`, data)
        if (res?.success) {
            setUserData(res?.data?.email)
            setModalEmail(true);
            setTimeout(() => {
                setModalEmail(false);
                toggleBack();
            }, 3000)
        } else {
            setemailErrors(res?.message);
        }
    }

    useEffect(() => {
    }, []);
    return (
        <>
            <Form className="form-field" onSubmit={handleSubmit(onEmailChange)}>
                <div className='Email-sec inner-content'>
                    <Form.Group className="form-div mb-4" controlId="exampleForm.ControlInput2">
                        <Form.Label>New Email</Form.Label>
                        <Form.Control
                            type="email"
                            name="Email"
                            placeholder="Enter Your Email Id"
                            {...register("email", {
                                required: "Email Id required",
                                pattern: {
                                    value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                                    message: "Email Id Invalid",
                                },
                            })}
                            className={classNames("", {
                                "is-invalid": errors?.email,
                            })}
                        />
                        {errors.email && (
                            <span className="text-danger">
                                {errors.email.message}
                            </span>
                        )}
                        {emailerror && (
                            <span className="text text-danger">{emailerror}</span>
                        )}
                    </Form.Group>

                    <Form.Label>Current Password</Form.Label>
                    <InputGroup className="mb-2">
                        <FormControl
                            type={showPassword ? "text" : "password"}
                            name="Password"
                            placeholder="Enter Password"
                            className={classNames("", {
                                "is-invalid": errors?.password,
                            })}
                            {...register("password", {
                                required: "Password is required",
                            })}
                        />
                        <InputGroup.Text onClick={togglePasswordVisibility}>
                            <FontAwesomeIcon className='eye-icon' icon={showPassword ? faEye : faEyeSlash} />
                        </InputGroup.Text>

                    </InputGroup>
                    {errors.password && (
                        <span className="text-danger">
                            {errors.password.message}
                        </span>
                    )}
                    {emailerror && (
                        <span className="text text-danger">{emailerror}</span>
                    )}
                    <div className='buttons-div mt-5'>
                        <button className='cancel-btn' onClick={toggleBack}>Cancel</button>
                        <button type='submit' className='save-btn' >Save</button>
                        {/* <button type='button' className='save-btn' onClick={() => openEmail("/job-portal/employer/setting")}>Save</button> */}
                    </div>

                    <ResetModal show={modalEmail}
                        onHide={() => setModalEmail(false)}
                        text={"Your Email has been Changed Successfully!"}
                    />
                </div>
            </Form>
        </>
    );
}

export default ChangeEmail;
