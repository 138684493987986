import './FBlogConclusion.css'
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Context } from '../../../../../utils/context';

const FBlogConclusion = ({ data }) => {
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();
    return (
        <>
            {data && data?.conclusion?.trim() !== "<p><br></p>" &&
                <section className='blog-conclusion'>
                    <div className='container-fluid'>
                        <div className='row bg-color' >
                            <div className='heading-holder text-center'>
                                <h2>In Conclusion,</h2>
                                <p>{htmlToReactParser.parse(data?.conclusion)}</p>
                            </div>
                        </div>
                    </div>
                </section>
            }

        </>
    )
}

export default FBlogConclusion