import React, { useState, useContext, useEffect } from "react";
import '.././../EmpViewJobDetails/EmpViewJobDetails.css'
import '../../../JP-Search/JP-Job-description/JpJobDescription'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import EmpSmBannerCommon from "../../EmpSmBannerCommon/EmpSmBannerCommon";
import ResetModal from "../../../JP-Common-Elements/Jp-modals/ResetModal/ResetModal";
import { useParams } from "react-router-dom";
import { Context } from "../../../../../utils/context";
import Cookies from "js-cookie";
library.add(fas);

function ReviewJob() {
    const { id } = useParams();
    console.log(id, "id id id id");
    const [modalEmail, setModalEmail] = useState(false);
    const openEmail = (redirectUrl) => {
        Cookies.remove("job_id");
        // setModalEmail(true);

        setTimeout(() => {
            window.location = redirectUrl;
            setModalEmail(false);
        }, 3000)
    }
    const job = {
        location: "Bavdhan, Pune",
    };

    const jobDetails = [
        {
            id: "1",
            title: "Pay",
            description: "₹ 2,00,000 - ₹ 7,00,000 per annum",
            image:
                "/assets/Job-portal/Images/job-search/job-description/money_bill.png",
        },

        {
            id: "2",
            title: "Shift",
            description: "Day, Afternoon",
            image: "/assets/Job-portal/Images/job-search/job-description/clock.png",
        },

        {
            id: "3",
            title: "Experience required:",
            description: "1 - 3 years",
            image:
                "/assets/Job-portal/Images/job-search/job-description/experience.png",
        },

        {
            id: "4",
            title: "Job Location:",
            description: "JBavdhan, Pune",
            image:
                "/assets/Job-portal/Images/job-search/job-description/location.png",
        },

        {
            id: "5",
            title: "Languages:",
            description: "English, Hindi, Marathi",
            image:
                "/assets/Job-portal/Images/job-search/job-description/language.png",
        },

        {
            id: "6",
            title: "Job type",
            description: "Full Time",
            image:
                "/assets/Job-portal/Images/job-search/job-description/briefcase.png",
        },

        {
            id: "7",
            title: "Number of Vacancy",
            description: "1",
            image: "/assets/Job-portal/Images/job-search/job-description/vacancy.png",
        },

        {
            id: "8",
            title: "Skills",
            description:
                "User interface, User experience, Wireframing, Prototyping, Graphics Knowledge",
            image:
                "/assets/Job-portal/Images/job-search/job-description/Secondary_skills.png",
        },

        {
            id: "9",
            title: "Application Deadline Date :",
            description: "20/10/2023",
            image:
                "/assets/Job-portal/Images/job-search/job-description/calendar_check.png",
        },

        {
            id: "10",
            title: "Qualification:",
            description: "Graduated, Certification in Ui Ux design:",
            image:
                "/assets/Job-portal/Images/job-search/job-description/user_graduate.png",
        },
    ];

    const rolesAndResponsibilities = [
        "Expertise in designing websites using Photoshop and Illustrator.",
        "Visualize and design user interface, wireframes, mock-ups, task flows for web applications.",
        "Create graphic assets like icons, buttons, widgets, landing pages for use in user interfaces. ",
        "Stay updated with the latest design trends and tools.",
        "Conceptualize ideas that bring simplicity and delight to the customer.",
        "Maintain standards and processes for producing deliverables.",
        "Gather feedback from teams and follow an iterative process.",
    ];

    const job_id = Cookies.get('job_id');

    const { getData, IMG_URL, postData } = useContext(Context);
    const [data, setData] = useState([]);
    const getDataAll = async () => {
        try {
            const response = await getData(
                `/job-portal/employer/job-post/job/${id}`
            );
            // console.log(response?.data,"response?.data response?.data");
            setData(response?.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data, "step4");
    // console.log(Question, "add question");

    useEffect(() => {
        getDataAll();
        // getDataQuestion();
    }, []);



    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();

    const [updatestatus, setupdatestatus] = useState([]);

    // const postDataAll = async () => {
    //     try {
    //         const paydata = {
    //             status: 1,
    //         };
    //         const response = await postData(`/job-portal/employer/job-post/job/status-update/data/${id}`, paydata);
    //         if (response?.success) {
    //             console.log(response);
    //             // If the job posting is successful, show the modal
    //             setModalEmail(true);
    //         }
    //     } catch (error) {
    //         console.error("Error fetching data:", error);
    //     }
    // };





    return (
        <>
            <section className="JPforyou job-description  emp-edit-details StepForm-Employer">
                <EmpSmBannerCommon />
                <div className="container">
                    <div className="heading-holder">
                        <div className="row">
                            <div className="col-xl-2 col-md-3">
                                <Link to={"/job-portal/employer/dashboard"}>
                                    <div className="BacktoCompanies">
                                        <p className="">Back to Dashboard</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="apply-job-section">
                        <div className="row me-0 ms-0">
                            <div className="col-xl-7 col-md-6 col-7">
                                <div className="job-info">
                                    <div className="text-holder">
                                        <h6>{data?.job_title_id?.label}</h6>
                                        <p>{data?.company_name}</p>
                                        <div className="rating">
                                            <div>
                                                <span className="location m-0">
                                                    <FontAwesomeIcon
                                                        className="location_icon me-2"
                                                        icon="fa-solid fa-location-dot"
                                                    />
                                                    {data?.address}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-5 col-md-6 col-5 text-end ">
                                <div className="edit-details">
                                    {/* <Link to={`/job-portal/employer/editjobdetails/${id}`}><FontAwesomeIcon icon="fa-solid fa-pen" className="me-2" />
                                        <span>Edit Details</span></Link> */}
                                    <Link to={`/job-portal/employer/editjobdetails/${id ? id : job_id}`}>
                                        <FontAwesomeIcon icon="fa-solid fa-pen" className="me-2" />
                                        <span>Edit Details</span>
                                    </Link>
                                    {/* <EditDetails  /> */}
                                </div>
                                <div className="brozen-text">
                                    <p>Bronze Plan</p>
                                </div>
                            </div>



                        </div>
                    </div>

                    {/* apply-job-section section end */}

                    {/* company-job-description section start */}

                    <div className="company-job-description">
                        <div className="row">
                            <div className="col-md-11 col-12">
                                <div className="description">
                                    <div className="heading-holder">
                                        <div className="job-description-icon-holder">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/Job-portal/Images/job-search/job-description/job_description.png"
                                                }
                                                className="description-icon-img"
                                                alt="..."
                                            />
                                        </div>
                                        <h6 className="heading">Job description:</h6>
                                    </div>
                                    <div className="content">
                                        <p>
                                            {htmlToReactParser.parse(data?.job_description)}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* company-job-description section end */}

                    {/* Job details: section start */}

                    <div className="Job-details">
                        <div className="heading">
                            <h6 className="heading">Job details:</h6>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                {/* {jobDetails.slice(0, 5).map((detail, index) => ( */}
                                <div className="job-details-card" >
                                    <div className="row">
                                        <div className="col-xl-1 col-md-1  col-2">
                                            <div className="details-card-icon">
                                                <img
                                                    src={process.env.PUBLIC_URL + "/assets/Job-portal/Images/job-search/job-description/money_bill.png"}
                                                    className="details_img"
                                                    alt="..."
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-11 col-md-11  col-10">
                                            <div className="content-holder">
                                                <h6>Pay</h6>
                                                <p>"₹ {data?.salary_min}- ₹ {data?.salary_max} per annum"</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ))} */}
                            </div>

                            <div className="col-md-6">
                                {/* {jobDetails.slice(5).map((detail, index) => ( */}
                                <div className="job-details-card" >
                                    <div className="row">
                                        <div className="col-xl-1 col-md-1 col-2">
                                            <div className="details-card-icon">
                                                <img
                                                    src={process.env.PUBLIC_URL + "/assets/Job-portal/Images/job-search/job-description/briefcase.png"}
                                                    className="details_img"
                                                    alt="..."
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-11   col-md-11  col-10">
                                            <div className="content-holder">
                                                <h6>Job type</h6>
                                                <p><p>
                                                    {data?.job_type_id?.label}
                                                </p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ))} */}
                            </div>

                        </div>

                        <div className="row">


                            <div className="col-md-6">
                                {/* {jobDetails.slice(5).map((detail, index) => ( */}
                                <div className="job-details-card" >
                                    <div className="row">
                                        <div className="col-xl-1 col-md-1 col-2">
                                            <div className="details-card-icon">
                                                <img
                                                    src={process.env.PUBLIC_URL + "/assets/Job-portal/Images/job-search/job-description/clock.png"}
                                                    className="details_img"
                                                    alt="..."
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-11   col-md-11  col-10">
                                            <div className="content-holder">
                                                <h6>Shift</h6>
                                                <p><p>
                                                    {data?.shift_details
                                                        ?.filter(val => val?.shift !== null)
                                                        .map(val => val?.shift?.name)
                                                        .join(', ')}
                                                </p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ))} */}
                            </div>
                            <div className="col-md-6">
                                {/* {jobDetails.slice(0, 5).map((detail, index) => ( */}
                                <div className="job-details-card" >
                                    <div className="row">
                                        <div className="col-xl-1 col-md-1  col-2">
                                            <div className="details-card-icon">
                                                <img
                                                    src={process.env.PUBLIC_URL + "/assets/Job-portal/Images/job-search/job-description/vacancy.png"}
                                                    className="details_img"
                                                    alt="..."
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-11 col-md-11  col-10">
                                            <div className="content-holder">
                                                <h6>Number of Vacancy</h6>
                                                <p> {data?.number_of_vacancy}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ))} */}
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                {/* {jobDetails.slice(0, 5).map((detail, index) => ( */}
                                <div className="job-details-card" >
                                    <div className="row">
                                        <div className="col-xl-1 col-md-1  col-2">
                                            <div className="details-card-icon">
                                                <img
                                                    src={process.env.PUBLIC_URL + "/assets/Job-portal/Images/job-search/job-description/experience.png"}
                                                    className="details_img"
                                                    alt="..."
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-11 col-md-11  col-10">
                                            <div className="content-holder">
                                                <h6>Experience required</h6>
                                                <p>"{data?.experience_min}-{data?.experience_max} year"</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ))} */}
                            </div>

                            <div className="col-md-6">
                                {/* {jobDetails.slice(5).map((detail, index) => ( */}
                                <div className="job-details-card" >
                                    <div className="row">
                                        <div className="col-xl-1 col-md-1 col-2">
                                            <div className="details-card-icon">
                                                <img
                                                    src={process.env.PUBLIC_URL + "/assets/Job-portal/Images/job-search/job-description/clock.png"}
                                                    className="details_img"
                                                    alt="..."
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-11   col-md-11  col-10">
                                            <div className="content-holder">
                                                <h6>Skills</h6>
                                                <p><p>
                                                    {data?.skills_details
                                                        ?.filter(val => val?.skill !== null)
                                                        .map(val => val?.skill?.name)
                                                        .join(', ')}
                                                </p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ))} */}
                            </div>

                        </div>
                        <div className="row">


                            <div className="col-md-6">
                                {/* {jobDetails.slice(5).map((detail, index) => ( */}
                                <div className="job-details-card" >
                                    <div className="row">
                                        <div className="col-xl-1 col-md-1 col-2">
                                            <div className="details-card-icon">
                                                <img
                                                    src={process.env.PUBLIC_URL + "/assets/Job-portal/Images/job-search/job-description/location.png"}
                                                    className="details_img"
                                                    alt="..."
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-11   col-md-11  col-10">
                                            <div className="content-holder">
                                                <h6>Job Location</h6>
                                                <p><p>
                                                    {data?.address}
                                                </p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ))} */}
                            </div>
                            <div className="col-md-6">
                                {/* {jobDetails.slice(0, 5).map((detail, index) => ( */}
                                <div className="job-details-card" >
                                    <div className="row">
                                        <div className="col-xl-1 col-md-1  col-2">
                                            <div className="details-card-icon">
                                                <img
                                                    src={process.env.PUBLIC_URL + "/assets/Job-portal/Images/job-search/job-description/calendar_check.png"}
                                                    className="details_img"
                                                    alt="..."
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-11 col-md-11  col-10">
                                            <div className="content-holder">
                                                <h6>Application Deadline Date </h6>
                                                <p> {data?.deadline}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ))} */}
                            </div>



                        </div>

                        <div className="row">

                            <div className="col-md-6">
                                {/* {jobDetails.slice(5).map((detail, index) => ( */}
                                <div className="job-details-card" >
                                    <div className="row">
                                        <div className="col-xl-1 col-md-1 col-2">
                                            <div className="details-card-icon">
                                                <img
                                                    src={process.env.PUBLIC_URL + "/assets/Job-portal/Images/job-search/job-description/language.png"}
                                                    className="details_img"
                                                    alt="..."
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-11   col-md-11  col-10">
                                            <div className="content-holder">
                                                <h6>Languages</h6>
                                                <p>
                                                    {data?.language_preference}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ))} */}
                            </div>

                            <div className="col-md-6">
                                {/* {jobDetails.slice(0, 5).map((detail, index) => ( */}
                                <div className="job-details-card" >
                                    <div className="row">
                                        <div className="col-xl-1 col-md-1  col-2">
                                            <div className="details-card-icon">
                                                <img
                                                    src={process.env.PUBLIC_URL + "/assets/Job-portal/Images/job-search/job-description/user_graduate.png"}
                                                    className="details_img"
                                                    alt="..."
                                                />
                                            </div>
                                        </div>
                                        <div className="col-xl-11 col-md-11  col-10">
                                            <div className="content-holder">
                                                <h6>Qualification</h6>
                                                <p>{data?.qualification?.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* ))} */}
                            </div>

                        </div>
                    </div>

                    {/* Job details: section end */}

                    {/* Roles and responsibilities: section  start  */}

                    <div className="company-job-description">
                        <div className="description">
                            <div className="heading-holder">
                                <div className="job-description-icon-holder">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/assets/Job-portal/Images/job-search/job-description/roll_and_responsibility.png"
                                        }
                                        className="description-icon-img"
                                        alt="..."
                                    />
                                </div>
                                <h6 className="heading">Roles and responsibilities:</h6>
                            </div>
                            <div className="content">
                                {/* <ul>
                                    {rolesAndResponsibilities.map((role, index) => (
                                        <li key={index}>
                                            <p>
                                                <FontAwesomeIcon
                                                    className="check-list"
                                                    icon="fa-solid fa-circle-check"
                                                />{" "}
                                                {role}
                                            </p>
                                        </li>
                                    ))}
                                </ul> */}
                                <p>{data?.roles_responsibities}</p>
                            </div>
                        </div>
                    </div>

                    {/* Roles and responsibilities: section  end  */}

                    {/* Contact: section start */}

                    <div className="apply-job-section">
                        <div className="job-info">
                            <div className="text-holder">
                                <h6>Contact:</h6>
                            </div>

                            <div className="contact">
                                <div className="row ">
                                    <div className="col-md-5">
                                        <div className="contact-text-holder">
                                            <p>
                                                {data?.job_portal_recruiter_contact?.contact_company_name}(HR Manager) <br />{data?.company_name}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-2 mb-2">
                                    <div className="col-md-4 col-12">
                                        <div className="contact-text-holder">
                                            <p>Recruiter Contact Number:</p>
                                        </div>
                                    </div>
                                    <div className="col-md-3 col-12">
                                        <div className="contact-info-text">
                                            <span>+91 {data?.job_portal_recruiter_contact?.contact_number}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-md-4 col-12">
                                        <div className="contact-text-holder">
                                            <p>Recruiter Email:</p>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-12">
                                        <div className="contact-info-text">
                                            <Link><span>{data?.job_portal_recruiter_contact?.email}</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="save-btn-div">
                        <Link to={"/job-portal/employer/emp-payment"}>
                            <button className="save-btn" onClick={() => {
                                // openEmail("/job-portal/employer/dashboard");
                                // postDataAll();
                            }}>Post Job</button>
                        </Link>
                    </div>
                    <ResetModal show={modalEmail}
                        onHide={() => setModalEmail(false)}
                        text={"Job has been Posted Successfully!!"}
                    />
                    {/* apply-job-section section end */}
                </div>
            </section>
        </>
    )
}

export default ReviewJob