import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import './FInsuranceReview.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import parse from 'html-react-parser';
import { Pagination } from 'swiper/modules';
import { Container } from "react-bootstrap";
const FInsuranceReview = ({ service_id }) => {
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();

    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/finance/insurance/f-i-testimonial/${service_id}`);
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };



    useEffect(() => {
        getDataAll();
    }, [service_id]);

    return (
        <>
            {data && data?.length > 0 &&
                <section className='F-Insurance-review'>
                    <Container>
                        <Swiper
                            spaceBetween={30}
                            pagination={{
                                clickable: true,
                                dynamicBullets: true,
                            }}
                            modules={[Pagination]}
                            className="mySwiper"
                        >
                            {data?.map((image, index) => (
                                <SwiperSlide>
                                    <div className='banner-slide-div'>
                                        <div className='image-div' >
                                            {/* <img className='banner-img ' src={IMG_URL + image?.image} /> */}
                                        </div>
                                        <div className='banner-content-div'>
                                            <div className='quatation-image'>
                                                <img className="quatation-img" src={process.env.PUBLIC_URL + "/assets/Finance/Image/insurance/quatation.png"}
                                                    alt="Banner"
                                                />
                                            </div>
                                            <h2 className='prop-title mt-2'>Testimonial</h2>
                                            <h3 className='subtitle my-4'>{image?.name}</h3>
                                            <p className='subcontent '>{parse(image?.description) || ""}</p>
                                            {/* <h4 className='nametext'>{image?.person_name}</h4> */}
                                        </div>

                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Container>
                    <div className='overlay-bg'></div>

                </section>
            }
        </>
    )
}

export default FInsuranceReview