import React, { useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import { Context } from "../../../../../../utils/context";
import { allSkills } from "../../../../../../utils/apis/common/Common";
import { getSkills, skillsPost } from "../../../../../../utils/apis/jobseekar/jobseekar";

library.add(fas);

function Steptwo(props) {
    const [selectedOptions, setSelectedOptions] = useState([]);

    const handleSelectChange = (selectedOption) => {
        setSelectedOptions([...selectedOptions, selectedOption]);
    };

    const handleCloseButtonClick = (optionValue) => {
        const updatedOptions = selectedOptions.filter(
            (option) => option.value !== optionValue
        );
        setSelectedOptions(updatedOptions);
    };

    const handleSuggestedSkillClick = (label) => {
        const newOption = { value: label, label: label };
        setSelectedOptions([...selectedOptions, newOption]);
    };


    const { postData, getData, Select2Data, IMG_URL } = useContext(Context);

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        setValue,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm();


    const [skillsData, setSkillsData] = useState([]);
    const getSkillsMaster = async () => {
        const res = await allSkills();
        if (res?.success) {
            setSkillsData(await Select2Data(res?.data, "skill_id"));
        }
    };


    const onSkillsSubmit = async (data) => {
        data.personal_details_id = props?.jobSeekarDetails?.personal_details_id;
        const res = await skillsPost(data);
        if (res?.success) {
            props.nextStep();
        }
    }


    const getsSkills = async () => {
        const res = await getSkills(props?.jobSeekarDetails?.personal_details_id);
        if (res?.success) {
            const skillsDetails = res?.data;
            if (skillsDetails?.length > 0) {
                var skills_id = [];
                skillsDetails?.forEach((skills, index) => {
                    skills_id.push({ value: skills?.skill_id, label: skills?.skill_type?.name });
                    setValue('skill_id', skills_id);
                });
            }
        }
    };


    useEffect(() => {
        getsSkills();
        getSkillsMaster();
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

    const options = [
        { value: " XD", label: " XD" },
        { value: "strawberry", label: "Strawberry" },
        { value: "vanilla", label: "Vanilla" },
    ];



    return (
        <>
            <div className="personal-details-form ">
                {/* form-holder */}
                <Form onSubmit={handleSubmit(onSkillsSubmit)}>
                    <div className="details-form-holder">
                        <div className="form-container">
                            {/*  Tell recruiters */}
                            <div className="">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="Tell_recruiters">
                                            <h1>
                                                Tell recruiters what you know or what you are known for
                                                e.g. Direct Marketing, Oracle, Java etc. We will send you
                                                job recommendations based on these skills. Each skill is
                                                separated by a comma.
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* Skills */}
                            <div className="">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="">
                                            <Form.Group controlId="Skills">
                                                <Form.Label className="">Skills</Form.Label>

                                                {/* <div className="select-option-array">
                                                    {selectedOptions.map((option) => (
                                                        <Button
                                                            key={option.value}
                                                            className="select-option-buttons"
                                                        >
                                                            {option.label}
                                                            <span
                                                                className="select-item-close-btn"
                                                                onClick={() =>
                                                                    handleCloseButtonClick(option.value)
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon="fa-solid fa-xmark" />
                                                            </span>
                                                        </Button>
                                                    ))}
                                                </div> */}
                                                <div>
                                                    <Controller
                                                        name="skill_id"
                                                        {...register("skill_id", {
                                                            required: "Skills is required",
                                                        })}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                isMulti
                                                                styles={{
                                                                    control: (baseStyles) => ({
                                                                        ...baseStyles,
                                                                        borderColor: errors.skill_id ? "red" : baseStyles,
                                                                    }),
                                                                }}
                                                                {...field}
                                                                options={skillsData}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </Form.Group>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* suggested_set_of_skills */}
                            <div className="suggested_set_of_skills ">
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* <Form className="form-field">
                    <Form.Group controlId="Skills">
                      <Form.Label className="">
                        Or you can select from the suggested set of skills
                      </Form.Label>

                      <div className="select-option-array">
                        <Button className="select-option-buttons"  onClick={() => handleSuggestedSkillClick("Spring")}>
                        Spring
                          <span className="select-item-close-btn">
                            <FontAwesomeIcon icon="fa-solid fa-xmark" />
                          </span>
                        </Button>

                        <Button className="select-option-buttons"  onClick={() => handleSuggestedSkillClick("Video Editing")}>
                        Video Editing
                          <span className="select-item-close-btn">
                            <FontAwesomeIcon icon="fa-solid fa-xmark" />
                          </span>
                        </Button>

                        <Button className="select-option-buttons"  onClick={() => handleSuggestedSkillClick("   Swing")}>
                        Swing
                          <span className="select-item-close-btn">
                            <FontAwesomeIcon icon="fa-solid fa-xmark" />
                          </span>
                        </Button>

                        <Button className="select-option-buttons"  onClick={() => handleSuggestedSkillClick(" Data Entry")}>
                        Data Entry
                          <span className="select-item-close-btn">
                            <FontAwesomeIcon icon="fa-solid fa-xmark" />
                          </span>
                        </Button>

                        <Button className="select-option-buttons"  onClick={() => handleSuggestedSkillClick("Kotlin")}>
                        Kotlin
                          <span className="select-item-close-btn">
                            <FontAwesomeIcon icon="fa-solid fa-xmark" />
                          </span>
                        </Button>
                      </div>
                    </Form.Group>
                                        </Form> */}
                                    </div>
                                </div>
                            </div>
                            <div
                                className="SaveAndNext apply-btn-holder"
                                style={{ textAlign: "center" }}
                            >
                                <Button
                                    // onClick={handleSubmit(onSubmit)}
                                    className="apply_now_btn tabs-btn"
                                    style={{ margin: "auto" }}
                                    type="submit"
                                >
                                    Save & Next
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </>
    );
}

export default Steptwo;
