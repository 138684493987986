import React from 'react'
import "./EmpsettingBanner.css"

function EmpsettingBanner() {
    return (
        <>
            <div className='setting-banner'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-10 col-xl-10 col-lg-10 col-12'>
                            <div className='setting-card'>
                                <div className='set-div'>
                                    <div class="company-img-div">
                                        <img class="com-logo" src="/assets/Job-portal/Images/Logo/smalllogo.png" />
                                       
                                    </div>
                                    
                                </div>
                                <h5>Account Settings</h5>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmpsettingBanner