import React from "react";
import "./JPContactForm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import JpContactUsForm from "../JP-Common-Elements/Jp-Forms/JP_contact_us_form/JpContactUsForm";
library.add(fas);

function JPContactForm() {
  return (
    <>
      <div className="JPContactForm">
        <div className="container-fluid p-0">
          {/* laptop view contact form start */}
          <div className="Jp-contact-form-container">
            <div className="bg-img-container">
              <div className="container">
                <div className="row me-0 ms-0">
                  <div className="col-md-12 p-0">
                    <div className="jp-contact-form-bg">
                      <div className="row ">
                        <div className="col-md-7">
                          <img
                            className="contact-bg-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Job-portal/Images/contact/contact_bg_img.png"
                            }
                          />
                        </div>
                      </div>

                      <div className="overlay"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="Jp-contact-form-content">
              <div className="container">

                <div className="row me-0 ms-0">
                  <div className=" col-xl-5 col-md-5">
                    <div className="text-holder">
                      <div className="Jp-contact-heading">
                        <h2>Contact Us for any Support</h2>
                        <h6>No matter what query you have</h6>
                      </div>
                      <div className="contact-info">
                        <p className="get-instant">Get Instant Help</p>
                        <div className="row">
                          <div className="col-md-6">
                            <p>
                              <FontAwesomeIcon
                                className="info-icon"
                                icon="fa-solid fa-phone"
                              />
                              1800-267-8961
                            </p>
                          </div>

                          <div className="col-md-6">
                            <p>
                              {" "}
                              <FontAwesomeIcon
                                className="info-icon"
                                icon="fa-solid fa-envelope"
                              />
                              inquiry@profcyma.com
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-md-7">
                    <div className="jp-contact-form">
                      <JpContactUsForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* laptop view contact form  end*/}

          {/* mobile view contact form start */}
          <div className="mobile-view-form-container">
            <div className="bg-img-container">
              <div className="container">
                <div className="row me-0 ms-0">
                  <div className="col-md-12 p-0">
                    <div className="jp-contact-form-bg">
                      <div className="row me-0 ms-0">
                        <div className="col-md-12 p-0">
                          <img
                            className="contact-bg-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Job-portal/Images/contact/contact_bg_img.png"
                            }
                          />
                        </div>
                      </div>

                      <div className="overlay"></div>

                      <div className="Jp-contact-banner-content">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="text-holder">
                                <div className="Jp-contact-heading">
                                  <h1>Contact Us for any Support</h1>
                                  <h6>No matter what query you have</h6>
                                </div>
                                <div className="contact-info">
                                  <p className="get-instant">
                                    Get Instant Help
                                  </p>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <p>
                                        <FontAwesomeIcon
                                          className="info-icon"
                                          icon="fa-solid fa-phone"
                                        />
                                        1800-267-8961
                                      </p>
                                    </div>

                                    <div className="col-md-6">
                                      <p>
                                        {" "}
                                        <FontAwesomeIcon
                                          className="info-icon"
                                          icon="fa-solid fa-envelope"
                                        />
                                        inquiry@profcyma.com
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="Jp-contact-form-content">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="jp-contact-form">
                      <JpContactUsForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* mobile view contact form end */}
        </div>
      </div>
    </>
  );
}

export default JPContactForm;
