import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import '../topcources/TopCources.css';
import { Swiper, SwiperSlide, } from 'swiper/react';
import { Pagination } from "swiper/modules";
import "swiper/css/pagination"
import "swiper/swiper-bundle.css"
import 'swiper/css';
import { Link } from "react-router-dom";
import { ShimmerPostItem } from "react-shimmer-effects";

const TopCources = () => {
    const TopCourcesDetails = [
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/home/top-course/img1.png",
            headingname: "MBA in Marketing Management",
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/home/top-course/img2.png",
            headingname: "MBA in Human Resource Management",
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/home/top-course/img3.png",
            headingname: "MBA in Operation Management",
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/home/top-course/img4.png",
            headingname: "MBA in Operation Management",
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/home/top-course/img1.png",
            headingname: "MBA in Operation Management",
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/home/top-course/img2.png",
            headingname: "MBA in Operation Management",
        },
    ]

    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getDataAll = async () => {
        try {
            const response = await getData(`/without-login/ed-tech/home/our-popular-courses`);
            setData(response?.data);
            setLoading(false); // Set loading to false after data is fetched
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        // Delay fetching data by 3 seconds
        const timer = setTimeout(() => {
            getDataAll();
        }, 3000);

        return () => clearTimeout(timer); // Clear timeout if the component unmounts
    }, []);

    return (
        <>
            <section className='top-cource'>
                <div className='container'>
                    <div className='row mt-5'>
                        <div className='col-md-12 mt-3 mb-5'>
                            <div className='title-profcyma-help text-center mt-5'>
                                {/* <h5>Top Courses</h5> */}
                                <h2 className='title'>Our Popular Courses</h2>
                                <p>Have a look through our popular Online MBA courses</p>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='mb-5'>
                                <Swiper
                                    modules={[Pagination]}
                                    spaceBetween={15}
                                    loop={true}
                                    onSlideChange={() => console.log('slide change')}
                                    onSwiper={(swiper) => console.log(swiper)}
                                    pagination={{
                                        dynamicBullets: true,
                                    }}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 1,
                                        },
                                        360: {
                                            slidesPerView: 1,
                                        },
                                        467: {
                                            slidesPerView: 2,
                                        },
                                        567: {
                                            slidesPerView: 2,
                                        },
                                        640: {
                                            slidesPerView: 2,
                                        },
                                        768: {
                                            slidesPerView: 3,
                                        },
                                        992: {
                                            slidesPerView: 3,
                                        },
                                        1024: {
                                            slidesPerView: 3,
                                        },
                                        1440: {
                                            slidesPerView: 4,
                                        },
                                        2000: {
                                            slidesPerView: 4,
                                        },
                                        2560: {
                                            slidesPerView: 4,
                                        },
                                        3000: {
                                            slidesPerView: 4,
                                        },
                                    }}
                                >
                                    {loading ? (
                                        Array.from({ length: 4 }).map((_, index) => (
                                            <SwiperSlide key={index}>
                                                <ShimmerPostItem card title/>
                                            </SwiperSlide>
                                        ))
                                    ) : (
                                        data?.map((item, index) => (
                                            <SwiperSlide key={index}>
                                                <Link to={`/ed-tech/specialization/${item?.id}`}>
                                                    <div className='main-img-class mb-5'>
                                                        {item.image && (
                                                            <img
                                                                src={IMG_URL + item.image}
                                                                className="cource-img"
                                                                alt="..."
                                                            />
                                                        )}
                                                        <div className='cource-name'>
                                                            <h5 className='title'>{item.name}</h5>
                                                        </div>
                                                        <div className='black-overlay'></div>
                                                    </div>
                                                </Link>
                                            </SwiperSlide>
                                        ))
                                    )}
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default TopCources;
