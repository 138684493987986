import React from 'react'

import FeaturedJobs from './FeaturedJobs/FeaturedJobs'
import SaveTime from './SaveTime/SaveTime'
import PostBanner from './PostBanner/PostBanner'
import JpHelpCenter from '../../Jp-Help-center/JpHelpCenter'

const PostJob = () => {
    return (
        <>
            <section className='JpPostJob'>
                <PostBanner />
                <SaveTime />
                <FeaturedJobs />
                <JpHelpCenter />

            </section>
        </>
    )
}

export default PostJob