import React from "react";
import Modal from "react-bootstrap/Modal";
import ErrorLottie from "./Animation/ErrorLottie/ErrorLottie";
function Exlamentry(props) {
  return (
    <section>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="Resetmodal"
      >
        <Modal.Body>
          <div>
            <div>
              <ErrorLottie />
            </div>
            <div className="resetpasstxt">
              <p>{props.text}</p>
              <p>Have a Great Time!</p>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default Exlamentry;
