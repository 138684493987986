import React, { useState, useContext, useEffect } from 'react'
import './Jp_employers_posted_jobs.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import JpDashboardbanner from '../JpDashboardbanner/JpDashboardbanner';
import { useNavigate } from "react-router-dom";
import { Context } from '../../../../utils/context';
import { useLocation } from 'react-router-dom';
import Delete_JobModel from '../../JP-Common-Elements/Jp-modals/Delete_JobModel/Delete_JobModel';
// import ResetModal from '../../../JP-Common-Elements/Jp-modals/ResetModal/ResetModal'
import ResetModal from '../../JP-Common-Elements/Jp-modals/ResetModal/ResetModal'



const Jp_employers_posted_jobs = () => {


    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get('query');
    const job_title = queryParams.get('job_title');
    const date = queryParams.get('date');

    console.log(date, job_title, "sdasdfsadsaf");

    const { getData, IMG_URL } = useContext(Context);

    const [jobData, setJobData] = useState();
    const getDashbordJob = async () => {
        try {
            const res = await getData(`/job-portal/search/employer/?jobtitle=${job_title}&date=${date}`)
            if (res?.success) {
                setJobData(res?.data)
            } else {
                console.log("Error");
            }
        } catch (error) {
            console.log(error.message);
        }
    }


    function formatDate(dateString) {
        if (!dateString) return "";
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = String(date.getFullYear()).slice(-2);

        return `${day}-${month}-${year}`;
    }


    const handleViewJobDetails = (jobId) => {
        navigate(`/job-portal/employer/jobdetails/${jobId}`); // Navigate to the specified URL
    };

    const handleEditJobDetails = (jobId) => {
        navigate(`/job-portal/employer/editjobdetails/${jobId}`); // Navigate to the specified URL
    };

    const [modalShow, setModalShow] = React.useState(false);
    const [modalreset, setModalReset] = useState(false);
    const [jobID, setJobID] = useState();
    const deleteJOB = (jobId) => {
        setJobID(jobId);
        setModalShow(true)
    }

    useEffect(() => {
        getDashbordJob();
    }, [job_title, date])
    return (
        <>
            <section className='Jp_employers_posted_jobs_section'>
                <JpDashboardbanner />


                <div className='container mt-5 mb-5'>

                    <div className='row'>
                        <div className='col-md-8 mx-auto'>

                            <div className='title-div  text-center'>
                                <h1 className='jpsect_title '>My Posted Jobs</h1>
                            </div>

                            <div className='mb-4'>
                                <Link className='backtoooo mb-5' to="/job-portal/employer/dashboard">Back To Dashboard</Link>
                            </div>

                            {jobData?.map((data) => (
                                <div className='posted-job-card'>
                                    <div className='row'>
                                        <div className='col-xxl-2 col-lg-2 col-md-3 col-4'>
                                            <div className='img-div'>
                                                <img className='company-img' src={IMG_URL + data?.company_logo} alt="This is Company Logo" />
                                            </div>
                                        </div>

                                        <div className='col-xxl-5 col-lg-5 col-md-7 col-7 my-auto ps-0'>
                                            <p className='compname'>{data?.company?.name}</p>
                                        </div>

                                        <div className='col-xxl-5 col-lg-5 col-md-2 col-1 text-end'>
                                            <div className='icon justify-contend-end'>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                        <FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        {/* <Dropdown.Item href="/job-portal/employer/jobdetails">View Job Details</Dropdown.Item>
                                                        <Dropdown.Item href="/job-portal/employer/editjobdetails">Edit Job Details</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3">Delete job</Dropdown.Item> */}
                                                        <Dropdown.Item onClick={() => handleViewJobDetails(data?.id)}>View Job Details</Dropdown.Item>
                                                        <Dropdown.Item onClick={() => handleEditJobDetails(data?.id)}>Edit Job Details</Dropdown.Item>
                                                        <Dropdown.Item href="#/action-3" onClick={() => deleteJOB(data?.id)}>Delete job</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='positon-name'>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <h5>{data?.job_title?.name}</h5>
                                            </div>
                                            <div className='col-6 text-end'>
                                                <p>Job Posted on {formatDate(data?.updatedAt)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <Delete_JobModel
                    show={modalShow}
                    jobID={jobID}
                    onHide={() => setModalShow(false)}
                    text={"Are you sure, you want to delete job?"}
                    heading={"Delete Job"}
                    setModalShow={setModalShow}
                    setModalReset={setModalReset}
                    imgSource={"/assets/Job-portal/Images/employer/Logout/deleteaward.png"}
                />
                <ResetModal
                    show={modalreset}
                    onHide={() => setModalReset(false)}
                    text={"Job Deleted Successfully"}
                />
            </section>
        </>
    )
}

export default Jp_employers_posted_jobs