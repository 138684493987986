import React, { useState, useContext, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../EmpAccountManagement.css';
import ResetModal from '../../../../JP-Common-Elements/Jp-modals/ResetModal/ResetModal';
import "react-phone-input-2/lib/style.css";
import { Context } from '../../../../../../utils/context';
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';



const ChangePassword = ({ toggleBack }) => {
  
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfNewPassword, setShowConfNewPassword] = useState(false);
  const [modalEmail, setModalEmail] = useState(false);
  const [matcherror, setmatchErrors] = useState("");


  const navigate = useNavigate();

 
  const { getData, postData, IMG_URL, signin, usertype, userdata } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword(!showNewPassword);
  };

  const toggleConfNewPasswordVisibility = () => {
    setShowConfNewPassword(!showConfNewPassword);
  };

  const onPassWordChange = async (data) => {
    console.log(data);
    if (data.new_password === data.confirm_new_password) {
      data.user_id = userdata?.id;
      console.log(data.user_id, "bjhdsvcdschsdch");
      const res = await postData(`/job-portal/employer/setting/change-password/${data.user_id}`, data)
      if (res?.success) {
        setModalEmail(true);
        setTimeout(() => {
          setModalEmail(false);
          toggleBack();
        }, 3000)
      }
    } else {
      setmatchErrors("Password Must Match");
    }
  }

  useEffect(() => {
  }, []);
  return (
    <>
      <Form className="form-field" onSubmit={handleSubmit(onPassWordChange)}>
        <div className='password-sec inner-content form-container'>
          <Form.Label>Current Password</Form.Label>
          <InputGroup className="mb-2">
            <FormControl
              type={showPassword ? 'text' : 'password'}
              placeholder="Password"
              name="password"
              className={classNames("", {
                "is-invalid": errors?.password,
              })}
              {...register("password", {
                required: "Password is required",
              })}
            />
            <InputGroup.Text onClick={togglePasswordVisibility}>
              <FontAwesomeIcon className='eye-icon' icon={showPassword ? faEye : faEyeSlash} />
            </InputGroup.Text>
            {/* <FontAwesomeIcon
                    icon={iconOne}
                    className="StepsixeditIcon"
                    onClick={() => togglePasswordVisibility("password")}
                  /> */}
          </InputGroup>
          {errors.password && (
            <span className="text-danger">
              {errors.password.message}
            </span>
          )}
          <br></br>
          <Form.Label>New Password</Form.Label>
          <InputGroup className="mb-2">
            <FormControl
              type={showNewPassword ? 'text' : 'password'}
              placeholder="Enter New Password"
              name="new_password"
              className={classNames("", {
                "is-invalid": errors?.new_password,
              })}
              {...register("new_password", {
                required: "New Password is required",
              })}
            />
            <InputGroup.Text onClick={toggleNewPasswordVisibility}>
              <FontAwesomeIcon className='eye-icon' icon={showNewPassword ? faEye : faEyeSlash} />
            </InputGroup.Text>
            {/* <FontAwesomeIcon
                    icon={iconOne}
                    className="StepsixeditIcon"
                    onClick={() => togglePasswordVisibility("password")}
                  /> */}
          </InputGroup>
          {errors.new_password && (
            <span className="text-danger">
              {errors.new_password.message}
            </span>
          )}
          <br></br>
          <Form.Label>Confirm New Password</Form.Label>
          <InputGroup className="mb-2">
            <FormControl
              type={showConfNewPassword ? 'text' : 'password'}
              placeholder="Please confirm Password"
              className={classNames("", {
                "is-invalid": errors?.confirm_new_password,
              })}
              {...register("confirm_new_password", {
                required: "Password is required",
              })}
            />
            <InputGroup.Text onClick={toggleConfNewPasswordVisibility}>
              <FontAwesomeIcon className='eye-icon' icon={showConfNewPassword ? faEye : faEyeSlash} />
            </InputGroup.Text>
            {/* <FontAwesomeIcon
                    icon={iconOne}
                    className="StepsixeditIcon"
                    onClick={() => togglePasswordVisibility("confirm_new_password")}
                  /> */}
          </InputGroup>
          {errors.confirm_new_password && (
            <span className="text-danger">
              {errors.confirm_new_password.message}
            </span>
          )}
          {matcherror && (
            <span className="text text-danger">{matcherror}</span>
          )}
          <div className='buttons-div mt-5'>
            <button className='cancel-btn' onClick={toggleBack}>Cancel</button>
            <button type='submit' className='save-btn'>Save</button>
            {/* <button type='button' className='save-btn' onClick={() => navigate("/job-portal/employer/setting")}>Save</button> */}
          </div>
          <ResetModal show={modalEmail}
            onHide={() => setModalEmail(false)}
            text={"Your Password has been Changed Successfully!"}
          />
        </div >
      </Form >
    </>
  );
}

export default ChangePassword;
