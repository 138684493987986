import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import './FProfcymaChoose.css'
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'swiper/css/autoplay';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from "swiper/modules"
const FProfcymaChoose = ({ service_id }) => {
    const iconsData = [
        { imageSrc: "/assets/Finance/Image/insurance/clock.png", text: "Quick and Easy Application" },
        { imageSrc: "/assets/Finance/Image/insurance/speedometer.png", text: "Speedy Approval Process" },
        { imageSrc: "/assets/Finance/Image/insurance/calendar.png", text: "Own it for up to XX months" },
        { imageSrc: "/assets/Finance/Image/insurance/venture.png", text: "Receive funds in just X working days" },
        { imageSrc: "/assets/Finance/Image/insurance/bank.png", text: "No need to visit a branch" },
        { imageSrc: "/assets/Finance/Image/insurance/award.png", text: "An ISO-certified company" }
    ];

    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/finance/insurance/f-i-choose-profcyma/${service_id}`);
            setData(response?.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getDataAll();
    }, [service_id]);


    return (
        <>
            {data && data?.length > 0 &&
                <section className='f-profcymachoose'>
                    <div className='container'>
                        <div className='row'>
                            <div className='hedaing-holder'>
                                <h2>Choose Profcyma Finance</h2>
                            </div>
                            {data?.map((icon, index) => (
                                <div key={index} className='col-md-2'>
                                    <div className='card'>
                                        <div className='image-icon'>
                                            <img className="fban-img" src={IMG_URL + icon?.image} alt="Banner" />
                                        </div>
                                        <div className='text-holder'>
                                            <p>{icon.name}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            }
        </>
    )
}

export default FProfcymaChoose