import React from 'react'
import '../../Jp-Privacy/JpPrivacy.css'
import { Link } from 'react-router-dom'
function JpEmpTerms() {
    return (
        <>
            <div className='privacy-policy-section'>
                <div className='privacy-banner'>
                    <h1>Privacy Policy</h1>
                </div>
                <div className='container'>
                    <div className='privacy-content'>
                        <div className='heading'>
                            <h5>Terms & Conditions</h5>
                        </div>
                        <div className='main-content'>
                            <p>
                                We respect the privacy of your personal information and, as such, make every effort to ensure your information is protected and remains private. As the owner and operator of loremipsum.io (the "Website") hereafter referred to in this Privacy Policy as "Lorem Ipsum", "us", "our" or "we", we have provided this Privacy Policy to explain how we collect, use, share and protect information about the users of our Website (hereafter referred to as “user”, “you” or "your"). For the purposes of this Agreement, any use of the terms "Lorem Ipsum", "us", "our" or "we" includes Hiclousia, without limitation. We will not use or share your personal information with anyone except as described in this Privacy Policy. This Privacy Policy
                            </p>
                            <p>
                                This Privacy Policy will inform you about the types of personal data we collect, the purposes for which we use the data, the ways in which the data is handled and your rights with regard to your personal data. Furthermore, this Privacy Policy is intended to satisfy the obligation of transparency under the EU General Data Protection Regulation 2016/679 ("GDPR") and the laws implementing GDPR.
                            </p>
                            <p>For the purpose of this Privacy Policy the Data Controller of personal data is Hiclousia and our contact details are set out in the Contact section at the end of this Privacy Policy. Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed.</p>
                            <p>
                                For purposes of this Privacy Policy, "Your Information" or "Personal Data" means information about you, which may be of a confidential or sensitive nature and may include personally identifiable information ("PII") and/or financial information. PII means individually identifiable information that would allow us to determine the actual identity of a specific living person, while sensitive data may include information, comments, content and other information that you voluntarily provide.

                            </p>
                            <p>Lorem Ipsum collects information about you when you use our Website to access our services, and other online products and services (collectively, the “Services”) and through other interactions and communications you have with us. The term Services includes, collectively, various applications, websites, widgets, email notifications and other mediums, or portions of such mediums, through which you have accessed this Privacy Policy.

                            </p>
                            <p>
                                We may change this Privacy Policy from time to time. If we decide to change this Privacy Policy, we will inform you by posting the revised Privacy Policy on the Site. Those changes will go into effect on the "Last updated" date shown at the end of this Privacy Policy. By continuing to use the Site or Services, you consent to the revised Privacy Policy. We encourage you to periodically review the Privacy Policy for the latest info- -rmation on our privacy practices.
                            </p>
                        </div>
                        <div className='bottom-btns text-center'>
                            <Link to="/job-portal/register"><button className='dis-agree-btn'>
                                Not for Now
                            </button></Link>
                            <Link to="/job-portal/register"><button className='agree-btn'>
                                Agree
                            </button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JpEmpTerms