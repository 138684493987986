import React, { useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import './FInsuranceBanner.css';
import { Container } from "react-bootstrap";
import { ShimmerThumbnail } from "react-shimmer-effects";

const FInsuranceBanner = ({ service_id }) => {
    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getDataAll = async () => {
        try {
            const response = await getData(`/finance/insurance/f-i-banner/${service_id}`);
            setData(response?.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {

        getDataAll();

    }, [service_id]);

    return (
        <>
            <section className='F-insurancebanner'>
                {loading ? (
                    <ShimmerThumbnail height={450} rounded />
                ) : (
                    <Swiper
                        spaceBetween={30}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        className="mySwiper"
                    >
                        {data?.map((image, index) => (
                            <SwiperSlide key={index}>
                                <div className='container-fluid p-0'>
                                    <div className="bannermaindiv">
                                        <div className="banimagemain">
                                            <img src={IMG_URL + image.image} className="banimg" />
                                        </div>
                                        <div className="baner_text_div ">
                                            <Container>
                                                {/* <div className="heading_div ">
                                                    <div className='hedaing-holder '>
                                                        <h1>{image?.name}</h1>
                                                        <p className='my-3'>{image?.sub_name}</p>
                                                    </div>
                                                    <div className='button-holder'>
                                                        <button className='btn btn-apply'>Apply Now</button>
                                                    </div>
                                                </div> */}
                                            </Container>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                )}
            </section>
        </>
    );
};

export default FInsuranceBanner;
