import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './FinanceLoanCalci.css';
import FirstTab from './FirstTab/FirstTab';
import SecondTab from './SecondTab/SecondTab';
import ThirdTab from './ThirdTab/ThirdTab';
import ForthTab from './ForthTab/ForthTab';
import FifthTab from './FifthTab/FifthTab';
import SixthTab from './SixthTab/SixthTab';
import SeventhTab from './SeventhTab/SeventhTab';
import EightTab from './EightTab/EightTab';
import NinthTab from './NinthTab/NinthTab';
import { ShimmerTable, ShimmerThumbnail } from 'react-shimmer-effects';
import { Container } from 'react-bootstrap';

const FinanceLoanCalci = () => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadTimer = setTimeout(() => {
            setLoading(false);
        }, 3000);

        return () => clearTimeout(loadTimer);
    }, []);

    return (
        <>
            {loading ? (
                <Container>
                    <Row>
                        <Col xxl={3} xl={3} lg={4} md={12} sm={12}>
                            <ShimmerTable row={5} col={1} />
                        </Col>
                        <Col xxl={9} xl={9} lg={8} md={12} sm={12}>
                            <ShimmerThumbnail height={600} rounded />
                        </Col>
                    </Row>

                </Container>

            ) : (
                <section className='finance-loan-calculator'>
                    <div className='container'>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col xxl={3} xl={3} lg={4} md={12} sm={12}>
                                    <div className='left-tabs'>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="first"> <FontAwesomeIcon icon="fa-solid fa-calculator" className='tab-icon' /> EMI Calculator</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="second"> <FontAwesomeIcon icon="fa-solid fa-house" className='tab-icon' />Home Loan EMI Calculator</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="third"><FontAwesomeIcon icon="fa-solid fa-user" className='tab-icon' /> Personal Loan EMI Calculator</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="forth"><FontAwesomeIcon icon="fa-solid fa-car-side" className='tab-icon' /> Car Loan EMI Calculator</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="fifth"><FontAwesomeIcon icon="fa-solid fa-house-user" className='tab-icon' />Home Loan Eligibility Calculator</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="six"><FontAwesomeIcon icon="fa-solid fa-credit-card" className='tab-icon' /> Credit Card Eligibility Calculator</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="seven"> <FontAwesomeIcon icon="fa-solid fa-calendar-days" className='tab-icon' />Monthly Savings Calculator</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="eight"><FontAwesomeIcon icon="fa-solid fa-percent" className='tab-icon' />RD Interest Calculator</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="nine"><FontAwesomeIcon icon="fa-solid fa-calculator" className='tab-icon' />FD Calculator</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </Col>
                                <Col xxl={9} xl={9} lg={8} md={12} sm={12}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first"><FirstTab /></Tab.Pane>
                                        <Tab.Pane eventKey="second"><SecondTab /></Tab.Pane>
                                        <Tab.Pane eventKey="third"><ThirdTab /></Tab.Pane>
                                        <Tab.Pane eventKey="forth"><ForthTab /></Tab.Pane>
                                        <Tab.Pane eventKey="fifth"><FifthTab /></Tab.Pane>
                                        <Tab.Pane eventKey="six"><SixthTab /></Tab.Pane>
                                        <Tab.Pane eventKey="seven"><SeventhTab /></Tab.Pane>
                                        <Tab.Pane eventKey="eight"><EightTab /></Tab.Pane>
                                        <Tab.Pane eventKey="nine"><NinthTab /></Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </section>
            )}
        </>
    );
}

export default FinanceLoanCalci;
