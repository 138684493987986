import { useState, useContext, useEffect } from "react";
import { PhoneInput } from "react-international-phone";
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import "react-international-phone/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./StepOneUnlockBestHome.css";
import { Form } from "react-bootstrap";
import { Context } from "../../../../../../../utils/context";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

function StepOneUnlockBestHome({

  contact_no,
  setContact_no,
  insuranceFormDetail,
  setMobileVerify,
  mobileVerify,
  name,
  dob,
  setName,
  setDob,
  contactErr,
  dobErr,
  nameErr

}) {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();
  const [isWhatsAppChecked, setIsWhatsAppChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState(Array(7).fill(null));
  const [phone, setPhone] = useState("");
  const [showJobSeekerRegisterrr, setShowJobSeekerRegisterrr] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [verifyStat, setVerifyStat] = useState(true);
  // const [mobileVerify, setMobileVerify] = useState(false);
  const [otpErr, setOtpErr] = useState("");
  const [contactError, setContactError] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isTimerExpired, setIsTimerExpired] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [startDate, setStartDate] = useState();
  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);


  useEffect(() => {
    if (insuranceFormDetail?.id) {
      setMobileVerify(true);
    }
    reset(insuranceFormDetail);

    console.log("resetting details");
  }, [insuranceFormDetail]);


  console.log(insuranceFormDetail, 'insurance forn detailsssssssssssssssss');

  useEffect(() => {
    const decrementTimer = () => {
      if (seconds > 0) {
        setIsTimerExpired(false);
        setSeconds(seconds - 1);
      } else {
        setIsTimerExpired(true);
      }
    };

    const timerInterval = setInterval(decrementTimer, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [seconds]);

  const handleResendCode = () => {
    setShowOtpInput(true);
  };

  // const {
  //   control,
  //   register,
  //   handleSubmit,
  //   getValues,
  //   setError,
  //   clearErrors,
  //   reset,
  //   formState: { errors },
  // } = useForm();



  const handlePhoneNumberChange = (value) => {
    setMobileVerify(false)
    console.log(value, "valuessssssssssssssss");
    if (value.length === 13) {
      setIsPhoneNumberValid(true);
      setContact_no(value); // Set the contact number using the prop function
      setContactError("");
    } else {
      setIsPhoneNumberValid(false);
      setContactError("Enter a Valid Number");
    }
  };


  const handleOtpChange = (index, value) => {

    if (!isNaN(value) && value !== "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 3) {
        setVerifyStat(true);
        document.getElementById(`otpInput-${index + 1}`).focus();
      } else {
        setVerifyStat(false);
      }
    } else if (value === "") {

      setVerifyStat(true);
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);

      if (index > 0) {
        document.getElementById(`otpInput-${index - 1}`).focus();
      }
    }
  };

  // const verifyOTP = async () => {
  //   if (contact_no) {
  //     const res = await postData(`/without-login/verify-otp/verify-otp`, { contact_no, otp: otp.join("") });
  //     if (res?.success) {
  //       setMobileVerify(true);
  //       setShowOtpInput(false);
  //       setIsPhoneNumberValid(true);
  //       clearErrors();
  //       setSeconds(90);
  //     } else {
  //       setOtpErr(res.message);
  //     }
  //   }
  // }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  // const handleGetOTP = async () => {
  //   if (contact_no) {
  //     const res = await postData(`/without-login/verify-otp/otp-send`, { contact_no });
  //     if (res?.success) {
  //       console.log(res, "otp");
  //       setShowOtpInput(true);
  //       setSeconds(90);
  //       clearErrors();
  //     } else {
  //       // Handle error response here
  //       console.error("Failed to send OTP:", res.message);
  //     }
  //   } else {
  //     // Handle case when contact_no is not available
  //     console.error("Contact number is required");
  //     setContactError("Contact number is required");
  //   }
  // };
  const onSubmit = async (d) => {
    try {
      const res = await postData(`/finance/forms/insurance-form`, d);
      if (res?.success) {
        console.log(res, 'submit');
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <div className="StepOneUnlockBestHome form-style">
        <div className="contact-number">
          <Form onSubmit={handleSubmit(onSubmit)}>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Your Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                name="name"
                value={name}
                onKeyDown={(event) => {
                  if (!/[A-Z-a-z ]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setName(e.target.value)
                }}
              />
              {nameErr && (
                <span className="text text-danger">
                  {nameErr}
                </span>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Date of Birth</Form.Label>
              <div>
                <Form.Control
                  type="date"
                  placeholder="Enter Date Of Birthday"
                  name="dob"
                  value={dob}
                  onChange={(e) => {
                    setDob(e.target.value)
                  }}
                />
                {dobErr && (
                  <span className="text text-danger">
                    {dobErr}
                  </span>
                )}
              </div>
            </Form.Group>

            <Form.Group className="" controlId="formBasicEmail">
              <Form.Label>Mobile Number</Form.Label>
              <div className="input-span-holder">
                <div className="country-coder">
                  <Controller
                    name="contact_no"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput
                        defaultCountry="in"  // Use uppercase for country code if necessary
                        value={contact_no}
                        onChange={(value) => {
                          field.onChange(value);
                          handlePhoneNumberChange(value);
                        }}
                        placeholder="Enter your contact number"  // Placeholder text
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.contact_no ? "red" : baseStyles.borderColor,
                          }),
                        }}
                      // disabled={mobileVerify}
                      />
                    )}
                    rules={{ required: "Mobile Number is required" }}
                  />

                </div>
                {contactErr && (
                  <span className="text text-danger">
                    {contactErr}
                  </span>
                )}
              </div>
            </Form.Group>

          </Form>
        </div>
      </div>
    </>
  );
}

export default StepOneUnlockBestHome;
