import React, { useState, useEffect, useContext } from 'react';
import 'swiper/swiper-bundle.css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import 'swiper/css/autoplay';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from "swiper/modules"
import { Container } from 'react-bootstrap'
import "./OurPartner.css"
import { Context } from "../../../../utils/context";
import AOS from "aos";
import "aos/dist/aos.css";

const OurPartner = () => {


    const allSecurity = [
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/university/dy-patil/Partners/Amdoc.png"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/university/dy-patil/Partners/Amazon.png"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/university/dy-patil/Partners/Accenture.png"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/university/dy-patil/Partners/Airtel.png"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/university/dy-patil/Partners/Cognizant.png"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/university/dy-patil/Partners/Tech.png"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/university/dy-patil/Partners/Prodapt.png"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/university/dy-patil/Partners/Practo.png"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/university/dy-patil/Partners/Paytm.png"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/university/dy-patil/Partners/Ux.png"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/university/dy-patil/Partners/Mphisis.png"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/university/dy-patil/Partners/Federal.png"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/university/dy-patil/Partners/Amdoc.png"
        },
        {

            imageSource: process.env.PUBLIC_URL + "/assets/images/university/dy-patil/Partners/Mphisis.png"
        },
        {

            imageSource: process.env.PUBLIC_URL + "/assets/images/university/dy-patil/Partners/Amdoc.png"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/university/dy-patil/Partners/Prodapt.png"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/university/dy-patil/Partners/Practo.png"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/university/dy-patil/Partners/Paytm.png"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/university/dy-patil/Partners/Ux.png"
        },
        {
            imageSource: process.env.PUBLIC_URL + "/assets/images/university/dy-patil/Partners/Mphisis.png"
        },

    ]
    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);
    const [partnerdata, setPartnerData] = useState([]);
    const [selectedPartnerTypeId, setSelectedPartnerTypeId] = useState(null);

    const getDataAll = async () => {
        try {
            const response = await getData(`/finance/home/f-partner-type`);
            setData(response?.data);
            console.log(data, 'sssddsfsjhsjkfncnsdff')
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const partnergetDataAll = async (partnerTypeId) => {
        try {
            const response = await getData(`/finance/home/f-our-partner`);
            if (response?.success) {
                setPartnerData(response?.data);
            } else {
                console.log("unsuccessful");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleTabSelect = (key) => {
        setSelectedPartnerTypeId(key);
        partnergetDataAll(key);
    };

    useEffect(() => {
        partnergetDataAll();

    }, []);
    const [securityPerPage, setsecurityPerPage] = useState(8);
    const totalSlides = Math.ceil(allSecurity.length / securityPerPage);
    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 576) {
                setsecurityPerPage(4);
            }

            else if (window.innerWidth <= 991) {
                setsecurityPerPage(6);

            }
            else {
                setsecurityPerPage(12);
            }
        };
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        AOS.init({ once: true });
    }, [])
    return (
        <>
            {partnerdata && partnerdata?.length > 0 &&
                <section className='Our-Partner'>
                    <div data-aos="fade-up"
                        data-aos-duration="3000">
                        <Container>
                            <div>
                                <div className='heading'>
                                    <h1 className='fianace-titleeee'>Our Partners</h1>
                                </div>

                                <div className='dy-partner-slider'>
                                    <Swiper
                                        spaceBetween={50}
                                        modules={[Autoplay, Pagination, Navigation]}
                                        pagination={{ clickable: true }}
                                        className='my-swiper'
                                    >
                                        {(() => {
                                            const slides = [];

                                            for (let i = 0; i < totalSlides; i++) {
                                                const start = i * securityPerPage;
                                                const end = (i + 1) * securityPerPage;
                                                const security = allSecurity.slice(start, end);

                                                slides.push(
                                                    <SwiperSlide key={i}>
                                                        <div className='row mb-5'>
                                                            {partnerdata?.map((item) => (
                                                                <div key={item.id} className='col-lg-2 col-md-4 col-sm-4 col-6  mt-4'>

                                                                    <div className='images-div'>
                                                                        <img src={IMG_URL + item.image} className='company-images' />
                                                                    </div>


                                                                </div>
                                                            ))}
                                                        </div>
                                                    </SwiperSlide>
                                                );
                                            }

                                            return slides;
                                        })()}
                                    </Swiper>
                                </div>
                            </div>
                        </Container>
                    </div>
                </section>
            }
        </>
    )
}

export default OurPartner
