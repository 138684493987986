import React, { useState, useEffect, useContext } from "react";
import JPPopularCompanies from './JPPopularCompanies/JPPopularCompanies'
import Jpmakediffrencebanner from '../homepage/Jpmakediffbanner/Jpmakediffrencebanner'
import Jpcompany_banner from './Jp-comp-banner/Jpcompany_banner'
import { useLocation } from 'react-router-dom';
function JPcompanies() {
  const location = useLocation();
  const [job_title, setJobTitle] = useState();

  return (
    <div>
      <Jpcompany_banner
        setJobTitle={setJobTitle}
      />
      <JPPopularCompanies
        job_title={job_title}
      />
    </div>
  )
}

export default JPcompanies