import React, { useEffect, useState, useContext } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import ForgetPass from "../../ForgetPassAnimation/ForgetPass";
import ResetModal from "../../../JP-Common-Elements/Jp-modals/ResetModal/ResetModal";
import { faEye, faEyeSlash, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import { Context } from "../../../../../utils/context";
import { useNavigate } from 'react-router-dom';
import PasswordChecklist from "react-password-checklist";

library.add(fas);



const ResetPass = ({ emailID }) => {

  const [modalShow, setModalShow] = useState(false);
  // const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailerror, setemailErrors] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const { getData, postData, IMG_URL } = useContext(Context);


  const navigate = useNavigate();


  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();



  const onSubmit = async (data) => {
    if (data) {
      if (data.password === data.confirm_password) {
        const res = await postData(`/job-portal/customer-management/users-sing-in/change-password`, data);
        if (res?.success) {
          setModalShow(true);
          setTimeout(() => {
            navigate("/job-portal/sign-in");
            setModalShow(false);
          }, 3000);
        }
        else {
          setemailErrors(res?.message);
        }
      }
      else {
        setError("password", {
          message: "Password Must Match",
        });
      }
    }
  }

  const openModal = () => {
    setModalShow(true);

  };



  const [password, setPassword] = useState("")
  const [passwordAgain, setPasswordAgain] = useState("")

  return (
    <>
      <section className="JpForgetPass form-container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <ForgetPass />
            <div className="card forgetPass">
              <div className="forgetHead">
                <h3 className="heading">Forgot Your Password?</h3>
              </div>

              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-lg-8 mx-auto">
                    <div className="form-field">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="text"
                          name="Email"
                          value={emailID}
                          readOnly
                          placeholder="Enter Your Email Id"
                          {...register("email", {
                            required: "Email Id required",
                            pattern: {
                              value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                              message: "Email Id Invalid",
                            },
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.email,
                          })}
                        />
                        {emailerror && (
                          <span className="text text-danger">{emailerror}</span>
                        )}

                      </Form.Group>
                    </div>

                    <div className="form-field">
                      <Form.Group controlId="Password">
                        <Form.Label className="required">Enter New Password</Form.Label>

                        <div className="password-input-container">
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter Password"
                            name="password"
                            {...register("password", {
                              required: "Password is required",
                              minLength: {
                                value: 8,
                                message: "Password must be at least 8 characters long",
                              },
                              pattern: {
                                // value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])\S.{8,}$/,
                                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w!@#$%^&*()-+=]{8,}$/,
                                message: "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                              }
                            })}
                            onChange={e => setPassword(e.target.value)}
                          />

                          <div
                            className="password-icon-holder"
                            onClick={togglePasswordVisibility}
                          >
                            <FontAwesomeIcon
                              className="password-icon"
                              icon={showPassword ? faEye : faEyeSlash}
                            />
                          </div>
                        </div>
                      </Form.Group>
                      {errors?.password && (
                        <span className="text text-danger">
                          {errors.password.message}
                        </span>
                      )}
                    </div>

                    <div className="form-field">
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label>Confirm New Password</Form.Label>
                        <div className="password-input-container">
                          <Form.Control
                            type={showConfirmPassword ? "text" : "password"}
                            name="Password"
                            placeholder="Enter Confirm Password"
                            className={classNames("", {
                              "is-invalid": errors?.confirm_password,
                            })}
                            {...register("confirm_password", {
                              required: "Password is required",
                            })}
                            onChange={e => setPasswordAgain(e.target.value)}
                          />
                          <div
                            className="password-icon-holder"
                            onClick={toggleConfirmPasswordVisibility}
                          >
                            <FontAwesomeIcon
                              className="password-icon"
                              icon={showConfirmPassword ? faEye : faEyeSlash}
                            />
                          </div>
                        </div>
                      </Form.Group>
                      <PasswordChecklist
                        rules={["minLength", "specialChar", "number", "capital", "match"]}
                        minLength={8}
                        value={password}
                        valueAgain={passwordAgain}
                        onChange={(isValid) => { }}
                      />
                      <div className="text-center">
                        <Button
                          type="submit"
                          className="reset_btn"
                        >
                          Reset Password{" "}
                        </Button>
                      </div>
                      <ResetModal
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        text={
                          "Password has been Reset Successfully! You have been notified on your e-mail id. "
                        }
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPass;
