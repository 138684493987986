import { React, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import { Swiper, SwiperSlide } from "swiper/react";
import { Grid, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import FHomeInsuranceModal from "../../F-Service/F-Insurance/FHomeInsuranceModal/FHomeInsuranceModal";
import ServiceFormModal from "../../F-Service/Service_form/F-Insurance/FHomeInsuranceModal/ServiceFormModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./TopServicesInsurance.css";
import AOS from "aos";
import "aos/dist/aos.css";
import Cookies from "js-cookie";

const TopServicesInsurance = () => {
  const [insuranceModalShow, setInsuranceModalShow] = useState(false);
  const [serviceId, setServiceId] = useState(null);
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [serviceName, setServiceName] = useState("");
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const getDataAll = async () => {
    try {
      const response = await getData(`/finance/service/f-service/2`);
      setData(response?.data.slice(0, response?.data?.length)); // Limit to the first 12 items

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const setService = (id) => {
    Cookies.set("service_id", id, {
      expires: 1,
    });
    Cookies.set("service_type_id", 2, {
      expires: 1,
    });
    // setTypeId(id);
  };

  const handleKnowMoreClick = async (id, name) => {
    await setService(id)
    await setServiceId(id); 
    await setServiceName(name)
    setInsuranceModalShow(true); 
  };

  useEffect(() => {
    getDataAll();
  }, []);

  useEffect(() => {
    AOS.init({ once: true });
  }, [])

  return (
    <>
      {data && data?.length > 0 &&
        <section className="finance-service-insurance">
          <div className="container">
            <p className="sub-heading">Insurance</p>
            <h4 className="heading">Top Services for Insurance</h4>

            <div className="insurance-slider" data-aos="fade-up"
             data-aos-duration="1000">
              <Swiper
                breakpoints={{
                  576: {
                    slidesPerView: 1,
                    grid: {
                      rows: 2,
                      fill: "row",
                    },
                  },
                  768: {
                    slidesPerView: 2,
                    grid: {
                      rows: 2,
                      fill: "row",
                    },
                  },
                  1024: {
                    slidesPerView: 3,
                    grid: {
                      rows: 2,
                      fill: "row",
                    },
                  },
                }}
                pagination={{
                  clickable: true,
                  dynamicBullets: true,
                }}
                modules={[Grid, Pagination]}
                className="mySwiper"
              >
                {data?.map((image, index) => (
                  <SwiperSlide key={index}>
                    <div className="insurance-card">
                      <div className="row">
                        <div className="col-xxl-10 col-9">
                          <h5>{image?.name}</h5>
                          <p>
                            {htmlToReactParser.parse(image?.description)}
                          </p>
                          <button
                            className="know-btn"
                            onClick={() => { handleKnowMoreClick(image?.id, image?.name) }}
                          >
                            Know More
                            <FontAwesomeIcon
                              icon="fa-solid fa-angles-right"
                              className="arrow-icon"
                            />
                          </button>
                        </div>
                        <div className="col-xxl-2 col-3 text-end">
                          <img
                            className="insu-icon"
                            src={IMG_URL + image?.logo}
                            alt="Logo"
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <ServiceFormModal
                show={insuranceModalShow}
                onHide={() => setInsuranceModalShow(false)}
                serviceId={serviceId}
                serviceName={serviceName}
              />
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default TopServicesInsurance;
