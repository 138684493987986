import React, { useContext, useEffect, useState } from 'react';
import JpDashboardbanner from '../../JpDashboardbanner/JpDashboardbanner';
import './EmployerAllPackages.css';
import { Context } from "../../../../../utils/context";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
function EmployerAllPackages() {
    const { id } = useParams();
    // Define an array of packages
    const packages = [
        {
            id: "1",
            name: "Bronze Plan",
            price: "Free",
            features: [
                "1 Job post",
                "20 Applications",
                "Featured job",
                "10 Days validity"
            ],
            btn: "Active Now",
        },
        {
            id: "2",
            name: "Silver Plan",
            price: "₹999",
            features: [
                "4 Job post",
                "5 Applications",
                "Featured job",
                "10 Days validity"
            ],
            btn: "Choose Plan",
        },
        {
            name: "Gold Plan",
            price: "₹1999",
            features: [
                "1 Job post",
                "20 Applications",
                "Featured job",
                "30 Days validity"
            ],
            btn: "Choose Plan",
        },
        {
            name: "Platinum Plan",
            price: "₹3999",
            features: [
                "6 Job post",
                "70 Applications",
                "Featured job",
                "10 Days validity"
            ]
            , btn: "Choose Plan",
        },
        {
            name: "Business Plan",
            price: "₹5999",
            features: [
                "Unlimited",
                "Unlimited",
                "Featured job",
                "30 Days validity"
            ],
            btn: "Choose Plan",
        },

    ];

    const { getData, IMG_URL } = useContext(Context);

    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/job-portal/packages`);
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data);

    useEffect(() => {
        getDataAll();
    }, []);


    return (
        <section className='Emp-all-packages'>
            <JpDashboardbanner />
            <div className='container'>
                <div className='main-all-pac-card'>
                    <div className='heading'>
                        <h5>Choose a Best Suited Plan for You</h5>
                    </div>
                    <div className='row justify-content-center cards-row'>
                        {data?.map((pkg, index) => (
                            <div key={index} className='col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 loop-card'>
                                <div className='all-mini-card'>
                                    <div className='top-pac'>
                                        <h6>{pkg?.name}</h6>
                                        <p>{pkg?.price}</p>
                                    </div>
                                    <div className='bot-pac'>
                                        {/* {pkg.features.map((feature, index) => (
                                            <p key={index}>{feature}</p>
                                        ))} */}
                                        <p>{pkg.job_post} Job post</p>
                                        <p>{pkg.application} application</p>
                                        <p>{pkg.featured_jobs} featured jobs</p>
                                        <p>{pkg.days_validity} days validity</p>
                                    </div>
                                    <div className='plan-btn-div'>
                                        {id &&
                                             <Link to={`/job-portal/employer/emp-payment/${id}`}>
                                                <button className="plan-btn">Choose Plan</button></Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </section>
    );
}

export default EmployerAllPackages;
