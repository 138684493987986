import React from 'react'
import FBlogCard from './F-BlogCard/FBlogCard'
import FBlogBanner from './F-BlogBanner/FBlogBanner'


const FBlog = () => {
  return (
    <>
      <FBlogBanner />
      <FBlogCard />
    </>
  );
}

export default FBlog