import React, { useState, useEffect, useContext } from "react";
import "./JpMainDashboard.css"
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import DashboardTab from './DashboardTab/DashboardTab';
import ManageTab from './ManageTab/ManageTab';
import ApplicantTab from './ApplicantTab/ApplicantTab';
import ShortlishTab from './ShortlishTab/ShortlishTab';
import { Context } from "../../../../utils/context";

function MainDashboard() {

    const { getData} = useContext(Context);  
    const [dashbordData, setDashbordData] = useState();  
    const [manageData, setManageData] = useState();
    const [jobStatusData, setJobStatusData] = useState();
  
    const getDashbord = async () => {
      try {
          const res = await getData("/job-portal/employer/dashboard")
          if (res?.success) {
            setDashbordData(res?.data?.rows)
          } else {
            console.log("Error");
          }
        }  catch (error) {
            console.log(error.message);  
      }
    }
    
    const getManageJobs = async () => {
        try {
            const res = await getData("/job-portal/employer/dashboard/manage-job")
            if (res?.success) {
                setManageData(res?.data?.data?.rows)
            } else {
                console.log("Error");
            }
        }  catch (error) {
            console.log(error.message);  
        }
    }

    const getJobStatus = async () => {
        try {
            const res = await getData("/without-login/master/all-job-status")
            if (res?.success) {
                setJobStatusData(res?.data)
            } else {
                console.log("Error");
            }
        }  catch (error) {
            console.log(error.message);  
        }
    }

    useEffect(() => {
        getDashbord();
        getManageJobs();
        getJobStatus();
      }, []);
    return (
        <>
            <section className='Jp-employer-main-dashboard'>
                <div className='container'>

                    <Tab.Container id="left-tabs-example" defaultActiveKey="dashboard" >

                        <Row>
                            <Nav variant="pills" className="main-tabs">

                                <Nav.Item>
                                    <Nav.Link eventKey="dashboard" >Dashboard</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="manage">Manage Jobs</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="applicants">Applicants</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="shortlist">Shortlisted Resumes</Nav.Link>
                                </Nav.Item>

                            </Nav>

                            <Tab.Content>
                                <Tab.Pane eventKey="dashboard">
                                    <DashboardTab dashbordData={dashbordData}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="manage">
                                    <ManageTab manageData={manageData} jobStatusData={jobStatusData}/>
                                </Tab.Pane>
                                <Tab.Pane eventKey="applicants">
                                    <ApplicantTab />
                                </Tab.Pane>
                                <Tab.Pane eventKey="shortlist">
                                    <ShortlishTab />
                                </Tab.Pane>
                            </Tab.Content>

                        </Row>

                    </Tab.Container>
                </div>

            </section>

        </>
    )
}

export default MainDashboard