import React, { useState, useContext, useEffect } from "react";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import "./JpJobDescription.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import ResetModal from "../../JP-Common-Elements/Jp-modals/ResetModal/ResetModal";
import { Context } from "../../../../utils/context";
import { useParams, useNavigate } from "react-router-dom";
import { saveJobPost } from "../../../../utils/apis/common/Common";
import Cookies from "js-cookie";

library.add(fas);

function JpJobDescription() {

  const { id } = useParams();
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const { getData } = useContext(Context);
  const [jobsDescription, setJobDescription] = useState();
  const [modalShow, setModalShow] = useState(false);


  const profileStatus = Cookies.get("profileStatus");

  console.log(profileStatus, "profileStatusprofileStatusprofileStatusprofileStatus");


  const [jobApplyStatus, setJobApplyStatus] = useState();
  const getJobApplyStatus = async () => {
    const res = await getData(`/job-portal/job-seeker/applied-job/status-update/${id}`);
    if (res?.success) {
      setJobApplyStatus(res?.data?.job_apply_status_id)
    }
  };

  const getJobDetails = async () => {
    try {
      const res = await getData(`/job-portal/jobs/${id}`)
      if (res?.success) {
        setJobDescription(res?.data)
        setJobData([{
          id: "1",
          title: "Pay",
          description: `${res?.data?.salary_min ?? ''} - ${res?.data?.salary_max ?? ''}`,
          image:
            "/assets/Job-portal/Images/job-search/job-description/money_bill.png",
        },

        {
          id: "2",
          title: "Shift",
          description: res?.data?.shift_details?.map(item => item.shift?.name).filter(Boolean).join(', ') ?? '',
          // description: `${res?.data?.shift_details?.shift?.name ?? ''}`,
          image: "/assets/Job-portal/Images/job-search/job-description/clock.png",
        },

        {
          id: "3",
          title: "Experience required:",
          description: `${res?.data?.experience_min ?? ''} - ${res?.data?.experience_max ?? ''}`,
          image:
            "/assets/Job-portal/Images/job-search/job-description/experience.png",
        },

        {
          id: "4",
          title: "Job Location:",
          description: `${res?.data?.address ?? ''} , ${res?.data?.city?.name ?? ''} , ${res?.data?.state?.name ?? ''}`,
          image:
            "/assets/Job-portal/Images/job-search/job-description/location.png",
        },

        {
          id: "5",
          title: "Languages:",
          description: `${res?.data?.language_preference ?? ''}`,
          image:
            "/assets/Job-portal/Images/job-search/job-description/language.png",
        },

        {
          id: "6",
          title: "Job type",
          description: res?.data?.job_type_details?.map(item => item.job_type?.name).filter(Boolean).join(', ') ?? '',
          image:
            "/assets/Job-portal/Images/job-search/job-description/briefcase.png",
        },

        {
          id: "7",
          title: "Number of Vacancy",
          description: `${res?.data?.number_of_vacancy ?? ''}`,
          image: "/assets/Job-portal/Images/job-search/job-description/vacancy.png",
        },

        {
          id: "8",
          title: "Skills",
          description: res?.data?.skills_details?.map(item => item.skill?.name).filter(Boolean).join(', ') ?? '',
          image:
            "/assets/Job-portal/Images/job-search/job-description/Secondary_skills.png",
        },

        {
          id: "9",
          title: "Application Deadline Date :",
          description: `${res?.data?.deadline ?? ''}`,
          image:
            "/assets/Job-portal/Images/job-search/job-description/calendar_check.png",
        },

        {
          id: "10",
          title: "Qualification:",
          description: `${res?.data?.qualification?.name ?? ''}`,
          image:
            "/assets/Job-portal/Images/job-search/job-description/user_graduate.png",
        },
        ]);
      } else {
        console.log("Error");
      }
    }
    catch (error) {

    }
  }
  setTimeout(() => {
    setModalShow(false);
  }, 5000);

  const [jobAlert, setJobAlert] = useState(true); // State variable for job alert status

  const job = {
    location: "Bavdhan, Pune", // Example job location
  };

  const company = {
    rating: 4.5, // Example rating
    reviews: 100, // Example number of reviews
  };

  const [jobDetails, setJobData] = useState([]);


  const rolesAndResponsibilities = [
    "Expertise in designing websites using Photoshop and Illustrator.",
    "Visualize and design user interface, wireframes, mock-ups, task flows for web applications.",
    "Create graphic assets like icons, buttons, widgets, landing pages for use in user interfaces. ",
    "Stay updated with the latest design trends and tools.",
    "Conceptualize ideas that bring simplicity and delight to the customer.",
    "Maintain standards and processes for producing deliverables.",
    "Gather feedback from teams and follow an iterative process.",
  ];

  const companyId = async (company_id) => {
    const data = new FormData();
    data.append("job_id", company_id);
    const res = await saveJobPost(data)
    if (res?.success) {
      setModalShow(true)
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }

  const calculateRemainingDays = (deadlineDate) => {
    const deadline = new Date(deadlineDate);
    const today = new Date();
    const timeDifference = deadline.getTime() - today.getTime();
    const remainingDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
    return remainingDays;
  };

  // const deadline = jobsDescription?.deadline
  const remainingDays = calculateRemainingDays(jobsDescription?.deadline);




  useEffect(() => {
    getJobApplyStatus();
    getJobDetails();
  }, []);
  return (

    <>
      <div class="jobdiscri-banner">
        <h6 class="jbt-ttwjegfi">Job Description</h6>
      </div>


      <section className="JPforyou job-description">
        <div className="container">
          <div className="heading-holder">
            <div className="row">
              <div className="col-xl-2 col-md-3">
                <Link to={"/job-portal/search/all-jobs-search"}>
                  <div className="BacktoCompanies">
                    <p className="">Back to Dashboard</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>

          {/* apply-job-section section start */}
          {/* {jobsDescription?.map((job) => ( */}
          <>
            <div className="apply-job-section">
              <div className="row me-0 ms-0">
                <div className="col-xl-7 col-md-6">
                  <div className="job-info">
                    <div className="text-holder">
                      <h6>{jobsDescription?.job_title?.name}</h6>
                      <p>{jobsDescription?.company?.name}</p>
                      <div className="rating">
                        <div>
                          {/* {[...Array(Math.floor(company.rating))].map((_, i) => (
                          <FontAwesomeIcon
                            key={i}
                            className="star_icon me-1"
                            icon="fa-solid fa-star"
                          />
                        ))}
                        {company.rating % 1 !== 0 && (
                          <FontAwesomeIcon
                            className="star_icon me-1"
                            icon="fa-solid fa-star-half-stroke"
                          />
                        )} */}

                          {/* <span>{company.reviews} reviews</span> */}

                          <span className="location">
                            <FontAwesomeIcon
                              className="location_icon me-2"
                              icon="fa-solid fa-location-dot"
                            />
                            {jobsDescription?.city?.name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-xl-3 col-md-3">
                  <div className="save-and-Apply">
                    <span className="save-btn-holder">
                      <FontAwesomeIcon
                        className="bookmark-icon"
                        icon="fa-solid fa-bookmark"
                        onClick={() => companyId(jobsDescription?.id)}
                      />
                    </span>

                    {jobApplyStatus == 2 ? (
                      <span className="apply-btn-holder">
                        <p>You have applied for this job.</p>
                      </span>
                    ) : (
                      <>
                        {/* {profileStatus == "Done" ? ( */}
                        <Link to={`/job-portal/few-click-away-stepform/${id}`}>
                          <span className="apply-btn-holder">
                            <Button className="apply_now_btn">Apply</Button>
                          </span>
                        </Link>
                        {/* ) :(
                          <Link to={`/job-portal/jobseeker/jobseeker-edit-profile`}>
                          <span className="apply-btn-holder">
                            <Button className="apply_now_btn">Apply</Button>
                          </span>
                        </Link>
                        )} */}
                      </>
                    )}
                  </div>
                </div>

                <div className="col-xl-2 col-md-3 p-0">
                  <div className="job_alert">
                    <div className="text-holder">
                      <div className="job-alert-notification">
                        <div className="switch_box">
                          <p>Job Alerts</p>

                          <div className="switch-alerts ">
                            <div className="row me-0 ms-0">
                              <div className="col-6 p-0">
                                <div
                                  className={`text on ${jobAlert ? "active" : ""}`}
                                  onClick={() => setJobAlert(true)}
                                >
                                  On
                                </div>
                              </div>

                              <div className="col-6 p-0">
                                <div
                                  className={`text off ${!jobAlert ? "active" : ""
                                    }`}
                                  onClick={() => setJobAlert(false)}
                                >
                                  Off
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <p>
                          Status : <span>{jobsDescription?.status == 1 ? ("Open") : ("Close")}</span>
                        </p>
                        <p>
                          Day Remaining: <span>{remainingDays}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ResetModal show={modalShow} onHide={() => setModalShow(false)} />

            {/* apply-job-section section end */}

            {/* company-job-description section start */}

            <div className="company-job-description">
              <div className="row">
                <div className="col-md-11 col-10">
                  <div className="description">
                    <div className="heading-holder">
                      <div className="job-description-icon-holder">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Job-portal/Images/job-search/job-description/job_description.png"
                          }
                          className="description-icon-img"
                          alt="..."
                        />
                      </div>
                      <h6 className="heading">Job description:</h6>
                    </div>
                    <div className="content">
                      <p>
                        {htmlToReactParser.parse(jobsDescription?.job_description)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* company-job-description section end */}

            {/* Job details: section start */}

            <div className="Job-details">
              <div className="heading">
                <h6 className="heading">Job details:</h6>
              </div>

              <div className="row">
                <div className="col-md-6">
                  {jobDetails.slice(0, 5).map((detail, index) => (
                    <div className="job-details-card" key={index}>
                      <div className="row">
                        <div className="col-xl-1  col-1">
                          <div className="details-card-icon">
                            <img
                              src={process.env.PUBLIC_URL + detail.image}
                              className="details_img"
                              alt="..."
                            />
                          </div>
                        </div>
                        <div className="col-xl-11  col-11">
                          <div className="content-holder">
                            <h6>{detail.title}</h6>
                            <p>{detail.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="col-md-6">
                  {jobDetails.slice(5).map((detail, index) => (
                    <div className="job-details-card" key={index}>
                      <div className="row">
                        <div className="col-xl-1  col-1">
                          <div className="details-card-icon">
                            <img
                              src={process.env.PUBLIC_URL + detail.image}
                              className="details_img"
                              alt="..."
                            />
                          </div>
                        </div>
                        <div className="col-xl-11  col-11">
                          <div className="content-holder">
                            <h6>{detail.title}</h6>
                            <p>{detail.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* Job details: section end */}

            {/* Roles and responsibilities: section  start  */}

            <div className="company-job-description">
              <div className="description">
                <div className="heading-holder">
                  <div className="job-description-icon-holder">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Job-portal/Images/job-search/job-description/roll_and_responsibility.png"
                      }
                      className="description-icon-img"
                      alt="..."
                    />
                  </div>
                  <h6 className="heading">Roles and responsibilities:</h6>
                </div>
                <div className="content">
                  <ul>
                    {/* {rolesAndResponsibilities.map((role, index) => ( */}
                    <li>
                      <p>
                        <FontAwesomeIcon
                          className="check-list"
                          icon="fa-solid fa-circle-check"
                        />{" "}
                        {jobsDescription?.roles_responsibities}
                      </p>
                    </li>
                    {/* ))} */}
                  </ul>
                </div>
              </div>
            </div>

            {/* Roles and responsibilities: section  end  */}

            {/* Contact: section start */}

            <div className="apply-job-section">
              <div className="job-info">
                <div className="text-holder">
                  <h6>Contact:</h6>
                </div>

                <div className="contact">
                  <div className="row">
                    <div className="col-md-5">
                      <div className="contact-text-holder">
                        <p>
                          {jobsDescription?.job_portal_recruiter_contact?.contact_company_name}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-5 mb-3">
                    <div className="col-md-4 col-6">
                      <div className="contact-text-holder">
                        <p>Recruiter Contact Number:</p>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="contact-info-text">
                        <span>{jobsDescription?.job_portal_recruiter_contact?.contact_number}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-4 col-6">
                      <div className="contact-text-holder">
                        <p>Recruiter Email:</p>
                      </div>
                    </div>
                    <div className="col-md-4 col-6">
                      <div className="contact-info-text">
                        <span>{jobsDescription?.job_portal_recruiter_contact?.email}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          {/* ))} */}
          {/* apply-job-section section end */}
        </div >
      </section >
    </>


  );
}
export default JpJobDescription;
