import React, { useRef, useState, useEffect, useContext } from 'react'
import "./JpEmpCompanyProfile.css"
import classNames from "classnames";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from "../../../../../utils/context";
import { Form, Button } from "react-bootstrap";
import Cookies from "js-cookie";
import { RegxExpression } from "../../../../../utils/apis/common/Common";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function JpEmpCompanyProfile(companyId) {
    const [modalShow, setModalShow] = React.useState(false);

    const { postData, getData, Select2Data, IMG_URL } = useContext(Context);
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset, watch,
        trigger,
        setValue
    } = useForm();


    const token = Cookies.get("llp_web_security");
    let decodedToken;
    if (token) {
        decodedToken = JSON.parse(atob(token.split(".")[1]));
    } else {
        console.error('Token is undefined');
        // Handle the case where token is not available
    }
    console.log(decodedToken, "decodedToken");


    const [data, setData] = useState([]);
    const [editing, setEditing] = useState(false);

    const getDataAll = async () => {
        const response = await getData(`/job-portal/employer/company-details/team-member/${decodedToken.user}`);
        await setData(response?.data);
    };

    useEffect(() => {
        getDataAll();
    }, []);

    const onSubmit = async (data) => {
        console.log(data, "dbsdasuggdxgs")
        const fromData = new FormData();
        fromData.append("name", data?.name);
        fromData.append("designation", data?.designation);
        fromData.append("user_id", decodedToken.user);
        fromData.append("image", data?.image[0]);

        try {
            let response;
            if (editing) {
                response = await postData(`/job-portal/employer/company-details/team-member/${data?.id}`, fromData);
            } else {
                response = await postData(`/job-portal/employer/company-details/team-member`, fromData);
            }

            if (response?.success) {
                console.log(response);
                setEditing(false);
                await getDataAll();
                reset({ name: "", designation: "", image: "" }); // Reset the form with empty values
            } else {
                console.error('Submission was not successful:', response);
            }
        } catch (error) {
            console.error('Error submitting data:', error);
            // Handle error and possibly reset the form here as well
        }
    };

    const handleEdit = async (id) => {
        const res = await getData(`/job-portal/employer/company-details/team-member/single/${id}`);
        reset(res?.data);
        setEditing(true)
    }

    const handleDelete = async (id) => {
        const res = await postData(`/job-portal/employer/company-details/team-member/delete/${id}`);
        if (res?.success) {
            getDataAll()
        }
    }


    return (
        <>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div className='btn-div'>

                    <button className='add-btn' type='button' onClick={handleSubmit(onSubmit)}>Save </button>
                </div>
                <div className='row '>
                    {data?.map((val, index) =>
                        <div className='col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-12 '>
                            <div className='person-detail-card d-flex'>
                                <div className='person-img-div me-4'>
                                    <img class="person-img" src={IMG_URL + val?.image} />
                                </div>

                                <div className='mt-3'>
                                    <h5 className='award-name'>{val?.name}  </h5>
                                    <p className='year-text'>{val?.designation}</p>
                                </div>

                                <div className='edit-icon' >
                                    <FontAwesomeIcon icon="fa-solid fa-trash " onClick={() => handleDelete(val?.id)} className='me-2 pencil-icon' />
                                    <FontAwesomeIcon icon="fa-solid fa-pencil" className='pencil-icon' onClick={() => handleEdit(val?.id)} />
                                </div>

                            </div>
                        </div>
                    )}
                </div>
                <div className='row justify-content-center mt-3'>
                    <div className='col-xxl-10 col-xl-10 col-lg-10 col-12 '>
                        <div className='row '>
                            <Form.Label className='col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-12'>
                                Upload Profile Photo
                            </Form.Label>
                            <Form.Group>
                                <Form.Control
                                    className={classNames("", {
                                        "is-invalid": errors?.image,
                                    })}
                                    type="file"
                                    {...register("image", {
                                        required: !editing ? "Image is required" : false,
                                        validate: async (value) => {
                                            if (typeof value !== "string") {
                                                const fileTypes = ["jpg", "png", "jpeg"];
                                                const fileType = value[0].name?.split(".")[1];

                                                if (!fileTypes.includes(fileType)) {
                                                    return `please upload a valid file format. (${fileTypes})`;
                                                }
                                                const fileSize = Math.round(value[0].size / 1024);
                                                if (fileSize > 500) {
                                                    return "file size must be lower than 500kb";
                                                }
                                            }
                                        },
                                    })}
                                    accept=".jpg, .jpeg, .png"
                                />
                            </Form.Group>
                            {errors.image && (
                                <span className="text-danger">
                                    {errors.image.message}
                                </span>
                            )}
                            <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12'>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label >Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Name"
                                        name="name"
                                        {...register("name", {
                                            required: "award name required",
                                        })}
                                        className={classNames("", {
                                            "is-invalid": errors?.name,
                                        })}
                                        onKeyDown={(event) => {
                                            if (!RegxExpression.name.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </Form.Group>

                            </div>
                            <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12'>

                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Designation</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Designation"
                                        name="designation"

                                        {...register("designation", {
                                            required: "designation required",
                                        })}
                                        className={classNames("", {
                                            "is-invalid": errors?.designation,
                                        })}
                                        onKeyDown={(event) => {
                                            if (!RegxExpression.name.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                </Form.Group>
                                {/* <button className='add-btn' type='button' onClick={handleSubmit(onSubmit)}>Save </button> */}

                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </>
    )
}

export default JpEmpCompanyProfile