import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation hook
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
// import "../Jobs_Job_Filter/JobsJobFilter.css";
import "../../JP-Common-Elements/JP-Job-Filter/Jobs_Job_Filter/JobsJobFilter.css";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from "../../../../utils/context";
import { useNavigate } from 'react-router-dom';
import InputRange from 'react-input-range';
import Select from "react-select";
library.add(fas);

function JPCompaniesFilter({
    setCityId,
    setCategory,
    setMinSalary,
    setMaxSalary,
    setMinExperience,
    setMaxExperience,
}) {
    const navigate = useNavigate();

    const Experience_Level = [
        { id: 1, label: 'Fresher', min_experience: '0', max_experience: '1' },
        { id: 2, label: '1 - 2 Yrs', min_experience: '1', max_experience: '2' },
        { id: 3, label: '2 - 3 Yrs', min_experience: '2', max_experience: '3' },
        { id: 4, label: '3 - 4 Yrs', min_experience: '3', max_experience: '4' },
    ];

    const Location = [
        { id: "Banglore", label: "Banglore" },
        { id: "Chennai", label: "Chennai" },
        { id: "Mumbai", label: "Mumbai" },
        { id: "Pune", label: "Pune" },
        { id: "Thane", label: "Thane" },
        { id: "Vasai", label: "Vasai" },
    ];

    const [showAllLocations, setShowAllLocations] = useState(false);

    const handleShowMoreLocations = () => {
        setShowAllLocations(true);
    };

    const { postData, getData, Select2Data, IMG_URL } = useContext(Context);
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset, watch,
        trigger,
        setValue
    } = useForm();

    const [cities, setCity] = useState([]);
    const GetAllCities = async () => {
        const response = await getData("/without-login/master/all-cities");
        if (response?.success) {
            setCity(await Select2Data(response?.data, "city_id"));
        }
    };

    const [company, setcompany] = useState([]);
    const GetAllcompany = async () => {
        const response = await getData(`/without-login/master/all-career-level`);
        if (response?.success) {
            setcompany(response?.data);
        }
    };

    const [selectedCategories, setSelectedCategories] = useState([]);
    const handleCategoryChange = (event, categoryId) => {
        if (event.target.checked) {
            setSelectedCategories(prev => [...prev, categoryId]);
        } else {
            setSelectedCategories(prev => prev.filter(id => id !== categoryId));
        }
    };

    const [selectedCity, setCityCheckboxes] = useState([]);
    const handleCityChange = (event, id) => {
        const { value, checked } = event.target;
        if (checked) {
            setCityCheckboxes([...selectedCity, id]);
        } else {
            setCityCheckboxes(selectedCity.filter(item => item.value !== value));
        }
    };

    useEffect(() => {
        GetAllcompany();
        GetAllCities();
    }, []);


    const [selectedExperience, setSelectedExperience] = useState(null);

    const handleExperienceLevelChange = (levelId) => {
        const level = Experience_Level.find(level => level.id === levelId);
        if (level) {
            setSelectedExperience(levelId); // Update selected experience by ID
            setValue('min_experience', level.min_experience);
            setValue('max_experience', level.max_experience);
        }
    };

    // const onSubmit = async (data) => {

    //     console.log(data, "dasvbvdjgvcdvcdcv");

    //        // Extract job_type_id from form data
    //     //    const job_type_id = data.job_type_id.value;
    //     //    const city_id = data.city_id.value;
    //     //    const company_name = data.company_name;

    //     //    // Construct query parameters
    //     //    const queryParams = new URLSearchParams({
    //     //        job_type_id: job_type_id,
    //     //        city_id: city_id,
    //     //        company_name: company_name,
    //     //    }).toString();

    //     //    // Navigate with query parameters
    //     //    navigate(`./?${queryParams}`);

    // }

    const onSubmit = (data) => {

        setCityId(data?.city);
        setCategory(selectedCategories?.join(','));
        setMinSalary(data?.minSalary);
        setMaxSalary(data?.maxSalary);
        setMinExperience(data?.min_experience);
        setMaxExperience(data?.max_experience);
        // Extract job_type_id from form data

        // const selectedcity = data.location;
        // const company = data.company;
        // const min_experience = data.min_experience;
        // const max_experience = data.max_experience;
        // const city = data.city;
        // const experience = data.experience;

        // // Construct query parameters
        // const queryParams = new URLSearchParams({
        //     selectedcity: selectedcity,
        //     company: company,
        //     min_experience: min_experience,
        //     max_experience: max_experience,
        //     city: city,
        //     experience: experience,

        // }).toString();

        // // Navigate with query parameters
        // navigate(`./?${queryParams}`);
    };

    // const [minSalary, setMinSalary] = useState(0);
    // const [maxSalary, setMaxSalary] = useState(500);
    const handleSalaryChange = (value) => {
        setMinSalary(value?.min);
        setMaxSalary(value?.max);
    };
    const [pagar, setPagar] = useState({ min: 0, max: 100 });
    const handleChange = (newValue) => {
        setPagar(newValue);
        setValue('minSalary', pagar.min);
        setValue('maxSalary', pagar.max);
    };


    return (
        <section className="Job_filter">
            <div className="Job_filter_content">
                <div className="heading-holder">
                    <h6>Company Filter</h6>
                </div>

                <Form className="form-field" onSubmit={handleSubmit(onSubmit)}>

                    <div className="form-content">
                        <>
                            {/* Type of Company field */}
                            <div className="check_box">
                                <Form.Group>
                                    <Form.Label>Type of Company</Form.Label>
                                    <div>
                                        {company?.map((Company, index) => (
                                            <Form.Check
                                                key={index}
                                                type="checkbox"
                                                label={Company?.name}
                                                value={Company?.id}
                                                onChange={(event) => handleCategoryChange(event, Company?.id)}
                                            // {...register('company')}
                                            />
                                        ))}
                                    </div>
                                </Form.Group>
                            </div>

                            {/* Search a Location field */}
                            <div className="form-field">
                                <Form.Group>
                                    <Form.Label>Location</Form.Label>

                                    <Controller
                                        name={"city"}
                                        {...register("city", {
                                        })}
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                styles={{

                                                }}
                                                {...field}
                                                options={cities}
                                            />
                                        )}
                                    />

                                    {/* <div className="form-field location-scroll check_box">
                                        {city?.map((Location, index) => (
                                            <Form.Check
                                                key={Location?.id}
                                                type="checkbox"
                                                id={Location?.id}
                                                label={Location?.name}
                                                value={Location?.id}
                                                name={Location?.id}
                                                onChange={(event) => handleCityChange(event, Location?.id)}
                                                {...register("location")}
                                                style={{
                                                    display:
                                                        showAllLocations || index < 4 ? "block" : "none",
                                                }}
                                            />
                                        ))}
                                        {!showAllLocations && (
                                            <div className="">
                                                <p
                                                    className="show-more"
                                                    onClick={handleShowMoreLocations}
                                                >
                                                    +{Location.length - 4} more
                                                </p>
                                            </div>
                                        )}
                                    </div> */}
                                </Form.Group>
                            </div>

                            {/* Experience Level field */}
                            <div className="form-field switch_box">
                                <Form.Group>
                                    <Form.Label>Experience Level</Form.Label>
                                    <div>
                                        {Experience_Level?.map((level) => (
                                            <Form.Check
                                                key={level?.id}
                                                type="radio" // Changed from switch to radio
                                                id={`experience_level_${level?.id}`} // Unique ID for each radio button
                                                name="experience_level" // All radio buttons in the same group must have the same name
                                                value={level?.id}
                                                label={level?.label}
                                                checked={selectedExperience === level?.id} // Correctly set the checked state
                                                onChange={() => handleExperienceLevelChange(level?.id)} // Update state and form values
                                            />
                                        ))}
                                    </div>
                                </Form.Group>
                            </div>

                            {/* Search by Experience field */}
                            <div className="form-field">
                                <Form.Group>
                                    <div className="form-control-holder">
                                        <Form.Control
                                            type="text"
                                            name="Search"
                                            placeholder="Search by Experience"
                                            {...register('experience')}
                                        />
                                        <div className="icon-holder">
                                            <FontAwesomeIcon
                                                className="font-icon"
                                                icon="fa-solid fa-magnifying-glass"
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>

                            {/* Salary Range slider field */}
                            <div className="form-field">
                                <Form.Group>
                                    <Form.Label> Salary Range</Form.Label>

                                    <InputRange
                                        minValue={100000}
                                        step={10000}
                                        maxValue={500000}
                                        value={pagar}
                                        onChange={handleChange}
                                    />
                                    <div className="Salary_Range">
                                        <p className="text">Min: 1L & Max: 50L</p>
                                    </div>

                                </Form.Group>
                            </div>


                            {/* Submit button */}
                            <div className="submit-btn">
                                <Button className="submit" type="submit">
                                    Submit
                                </Button>
                            </div>
                        </>
                    </div>
                </Form>
            </div>
        </section>
    );
}

export default JPCompaniesFilter;
