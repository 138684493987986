import React, { useRef, useState, useContext, useEffect } from "react";
import './main-junction.css'
import Menu from "../Ed-tech/Animation/home/home-menu/Menu";

import { Link } from "react-router-dom";

const Main_junction = () => {

    const [show, setShow] = React.useState(false);

    const homemenus = [
        {
            name: "Free Counselling",
            imgSrc: "/assets/images/home-menu/operator.png",
            link: "/comming-soon",
        },
        {
            name: "College Compare",
            imgSrc: "/assets/images/home-menu/exchange-horizontal.png",
            link: "/comming-soon",
        },
        {
            name: "Job Portal",
            imgSrc: "/assets/images/home-menu/suitcase.png",
            link: "/job-portal/home",
            // link: "/comming-soon",
        },
        {
            name: "Finance",
            imgSrc: "/assets/images/home-menu/rupee.png",
            link: "/finance/home",
            // link: "/comming-soon",
        },
        {
            name: "Ed-Tech",
            imgSrc: "/assets/images/home-menu/mortarboard.png",
            link: "/ed-tech/home",
        },
    ];

    return (
        <>

            <section className='Main_junction_section'>

                <div className="open-menu-div">
                    <div className="icon-div" onClick={() => setShow(!show)}>
                        <Menu />
                    </div>

                    {show && (
                        <div className="menus-list-div">
                            {homemenus?.map((menu, index) => (
                                <div className="list-div" key={index}>
                                    <div className="icom-div">
                                        <Link className="name" to={menu.link}>
                                            <img className="icon" src={menu.imgSrc} />
                                        </Link>
                                    </div>
                                    <div className="menu-name-div ">
                                        <Link className="name" to={menu.link}>
                                            {" "}
                                            {menu.name}
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </section>


            <section className="whatsappcallicoosec">
                {/* <img className="whatsappcallicoo" src={process.env.PUBLIC_URL + '/assets/images/icons/whatsapp-icon.png'} /> */}


                <a target="_blank" class="webwhats" href="https://web.whatsapp.com/send?phone=8956353503"><img className="whatsappcallicoo" src={process.env.PUBLIC_URL + '/assets/images/icons/whatsapp-icon.png'} /></a>

                <a target="_blank" class="mobwhats" href="https://wa.me/8956353503"><img className="whatsappcallicoo" src={process.env.PUBLIC_URL + '/assets/images/icons/whatsapp-icon.png'} /></a>
            </section>
        </>
    )
}

export default Main_junction