import React from 'react'
import Lottie from "react-lottie";
import * as animationData from '../ErrorLottie.json';
import './ErrorLottie.css'

const Success = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <section className='main-lottey'>
                <div className='success mx-auto'>
                    <Lottie
                        options={defaultOptions}
                    />
                </div>
            </section>
        </>
    )
}

export default Success