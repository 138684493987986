import { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap';
import '../SixthTab.css';
import classNames from "classnames";

const FinanceLoanStep3 = ({ register, errors, setValue }) => {

  const [occupation, setOccupation] = useState("");

  const handleChangeOccupation = (value) => {
    setValue("company_name", "")
    setValue("monthly_income", "")
    setValue("occupation", value);
    setOccupation(value)
  };

  return (

    <section className='step-3 step-2'>
      <div className='mb-3'>
        <Form.Label>Occupation</Form.Label>
        {['radio'].map((type) => (
          <div key={`inline-${type}`} className="mb-0">
            <Form.Check
              inline
              label="Salaried"
              name="group1"
              type="radio"
              value="salarized"
              onChange={(e) => handleChangeOccupation(e.target.value)}
            />
            <Form.Check
              inline
              label="Self-Employed"
              name="group1"
              type="radio"
              value="Self-Employed"
              onChange={(e) => handleChangeOccupation(e.target.value)}
            />

          </div>
        ))}
      </div>

      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Company Name</Form.Label>
        {/* <Form.Control type="text" placeholder="Company Name" /> */}
        <Form.Control
          type="text"
          className={classNames("", {
            "is-invalid": errors?.company_name,
          })}
          {...register("company_name", {
            required: "Company Name is required",
          })}
          placeholder="Company Name"
        />
        {errors.company_name && (
          <span className="text-danger">
            {errors.company_name.message}
          </span>
        )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>
          {occupation === 'salarized' ? 'Monthly Salary' : 'ITR (amount)'}

        </Form.Label>
        <Form.Control
          type="text"
          className={classNames("", {
            "is-invalid": errors?.monthly_income,
          })}
          {...register("monthly_income", {
            required: "Field is required",
          })}
          placeholder={occupation === 'salarized' ? 'Monthly Salary' : 'ITR (amount)'}
          onKeyDown={(event) => {
            if (!/^[0-9]$/.test(event.key) && event.key !== "Backspace" && event.key !== "Tab") {
              event.preventDefault();
            }

          }}
        />
        {errors.monthly_income && (
          <span className="text-danger">
            {errors.monthly_income.message}
          </span>
        )}
        {/* <Form.Control type="text" placeholder="Enter your monthly salary" /> */}
      </Form.Group>


    </section>

  );
};

export default FinanceLoanStep3;
