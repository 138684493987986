import { useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import './FaboutBanner.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import parse from 'html-react-parser';
import { Container } from "react-bootstrap";

const FaboutBanner = () => {
    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        getDataAll();



    }, []);

    const getDataAll = async () => {
        try {
            const response = await getData(`/finance/about-us/f-who-we-are`);
            setData(response?.data);
            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    return (
        <>
            {data && data?.length > 0 &&
                <section className='finance-about-sec'>
                    <Container>
                        {loading ? (
                            <ShimmerThumbnail height={550} rounded />
                        ) : (
                            <Swiper
                                spaceBetween={30}
                                pagination={{
                                    clickable: true,
                                }}
                                modules={[Pagination]}
                                className="mySwiper"
                            >
                                {data?.map((image, index) => (
                                    <SwiperSlide key={index}>
                                        <>
                                            <img
                                                className="fabout-img"
                                                src={IMG_URL + image?.image}
                                                alt="Banner"
                                            />

                                            <div className='overlay-text'>
                                                <div className='container'>
                                                    <div className='row'>
                                                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-12'>
                                                            <h1 className=''>{image?.name}</h1>
                                                            <p>{parse(image?.description) || ""}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="mobile-view-txttt">
                                                <h2>About Us</h2>
                                            </div> */}
                                            <div className='overlay-color' />
                                        </>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        )}
                    </Container>

                </section>
            }
        </>
    );
}

export default FaboutBanner;
