import React, { useRef, useState, useEffect, useContext } from 'react'
import Col from 'react-bootstrap/Col';

import JoditEditor from "jodit-react";
import Row from 'react-bootstrap/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from 'react-select';
import { Link, Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EmpAwardEditModal from "../../../JP-Common-Elements/Jp-modals/EmpAwardEditModal/EmpAwardEditModal"
import EmpEditPersonModal from "../../../JP-Common-Elements/Jp-modals/EmpEditPersonModal/EmpEditPersonModal"
import "./JpEmpCompanyProfile.css"
import LogoutModal from '../../../JP-Common-Elements/Jp-modals/LogoutModal/LogoutModal';
import classNames from "classnames";
import { Tab, Nav } from "react-bootstrap";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from "../../../../../utils/context";
import { Form, Button } from "react-bootstrap";
import AwardAchivement from "./awardachivement";
import TeamMember from "./team_member";
import BranchLocation from "./BranchLocation";
import SocialMedia from "./socialmedia";
import Cookies from "js-cookie";
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function JpEmpCompanyProfile(props) {
    // const history = useHistory();
    const [modalShow, setModalShow] = React.useState(false);
    const [modalShowone, setModalShowone] = React.useState(false);
    const [modalShowtwo, setModalShowtwo] = React.useState(false);
    const [phone, setPhone] = useState("in");
    const awards = [
        {
            awardName: 'Best Achiever Year',
            year: '2017-18',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry hgjgfhhhhhhh'
        },
        {
            awardName: 'Best Achiever Year',
            year: '2017-18',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry hgjgfhhhhhhh'
        },
        {
            awardName: 'Best Achiever Year',
            year: '2017-18',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry hgjgfhhhhhhh'
        },

    ];
    const options1 = [

        { value: 'IT Industry', label: 'IT Industry' },
    ];
    const editor = useRef(null);
    const [content, setContent] = useState("");


    const navigate = useNavigate();
    const { postData, getData, Select2Data, IMG_URL } = useContext(Context);
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset, watch,
        trigger,
        setValue
    } = useForm();


    const token = Cookies.get("llp_web_security");
    let decodedToken;
    if (token) {
        decodedToken = JSON.parse(atob(token.split(".")[1]));
    } else {
        console.error('Token is undefined');
        // Handle the case where token is not available
    }
    console.log(decodedToken, "decodedToken");
    const imageFile = watch("image");
    const videoFile = watch("video");
    const descriptionValue = watch("description");
    const [companyTypes, setCompanyTypes] = useState([]);
    const GetAllCompanyatype = async () => {
        const response = await getData("/without-login/master/all-company");

        if (response?.success) {
            setCompanyTypes(response.data);
        }
    };
    const [companyId, setCompanyId] = useState(null);
    const [industry, setIndustry] = useState([]);
    const GetAllIndustry = async () => {
        const response = await getData("/without-login/master/all-industry");

        if (response.success) {
            setIndustry(await Select2Data(response?.data, "industry_id"));
        }
    };

    const GetId = async () => {
        const response = await getData("/without-login/master/all-industry");

        if (response.success) {
            setIndustry(await Select2Data(response?.data, "industry_id"));
        }
    };

    useEffect(() => {
        GetAllCompanyatype();
        GetAllIndustry();
        GetId();
    }, []);

    const [selectedGSTImages, setSelectedGSTImage] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setSelectedGSTImage(file);
        //   setStoreGst(null);
        clearErrors('gst_image');
    };

    const handleRemoveFile = () => {
        setSelectedGSTImage(null);
        const fileInput = document.getElementById('fileGSTInput');
        fileInput.value = '';
    };

    const onSubmit = async (data) => {

        const fromData = new FormData();
        if (companyId) {
            fromData.append("company_id", companyId);
        }

        fromData.append("user_id", decodedToken.user);
        fromData.append("company_name", data?.company_name);
        fromData.append("company_website", data?.company_website);
        fromData.append("email", data?.email);
        fromData.append("establish_since", data?.establish_since);
        fromData.append("contact_no", data?.contact_no);
        fromData.append("headquarter", data?.headquarter);
        fromData.append("gst", data?.gst);
        fromData.append("team_size", data?.team_size);
        fromData.append("about_us", data?.about_us);
        fromData.append("company_type_id", data?.company_type_id);
        fromData.append("industry_id", data?.industry_id?.value);
        fromData.append("image", data?.image[0]);
        fromData.append("video", data?.video[0]);

        const response = await postData(`/job-portal/employer/company-details/company-edit`, fromData);
        if (response?.success) {
            // history.push("/job-portal/employer/companyprofileupdate");
            navigate(`/job-portal/employer/companyprofileupdate`)

        }

    };


    const getusersDetails = async () => {
        try {
            const res = await getData(`/job-portal/employer/company-details/company-edit`);
            if (res?.success) {
                reset(res?.data);
                if (res?.data?.detail_id) {
                    const newCompanyId = res?.data?.detail_id;
                    setCompanyId(newCompanyId);
                }
            }
        } catch (error) {
            console.error("Error while fetching user details:", error);
        }
    };


    const [companyProfileData, setCompanyProfileData] = useState();

    const getCompanyProfileData = async () => {
        const res = await getData("/job-portal/employer/company-details/company-edit/profile-image-company/data");
        if (res?.success) {
            setCompanyProfileData(res?.data);
        } else {
            console.error("Error fetching  data:", res?.error);
        }
    };


    useEffect(() => {
        getCompanyProfileData();
        getusersDetails();
    }, []);

    return (
        <>
            <section className='jp-emp-company-profile form-container'>
                <div className='company-bann'>
                    <div className='container'>
                        <div className='company-logo-card'>
                            <div className='logo-abs-div'>
                                <div className='company-img-div'>
                                    <img class="com-logo" src={IMG_URL + companyProfileData?.image} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className='container'>
                        <div className='details-sec'>
                            <div className='heading'>
                                <h5 className='main-heading'>Company Profile</h5>
                            </div>
                            <div className='padding-div'>
                                <div className='sub-heading '>
                                    <h6 className='sub-head mb-4'>Company Details</h6>
                                </div>

                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                    <Form.Label column lg="2" md="3 " xs="5">
                                        Company Name:
                                    </Form.Label>
                                    <Col lg="10" md="8 " xs="7"   >
                                        <Form.Control
                                            type="text"
                                            placeholder=" Enter Company Name"
                                            readOnly
                                            name="company_name"
                                            {...register("company_name", {
                                                required: "Company name required",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.company_name,
                                            })}
                                        />
                                    </Col>
                                </Form.Group>
                                <div className='row'>
                                    <div className='col-xxl-6 col-lg-6 col-md-12 col-12'>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label column xxl="4" lg="4" md="3" xs="5"  >
                                                Company Website:
                                            </Form.Label>
                                            <Col xxl="7" lg="" md="8" xs="7"   >
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Company Website"

                                                    name="company_website"

                                                    {...register("company_website", {
                                                        required: "Company website required",
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.company_website,
                                                    })}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </div>
                                    <div className='col-xxl-6 col-lg-6 col-md-12 col-12'>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label column xxl="4" md="3" lg="4" xs="5">
                                                Support Mail:
                                            </Form.Label>
                                            <Col xxl="8" lg="8" md="8" xs="7" >
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Support Mail"
                                                    name="email"
                                                    readOnly
                                                    {...register("email", {
                                                        required: "email required",
                                                        pattern: {
                                                            value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                                                            message: "Invalid Email Address",
                                                        },
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.email,
                                                    })}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-xxl-6 col-lg-6 col-md-12 col-12'>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label column xxl="4" lg="4" md="3" xs="5"  >
                                                Establish Since:
                                            </Form.Label>
                                            <Col xxl="7" lg="" md="8" xs="7"   >
                                                <Form.Control
                                                    type="date"
                                                    placeholder="Enter Establish Since"
                                                    name="establish_since"
                                                    max={new Date().toISOString().split('T')[0]}
                                                    {...register("establish_since", {
                                                        required: "establish since required",
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.establish_since,
                                                    })}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </div>
                                    <div className='col-xxl-6 col-lg-6 col-md-12 col-12'>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label column xxl="4" md="3" lg="4" xs="5">
                                                Contact :
                                            </Form.Label>
                                            <Col xxl="8" lg="8" md="8" xs="7"   >
                                                <Controller
                                                    name="contact_no"
                                                    readOnly
                                                    control={control}
                                                    disabled
                                                    render={({ field }) => (
                                                        <PhoneInput
                                                            disabled
                                                            country="in"
                                                            name="contact_no"
                                                            value={field.value}
                                                            onChange={(value) => field.onChange(value)}
                                                            placeholder="Enter your contact number"
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: errors.contact_no
                                                                        ? "red"
                                                                        : baseStyles.borderColor,
                                                                }),
                                                            }}
                                                        // disabled={mobileVerify}
                                                        />
                                                    )}
                                                // rules={{ required: "Mobile Number is required" }}
                                                />
                                                {errors?.contact_no && (
                                                    <span className="text text-danger">
                                                        {errors.contact_no.message}
                                                    </span>
                                                )}
                                                {/* <Form.Control
                                                    type="text"
                                                    placeholder="contact_no Since"
                                                    name="contact_no"

                                                    {...register("contact_no", {
                                                        required: "contact_no required",
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.contact_no,
                                                    })}
                                                /> */}

                                            </Col>

                                        </Form.Group>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-xxl-6 col-lg-6 col-md-12 col-12'>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label column xxl="4" lg="4" md="3" xs="5"  >
                                                Company Headquarters: <span className='supp-text'>(If Any)</span>
                                            </Form.Label>
                                            <Col xxl="7" lg="" md="8" xs="7"   >
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter  Company Headquarters"
                                                    name="headquarter"

                                                    {...register("headquarter", {
                                                        // required: "headquarter required",
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.headquarter,
                                                    })}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </div>
                                    <div className='col-xxl-6 col-lg-6 col-md-12 col-12'>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label column xxl="4" md="3" lg="4" xs="5">
                                                GST :
                                            </Form.Label>
                                            <Col xxl="8" lg="8" md="8" xs="7"   >
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter GST"
                                                    name="gst"

                                                    {...register("gst", {
                                                        required: "gst required",
                                                        pattern: {
                                                            value: /^\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[1-9A-Z]{1}$/,
                                                            message: "Invalid GST Number",
                                                        },
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.gst,
                                                    })}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-xxl-6 col-lg-6 col-md-12 col-12'>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label column xxl="4" lg="4" md="3" xs="5"  >
                                                Team Size:
                                            </Form.Label>
                                            <Col xxl="7" lg="" md="8" xs="7"   >
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter team size"
                                                    name="team_size"

                                                    {...register("team_size", {
                                                        required: "team size required",
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.team_size,
                                                    })}
                                                />
                                            </Col>
                                        </Form.Group>
                                    </div>
                                    <div className='col-xxl-6 col-lg-6 col-md-12 col-12'>
                                        <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                            <Form.Label column xxl="4" md="3" lg="4" xs="5">
                                                Industry :
                                            </Form.Label>
                                            <Col xxl="8" lg="8" md="8" xs="7"   >
                                                <div className=' search-select-to'>
                                                    {/* <Select placeholder="Select" options={options1} /> */}
                                                    <Controller
                                                        name="industry_id"
                                                        {...register("industry_id", {
                                                            required: "industry_id required",
                                                        })}
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                styles={{
                                                                    control: (baseStyles) => ({
                                                                        ...baseStyles,
                                                                        borderColor: errors.industry_id
                                                                            ? "red"
                                                                            : baseStyles,
                                                                    }),
                                                                }}
                                                                {...field}
                                                                options={industry}
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </Col>
                                        </Form.Group>


                                    </div>
                                </div>
                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                    <Form.Label column lg="2" md="3 " xs="5">
                                        About Us:
                                    </Form.Label>
                                    <Col lg="10" md="8 " xs="7"   >
                                        <Form.Control
                                            // className='text-area-holder'
                                            //  as="textarea" placeholder="" rows={1} 
                                            type="text"
                                            placeholder="Enter team size"
                                            name="about_us"

                                            {...register("about_us", {
                                                required: "about_us required",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.about_us,
                                            })}
                                        />
                                    </Col>
                                </Form.Group>

                                <Form.Group as={Row} className="mb-3" controlId="formPlaintextPassword">
                                    <Form.Label column lg="2" md="3 " xs="5">
                                        Company Type:
                                    </Form.Label>
                                    <Col lg="10" md="8 " xs="7"   >
                                        <div className=''>
                                            {/* <Form.Check
                                                inline
                                                label="Unicorn"
                                                className='mt-2 me-3'
                                            />
                                            <Form.Check
                                                inline
                                                label="MNC"
                                                className='mt-2 me-3'
                                            />
                                            <Form.Check
                                                inline
                                                label="Startup"
                                                className='mt-2 me-3'
                                            />
                                            <Form.Check
                                                inline
                                                label="Product based"
                                                className='mt-2 me-3'
                                            />
                                            <Form.Check
                                                inline
                                                label="Service based"
                                                className='mt-2 me-3'
                                            /> */}
                                            <Nav variant="radio">
                                                {companyTypes?.map((item, index) => (
                                                    <div key={index} style={{ marginLeft: '10px' }}> {/* Add margin bottom for spacing */}
                                                        <Form.Check
                                                            type="radio"
                                                            id={index}
                                                            name="company_type_id"
                                                            label={item?.name}
                                                            value={item?.id}
                                                            defaultChecked={getValues('company_type_id') === item?.id}
                                                            {...register("company_type_id", { required: "company type required" })}
                                                        />
                                                    </div>
                                                ))}

                                            </Nav>
                                            {errors?.company_type_id && (
                                                <span className="text text-danger">
                                                    {errors.company_type_id.message}
                                                </span>
                                            )}
                                        </div>

                                    </Col>
                                </Form.Group>

                                <div className='row '>
                                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 mt-5'>
                                        {/* <div className='upload-link-div'>
                                            <button className='upload-btn'>Upload Profile Photo </button> <FontAwesomeIcon icon="fa-solid fa-upload" className='ms-2' />
                                        </div>
                                        <div className='upload-img-div'>
                                            <img class="com-logo" src="/assets/Job-portal/Images/Logo/smalllogo.png" />
                                        </div> */}

                                        <Form.Label column sm={3} className="text-center">
                                            Upload Profile Photo
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Form.Group>
                                                <Form.Control
                                                    className={classNames("", {

                                                    })}
                                                    type="file"
                                                    {...register("image", {
                                                        // required: "Image is required",
                                                        validate: async (value) => {
                                                            if (typeof value !== "string") {
                                                                const fileTypes = ["jpg", "png", "jpeg"];
                                                                const fileType = value[0]?.name?.split(".")[1];

                                                                if (!fileTypes.includes(fileType)) {
                                                                    return `please upload a valid file format. (${fileTypes})`;
                                                                }

                                                                // const sizes = await getDimension(value[0]);
                                                                // if (
                                                                //     sizes.width !== 1296 &&
                                                                //     sizes.height !== 396
                                                                // ) {
                                                                //     return "Image width and height must be 1296px and 396px";
                                                                // }

                                                                // const fileSize = Math.round(
                                                                //   value[0].size / 1024
                                                                // );
                                                                // if (fileSize > 500) {
                                                                //   return "file size must be lower than 500kb";
                                                                // }
                                                            }
                                                        },
                                                    })}
                                                    accept=".jpg, .jpeg, .png"
                                                />
                                                {/* <Form.Control
                                                    className={classNames("", {
                                                        "is-invalid": errors?.image,
                                                    })}
                                                    type="file"
                                                    {...register("image", {
                                                        required: "Image is required",
                                                        validate: async (value) => {
                                                            if (typeof value !== "string") {
                                                                const fileTypes = ["jpg", "png", "jpeg"];
                                                                const fileType = value[0].name?.split(".")[1];

                                                                if (!fileTypes.includes(fileType)) {
                                                                    return `please upload a valid file format. (${fileTypes})`;
                                                                }
                                                                const fileSize = Math.round(value[0].size / 1024);
                                                                if (fileSize > 500) {
                                                                    return "file size must be lower than 500kb";
                                                                }
                                                            }
                                                        },
                                                    })}
                                                    accept=".jpg, .jpeg, .png"
                                                /> */}
                                            </Form.Group>
                                            {errors.image && (
                                                <span className="text-danger">
                                                    {errors.image.message}
                                                </span>
                                            )}
                                        </Col>
                                        <Col lg={6}>
                                            <div className="main-form-section mt-3">
                                                <Row className="justify-content-center">
                                                    <Form.Label column sm={3} className="text-center">
                                                        Image Preview
                                                    </Form.Label>
                                                    <Col sm={9}>
                                                        {typeof getValues("image") == "string" ? (
                                                            <div className="image-preview-container">
                                                                <img
                                                                    src={IMG_URL + getValues("image")}
                                                                    alt="Preview"
                                                                    className="image-preview"
                                                                    style={{ width: 100, height: 100, marginLeft: 50 }}
                                                                />
                                                            </div>
                                                        ) : (
                                                            imageFile &&
                                                            imageFile?.length > 0 && (
                                                                <div className="image-preview-container">
                                                                    <img
                                                                        // src={URL.createObjectURL(getValues("image")[0])}
                                                                        src={URL?.createObjectURL(imageFile[0])}
                                                                        alt="Preview"
                                                                        className="image-preview"
                                                                        style={{ width: 100, height: 100, marginLeft: 50 }}
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>

                                    </div>
                                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12 mt-5'>

                                        {/* <div className='d-flex'> */}
                                        {/* <div className='upload-link-div'>
                                                <button className='upload-btn'>Upload Video </button> <FontAwesomeIcon icon="fa-solid fa-upload" className='ms-2' />
                                            </div>
                                            <span className='supp-text ms-2'>(Supported file formats MP4, MOV, & Maximum file size 5 MB)</span> */}
                                        <Form.Label column sm={3} className="text-center">
                                            Upload Video
                                        </Form.Label>
                                        <Col sm={9}>
                                            <Form.Group>
                                                <Form.Control
                                                    className={classNames("", {
                                                        "is-invalid": errors?.video,
                                                    })}
                                                    type="file"
                                                    {...register("video", {
                                                        // required: "video is required",

                                                    })}
                                                    accept="video/mp4, video/x-m4v, video/*"
                                                />
                                            </Form.Group>
                                            {errors.video && (
                                                <span className="text-danger">
                                                    {errors.video.message}
                                                </span>
                                            )}
                                        </Col>
                                        {/* </div> */}
                                        {/* <video className='upload-video-div' controls autoPlay loop mute > */}
                                        {/* <source src={process.env.PUBLIC_URL + '/assets/videos/home/who-we-are/bgvideo.mp4'}></source> */}
                                        {/* <Col lg={6}>
                                            <div className="main-form-section mt-3">
                                                <Row className="justify-content-center">
                                                    <Form.Label column sm={3} className="text-center">
                                                        Video Preview
                                                    </Form.Label>
                                                    <Col sm={9}>
                                                        {videoFile && videoFile.length > 0 && (
                                                            <div className="video-preview-container">
                                                                <video controls className="video-preview" style={{
                                                                    width: "300px",
                                                                    height: "150px",
                                                                }} >
                                                                    <source src={URL.createObjectURL(videoFile[0])} type="video/mp4" />
                                                                    Your browser does not support the video tag.

                                                                </video>
                                                            </div>
                                                        )}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col> */}
                                        <Col lg={6}>
                                            <div className="main-form-section mt-3">
                                                <Form.Label className="text-center">Video Preview</Form.Label>
                                                <Row className="justify-content-center">
                                                    <Col sm={9}>
                                                        {typeof getValues("video") == "string" ? (
                                                            <div className="image-preview-container">
                                                                <video
                                                                    src={IMG_URL + getValues("video")}
                                                                    alt="Preview"
                                                                    className="image-preview"
                                                                    style={{ width: "150px", height: "130px" }}
                                                                    autoPlay
                                                                    loop
                                                                    muted
                                                                    controls
                                                                />
                                                            </div>
                                                        ) : (
                                                            videoFile &&
                                                            videoFile?.length > 0 && (
                                                                <div className="image-preview-container">
                                                                    <video
                                                                        // src={URL.createObjectURL(getValues("image")[0])}
                                                                        src={URL?.createObjectURL(videoFile[0])}
                                                                        alt="Preview"
                                                                        className="image-preview"
                                                                        style={{ width: "150px", height: "130px" }}
                                                                        controls
                                                                        muted
                                                                    />
                                                                </div>
                                                            )
                                                        )}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        {/* </video> */}
                                    </div>
                                </div>
                                {/* <Button
                                    // onClick={handleNext}
                                    type="submit"
                                    className="tabs-btn">
                                    Save
                                </Button> */}
                                {/* <div className='btn-div'>
                                    <button className='add-btn' type='submit'  >Add </button>
                                </div> */}


                            </div>
                        </div>
                        <div className='award-sec'>
                            <div className='sub-heading d-flex'>
                                <div className='heading-div'>
                                    <h6 className='sub-head'>Awards & Achievements</h6>
                                </div>
                            </div>

                            <AwardAchivement
                                companyId={companyId}
                                getValues={getValues}
                            />


                        </div>
                        <div className='social-sec'>
                            <div className='sub-heading d-flex socia-head'>
                                <div className='heading-div'>
                                    <h6 className='sub-head'>Social Network Links</h6>
                                </div>
                            </div>
                            <SocialMedia companyId={companyId} />

                        </div>
                        <div className='team-member-sec'>
                            <div className='sub-heading d-flex'>
                                <div className='heading-div'>
                                    <h6 className='sub-head'>Team members</h6>
                                </div>

                            </div>
                            <TeamMember companyId={companyId} />

                        </div>

                        <div className='team-member-sec'>
                            <div className='sub-heading d-flex'>
                                <div className='heading-div'>
                                    <h6 className='sub-head'>Branches & Addresses</h6>
                                </div>

                            </div>
                            <BranchLocation companyId={companyId} />

                        </div>

                        {/* <div className='btn-div justify-content-center mb-5 mt-3'>
                        <Link to="/job-portal/employer/companyprofileupdate">
                            <button className='add-btn' type='submit'
                            onClick={handleSubmit(onSubmit)}
                            >Update</button>
                        </Link>
                    </div> */}
                        <div className='btn-div justify-content-center mb-5 mt-3'>
                            <button className='add-btn' type='submit' onClick={handleSubmit(onSubmit)}>
                                {/* <Link to={`/job-portal/employer/companyprofileupdate`}>Update</Link> */}
                                Update
                            </button>
                        </div>
                    </div>
                </Form>
                <EmpAwardEditModal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    getValues={getValues}
                />
                <EmpEditPersonModal
                    show={modalShowone}
                    onHide={() => setModalShowone(false)}
                    getValues={getValues}
                />
                <LogoutModal
                    show={modalShowtwo}
                    onHide={() => setModalShowtwo(false)}
                    text={"Are you sure, you want to delete job?"}
                    heading={"Delete Award"}
                    imgSource={"/assets/Job-portal/Images/employer/Logout/Object.png"}
                />
            </section>

        </>
    )
}

export default JpEmpCompanyProfile