import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation hook
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
// import "../Jobs_Job_Filter/JobsJobFilter.css";
// import "./JobsJobFilter.css";
// import "../../../../JP-Common-Elements/JP-Job-Filter/Jobs_Job_Filter/JobsJobFilter.css";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from "../../../../utils/context";
import { useNavigate } from 'react-router-dom';
import InputRange from 'react-input-range';
import { allJobTitle, allJobType, allCity, allIndustries, } from "../../../../utils/apis/common/Common";
library.add(fas);


function JPCompaniesJobFilter({
    job_title,
    city_id,
    job_type_id,

    setJobTitle,
    setCityId,
    setJobTypeId,
    setDate,
    setCategory,
    setMinSalary,
    setMaxSalary,
    setMinExperience,
    setMaxExperience,
    setFrom,
}) {

    const minDistance = 0;
    const maxDistance = 500;
    const minSalary = 0;
    const maxSalary = 100;
    const [salaryRange, setSalaryRange] = useState([minSalary, maxSalary]);


    // const handleSalaryChange = (value) => {
    //     console.log("Slider changing to:", value); // Debugging line
    //     setSalaryRange({ minSalary: value[0], maxSalary: value[1] });
    // };

    // useEffect(() => {
    //     // Here you can set form data or perform any other action with the updated salaryRange state
    //     console.log("Updated salary range:", salaryRange);
    // }, [salaryRange]);

    // Define initial state and handler for distance slider
    const [distance, setDistance] = useState(0);
    const handleDistanceChange = (value) => {
        setDistance(value);
    };

    // Define initial state and handler for salary slider
    const [salary, setSalary] = useState(0);
    // const handleSalaryChange = (value) => {
    //     setSalary(value);
    // };

    // Define min and max values for the sliders
    // Maximum salary value, adjust as needed

    const categories = [
        { id: "Unicorn", label: "Unicorn" },
        { id: "MNC", label: "MNC" },
        { id: "Startup", label: "Startup" },
        { id: "Product_based", label: "Product based" },
        { id: "Service_Based", label: "Service Based" },
    ];

    const JobType = [
        { id: "Full_Time", label: "Full Time" },
        { id: "Part_Time", label: "Part Time" },
        { id: "Internship", label: "Internship" },
        { id: "Freelance", label: "Freelance" },
    ];

    const PostedDate = [
        { id: "All", label: "All", value: "" },
        { id: "All", label: "Today", value: "1" },
        { id: "All", label: "Last 4 Days", value: "4" },
        { id: "All", label: "Last 7 Days", value: "7" },
        { id: "All", label: "Last 14 Days", value: "14" },
        { id: "All", label: "Last 30Days", value: "30" },
    ];

    const Experience_Level = [
        { id: 1, label: 'Fresher', min_experience: '0', max_experience: '1' },
        { id: 2, label: '1 - 2 Yrs', min_experience: '1', max_experience: '2' },
        { id: 3, label: '2 - 3 Yrs', min_experience: '2', max_experience: '3' },
        { id: 4, label: '3 - 4 Yrs', min_experience: '3', max_experience: '4' },
    ];

    const Company = [
        { id: "Unicorn", label: "Unicorn" },
        { id: "MNC", label: "MNC" },
        { id: "Startup", label: "Startup" },
        { id: "Product_based", label: "Product based" },
        { id: "Service_Based", label: "Service Based" },
    ];

    const Location = [
        { id: "Banglore", label: "Banglore" },
        { id: "Chennai", label: "Chennai" },
        { id: "Mumbai", label: "Mumbai" },
        { id: "Pune", label: "Pune" },
        { id: "Thane", label: "Thane" },
        { id: "Vasai", label: "Vasai" },
    ];
    const [selectedExperience, setSelectedExperience] = useState(null);
    const [showAllLocations, setShowAllLocations] = useState(false);

    const handleShowMoreLocations = () => {
        setShowAllLocations(true);
    };

    const navigate = useNavigate();
    // Use useLocation hook to get the current path
    const location = useLocation();
    const { getData, IMG_URL, Select2Data } = useContext(Context);
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset,
        setValue
    } = useForm();

    // Define initial state and handler for distance slider

    // useEffect(() => {
    //     if (job_title) {
    //         reset("title", job_title);
    //     } if (city_id) {
    //         reset("city", city_id);
    //     }
    // }, [job_title, city_id])

    // Define initial state and handler for salary slider

    const [level, setLevel] = useState();
    const handleCompanyChange = (event, id) => {
        const companyIdNumber = Number(id);
        const isChecked = event.target.checked;

        if (isChecked) {
            setLevel(prev => [...prev, companyIdNumber]);
        } else {
            setLevel(prev => prev.filter(item => item !== companyIdNumber));
        }
    };
    const [getJobType, setJobType] = useState();
    const handleJobTypeChange = (event, id) => {
        const companyIdNumber = Number(id);
        const isChecked = event.target.checked;

        if (isChecked) {
            setJobType(prev => [...prev, companyIdNumber]);
        } else {
            setJobType(prev => prev.filter(item => item !== companyIdNumber));
        }
    };

    const handleSalaryChange = (value) => {
        setSalary(value);
    };

    const getAllJobType = async () => {
        const res = await getData(`/without-login/master/all-job-type`);
        if (res?.success) {
            setJobType(res.data);
        } else {
            console.error("Error fetching  data:", res?.error);
        }
    };

    const getproject = async () => {
        const res = await getData(`/without-login/master/all-career-level`);
        if (res?.success) {
            setLevel(res.data);
        } else {
            console.error("Error fetching  data:", res?.error);
        }
    };
    const handleExperienceLevelChange = (levelId) => {
        const level = Experience_Level.find(level => level.id === levelId);
        if (level) {
            setSelectedExperience(levelId);
            setValue('min_experience', level.min_experience);
            setValue('max_experience', level.max_experience);
        }
    };

    const [selectedJobTypes, setSelectedJobType] = useState([]);
    const handleJobTypesChange = (event, categoryId) => {
        if (event.target.checked) {
            setSelectedJobType(prev => [...prev, categoryId]);
        } else {
            setSelectedJobType(prev => prev.filter(id => id !== categoryId));
        }
    };

    const [selectedCategories, setSelectedCategories] = useState([]);
    const handleCategoryChange = (event, categoryId) => {
        if (event.target.checked) {
            setSelectedCategories(prev => [...prev, categoryId]);
        } else {
            setSelectedCategories(prev => prev.filter(id => id !== categoryId));
        }
    };

    useEffect(() => {
        console.log("selectedCategories updated:", selectedCategories);
        console.log("selectedJobTypes updated:", selectedJobTypes);
    }, [selectedCategories, selectedJobTypes]);

    const onSubmit = (data) => {
        console.log("here");
        try {


            console.log(data, "categary");

            // const selectedCategory = selectedCategories?.join(',');
            // const city = data?.city?.value;
            // const date = data?.date;
            // const experienceNumber = data?.experienceNumber;
            // const min_experience = data?.min_experience;
            // const max_experience = data?.max_experience;
            // const title = data?.title;
            // const selectedJobType = selectedJobTypes?.join(',');
            // const minSalary = data?.minSalary;
            // const maxSalary = data?.maxSalary;

            setJobTitle(data?.title);
            setCityId(data?.city);
            setJobTypeId(selectedJobTypes?.join(','));
            setDate(data?.date);
            setCategory(selectedCategories?.join(','));
            setMinSalary(data?.minSalary);
            setMaxSalary(data?.maxSalary);
            setMinExperience(data?.min_experience);
            setMaxExperience(data?.max_experience);

            setFrom("filter")


            // Construct query parameters
            // const queryParams = new URLSearchParams({
            //     category: selectedCategory,
            //     city: city,
            //     date: date,
            //     experienceNumber: experienceNumber,
            //     min_experience: min_experience,
            //     max_experience: max_experience,
            //     title: title,
            //     selectedJobType: selectedJobType,
            //     minSalary: minSalary,
            //     maxSalary: maxSalary,
            // }).toString();


            // // Navigate with query parameters
            // navigate(`./?${queryParams}`);
        } catch {
            console.log("Error Submitting Form", errors);
        }
    }
    const [carrerLevelData, setCarrerLevelData] = useState([]);
    const GetAllCarrerLevelData = async () => {
        const response = await getData("/without-login/master/all-career-level");
        setCarrerLevelData(response?.data);
        // if (response.success) {
        //   setCarrerLevelData(await Select2Data(response?.data, "career_level_id"));
        // }
    };



    console.log(getValues(), "jobkkkkkkkkk");
    const [pagar, setPagar] = useState({ min: 0, max: 100 });
    const handleChange = (newValue) => {
        setPagar(newValue);
        setValue('minSalary', pagar.min);
        setValue('maxSalary', pagar.max);
    };

    const [jobCityData, setCityData] = useState();
    const getCityData = async () => {
        const res = await allCity();
        if (res?.success) {
            setCityData(await Select2Data(res?.data, "city_id"));
        }
    };

    useEffect(() => {
        getCityData()
        getproject();
        getAllJobType();
    }, []);

    return (
        <section className="Job_filter">
            <div className="Job_filter_content">
                <div className="heading-holder">
                    <h6>Job Filter</h6>
                </div>


                <Form className="form-field" onSubmit={handleSubmit(onSubmit)}>

                    <div className="form-content">
                        <>
                            {/* Search by Keywords field */}
                            <div className="">
                                <Form.Group>
                                    <Form.Label>Search by Keywords</Form.Label>

                                    <div className="form-control-holder">
                                        <Form.Control
                                            type="text"
                                            name="Search"
                                            placeholder="Job Title, Keywords or Comapny"
                                            {...register('title')}
                                        />
                                        <div className="icon-holder">
                                            <FontAwesomeIcon
                                                className="font-icon"
                                                icon="fa-solid fa-magnifying-glass"
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>

                            {/*City or Location  field */}
                            <div className="form-field ">
                                <Form.Group>
                                    <Form.Label>Location</Form.Label>
                                    <div className="form-control-holder">
                                        {/* <Form.Control
                                            type="text"
                                            name="Search"
                                            placeholder="City or Location"
                                            {...register('city')}
                                        /> */}
                                        <Controller
                                            name={"city"}
                                            {...register("city", {
                                            })}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    styles={{

                                                    }}
                                                    {...field}
                                                    options={jobCityData}
                                                />
                                            )}
                                        />
                                        <div className="icon-holder">
                                            <FontAwesomeIcon
                                                className="font-icon"
                                                icon="fa-solid fa-location-dot"
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>

                            {/* distance rang slider  */}
                            <div className="form-field">
                                <Form.Group>
                                    <div className="range-value-holder">
                                        <span>0 km</span>
                                        <span>100 km</span>
                                    </div>
                                    <RangeSlider
                                        value={distance}
                                        onChange={handleDistanceChange}
                                        min={minDistance}
                                        max={maxDistance}
                                        className=""

                                    />
                                </Form.Group>
                            </div>

                            {/* Category field */}
                            <div className="form-field check_box">
                                <Form.Group>
                                    <Form.Label>Category</Form.Label>
                                    <div>
                                        {/* {categories.map((category) => (
                      <Form.Check
                        key={category.id}
                        type="checkbox"
                        id={category.id}
                        label={category.name}
                        name={category.name}
                      />
                    ))} */}
                                        {level?.map((category, index) => (
                                            <Form.Check
                                                key={category.id}
                                                type="checkbox"
                                                id={category.id}
                                                label={category.name}
                                                onChange={(event) =>
                                                    handleCategoryChange(event, category.id)
                                                }
                                                checked={selectedCategories.includes(category.id)}
                                            />
                                        ))}
                                    </div>
                                </Form.Group>
                            </div>

                            {/* Job Type field */}
                            <div className="form-field switch_box">
                                <Form.Group>
                                    <Form.Label>Job Type</Form.Label>
                                    <div>
                                        {getJobType?.map((jobType, index) => (
                                            <Form.Check
                                                key={index}
                                                value={jobType?.id}
                                                type="switch"
                                                id={jobType?.id}
                                                label={jobType?.name}
                                                name={jobType?.id}
                                                onChange={(event) => handleJobTypesChange(event, jobType?.id)}
                                            // {...register('jobType')}
                                            />
                                        ))}
                                    </div>
                                </Form.Group>
                            </div>

                            {/* Posted Date field  */}
                            <div className="form-field radio_box">
                                <Form.Group>
                                    <Form.Label>Posted Date</Form.Label>
                                    <div>
                                        {PostedDate.map((PostedDate) => (
                                            <Form.Check
                                                key={PostedDate?.id}
                                                value={PostedDate?.value}
                                                type="radio"
                                                id={PostedDate?.id}
                                                label={PostedDate?.label}
                                                name={PostedDate?.id}
                                                {...register('date')}
                                            />
                                        ))}
                                    </div>
                                </Form.Group>
                            </div>

                            {/* Experience Level field */}
                            <div className="form-field switch_box">
                                <Form.Group>
                                    <Form.Label>Experience Level</Form.Label>
                                    <div>
                                        {Experience_Level?.map((level, index) => (
                                            <Form.Check
                                                key={index}
                                                type="switch"
                                                id={level?.id}
                                                value={selectedExperience === level?.id ? level?.max_experience : level?.min_experience}
                                                label={level?.label}
                                                checked={selectedExperience === level?.id}
                                                onChange={() => handleExperienceLevelChange(level?.id)}
                                            />
                                        ))}

                                    </div>
                                </Form.Group>
                            </div>

                            {/* Search by Experience field  */}
                            <div className="form-field">
                                <Form.Group>
                                    <div className="form-control-holder">
                                        <Form.Control
                                            type="text"
                                            name="Search"
                                            placeholder="Search by Experience"
                                            {...register('experienceNumber')}
                                        />
                                        <div className="icon-holder">
                                            <FontAwesomeIcon
                                                className="font-icon"
                                                icon="fa-solid fa-magnifying-glass"
                                            />
                                        </div>
                                    </div>
                                </Form.Group>
                            </div>

                            {/* Salary Range slider field */}
                            <div className="form-field">
                                <Form.Group>
                                    <Form.Label> Salary Range</Form.Label>

                                    <InputRange
                                        minValue={100000}
                                        step={10000}
                                        maxValue={500000}
                                        value={pagar}
                                        onChange={handleChange}
                                    />
                                    <div className="Salary_Range">
                                        <p className="text">Min: 1L & Max: 50L</p>
                                    </div>

                                </Form.Group>
                            </div>

                            {/*  Submit button */}
                            <div className="submit-btn">
                                <Button className="submit" type="submit">
                                    Submit
                                </Button>
                            </div>
                        </>
                    </div>
                </Form>
            </div>
        </section>
    );
}

export default JPCompaniesJobFilter;
