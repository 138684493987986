import React, { useState, useRef, useEffect, useContext } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import JoditEditor from "jodit-react";
import "./Step1.css";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from "../../../../../utils/context";
import { Button } from "react-bootstrap";
import classNames from "classnames";
import Cookies from "js-cookie";
import { allStateID, allCourse, selectedCity } from "../../../../../utils/apis/common/Common.js";
import { useParams } from "react-router-dom";

function Step1({ handleNext, userDetails }) {

  const { id } = useParams();
  const editor = useRef(null);
  const [content, setContent] = useState("");

  const defaultLogo = "/assets/Job-portal/Images/Logo/smalllogo.png"; // Default logo URL
  const [logo, setLogo] = useState(defaultLogo); // State to store selected logo file

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setLogo(URL.createObjectURL(file));
  };

  const token = Cookies.get("llp_web_security");
  let decodedToken;
  if (token) {
    decodedToken = JSON.parse(atob(token.split(".")[1]));
  } else {
    console.error('Token is undefined');
    // Handle the case where token is not available
  }


  const { postData, getData, Select2Data, IMG_URL } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset, watch,
    trigger,
    setValue
  } = useForm();

  const imageFile = watch("company_logo");
  const videoFile = watch("video");
  const descriptionValue = watch("description");

  const [jobTypes, setJobTypes] = useState([]);
  const GetAllJobType = async () => {
    const response = await getData("/without-login/master/all-job-type");
    if (response.success) {
      setJobTypes(await Select2Data(response?.data, "job_type_id"));
    }
  };

  const [jobTitle, setJobTitle] = useState(null);
  const GetAllJobTitle = async () => {
    const response = await getData("/without-login/master/all-job-title");
    if (response.success) {
      setJobTitle(await Select2Data(response?.data, "job_title_id"));
    }
  };
  const [industry, setIndustry] = useState([]);
  const GetAllIndustry = async () => {
    const response = await getData("/without-login/master/all-industry");

    if (response.success) {
      setIndustry(await Select2Data(response?.data, "industry_id"));
    }
  };

  const [skillsData, setSkillsData] = useState([]);
  const GetAllSkills = async () => {
    const response = await getData("/without-login/master/all-skills");

    if (response.success) {
      setSkillsData(await Select2Data(response?.data, "skills_id"));
    }
  };

  const [shiftData, setShiftData] = useState([]);
  const GetAllShift = async () => {
    const response = await getData("/without-login/master/all-shifts");

    if (response.success) {
      setShiftData(await Select2Data(response?.data, "shift_id"));
    }
  };

  const [CountryData, setcountryData] = useState([]);
  const GetAllCountry = async () => {
    const response = await getData("/without-login/master/all-country");

    if (response.success) {
      setcountryData(await Select2Data(response?.data, "country_id"));
    }
  };

  const [qualificationData, setQualificationData] = useState([]);
  const GetAllQualification = async () => {
    const response = await getData("/without-login/master/allqualification");

    if (response.success) {
      setQualificationData(await Select2Data(response?.data, "qualification_id"));
    }
  };

  const [carrerLevelData, setCarrerLevelData] = useState([]);
  const GetAllCarrerLevelData = async () => {
    const response = await getData("/without-login/master/all-career-level");

    if (response.success) {
      setCarrerLevelData(await Select2Data(response?.data, "career_level_id"));
    }
  };
  const [states, setStates] = useState([]);
  const GetAllStates = async (id) => {
    const response = await getData(`/without-login/master/all-state/${id}`);
    if (response?.success) {
      setStates(await Select2Data(response?.data, "state_id"));
    }
  };

  const [city, setCity] = useState([]);
  const GetAllCities = async (id) => {

    const response = await getData(`/without-login/master/all-city/${id}`);
    if (response?.success) {
      setCity(await Select2Data(response?.data, "state_id"));
    }
  };

  const [company, setcompany] = useState([]);
  const GetAllcompany = async () => {

    const response = await getData(`/without-login/master/all-company`);
    if (response?.success) {
      setcompany(await Select2Data(response?.data, "company_id"));
    }
  };


  useEffect(() => {
    GetAllJobType();
    GetAllJobTitle();
    GetAllIndustry();
    GetAllSkills();
    GetAllShift();
    GetAllCountry();
    GetAllQualification();
    GetAllCarrerLevelData();
    GetAllcompany()
  }, []);



  const onSubmit = async (data) => {

    console.log(data, "FormDATA");
    const fromData = new FormData();

    if (id) {
      fromData.append("id", id);
    } else if (Cookies.get('job_id')) {
      fromData.append("id", Cookies.get('job_id'));
    }

    if (userDetails?.contact_id) {
      fromData.append("contact_id", userDetails?.contact_id);
    }

    fromData.append("user_id", decodedToken.user);
    if (data?.company_id) {
      fromData.append("company_id", data?.company_id.value);
    }
    if (data?.company_name) {
      fromData.append("company_name", data?.company_name);
    }

    fromData.append("deadline", data?.deadline);
    fromData.append("number_of_vacancy", data?.number_of_vacancy);
    fromData.append("experience_max", data?.experience_max);
    fromData.append("experience_min", data?.experience_min);
    fromData.append("salary_max", data?.salary_max);
    fromData.append("salary_min", data?.salary_min);
    fromData.append("language_preference", data?.language_preference);
    fromData.append("roles_responsibities", data?.roles_responsibities);
    fromData.append("gender", data?.gender);
    fromData.append("address", data?.address);
    fromData.append("job_description", data?.description);
    fromData.append("industry_id", data?.industry_id?.value);
    fromData.append("qualification_id", data?.qualification_id?.value);
    fromData.append("job_title_id", data?.job_title_id?.value);
    fromData.append("career_level_id", data?.career_level_id?.value);
    fromData.append("country_id", data?.country_id?.value);
    fromData.append("state_id", data?.state_id?.value);
    fromData.append("city_id", data?.city_id?.value);
    fromData.append("job_type_id", data?.job_type_id?.value);

    const skillsIds = data?.skills_id?.map(option => option.value);
    if (skillsIds && skillsIds.length > 0) {
      const skillsIdsJSON = JSON.stringify(skillsIds);
      fromData.append("skills_id", skillsIdsJSON);
    }


    const shiftIds = data?.shift_id?.map(option => option.value);
    if (shiftIds && shiftIds.length > 0) {
      const shiftIdsJSON = JSON.stringify(shiftIds);
      fromData.append("shift_id", shiftIdsJSON);
    }
    fromData.append("company_logo", data?.company_logo[0]);
    fromData.append("contact_number", data?.contact_number);
    fromData.append("contact_company_name", data?.contact_company_name);
    fromData.append("email", data?.email);

    const response = await postData(`/job-portal/employer/job-post/job`, fromData);
    if (response?.success) {
      Cookies.set("job_id", response.data, { expires: 1 });
      handleNext();
    }


  };

  const validateExperience = (min, max) => {
    const minExperience = parseInt(min, 10);
    const maxExperience = parseInt(max, 10);

    if (minExperience > maxExperience) {
      return "Minimum experience cannot be greater than maximum experience";
    }
    return true;
  };

  const getusersDetails = async () => {
    let res;
    if (id) {
      res = await getData(`/job-portal/employer/job-post/job/${id}`);
    }
    else {
      res = await getData(`/job-portal/employer/job-post/job/${Cookies.get('job_id')}`);
    }
    if (res?.success) {

      const userDetails = res?.data;
      // const jobTypeNames = userDetails.job_type_details.map(item => ({
      //   value: item.job_type_id,
      //   label: item.job_type.name
      // }));
      const skills = userDetails?.skills_details?.map(item => ({
        value: item.skills_id,
        label: item.skill.name
      }));



      const shiftdata = userDetails.shift_details?.map(item => ({
        value: item.shift_id,
        label: item.shift?.name
      }));

      const resetData = {
        id: userDetails.id,
        address: userDetails.address,
        city: userDetails.city,
        job_type_id: userDetails.job_type_id,
        skills_id: skills,
        shift_id: shiftdata,
        salary_min: userDetails.salary_min,
        salary_max: userDetails.salary_max,
        roles_responsibities: userDetails.roles_responsibities,
        qualification_id: userDetails.qualification_id,
        language_preference: userDetails.language_preference,
        job_title_id: userDetails.job_title_id,
        description: userDetails.job_description,
        industry_id: userDetails.industry_id,
        experience_min: userDetails.experience_min,
        experience_max: userDetails.experience_max,
        deadline: userDetails.deadline,
        gender: userDetails.gender,
        career_level_id: userDetails.career_level_id,
        country_id: userDetails.country_id,
        state_id: userDetails.state_id,
        city_id: userDetails.city_id,
        deadline: userDetails.deadline,
        career_level_id: userDetails.career_level_id,
        number_of_vacancy: userDetails.number_of_vacancy,
        company_logo: userDetails.company_logo,
        company_id: userDetails.company_id,
        contact_company_name: userDetails.job_portal_recruiter_contact.contact_company_name,
        email: userDetails.job_portal_recruiter_contact.email,
        contact_number: userDetails.job_portal_recruiter_contact.contact_number,
        contact_id: userDetails.job_portal_recruiter_contact.id,
      };
      reset(resetData);


    }
  };

  useEffect(() => {
    getusersDetails();
  }, []);

  // useEffect(() => {
  //   reset(userDetails)
  // }, [userDetails]);




  // useEffect(() => {
  //   register('description', {
  //     required: 'Description is required.',
  //     minLength: {
  //       value: 300,
  //       message: 'Description must be min 300 characters long.'
  //     }
  //   });
  // }, [register]);

  const validateSalary = (min, max) => {
    const minSalary = parseFloat(min);
    const maxSalary = parseFloat(max);

    if (minSalary >= maxSalary) {
      return "Minimum salary must be less than maximum salary";
    }
    return true;
  };

  const isNumeric = (value) => {
    return !isNaN(value) && parseFloat(value).toString() === value.toString();
  };

  const [isOtherSelected, setIsOtherSelected] = useState(false);

  // Update the onChange handler of your Select component

  return (
    <>
      <section className="  step1-job-details form-container ">
        <div className="container">
          <Form>
            <div className="top-div ">
              <div className="heading-div">
                <h6>Job Details</h6>
              </div>
            </div>
            <div className="form-div">

              <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Company Name</Form.Label>

                    <Controller
                      name="company_id"
                      {...register("company_id", {
                        // required: "company_id required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.company_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          // options={company}
                          options={[...company, { label: 'other', value: "other", name: 'company_id' }]}
                          onChange={(selectedOption) => {
                            if (selectedOption.value === "other") {
                              setIsOtherSelected(true);
                              field.onChange(null);
                            } else {
                              setIsOtherSelected(false);
                              field.onChange(selectedOption);
                            }
                          }}
                        />
                      )}

                    />

                    {isOtherSelected && (
                      <Form.Control
                        type="text"
                        placeholder="Enter company name"
                        name="company_name"
                        {...register("company_name", {
                          required: "company_name required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.company_name,
                        })}
                      />
                    )}
                  </Form.Group>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Job Type</Form.Label>
                    <Controller
                      name="job_type_id"
                      {...register("job_type_id", {
                        required: "job_type_id required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.job_type_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={jobTypes}
                        />
                      )}
                    />
                  </Form.Group>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Job Title</Form.Label>
                    <Controller
                      name="job_title_id"
                      {...register("job_title_id", {
                        required: "job_title_id required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.job_title_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={jobTitle}
                        />
                      )}
                    />
                  </Form.Group>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12">
                  <div className="">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label>Industry / Sector</Form.Label>
                      <Controller
                        name="industry_id"
                        {...register("industry_id", {
                          required: "industry_id required",
                        })}
                        control={control}
                        render={({ field }) => (
                          <Select
                            styles={{
                              control: (baseStyles) => ({
                                ...baseStyles,
                                borderColor: errors.industry_id
                                  ? "red"
                                  : baseStyles,
                              }),
                            }}
                            {...field}
                            options={industry}
                          />
                        )}
                      />
                    </Form.Group>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Career Level</Form.Label>

                    <Controller
                      name="career_level_id"
                      {...register("career_level_id", {
                        required: "career_level_id required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.career_level_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={carrerLevelData}
                        />
                      )}
                    />
                  </Form.Group>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Qualification</Form.Label>

                    <Controller
                      name="qualification_id"
                      {...register("qualification_id", {
                        required: "qualification_id required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.qualification_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={qualificationData}
                        />
                      )}
                    />
                  </Form.Group>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Relevant Experience</Form.Label>
                    <div className="d-flex main-grp">
                      <div className="d-flex sub-grp">
                        <Form.Label>Min.</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter minimum experience"
                          name="experience_min"
                          // {...register("experience_min", {
                          //   required: "Minimum experience required",
                          //   validate: value => validateExperience(value, watch('experience_max'))
                          // })}
                          {...register("experience_min", {
                            required: "Minimum experience required",
                            validate: [
                              {
                                notExceed50: value => {
                                  if (value && parseInt(value) > 50) {
                                    return "Minimum relevant experience cannot exceed 50";
                                  }
                                  return true;
                                }
                              },
                              value => validateExperience(watch('experience_max'), value)
                            ]
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.experience_min,
                          })}
                          onKeyDown={(event) => {
                            const currentValue = event.target.value;
                            const keyPressed = event.key;

                            if (currentValue === '' && keyPressed === '0') {
                              event.preventDefault();
                              return;
                            }

                            if (
                              Number(currentValue + keyPressed) > 50 &&
                              !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End'].includes(keyPressed)
                            ) {
                              event.preventDefault();
                            }

                            // Only allow numeric characters and specific control keys
                            if (!/^\d$/.test(keyPressed) &&
                              !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End'].includes(keyPressed)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors.experience_min && <span className="invalid-feedback">{errors.experience_min.message}</span>}
                      </div>

                      <div className="d-flex sub-grp">
                        <Form.Label>Max.</Form.Label>

                        <Form.Control
                          type="text"
                          placeholder="Enter maximum experience"
                          name="experience_max"
                          {...register("experience_max", {
                            required: "Maximum experience required",
                            validate: [
                              {
                                notExceed50: value => {
                                  if (value && parseInt(value) > 50) {
                                    return "Maximum relevant experience cannot exceed 50";
                                  }
                                  return true;
                                }
                              },
                              value => validateExperience(watch('experience_min'), value)
                            ]
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.experience_max,
                          })}
                          onKeyDown={(event) => {
                            const currentValue = event.target.value;
                            const keyPressed = event.key;

                            if (currentValue === '' && keyPressed === '0') {
                              event.preventDefault();
                              return;
                            }

                            if (
                              Number(currentValue + keyPressed) > 50 &&
                              !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End'].includes(keyPressed)
                            ) {
                              event.preventDefault();
                            }

                            // Only allow numeric characters and specific control keys
                            if (!/^\d$/.test(keyPressed) &&
                              !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End'].includes(keyPressed)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors.experience_max && <span className="invalid-feedback">{errors.experience_max.message}</span>}
                      </div>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Skills</Form.Label>

                    <Controller
                      name="skills_id"
                      {...register("skills_id", {
                        required: "skills_id required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.skills_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={skillsData}
                          isMulti
                        />
                      )}
                    />
                  </Form.Group>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Salary Offered (in ₹ / year)</Form.Label>
                    <div className="d-flex main-grp">
                      <div className="d-flex sub-grp">
                        <Form.Label>Min.</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter minimum salary"
                          name="salary_min"
                          {...register("salary_min", {
                            required: "Minimum salary required",
                            validate: value => validateSalary(value, watch('salary_max')),
                            validate: value => isNumeric(value) || "Please enter a valid number"
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.salary_min,
                          })}
                          onKeyDown={(event) => {
                            const key = event.key;

                            if (key === '0' && event.target.selectionStart === 0) {
                              event.preventDefault();
                            }


                            if (!/^\d$/.test(key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End'].includes(key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors.salary_min && <span className="invalid-feedback">{errors.salary_min.message}</span>}
                      </div>

                      <div className="d-flex sub-grp">
                        <Form.Label>Max.</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter maximum salary"
                          name="salary_max"
                          {...register("salary_max", {
                            required: "Maximum salary required",
                            validate: value => validateSalary(watch('salary_min'), value),
                            validate: value => isNumeric(value) || "Please enter a valid number"
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.salary_max,
                          })}
                          onKeyDown={(event) => {
                            const key = event.key;
                            if (key === '0' && event.target.selectionStart === 0) {
                              event.preventDefault();
                            }

                            if (!/^\d$/.test(key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End'].includes(key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors.salary_max && <span className="invalid-feedback">{errors.salary_max.message}</span>}
                      </div>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Application Deadline:</Form.Label>
                    <Form.Control
                      type="date"
                      placeholder="Enter "
                      name="deadline"
                      min={new Date().toISOString().split('T')[0]}
                      {...register("deadline", {
                        required: "deadline required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.deadline,
                      })}
                    />
                  </Form.Group>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-8 col-md-12 col-12">
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Number of Vacancy</Form.Label>
                        <Form.Control
                          type="text" placeholder="Enter "
                          name="number_of_vacancy"
                          {...register("number_of_vacancy", {
                            required: "number of vacancy required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.number_of_vacancy,
                          })}
                          onKeyDown={(event) => {
                            const key = event.key;

                            if (key === '0' && event.target.selectionStart === 0) {
                              event.preventDefault();
                            }

                            if (!/^\d$/.test(key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End'].includes(key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-md-6 col-12">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Shift</Form.Label>

                        <Controller
                          name="shift_id"
                          {...register("shift_id", {
                            required: "shift_id required",
                          })}
                          control={control}
                          render={({ field }) => (
                            <Select
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.industry_id
                                    ? "red"
                                    : baseStyles,
                                }),
                              }}
                              {...field}
                              options={shiftData}
                              isMulti
                            />
                          )}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-12 col-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label className="mb-3">Choose Gender</Form.Label>

                    <div>
                      <Form.Check
                        inline
                        label="Male"
                        type="radio"
                        value="male"
                        {...register('gender', { required: 'Please select a gender' })}
                        isInvalid={!!errors.gender}
                      />
                      <Form.Check
                        inline
                        label="Female"
                        type="radio"
                        value="Female"
                        {...register('gender', { required: 'Please select a gender' })}
                        isInvalid={!!errors.gender}
                      />
                      <Form.Check
                        inline
                        label="Other"
                        type="radio"
                        value="other"
                        {...register('gender', { required: 'Please select a gender' })}
                        isInvalid={!!errors.gender}
                      />
                      {errors.gender && <Form.Control.Feedback type="invalid">{errors.gender.message}</Form.Control.Feedback>}
                    </div>
                  </Form.Group>
                </div>
                <div className="col-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Job Description(min 300 words):</Form.Label>

                    {/* <JoditEditor
                      value={descriptionValue || ''}
                      onBlur={(newContent) => {
                        setValue('description', newContent);
                        trigger('description');
                      }}

                    /> */}
                    <Controller
                      name="description"
                      control={control}
                      rules={{
                        required: 'Description is required.',
                        minLength: { value: 307, message: 'Description must be at min 300 characters long.' }
                      }} // Correct the maxLength rule
                      render={({ field: { onChange, onBlur, value } }) => (
                        <JoditEditor
                          value={value}
                          onBlur={() => {
                            onBlur();
                            trigger('description');
                          }}
                          onChange={onChange}
                        />
                      )}
                    />

                    {errors.description && (
                      <span className="text-danger">
                        {errors.description.message}
                      </span>
                    )}
                  </Form.Group>

                </div>
                <div className="col-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Roles and responsibilities:</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={5}
                      placeholder="Enter Roles and responsibilities:"
                      name="roles_responsibities"

                      {...register("roles_responsibities", {
                        required: "roles responsibities required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.roles_responsibities,
                      })}
                    />
                  </Form.Group>
                </div>
                <div className="col-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Language Preferences</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Language Preferences"
                      name="language_preference"

                      {...register("language_preference", {
                        required: "language preference required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.language_preference,
                      })}
                    />
                  </Form.Group>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-6 col-md-6 col-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Country</Form.Label>
                    <Controller
                      name="country_id"
                      {...register("country_id", {
                        required: "country_id required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.country_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={CountryData}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption);
                            GetAllStates(selectedOption.value);
                            setValue("country_id", selectedOption);
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-6 col-md-6 col-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>State</Form.Label>
                    <Controller
                      name="state_id"
                      {...register("state_id", {
                        required: "state_id required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.state_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={states}
                          onChange={(selectedOption) => {
                            field.onChange(selectedOption);
                            GetAllCities(selectedOption.value);
                            setValue("state_id", selectedOption);
                          }}
                        />
                      )}
                    />
                  </Form.Group>
                </div>
                <div className="col-xxl-2 col-xl-2 col-lg-6 col-md-6 col-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>City</Form.Label>
                    <Controller
                      name="city_id"
                      {...register("city_id", {
                        required: "city_id required",
                      })}
                      control={control}
                      render={({ field }) => (
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.city_id
                                ? "red"
                                : baseStyles,
                            }),
                          }}
                          {...field}
                          options={city}
                        />
                      )}
                    />
                  </Form.Group>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-12">
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Upload Company Logo</Form.Label>
                        <br></br>

                        <Form.Group>
                          <Form.Control
                            className={classNames("", {
                              "is-invalid": errors?.company_logo,
                            })}
                            type="file"
                            {...register("company_logo", {

                              validate: async (value) => {
                                if (typeof value !== "string") {

                                  // const fileTypes = ["jpg", "png", "jpeg"];
                                  // const fileType = value[0].name?.split(".")[1];

                                  // if (!fileTypes.includes(fileType)) {
                                  //   return `please upload a valid file format. (${fileTypes})`;
                                  // }

                                  // const sizes = await getDimension(value[0]);
                                  // if (sizes.width !== 1296 && sizes.height !== 396) {
                                  //   return "Image width and height must be 1296 px and 396 px";
                                  // }

                                  // const fileSize = Math.round(value[0].size / 1024);
                                  // if (fileSize > 1000) {
                                  //   return "file size must be lower than 1000kb";
                                  // }
                                }
                              },
                            })}
                            accept=".jpg, .jpeg, .png"
                          />
                        </Form.Group>
                        {errors.company_logo && (
                          <span className="text-danger">
                            {errors.company_logo.message}
                          </span>
                        )}

                      </Form.Group>
                    </div>
                    <div className="col-md-6 col-12">
                      <Form.Group controlId=""><Form.Label>  Company Logo Preview</Form.Label></Form.Group>
                      <div className="img-div">

                        <div className="main-form-section mt-3">

                          {typeof getValues("company_logo") == "string" ? (
                            <div className="image-preview-container">
                              <img
                                src={IMG_URL + getValues("company_logo")}
                                alt="Preview"
                                className="image-preview"
                                style={{ width: "100px", height: "50px" }}
                              />
                            </div>
                          ) : (
                            imageFile &&
                            imageFile?.length > 0 && (
                              <div className="image-preview-container">
                                <img
                                  // src={URL.createObjectURL(getValues("image")[0])}
                                  src={URL?.createObjectURL(imageFile[0])}
                                  alt="Preview"
                                  className="image-preview"
                                  style={{ width: "100px", height: "50px" }}
                                />
                              </div>
                            )
                          )}

                        </div>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>Address:</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={1}
                      placeholder="Enter Address"
                      name="address"

                      {...register("address", {
                        required: "address required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.address,
                      })}
                    />
                  </Form.Group>
                </div>
              </div>


              <div className="contact-form-div">
                <div className="heading-div">
                  <h6>Contact:</h6>
                </div>
                {/* <Form> */}
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Company Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Company Name"
                    controlId="exampleForm.ControlInput1"
                    name="contact_company_name"

                    {...register("contact_company_name", {
                      required: "contact company name required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.contact_company_name,
                    })}
                    onKeyDown={(event) => {
                      if (!/[A-Z-a-z ]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Group>
                <div className="row bottom-row">
                  <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
                    <Form.Group
                    // className="mb-3"

                    >
                      <Form.Label>Recruiter Contact Number:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Number"
                        controlId="exampleForm.ControlInput1"
                        name="contact_number"

                        {...register("contact_number", {
                          required: "contact number required",
                          pattern: {
                            value: /^\d{10}$/,
                            message: "Phone number must be a 10-digit number",
                          },
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.contact_number,
                        })}
                        onKeyDown={(event) => {
                          const key = event.key;
                          const inputValue = event.target.value;

                          // Allow only digits and specified control keys
                          if (!/^\d$/.test(key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End'].includes(key)) {
                            event.preventDefault();
                          }

                          // Allow Backspace, Delete, ArrowLeft, ArrowRight, Tab, Home, End
                          if (
                            (inputValue.length >= 10 && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End'].includes(key)) ||
                            (inputValue.length === 10 && /^\d$/.test(key))
                          ) {
                            event.preventDefault();
                          }
                        }}
                      />
                      {errors?.contact_number && (
                        <span className="text text-danger">
                          {errors.contact_number.message}
                        </span>
                      )}

                    </Form.Group>
                  </div>
                  <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
                    <Form.Group
                    // className="mb-3"

                    >
                      <Form.Label>Recruiter Email:</Form.Label>
                      <Form.Control
                        type="email" placeholder="Enter Mail"
                        name="email"

                        {...register("email", {
                          required: "email required",
                          pattern: {
                            value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                            message: "Invalid E-Mail Address",
                          },
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.email,
                        })}

                      />
                      {errors?.email && (
                        <span className="text text-danger">
                          {errors.email.message}
                        </span>
                      )}
                    </Form.Group>
                  </div>
                </div>
                {/* </Form> */}
              </div>
            </div>
            <div className="save-btn-div ">
              <button
                className="save-btn"
                // onClick={handleNext}
                onClick={handleSubmit(onSubmit)}
                type="button"
              >
                Save & Next
              </button>
            </div>

          </Form>
        </div>
      </section>
    </>
  );
}

export default Step1;