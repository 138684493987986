import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';
import '../FinanceLoanCalci.css'

const FifthTab = () => {
  const [grossIncome, setGrossIncome] = useState(100000);
  const [tenure, setTenure] = useState(1);
  const [interestRate, setInterestRate] = useState(0.5);
  const [existingEMI, setExistingEMI] = useState(0);

  const handleChange = (setValue) => (event) => {
    setValue(event.target.value);
  };

  const handleChangeGrossIncome = (event) => {
    let input = event.target.value;
    if (/^\d*$/.test(input)) {
      if (input > 100000000) {
        input = 100000000;
      } else if (parseInt(existingEMI) > input) {
        alert("We are unable to show you any offers currently as your current EMIs amount is very high. " +
          "You can go back and modify your inputs if you wish to recalculate your eligibility.");
        input = existingEMI;
      }
      setGrossIncome(input);
    }
  };

  const handleChangeTenure = (event) => {
    let input = event.target.value;
    if (/^\d*$/.test(input)) {
      if (input > 30) {
        input = 30;
      }
      setTenure(input);
    }
  };

  const handleChangeInterestRate = (event) => {
    let input = event.target.value;
    if (/^\d*\.?\d*$/.test(input)) {
      if (input > 30) {
        input = 30;
      }
      setInterestRate(input);
    }
  };

  const handleChangeExistingEmi = (event) => {
    let input = event.target.value;
    if (/^\d*$/.test(input)) {
      if (input > 1000000) {
        input = 1000000;
      } else if (parseInt(grossIncome) < input) {
        alert("We are unable to show you any offers currently as your current EMIs amount is very high. " +
          "You can go back and modify your inputs if you wish to recalculate your eligibility.");
        input = grossIncome;
      }
      setExistingEMI(input);
    }
  };

  const calculateEligibility = () => {
    // Assuming 60% of gross income is available for loan
    const maximumLoanAmount = (grossIncome * 0.6) - existingEMI;
    return maximumLoanAmount.toLocaleString(); // Convert to locale string for display
  };

  const calculateEMI = () => {
    const monthlyInterestRate = (interestRate / 12) / 100; // Monthly interest rate
    const tenureInMonths = tenure * 12; // Convert tenure from years to months for calculation

    // Calculate maximum loan amount based on gross income and existing EMI
    const loanAmount = (grossIncome * 0.6) - existingEMI; // Adjust as per your eligibility calculation

    // Calculate EMI using the formula
    const emi = loanAmount * monthlyInterestRate / (1 - Math.pow(1 + monthlyInterestRate, -tenureInMonths));

    return emi.toFixed(2); // Return EMI rounded to 2 decimal places
  };

  return (
    <section className='home-eligibility'>
      <div>
        <div className="range-div">
          <div className="main-ranges-div">
            <div className="price-div">
              <p>Gross Income (Monthly)</p>
              <div>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="500000"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={grossIncome}
                    onChange={handleChangeGrossIncome}
                  />
                  <InputGroup.Text id="basic-addon2">₹</InputGroup.Text>
                </InputGroup>
              </div>
            </div>
            <div className="price-div mt-5">
              <p>₹10,000</p> <p>₹10 Cr</p>
            </div>
            <div className="range-bar-container">
              <p
                className="range-value"
                style={{
                  left: `calc(${(grossIncome / 100000000) * 98}% - 30px)`,
                }}
              >
                {/* ₹ {grossIncome} */}
              </p>
              <input
                type="range"
                min="10000"
                max="100000000"
                step={10000}
                value={grossIncome}
                onChange={handleChangeGrossIncome}
                className="range-bar"
              />
              <div
                className="range-bar-color"
                style={{
                  width: `${(grossIncome / 100000000) * 98}%`,
                }}
              ></div>
            </div>
          </div>

          <div className="main-ranges-div">
            <div className="price-div mt-5">
              <p>Tenure (Years)</p>
              <div>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="1"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={tenure}
                    onChange={handleChangeTenure}
                  />
                  <InputGroup.Text id="basic-addon2">Yr</InputGroup.Text>
                </InputGroup>
              </div>
            </div>
            <div className="price-div">
              <p>1</p> <p>30</p>
            </div>
            <div className="range-bar-container">
              <p
                className="range-value"
                style={{
                  left: `calc(${((tenure - 1) / (30 - 1)) * 98}% - 30px)`,
                }}
              >
                {/* {tenure} yr */}
              </p>
              <input
                type="range"
                min="1"
                max="30"
                value={tenure}
                onChange={handleChangeTenure}
                className="range-bar"
              />
              <div
                className="range-bar-color"
                style={{
                  width: `${((tenure - 1) / (30 - 1)) * 98}%`,
                }}
              ></div>
            </div>
          </div>

          <div className="main-ranges-div">
            <div className="price-div mt-5">
              <p>Interest Rate (% P.A.)</p>
              <div>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="7"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={interestRate}
                    onChange={handleChangeInterestRate}
                  />
                  <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                </InputGroup>
              </div>
            </div>
            <div className="price-div">
              <p>7</p> <p>30</p>
            </div>
            <div className="range-bar-container">
              <p
                className="range-value"
                style={{
                  left: `calc(${((interestRate - 7) / (30 - 7)) * 98}% - 30px)`,
                }}
              >
                {/* {interestRate} % */}
              </p>
              <input
                type="range"
                min="7"
                max="30"
                step="0.1"
                value={interestRate}
                onChange={handleChangeInterestRate}
                className="range-bar"
              />
              <div
                className="range-bar-color"
                style={{
                  width: `${((interestRate - 7) / (30 - 7)) * 98}%`,
                }}
              ></div>
            </div>
          </div>

          <div className="main-ranges-div">
            <div className="price-div mt-5">
              <p>Other EMIs (Monthly)</p>
              <div>
                <InputGroup className="mb-3">
                  <Form.Control
                    placeholder="0"
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    value={existingEMI}
                    onChange={handleChangeExistingEmi}
                  />
                  <InputGroup.Text id="basic-addon2">₹</InputGroup.Text>
                </InputGroup>
              </div>
            </div>
            <div className="price-div">
              <p>₹0</p> <p>₹10 L</p>
            </div>
            <div className="range-bar-container">
              <p
                className="range-value"
                style={{
                  left: `calc(${(existingEMI / 1000000) * 98}% - 30px)`,
                }}
              >
                {/* ₹ {existingEMI} */}
              </p>
              <input
                type="range"
                min="0"
                max="1000000"
                value={existingEMI}
                onChange={handleChangeExistingEmi}
                className="range-bar"
              />
              <div
                className="range-bar-color"
                style={{
                  width: `${(existingEMI / 1000000) * 98}%`,
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="calculation-div mt-5">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 ">
              <div className="loan-table">
                <Table>
                  <thead>
                    <tr>
                      <th>Your Home Loan Eligibility</th>
                      <th>Your Home Loan EMI will be</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>₹{calculateEligibility()}/-</td>
                      <td>₹{calculateEMI()} <span>/monthly</span></td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FifthTab;
