import React, { useState, useContext, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import CommonModal from "../../CommonModal/CommonModal";
import "./ProfileSumModal.css";
import { Link } from "react-router-dom";
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import { allSkills } from "../../../../../../utils/apis/common/Common";
import { getSkills, skillsPost } from "../../../../../../utils/apis/jobseekar/jobseekar";
import { useNavigate } from 'react-router-dom';
import { Context } from "../../../../../../utils/context";
import { RegxExpression } from "../../../../../../utils/apis/common/Common";
import classNames from "classnames"



const ProfileSumModal = ({ show, onHide, jobSeekarDetailsID }) => {

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();


  const { postData, getData, Select2Data, IMG_URL } = useContext(Context);


  const navigate = useNavigate();

  const onSummarySubmit = async (data) => {
    data.personal_details_id = jobSeekarDetailsID;
    const res = await postData("/job-portal/job-seeker/profile/profile-details/profile-summary", data);
    if (res?.success) {
      // navigate("/job-portal/jobseeker/jobseeker-edit-profile");
      onHide();
    }
  }

  const getSummary = async () => {
    const res = await getData(`/job-portal/job-seeker/profile/profile-details/profile-summary/${jobSeekarDetailsID}`)
    if (res?.success) {
      console.log(res?.data);
      reset(res?.data);
    }
  }

  const validateSummary = (value) => {
    if (value && value.trim().length < 50) {
      return 'Profile Summary must be at least 50 characters';
    }
    return true;
  };
  useEffect(() => {
    getSummary();
  }, [jobSeekarDetailsID])

  return (
    <>
      <CommonModal show={show} onHide={onHide}>
        <Modal.Body>
          <div className="heading-holder">
            <h6>Profile Summary</h6>
          </div>
          <Form onSubmit={handleSubmit(onSummarySubmit)}>
            <div className="personal-details-form ">
              {/* form-holder */}
              <div className="details-form-holder">
                <div className="form-container">
                  {/*  Tell recruiters */}
                  <div className="">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="project-summary">
                          <h1>
                            Your Profile Summary should mention the highlights of
                            your career and education, what your professional
                            interests are, and what kind of a career you are
                            looking for. Write a meaningful summary of more than
                            50 characters.
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  Job Responsibilities: */}
                  <div className="">
                    <div className="row">
                      <div className="col-md-12">
                        <div className=" ">
                          <Form.Group controlId="">
                            <Form.Control
                              as="textarea"
                              name="summary"
                              placeholder="Type your profile summary, more than 50 words..."
                              rows={6}
                              type="text"
                              {...register("name", {
                                required: "Profile Summary is Required",
                                validate: validateSummary
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.name,
                                "is-valid": getValues("name"),
                              })}
                            // onKeyDown={(event) => {
                            //   if (!RegxExpression.name.test(event.key)) {
                            //     event.preventDefault();
                            //   }
                            // }}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* close save btn */}
            <div className="close-save-btn-holder form-field">
              <div className="Close-btn-holder">
                <Button className="close-btn" onClick={onHide}>
                  Cancel
                </Button>
              </div>

              <div className="apply-btn-holder">
                {/* <Link to={"/job-portal/jobseeker/jobseeker-edit-profile"}> */}
                <Button className="apply_now_btn" type="submit">Save & Close</Button>
                {/* </Link> */}
              </div>
            </div>
          </Form>
        </Modal.Body>
      </CommonModal>
    </>
  );
};

export default ProfileSumModal;
