import React, { useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import "../../../Jp-Forms/JPForm.css";
import "../JPSignInForm.css";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { faEye, faEyeSlash, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../../../../utils/context";
import Cookies from "js-cookie";


library.add(fas);

function JPJobSeekerSignInForm({ showEmpoyerSignIn }) {
  const [showPassword, setShowPassword] = useState(false);
  const [userid, setUserId] = useState();
  const [emailerror, setemailErrors] = useState("");
  const [userError, setUserError] = useState("");


  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const { getData, postData, IMG_URL, setSignin, setUsertype, setUserData } = useContext(Context);


  const navigate = useNavigate();
  const onSubmit = async (data) => {
    data.users_type_id = showEmpoyerSignIn;
    if (data.users_type_id) {
      if (data) {
        console.log(data);
        const res = await postData(`/job-portal/customer-management/users-sing-in`, data);
        if (res?.success) {
          Cookies.set("llp_web_security", res.data.token, { expires: 1 });
          Cookies.set("jobseekar_id", res?.data?.id);
          setSignin(true);
          setUserData(res?.data);
          setUsertype(res?.data?.type);
          navigate("/job-portal/jobseeker/jobseeker-edit-profile");
        } else {
          setemailErrors(res?.message);
        }
      }
      else {
        setError("email", {
          message: "Please Enter Email",
        });
        setError("password", {
          message: "Please Enter password",
        });
      }
    } else {
      setUserError({
        message: "Please Select Your Account Type",
      });
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <Form className="form-field" onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="row">
            <div className="col-md-12">
              <Form.Group controlId="">
                <Form.Label className="">Email</Form.Label>
                <Form.Control
                  type="text"
                  name="Email"
                  placeholder="Enter Your Email Id"
                  {...register("email", {
                    required: "Email Id required",
                    pattern: {
                      value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                      message: "Invalid email address",
                    }
                  })}
                  className={classNames("", {
                    "is-invalid": errors?.email,
                  })}
                />
                {errors.email && (
                  <span className="text-danger">
                    {errors.email.message}
                  </span>
                )}
              </Form.Group>
            </div>
          </div>
        </div>

        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-field">
                <Form.Group controlId="Password">
                  <Form.Label className="">Password</Form.Label>

                  <div className="password-input-container">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      name="Password"
                      placeholder="Enter Password"
                      className={classNames("", {
                        "is-invalid": errors?.password,
                      })}
                      {...register("password", {
                        required: "Password is required",
                      })}
                    />
                    <div
                      className="password-icon-holder"
                      onClick={togglePasswordVisibility}
                    >
                      <FontAwesomeIcon
                        className="password-icon"
                        icon={showPassword ? faEye : faEyeSlash}
                      />
                    </div>
                  </div>
                  {errors.password && (
                    <span className="text-danger">
                      {errors.password.message}
                    </span>
                  )}
                </Form.Group>
              </div>
            </div>
          </div>
          {emailerror && (
            <span className="text text-danger">{emailerror}</span>
          )}
        </div>
        {userError && (
          <span className="text text-danger">{userError}</span>
        )}

        {/* {errors.user && (
          <span className="text-danger">
            {errors.user.message}
          </span>
        )} */}
        <div>
          <div className="row">
            <div className="col-md-6">
              <div className="form-field check_box">
                <Form.Group controlId="RememberMe">
                  <Form.Check
                    type="checkbox"
                    label="Remember Me"
                    name="RememberMe"
                  />
                </Form.Group>
              </div>
            </div>

            <div className="col-md-6">
              <div className="form-field">
                <Link to={"/job-portal/jpforget"}>
                  <p className=" forgot-password">Forgot Password?</p>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="form-field">
          <div className="apply-btn-holder ">
            {/* <Link to={"/job-portal/jobseeker/dashboard"}> */}
            <Button className="apply_now_btn" type="submit">Sign In </Button>
            {/* </Link> */}
          </div>
        </div>
      </Form>

      <div className="dont-have-An-Account">
        <p>
          Don't have an account?{" "}
          <Link to={"/job-portal/register"}>
            <span>Register Here!</span>
          </Link>
        </p>
      </div >
    </>
  );
}

export default JPJobSeekerSignInForm;
