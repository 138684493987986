import React from 'react'
import Jp_interview_results_cards from '../../../JP-Common-Elements/JP-Cards/Jp_interview_results_cards/Jp_interview_results_cards'

const Jp_interview_component = () => {
  return (
    <>
         <section className='Jp_applied_component_sec'>
        <div className='row mb-5'>

          <div className='col-xl-9 col-lg-8'>

            <div className='tabs-section'>
                <Jp_interview_results_cards />
            </div>
          </div>



          <div className='col-xl-3 col-lg-4 col-md-6 col-sm-8 mx-auto'>
            <div className='addvertisement-div mb-4'>
              <img className='add-banner' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/advertisement/ban1.png'} />
              <button className='know-more-bt'>Know More</button>
            </div>
            <div className='addvertisement-div'>
              <img className='add-banner' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/advertisement/ban1.png'} />
              <button className='know-more-bt'>Know More</button>
            </div>
          </div>
          
        </div>
        </section>
    </>
  )
}

export default Jp_interview_component