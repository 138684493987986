import React, { useState, useEffect, useContext } from "react";
import "../SkillQuestions/AllQuestion.css";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames"
import Form from "react-bootstrap/Form";
import { Button } from "react-bootstrap";
import { Context } from "../../../../../utils/context";
import { useNavigate } from "react-router-dom";


const Question1 = ({ getQuestionsData, jobApplyID, backStep, nextStep, totalSteps, questionIndex }) => {


  const { postData, getData, Select2Data, IMG_URL } = useContext(Context);

  const navigate = useNavigate();
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue
  } = useForm();

  const onSubmit = async (data) => {
    if (answerId) {
      data.quetion_id = getQuestionsData?.job_question?.id;
      data.job_apply_id = jobApplyID;
      data.answerId = answerId
    }
    else {
      data.quetion_id = getQuestionsData?.job_question?.id;
      data.job_apply_id = jobApplyID;
    }
    const res = await postData(`/job-portal/job-seeker/applied-job/applied-question`, data);
    if (res?.success) {
      if (questionIndex == totalSteps - 1) {
        navigate("/job-portal/jobseeker/dashboard");
      } else {
        nextStep();
        reset();
      }
    }
  };


  const onLastSubmit = async (data) => {
    if (answerId) {
      data.quetion_id = getQuestionsData?.job_question?.id;
      data.job_apply_id = jobApplyID;
      data.answerId = answerId
    }
    else {
      data.quetion_id = getQuestionsData?.job_question?.id;
      data.job_apply_id = jobApplyID;
    }
    const res = await postData(`/job-portal/job-seeker/applied-job/applied-question-last`, data);
    if (res?.success) {
      navigate("/job-portal/jobseeker/dashboard");
    }
  };


  const [answerId, setAnswerID] = useState();
  const getQuestions = async () => {
    const res = await getData(`/job-portal/job-seeker/applied-job/get-apply-quetion/${jobApplyID}/${getQuestionsData?.job_question?.id}`);
    if (res?.success) {
      setAnswerID(res?.data?.id)
      reset(res?.data);
      if (res?.data === null) {
        setValue('answer', '')
      }
    }
  };

  useEffect(() => {
    getQuestions();
  }, [getQuestionsData]);

  return (
    <>
      <section className="Questions-sec">
        <div className="container p-0">
          <Form>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>
                {getQuestionsData?.job_question?.question}
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={1}
                type="text"
                name="answer"
                placeholder="Enter Answer"
                {...register("answer", {
                  required: "Answer is Required",
                })}
                className={classNames("", {
                  "is-invalid": errors?.answer,
                })}
              />
            </Form.Group>
          </Form>
        </div>
      </section>
      <div className="navigation-buttons">
        <div className="apply-btn-holder">
          <Button className="back-btn" type="button" onClick={backStep}>
            Back
          </Button>
        </div>

        <div className="apply-btn-holder">
          {questionIndex == totalSteps - 1 ? (
            <Button className="back-btn" type="button" onClick={handleSubmit(onLastSubmit)}>
              Done
            </Button>
          ) : (
            <Button className="apply_now_btn" type="button" onClick={handleSubmit(onSubmit)}>

              Next
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default Question1;
