import React, { useState, useContext, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../EmpAccountManagement.css';
import ResetModal from '../../../../JP-Common-Elements/Jp-modals/ResetModal/ResetModal';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Context } from '../../../../../../utils/context';
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";

const ChangePhone = ({ toggleBack }) => {

    const [mobileVerify, setMobileVerify] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [modalEmail, setModalEmail] = useState(false);
    const [phone, setPhone] = useState("in");
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const [otpErr, setOtpErr] = useState("");
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [verifyStat, setVerifyStat] = useState(true);
    const [isTimerExpired, setIsTimerExpired] = useState(false);
    const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [contactError, setContactError] = useState();
    const [otpSent, setOtpSent] = useState(false);
    const { getData, postData, IMG_URL, signin, usertype, userdata } = useContext(Context);

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleGetOTP = async () => {
        if (getValues("contact_no").length === 12) {
            const contact_no = getValues("contact_no")
            const res = await postData(`/without-login/verify-otp/otp-send`, { contact_no });
            if (res?.success) {
                setShowOtpInput(true);
                setSeconds(90);
                setOtpSent(true);
                setContactError("");
                clearErrors();
            }
        } else {
            setError("contact_no", {
                message: "Please enter Valid mobile number",
            });
            setContactError("Please enter Valid mobile number")

        }

    };

    const handlePhoneNumberChange = (value) => {
        setPhoneNumber(value);
        if (value.length >= 12 && value.length <= 12) {
            setIsPhoneNumberValid(true);
        } else {
            setIsPhoneNumberValid(false);
        }
    };

    const handleOtpChange = (index, value) => {

        if (!isNaN(value) && value !== "") {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (index < 3) {
                setVerifyStat(true);
                document.getElementById(`otpInput-${index + 1}`).focus();
            } else {
                setVerifyStat(false);
            }
        } else if (value === "") {

            setVerifyStat(true);
            const newOtp = [...otp];
            newOtp[index] = "";
            setOtp(newOtp);

            if (index > 0) {
                document.getElementById(`otpInput-${index - 1}`).focus();
            }
        }
    };


    const [otpButton, setOtpButton] = useState(false);
    // const verifyOTP = async (otp) => {
    //     if (phoneNumber) {
    //         const contact_no = phoneNumber;
    //         const res = await postData(`/without-login/verify-otp/verify-otp`, { contact_no, otp });
    //         if (res?.success) {
    //             setOtpButton(true)
    //             setMobileVerify(true);
    //             setShowOtpInput(false);

    //             setIsPhoneNumberValid(true);
    //             clearErrors();
    //             setSeconds(90);
    //         } else {
    //             setOtpErr(res.message);
    //         }
    //     }
    //     else {
    //         setShowOtpInput(true);
    //     }
    // }

    const verifyOTP = async () => {
        // if (phoneNumber) {
        const contact_no = getValues("contact_no")
        const res = await postData(`/without-login/verify-otp/verify-otp`, { contact_no, otp: otp.join("") });
        if (res?.success) {
            setMobileVerify(true);
            setShowOtpInput(false);
            // setIsPhoneNumberValid(true);
            clearErrors();
            setSeconds(90);
        } else {
            setOtpErr(res.message);
        }
        // }
    }
    const onPhoneChange = async (data) => {
        data.user_id = userdata?.id;
        const res = await postData(`/job-portal/employer/setting/change-phone/${data.user_id}`, data)
        if (res?.success) {
            setModalEmail(true);
            setTimeout(() => {
                setModalEmail(false);
                toggleBack();
            }, 3000)
        }
    }

    useEffect(() => {
    }, []);
    return (
        <>
            <Form className="form-field" onSubmit={handleSubmit(onPhoneChange)}>
                <div className='Number-sec inner-content form-container'>
                    <Form.Group className=" form-div mb-4" controlId="exampleForm.ControlInput1">
                        <Form.Label className="required">Change Phone Number</Form.Label>
                        {/* <Controller
                            name="contact_no"
                            control={control}
                            render={({ field }) => (
                                <PhoneInput
                                    country="in"
                                    name="contact_no"
                                    value={field.value}
                                    onChange={(value) => handlePhoneNumberChange(value)}
                                    placeholder="Enter Mobile number"
                                    inputStyle={{
                                        borderColor: errors.contact_no ? "red" : mobileVerify ? "green" : "",
                                    }}
                                //   disabled={mobileVerify}
                                />
                            )}
                            rules={{ required: "Mobile Number is required" }}
                        /> */}
                          <Controller
                                name="contact_no"
                                control={control}
                                render={({ field }) => (
                                    <div style={{ position: 'relative' }}>
                                        <PhoneInput
                                            country="in"
                                            name="contact_no"
                                            value={field.value}
                                            onChange={(value) => field.onChange(value)}
                                            placeholder="Enter Mobile number"
                                            inputStyle={{
                                                borderColor: errors.contact_no ? "red" : mobileVerify ? "green" : "",
                                            }}
                                            disabled={mobileVerify}
                                            min={10}
                                        />
                                    </div>
                                )}
                                rules={{ required: "Contact No Requird" }}
                            />
                        {/* <Controller
                            name="contact_no"
                            control={control}
                            render={({ field }) => (
                                <PhoneInput
                                    country={"in"}
                                    name="contact_no"
                                    value={field.value}
                                    onChange={(value) => field.onChange(value)}
                                    placeholder="Enter Mobile number"
                                    styles={{
                                        control: (baseStyles) => ({
                                            ...baseStyles,
                                            borderColor: errors.contact_no
                                                ? "red"
                                                : baseStyles.borderColor,
                                        }),
                                    }}
                                // disabled={mobileVerify}
                                />
                            )}
                        /> */}

                        {/* <div className='button-div'>
                            <button className='verify' type='button' name="getop" onClick={() => handleGetOTP()}>Get OTP</button>
                        </div> */}
                        {/* <div className='button-div'>
                            {otpButton === false && (
                                <button className='verify' type='button' name="getop" onClick={() => handleGetOTP()}>Get OTP</button>
                            )}
                        </div> */}
                          {!otpSent && (
                                <div className='button-div'>
                                    <button className='verify' type='button' name="getop" onClick={() => handleGetOTP()}>Get OTP</button>
                                </div>
                            )}
                    </Form.Group>
                    {errors.contact_no && (
                        <span className="text-danger">{errors.contact_no.message}</span>
                    )}
                    <p className="otp text text-danger" style={{ fontSize: '11px' }}>
                        {contactError}
                    </p>
                    {showOtpInput && (
                        <div className="col-md-12">
                            <div className="get-otp form-field">
                                <div className="otp-container">
                                    {/* Input fields for OTP */}
                                    {otp.map((digit, index) => (
                                        <input
                                            className="opt-input"
                                            key={index}
                                            id={`otpInput-${index}`}
                                            type="text"
                                            maxLength="1"
                                            value={digit}
                                            onChange={(e) => handleOtpChange(index, e.target.value)}
                                        />
                                    ))}
                                    <button type="button" className="verifyotp-btnn" onClick={() => verifyOTP(otp.join(""))} disabled={verifyStat || isTimerExpired}>Verify</button>
                                </div>

                                {/* {!showOtpInput && (
                          <p>
                            If you don't receive code,{" "}
                            <span onClick={handleResendCode}>click here</span>
                          </p>
                        )} */}

                                {showOtpInput && (
                                    <p>Enter OTP within{" "}
                                        {`${Math.floor(seconds / 60)}:${(seconds % 60)
                                            .toString()
                                            .padStart(2, "0")}`}{" "}
                                        seconds</p>
                                )}
                                <p className="otp text text-danger">
                                    {otpErr}
                                </p>
                                {/* <p>
                                    Didn't get the OTP? <span onClick={handleGetOTP} style={{ cursor: 'pointer' }}> Click to Resend.</span>
                                </p> */}
                                 {seconds === 0 && (
                                    <p>
                                        Resend OTP ? <span onClick={handleGetOTP} style={{ cursor: 'pointer' }}>Click to Resend</span>
                                    </p>
                                )}
                                {seconds < 0 && (
                                    <p>
                                        <>Resend OTP <span onClick={handleGetOTP} style={{ cursor: 'pointer' }}>Click to Resend</span></>
                                    </p>
                                )}
                            </div>
                        </div>

                    )}
                    <div className='buttons-div mt-5'>
                        <button className='cancel-btn' onClick={toggleBack}>Cancel</button>
                        <button type='submit' className='save-btn' >Save</button>
                        {/* <button type='button' className='save-btn' onClick={() => openEmail("/job-portal/employer/setting")}>Save</button> */}
                    </div>
                    <ResetModal show={modalEmail}
                        onHide={() => setModalEmail(false)}
                        text={"Your Phone Number has been Changed Successfully!"}
                    />
                </div>
            </Form>
        </>
    );
}

export default ChangePhone;
