import React, { useState, useEffect, useContext } from "react";
import './Interview_reject_modal.css'
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Button from 'react-bootstrap/Button';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from '../../../../../utils/context';
import { forReject } from '../../../../../utils/apis/common/Common';
import classNames from "classnames";


const Interview_reject_modal = (props) => {
    const [show, setShow] = useState(false);

    const { control, register, handleSubmit, getValues, setError, clearErrors, formState: { errors }, reset, watch, trigger, setValue } = useForm();

    const { getData, postData, IMG_URL } = useContext(Context);

    const onSubmit = async (formData) => {
        const data = new FormData();
        data.append('interview_id', props.compid);
        data.append('employer_id', props.empId);
        data.append('status', "Rejected");
        data.append('reason', formData.reason);
        const res = await postData(`/job-portal/job-seeker/jobs/reject`, data);
        if (res?.success) {
            props.getproject();
            props.onHide();
        } else {
            console.log("Error");
        }
    };
    return (
        <>
            <section className='Interview_reject_modal_section'>
                <Modal
                    {...props}
                    size="lg"
                    className="Interview_reject_modal_section"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header >
                        <Modal.Title id="contained-modal-title-vcenter">Reason for Rejecting an Interview</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form className="" onSubmit={handleSubmit(onSubmit)}>
                            <FloatingLabel controlId="floatingTextarea2" label="Type a Reason for Rejecting Interview">
                                <Form.Control
                                    as="textarea"
                                    placeholder="Type a Reason for Rejecting Interview"
                                    style={{ height: '150px' }}
                                    {...register("reason", {
                                        required: "Reason is required",
                                    })}
                                    className={classNames("", {
                                        "is-invalid": errors?.name,
                                    })}
                                />

                                <div className='button-divvv text-center mt-3'>

                                    <button className='cancel-bttt' onClick={props.onHide}>Close</button>
                                    <button className='submit-bttt' type='submit'>Submit</button>
                                </div>

                            </FloatingLabel>

                        </Form>
                    </Modal.Body>
                </Modal>
            </section>
        </>
    )
}

export default Interview_reject_modal