import React from "react";
import "./JpHelpCenter.css";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function JpHelpCenter() {
  return (
    <>
      <section className="helpbanner-section">
        <div className="back-image-main">
         
          {/* <div className='backimg'>
                        <img src={process.env.PUBLIC_URL + "/assets/Job-portal/Images/help/helpbanner.png"} className="ban-img" alt="..." />
                    </div> */}
          <div className="backsection">
            <div className="backimg">

              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/Job-portal/Images/help/helpbanner.png"
                }
                className="ban-img"
                alt="..."
              />
              <div className="boarddivmain">
                <Container>
                  <div className="contn-main11">
                    <div className="BacktoCompanies mt-3">
                      <Link to={"/job-portal/jobseeker/dashboard"}>
                        <p className="">Back to Dashboard</p>
                      </Link>
                    </div>
                    <Row>
                      <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                        <div className="contn-main">
                          <div className="big-cont">
                            <h1>
                              Don't Be <br />
                              <span className="span-div">Stuck</span>
                            </h1>
                          </div>
                          <div className="small-contn">
                            <p>
                              Make Your Journey More <br />
                              Exciting with Our Help Experts
                            </p>
                          </div>
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        sm={12}
                        md={7}
                        lg={6}
                        xl={6}
                        xxl={6}
                        className="colsmain my-auto"
                      >
                        <div className=" main-help ">
                          <Row className="">
                            <Col xs={6} sm={6} lg={6} xl={6} xxl={6}>
                              <div className="helpno">
                                <div className="helpnoicn">
                                  <FontAwesomeIcon icon="icn5 fa-solid fa-phone-volume" />
                                </div>
                                <div className="helpnotxt">
                                  <p>Help Desk Number :</p>
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} sm={6} lg={6} xl={6} xxl={6}>
                              <div className="helpnoaddr">
                                <p>0123-456-7890</p>
                              </div>
                            </Col>
                            <Col xs={6} sm={6} lg={6} xl={6} xxl={6}>
                              <div className="helpno">
                                <div className="helpnoicn">
                                  <FontAwesomeIcon icon=" icn5 fa-solid fa-envelope" />
                                </div>
                                <div className="helpnotxt">
                                  <p>E-mail :</p>
                                </div>
                              </div>
                            </Col>
                            <Col xs={6} sm={6} lg={6} xl={6} xxl={6}>
                              <div className="helpnoaddr">
                                <p>inquiry@profcyma.com</p>
                              </div>
                            </Col>
                          </Row>
                        </div>
                        <div className="top-labels my-auto">
                          <div className="label-icns my-auto">
                            <FontAwesomeIcon icon="fa-solid fa-headphones-simple" />
                          </div>
                          <div className="labelcons my-auto">
                            <p>for Support</p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Container>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default JpHelpCenter;
