import React, { useState, useEffect, useContext } from "react";
import '../Jp_applied_job_cards/Jp_applied_job_cards.css'
import { Context } from "../../../../../../src/utils/context";
import { getShortlisted } from "../../../../../utils/apis/common/Common";

const Jp_shortlisted_job_cards = () => {

    const [shortlisted, setShortlisted] = useState();
    const { getData, IMG_URL } = useContext(Context);

    const getproject = async () => {
        const res = await getShortlisted();
        if (res?.success) {
            setShortlisted(res.data);
        } else {
            console.error("Error fetching  data:", res?.error);
        }
    };

    useEffect(() => {
        getproject();
    }, []);


    const shortlisted_job_data = [
        {
            comp_logo: '/assets/Job-portal/Images/companynames/Profcyma.png',
            comp_name: 'Profcyma SOlutions Pvt. Ltd.',
            comp_star: '/assets/Job-portal/Images/icons/rate_star.png',
            comp_ratings: '4.2',
            comp_reviews: '253',
            comp_position: 'Graphic & UI Designer',
            comp_apllied_date: '15/09/23',
            comp_location: 'Pune',
            comp_listing: 'Shortlisted'
        },
        {
            comp_logo: '/assets/Job-portal/Images/companynames/Profcyma.png',
            comp_name: 'Profcyma SOlutions Pvt. Ltd.',
            comp_star: '/assets/Job-portal/Images/icons/rate_star.png',
            comp_ratings: '4.2',
            comp_reviews: '253',
            comp_position: 'Graphic & UI Designer',
            comp_apllied_date: '15/09/23',
            comp_location: 'Pune',
            comp_listing: 'Shortlisted'
        },
        {
            comp_logo: '/assets/Job-portal/Images/companynames/Profcyma.png',
            comp_name: 'Profcyma SOlutions Pvt. Ltd.',
            comp_star: '/assets/Job-portal/Images/icons/rate_star.png',
            comp_ratings: '4.2',
            comp_reviews: '253',
            comp_position: 'Graphic & UI Designer',
            comp_apllied_date: '15/09/23',
            comp_location: 'Pune',
            comp_listing: 'Shortlisted'
        },
        {
            comp_logo: '/assets/Job-portal/Images/companynames/Profcyma.png',
            comp_name: 'Profcyma SOlutions Pvt. Ltd.',
            comp_star: '/assets/Job-portal/Images/icons/rate_star.png',
            comp_ratings: '4.2',
            comp_reviews: '253',
            comp_position: 'Graphic & UI Designer',
            comp_apllied_date: '15/09/23',
            comp_location: 'Pune',
            comp_listing: 'Shortlisted'
        },
    ];


    return (
        <>
            <section className='Jp_applied_job_cards_section'>
                <div className='row'>
                    <div className='col-md-11'>
                        <div className='row'>
                            {shortlisted?.map((data, index) => (
                                <div className='col-md-6 mb-4'>
                                    <div className='applied-job-card'>
                                        <img className='comp-img' src={IMG_URL + data?.job_portal_job?.company_logo} />
                                        <h6 className='comp-name'>{data?.job_portal_job?.company_name}</h6>
                                        <div className='d-flex'>
                                            <img className='star-img' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/icons/rate_star.png'} />
                                            <p className='comp_rate'>{'4.2'}</p>
                                            <p className='comp-review'>( 253 Review)</p>
                                        </div>
                                        <h5 className='comp-position'>{data?.job_portal_job?.job_title?.name}</h5>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <div className='d-flex'>
                                                    <img className='comp-cal' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/icons/calender.png'} />
                                                    <p className='applied-date'>Applied on  {data?.createdAt && (
                                                        <>
                                                            {new Date(data?.createdAt).toLocaleDateString('en-GB', {
                                                                day: '2-digit',
                                                                month: '2-digit',
                                                                year: 'numeric'
                                                            })}
                                                        </>
                                                    )}
                                                    </p>
                                                </div>

                                                <div className='d-flex'>
                                                    <img className='comp-loc' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/icons/card-location.png'} />
                                                    <p className='applied-date'>{data?.job_portal_job?.city?.name} </p>
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <p className='view-resume'>{'Shortlisted'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Jp_shortlisted_job_cards