import React, { useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import { useForm, Controller, SubmitHandler, useFieldArray, } from "react-hook-form";
import classNames from "classnames"
import { RegxExpression } from "../../../../../../../../../utils/apis/common/Common";
import { higherEduPost, getEducation } from "../../../../../../../../../utils/apis/jobseekar/jobseekar";
import { useNavigate } from "react-router-dom"

function Certifications({ jobSeekarDetailsID, EduID, onHide, navItems, activeTab, handleSaveAndNext, handleBack }) {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

    const navigate = useNavigate();

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm();


    const { append, fields, remove } = useFieldArray({
        control,
        name: "data",
    });


    const getEducationDetails = async () => {
        reset({ data: [] });
        const res = await getEducation(EduID);
        if (res?.success) {
            if (res?.data?.length > 0) {
                reset({ data: res?.data });
            } else {
                append({
                    education_level: "",
                    awarded_year: "",
                    descipline: "",
                    authority: "",
                    degree_name: "",
                    collage: "",
                    percentage: "",
                });
            }
        } else {
            append({
                awarded_year: "",
                collage: "",
                percentage: "",
            });
        }
    };


    const onSubmit = async (d) => {
        d.education_id = 6
        d.personal_details_id = jobSeekarDetailsID
        const res = await higherEduPost(d);
        if (res?.success) {
            navigate("/job-portal/jobseeker/jobseeker-edit-profile");
        }
    }

    useEffect(() => {
        getEducationDetails();
    }, [EduID]);



    return (
        <>
            <div className="personal-details-form ">
                {/* form-holder */}
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-container">
                        {/* Education Level: , Awarded Year: */}
                        {fields?.map((item, index) => (
                            <>
                                <div className="">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="">
                                                <Form.Group controlId="Education_Level">
                                                    <Form.Label className="">
                                                        Education Level:
                                                    </Form.Label>

                                                    <Form.Control
                                                        type="text"
                                                        name={`data[${index}].education_level`}
                                                        // defaultValue={item.education_level}
                                                        placeholder="Enter Education Level"
                                                        {...register(`data[${index}].education_level`, {
                                                            required: "Education Level is Required",
                                                        })}
                                                        className={classNames("", {
                                                            "is-invalid": errors?.education_level,
                                                            // "is-valid": getValues("education_level"),
                                                        })}
                                                        onKeyDown={(event) => {
                                                            if (!RegxExpression.name.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="">
                                                <Form.Group controlId="Awarded_Year">
                                                    <Form.Label className="">
                                                        Awarded Year:
                                                    </Form.Label>

                                                    <Form.Control
                                                        type="text"
                                                        name={`data[${index}].awarded_year`}
                                                        placeholder="Enter Awarded Year"
                                                        // defaultValue={item.awarded_year}
                                                        {...register(`data[${index}].awarded_year`, {
                                                            required: "Awarded Year is Required",
                                                            maxLength: 4,
                                                        })}
                                                        className={classNames("", {
                                                            "is-invalid": errors?.awarded_year,
                                                            // "is-valid": getValues("awarded_year"),
                                                        })}
                                                        onKeyDown={(event) => {
                                                            const charCode = event.keyCode || event.which;
                                                            const allowedKeys = /[0-9\b]/; // Allow numeric characters and Backspace (\b)
                                                            if ((!allowedKeys.test(event.key) || event.target.value.length >= 4) && charCode !== 46 && charCode !== 8) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        maxLength={4} // Adding maxlength attribute
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                < div className="" >
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-field">
                                                <Form.Group controlId="Desciplines">
                                                    <Form.Label className="">
                                                        Desciplines:
                                                    </Form.Label>

                                                    <Form.Control
                                                        type="text"
                                                        name={`data[${index}].descipline`}
                                                        placeholder="Enter Desciplines"
                                                        // defaultValue={item.descipline}
                                                        {...register(`data[${index}].descipline`, {
                                                            required: "Desciplines is Required",
                                                        })}
                                                        className={classNames("", {
                                                            "is-invalid": errors?.descipline,
                                                            // "is-valid": getValues("descipline"),
                                                        })}
                                                        onKeyDown={(event) => {
                                                            if (!RegxExpression.name.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Authourity: , Degree Name: */}
                                <div className="">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-field">
                                                <Form.Group controlId="Authourity">
                                                    <Form.Label className="">
                                                        Authourity:
                                                    </Form.Label>

                                                    <Form.Control
                                                        type="text"
                                                        name={`data[${index}].authority`}
                                                        placeholder="Enter Authourity"
                                                        // defaultValue={item.authority}
                                                        {...register(`data[${index}].authority`, {
                                                            required: "Authourity is Required",
                                                        })}
                                                        className={classNames("", {
                                                            "is-invalid": errors?.authority,
                                                            // "is-valid": getValues("authority"),
                                                        })}
                                                        onKeyDown={(event) => {
                                                            if (!RegxExpression.name.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-field">
                                                <Form.Group controlId="Degree_Name:">
                                                    <Form.Label className="">
                                                        Degree Name:
                                                    </Form.Label>

                                                    <Form.Control
                                                        type="text"
                                                        name={`data[${index}].degree_name`}
                                                        placeholder="Enter Degree Name:"
                                                        // defaultValue={item.degree_name}
                                                        {...register(`data[${index}].degree_name`, {
                                                            required: "Degree Name is Required",
                                                        })}
                                                        className={classNames("", {
                                                            "is-invalid": errors?.degree_name,
                                                            // "is-valid": getValues(`data[${index}].degree_name`),
                                                        })}
                                                        onKeyDown={(event) => {
                                                            if (!RegxExpression.name.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*  College / University */}
                                <div className="">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-field ">
                                                <Form.Group controlId="">
                                                    <Form.Label className="">
                                                        College / University:
                                                    </Form.Label>

                                                    <Form.Control
                                                        as="textarea"
                                                        name={`data[${index}].collage`}
                                                        placeholder="Enter College / University Name"
                                                        // defaultValue={item.collage}
                                                        rows={3}
                                                        {...register(`data[${index}].collage`, {
                                                            required: "College / University Name is Required",
                                                        })}
                                                        className={classNames("", {
                                                            "is-invalid": errors?.collage,
                                                            // "is-valid": getValues("collage"),
                                                        })}
                                                        onKeyDown={(event) => {
                                                            if (!RegxExpression.name.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*   Percentages: , Add Education */}
                                <div className="Percentages-Add-Education">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-field mb-lg-0 mb-md-0 mb-sm-3 mb-3">
                                                <Form.Group controlId="Percentages">
                                                    <Form.Label className="">
                                                        Percentages:
                                                    </Form.Label>

                                                    <Form.Control
                                                        type="text"
                                                        accept="decimal"
                                                        name={`data[${index}].percentage`}
                                                        placeholder="Enter Percentages"
                                                        // defaultValue={item.percentage}
                                                        {...register(`data[${index}].percentage`, {
                                                            required: "Percentage is Required",
                                                        })}
                                                        className={classNames("", {
                                                            "is-invalid": errors?.percentage,
                                                            // "is-valid": getValues("percentage"),
                                                        })}
                                                        // onKeyDown={(event) => {
                                                        //     const charCode = event.charCode;
                                                        //     if ((charCode < 48 || charCode > 57) && charCode !== 8) {
                                                        //         event.preventDefault();
                                                        //     }
                                                        // }}
                                                        onKeyDown={(event) => {
                                                            const charCode = event.keyCode || event.which;
                                                            const allowedKeys = /[0-9\b]/; // Allow numeric characters and Backspace (\b)
                                                            if ((!allowedKeys.test(event.key) || event.target.value.length >= 4) && (charCode !== 46 && charCode !== 8)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        maxLength={4}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        {/* 
                                <div className="col-md-6"></div> */}
                                    </div>
                                    {index !== 0 && (
                                        <div className="addremoveadress">
                                            <div className="text-end Addnewadresstxt">
                                                <p className="" onClick={() => remove(index)}>
                                                    Remove Education{" "}
                                                    {/* <FontAwesomeIcon icon="fa-solid fa-minus" /> */}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        ))}
                        <div className="">
                            <div className="row">
                                <div className="col-md-6"></div>
                                <div className="col-md-6">
                                    <div className="Add-education-btn-holder">
                                        <div className="apply-btn-holder">
                                            <Button className="apply_now_btn" type="button" onClick={() =>
                                                append({
                                                    education_level: "",
                                                    awarded_year: "",
                                                    descipline: "",
                                                    authority: "",
                                                    degree_name: "",
                                                    collage: "",
                                                    percentage: "",
                                                })
                                            }>
                                                Add Education
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="close-save-btn-holder form-field mt-5">
                            <div className="Close-btn-holder">
                                <Button className="close-btn" onClick={handleBack}>
                                    Back
                                </Button>
                            </div>

                            <div className="apply-btn-holder">
                                {/* <Link to={"/job-portal/jobseeker/jobseeker-edit-profile"}> */}
                                <Button className="apply_now_btn" type="submit">Save & Next</Button>
                                {/* </Link> */}
                            </div>
                            <div className="Close-btn-holder">
                                <Button className="close-btn" onClick={() => handleSaveAndNext()} >
                                    Next
                                </Button>
                            </div>
                        </div>
                    </div>
                </Form>
            </div>
        </>

    );
}

export default Certifications;
