import {
    getData,
    postData,
    putData,
    editStatusData,
    deleteData,
    getDownloadDataExcel,
} from "../../api";

export const skillsPost = async (data) => {
    try {
        return await postData(`/job-portal/job-seeker/profile/skill-set`, data);
    } catch (error) {
        console.log(error);
    }
};



export const getSkills = async (user_id) => {
    try {
        return await getData(`/job-portal/job-seeker/profile/skill-set/${user_id}`);
    } catch (error) {
        console.log(error);
    }
};


export const higherEduPost = async (data) => {
    try {
        return await postData(`/job-portal/job-seeker/profile/education`, data);
    } catch (error) {
        console.log(error);
    }
};



export const getProfilePicture = async () => {
    try {
        return await getData(`/job-portal/job-seeker/profile/profile-details/profile-image-jobseeker/data`);
    } catch (error) {
        console.log(error);
    }
};

export const getProfilePercentage = async () => {
    try {
        return await getData(`/job-portal/job-seeker/profile/profile-details/profile-percentage`);
    } catch (error) {
        console.log(error);
    }
};


export const getEducation = async (user_id) => {
    try {
        return await getData(`/job-portal/job-seeker/profile/education/${user_id}`);
    } catch (error) {
        console.log(error);
    }
};


export const EmploymentPost = async (data) => {
    try {
        return await postData(`/job-portal/job-seeker/profile/employment-details/details`, data);
    } catch (error) {
        console.log(error);
    }
};

export const getEmployment = async (user_id) => {
    try {
        return await getData(`/job-portal/job-seeker/profile/employment-details/${user_id}`);
    } catch (error) {
        console.log(error);
    }
};


export const AwardPost = async (data) => {
    try {
        return await postData(`/job-portal/job-seeker/profile/employment-details/award`, data);
    } catch (error) {
        console.log(error);
    }
};

export const getAward = async (user_id) => {
    try {
        return await getData(`/job-portal/job-seeker/profile/employment-details/award/${user_id}`);
    } catch (error) {
        console.log(error);
    }
};


export const CarrerPost = async (data) => {
    try {
        return await postData(`/job-portal/job-seeker/profile/career-preference`, data);
    } catch (error) {
        console.log(error);
    }
};

export const getCarrer = async (user_id) => {
    try {
        return await getData(`/job-portal/job-seeker/profile/career-preference/${user_id}`);
    } catch (error) {
        console.log(error);
    }
};