import React, { useRef, useState ,useContext,useEffect} from 'react';
import Container from 'react-bootstrap/Container';
import { Swiper, SwiperSlide } from 'swiper/react';
import "./JpTestimonials.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import { Context } from "../../../../utils/context";
import { EffectCoverflow, Pagination, Autoplay } from 'swiper/modules';
import parse from "html-react-parser";
const JpTestimonials = () => {
    const swiperRef = useRef(null);
    const [swiperInstance, setSwiperInstance] = useState(null);

    const testimonialsData = [
        {
            imgSrc: '/assets/Job-portal/Images/home/testimonial/man.png',
            text: "I wanted to thank you so much for your help with my job search. I secured a fabulous role… It's exactly what I was looking for and I'm so excited to start! I have worked with a number of recruiters throughout my career, and your firm was easily the best.",
            name: "Shweta K.",
            position: "Senior Frontend Developer"
        },
        {
            imgSrc: '/assets/Job-portal/Images/home/testimonial/man.png',
            text: "I wanted to thank you so much for your help with my job search. I secured a fabulous role… It's exactly what I was looking for and I'm so excited to start! I have worked with a number of recruiters throughout my career, and your firm was easily the best.",
            name: "Sakshi  G.",
            position: "Senior Frontend Developer"
        },
        {
            imgSrc: '/assets/Job-portal/Images/home/testimonial/man.png',
            text: "I wanted to thank you so much for your help with my job search. I secured a fabulous role… It's exactly what I was looking for and I'm so excited to start! I have worked with a number of recruiters throughout my career, and your firm was easily the best.",
            name: "Aditya J.",
            position: "Senior Frontend Developer"
        },
        {
            imgSrc: '/assets/Job-portal/Images/home/testimonial/man.png',
            text: "I wanted to thank you so much for your help with my job search. I secured a fabulous role… It's exactly what I was looking for and I'm so excited to start! I have worked with a number of recruiters throughout my career, and your firm was easily the best.",
            name: "Shweta K.",
            position: "Senior Frontend Developer"
        },

    ];

    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }
    };

    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);
    const getDataAll = async () => {
        try {
            const response = await getData(
                `/job-portal/home/testimonials`
            );
            setData(response?.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    useEffect(() => {
        getDataAll();
        // getDataQuestion();
    }, []);


    return (
        <section className='Jp-home-testimonial'>
            <Container>
                <div className='heading'>
                    <h4 className='titles'>Testimonials</h4>
                    <p>Few words from candidates</p>
                </div>
                <div className='row justify-content-center'>
                    <div className='col-xxl-10 col-xl-10 col-lg-11 col-md-12 col-12 '>
                        <div className='jp-home-slider'>
                            <Swiper
                                effect={'coverflow'}
                                grabCursor={true}
                                centeredSlides={true}
                                initialSlide={1}
                                coverflowEffect={{
                                    rotate: 50,
                                    stretch: 0,
                                    depth: 100,
                                    modifier: 1,
                                    slideShadows: false,
                                }}
                                pagination={true}
                                modules={[EffectCoverflow, Pagination]}
                                slideShadows={false}
                                className="mySwiper"
                                breakpoints={{
                                    0: {
                                        slidesPerView: 1,
                                        centeredSlides: false,
                                    },
                                    769: {
                                        slidesPerView: 3,
                                        slidesPerGroup: 1,
                                    },
                                }}
                                onSwiper={(swiper) => setSwiperInstance(swiper)}
                            >
                                {data?.map((testimonial, index) => (
                                    <SwiperSlide key={index}>
                                        <div className='main-card-div'>
                                            <div className='left-div'>
                                                <div className='inner-div'>
                                                    <img className='man-img' src={IMG_URL + testimonial?.image} alt="man" />
                                                </div>
                                                <div className='colan-img-div'>
                                                    <img className='col-img' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/home/testimonial/colan.png'} alt="colan" />
                                                </div>
                                            </div>
                                            <div className='right-div'>
                                               
                                                <p>{parse(testimonial?.description) || ""}</p>
                                                <h6>{testimonial.subtitle}</h6>
                                                <p>{testimonial.title}</p>
                                                
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <div className="main-slider" onClick={() => handleMainSliderClick("prev")}>
                                <div className='main-arrow-back'>
                                    <FontAwesomeIcon icon={['fa-solid', 'angle-left']} className='arrow-back' />
                                </div>
                            </div>
                            <div className="main-slider" onClick={() => handleMainSliderClick("next")}>
                                <div className='main-arrow-next'>
                                    <FontAwesomeIcon icon={['fa-solid', 'angle-right']} className='arrow-next' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default JpTestimonials;
