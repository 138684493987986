// import React from 'react'
import './CreditCard.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../../utils/context";

const renderStars = (rating) => {
  const stars = [];
  const numericRating = Number(rating); // Ensure the rating is a number
  console.log(numericRating, "numericRating");
  for (let i = 1; i <= numericRating; i++) {
    stars.push(
      <FontAwesomeIcon
        key={i}

        icon="fa-solid fa-star" className='Star'
      // className={i <= numericRating ? 'filled' : 'unfilled'} 
      />
    );
  }
  return stars;
};
const CreditCard = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/finance/masters/cards`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);


  useEffect(() => {
    getDataAll();
  }, []);

  return (
    <>
      <section className='creditcard-sec'>
        <h4 className='titles'>Yay! You are eligible for credit cards</h4>
        {data?.map((val) =>
          <div className='rupi-card my-4'>
            <div className='row'>
              <div className='col-xxl-6 col-xl-6 col-md-7 col-12'>
                <div className='card-desc p-3'>
                  <p className='rupi-text'>{val?.name}</p>
                  <p className='reward-text'>{val?.sub_name}</p>
                  <div className='d-flex'>
                    <p className='rating me-2 mt-2'>Profcyma Rating</p>
                    <div className='rating-div p-1'>
                      <p className='rating'>{val?.rating}/5
                        {/* <FontAwesomeIcon icon="fa-solid fa-star" className='Star ms-2' />
                    <FontAwesomeIcon icon="fa-solid fa-star" className='Star'/>
                    <FontAwesomeIcon icon="fa-solid fa-star" className='Star'/>
                    <FontAwesomeIcon icon="fa-solid fa-star" className='Star'/>
                    <FontAwesomeIcon icon="fa-solid fa-star" className='Star'/>
                    <FontAwesomeIcon icon="fa-solid fa-star" className='Star'/> */}
                        {renderStars(val?.rating)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className='feesbox'>
                  <div className='row '>
                    <div className='col-6 border-div '>
                      <p className='rating'>Annual Fees</p>
                      <p className='reward-text'>{val?.annual_fees}</p>
                    </div>
                    <div className='col-6 '>
                      <p className='rating'>Joining Fees</p>
                      <p className='reward-text'>{val?.joining_fees}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-xxl-6 col-xl-6 col-md-5 col-12 '>
                <div className='mx-5 my-4 img-div'>
                  <img
                    className="card-img"
                    src={
                      IMG_URL +
                      val?.image
                    }
                    alt="Banner"
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {/* <div className='rupi-card my-4'>
          <div className='row'>
            <div className='col-xxl-6 col-xl-6 col-md-7 col-12'>
              <div className='card-desc p-3'>
                <p className='rupi-text'>Rupicard</p>
                <p className='reward-text'>Rewards , Cashback , Lifestyle</p>
                <div className='d-flex'>
                  <p className='rating me-2 mt-2'>Profcyma Rating</p>
                  <div className='rating-div p-1'>
                    <p className='rating'>4.7/5
                    <FontAwesomeIcon icon="fa-solid fa-star" className='Star ms-2' />
                    <FontAwesomeIcon icon="fa-solid fa-star" className='Star'/>
                    <FontAwesomeIcon icon="fa-solid fa-star" className='Star'/>
                    <FontAwesomeIcon icon="fa-solid fa-star" className='Star'/>
                    <FontAwesomeIcon icon="fa-solid fa-star" className='Star'/>
                    <FontAwesomeIcon icon="fa-solid fa-star" className='Star'/>
                    </p>
                  </div>
                </div>
              </div>
             <div className='feesbox'>
             <div className='row '>
                <div className='col-6 border-div '>
                  <p className='rating'>Annual Fees</p>
                  <p className='reward-text'>Free</p>
                </div>
                <div className='col-6 '>
                  <p className='rating'>Joining Fees</p>
                  <p className='reward-text'>Free</p>
                </div>
              </div>
             </div>
            </div>
            <div className='col-xxl-6 col-xl-6 col-md-5 col-12 '>
              <div className='mx-5 my-4 img-div'>
                <img
                  className="card-img"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/Finance/Image/calculator/card.png"
                  }
                  alt="Banner"
                />
              </div>
            </div>
          </div>
        </div> */}
      </section>
    </>
  )
}

export default CreditCard