import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Modal } from "react-bootstrap";
import "./PleaseWaitModal.css"

function PleaseWaitModal(props) {
  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static" // Prevent closing when clicking outside
          keyboard={false} // Prevent closing when pressing Esc key
        className="loan-insurance-modal VerifyMobileNumber-modal  PleaseWaitModal "
      
      >
        <Modal.Body>
        <div className="offer-sack-holder">
          <FontAwesomeIcon className="offer-sack" icon="fa-solid fa-sack-dollar" />
        </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="Terms-And-Conditions-Text">
            <p>Please wait we are fetching best offer for you</p>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default PleaseWaitModal;
