import React, { useState, useEffect, useContext } from "react";
import './Jpbrowsecategory.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import Jp_browse_cat_card from '../../JP-Common-Elements/JP-Cards/Jp_browse_cat_card/Jp_browse_cat_card';
import { Context } from "../../../../utils/context";
import AOS from "aos";
import "aos/dist/aos.css";

const Jpbrowsecategory = () => {

    const { getData } = useContext(Context);

    const [categoryData, setCategoryData] = useState();

    const getCategory = async () => {
        try {
            const res = await getData("/without-login/master/all-job-title")
            if (res?.success) {
                setCategoryData(res?.data)
            } else {
                console.log("Error");
            }
        } catch (error) {
            console.log(error.message);
        }
    }
    useEffect(() => {
        AOS.init({ once: true });
    }, []);

    useEffect(() => {
        getCategory();
    }, []);
    return (
        <>
            <section className='Jpbrowsecategory-section'>
                <div className='container Jpbrowsecategory-section-container' data-aos="fade-up"
                    data-aos-duration="3000">
                    <div className='sect-title'>
                        <h1 className='jpsect_title'>Browse by Category</h1>
                        <p className='jpsect_subtitle '>Find the job that’s perfect for you. About 800+ new jobs everyday</p>
                    </div>

                    <Jp_browse_cat_card categoryData={categoryData} />

                </div>
            </section>
        </>
    )
}

export default Jpbrowsecategory