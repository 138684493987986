import React from 'react'
import "./EmpSmBannerCommon.css"

function EmpSmBannerCommon() {
    return (
        <section className='Emp-common-banner'>
            <div className="top-banner">
                <h5 className="banner-heading">With Every Click, Add Up Bests to Your Team</h5>
            </div>
        </section>
    )
}

export default EmpSmBannerCommon