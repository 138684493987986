import React, { useState, useEffect, useContext } from "react";
import "./JPOnlineDegree.css"
import { Context } from "../../../../../../utils/context";

function JPOnlineDegree({ screen }) {

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const getDataAll = async () => {
    try {
      const response = await getData(`/job-portal/home/advertisment/${screen}`);
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  console.log("Add data", data);
  useEffect(() => {
    getDataAll();

  }, []);
  return (
    <>
      {data &&
        <div className="dy-image mb-5">
          <img
            className="dpu-img"
            src={
              IMG_URL + data?.image
            }
          />
        </div>
      }

      {/* <div className="dy-image mb-5">
        <img
          className="dpu-img"
          src={
            process.env.PUBLIC_URL +
            "/assets/Job-portal/Images/blog/DPU_LLP.png"
          }
        />
      </div> */}
    </>
  )
}

export default JPOnlineDegree