import React, { useRef, useState, useContext, useEffect } from 'react';
import './Jp_browse_cat_card.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { Context } from "../../../../../utils/context";
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const Jp_browse_cat_card = () => {

    const BrowseCatergoryJObs = [
        {
            jobname1: 'Content Writer',
            available1: '2',
            icon1: '/assets/Job-portal/Images/home/browse-category/content.png',

            jobname2: 'Management',
            available2: 'No',
            icon2: '/assets/Job-portal/Images/home/browse-category/human.png',

        },
        {
            jobname1: 'Content Writer',
            available1: '2',
            icon1: '/assets/Job-portal/Images/home/browse-category/content.png',


            jobname2: 'Management',
            available2: 'No',
            icon2: '/assets/Job-portal/Images/home/browse-category/human.png',

        },

        {
            jobname1: 'Content Writer',
            available1: '2',
            icon1: '/assets/Job-portal/Images/home/browse-category/content.png',


            jobname2: 'Management',
            available2: 'No',
            icon2: '/assets/Job-portal/Images/home/browse-category/human.png',

        },

        {
            jobname1: 'Content Writer',
            available1: '2',
            icon1: '/assets/Job-portal/Images/home/browse-category/content.png',


            jobname2: 'Management',
            available2: 'No',
            icon2: '/assets/Job-portal/Images/home/browse-category/human.png',

        },

        {
            jobname1: 'Content Writer',
            available1: '2',
            icon1: '/assets/Job-portal/Images/home/browse-category/content.png',


            jobname2: 'Management',
            available2: 'No',
            icon2: '/assets/Job-portal/Images/home/browse-category/human.png',

        },

        {
            jobname1: 'Content Writer',
            available1: '2',
            icon1: '/assets/Job-portal/Images/home/browse-category/content.png',


            jobname2: 'Management',
            available2: 'No',
            icon2: '/assets/Job-portal/Images/home/browse-category/human.png',

        },
        {
            jobname1: 'Content Writer',
            available1: '2',
            icon1: '/assets/Job-portal/Images/home/browse-category/content.png',


            jobname2: 'Management',
            available2: 'No',
            icon2: '/assets/Job-portal/Images/home/browse-category/human.png',

        },

    ];

    const [swiperInstance, setSwiperInstance] = useState(null);
    const handleMainSliderClick = (direction) => {
        if (swiperInstance) {
            if (direction === "prev") {
                swiperInstance.slidePrev();
            } else if (direction === "next") {
                swiperInstance.slideNext();
            }
        }

    };

    const { getData } = useContext(Context);

    const [categoryData, setCategoryData] = useState();

    const getCategory = async () => {
        try {
            const res = await getData("/without-login/master/all-job-title")
            if (res?.success) {
                setCategoryData(res?.data)
            } else {
                console.log("Error");
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    useEffect(() => {
        getCategory();
    }, []);
    return (
        <>
            <section className='job-browse-cat-card-sec'>
                <Swiper
                    slidesPerView={5}
                    spaceBetween={30}
                    centeredSlides={false}
                    // autoplay={{
                    //     delay: 2500,
                    //     disableOnInteraction: false,
                    // }}
                    pagination={{
                        clickable: true,
                        dynamicBullets: true,
                    }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    onSwiper={(swiper) => setSwiperInstance(swiper)}

                    breakpoints={{
                        '0': {
                            slidesPerView: 1,
                            spaceBetween: 50,
                        },
                        '400': {
                            slidesPerView: 1,
                            spaceBetween: 10,
                        },
                        '575': {
                            slidesPerView: 2,
                            spaceBetween: 20,
                        },
                        '768': {
                            slidesPerView: 3,
                            spaceBetween: 20,
                        },
                        '991': {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                        '1200': {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        },
                        '1400': {
                            slidesPerView: 5,
                            spaceBetween: 20,
                        },
                    }}

                    className="mySwiper"
                >
                    {categoryData?.map((data, index) => (
                        <SwiperSlide>
                            <div className='cat-slider-main'>
                                <Link to={`/job-portal/jobseeker/browse-category/${data?.id}`}>
                                    <div className='box-1 my-auto'>
                                        <div className='d-flex'>
                                            <div className='icon-div my-auto'>
                                                {/* <img className='icco' src={data.icon1} /> */}
                                            </div>
                                            <div className='cont-div my-auto'>
                                                <h6 className='cotname'>{data?.name}</h6>
                                                <p className='avl-p'>{data.available1} Jobs Available</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className='cat-slider-main'>
                                <Link to={`/job-portal/jobseeker/browse-category/${data?.id}`}>
                                    <div className='box-1 my-auto'>
                                        <div className='d-flex'>
                                            <div className='icon-div my-auto'>
                                                <img className='icco' src={data.icon2} />
                                            </div>
                                            <div className='cont-div my-auto'>
                                                <h6 className='cotname'>{data?.name}</h6>
                                                <p className='avl-p'>{data.available2} Jobs Available</p>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <div
                    className="main-slider "
                    onClick={() => handleMainSliderClick("prev")}
                >
                    <div className='main-arrow-back'>
                        <FontAwesomeIcon icon="fa-solid fa-angle-left" className='arrow-back' />
                    </div>
                </div>
                <div
                    className="main-slider"
                    onClick={() => handleMainSliderClick("next")}
                >
                    <div className='main-arrow-next'>
                        <FontAwesomeIcon icon="fa-solid fa-angle-right" className='arrow-next' />
                    </div>
                </div>
            </section>
        </>
    )
}

export default Jp_browse_cat_card