import React from 'react'
import './Jphomecounter.css'
import CountUp from 'react-countup'


const Jphomecounter = () => {
    return (
        <>
            <section className='Jphomecounter-section'>
                <div className='container'>
                    <div className='counter-main'>
                        <div className='row'>
                            <div className='col-md-3 col-6 mb-md-0 mb-3'>
                                <div className='conter-div'>
                                    <h1 className='count-num'><CountUp start={0} end={25} duration={5} decimals={0} />+</h1>
                                    <h3 className='counter-subtitle'>Completed Cases</h3>
                                    <p className='count-cont'>We always provide people a complete solution upon of any business</p>
                                </div>
                            </div>
                            <div className='col-md-3 col-6 mb-md-0 mb-3'>
                                <div className='conter-div'>
                                    <h1 className='count-num'><CountUp start={0} end={20} duration={5} decimals={0} />+</h1>
                                    <h3 className='counter-subtitle'>Awards</h3>
                                    <p className='count-cont'>We always provide people a complete solution upon of any business</p>
                                </div>
                            </div>
                            <div className='col-md-3 col-6 mb-md-0 mb-3'>
                                <div className='conter-div'>
                                    <h1 className='count-num'><CountUp start={0} end={150} duration={5} decimals={0} />+</h1>
                                    <h3 className='counter-subtitle'>Skillful Team</h3>
                                    <p className='count-cont'>We always provide people a
                                        complete solution upon focused
                                        of any business</p>
                                </div>
                            </div>
                            <div className='col-md-3 col-6 mb-md-0 mb-3'>
                                <div className='conter-div'>
                                    <h1 className='count-num'><CountUp start={0} end={100} duration={5} decimals={0} />+</h1>
                                    <h3 className='counter-subtitle'>Happy Clients</h3>
                                    <p className='count-cont'>We always provide people a
                                        complete solution upon focused
                                        of any business</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Jphomecounter