import "./banner/Banner.css";
import Banner from "./banner/Banner";

function Welcome() {
  return (
    <>
      <Banner />      
    </>
  );
}

export default Welcome;
