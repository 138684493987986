import React, { useRef, useState } from 'react'
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './EmpEditPersonModal.css'
import { Form } from "react-bootstrap";

const EmpEditPersonModal = (props) => {
    const [show, setShow] = useState(false);
    const editor = useRef(null);
    return (
        <>
            <section className='edit-person-modal'>
                <Modal
                    {...props}
                    size="lg"
                    className="EmpEditPersonModal-section form-container"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header >
                        <Modal.Title id="contained-modal-title-vcenter">Edit Person</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className='main-div'>
                        <div className='row'>
                            <div className='col-xxl-4 col-xl-4 col-md-4 col-sm-4 col-12 align-items-center  d-flex text-center justify-content-center mb-4'>
                                <div className='user-img-div'>
                                    <img class="user-img" src="/assets/Job-portal/Images/employer/companyform/member.png" />
                                    <button className='plus-icon-bg'>
                                        <FontAwesomeIcon icon="fa-solid fa-plus" className='fa-plus' />
                                    </button>
                                </div>
                            </div>
                            <div className='col-xxl-8 col-xl-8 col-md-8 col-sm-8 col-12'>
                                <Form>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label> Name</Form.Label>
                                        <Form.Control type="text" placeholder="Best Achiever of Year" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Designation</Form.Label>
                                        <Form.Control type="text" placeholder="Best Achiever of Year" />
                                    </Form.Group>
                                </Form>
                            </div>
                        </div>
                        </div>
                        <div className='button-divvvs text-center mt-3'>
                            <button className='cancel-btn' onClick={props.onHide}>Close</button>
                            <button className='save-btn' >Save</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </section>
        </>
    )
}

export default EmpEditPersonModal