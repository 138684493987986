import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import './JpSearch.css'
import { Link } from 'react-router-dom';
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../../../utils/context";
import Select from "react-select";

const JpJobseekerSearch = (props) => {

    const { postData, getData, Select2Data, IMG_URL } = useContext(Context);
    const [show, setShow] = useState(false);

    const {
        control,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        console.log(data, "dfsfdsfdsfds");
        const job_title = data.job_title;
        const location = data.city_id?.value;

        // Construct query parameters
        const queryParams = new URLSearchParams({
            job_title: job_title,
            city: location,
        }).toString();

        navigate(`/job-portal/job/?${queryParams}`);
        props.onHide();
    }

    const [cities, setCity] = useState([]);
    const GetAllCities = async () => {
        const response = await getData("/without-login/master/all-cities");
        if (response?.success) {
            setCity(await Select2Data(response?.data, "city_id"));
        }
    };

    useEffect(() => {
        GetAllCities();
    }, []);

    return (
        <>
            <section className='search-emp-modal'>
                <Modal
                    {...props}
                    size="lg"
                    className="search-modal-section form-container"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <div className='heading-div'>
                                <h4>Search Jobs Here</h4>
                                <div className='cross-icon-div' onClick={props.onHide}>
                                    <FontAwesomeIcon icon="fa-solid fa-xmark" className='cross-icon' />
                                </div>
                            </div>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='form-container search-div'>
                                <div>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Job Title / Skill / Companies</Form.Label>
                                        {/* <Form.Control type="text" placeholder="Enter Job Job Title / Skill / Designations / Companies" /> */}
                                        <Form.Control
                                            type="text"
                                            name="job_title"
                                            placeholder="Enter Job Job Title / Skill / Companies"
                                            {...register("job_title", {
                                                // required: "Job Title is required",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.job_title,
                                            })}
                                            onKeyDown={(event) => {
                                                if (!/[A-Z-a-z ]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </Form.Group>
                                </div>
                                <div>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Location</Form.Label>
                                        <Controller
                                            name={"city_id"}
                                            {...register("city_id", {
                                            })}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    styles={{

                                                    }}
                                                    {...field}
                                                    options={cities}
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className='button-divvvs text-center mt-3 mb-3'>
                                {/* <button className='cancel-btn' onClick={props.onHide}>Close</button> */}
                                {/* <Link to="/job-portal/job"> <button className='save-btn' onClick={props.onHide}>Search</button></Link> */}
                                <button className='save-btn' type='submit'>Search</button>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            </section>
        </>
    )
}

export default JpJobseekerSearch