import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./ITCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigation } from "react-router-dom";
import Exlamentry from "../../../Jp-modals/Exlamentry_Modal/Exlamentry";
import { Context } from "../../../../../../utils/context";
import { useParams, useNavigate } from "react-router-dom";

library.add(fas);

function ITCard({
  selectedId,
  job_title,
  city_id,
  job_type_id,
  max_experience,
  min_experience,
  maxSalary,
  minSalary,
  category,
  date,
  from,
}) {

  const { id } = useParams();
  const { getData, postData, IMG_URL, signin, usertype, userdata, setUsertype } = useContext(Context);
  const [jobsData, setJobData] = useState([]);

  const navigate = useNavigate();

  const getJob = async (selectedId) => {
    try {
      if (id) {
        if (selectedId) {
          const res = await getData(`/job-portal/companies/industry-company/${id}/${selectedId}`)
          if (res?.success) {
            setJobData(res?.data)
          } else {
            console.log("Error");
          }
        }
        else {
          const res = await getData(`/job-portal/companies/${id}`)
          if (res?.success) {

            setJobData(res?.data)
          } else {
            console.log("Error");
          }

        }


      } else {
        if (selectedId) {
          const res = await getData(`/job-portal/jobs/industry/${selectedId}`)
          if (res?.success) {
            setJobData(res?.data)
          } else {
            console.log("Error");
          }
        }
        else {
          const res = await getData("/job-portal/jobs")
          if (res?.success) {
            setJobData(res?.data)
          } else {
            console.log("Error");
          }
        }
      }

    } catch (error) {

    }
  }

  useEffect(() => {
    if (!(job_type_id || city_id || job_title || max_experience || category)) {
      // If any of the variables is not defined, proceed to call getJob
      getJob(selectedId);
    } else {
      console.log("All variables are defined; might not need to call getJob based on original intent.");
    }
  }, [selectedId]);

  const getsearchJob = async (selectedId) => {
    console.log("city_id :--", city_id);
    console.log("job_type_id :--", job_type_id);
    try {
      if (selectedId) {
        const res = await getData(`/job-portal/jobs/industry/${selectedId}?job_type_id=${job_type_id?.value || ""}&city_id=${city_id?.value || ""}&job_title=${job_title}`)
        if (res?.success) {
          setJobData(res?.data)
        } else {
          console.log("Error");
        }
      }
      else {
        const res = await getData(`/job-portal/jobs/search-data/data?job_type_id=${job_type_id?.value || ""}&city_id=${city_id?.value || ""}&job_title=${job_title}`)
        if (res?.success) {
          setJobData(res?.data)
        } else {
          console.log("Error");
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  // useEffect(() => {

  //   if (job_type_id || city_id || job_title) {
  //     getsearchJob(selectedId);
  //   } else {
  //     // Handle the case where any of the required variables is not defined or falsy
  //     console.log("Required variables are not defined");
  //   }
  // }, [selectedId, job_type_id, city_id, job_title]);



  const getHeaderSearchJob = async (selectedId) => {
    try {
      if (selectedId) {
        const res = await getData(`/job-portal/search/jobseeker/${selectedId}?job_title=${job_title}&joblocation=${city_id}`)
        if (res?.success) {
          setJobData(res?.data)
        } else {
          console.log("Error");
        }
      }
      else {
        const res = await getData(`/job-portal/search/jobseeker/?job_title=${job_title}&joblocation=${city_id}`)
        if (res?.success) {
          setJobData(res?.data)
        } else {
          console.log("Error");
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  // useEffect(() => {

  //   if (job_title || city_id) {
  //     getHeaderSearchJob(selectedId);
  //   } else {
  //     // Handle the case where any of the required variables is not defined or falsy
  //     console.log("Required variables are not defined");
  //   }
  // }, [selectedId, job_title, city_id]);


  const getsearchCompanyJob = async (selectedId) => {
    console.log("category :--:--", category);
    try {
      if (selectedId) {
        const res = await getData(`/job-portal/companies/industry-company/${id}/${selectedId || ""}?category=${category || ""}&date=${date || ""}` +
          `&min_experience=${min_experience || ""}&title=${job_title || ""}&max_experience=${max_experience || ""}` +
          `&maxSalary=${maxSalary || ""}&minSalary=${minSalary || ""}&city=${city_id?.value || "" || ""}&selectedJobType=${job_type_id || ""}`)
        if (res?.success) {
          setJobData(res?.data)
          window.scrollTo(0, 500)
        } else {
          console.log("Error");
        }
      }
      else {
        const res = await getData(`/job-portal/companies/${id}?category=${category || ""}&date=${date || ""}` +
          `&min_experience=${min_experience || ""}&title=${job_title || ""}&max_experience=${max_experience || ""}` +
          `&maxSalary=${maxSalary || ""}&minSalary=${minSalary || ""}&city=${city_id?.value || "" || ""}&selectedJobType=${job_type_id || ""}`)
        if (res?.success) {
          setJobData(res?.data)
          window.scrollTo(0, 500)
        } else {
          console.log("Error");
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {

    if (id && max_experience || min_experience) {
      getsearchCompanyJob(selectedId);
    } else {
      // Handle the case where any of the required variables is not defined or falsy
      console.log("Required variables are not defined");
    }
  }, [id, selectedId, max_experience, min_experience]);


  const getFilterjobs = async (selectedId) => {
    try {
      if (selectedId) {
        const res = await getData(`/job-portal/jobs/industry/${selectedId}?category=${category || ""}&date=${date || ""}` +
          `&min_experience=${min_experience || ""}&max_experience=${max_experience || ""}&job_title=${job_title || ""}` +
          `&maxSalary=${maxSalary || ""}&minSalary=${minSalary || ""}&city_id=${city_id?.value || "" || ""}&job_type_id=${job_type_id || ""}`)
        if (res?.success) {
          setJobData(res?.data)
          window.scrollTo(0, 500)
        } else {
          console.log("Error");
        }
      }
      else {
        const res = await getData(`/job-portal/jobs?category=${category || ""}&date=${date || ""}` +
          `&min_experience=${min_experience || ""}&max_experience=${max_experience || ""}&title=${job_title || ""}` +
          `&maxSalary=${maxSalary || ""}&minSalary=${minSalary || ""}&city=${city_id?.value || "" || ""}&selectedJobType=${job_type_id || ""}`)
        if (res?.success) {
          setJobData(res?.data)
          window.scrollTo(0, 500)
        } else {
          console.log("Error");
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    console.log("from :---", from);
    if (from === 'filter') {
      getFilterjobs(selectedId);
    } else if (from === 'search') {
      getsearchJob(selectedId);
    }
  }, [from, selectedId, max_experience, min_experience, job_type_id, city_id, job_title]);


  const getRecentJob = async (selectedId) => {
    try {
      if (selectedId) {
        const res = await getData(`/job-portal/jobs/industry/${selectedId}?job_type_id=${job_type_id?.value || ""}&city_id=${city_id?.value || ""}&job_title=${job_title}`)
        if (res?.success) {
          setJobData(res?.data)
        } else {
          console.log("Error");
        }
      }
      else {
        const res = await getData(`/job-portal/jobs/search-data/data?job_type_id=${job_type_id?.value || ""}&city_id=${city_id?.value || ""}&job_title=${job_title}`)
        if (res?.success) {
          setJobData(res?.data)
        } else {
          console.log("Error");
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  // useEffect(() => {

  //   if (job_type_id || city_id || job_title) {
  //     getRecentJob(selectedId);
  //   } else {
  //     // Handle the case where any of the required variables is not defined or falsy
  //     console.log("Required variables are not defined");
  //   }
  // }, [selectedId, job_type_id, city_id, job_title]);



  const [modalShow, setModalShow] = useState(false);
  const openmodal = () => {
    setModalShow(true);

    setTimeout(() => {
      window.location = "/job-portal/search/job-description"
      setModalShow(false);
    }, 3000)
  }



  return (
    <section className="IT_Tab_content">
      <div className="IT-Cards">
        <div className="row">
          {jobsData?.map((job) => (
            <div
              key={job?.id}
              className="col-xl-3 col-lg-4 col-md-6 col-sm-6 "
            >

              <Card className="it-job-card">
                <div className="card-img-holder">
                  <Card.Img variant="top" src={IMG_URL + job?.company_logo} />
                </div>
                <Card.Body>
                  <Card.Title>{job?.company_name}</Card.Title>
                  <Card.Text>
                    <div className="text-holder">
                      <h6>{job?.job_title?.name}</h6>
                      <p>
                        <FontAwesomeIcon
                          className="location_icon me-2"
                          icon="fa-solid fa-location-dot"
                        />
                        {job?.city?.name}
                      </p>
                    </div>
                  </Card.Text>
                  {signin ? (
                    <Button className="apply-btn" onClick={() => navigate(`/job-portal/search/job-description/${job?.id}`)}>Explore</Button>
                    // <p className="" onClick={() => navigate(`/job-portal/company/Company-Job/${company?.id}`)}>View Jobs</p>
                  ) : (
                    <Button className="apply-btn" onClick={() => navigate("/job-portal/sign-in")}>Explore</Button>
                    // <p className="" onClick={() => navigate("/job-portal/sign-in")}>Explore</p>
                  )}
                  {/* <Button className="apply-btn" onClick={() => navigate("/job-portal/search/job-description")}>Explore</Button> */}
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>

        <Exlamentry
          show={modalShow}
          onHide={() => setModalShow(false)}
          text={"Your Need to Log In to JobSeeker to Apply for the Job!"}
        />
      </div>
    </section>
  );
}

export default ITCard;
