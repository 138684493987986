import React, { useEffect, useState, useContext } from "react";
import "./JPBlogUpdate.css";
import { Link } from "react-router-dom";
import { Context } from "../../../../utils/context.js";
import { getBlog } from "../../../../utils/apis/common/Common.js";
import BlogModal from "../../JP-Common-Elements/Jp-modals/BlogModal/BlogModal.js";
const JPBlogUpdate = () => {
  const { getData, IMG_URL, usertype } = useContext(Context);
  const [modalShow, setModalShow] = useState(false);
  const [blogs, setBlog] = useState();
  const [data, setData] = useState("");

  const getproject = async () => {
    const res = await getBlog();
    if (res?.success) {
      setBlog(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getproject();
  }, []);

  function isToday(someDate) {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  }

  console.log(usertype, "usertypeusertype");
  return (
    <>
      <section className="jp-blog-update">
        <div className="container">
          <div className="heading-holder">
            <div className="row">
              <div className="col-xl-2 col-md-3">
                <div className="BacktoCompanies">
                  {usertype == "Employer" &&
                    <Link to={"/job-portal/employer/dashboard"}>
                      <p className="">Back to Dashboard</p>
                    </Link>
                  }
                  {usertype == "JobSeekar" &&
                    <Link to={"/job-portal/jobseeker/dashboard"}>
                      <p className="">Back to Dashboard</p>
                    </Link>
                  }
                </div>
              </div>
              <div className="col-xl-8 col-md-6">
                <div className="text-center">
                  <h1 className="jpsect_title">
                    Latest Updates on Career Opportunities
                  </h1>
                </div>
              </div>
            </div>
          </div>

          <div className="blog-content-holder">
            <div className="row">
              {blogs?.map((data, index) =>
                index === 0 ? (
                  <div key={index} className="col-xxl-5 col-xl-5 col-lg-6">
                    <div>
                      <div className="image-holder mb-4" onClick={() => {
                        setData(data);
                        setModalShow(true);
                      }}>
                        <img
                          className="bannn-img1"
                          src={IMG_URL + data?.image}
                          alt="Blog Banner"
                        />
                      </div>
                      <div className="text-holder mb-4">
                        <h5>{data?.title}</h5>
                        <p>
                          Uploaded:{" "}
                          {isToday(new Date(data?.createdAt))
                            ? "Today"
                            : new Date(data?.createdAt).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "short",
                                year: "2-digit",
                              }
                            )}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : null
              )}

              <div className="col-xxl-7 col-xl-7 col-lg-6">
                {blogs?.map((data, index) => (
                  <div key={index} className="row">
                    {index !== 0 ? (
                      <>
                        <div className="col-xxl-3 col-xl-3 col-lg-5 col-md-4 col-sm-5">
                          <div className="image-holder mb-3" onClick={() => {
                            setData(data);
                            setModalShow(true);
                          }}>
                            <img
                              className="update-img"
                              src={IMG_URL + data?.image}
                            />
                          </div>
                        </div>
                        <div className="col-xxl-9 col-xl-9 col-lg-7 col-md-8 col-sm-7">
                          <div className="update-content mb-3">
                            <h5>{data?.title}</h5>
                            <p>
                              Uploaded:{" "}
                              {isToday(new Date(data?.createdAt))
                                ? "Today"
                                : new Date(data?.createdAt).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "short",
                                    year: "2-digit",
                                  }
                                )}
                            </p>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <hr />

          <BlogModal show={modalShow} onHide={() => setModalShow(false)} data={data} setData={setData} />
        </div>
      </section>
    </>
  );
};

export default JPBlogUpdate;
