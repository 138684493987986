import React from "react";
import { Button } from "react-bootstrap";
import "./CreateAccount.css";
import { Link } from "react-router-dom";

function CreateAccount() {
  return (
    <section className="CreateAccount">
      <div className="container p-0">
        <div className="create-account-card">
          <div className="img-holder">
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/Job-portal/Images/company/IT/create_account_bg_img.png"
              }
              className="create_account_bg_img"
              alt="..."
            />
            <div className="overlay"></div>
          </div>

          <div className="content-holder">
            <div className="heading-holder">
              <h6>Make a Difference with Your Online Resume!</h6>
              <h1>
                Your resume in minutes with JobBoard resume assistant is ready!
              </h1>
            </div>
            <div className="yellow_create-Account_btn">
              <div className="btn-holder">
              <Link to={"/job-portal/register"}>
                <Button className="Create_Account_btn">Create Account</Button>
              </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreateAccount;
