import React, { useState } from "react";
import Home from "../homepage/Home";
import AboutUs from "../about-us/AboutUs";
import MBA from "../Courses/Pg-courses/mba/MBA";
import Mcom from "../Courses/Pg-courses/mcom/Mcom";
import Mca from "../Courses/Pg-courses/mca/Mca";
import Mlib from "../Courses/Pg-courses/MLib/Mlib";
import Bba from "../Courses/Ug-courses/BBA/Bba";
import Bca from "../Courses/Ug-courses/BCA/Bca";
import Bcom from "../Courses/Ug-courses/Bcom/Bcom";
import Bsc from "../Courses/Ug-courses/Bsc/Bsc";
import Ba from "../Courses/Ug-courses/BA/Ba";
import BLib from "../Courses/Ug-courses/BLib/BLib";
import Diploma from "../Courses/Certification/diploma/Diploma";
import Dypatil from "../universities/dy-patil/Dypatil";
import AmityUniversity from "../universities/amity-university/AmityUniversity";
import Bharati from "../universities/bharati-vidyapeeth/Bharati";

import Common from "../universities/common/CommonUniversity";
import CommonUniversity from "../universities/common-university/common-university";
import OtherCourses from "../Courses/Other-courses/Other-courses";
import CommonCourses from "../Courses/common-courses/common/common";

import Admission from "../Admission/Admission";
import Blogs from "../blogs/Blogs";
import Readmorefirst from "../blogs/ReadMoreFirst/Readmorefirst";
import ReadMoreSecond from "../blogs/ReadMoreSecond/ReadMoreSecond";
import Redmorethird from "../blogs/ReadMoreThird/Redmorethird";
import ContactUs from "../contact-us/ContactUs";
import PrivacyPolicy from "../privacy-policy/PrivacyPolicy";
import TermsCondition from "../terms-condition/TermsCondition";
import { Route, Routes } from "react-router-dom";
import Msc from "../Courses/Pg-courses/msc/Msc";
import Ma from "../Courses/Pg-courses/ma/Ma";
import { useParams } from "react-router-dom";
import BecomePartnerPage from "../BecomePartnerPage/BecomePartnerPage";
import Specilization from "../Specilization/Specilization";
import SpecializationNew from "../Specialization_new/SpecializationNew";
import Stepform from "../Stepform/Stepform";
import StepformMain from "../Stepform/StepformMain";
import InquiryFormModal from "../InquiryFormModal/InquiryFormModal";
import { useEffect } from "react";


const EdtechRoutes = () => {
  let { id } = useParams();
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setModalShow(false);
    },);

    return () => clearInterval(timer);
  }, []);


  return (
    <>
      <Routes>
        <Route path={"/ed-tech/home"} element={<Home />} />
        <Route path={`/ed-tech/about`} element={<AboutUs />} />
        <Route
          path={`/ed-tech/becomepartner`}
          element={<BecomePartnerPage />} />
        <Route path="/ed-tech/specialization/:id" element={<SpecializationNew />} />


        {/* Courses & Innerpage */}
        <Route path="/ed-tech/pg-courses/mba" element={<MBA />} />
        <Route path="/ed-tech/pg-courses/mca" element={<Mca />} />
        <Route path="/ed-tech/pg-courses/mcom" element={<Mcom />} />
        <Route path="/ed-tech/pg-courses/msc" element={<Msc />} />
        <Route path="/ed-tech/pg-courses/ma" element={<Ma />} />
        <Route path="/ed-tech/pg-courses/mlib" element={<Mlib />} />
        {/* UG */}
        <Route path="/ed-tech/ug-courses/bba" element={<Bba />} />
        <Route path="/ed-tech/ug-courses/bca" element={<Bca />} />
        <Route path="/ed-tech/ug-courses/bcom" element={<Bcom />} />
        <Route path="/ed-tech/ug-courses/bsc" element={<Bsc />} />
        <Route path="/ed-tech/ug-courses/ba" element={<Ba />} />
        <Route path="/ed-tech/ug-courses/b-lib" element={<BLib />} />
        {/* Certification */}
        <Route path="/ed-tech/certification/diploma" element={<Diploma />} />

        {/* <Route
          path="/ed-tech/ed-tech/courses/other-courses/:id"
          element={<OtherCourses />}
        /> */}
        <Route
          path="/ed-tech/ed-tech/courses/common-courses/:id"
          element={<CommonCourses />}
        />
        {/* University */}
        <Route path="/ed-tech/university/dy-patil" element={<Dypatil />} />
        <Route
          path="/ed-tech/university/amity-university"
          element={<AmityUniversity />}
        />
        <Route
          path="/ed-tech/university/bharati-vidyapeeth"
          element={<Bharati />}
        />

        {/* <Route
          path="/ed-tech/university/common-university/:id"
          element={<Common />}
        /> */}
        <Route
          path="/ed-tech/university/:id"
          element={<CommonUniversity />}
        />
        <Route path="/ed-tech/admission" element={<Admission />} />
        {/* Blog & Innerpage */}
        <Route path="/ed-tech/blog" element={<Blogs />} />
        <Route path="/ed-tech/blog/difference" element={<Readmorefirst />} />
        <Route
          path="/ed-tech/blog/online-mba-myth/:id"
          element={<ReadMoreSecond />}
        />
        <Route
          path="/ed-tech/blog/online-degree-value"
          element={<Redmorethird />}
        />
        <Route path="/ed-tech/contact-us" element={<ContactUs />} />
        <Route path="/ed-tech/privacy-policy" element={<PrivacyPolicy />} />
        <Route
          path="/ed-tech/terms-and-condition"
          element={<TermsCondition />}
        />

        <Route
          path="/ed-tech/stepform/:id"
          element={<StepformMain />} />
      </Routes>


      <InquiryFormModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default EdtechRoutes;
