import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import "./Apply.css";
import { Col, Container, Row } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";

const Apply = ({ loan_form_id }) => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const getDataAll = async () => {
    try {
      const response = await getData(`/finance/loan/f-who-can-apply/${loan_form_id}`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data, "dsdsdsdsd");

  useEffect(() => {
    getDataAll();
  }, []);

  useEffect(() => {
    AOS.init({ once: true });
  }, [])

  return (
    <>
      {data && data?.length > 0 &&
        <section className="Apply">
          <div data-aos="fade-up"
            data-aos-duration="3000">
            <Swiper
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper"
            >
              {data?.map((image, index) => (
                <SwiperSlide>
                  <>
                    <Container fluid className="p-0">
                      <div className="Background-image">
                        <Container>
                          <div className="Apply-Content">
                            <h1 className="fianace-titleeee second-fiannn">Who Can Apply?</h1>
                            <p className="inner-text">Qualifications & Document</p>
                            <ul>
                              {htmlToReactParser.parse(image?.description)}
                            </ul>

                          </div>
                        </Container>
                      </div>
                    </Container>
                  </>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

        </section>
      }
    </>
  );
};

export default Apply;
