import React from 'react'
import FInsuranceBanner from './F-InsuranceBanner/FInsuranceBanner'
import FInsuranceAdvantage from './F-insurance-advantage/FInsuranceAdvantage'
import FInsuranceEligibility from './F-Insurance-Eligibility/FInsuranceEligibility'
import FInsuranceDocument from './F-Insurance-Document/FInsuranceDocument'
import FInsuranceReview from './F-Insurance-Review/FInsuranceReview'
import ChooseFinance from './F-Profcyma-Choose/FProfcymaChoose'
import { useParams } from 'react-router-dom';

const FInsuranceComman = () => {
    const { id } = useParams();
    console.log(id, "idididididdi")
    return (
        <>
            <FInsuranceBanner service_id={id} />
            <FInsuranceAdvantage service_id={id} />
            <ChooseFinance service_id={id} />
            <FInsuranceEligibility service_id={id} />
            <FInsuranceDocument service_id={id} />
            <FInsuranceReview service_id={id} />
        </>
    )
}

export default FInsuranceComman