
import { React, useRef, useEffect, useState, useContext } from "react";
import { Button, Form, Modal } from 'react-bootstrap'
import SecondCredit from "../SecondCredit/SecondCredit";
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from "../../../../../utils/context";
import classNames from "classnames";
import ThirdCredit from "../ThirdCredit/ThirdCredit";
import Cookies from "js-cookie";
const FirstCredit = (props) => {
    const [secondModalShow, setSecondModalShow] = useState(false);
    const { postData, IMG_URL } = useContext(Context);
    const [thirdModalShow, setThirdModalShow] = useState(false);
    // const handleClose = () => {
    //     props.onHide();
    //     setSecondModalShow(true);
    // };

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [contact_no, setContact_no] = useState(null);
    const [gender, setGender] = useState("");
    const [futureUpdates, setFutureUpdates] = useState(false);
    const [verified, setVerified] = useState(false);

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setValue,
        setError,
        clearErrors,
        formState: { errors },
        reset
    } = useForm();

    const handleForm = async (d) => {
        if (!gender) {

            setError("gender", { type: "manual", message: "Select Gender" });
            return false
        }
        setValue("gender", gender)
        setValue("future_updates", futureUpdates)
        setSecondModalShow(true)
        handleGetOTP(d?.contact_no)
    };

    const handleGetOTP = async (contact_no) => {
        if (contact_no) {
            const res = await postData(`/without-login/verify-otp/otp-send`, { contact_no });
            if (res?.success) {
                console.log(res, "otp");
                handleClose();
            } else {
                console.error("Failed to send OTP:", res.message);
            }
        } else {
            console.error("Valid Contact number is required");
            // setContactError("Contact number is required");
        }
    };

    console.log("getValues", getValues());
    const onSubmit = async (data) => {
        console.log("data", data);

        if (!gender) {
            setError("gender", { type: "manual", message: "Select Gender" });
            return false
        }
        // handleGetOTP(d?.contact_no)
        // alert("AAgaya")
        try {
            const formdata = new FormData();
            formdata.append("name", getValues("name"));
            formdata.append("email", getValues("email"));
            formdata.append("contact_no", getValues("contact_no"));
            formdata.append("gender", gender);
            formdata.append("future_updates", futureUpdates);
            const res = await postData(`/finance/forms/free-credit-score-form`, formdata);
            if (res?.success) {
                Cookies.set("score_form_id", res.data.id, {
                    expires: 1,
                });
                handleClose();
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handleClose = () => {
        props.onHide();
        setThirdModalShow(true);
    };


    const handleSwitchChange = () => {
        setFutureUpdates(!futureUpdates); // Toggle the state value
    };

    const [charCount, setCharCount] = useState(0);

    const handleCount = (e) => {
        const inputValue = e.value;
        setCharCount(inputValue.length);
    };

    useEffect(() => {
        console.log("contact", contact_no);
        console.log("name", name);
    }, [contact_no, name]);

    return (
        <>
            <section className='first-credit'>
                <Modal
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered scrollable
                    className='fenquiry-modal sec-fmoalll modal-dialog-scrollable'
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Lifetime Free Credit Score1
                            <p className="second-line-first">Get your credit report for free, with monthly updates</p>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={handleSubmit(handleForm)}>
                            <div className='form-sec-start form-sec-start-two'>
                                <p>Gender</p>

                                {['radio'].map((type) => (
                                    <div key={`inline-${type}`} className="mb-0">
                                        <Form.Check
                                            inline
                                            label="Male"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-1`}
                                            className="me-5"
                                            checked={gender === 'Male'}
                                            onChange={(e) => { setGender(e.target.value); clearErrors("gender") }}
                                            value="Male"
                                        />
                                        <Form.Check
                                            inline
                                            label="Female"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-2`}
                                            className="me-5"
                                            checked={gender === 'Female'}
                                            onChange={(e) => { setGender(e.target.value); clearErrors("gender") }}
                                            value="Female"
                                        />
                                        <Form.Check
                                            inline
                                            label="Other"
                                            name="group1"
                                            type={type}
                                            id={`inline-${type}-3`}
                                            className="me-5"
                                            checked={gender === 'Other'}
                                            onChange={(e) => { setGender(e.target.value); clearErrors("gender") }}
                                            value="Other"
                                        />
                                    </div>
                                ))}
                                {errors.gender && (
                                    <span className="text-danger">
                                        {errors.gender.message}
                                    </span>
                                )}
                                <Form.Group controlId="fullName">
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control
                                        name="name"
                                        className={classNames("", {
                                            "is-invalid": errors?.name,
                                        })}
                                        type="text"
                                        {...register("name", {
                                            required: "Name is required",
                                            pattern: {
                                                value: /^[A-Za-z ]*$/,
                                                message: "Name must contain only letters",
                                            },
                                        })}
                                        onKeyDown={(event) => {
                                            if (!/[A-Z-a-z ]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        placeholder="Enter Full Name"
                                    />

                                </Form.Group>
                                {errors.name && (
                                    <span className="text-danger">
                                        {errors.name.message}
                                    </span>
                                )}

                                <Form.Group controlId="email">
                                    <Form.Label>Email Address</Form.Label>
                                    <Form.Control
                                        className={classNames("", {
                                            "is-invalid": errors?.email,
                                        })}
                                        type="text"
                                        {...register("email", {
                                            required: "Email is required",
                                            pattern: {
                                                value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                                                message: "Invalid E-Mail Address",
                                            },
                                        })}
                                        placeholder="Enter Email"
                                    />
                                </Form.Group>
                                {errors.email && (
                                    <span className="text-danger">
                                        {errors.email.message}
                                    </span>
                                )}


                                <Form.Group controlId="mobileNumber">
                                    <Form.Label>Mobile Number</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            className={classNames("", {
                                                "is-invalid": errors?.contact_no,
                                            })}
                                            type="tel"
                                            {...register("contact_no", {
                                                required: "Contact No Number is required",
                                                pattern: {
                                                    value: /^\d{10}$/,
                                                    message: "Contact No number must be a 10-digit number",
                                                },
                                                maxLength: 10
                                            })}
                                            onKeyDown={(event) => {
                                                const key = event.key;
                                                if (!/^\d$/.test(key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab', 'Home', 'End'].includes(key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                            placeholder="Enter Monile Number"
                                            maxLength={10}
                                        />
                                        <InputGroup.Text id="basic-addon1">{charCount}/10 Digits</InputGroup.Text>
                                    </InputGroup>
                                    {errors.contact_no && (
                                        <span className="text-danger">
                                            {errors.contact_no.message}
                                        </span>
                                    )}
                                    <p className='modal-note'>Note : Please Use the Mobile Number Registered with your Credit Card/Loan account</p>
                                </Form.Group>
                                <Form.Group className="terms-text position-relative mb-5 mt-5 d-flex">
                                    <Form.Check
                                        required
                                        name="terms"
                                        {...register("terms", { required: true })}
                                        isInvalid={errors.terms}
                                    />
                                    <p className="ms-2">
                                        {" "}
                                        I have read and agree to
                                        <span className="terms-span-text">
                                            Credit Score Terms of Use
                                        </span>{" "}
                                        and hereby appoint Profcyma as my authorised representative to
                                        receive my{" "}
                                        <span className="terms-span-text">
                                            credit information from Cibil / Equifax / Experian/ CRIF
                                            Highmark
                                        </span>
                                    </p>
                                </Form.Group>
                                <div className='text-center'>

                                    <button
                                        className='free-credit-btn'
                                        type="submit"
                                    // onClick={onSubmit}
                                    >
                                        Get Free Credit Report
                                        <FontAwesomeIcon icon="fa-solid fa-angles-right"
                                            className=' right-angle' />
                                    </button>
                                </div>
                                <div className="get-notify d-flex justify-content-center mt-3">
                                    <div className="get-notify-switch">
                                        <p className="get-notify-text">
                                            <img
                                                className="whatsapp-icon me-2"
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/Finance/Image/icons/whatsapp.png"
                                                }
                                                alt="whatsapp-icon"
                                            />
                                            Get regular Credit Report updates via Whatsapp
                                        </p>
                                    </div>
                                    <Form.Check
                                        type="switch"
                                        checked={futureUpdates}
                                        onChange={handleSwitchChange}
                                    />
                                </div>

                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            </section >
            <SecondCredit
                show={secondModalShow}
                onHide={() => setSecondModalShow(false)}
                // contact_no={contact_no}
                // name={name}
                // email={email}
                // futureUpdates={futureUpdates}
                // gender={gender}
                errors={errors}
                setError={setError}
                setVerified={setVerified}
                getValues={getValues}
                handleSwitchChange={handleSwitchChange}
            />
            {/* <ThirdCredit
                show={thirdModalShow}
                onHide={() => setThirdModalShow(false)}
            /> */}
        </>
    );
};

export default FirstCredit;
