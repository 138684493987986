import React, { useState, useEffect, useContext } from "react";
import './Jp_dash_component.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ITCard from '../../../JP-Common-Elements/JP-Cards/JP_Job_Card/JobITCard/ITCard'

import Jp_browse_cat_card from '../../../JP-Common-Elements/JP-Cards/Jp_browse_cat_card/Jp_browse_cat_card'
import Jpmakediffrencebanner from '../../../homepage/Jpmakediffbanner/Jpmakediffrencebanner'

import Suggetions_company from './Suggetions-company/Suggetions_company'
import Followed_company from './followed_company/Followed_company'
import Form from 'react-bootstrap/Form';
import { Context } from "../../../../../utils/context";

const Jp_dash_component = () => {

  const { getData, postData, IMG_URL } = useContext(Context);
  const [companyFollow, setCompanyFollow] = useState([]);
  const [jobsData, setJobData] = useState();

  const getCompanyFollow = async () => {
    const res = await getData("/job-portal/companies")
    if (res?.success) {
      setCompanyFollow(res?.data?.rows)
    } else {
      console.log("Error");
    }
  }

  const getCompany = async () => {
    const res = await getData(`/job-portal/job-seeker/dashboard/followed`)
    if (res?.success) {
      setJobData(res?.data?.followed_company)
    } else {
      console.log("Error");
    }
  };
  const unfollowCompany = async (id) => {
    const res = await postData(`/job-portal/job-seeker/dashboard/chnage`, { company_id: id })
    if (res?.success) {
      getCompanyFollow();
      getCompany();
    } else {
      console.log("Error");
    }
  };

  useEffect(() => {
    getCompanyFollow();
    getCompany();
  }, []);

  return (
    <>
      <section className='Jp_dash_component-sec'>
        <div className='text-center title-div'>
          <h1 className='jpsect_title mb-5 mt-md-0 mt-sm-3 mt-4'>Job Alerts</h1>
        </div>

        <div className='job-alerts-sec'>
          <div className='row ms-0 me-0'>
            <div className='col-md-8 mx-auto'>
              <ITCard />

              <div className='show-more-div text-center'>
                <Link to='/job-portal/job'><button className='show-more-btn'>Show More <span className='fafa-div'><FontAwesomeIcon icon="fa-solid fa-chevron-down" className="fafa" /></span></button></Link>
              </div>
            </div>
          </div>
        </div>

        <div className='sugg-and-comp-fol-sec'>
          <div className='row'>
            <div className='col-lg-3 col-md-6 order-lg-1 order-1'>
              <Suggetions_company companyFollow={companyFollow} unfollowCompany={unfollowCompany} />
            </div>

            <div className='col-lg-6 col-md-12 order-lg-2 order-3'>
              <Followed_company jobsData={jobsData} unfollowCompany={unfollowCompany} />
            </div>

            <div className='col-lg-3 col-md-6  order-lg-3 order-2'>

              <div className='comp-search-in-div'>
                <Form.Control className='serch-comp-imppp' required type="text" placeholder="Search a Company You Followed" />
                <img className='ser-iccc' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/icons/search2.png'} />
              </div>

              <div className='addvertisement-div'>
                <img className='add-banner' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/advertisement/ban1.png'} />
                <Link to={"/job-portal/blog"}>
                  <button className='know-more-bt'>Know More</button>
                </Link>
              </div>
            </div>

          </div>
        </div>

        <div className="car-ban-sec">
          <Jpmakediffrencebanner />
        </div>

        <div className='job-suggetion-div mt-5 mb-5'>
          <div className='title-div text-center mb-5'>
            <h1 className='jpsect_title '>Job Suggetions</h1>
          </div>
          <Jp_browse_cat_card />
        </div>




      </section>
    </>
  )
}

export default Jp_dash_component