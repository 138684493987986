import React from 'react'

import '../../homepage/Jpsearchbar/Jpsearchbar.css'
import Jpsearchbar from '../../homepage/Jpsearchbar/Jpsearchbar';
import Nav from 'react-bootstrap/Nav';

const JPbanner = ({ setJobTypeId, setCityId, setJobTitle, setFrom }) => {

  return (
    <div>
      <section className='jp-homesearch-section'>
        <div className='container-fluid p-0'>
          <div className='sarch-main-div'>
            <div className='image-overlay'></div>
            <img className='banner-imggg' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/home/home-search/banner.png'} />

            <div className='overlay-ban-cont '>
              <div className='container'>
                <h2 className='bann-title'>Jobs for You</h2>

                <div className='ban-title-subb-div'>
                  <p className='ban-title-subb'>Find a Desired Job within Clicks</p>
                </div>

                <Jpsearchbar
                  setJobTypeId={setJobTypeId}
                  setCityId={setCityId}
                  setJobTitle={setJobTitle}
                  setFrom={setFrom}
                />

                <div className='pop-ser-div'>
                  <Nav className='justify-content-center'>
                    <Nav.Item>
                      <Nav.Link className=' pop-serach-text'>Popular Searches: </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link href="" >Content Writer</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link href="" >Finance</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link href="" >Human Resource</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link href="" >Management</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link className='clearallbtn'>Clear All</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>

              </div>
            </div>

          </div>
        </div>
      </section>
    </div>
  )
}

export default JPbanner
