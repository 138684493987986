import React, { useEffect, useContext, useState } from "react";
import { useForm } from 'react-hook-form';
import './Jp_latest_update.css'
import ResetModal from '../../JP-Common-Elements/Jp-modals/ResetModal/ResetModal';
import Form from 'react-bootstrap/Form';
import { Context } from "../../../../utils/context";
import classNames from "classnames";

const Jp_latest_update = () => {
    const [modalEmail, setModalEmail] = useState(false);
    const openEmail = () => {
        setModalEmail(true);

        setTimeout(() => {
            window.location = ('');
            setModalEmail(false);
        }, 3000)
    }

    const { IMG_URL, postData } = useContext(Context);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();
    // const navigate = useNavigate();
    const [emailerror, setemailErrors] = useState("");

    const onSubmit = async (d) => {
        const res = await postData(`/without-login/info-subscribe`, d);
        if (res?.success) {
            reset();
            // setModalShow(true)
            setTimeout(() => {
                // setModalShow(false)
                window.scrollTo(0, 0)
            }, 3000);
        } else {
            setemailErrors(res?.message);
        }
    }
    return (
        <>
            <section className='Jp_latest_update_section'>
                <div className='container'>
                    <div className='main-bg'>
                        <img className='bg-img' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/home/latest-update/banner.png'} />

                        <div className='overlay-connntt'>
                            <h1 className='jpsect_title bna-tt'>Stay Updated with Latest Updates </h1>
                            <p className='jpsect_subtitle subbtt'>Subscribe to get market latest updates</p>
                            <Form onSubmit={handleSubmit(onSubmit)}>
                                <div className='search-div'>
                                    <Form.Control
                                        type="email"
                                        placeholder="Email"
                                        {...register("email", {
                                            required: "Email Id required",
                                            pattern: {
                                                value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                                                message: "Email Id Invalid",
                                            },
                                        })}
                                        className={classNames("", {
                                            "is-invalid": errors?.email,
                                        })}
                                    />
                                    {errors.email && (
                                        <span className="text-danger">{errors.email.message}</span>
                                    )}
                                    <img className='emaill-icc' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/icons/email.png'} />

                                    <button
                                        className='subscribe-btn'
                                        // onClick={openEmail}
                                        type="submit"
                                    >
                                        <img className='check-ic' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/icons/check.png'} />
                                        Subscribe
                                    </button>
                                </div>
                            </Form>
                            <ResetModal show={modalEmail}
                                onHide={() => setModalEmail(false)}
                                text={"Your Subscription is Successfully!"}
                            />

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Jp_latest_update