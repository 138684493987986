import React, { useEffect, useState, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "../../Service-Modal-Style/ServiceModalStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
//import OfferTableModal from "./HomeLoanSteps/Offer_Table_Modal/OfferTableModal";
import { Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import StepOneUnlockBestHome from "../../F-Insurance/FHomeInsuranceModal/HomeInsuranceSteps/StepOneUnlockBestHome/StepOneUnlockBestHome";
import VerifyMobileNumber from "../../F-Insurance/FHomeInsuranceModal/HomeInsuranceSteps/StepOneUnlockBestHome/VerifyMobileNumber/VerifyMobileNumber";
import { getData } from "../../../../../utils/api";
import TopServicesInsurance from "../../../F-HomePage/TopServicesInsurance/TopServicesInsurance";
import { Context } from "../../../../../utils/context";
import { useNavigate } from "react-router-dom";


library.add(fas);

function FHomeInsuranceModal(props) {

  const navigate = useNavigate();
  const { getData, IMG_URL, postData } = useContext(Context);
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(1);
  const [subtitle, setSubtitle] = useState(
    "Unlock the Best Home Loan Offers Suitable for your needs from 20+ Lenders"
  );

  const [showVerifyMobile, setShowVerifyMobile] = useState(false); // State to manage visibility of VerifyMobileNumber modal
  const [showPleaseWaitModal, setShowPleaseWaitModal] = useState(false);
  const [showTable, setShowTable] = useState(false);

  const [service_id, setService_id] = useState('');
  const [insurance_form_id, setInsurance_form_id] = useState(Cookies.get("insurance_form_id"));
  const [insuranceFormDetail, setLoanFormDetail] = useState({});
  const [loanFormDetails, setLoanFormDetails] = useState({});
  const [mobileVerify, setMobileVerify] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [otpErr, setOtpErr] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [verifyStat, setVerifyStat] = useState(true);
  const [formId, setFormId] = useState("");

  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [contact_no, setContact_no] = useState('');
  const [nameErr, setNameErr] = useState('');
  const [contactErr, setContactErr] = useState('');
  const [dobErr, setDobErr] = useState('');

  useEffect(() => {
    setInsurance_form_id(Cookies.get("insurance_form_id"));
    getLoanFormDetails(Cookies.get("insurance_form_id"));
  }, [Cookies.get("insurance_form_id")]);

  const getLoanFormDetails = async (id) => {
    try {
      const res = await getLoanDetails(id);
      if (res?.success) {
        console.log(res, "users");
        setLoanFormDetail(res.data);
        setMobileVerify(true);
        setFormId(res.data.id);
        setDob(res.data.dob);
        setName(res.data.name);
        setContact_no(res.data.contact_no);
        // setContactNo(res.data.contact_no);
        console.log("formId", res.data.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  console.log("insurance_id ::---", insurance_form_id);

  useEffect(() => {
    setService_id(Cookies.get("service_id"), "llllllll");
    getLoanFormDetails(Cookies.get("insurance_form_id"));
    console.log("insurance_form_id", insurance_form_id);
    console.log("service_id", service_id);
  }, []);

  const getLoanDetails = async (id) => {
    try {
      const res = await getData(
        `/finance/forms/insurance-form/${id}`
      );
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  const handleNext = async () => {

    if (!name) {
      setNameErr("Name Is Required")
    } else {
      setNameErr()
    }
    if (!dob) {
      setDobErr("DOB Is Required")
    } else {
      setDobErr()
    }
    if (!contact_no) {
      setContactErr("Contact No Is Required")
    } else if (contact_no && contact_no?.length < 13) {
      setContactErr("Invalid Contact No")
    } else {
      setContactErr()
      handleGetOTP()
    }
  };

  const handleGetOTP = async () => {
    console.log(contact_no.length, "contact_nocontact_nocontact_no");
    if (contact_no && contact_no.length === 13) {
      const res = await postData(`/without-login/verify-otp/otp-send`, { contact_no });
      if (res?.success) {
        console.log(res, "otp");
        setShowVerifyMobile(true);
      } else {
        // Handle error response here
        console.error("Failed to send OTP:", res.message);
      }
    } else {
      // Handle case when contact_no is not available
      console.error("Valid Contact number is required");
      // setContactError("Contact number is required");
    }
  };

  console.log(dob, "date of birth");
  console.log(contact_no, "contact_no");
  const handleVerifyAndLogin = async () => {

    if (!otp) {
      setOtpErr("please Enter Otp");
    } else if (otp.length !== 4) {
      setOtpErr("please Enter Complete Otp");
    }

    if (contact_no && otp) {
      console.log("i am here :- ", contact_no, otp);
      const res = await postData(`/without-login/verify-otp/verify-otp`, { contact_no, otp });
      if (res?.success) {

        setMobileVerify(true);
        setIsPhoneNumberValid(true);
        // handelSubmitUser();
        handleSubmitNewUser()
        setSeconds(90);
        setShowVerifyMobile(false)
        // window.location = `/finance/insurance/${service_id}`;

      } else {
        setOtpErr(res?.message);
      }
    }
    console.error("Contact number is required");
    setShowVerifyMobile(false); // Close the VerifyMobileNumber modal
    // setCurrentStep(2); // Move to Step Two
  };

  const handleOtpChange = (otpValue) => {
    console.log("otpValue :-", otpValue);
    setOtp(otpValue);
    // handleVerifyAndLogin();

  };

  const handleSubmitNewUser = async () => {
    try {
      const formData = new FormData();
      if (insurance_form_id) {
        formData.append('insurance_form_id', insurance_form_id);
      }
      if (name) {
        formData.append('name', name);
      }
      if (dob) {
        formData.append('dob', dob);
      }
      formData.append('contact_no', contact_no);
      formData.append('service_id', Cookies.get("service_id"));
      console.log("serviccccc", Cookies.get("service_id"));
      const res = await postData(`/finance/forms/insurance-form`, formData);
      if (res?.success) {
        Cookies.set("insurance_form_id", res.data.id, {
          expires: 1,
        });
        // setShowPleaseWaitModal(true);
        props.onHide();
        navigate(`/finance/insurance/${service_id}`);
      }

    } catch (error) {
      console.error(error);
    }
  }

  // const handelSubmitUser = async () => {
  //   try {
  //     const formData = new FormData();
  //     formData.append('contact_no', contact_no);
  //     if (insurance_form_id) {
  //       formData.append('insurance_form_id', insurance_form_id);
  //     }
  //     formData.append('service_id', service_id);
  //     if (name) {
  //       formData.append('name', name);
  //     }
  //     formData.append('dob', dob);
  //     const res = await postData(`/finance/forms/insurance-form`, formData);
  //     if (res?.success) {
  //       console.log("create.id :- ", res.data.id);
  //       Cookies.set("insurance_form_id", res.data.id, {
  //         expires: 1,
  //       });
  //       if (!mobileVerify) {
  //         setMobileVerify(true);
  //       }
  //       window.location = `/finance/insurance/${service_id}`;

  //       // setCurrentStep(currentStep + 1)
  //       // console.log("insurance_form_id", res?.data);
  //       // window.location = `/finance/insurance/${service_id}`;

  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  useEffect(() => {
    // Define your subtitle for each step here
    const subtitles = [
      "Unlock the Best Home Loan Offers Suitable for your needs from 20+ Lenders",
    ];

    // Update the subtitle based on the current step
    if (currentStep <= subtitles.length) {
      setSubtitle(subtitles[currentStep - 1]);
    }
  }, [currentStep]);

  // const handleProceed = () => {
  //   if (currentStep === 1) {
  //     setShowVerifyMobile(true);
  //   } else if (currentStep < totalSteps) {
  //     setCurrentStep(currentStep + 1);
  //   } else {
  //     props.onHide();
  //   }
  // };

  const handleProceed = () => {
    if (currentStep === 1 && !mobileVerify) {
      // Show VerifyMobileNumber modal on step one
      handleNext();
    } else if (currentStep === 1 && mobileVerify) {
      handleSubmitNewUser();
      // window.location = `/finance/insurance/${service_id}`;
    } else {
      // Handle form submission or any other action when all steps are completed
      props.onHide();
    }
  };

  console.log(mobileVerify, "mobileVerify mobileVerify");
  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const pageRedirect = (insurance_form_id) => {
    setShowTable(false);
    navigate(`/finance/home-loan/${insurance_form_id}`);
  };
  const pageHomeRedirect = () => {
    // window.location = "/finance/home";
    props.onHide();
  };


  const steps = [{ component: StepOneUnlockBestHome }];

  const totalSteps = steps.length;
  const [error, setErrors] = useState("");
  useEffect(() => {
    if (showPleaseWaitModal) {
      const timer = setTimeout(() => {
        setShowPleaseWaitModal(false);
        setShowTable(true); // After timeout, show the VerifyMobileNumber modal
      }, 3000); // 3 seconds timeout
      return () => clearTimeout(timer);
    }
  }, [showPleaseWaitModal]);



  return (
    <>
      {/* loan-insurance-modal */}
      <div className="loan-insurance-modal">
        <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="loan-insurance-modal content-body-holder"
        >
          <div className="modal-content-background">
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                {currentStep == 1 ? (
                  <span onClick={pageHomeRedirect}>
                    <FontAwesomeIcon
                      className="me-2"
                      icon="fa-solid fa-angle-left"
                    />
                    Back
                  </span>
                ) : (
                  <span onClick={handleBack}>
                    <FontAwesomeIcon
                      className="me-2"
                      icon="fa-solid fa-angle-left"
                    />
                    Back
                  </span>
                )}
                <h4>{props.serviceName}</h4>
              </Modal.Title>
              {/* <h4 className="subtitle">{subtitle}</h4> */}
            </Modal.Header>
            <div className="step-ten-eleven-background-color content-body">
              <Modal.Body>
                {steps[currentStep - 1] && (
                  <div className="Home_Loan_Step">

                    {React.createElement(steps[currentStep - 1].component, {
                      insuranceFormDetail: insuranceFormDetail,
                      loanFormDetails: loanFormDetails,
                      mobileVerify: mobileVerify,
                      contact_no: contact_no,
                      name: name,
                      dob: dob,
                      nameErr: nameErr,
                      dobErr: dobErr,
                      contactErr: contactErr,


                      setContact_no: setContact_no,
                      setDob: setDob,
                      setMobileVerify: setMobileVerify,
                      setName: setName,
                      setService_id: setService_id
                      // setUserData: setUserData,
                    })}
                  </div>
                )}
                {otpErr && (
                  <span className="text-danger">{otpErr}</span>
                )}
              </Modal.Body>

              <Modal.Footer>
                <Button className="submit-btn" onClick={handleProceed}>
                  Proceed
                  <FontAwesomeIcon
                    className="right-angle-arrow "
                    icon="fa-solid fa-angles-right"
                  />
                </Button>
              </Modal.Footer>
            </div>
          </div>
        </Modal>
      </div>

      {/* VerifyMobileNumber modal */}

      <div className="VerifyMobileNumber-modal">
        <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static" // Prevent closing when clicking outside
          keyboard={false} // Prevent closing when pressing Esc key
          className="loan-insurance-modal VerifyMobileNumber-modal content-body-holder"
          show={showVerifyMobile}
          onHide={() => setShowVerifyMobile(false)}
        >
          <div className="content-body">
            <Modal.Header>
              <FontAwesomeIcon
                icon="fa-solid fa-circle-xmark"
                onClick={() => setShowVerifyMobile(false)}
              />
            </Modal.Header>
            <Modal.Body>
              <VerifyMobileNumber
                onOtpChange={handleOtpChange}
                contact_no={contact_no}
                otpError={otpErr}
                setOtpErr={setOtpErr}
              />
            </Modal.Body>

            <Modal.Footer>
              <Button className="submit-btn" onClick={handleVerifyAndLogin}>
                Verify & Login
              </Button>

              <div className="get-notify">
                <Form className="get-notify-switch">
                  <div className="whatsapp-icon-holder">
                    <img
                      className="whatsapp-icon"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Finance/Image/icons/whatsapp.png"
                      }
                      alt="whatsapp-icon"
                    />
                  </div>
                  <span>Get regular Credit Report updates via Whatsapp</span>
                  <Form.Check // prettier-ignore
                    type="switch"
                  />
                </Form>
              </div>

              <div className="Terms-And-Conditions-Text">
                <p>
                  By logging in, you agree to the following
                  <span>
                    Credit Report Terms of Use,Terms of Use and Privacy Policy
                  </span>
                </p>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div>

      {/* Modify Details modal */}
      <div className="VerifyMobileNumber-modal">
        <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static" // Prevent closing when clicking outside
          keyboard={false} // Prevent closing when pressing Esc key
          className="loan-insurance-modal Modify-Details "
          show={showTable}
          onHide={() => setShowTable(false)}
        >
          <div className="modal-content-background">
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Modify Details
              </Modal.Title>
              <FontAwesomeIcon
                className="last-close"
                icon="fa-solid fa-circle-xmark"
                onClick={pageRedirect}
              />
            </Modal.Header>
            <Modal.Body>
              {/* <OfferTableModal /> */}
            </Modal.Body>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default FHomeInsuranceModal;
