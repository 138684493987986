import React, { useContext, useEffect, useState } from "react";
import '../Jp_applied_job_cards/Jp_applied_job_cards.css'
import { Context } from "../../../../../utils/context";
import { interviewResult, schedule } from "../../../../../utils/apis/common/Common";
import Interview_reject_modal from '../../Jp-modals/Interview_reject_modal/Interview_reject_modal';

const Jp_interview_results_cards = () => {

    const [modalShow, setModalShow] = React.useState(false);
    const [getInterviewResult, setInterviewResult] = useState();
    const { getData, postData, IMG_URL } = useContext(Context);
    const [compid, setCompanyId] = useState();

    const getproject = async () => {
        const res = await interviewResult();
        if (res?.success) {
            setInterviewResult(res?.data?.data);
        } else {
            console.error("Error fetching  data:", res?.error);
        }
    };

    useEffect(() => {
        getproject();
    }, []);

    const acceptCompany = async (id) => {
        const res = await schedule({ id: id, i_status: 1 });
        if (res?.success) {
            getproject();
        } else {
            console.log("Error");
        }
    };
    const [empId, setEmpId] = useState();
    const openPopUp = async (id, employer_id) => {
        if (id) {
            setModalShow(true);
            setCompanyId(id);
            setEmpId(employer_id)

        }
    };

    const changeStatus = async (id, status, employer_id) => {
        const data = {
            interview_id: id,
            status: status,
            employer_id: employer_id,
        }
        const res = await postData(`/job-portal/job-seeker/jobs/update`, data);
        if (res?.success) {
            getproject();
        } else {
            console.log("Error");
        }
    };



    return (
        <>
            <section className='Jp_applied_job_cards_section'>
                <div className='row'>
                    <div className='col-md-11'>
                        <div className='row'>
                            {getInterviewResult?.map((data, index) => (
                                <div className='col-md-6 mb-4'>
                                    <div className='applied-job-card'>
                                        <img className='comp-img' src={IMG_URL + data?.job_portal_job?.company_logo} />
                                        <h6 className='comp-name'>{data?.job_portal_job?.company_name}</h6>
                                        <h5 className='comp-position'>{data?.job_portal_job?.job_title?.name}</h5>


                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <div className='d-flex mb-1'>
                                                    <img className='comp-loc' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/icons/card-location.png'} />
                                                    <p className='applied-date'>{data?.job_portal_job?.city?.name} </p>
                                                </div>
                                                <div className='d-flex  mb-1'>
                                                    <img className='comp-cal' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/icons/calender.png'} />
                                                    <p className='applied-date'> {data?.date}
                                                    </p>
                                                </div>
                                                <div className='d-flex  mb-1'>
                                                    <img className='comp-cal' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/icons/watch.png'} />
                                                    <p className='applied-date'> {data?.s_time} - {data?.e_time} </p>
                                                </div>
                                                <div className='d-flex  mb-1'>
                                                    <img className='comp-cal' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/icons/hr.png'} />
                                                    <p className='applied-date'> {data?.round_name} </p>
                                                </div>

                                                <div className='d-flex  mb-1'>
                                                    <p className='applied-date'>Interview Scheduled</p>

                                                    {data?.i_status == null ? (
                                                        <>
                                                            <button className='off-acc-btt' onClick={() => {
                                                                changeStatus(data?.id, "Accepted", data?.employer_id);
                                                            }}>Accept</button>
                                                            <button className='off-rej-btt' onClick={() => {
                                                                openPopUp(data?.id, data?.employer_id);
                                                            }}>Reject</button>
                                                        </>
                                                    ) : data?.i_status == 1 ? (
                                                        <p className='off-acc-btt'>offer Accept</p>
                                                    ) : (
                                                        <p className='off-acc-btt'>offer rejected</p>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>

                <Interview_reject_modal
                    show={modalShow}
                    compid={compid}
                    empId={empId}
                    getproject={getproject}
                    onHide={() => setModalShow(false)}
                />
            </section>
        </>
    )
}

export default Jp_interview_results_cards