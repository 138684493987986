import React, { useState, useEffect, useContext } from "react";
import classNames from "classnames";
import "react-international-phone/style.css";
import { Form } from "react-bootstrap";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from "../../../../../../../utils/context";
import Select from "react-select";

function StepFiveProvideEmploymentDetail({ setCompany_id, loanFormDetail }) {

  useEffect(() => {
    if (loanFormDetail) {
      console.log(loanFormDetail);
      reset(loanFormDetail); // Reset the form field named 'employmentType'
      console.log("resetting setCompany_id");
    }
  }, [loanFormDetail]);
  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);

  const [startDate, setStartDate] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [phone, setPhone] = useState("in");

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset
  } = useForm();
  const [companies, setCompanies] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);


  const GetAllCompanies = async () => {
    const response = await getData("/without-login/master/all-finance-companies");

    if (response.success) {
      setCompanies(await Select2Data(response?.data, "company_id"));
    }
  };

  useEffect(() => {
    GetAllCompanies();
  }, []);





  return (
    <div className="StepOneUnlockBestHome form-style">
      <div className="contact-number">
        <Form >
          <Form.Group className="" controlId="formBasicEmail">
            <Form.Label>Company Name</Form.Label>
            <div className="input-span-holder">
              <div className="country-coder">
                {/* <Form.Control
                  type="text"
                  placeholder="Enter Your Company Name"
                /> */}
                <Controller
                  name="company_id" // name of the field
                  {...register("company_id", {
                    required: "Company is required",
                  })}
                  control={control}
                  render={({ field }) => (
                    <Select
                      styles={{
                        control: (baseStyles) => ({
                          ...baseStyles,
                          borderColor: errors.company_id
                            ? "red"
                            : baseStyles,
                        }),
                      }}
                      {...field}
                      options={companies}
                      isMult
                      isSearchable
                      onChange={(selectedOption) => { setCompany_id(selectedOption.value); }}
                    />
                  )}
                />
                {errors?.company_id && (
                  <span className="text text-danger">
                    {errors.company_id.message}
                  </span>
                )}
              </div>
            </div>
            <Form.Text className="text-muted">
              Type slowly to select your company
            </Form.Text>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
}

export default StepFiveProvideEmploymentDetail;
