import React, { useEffect, useState, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import "../../Service-Modal-Style/ServiceModalStyle.css";
import StepOneUnlockBestHome from "./HomeLoanSteps/StepOneUnlockBestHome/StepOneUnlockBestHome";
import StepTwoEmploymentType from "./HomeLoanSteps/StepTwoEmploymentType/StepTwoEmploymentType";
import StepThreeSelectYearlyIncome from "./HomeLoanSteps/StepThreeSelectYearlyIncome/StepThreeSelectYearlyIncome";
import StepFourSelectPrimaryBankAccount from "./HomeLoanSteps/StepFourSelectPrimaryBankAccount/StepFourSelectPrimaryBankAccount";
import StepFiveProvideEmploymentDetail from "./HomeLoanSteps/StepFiveProvideEmploymentDetail/StepFiveProvideEmploymentDetail";
import StepSixProvideEmploymentDetail from "./HomeLoanSteps/StepSixProvideEmploymentDetail/StepSixProvideEmploymentDetail";
import StepSevenSelectResidenceCity from "./HomeLoanSteps/StepSevenSelectResidenceCity/StepSevenSelectResidenceCity";
import StepEightSelectResidenceType from "./HomeLoanSteps/StepEightSelectResidenceType/StepEightSelectResidenceType";
import StepNineSelectHomeLoanAmount from "./HomeLoanSteps/StepNineSelectHomeLoanAmount/StepNineSelectHomeLoanAmount";
import StepTenGetBestOffers from "./HomeLoanSteps/StepTenGetBestOffers/StepTenGetBestOffers";
import StepElevenGetBestOffers from "./HomeLoanSteps/StepElevenGetBestOffers/StepElevenGetBestOffers";
import VerifyMobileNumber from "./HomeLoanSteps/StepOneUnlockBestHome/VerifyMobileNumber/VerifyMobileNumber";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import PleaseWaitModal from "./HomeLoanSteps/PleaseWaitModal/PleaseWaitModal";
import OfferTableModal from "./HomeLoanSteps/Offer_Table_Modal/OfferTableModal";
import { Link, useLocation } from "react-router-dom";
import { Context } from "../../../../../utils/context";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
//import { Navigate } from "react-router-dom";

library.add(fas);

function FHomeLoanModal(props) {

  const { onNext, serviceId } = props;
  const navigate = useNavigate();

  useEffect(() => {
    console.log("serviceId", serviceId);
    console.log("service_Id", service_id);
  }, [serviceId]);

  const { getData, IMG_URL, postData } = useContext(Context);
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(1);
  const [subtitle, setSubtitle] = useState(
    "Unlock the Best Home Loan Offers Suitable for your needs from 20+ Lenders"
  );

  const [showVerifyMobile, setShowVerifyMobile] = useState(false); // State to manage visibility of VerifyMobileNumber modal
  const [showPleaseWaitModal, setShowPleaseWaitModal] = useState(false);
  const [showTable, setShowTable] = useState(false);

  const [service_id, setService_id] = useState('');
  const [loan_form_id, setLoan_form_id] = useState(Cookies.get("loan_form_id"));
  const [loanFormData, setLoanFormData] = useState({});
  const [loanFormDetail, setLoanFormDetail] = useState({});
  const [contact_no, setContact_no] = useState('');
  const [mobileVerify, setMobileVerify] = useState(false);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [otpErr, setOtpErr] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [verifyStat, setVerifyStat] = useState(true);
  const [formId, setFormId] = useState("");
  const [userData, setUserData] = useState({
    gender: "",
    address: "",
    pincode_id: "",
    pan_no: ""
  });

  console.log("loanFormDetail", loanFormDetail);

  const [employment_type_id, setEmployment_type_id] = useState('');
  const [yearly_income_id, setYearlyIncomeId] = useState('');
  const [bank_id, setBank_id] = useState('');
  const [company_id, setCompany_id] = useState('');
  const [city_id, setCity_id] = useState('');
  const [residence_type_id, setResidence_type_id] = useState('');
  const [desired_amount_id, setDesired_amount_id] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [address, setAddress] = useState('');
  const [pincode_id, setPincode_id] = useState('');
  const [pan_no, setPan_no] = useState('');
  const [nameErr, setNameErr] = useState('');
  const [contactErr, setContactErr] = useState('');
  const [dobErr, setDobErr] = useState('');

  useEffect(() => {
    setLoan_form_id(Cookies.get("loan_form_id"))
  }, [Cookies.get("loan_form_id")])

  const getLoanForm = async (id) => {
    const res = await getLoanFormData(id);
    if (res?.success) {
      console.log(res, "users");
      setLoanFormData(res.data);
      setMobileVerify(true);
      setFormId(res.data.id);
      setPincode_id(res.data.pincode_id)
      setFormId(res.data.id);
      setDob(res.data.dob);
      setName(res.data.name);
      setContact_no(res.data.contact_no);
      console.log("formId", res.data.id);
    }
    setLoan_form_id(Cookies.get("loan_form_id"));
    setService_id(serviceId);
  };

  console.log("loan_form_id:---", loan_form_id);

  const getLoanFormData = async (id) => {
    try {
      const res = await getData(
        `/finance/forms/loan-form/${id}`
      );
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  const getLoanFormDetail = async () => {
    console.log(Cookies.get("loan_form_id"), "llllllll");
    const res = await getLoanFormDetailsData(Cookies.get("loan_form_id"));
    if (res?.success) {
      console.log(res, "details");

      setLoanFormDetail(res.data);
    }
  };

  const getLoanFormDetailsData = async (loan_form_id) => {
    try {
      const res = await getData(
        `/finance/forms/loan-form-details/${loan_form_id}`
      );
      return res;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getLoanForm(Cookies.get("loan_form_id"));
    getLoanFormDetail();
    console.log("loan_form_id", loan_form_id);
  }, []);

  useEffect(() => {
    console.log("loanFormData :- ", loanFormData);
    console.log("loanFormDetails :- ", loanFormDetail);
  }, []);

  const handleNext = async () => {

    if (!name) {
      setNameErr("Name Is Required")
    } else {
      setNameErr()
    }
    if (!dob) {
      setDobErr("DOB Is Required")
    } else {
      setDobErr()
    }
    if (!contact_no) {
      setContactErr("Contact No Is Required")
    } else if (contact_no && contact_no?.length < 13) {
      setContactErr("Invalid Contact No")
    } else {
      setContactErr()
      handleGetOTP()
    }
  };

  const handleGetOTP = async () => {
    if (contact_no) {
      const res = await postData(`/without-login/verify-otp/otp-send`, { contact_no });
      if (res?.success) {
        console.log(res, "otp");
        setShowVerifyMobile(true);
        setOtpErr()
      } else {
        // Handle error response here
        console.error("Failed to send OTP:", res.message);
      }
    } else {
      // Handle case when contact_no is not available
      console.error("Contact number is required");
      // setContactError("Contact number is required");
    }
  };

  const handleVerifyAndLogin = async () => {
    if (!otp) {
      setOtpErr("please Enter Otp");
    } else if (otp.length !== 4) {
      setOtpErr("please Enter Complete Otp");
    }
    if (contact_no) {
      const res = await postData(`/without-login/verify-otp/verify-otp`, { contact_no, otp });
      if (res?.success) {
        setMobileVerify(true);
        // setShowOtpInput(false);
        setIsPhoneNumberValid(true);
        // clearErrors();
        // handelSubmitUser();
        setSeconds(90);
        setShowVerifyMobile(false)
        handleSubmitNewUser()
        // setCurrentStep(currentStep + 1)
        // setCurrentStep(2);
      } else {
        setOtpErr(res?.message);
      }
    }

    // setShowVerifyMobile(false); // Close the VerifyMobileNumber modal
    // setCurrentStep(2); // Move to Step Two
  };

  const handleOtpChange = (otpValue) => {
    setOtp(otpValue);
    // handleVerifyAndLogin();
  };

  const handleSubmitNewUser = async () => {
    try {
      const formData = new FormData();
      if (loan_form_id) {
        formData.append('id', Cookies.get("loan_form_id"));
      }
      if (name) {
        formData.append('name', name);
      }
      if (dob) {
        formData.append('dob', dob);
      }
      formData.append('contact_no', contact_no);
      formData.append('service_id', Cookies.get("service_id"));
      // formData.append('id', Cookies.get("loan_form_id"));
      const res = await postData(`/finance/forms/loan-form`, formData);
      if (res?.success) {
        Cookies.set("loan_form_id", res.data.id, {
          expires: 1,
        });
        // setShowPleaseWaitModal(true);
        props.onHide();
        navigate(`/finance/home-loan/${Cookies.get("service_id")}`);
      }

    } catch (error) {
      console.error(error);
    }
  }

  const handelSubmitUser = async () => {
    try {
      const formData = new FormData();
      formData.append('contact_no', contact_no);
      if (loan_form_id) {
        formData.append('id', loan_form_id);
      }
      if (name) {
        formData.append('name', name);
      }
      if (dob) {
        formData.append('id', dob);
      }
      if (email) {
        formData.append('email', email);
      }

      if (currentStep === 10) {
        formData.append('gender', userData?.gender);
        formData.append('address', userData?.address);
        formData.append('pincode_id', userData?.pincode_id);
        formData.append('pan_no', userData?.pan_no);
        console.log("in user submit");
      }
      const res = await postData(`/finance/forms/loan-form`, formData);
      if (res?.success) {
        if (currentStep === 1) {
          Cookies.set("loan_form_id", res.data.id, {
            expires: 1,
          });
        }
        else if (currentStep === 10) {
          console.log("in user submit if condition");
          setShowPleaseWaitModal(true);

        } else {
          // setCurrentStep(currentStep + 1)
        }

      }

    } catch (error) {
      console.error(error);
    }
  }

  const handelSubmitUserr = async () => {
    setShowPleaseWaitModal(true);
    // try {
    //   const formData = new FormData();
    //   formData.append('id', loan_form_id);
    //   formData.append('gender', userData?.gender);
    //   formData.append('address', userData?.address);
    //   formData.append('pincode_id', userData?.pincode_id);
    //   formData.append('pan_no', userData?.pan_no);

    //   const res = await postData(`/finance/forms/loan-form`, formData);

    //   if (res?.success) {
    //     // setShowPleaseWaitModal(true);
    //     props.onHide();
    //   }

    // } catch (error) {
    //   console.error(error);
    // }
  }

  const handelSubmitUserDetails = async () => {
    try {
      const formData = new FormData();
      formData.append('loan_form_id', loan_form_id);
      if (employment_type_id) {
        formData.append('employment_type_id', employment_type_id);
      }
      if (service_id) {
        formData.append('service_id', service_id);
      }
      if (yearly_income_id) {
        formData.append('yearly_income_id', yearly_income_id);
      }
      if (bank_id) {
        formData.append('bank_id', bank_id);
      }
      if (company_id) {
        formData.append('company_id', company_id);
      }
      if (city_id) {
        formData.append('city_id', city_id);
      }
      if (residence_type_id) {
        formData.append('residence_type_id', residence_type_id);
      }
      if (desired_amount_id) {
        formData.append('desired_amount_id', desired_amount_id);
      }

      const res = await postData(`/finance/forms/loan-form-details`, formData);
      if (res?.success) {
        setCurrentStep(currentStep + 1)
      }
    } catch (error) {
      console.error(error);
    }
  }


  useEffect(() => {
    // Define your subtitle for each step here
    const subtitles = [
      "Unlock the Best Home Loan Offers Suitable for your needs from 20+ Lenders",
      "Employment Type",
      "Select your Yearly Income",
      "Select your Primary Bank Account",
      "Please provide your Employment Detail",
      // "Please provide your Employment Detail",
      "Select your Residence City",
      "Select your Residence Type",
      "Select your desired Home Loan Amount",
      "One step away to Get Best Offers",
      "One step away to Get Best Offers",
    ];

    // Update the subtitle based on the current step
    if (currentStep <= subtitles.length) {
      setSubtitle(subtitles[currentStep - 1]);
    }
  }, [currentStep]);

  useEffect(() => {

    console.log("serviceIdaaaaa", serviceId);
  }, [serviceId]);

  useEffect(() => {

    console.log("employment_type_id", employment_type_id);
  }, [employment_type_id]);

  const handleProceed = () => {

    if (currentStep === 1 && !mobileVerify) {
      handleNext();
    }
    // else if (currentStep < 11) {

    // if (currentStep === 2) {
    //   handelSubmitUserDetails()
    // }
    // setCurrentStep(currentStep + 1);
    // if (currentStep === 3) {
    //   handelSubmitUserDetails()
    // }
    // // setCurrentStep(currentStep + 1);
    // if (currentStep === 4) {
    //   handelSubmitUserDetails()
    // }
    // // setCurrentStep(currentStep + 1);
    // if (currentStep === 5) {
    //   handelSubmitUserDetails()
    // }
    // // setCurrentStep(currentStep + 1);
    // if (currentStep === 6) {
    //   handelSubmitUserDetails()
    // }
    // // setCurrentStep(currentStep + 1);
    // if (currentStep === 7) {
    //   handelSubmitUserDetails()
    // }
    // // setCurrentStep(currentStep + 1);
    // if (currentStep === 8) {
    //   handelSubmitUserDetails()
    // }
    // // setCurrentStep(currentStep + 1);
    // if (currentStep === 8) {
    //   handelSubmitUserDetails()
    // }
    // // setCurrentStep(currentStep + 1);
    // if (currentStep === 9) {
    //   handelSubmitUser()
    // }
    // // setCurrentStep(currentStep + 1);
    // if (currentStep === 10) {
    //   console.log("in proceeed");
    //   handelSubmitUserr()
    // }
    // setCurrentStep(currentStep + 1);
    // if (currentStep === 11) {
    //   console.log("in step 11");
    //   handelSubmitUser()
    // onNext();
    // }
    // } 
    else {
      // Handle form submission or any other action when all steps are completed
      props.onHide();
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const pageRedirect = (service_id) => {
    setShowTable(false);
    props.onHide();
    navigate(`/finance/home-loan/${Cookies.get("service_id")}`);

  };

  const pageHomeRedirect = () => {
    // window.location = "/finance/home";
    props.onHide();
  };


  // Define your steps here
  const steps = [
    { component: StepOneUnlockBestHome },
    { component: StepTwoEmploymentType },
    { component: StepThreeSelectYearlyIncome },
    { component: StepFourSelectPrimaryBankAccount },
    { component: StepFiveProvideEmploymentDetail },
    // { component: StepSixProvideEmploymentDetail },
    { component: StepSevenSelectResidenceCity },
    { component: StepEightSelectResidenceType },
    { component: StepNineSelectHomeLoanAmount },
    { component: StepTenGetBestOffers },
    { component: StepElevenGetBestOffers },
  ];

  const totalSteps = steps.length;
  const [error, setErrors] = useState("");

  useEffect(() => {
    if (showPleaseWaitModal) {
      const timer = setTimeout(() => {
        setShowPleaseWaitModal(false);
        setShowTable(true);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showPleaseWaitModal]);

  useEffect(() => {
    console.log("userData from main", userData);
  }, [userData]);

  return (
    <>
      {/* loan-insurance-modal */}
      <div className="loan-insurance-modal ">
        <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static" // Prevent closing when clicking outside
          keyboard={false} // Prevent closing when pressing Esc key
          className={`loan-insurance-modal  content-body-holder ${currentStep === 9 || currentStep === 10
            ? "step-ten-eleven content-body-holder"
            : ""
            }`}
        >
          <div className="modal-content-background">
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                {currentStep == 1 ? (
                  <Link to={"/finance/home"}>
                    <span onClick={pageHomeRedirect}>
                      <FontAwesomeIcon
                        className="me-2"
                        icon="fa-solid fa-angle-left"
                      />
                      Back
                    </span>
                  </Link>
                ) : (
                  <span onClick={handleBack}>
                    <FontAwesomeIcon
                      className="me-2"
                      icon="fa-solid fa-angle-left"
                    />
                    Back
                  </span>
                )}

                <h4>{props.serviceName}</h4>
              </Modal.Title>
              <h4 className="subtitle">{subtitle}</h4>
            </Modal.Header>
            <div className="step-ten-eleven-background-color content-body">
              <Modal.Body>
                <div className="Home_Loan_Step">
                  {/* {React.createElement(steps[currentStep - 1].component)} */}
                  {React.createElement(steps[currentStep - 1].component, {
                    loanFormData: loanFormData,
                    loanFormDetail: loanFormDetail,
                    mobileVerify: mobileVerify,
                    contact_no: contact_no,
                    name: name,
                    dob: dob,
                    nameErr: nameErr,
                    dobErr: dobErr,
                    contactErr: contactErr,

                    setDob: setDob,
                    setName: setName,
                    setContact_no: setContact_no,
                    setEmployment_type_id: setEmployment_type_id,
                    setMobileVerify: setMobileVerify,
                    setYearlyIncomeId: setYearlyIncomeId,
                    setBank_id: setBank_id,
                    setCompany_id: setCompany_id,
                    setCity_id: setCity_id,
                    setResidence_type_id: setResidence_type_id,
                    setDesired_amount_id: setDesired_amount_id,
                    setEmail: setEmail,
                    setUserData: setUserData,


                  })}

                </div>
              </Modal.Body>
              <Modal.Footer>
                {currentStep === 10 && (
                  <div className="Terms-And-Conditions-Text agreement">
                    <FontAwesomeIcon
                      className="circle-icon"
                      icon="fa-solid fa-circle"
                    />
                    <p>
                      I have read and agree to Credit Score Terms of Use and
                      hereby appoint Profcyma as my authorised representative to
                      receive my credit information from Cibil / Equifax /
                      Experian.
                    </p>
                  </div>
                )}

                {!mobileVerify ?
                  (<Button
                    className="submit-btn"
                    onClick={handleProceed}>
                    Proceed{" "}
                    <FontAwesomeIcon
                      className="right-angle-arrow "
                      icon="fa-solid fa-angles-right"
                    />
                  </Button>)
                  :
                  (
                    <Button
                      className="submit-btn"
                      onClick={handleSubmitNewUser}
                    // onClick={() => setShowPleaseWaitModal(true)}
                    >
                      Unlock Best Offers
                    </Button>)}

                {/* {currentStep < 7 ? (
                  <Button className="submit-btn" onClick={handleProceed}>
                    Proceed{" "}
                    <FontAwesomeIcon
                      className="right-angle-arrow "
                      icon="fa-solid fa-angles-right"
                    />
                  </Button>
                ) : currentStep < 9 ? (
                  <Button className="submit-btn" onClick={handleProceed}>
                    Continue
                  </Button>
                ) : currentStep < 10 ? (
                  <Button className="submit-btn" onClick={handleProceed}>
                    Unlock Best Offers
                  </Button>
                ) : (
                  <Button
                    className="submit-btn"
                    onClick={handelSubmitUserr}
                  // onClick={() => setShowPleaseWaitModal(true)}

                  >
                    Unlock Best Offers
                  </Button>
                )} */}

                <PleaseWaitModal
                  show={showPleaseWaitModal}

                  onHide={() => showPleaseWaitModal(false)}
                />

                {/* Conditional rendering for Terms and Conditions */}
                {currentStep === 1 && (
                  <div className="Terms-And-Conditions-Text">
                    <p>
                      By clicking on Proceed, you have read and agree to the
                      Profcyma{" "}
                      <span>
                        Credit Report Terms of Use. Profcyma Terms of Use &
                        Privacy Policy
                      </span>
                    </p>
                  </div>
                )}

                {/* Conditional rendering for Information is safe with Us */}
                {currentStep === 9 && (
                  <div className="Terms-And-Conditions-Text">
                    <p>
                      <span>
                        <FontAwesomeIcon
                          className="check-icon"
                          icon="fa-solid fa-shield"
                        />
                      </span>
                      Your Information is safe with Us
                    </p>
                  </div>
                )}
              </Modal.Footer>
            </div>
          </div>
        </Modal >
      </div >

      {/* VerifyMobileNumber modal */}

      < div className="VerifyMobileNumber-modal" >
        <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static" // Prevent closing when clicking outside
          keyboard={false} // Prevent closing when pressing Esc key
          className="loan-insurance-modal VerifyMobileNumber-modal content-body-holder"
          show={showVerifyMobile}
          onHide={() => setShowVerifyMobile(false)}
        >
          <div className="content-body">
            <Modal.Header>
              <FontAwesomeIcon
                icon="fa-solid fa-circle-xmark"
                onClick={() => setShowVerifyMobile(false)}
              />
            </Modal.Header>
            <Modal.Body>
              <VerifyMobileNumber
                onOtpChange={handleOtpChange}
                contact_no={contact_no}
                otpError={otpErr}
                setOtpErr={setOtpErr}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button className="submit-btn" onClick={handleVerifyAndLogin}>
                Verify & Login
              </Button>
              <div className="get-notify">
                <Form className="get-notify-switch">
                  <div className="whatsapp-icon-holder">
                    <img
                      className="whatsapp-icon"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Finance/Image/icons/whatsapp.png"
                      }
                      alt="whatsapp-icon"
                    />
                  </div>
                  <span>Get regular Credit Report updates via Whatsapp</span>
                  <Form.Check // prettier-ignore
                    type="switch"
                  />
                </Form>
              </div>

              <div className="Terms-And-Conditions-Text">
                <p>
                  By logging in, you agree to the following
                  <span>
                    Credit Report Terms of Use,Terms of Use and Privacy Policy
                  </span>
                </p>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div >

      {/* Modify Details modal */}
      < div className="VerifyMobileNumber-modal" >
        <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static" // Prevent closing when clicking outside
          keyboard={false} // Prevent closing when pressing Esc key
          className="loan-insurance-modal Modify-Details "
          show={showTable}
          onHide={() => setShowTable(false)}
        >
          <div className="modal-content-background">
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                Modify Details
              </Modal.Title>
              <FontAwesomeIcon
                className="last-close"
                icon="fa-solid fa-circle-xmark"
                onClick={pageRedirect}
              />
            </Modal.Header>
            <Modal.Body>
              <OfferTableModal />
            </Modal.Body>
          </div>
        </Modal>
      </div >
    </>
  );
}

export default FHomeLoanModal;
