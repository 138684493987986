import React from "react";
import "./GoogleMap.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const GoogleMap = () => {
  return (
    <>
      <div className="GoogleMap-map-container">
        <div className="mapouter">
          <div className="gmap_canvas">
            <iframe
              width="600"
              height="500"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=%20Profcyma%20Career%20Solution%20%20pune&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              title="Google Map"
            ></iframe>
            <a href="https://embedgooglemap.net/124/">what is 123movies</a>
            <br />
          </div>
          <style>{`
        .mapouter {
          position: relative;
          text-align: right;
          height: 500px;
          width: 600px;
        }
        .gmap_canvas {
          overflow: hidden;
          background: none!important;
          height: 500px;
          width: 600px;
        }
      `}</style>
        </div>
      </div>
    </>
  );
};

export default GoogleMap;
