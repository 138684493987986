import React from 'react'
import '../../homepage/Jpsearchbar/Jpsearchbar.css'
import './Jpcompanybanner.css'
import Nav from 'react-bootstrap/Nav';

import Select from 'react-select'

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";


const Jpcompany_banner = ({ setJobTitle }) => {

    const {
        control,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        setJobTitle(data?.company_name)
    }
    return (
        <>
            <section className='jp-homesearch-section'>
                <div className='container-fluid p-0'>
                    <div className='sarch-main-div'>
                        <div className='image-overlay'></div>
                        <img className='banner-imggg' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/home/home-search/banner.png'} />

                        <div className='overlay-ban-cont camppp-toppp-mob'>
                            <div className='container'>
                                <h2 className='bann-title'>Find Great Places to Work</h2>

                                <div className='ban-title-subb-div'>
                                    <p className='ban-title-subb'>Find Here a Best Places with Place Teams to Work</p>
                                </div>

                                {/* <Jpsearchbar /> */}


                                <section className='jphmsearchbar-section'>
                                    <div className='container'>
                                        <div className='row'>

                                            <div className='col-md-10 mx-auto'>


                                                <div className='barr-div'>
                                                    <div className='row justify-content-center'>

                                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                                            <div className='firstt'>
                                                                <Form.Control
                                                                    aria-label="Small"
                                                                    aria-describedby="inputGroup-sizing-sm"
                                                                    placeholder='Enter Job Title or Company Name'
                                                                    {...register("company_name", {
                                                                        required: "Company Name is required",
                                                                    })}
                                                                    className={classNames("", {
                                                                        "is-invalid": errors?.company_name,
                                                                    })}
                                                                    onKeyDown={(event) => {
                                                                        if (!/[A-Z-a-z ]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                />
                                                                <button className='btn search-btnn' type='submit'>
                                                                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" /> <span className='serarchtextt'>Search</span>
                                                                </button>
                                                                <img className='fa-icc' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/icons/grid.png'} />
                                                            </div>
                                                        </Form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>


                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Jpcompany_banner