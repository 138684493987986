import { Context } from '../../../../utils/context'
import React, { useEffect, useState, useContext } from 'react';
import FBloginnerBanner from './F-Blogbannerinner/FBloginnerBanner'
import FBlogDescription from './F-BlogDescription/FBlogDescription'
import FBlogConclusion from './F-BlogConclusion/FBlogConclusion'
import { useParams } from 'react-router-dom';
const FBlogInnerPage = () => {
  const { id } = useParams();
  console.log(id, "idididididdi");

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState();

  const getblogde = async () => {
    const res = await getData(`/finance/blogs/f-blog/${id}`);
    if (res?.success) {
      setData(res?.data);
    }
    else {
      console.error('Error fetching  data:', res?.error);
    }
  }
  console.log(data, 'ssssssssfsdfsdfsf');

  useEffect(() => {
    getblogde();
  }, [id]);


  return (
    <>
      <FBloginnerBanner data={data} />
      <FBlogDescription data={data} />
      <FBlogConclusion data={data} />
    </>
  )
}

export default FBlogInnerPage