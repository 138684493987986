import React, { useState, useEffect, useContext } from 'react';
import Pagination from "react-bootstrap/Pagination";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import Opportunities from "../../JPjobs/JPforyou/JPjobforyouTabContant/JPjobforyouITTab/Opportunities/Opportunities";
import ITCard from "../../JP-Common-Elements/JP-Cards/JP_Job_Card/JobITCard/ITCard";
import JobsJobFilter from "../../JP-Common-Elements/JP-Job-Filter/Jobs_Job_Filter/JobsJobFilter";
import PaginationSection from "../../JP-Common-Elements/JP-Pegination/PaginationSection/PaginationSection";
import { Link } from "react-router-dom";
import { getProfilePicture } from '../../../../utils/apis/jobseekar/jobseekar';

function JPJobSearch() {

  const [jobSeekarProfileData, setJobSeekarProfiledata] = useState();

  const getJobSeekarProfileData = async () => {
    const res = await getProfilePicture();
    if (res?.success) {
      setJobSeekarProfiledata(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };



  useEffect(() => {
    getJobSeekarProfileData();
  }, []);
  return (
    <>
      <section className="JPforyou">
        <div className="container">
          <div className="heading-holder">
            <div className="row">
              <div className="col-xl-12 col-md-12">
                <div>
                  <h1 className="jpsect_title">
                    {jobSeekarProfileData?.job_seeker_personal_detail?.job_profile_title} and Relevant Jobs
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12 col-md-12">
              <div className="BacktoCompanies">
                <Link to={"/job-portal/jobseeker/dashboard"}>
                  <p className="">Back to Dashboard</p>
                </Link>
              </div>
            </div>
          </div>

          {/*JPforyou tab section start */}
          <div className="JPforyou-tabs">
            <div className="row tabs-content">
              <div className=" col-xl-9 col-md-8 col-12">
                <div className="jobs_tab">
                  <div>
                    <ITCard />
                    <Opportunities />
                    <ITCard />
                  </div>

                  <PaginationSection />
                </div>
              </div>
              <div className="col-xl-3 col-md-4 col-12">
                <div>
                  <JobsJobFilter />
                </div>
              </div>
            </div>
          </div>
          {/*JPforyou tab section end */}
        </div>
      </section>
    </>
  );
}

export default JPJobSearch;
