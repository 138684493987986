import React, { useState, useEffect, useContext } from "react";
import './Jp-come-recent-jobs.css'
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Pagination from "react-bootstrap/Pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Autoplay, Navigation } from "swiper/modules";
import { Button, Col, Nav, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import JPOnlineDegree from '../../JP-Common-Elements/JP-Addvertise/Jp-Online-Specilisation-Addvertise/JPOnlineDegree/JPOnlineDegree';
import CreateAccountAddvertise from '../../JP-Common-Elements/JP-Addvertise/JP-Create-Account-Addvertise/Create-Account-Addvertise/CreateAccountAddvertise';
import Jp_recent_job_card from '../../JP-Common-Elements/JP-Cards/Jp_applied_job_cards/Jp_recent_job_card/Jp_recent_job_card';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Exlamentry from '../../JP-Common-Elements/Jp-modals/Exlamentry_Modal/Exlamentry';
import { Context } from "../../../../utils/context";


const Jp_home_recent_jobs = ({
    job_title,
    city_id,
    job_type_id,
}) => {

    const { getData } = useContext(Context);
    const [modalShow, setModalShow] = useState(false);
    const [recentJobData, setRecentJobdData] = useState();
    const [industryData, setIndustryData] = useState();
    const [selectedId, setSelectedId] = useState();
    const [searchJob, setSearchJobData] = useState();


    const openmodal = () => {
        setModalShow(true);
        setTimeout(() => {
            setModalShow(false);
        }, 3000)
    }


    const getRecentJob = async (selectedId) => {
        try {
            if (selectedId) {
                const res = await getData(`/job-portal/jobs/industry/${selectedId}?job_type_id=${job_type_id?.value || ""}&city_id=${city_id?.value || ""}&job_title=${job_title}`)
                if (res?.success) {
                    setRecentJobdData(res?.data)
                    window.scrollTo(0, 1500);
                } else {
                    console.log("Error");
                }
            }
            else {
                const res = await getData(`/job-portal/jobs/search-data/data?job_type_id=${job_type_id?.value || ""}&city_id=${city_id?.value || ""}&job_title=${job_title}`)
                if (res?.success) {
                    setRecentJobdData(res?.data)
                    window.scrollTo(0, 1500);
                } else {
                    console.log("Error");
                }
            }

        } catch (error) {
            console.log(error.message);
        }
    }

    // const response = await axios.get(`your_api_endpoint?jobjob_type_id=${jobjob_type_id}&city_id=${city_id}&experienceMin=${experienceMin}`);
    const getSearchJob = async (selectedId) => {
        try {
            if (selectedId) {
                const res = await getData(`/job-portal/jobs/industry/${selectedId}`)
                if (res?.success) {
                    setRecentJobdData(res?.data)
                } else {
                    console.log("Error");
                }
            }
            else {
                const res = await getData("/job-portal/jobs")
                if (res?.success) {
                    setRecentJobdData(res?.data)
                } else {
                    console.log("Error");
                }
            }
        } catch (error) {
            console.log(error.message);
        }
    }


    const getIndustry = async () => {
        try {
            const res = await getData("/without-login/master/all-industry")
            if (res?.success) {
                setIndustryData(res?.data)
            } else {
                console.log("Error");
            }
        } catch (error) {
            console.log(error.message);
        }
    }

    const handleNavItemClick = (id) => {
        setSelectedId(id);
    };

    const handleAllClick = (id) => {
        // getRecentJob();
        getSearchJob();
    };

    useEffect(() => {
        getIndustry();
        if (!(job_type_id && city_id && job_title)) {

            getSearchJob(selectedId);
        } else {
            console.log("All variables are defined; might not need to call getJob based on original intent.");
        }
    }, [selectedId, job_type_id, city_id, job_title]);

    useEffect(() => {

        if (job_type_id || city_id || job_title) {
            getRecentJob(selectedId);
        } else {
            // Handle the case where any of the required variables is not defined or falsy
            console.log("Required variables are not defined");
        }
    }, [selectedId, job_type_id, city_id, job_title]);
    return (
        <>
            <section className='Jp_home_recent_jobs_section'>
                <div className='container'>
                    <div className='sec-title-div'>
                        <h1 className="jpsect_title">Recent Jobs</h1>
                        <p className="jpsect_subtitle ">20+ Recently Added Jobs</p>
                    </div>

                    <Tab.Container id="left-tabs-example" defaultActiveKey="alljobs">
                        <div className='job-main'>
                            <div className='job-list-slider-div'>
                                <Nav variant="pills" className="">
                                    <Swiper
                                        modules={[Navigation, Autoplay]}
                                        pagination={false}
                                        navigation={false}
                                        // loop={true}
                                        breakpoints={{
                                            0: {
                                                slidesPerView: 1.5,
                                            },
                                            400: {
                                                slidesPerView: 2,
                                            },
                                            768: {
                                                slidesPerView: 3,
                                            },
                                            992: {
                                                slidesPerView: 5,
                                            },
                                            1024: {
                                                slidesPerView: 4,
                                            },
                                            1400: {
                                                slidesPerView: 5,
                                            },
                                        }}
                                        className="suportres-slider"
                                    >

                                        <SwiperSlide className="swiper-slide1">
                                            <Nav.Item >
                                                <Nav.Link eventKey="alljobs" onClick={() => handleAllClick()}>
                                                    {/* All Jobs */}
                                                    <img className='iccon' /> All Jobs <span className='job-avalable'></span>
                                                </Nav.Link>
                                            </Nav.Item>
                                        </SwiperSlide>

                                        {industryData?.map((item) => (
                                            <SwiperSlide className="swiper-slide1">
                                                <Nav.Item key={item?.eventKey}>
                                                    <Nav.Link eventKey={item?.name + item?.id} onClick={() => handleNavItemClick(item?.id)}>
                                                        {/* <Nav.Link eventKey={item?.eventKey} > */}
                                                        <img className='iccon' src={item?.icon} /> {item?.name} <span className='job-avalable'>{item?.availablejob}</span>
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </SwiperSlide>
                                        ))}
                                    </Swiper>
                                </Nav>
                            </div>


                            <div className='jp-recentjob-list mt-4'>
                                <div className='row'>
                                    <div className='col-lg-9'>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="alljobs" >
                                                <div className='recentjob-data'>
                                                    <div className='overflow-data'>
                                                        <div className='main-card'>
                                                            <Jp_recent_job_card recentJobData={recentJobData} />
                                                        </div>
                                                    </div>
                                                    <div className='view-job-div'>
                                                        <Link to="/job-portal/job"><button className='view-more-btn'>View More Jobs</button></Link>
                                                    </div>
                                                    {/* <div className='view-job-div'>
                                                        <Link to={"/job-portal/job/1"}><button className='view-more-btn'>View More Jobs</button></Link>
                                                    </div> */}
                                                </div>
                                            </Tab.Pane>

                                            {industryData?.map((item) => (
                                                <Tab.Pane eventKey={item?.name + item?.id}>
                                                    <div className='recentjob-data'>
                                                        <div className='overflow-data'>
                                                            <div className='main-card'>
                                                                <Jp_recent_job_card recentJobData={recentJobData} />
                                                            </div>
                                                        </div>
                                                        <div className='view-job-div'>
                                                            <Link to="/job-portal/job"><button className='view-more-btn'>View More Jobs</button></Link>
                                                        </div>
                                                        {/* <Link to={`/job-portal/job/${item?.id}`}><button className='view-more-btn'>View More Jobs</button></Link>                                                        </div> */}
                                                    </div>
                                                </Tab.Pane>
                                            ))}

                                            {/* <Tab.Pane eventKey="finance">
                                                <div className='recentjob-data'>
                                                    <div className='overflow-data'>
                                                        <div className='main-card'>
                                                            <Jp_recent_job_card />
                                                        </div>
                                                    </div>
                                                    <div className='view-job-div'>
                                                        <Link to="/job-portal/job"><button className='view-more-btn'>View More Jobs</button></Link>
                                                    </div>
                                                </div>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="Software">
                                                <div className='recentjob-data'>
                                                    <div className='overflow-data'>
                                                        <div className='main-card'>
                                                            <Jp_recent_job_card />
                                                        </div>
                                                    </div>
                                                    <div className='view-job-div'>
                                                        <Link to="/job-portal/job"><button className='view-more-btn'>View More Jobs</button></Link>
                                                    </div>
                                                </div>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="market_research">
                                                <div className='recentjob-data'>
                                                    <div className='overflow-data'>
                                                        <div className='main-card'>
                                                            <Jp_recent_job_card />
                                                        </div>
                                                    </div>
                                                    <div className='view-job-div'>
                                                        <Link to="/job-portal/job"><button className='view-more-btn'>View More Jobs</button></Link>
                                                    </div>
                                                </div>
                                            </Tab.Pane>

                                            <Tab.Pane eventKey="uiuxdesign">
                                                <div className='recentjob-data'>
                                                    <div className='overflow-data'>
                                                        <div className='main-card'>
                                                            <Jp_recent_job_card />
                                                        </div>
                                                    </div>
                                                    <div className='view-job-div'>
                                                        <Link to="/job-portal/job">
                                                            <Link to="/job-portal/job"><button className='view-more-btn'>View More Jobs</button></Link>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Tab.Pane> */}
                                        </Tab.Content>
                                    </div>
                                    <div className='col-lg-3 col-sm-8 mx-auto my-lg-0 my-5'>

                                        <JPOnlineDegree
                                            screen={"home"}
                                        />
                                        <CreateAccountAddvertise />

                                    </div>
                                </div>
                            </div>
                        </div>

                        <Exlamentry show={modalShow}
                            onHide={() => setModalShow(false)}
                            text={"Your Need to Log In to Job Seeker to Apply for the Job!"}
                        />

                    </Tab.Container>
                </div>
            </section>
        </>
    )
}

export default Jp_home_recent_jobs