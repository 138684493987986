import React, { useEffect } from "react";
import "./FContact.css";
import GoogleMap from "./GoogleMap/GoogleMap";
import FeedbackForm from "./Feedback-Form/FeedbackForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AOS from "aos";
import "aos/dist/aos.css";

function FContact() {

  useEffect(() => {
    AOS.init({ once: true });
  }, [])

  return (
    <>
      <div className="container">
        <div className="GoogleMap GoogleMap-container">
          <GoogleMap />

          <div className="expand-icon-holder" >
            {" "}
            <FontAwesomeIcon className="expand-icon " icon="fa-solid fa-expand" />
          </div>
        </div>

        <div className="feedbackForm">
          <div className="row mb-5">
            <div className="col-md-3" data-aos="fade-right" data-aos-duration="3000">
              <div className="Contact_Details">
                <div className="bg-content-holder">
                  {/* <div className="details-bg-img">
                        <img
                          className="detail-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Finance/Image/Contact/Contact_bg.png"
                          }
                          alt="details-img"
                        />
                      </div> */}
                  <div className="Contact_Details-holder">
                    <div className="details-heading-holder">
                      <h4>Contact Details</h4>
                    </div>

                    <div className="details-holder">
                      <div className="icon-holder">
                        <FontAwesomeIcon
                          className="details-icon"
                          icon="fa-solid fa-location-dot"
                        />
                      </div>

                      <p>
                        2nd floor, Office no 3, Opp high class soc, M.no 734 Near
                        Suryadutta College, Bavdhan Khurd, Pune 411021.
                      </p>
                    </div>
                    <div className="details-holder">
                      <div className="icon-holder">
                        <FontAwesomeIcon
                          className="details-icon"
                          icon="fa-solid fa-location-dot"
                        />
                      </div>

                      <p>
                        Office No: 510 5th floor, Sky Max Mall, Datta Mandir Chowk, Viman Nagar Pune, 411014.
                      </p>
                    </div>

                    <div className="details-holder">
                      <div className="icon-holder">
                        <FontAwesomeIcon
                          className="details-icon"
                          icon="fa-solid fa-phone-volume"
                        />
                      </div>

                      <p>
                        1800-267-8961 / +91 96077 55557
                      </p>
                    </div>

                    <div className="details-holder">
                      <div className="icon-holder">
                        <FontAwesomeIcon
                          className="details-icon"
                          icon="fa-solid fa-envelope"
                        />
                      </div>

                      <p>
                        inquiry@profcyma.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-9" data-aos="fade-left" data-aos-duration="3000">
              <div className="contact-feedback-form-container">
                <FeedbackForm />
              </div>
            </div>


          </div>
        </div>
      </div>

    </>
  );
}

export default FContact;
