import React, { useState, useEffect, useContext } from "react";
import "./JpJobSeekerEditProfile.css";
import JpJobSeekerEditProfileSTepForm from "./JpJobSeekerEditProfileSTepForm/JpJobSeekerEditProfileSTepForm";
import KnowMoreAddvertise from "../../JP-Common-Elements/JP-Addvertise/Know-More-Addvertise/KnowMoreAddvertise";
import { Button } from "react-bootstrap";
import { Context } from "../../../../utils/context";
import { error } from "jquery";
import { getProfilePicture } from "../../../../utils/apis/jobseekar/jobseekar";
import Cookies from "js-cookie";

function JpJobSeekerEditProfile() {

  const [profileImage, setProfileImage] = useState();

  const { getData, postData, IMG_URL } = useContext(Context);
  const [profile_percentage, setProfile_percentage] = useState()
  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    const data = new FormData();
    if (file) {
      data.append('image', file);
    }

    const res = await postData(`/job-portal/job-seeker/profile/profile-details/image`, data);
    if (res?.success) {
      reader.onloadend = () => {
        setProfileImage(reader.result);
        getProfileData();
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
    else {
      console.log(error);
    }
  };


  const getProfileData = async () => {
    const res = await getProfilePicture();
    if (res?.success) {
      setProfileImage(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };



  const getPercentage = async () => {
    console.log("getting percentage");
    if (Cookies.get("profile_percentage")) {
      setProfile_percentage(Cookies.get("profile_percentage"));
      console.log("percentage :- ", Cookies.get("profile_percentage"));
    }
  };

  useEffect(() => {
    getProfileData();
    getPercentage()
  }, []);
  useEffect(() => {
    getPercentage()
  }, [Cookies.get("profile_percentage")]);

  return (
    <>
      <section className="JpJobseekerEditProfile">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="update-profile-section">
                <div className="profile-img-holder">
                  <img src={IMG_URL + profileImage?.job_seeker_image?.image} className="profile-Img" />

                  <div className="apply-btn-holder">
                    <label htmlFor="upload-photo" className="apply_now_btn">
                      Upload Photo
                    </label>
                    <input
                      id="upload-photo"
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-xl-12 col-lg-12 col-md-6">
                    <KnowMoreAddvertise />
                  </div>
                  <div className=" col-xl-12 col-lg-12 col-md-6">
                    <KnowMoreAddvertise />
                  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="edit-profile-stepform">
                <JpJobSeekerEditProfileSTepForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default JpJobSeekerEditProfile;
