import React, { useEffect, useRef, useState, useContext } from 'react';
import "react-international-phone/style.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { Context } from '../../../../../../../utils/context';
import { useForm, Controller, SubmitHandler } from "react-hook-form";

function StepNineSelectHomeLoanAmount({ setDesired_amount_id, loanFormDetail }) {
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    reset(loanFormDetail);
    console.log("loanFormDetail form 8:-", loanFormDetail);
    setSelected(loanFormDetail?.desired_amount_id?.value)
  }, [loanFormDetail]);

  const [modalShow, setModalShow] = useState(false);
  const [desired_amount_id, selectedYearID] = useState(null);
  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
  const [data, setData] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset
  } = useForm();

  console.log(desired_amount_id, 'empppppp');
  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/master/all-desired-amount`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataAll();
  }, []);

  const handleOptionSelect = (id) => {
    setSelected(id)
    setDesired_amount_id(id);
  };

  return (
    <>
      <div className="StepTwoEmploymentType">
        <Form>
          {data?.map((year, index) => (
            <div className="EmploymentType-content" key={index}>
              <div className="text-holder">
                <p>{"₹"}{year?.min}{"  "}-{"  "}{year?.max}{"  "}lakh</p>
              </div>
              <div className="circle-icon-holder">
                <Form.Check
                  inline
                  name="desired_amount_id"
                  type="radio"
                  value={year.id}
                  checked={selected === year.id}
                  {...register('desired_amount_id', { required: true })}
                  onChange={() => handleOptionSelect(year.id)}
                />
              </div>
            </div>
          ))}
        </Form>
      </div>
    </>
  );
}

export default StepNineSelectHomeLoanAmount;
