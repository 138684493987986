import React from 'react'
import JPBlogBanner from './Jpblog-banner/JPBlogBanner'
import JPBlogUpdate from './JPBlog-Update/JPBlogUpdate'
import JPBlogCard from './Jpblog-card/JPBlogCard'


const JPBlog = () => {
    return (
        <>
            <JPBlogBanner />
            <JPBlogUpdate />
            <JPBlogCard />
        </>
    )
}

export default JPBlog