import React from 'react'
import "./SaveTime.css";
const SaveTime = () => {
    return (
        <>
            <section className='SaveTime'>
                <div className='container'>
                    <div className='saveTimehead'>
                        <h3>Save time and effort in your recruitment journey.</h3>
                        <p>Find the best fit talent for your job, may it full-time, part-time or an intern, <br /> by 3 simple steps</p>
                    </div>

                    <div className='row'>
                        <div className='col-lg-4 col-md-4'>
                            <div className='card saveTimecard'>
                                <div className='saveTimeCardhead'>
                                    <h2>Create your <br /> free account</h2>
                                    <p>Few Simple steps and you are ready to go</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            <div className='card saveTimecard'>
                                <div className='saveTimeCardhead'>
                                    <h2>Build your <br /> job post</h2>
                                    <p>Few Simple steps and you are ready to go</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            <div className='card saveTimecard'>
                                <div className='saveTimeCardhead'>
                                    <h2>Post <br/> your Job</h2>
                                    <p>Few Simple steps and you are ready to go</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default SaveTime