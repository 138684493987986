import React, { useState, useContext, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../EmpAccountManagement.css';
import Collapse from 'react-bootstrap/Collapse';
import ResetModal from '../../../../JP-Common-Elements/Jp-modals/ResetModal/ResetModal';
import "react-phone-input-2/lib/style.css";
import { Context } from '../../../../../../utils/context';
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";


const DeleteAccount = ({deletedreason,toggleBack}) => {

    const [showPassword, setShowPassword] = useState(false);
    const [open, setOpen] = useState(false);
    const [modalEmail, setModalEmail] = useState(false);

    const navigate = useNavigate();

    // const goToContent = (content) => {
    //     setShowForm(false);
    //     setHeadingText(content.name);
    //     setSelectedContent(content);
    // };
    const { getData, postData, IMG_URL, signin, usertype, userdata,setSignin } = useContext(Context);

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

    const handleCheckboxChange = (event, id) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedCheckboxes([...selectedCheckboxes, id]);
        } else {
            setSelectedCheckboxes(selectedCheckboxes.filter(item => item.value !== value));
        }
    };

    const onDelete = async () => { 
        const user_id = userdata?.id;
        const res = await postData(`/job-portal/employer/setting/delete/${user_id}`, { delete_reasons_id: selectedCheckboxes });
        if (res?.success) {
            setModalEmail(true);
            setTimeout(() => {
                setModalEmail(false);
                Cookies.remove('llp_web_security');
                setSignin(false);
                navigate("/job-portal/home");
            }, 3000);
        }
    }

    // useEffect(() => {
    //     getdeletereason();
    // }, []);
    return (
        <>
            <Form className="form-field" onSubmit={handleSubmit(onDelete)}>
                <div className='Reason-div inner-content form-container'>
                    {deletedreason?.map((data, index) => (
                        <Form.Check
                            key={index}
                            type="checkbox"
                            label={data?.name}
                            value={data?.name}
                            onChange={(event) => handleCheckboxChange(event, data?.id)}
                        // checked={selectedCheckboxes.some(item => item.value === data?.name)}
                        />
                    ))}
                    {/* <Form.Check
                  type="checkbox"
                  label="I don't find this useful."
                />
                <Form.Check
                  type="checkbox"
                  label="Taking break, I will come back soon."
                />
                <Form.Check
                  type="checkbox"
                  label="Haven't heard back from the candidates."
                />*/}.
                    {/* <Form.Check
                  type="checkbox"
                  label="Any Other Reason for Disabling Your Account"
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                /> */}
                    <Collapse in={open}>
                        <div id="example-collapse-text ">
                            <Form.Group className=" form-div mt-4 mb-4" controlId="exampleForm.ControlInput1">
                                <Form.Control as="textarea" rows={5} placeholder="Type a Reason for Disabling Your Account" />
                            </Form.Group>
                        </div>
                    </Collapse>
                    <div className='buttons-div mt-5'>
                        <button className='cancel-btn' onClick={toggleBack}>Cancel</button>
                        <button type='submit' className='save-btn' >Delete</button>
                        {/* <button type='button' className='save-btn' onClick={() => openEmail("/job-portal/home")}>Save</button> */}
                    </div>

                    <ResetModal show={modalEmail}
                        onHide={() => setModalEmail(false)}
                        text={"Your Account has been Deleted Successfully!"}
                    />
                </div>
            </Form>
        </>
    );
}

export default DeleteAccount;
