import React from "react";
import FHomeBanner from "./FHomeBanner/FHomeBanner";
import TopServicesLoan from "./TopServicesLoan/TopServicesLoan";
import WeStandOut from "./WeStandOut/WeStandOut";
import FinancialResiLience from "./FinancialResiLience/FinancialResiLience";
import EnquiryForm from "./EnquiryForm/EnquiryForm";
import CreditScore from "./CreditScore/CreditScore";
import FmainBanner from "./FmainBanner/FmainBanner";
import TopServicesInsurance from "./TopServicesInsurance/TopServicesInsurance";
import FTestimonials from "./F-Testimonial/FTestimonials";
import FinancePartner from "./FinancePartner/FinancePartner";

const FHomePage = () => {
  return (
    <>
      <FmainBanner />
      <FHomeBanner />
      <TopServicesLoan />
      <TopServicesInsurance />
      <WeStandOut />
      <FinancialResiLience />
      <CreditScore />
      <FTestimonials />
      <FinancePartner />
      <EnquiryForm />
    </>
  );
};
export default FHomePage;
