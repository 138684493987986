import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import AOS from "aos";
import "aos/dist/aos.css";
import FirstCredit from "../../F-HomePage/CreditReportModal/FirstCredit/FirstCredit"
import "./Score.css";
const Score = () => {
  const [modalShow, setModalShow] = useState(false);
  useEffect(() => {
    AOS.init({ once: true });
  }, [])
  return (
    <>
      <section className="Score-section">
        <div data-aos="fade-up"
          data-aos-duration="3000">
          <Container>
            <Row>
              <Col xxl={6} xl={6} lg={6} md={6}>
                <div className="Score-Image">
                  <img
                    className="score"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Finance/Image/HomeLoan/Score/hero-creditstore.png"
                    }
                    alt="Banner"
                  />
                </div>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6}>
                <div className="score-content">
                  <h1 className="fianace-titleeee second-fiannn">
                    Happily Submit Your Loan <br></br>Application And Know CIBIL
                    Score
                  </h1>
                  <p className="text">Know your exact EMI & interest rate.</p>
                  <button class="readmore-btn" onClick={() => setModalShow(true)}>
                    Apply Now
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="angles-right"
                      class="svg-inline--fa fa-angles-right arrow-icon "
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M470.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 256 265.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160zm-352 160l160-160c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L210.7 256 73.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0z"
                      ></path>
                    </svg>
                  </button>

                  <FirstCredit show={modalShow} onHide={() => setModalShow(false)} />
                </div>
              </Col>
            </Row>
          </Container>
        </div>   
      </section>
    </>
  );
};

export default Score;
