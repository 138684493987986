import { React, useRef, useEffect, useState, useContext } from "react";
import "./FEnquiryModal.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import InputGroup from 'react-bootstrap/InputGroup';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from "../../../../utils/context"
import Select from "react-select";
import classNames from "classnames";
import moment from 'moment';
import Cookies from "js-cookie";
import SubitFormModal from "../../../Ed-tech/Admission/E-brochure/SubitFormModal/SubitFormModal";
// import 'react-datepicker/dist/react-datepicker.css'; 

const FEnquiryModal = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [modalShow, setModalShow] = useState(true);
  const [emailError, setEmailError] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [phone, setPhone] = useState("in");
  const [formData, setFormData] = useState({ dob: '' });
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    setValue
  } = useForm();
  const [pincode, setPincode] = useState([]);
  // const [employmentTypes, setEmploymentTypes] = useState([]);
  const [showOther, setShowOther] = useState(false);

  const employmentTypes = [
    'Salaried',
    'Self-employed',
    'Commision-based',
    'Other'
  ];

  const [showModal, setShowModal] = useState(false);


  const GetAllPincode = async () => {
    const response = await getData("/without-login/master/all-pincodes");

    if (response.success) {
      setPincode(await Select2Data(response?.data, "pincode_id"));
    }
  };

  // const fetchEmploymentTypes = async () => {
  //   try {
  //     const response = await getData("/without-login/master/all-employment-type");
  //     if (response.success) {
  //       // Extracting only the "name" property from each object
  //       const names = response.data?.map(employmentType => employmentType.name);
  //       setEmploymentTypes(names);
  //     } else {
  //       // Handle error when response is not successful
  //       console.error('Error fetching employment types:', response.error);
  //     }
  //   } catch (error) {
  //     // Handle fetch error
  //     console.error('Error fetching employment types:', error);
  //   }
  // };

  // const handleDateChange = (date) => {

  //   const formattedDate = moment(date).format("DD/MM/YYYY");
  //   setStartDate(date);
  //   setFormData(prevData => ({
  //     ...prevData,
  //     dob: formattedDate
  //   }));
  // };

  useEffect(() => {

    GetAllPincode();
    // fetchEmploymentTypes();
  }, []);

  useEffect(() => {
    if (modalShow) {
      reset(); // Reset the form when the modal is shown
    }
  }, [modalShow, reset]);

  const handleCancel = () => {
    setModalShow(false);
  };

  const onSubmit = async (d) => {
    if (!d?.employment_type) {
      setError("employment_type", { type: "manual", message: "Employment Type is Required" });
      return false
    }
    //alert('ss');
    try {
      const res = await postData(`/finance/forms/inquery-form`, d);
      if (res?.success) {
        setModalShow(true);
        Cookies.set('FEnquirySubmitted', true, { expires: 1 });
        setTimeout(() => {
          // window.location.reload();
          setModalShow(false);
        }, 2000)
      } else {
        setEmailError(res?.message?.email)
        setMobileError(res?.message?.contact_no)
      }
    } catch (error) {
      console.error(error);
    }
  }



  const CityType = async () => {
    try {
      return await getData(`/without-login/master/all-cities`);
    } catch (error) {
      console.log(error);
    }
  };
  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
  const [cityTypes, setCityType] = useState([]);
  const getMasters = async () => {
    {
      const res = await CityType();
      if (res?.success) {
        const data = await Select2Data(res.data, "city_id", false);
        setCityType(data);
      }
    }
  };

  useEffect(() => {
    reset();
    clearErrors();
    getMasters();
  }, []);


  const validateAge = (dob) => {
    console.log("dob", dob);
    const selectedDate = new Date(dob);
    const currentDate = new Date();
    const minAgeDate = new Date();
    minAgeDate.setFullYear(currentDate.getFullYear() - 18);

    if (selectedDate > minAgeDate) {
      console.log("dob", "Age should not be less than 18 years");
      setError("dob", { type: "manual", message: "Age should not be less than 18 years" });
      return "Age should not be less than 18 years";
    }
    console.log("dob", "done");
    clearErrors("dob")
    return true;
  };

  const handleDateChange = (event) => {
    const value = event.target.value;
    validateAge(value);
  };

  const handleRadioChange = (event) => {
    const value = event.target.value;
    if (value === 'Other') {
      setShowOther(true);
      setValue('employment_type', ''); // Clear employment_type value when "Other" is selected

    } else {
      setShowOther(false);
      setValue('employment_type', value); // Set employment_type value when a radio button is selected
      clearErrors("employment_type")
    }
  };

  // const validateEmploymentType = () => {
  //   const employmentType = getValues('employment_type');
  //   const otherEmploymentType = getValues('other_employment_type');
  //   setValue("employment_type", getValues("other_employment_type"))
  //   if (!employmentType && !otherEmploymentType) {
  //     setError("employment_type", { type: "manual", message: "Employment Type is Required" })
  //   }
  //   return true;
  // };

  console.log("get Values", getValues());


  return (
    <>
      {modalShow &&
        <Modal
          className="fenquiry-modal sec-fmoalll modal-dialog-scrollable"
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter "
          centered
          scrollable
          backdrop="static"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Inquiry Form
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-sec-start">
              <div className="row">
                <p className="please-txttt">*Please fill the details below completely</p>
                <div className="personal-detail mb-4">
                  <p className="me-1">Section 1 :</p>
                  <p>Personal Details</p>
                </div>
              </div>
              <Form onSubmit={handleSubmit(onSubmit)}>
                <Row className="form-detail">
                  <Col md={8}>
                    <Form.Group controlId="formGridname" className=" mb-4 text-input-bottom">
                      <Form.Label>Full Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="As per your bank records"
                        name="name"
                        className={classNames("", {
                          "is-invalid": errors?.name,
                        })}

                        {...register("name", {
                          required: "Name is required",
                          pattern: {
                            value: /^[A-Za-z ]*$/,
                            message: "Name must contain only letters",
                          },
                        })}
                        onKeyDown={(event) => {
                          if (!/[A-Z-a-z ]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}

                      />
                      {errors?.name && (
                        <span className="text text-danger">
                          {errors.name.message}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group className="mb-4" controlId="">
                      <Form.Label>Gender</Form.Label>
                      <div className="mb-4">
                        <Form.Check
                          inline
                          label="Male"
                          name="gender"
                          type="radio"
                          value="male"
                          {...register("gender", {
                            required: "Select Your Gender",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.gender,
                          })}
                        />
                        <Form.Check
                          inline
                          label="Female"
                          name="gender"
                          type="radio"
                          value="female"
                          {...register("gender", {
                            required: "Select Your Gender",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.gender,
                          })}
                        />
                        {errors?.gender && (
                          <span className="text text-danger">
                            {errors.gender.message}
                          </span>
                        )}
                      </div>

                    </Form.Group>
                  </Col>
                  <Col md={8}>
                    <Form.Group controlId="formGridaddress" className=" mb-4 text-input-bottom">
                      <Form.Label>Address</Form.Label>
                      <Form.Control type="text"
                        name="address"
                        placeholder="As per your bank records"
                        {...register("address", {
                          required: "Address is required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.address,
                        })}
                      />
                      {errors?.address && (
                        <span className="text text-danger">
                          {errors.address.message}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={4}>

                    <Form.Group controlId="formGridpincode" className=" mb-4 text-input-bottom">
                      <Form.Label>Pin Code</Form.Label>
                      <Form.Control
                        type="number"
                        name="pincode"
                        placeholder="Enter Pincode"
                        {...register("pincode", {
                          required: "pincode is required",
                          minLength: {
                            value: 6,
                            message: "Pincode must be 6 digits long",
                          },
                          validate: {
                            startsWithZero: value => value.toString().charAt(0) !== '0' || "Pincode cannot start with 0"
                          }
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.pincode,
                        })}
                        onKeyDown={(event) => {
                          if (!/[A-Za-z0-9 ]/.test(event.key)) {
                            event.preventDefault();
                          }
                          if (event.target.value.length >= 6 && event.key !== "Backspace") {
                            event.preventDefault();
                          }
                        }}
                      />
                      {errors?.pincode && (
                        <span className="text text-danger">
                          {errors.pincode.message}
                        </span>
                      )}
                    </Form.Group>

                  </Col>


                  <Col md={4}>
                    <Form.Group controlId="formGridpincode" className=" mb-4 text-input-bottom">
                      <Form.Label>Mobile Number</Form.Label>
                      {/* <Form.Control
                      type="text"
                      placeholder="Mobile number   0/10 Digits"
                    /> */}
                      {/* <Form.Control
                        type="number"
                        maxLength={10}
                        name="contact_no"
                        placeholder="Mobile number"
                        {...register("contact_no", {
                          required: "Phone is required",
                          pattern: {
                            value: /^(?:\D*\d){10,}$/,
                            message: "Invalid phone Number",
                          },
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.contact_no,
                        })}
                      /> */}
                      <Form.Control
                        type="text"
                        placeholder="Enter Number"
                        controlId="exampleForm.ControlInput1"
                        name="contact_no"
                        {...register("contact_no", {
                          required: "contact number required",
                          pattern: {
                            value: /^(?:\D*\d){10,}$/,
                            message: "Invalid phone Number",
                          },
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.contact_no,
                        })}
                        onKeyPress={(event) => {
                          // Get the character code of the pressed key
                          const charCode = event.which ? event.which : event.keyCode;
                          // Allow only numeric characters (0-9) and the backspace key (charCode 8)
                          if (charCode < 48 || charCode > 57) {
                            event.preventDefault();
                          }
                        }}
                        maxLength={10}

                      />
                      {errors?.contact_no && (
                        <span className="text text-danger">
                          {errors.contact_no.message}
                        </span>
                      )}
                      <span className="text text-danger">
                        {mobileError}
                      </span>
                    </Form.Group>

                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formGridemail" className=" mb-4 text-input-bottom">
                      <Form.Label>Email Address</Form.Label>
                      {/* <Form.Control type="email" placeholder="Email ID" />
                  </Form.Group> */}
                      <Form.Control type="text" name="email" placeholder="Email ID"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                            message: "Invalid email address",
                          }
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.email,
                        })}

                      />
                      {errors?.email && (
                        <span className="text text-danger">
                          {errors.email.message}
                        </span>
                      )}
                      <span className="text text-danger">
                        {emailError}
                      </span>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group controlId="formGriddate" className=" mb-4 text-input-bottom">
                      <Form.Label>Date of Birth</Form.Label>
                      <div className="date-picker-holder">
                        <Form.Control
                          type="date"
                          placeholder="Enter Date Of Birthday"
                          name="dob"
                          max={new Date().toISOString().split('T')[0]}
                          {...register("dob", {
                            required: "date required",

                          })}
                          className={classNames("", {
                            "is-invalid": errors?.dob,
                          })}
                          onChange={handleDateChange}
                        />
                      </div>
                      {errors?.dob && (
                        <span className="text text-danger">
                          {errors.dob.message}
                        </span>
                      )}
                    </Form.Group>

                  </Col>
                </Row>
                <div className="row">
                  <div className="personal-detail mb-4">
                    <p className="me-1">Section 2 :</p>
                    <p>Employment</p>
                  </div>
                </div>
                <Row className="form-detail">
                  <Col lg={7} md={8}>
                    <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                      <Form.Label column sm="5" className="pe-lg-0 pe-md-0 pe-sm-none pe-none">
                        Employment Type :
                      </Form.Label>
                      <Col sm="7">
                        {employmentTypes.map((name, index) => (
                          <Form.Group key={index} id={`formGridCheckbox${index}`}>
                            <Form.Check
                              type="radio"
                              className={classNames('', {
                                'is-invalid': errors?.employment_type,
                              })}
                              label={name}
                              value={name}
                              {...register('employment_type', {

                              })}
                              onChange={handleRadioChange}
                            />
                          </Form.Group>
                        ))}

                        {showOther && (
                          <Form.Control
                            type="text"
                            placeholder="Employment Type"
                            onChange={(e) => {
                              setValue('employment_type', e.target.value);
                              clearErrors('employment_type');
                            }}
                            className={classNames('', {
                              'is-invalid': errors?.employment_type,
                            })}
                          />
                        )}
                        {errors?.employment_type && (
                          <span className="text text-danger">
                            {errors.employment_type.message}
                          </span>
                        )}
                      </Col>

                    </Form.Group>
                  </Col>
                  {/* Conditional rendering for "Self-Employed" and "Others" checkboxes */}
                  {/* {employmentTypes.length === 0 && (
                    <Col lg={5} md={4}>
                      <Form.Group className="mb-4" controlId="">
                        <div>
                          <Form.Group id="formGridCheckbox">
                            <Form.Check type="checkbox" label="Self - Employed" />
                          </Form.Group>
                          <Form.Group id="formGridCheckbox" className="d-flex other-check-box">
                            <Form.Check type="checkbox" label="Others" />
                            <Form.Control type="email" placeholder="" />
                          </Form.Group>
                        </div>
                      </Form.Group>
                    </Col>
                  )} */}
                  <Col md={8}>
                    <Form.Group as={Row} className="mb-4">
                      <Form.Label column sm="5">
                        Years in Service :
                      </Form.Label>
                      <Col sm="6">
                        {/* <Form.Control type="text" className="year-text" /> */}
                        <Form.Control type="text" name="years_in_service" {...register("years_in_service", {
                          required: " Year is required",
                        })}
                          className={classNames("", {
                            "is-invalid": errors?.years_in_service,
                          })}
                          onKeyDown={(event) => {
                            if (!/[0-9]/.test(event.key) && !['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab'].includes(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          maxLength={2}
                        />
                        {errors?.years_in_service && (
                          <span className="text text-danger">
                            {errors.years_in_service.message}
                          </span>
                        )}
                      </Col>
                    </Form.Group>
                  </Col>
                  <div className="button-holder text-center mb-md-2 mb-sm-5 mb-4">
                    <Button className="btn apply-btn me-3" type="submit" onClick={() => setShowModal(true)}>
                      Apply
                    </Button>
                    <SubitFormModal
                      show={showModal}
                      onHide={() => setShowModal(false)}
                      setModalShow={setShowModal}
                      modalShow={showModal}
                    />
                    <Button className="btn btn-cancle" onClick={handleCancel} >
                      Cancel
                    </Button>
                  </div>
                </Row>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      }
    </>
  );
};

export default FEnquiryModal;
