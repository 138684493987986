import React, { useState, useContext, useEffect } from 'react'
import './Jpsearchbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFontAwesome } from '@fortawesome/free-solid-svg-icons'

import Select from 'react-select'
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { Context } from "../../../../utils/context";
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';

const Jpsearchbar = ({ setJobTypeId, setCityId, setJobTitle, setFrom }) => {
    const navigate = useNavigate();
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset, watch,
        trigger,
        setValue
    } = useForm();
    const { postData, getData, Select2Data, IMG_URL } = useContext(Context);
    const [jobTypes, setJobTypes] = useState([]);
    const GetAllJobType = async () => {
        const response = await getData("/without-login/master/all-job-type");
        if (response.success) {
            setJobTypes(await Select2Data(response?.data, "job_type_id"));
        }
    };
    const [city, setCity] = useState([]);
    const GetAllCities = async () => {
        const response = await getData(`/without-login/master/all-cities`);
        if (response?.success) {
            setCity(await Select2Data(response?.data, "city_id"));
        }
    };

    useEffect(() => {
        GetAllJobType();
        GetAllCities();
    }, []);

    const onSubmit = (data) => {
        // Extract job_type_id from form data
        setJobTypeId(data?.job_type_id)
        setCityId(data?.city_id)
        setJobTitle(data?.company_name)

        setFrom("search")

        // Construct query parameters
        // const queryParams = new URLSearchParams({
        //     selectedJobType: job_type_id,
        //     city: city_id,
        //     title: company_name,
        // }).toString();

        // // Navigate with query parameters 
        // navigate(`./?${queryParams}`);
    };
    return (
        <>
            <section className='jphmsearchbar-section'>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='container'>
                        <div className='barr-div'>
                            <div className='row justify-content-center'>
                                <div className='col-lg-4  col-sm-6  mb-lg-0 mb-3'>
                                    <div className='firstt'>
                                        {/* <Select placeholder="Job Title" options={options1} className='ms-1' /> */}
                                        <Controller
                                            name="job_type_id"
                                            className="yourClassNameHere"
                                            // {...register("job_type_id", {
                                            //     required: "job_type_id required",
                                            // })}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    placeholder="Job Title"
                                                    className="yourClassNameHere"
                                                    classNamePrefix="searchfield"
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            borderColor: errors.job_type_id
                                                                ? "red"
                                                                : baseStyles,
                                                        }),
                                                    }}
                                                    {...field}
                                                    options={jobTypes}
                                                />
                                            )}
                                        />
                                        <FontAwesomeIcon icon="fa-solid fa-briefcase " className='fa-icc' />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-sm-6  mb-lg-0 mb-3'>
                                    <div className='firstt'>
                                        {/* <Select placeholder="Locations" options={options2} className='ms-1' /> */}
                                        <Controller
                                            name="city_id"
                                            // {...register("city_id", {
                                            //     required: "city_id required",
                                            // })}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    placeholder="Location"
                                                    classNamePrefix="searchfield"
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                            ...baseStyles,
                                                            borderColor: errors.city_id
                                                                ? "red"
                                                                : baseStyles,
                                                        }),
                                                    }}
                                                    {...field}
                                                    options={city}
                                                />
                                            )}
                                        />
                                        <FontAwesomeIcon icon="fa-solid fa-location-dot " className='fa-icc' />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-sm-6  mb-lg-0 mb-3'>
                                    <div className='firstt'>
                                        <Form.Control
                                            aria-label="Small"
                                            aria-describedby="inputGroup-sizing-sm"
                                            placeholder='Enter Keyword'
                                            type="text"
                                            name="Enter_Keyword"

                                            {...register("company_name", {
                                                // required: "company_name required",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.Enter_Keyword,
                                            })}
                                        />
                                        <button className='btn search-btnn' type='submit'><FontAwesomeIcon icon="fa-solid fa-magnifying-glass me-2" /> <span className='serarchtextt'>Search</span></button>
                                        <img className='fa-icc' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/icons/grid.png'} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </>
    )
}

export default Jpsearchbar


