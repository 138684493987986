import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import { useForm, Controller, SubmitHandler, useFieldArray, } from "react-hook-form";
import { higherEduPost, getEducation } from "../../../../../../../../utils/apis/jobseekar/jobseekar";
import classNames from "classnames"
import { RegxExpression } from "../../../../../../../../utils/apis/common/Common";


function Certifications({ activeTab, navItems, EduID, handleBack, handleSaveAndNext, educationData, userID, eduDeatils, id }) {

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm();

    const { append, fields, remove } = useFieldArray({
        control,
        name: "data",
    });


    const [data, setData] = useState([]);

    const getEducationDetails = async () => {
        const res = await getEducation(EduID);
        reset({ data: [] });
        if (res?.success) {
            reset({ data: [] });
            if (res?.data?.length > 0) {
                reset({ data: res?.data });
                setData(res?.data);
            } else {
                setData([]);
                append({
                    education_level: "",
                    awarded_year: "",
                    descipline: "",
                    authority: "",
                    degree_name: "",
                    collage: "",
                    percentage: "",
                });
            }
        }
    };


    useEffect(() => {
        getEducationDetails();
    }, [EduID]);


    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

    const onSubmit = async (d) => {
        d.education_id = 6
        d.personal_details_id = userID
        const res = await higherEduPost(d);
        if (res?.success) {
            handleSaveAndNext();
        }
    }




    return (
        <>
            <div className="personal-details-form ">
                {/* form-holder */}
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-container">
                        {/* School / College / University:: , Awarded Year: */}
                        {fields?.map((item, index) => (
                            <>
                                <div className="">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="">
                                                <Form.Group controlId="Education_Level">
                                                    <Form.Label className="">
                                                        Education Level:
                                                    </Form.Label>

                                                    <Form.Control
                                                        type="text"
                                                        name={`data[${index}].education_level`}
                                                        defaultValue={item.education_level}
                                                        placeholder="Enter Education Level"
                                                        {...register(`data[${index}].education_level`, {
                                                            required: "Education Level is Required",
                                                        })}
                                                        className={classNames("", {
                                                            "is-invalid": errors?.education_level,
                                                            // "is-valid": getValues("education_level"),
                                                        })}
                                                        onKeyDown={(event) => {
                                                            if (!RegxExpression.name.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="">
                                                <Form.Group controlId="Awarded_Year">
                                                    <Form.Label className="">
                                                        Awarded Year:
                                                    </Form.Label>

                                                    <Form.Control
                                                        type="text"
                                                        name={`data[${index}].awarded_year`}
                                                        placeholder="Enter Awarded Year"
                                                        defaultValue={item.awarded_year}
                                                        {...register(`data[${index}].awarded_year`, {
                                                            required: "Awarded Year is Required",
                                                            maxLength: 4,
                                                        })}
                                                        className={classNames("", {
                                                            "is-invalid": errors?.awarded_year,
                                                            // "is-valid": getValues("awarded_year"),
                                                        })}
                                                        onKeyDown={(event) => {
                                                            const charCode = event.keyCode || event.which;
                                                            const allowedKeys = /[0-9\b]/; // Allow numeric characters and Backspace (\b)
                                                            if ((!allowedKeys.test(event.key) || event.target.value.length >= 4) && charCode !== 46 && charCode !== 8) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        maxLength={4} // Adding maxlength attribute
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                < div className="" >
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-field">
                                                <Form.Group controlId="Desciplines">
                                                    <Form.Label className="">
                                                        Desciplines:
                                                    </Form.Label>

                                                    <Form.Control
                                                        type="text"
                                                        name={`data[${index}].descipline`}
                                                        placeholder="Enter Desciplines"
                                                        defaultValue={item.descipline}
                                                        {...register(`data[${index}].descipline`, {
                                                            required: "Desciplines is Required",
                                                        })}
                                                        className={classNames("", {
                                                            "is-invalid": errors?.descipline,
                                                            // "is-valid": getValues("descipline"),
                                                        })}
                                                        onKeyDown={(event) => {
                                                            if (!RegxExpression.name.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Authourity: , Degree Name: */}
                                <div className="">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-field">
                                                <Form.Group controlId="Authourity">
                                                    <Form.Label className="">
                                                        Authourity:
                                                    </Form.Label>

                                                    <Form.Control
                                                        type="text"
                                                        name={`data[${index}].authority`}
                                                        placeholder="Enter Authourity"
                                                        defaultValue={item.authority}
                                                        {...register(`data[${index}].authority`, {
                                                            required: "Authourity is Required",
                                                        })}
                                                        className={classNames("", {
                                                            "is-invalid": errors?.authority,
                                                            // "is-valid": getValues("authority"),
                                                        })}
                                                        onKeyDown={(event) => {
                                                            if (!RegxExpression.name.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className="col-md-6">
                                            <div className="form-field">
                                                <Form.Group controlId="Degree_Name:">
                                                    <Form.Label className="">
                                                        Degree Name:
                                                    </Form.Label>

                                                    <Form.Control
                                                        type="text"
                                                        name={`data[${index}].degree_name`}
                                                        placeholder="Enter Degree Name:"
                                                        defaultValue={item.degree_name}
                                                        {...register(`data[${index}].degree_name`, {
                                                            required: "Degree Name is Required",
                                                        })}
                                                        className={classNames("", {
                                                            "is-invalid": errors?.degree_name,
                                                            // "is-valid": getValues(`data[${index}].degree_name`),
                                                        })}
                                                        onKeyDown={(event) => {
                                                            if (!RegxExpression.name.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*  College / University */}
                                <div className="">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-field ">
                                                <Form.Group controlId="">
                                                    <Form.Label className="">
                                                        College / University:
                                                    </Form.Label>

                                                    <Form.Control
                                                        as="textarea"
                                                        name={`data[${index}].collage`}
                                                        placeholder="Enter College / University Name"
                                                        defaultValue={item.collage}
                                                        rows={3}
                                                        {...register(`data[${index}].collage`, {
                                                            required: "College / University Name is Required",
                                                        })}
                                                        className={classNames("", {
                                                            "is-invalid": errors?.collage,
                                                            // "is-valid": getValues("collage"),
                                                        })}
                                                        onKeyDown={(event) => {
                                                            if (!RegxExpression.name.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/*   Percentages: , Add Education */}
                                <div className="Percentages-Add-Education">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-field mb-lg-0 mb-md-0 mb-sm-3 mb-3">
                                                <Form.Group controlId="Percentages">
                                                    <Form.Label className="">
                                                        Percentages:
                                                    </Form.Label>

                                                    <Form.Control
                                                        type="text"
                                                        name={`data[${index}].percentage`}
                                                        placeholder="Enter Percentages"
                                                        defaultValue={item.percentage}
                                                        {...register(`data[${index}].percentage`, {
                                                            required: "Percentage is Required",
                                                        })}
                                                        className={classNames("", {
                                                            "is-invalid": errors?.percentage,
                                                            // "is-valid": getValues("percentage"),
                                                        })}
                                                        // onKeyDown={(event) => {
                                                        //     const charCode = event.charCode;
                                                        //     if ((charCode < 48 || charCode > 57) && charCode !== 8) {
                                                        //         event.preventDefault();
                                                        //     }
                                                        // }}
                                                        onKeyDown={(event) => {
                                                            const charCode = event.keyCode || event.which;
                                                            const allowedKeys = /[0-9\b]/; // Allow numeric characters and Backspace (\b)
                                                            if ((!allowedKeys.test(event.key) || event.target.value.length >= 2) && (charCode !== 46 && charCode !== 8)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        maxLength={4}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>
                                        {/* 
                                <div className="col-md-6"></div> */}
                                    </div>
                                    {index !== 0 && (
                                        <div className="addremoveadress">
                                            <div className="text-end Addnewadresstxt">
                                                <p className="" onClick={() => remove(index)}>
                                                    Remove Education{" "}
                                                    {/* <FontAwesomeIcon icon="fa-solid fa-minus" /> */}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        ))}

                        {/*  Add Education */}
                        <div className="Percentages-Add-Education form-field">
                            <div className="row">
                                <div className="col-md-6"></div>

                                <div className="col-md-6">
                                    <div className="Add-education-btn-holder">
                                        <div className="apply-btn-holder">
                                            <Button className="apply_now_btn" type="button" onClick={() =>
                                                append({
                                                    education_level: "",
                                                    awarded_year: "",
                                                    descipline: "",
                                                    authority: "",
                                                    degree_name: "",
                                                    collage: "",
                                                    percentage: "",
                                                    certificate: "",
                                                })
                                            }>
                                                Add Certificate
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="education-step-save-and-next">
                                    <div
                                        className="apply-btn-holder"
                                        style={{ textAlign: "center" }}
                                    >
                                        {activeTab !== navItems[0].eventKey && (
                                            <Button className="apply_now_btn mx-2" onClick={() => { handleBack(); getEducationDetails(); }}>
                                                Back
                                            </Button>
                                        )}
                                        <Button className="apply_now_btn mx-2" type="submit">
                                            Save & Next
                                        </Button>
                                        <Button className="apply_now_btn mx-2" onClick={() => handleSaveAndNext()} >
                                            Skip
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
                <div className="education-card">
                    <div className="row">
                        {data?.map((data, index) => (
                            <div className="col-md-3 col-sm-6 col-6">
                                <div className="card-holder">
                                    <h6>Certificate</h6>
                                    <div className="card-info-holder">
                                        <h4>{data?.education_level}</h4>
                                        <h4>{data?.awarded_year}</h4>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>

    );
}

export default Certifications;
