import React, { useState } from "react";
import { Button } from "react-bootstrap";
import JPJobSeekerRegister from "./JP-Jobseeker_register_form/JPJobSeekerRegister";
import JPEmployerRegister from "./JP-Employer_register_form/JPEmployerRegister";

function JpRegisterForm() {
  const [showJobSeekerRegister, setShowJobSeekerRegister] = useState(1);


  const handleJobSeekerClick = () => {
    if(showJobSeekerRegister == 1){      
    setShowJobSeekerRegister(2);
    }else{
      setShowJobSeekerRegister(1);
    }
  };

 

  return (
    <>
      <div className="form-container">
        <div className="tabs-btn">
          <div className="">
            <div className="apply-btn-holder ">
              <Button
                className={`apply_now_btn tabs-btn ${
                  showJobSeekerRegister == 1 ? "active" : ""
                }`}
                onClick={handleJobSeekerClick}
              >
                Jobseeker
              </Button>
            </div>
          </div>
          <div className="">
            <div className="apply-btn-holder ">
              <Button
                className={`apply_now_btn tabs-btn ${
                  showJobSeekerRegister == 2 ? "active" : ""
                }`}
                onClick={handleJobSeekerClick}
              >
                Employer
              </Button>
            </div>
          </div>
        </div>

        <div>
          {showJobSeekerRegister == 1 ? (
            <JPJobSeekerRegister showJobSeekerRegister={showJobSeekerRegister}/>
          ) : (
            <JPEmployerRegister showJobSeekerRegister={showJobSeekerRegister}/>
          )}
        </div>
      </div>
    </>
  );
}

export default JpRegisterForm;
