import React,{useEffect,useContext,useState} from 'react'
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import './Jp_profile_banner.css'
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { getProfilePicture } from '../../../../../utils/apis/jobseekar/jobseekar';
import { Context } from '../../../../../utils/context';
const Jp_Profile_banner = () => {

  const value = 0.3
  const radius = 50;
  const distance = 70;
  const angle = value * 360;

  const textPositionX = Math.cos((angle - 90) * (Math.PI / 180)) * (radius + distance) + radius;
  const textPositionY = Math.sin((angle - 90) * (Math.PI / 180)) * (radius + distance) + radius;

  const { getData, postData, IMG_URL } = useContext(Context);

  const [profileImage, setProfileImage] = useState();

  const getProfileData = async () => {
    const res = await getProfilePicture();
    if (res?.success) {
      setProfileImage(res?.data);
        console.log(res?.data,"fdsfe");
    } else {
        console.error("Error fetching  data:", res?.error);
    }
};

useEffect(() => {
  getProfileData();
}, []);


  return (
    <>
      <section className='Jp_Profile_banner-section'>
        <div className='container'>
          <div className='box'>
            <div className='row'>
              <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 text-center justify-content-center d-flex'>
                <div className="card profilecardone">
                  <div className="barimgmainsec">
                    <div className="CircularProgressbar">
                      <CircularProgressbar
                        value={value * 100}
                        maxValue={100}
                        strokeWidth={3}
                      />
                      <div  className="progress-text">
                        {value * 100}%
                      </div>
                    </div>

                    <div className="progreebarSec">
                      <img
                        src={IMG_URL + profileImage?.job_seeker_image?.image}
                        className="profileImg"
                      />
                    </div>
                  </div>
                </div>
                {/* <div className='img-div'>
                  <img className='jb-dash-logo' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/Logo/smalllogo.png'} />
                </div> */}
              </div>
              <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-5'>
                <div className='info-div'>
                  <h2>{profileImage?.first_name}   {profileImage?.last_name} </h2>
                  <p>{profileImage?.job_seeker_personal_detail?.job_profile_title}</p>
                </div>
              </div>
              <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3  col-sm-3 col-3'>
                <div className='btn-div'>
                  <Link to="/job-portal/jobseeker/myprofile">
                    <Button className='edit-btn' >Edit Profile</Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Jp_Profile_banner