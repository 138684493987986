import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import './FInsuranceEligibility.css'
import { Card } from 'react-bootstrap';
import parse from 'html-react-parser'
const FInsuranceEligibility = ({ service_id }) => {


    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/finance/insurance/f-i-eligibilty/${service_id}`);
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data, "dsdsdsdsd");

    useEffect(() => {
        getDataAll();
    }, [service_id]);

    return (
        <>
            {data && data?.length > 0 &&
                <section className='F-insurance-eligibility'>
                    <div className='container'>
                        <div className='row'>
                            <div className='hedaing-holder mb-5'>
                                <h2>Eligibility</h2>
                            </div>
                        </div>

                        <Swiper
                            spaceBetween={30}
                            pagination={{
                                clickable: true,
                                dynamicBullets: true,
                            }}
                            modules={[Pagination]}
                            breakpoints={{
                                576: { slidesPerView: 2 },
                                768: { slidesPerView: 1.5 },
                                1024: { slidesPerView: 2 },
                                1200: { slidesPerView: 2 },
                                1440: { slidesPerView: 3 },
                            }}
                            className="mySwiper"
                        >
                            {data?.map((image, index) => (
                                <SwiperSlide key={index}>
                                    <div className=' '>
                                        <Card className="mb-5">
                                            <div className="row g-0">
                                                <div className="col-lg-3 col-md-4">
                                                    <div className='bg-color'>
                                                        <div className=''>
                                                            <Card.Img className="fcircle-img" src={process.env.PUBLIC_URL + '/assets/Finance/Image/insurance/Scroll23.png'} alt="icon" />
                                                        </div>
                                                        <div className='image-icon text-cnter'>
                                                            <Card.Img className="ficon-img" src={IMG_URL + image.image} alt="icon" />
                                                        </div>
                                                        <div className='overlay-color' />
                                                    </div>
                                                </div>
                                                <div className="col-lg-9 col-md-8">
                                                    <Card.Body>
                                                        <div className='text-holder'>
                                                            <Card.Title>{image?.name}</Card.Title>
                                                            <Card.Text >{parse(image?.description) || ""} </Card.Text>
                                                        </div>
                                                    </Card.Body>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>

                    </div>
                </section>
            }
        </>
    )
}

export default FInsuranceEligibility