import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import "./ChooseFinance.css";
import { Col, Container, Row, card } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
const ChooseFinance = ({ loan_form_id }) => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/finance/loan/f-profcyma-finance/${loan_form_id}`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data, "dsdsdsdsd");

  useEffect(() => {
    getDataAll();
  }, [loan_form_id]);


  const ChooseFinanceDetails = [
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Finance/Image/HomeLoan/ChooseFinance/XMLID_806_1.png",
      text: "Quick and Easy Application",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Finance/Image/HomeLoan/ChooseFinance/speedometer2.png",
      text: "Speedy Approval Process",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Finance/Image/HomeLoan/ChooseFinance/calendar3.png",
      text: "Own it for up to XX months",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Finance/Image/HomeLoan/ChooseFinance/venture4.png",
      text: "Receive funds in just X working days",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Finance/Image/HomeLoan/ChooseFinance/bank5.png",
      text: "No need to visit a branch",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Finance/Image/HomeLoan/ChooseFinance/award6.png",
      text: "An ISO-certified company",
    }

  ];
  useEffect(() => {
    AOS.init({ once: true });
  }, [])
  return (
    <>
      {data && data?.length > 0 &&
        <section className="Choose-Finance">
          <Container>
            <h1 className="fianace-titleeee">Choose Profcyma Finance</h1>
            {/* <Row>
            {ChooseFinanceDetails.map((item, index) => (
              <Col xxl={2} xl={2} lg={4} md={4}>
                <div className="background-card">
                  <div className="background">
                    <div className="Finance-content">
                      <div className="logo">
                        <img src={item.image} className="img-one" />
                      </div>
                      <p className="text">{item.text}</p>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row> */}
            <div data-aos="fade-up"
              data-aos-duration="3000">
              <Swiper
                pagination={{
                  dynamicBullets: true,
                  clickable: true
                }}
                spaceBetween={30}
                modules={[Pagination]}
                breakpoints={{
                  480: { slidesPerView: 3 },
                  768: { slidesPerView: 4 },
                  1024: { slidesPerView: 5 },
                  1200: { slidesPerView: 6 },
                }}
                className="mySwiper"
              >

                {data?.map((data, index) => (
                  <SwiperSlide key={index}>
                    {/* <div className=' '>
                  <Card className="mb-5">
                    <div className="row g-0">
                      <div className="col-lg-3 col-md-4">
                        <div className='bg-color'>
                          <div className=''>
                            <Card.Img className="fcircle-img" src={process.env.PUBLIC_URL + icon.imageSrc1} alt="icon" />
                          </div>
                          <div className='image-icon text-cnter'>
                            <Card.Img className="ficon-img" src={process.env.PUBLIC_URL + icon.imageSrc} alt="icon" />
                          </div>
                          <div className='overlay-color' />
                        </div>
                      </div>
                      <div className="col-lg-9 col-md-8">
                        <Card.Body>
                          <div className='text-holder'>
                            <Card.fianace-titleeee>{icon.heading}</Card.fianace-titleeee>
                            <Card.Text dangerouslySetInnerHTML={{ __html: icon.text }} />
                          </div>
                        </Card.Body>
                      </div>
                    </div>
                  </Card>
                </div> */}
                    <div className="background-card">
                      <div className="background">
                        <div className="Finance-content">
                          <div className="logo">
                            <img src={IMG_URL + data?.image} className="img-one" />
                          </div>
                          <p className="text">{data?.name}</p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Container>
        </section>
      }
    </>
  );
};

export default ChooseFinance;
