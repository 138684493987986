import React, { useEffect, useState, useContext } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { Context } from "../../../../../../utils/context";
import { PhoneInput } from "react-international-phone";
import DatePicker from "react-datepicker";
import "../../../Service-Modal-Style/ServiceModalStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
// import OfferTableModal from "./HomeLoanSteps/Offer_Table_Modal/OfferTableModal";
import { Link, useLocation } from "react-router-dom";
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import StepOneUnlockBestHome from "../../F-Insurance/FHomeInsuranceModal/HomeInsuranceSteps/StepOneUnlockBestHome/StepOneUnlockBestHome";
import VerifyMobileNumber from "../../F-Insurance/FHomeInsuranceModal/HomeInsuranceSteps/StepOneUnlockBestHome/VerifyMobileNumber/VerifyMobileNumber";
library.add(fas);

function ServiceFormModal(props) {
  const [startDate, setStartDate] = useState(null);
  const [phone, setPhone] = useState("");
  const { onNext, serviceId } = props;

  const location = useLocation();
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();

  const [subtitle, setSubtitle] = useState(
    "Unlock the Best Home Loan Offers Suitable for your needs from 20+ Lenders"
  );

  const [showVerifyMobile, setShowVerifyMobile] = useState(false); // State to manage visibility of VerifyMobileNumber modal

  useEffect(() => {
    // Define your subtitle for each step here
    const subtitles = [
      "Unlock the Best Home Loan Offers Suitable for your needs from 20+ Lenders",
    ];

    // Update the subtitle based on the current step
    if (currentStep <= subtitles.length) {
      setSubtitle(subtitles[currentStep - 1]);
    }
  }, [currentStep]);

  const handleProceed = () => {
    if (currentStep === 1) {
      // Show VerifyMobileNumber modal on step one
      setShowVerifyMobile(true);
    } else if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    } else {
      // Handle form submission or any other action when all steps are completed
      props.onHide();
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  // const handleVerifyAndLogin = () => {
  //   setShowVerifyMobile(false); // Close the VerifyMobileNumber modal
  //   // setCurrentStep(2); // Move to Step Two
  //   window.location = "/finance/insurance";
  // };

  const pageHomeRedirect = () => {
    window.location = "/finance/home";
  };

  // Define your steps here
  const steps = [{ component: StepOneUnlockBestHome }];

  const totalSteps = steps.length;

  const { getData, IMG_URL, postData } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  const [mobileVerify, setMobileVerify] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState("");
  const [loan_form_id, setLoan_form_id] = useState(Cookies.get("loan_form_id"));

  console.log("mobileVerify", mobileVerify);


  useEffect(() => {
    getLoanFormData(Cookies.get("loan_form_id"));
    setLoan_form_id(Cookies.get("loan_form_id"))
    console.log("loan_form_id", loan_form_id);
  }, []);

  const getLoanForm = async (id) => {

    const res = await getLoanFormData(id);
    if (res?.success) {
      console.log("herehereherebhi");
      console.log(res, "users");
      setMobileVerify(true);
      reset(res.data);
      console.log("formId", res.data.id);
    }
  };

  const getLoanFormData = async (id) => {
    console.log("hereherehere", id);
    try {
      const res = await getData(
        `/finance/forms/insurance-form/${id}`
      );
      if (res?.success) {
        console.log("herehereherebhi");
        console.log(res, "users");
        await setMobileVerify(true);
        reset(res.data);
        console.log("formId", res.data.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOtpChange = (otpValue) => {
    setOtp(otpValue);
    // handleVerifyAndLogin();
  };

  const handleVerify = async (data) => {
    if (!mobileVerify) {
      handleGetOTP(data?.contact_no)
    } else if (!mobileVerify && showVerifyMobile) {
      handleVerifyAndLogin(data?.contact_no)
    } else if (mobileVerify && !showVerifyMobile) {
      handleSubmitNewUser(data);
    }
  };

  const handleGetOTP = async (contact_no) => {
    if (contact_no) {
      let sanitizedContactNo = contact_no.replace(/^(\+91)?/, '');
      // Remove any dashes (-)
      sanitizedContactNo = sanitizedContactNo.replace(/-/g, '');
      const res = await postData(`/without-login/verify-otp/otp-send`, { contact_no: sanitizedContactNo });
      if (res?.success) {
        console.log(res, "otp");
        setOtpErr()
        setShowVerifyMobile(true);
      } else {
        // Handle error response here
        console.error("Failed to send OTP:", res.message);
      }
    } else {
      // Handle case when contact_no is not available
      console.error("Contact number is required");
      // setContactError("Contact number is required");
    }
  };

  const handleVerifyAndLogin = async (contact_no) => {

    if (!otp) {
      setOtpErr("please Enter Otp");
    } else if (otp.length !== 4) {
      setOtpErr("please Enter Complete Otp");
    }
    if (contact_no && otp) {
      let sanitizedContactNo = contact_no.replace(/^(\+91)?/, '');
      // Remove any dashes (-)
      sanitizedContactNo = sanitizedContactNo.replace(/-/g, '');
      const res = await postData(`/without-login/verify-otp/verify-otp`, { contact_no: sanitizedContactNo, otp: otp });
      if (res?.success) {
        setShowVerifyMobile(false)
        setMobileVerify(true)
        handleSubmitNewUser()
      } else {
        setOtpErr(res?.message);
      }
    }

    // setShowVerifyMobile(false); // Close the VerifyMobileNumber modal
    // setCurrentStep(2); // Move to Step Two
  };

  const handleSubmitNewUser = async (data) => {
    try {
      const formData = new FormData();
      if (loan_form_id) {
        formData.append('id', loan_form_id);
      }
      formData.append('name', getValues("name"));
      formData.append('dob', getValues("dob"));
      formData.append('contact_no', getValues("contact_no"));
      formData.append('service_id', Cookies.get("service_id"));
      // formData.append('id', Cookies.get("loan_form_id"));
      const res = await postData(`/finance/forms/insurance-form`, formData);
      if (res?.success) {
        Cookies.set("loan_form_id", res.data.id, {
          expires: 1,
        });
        // setShowPleaseWaitModal(true);
        props.onHide();
        if (Cookies.get("service_type_id") == 1) {
          navigate(`/finance/home-loan/${Cookies.get("service_id")}`);
        } else if (Cookies.get("service_type_id") == 2) {
          navigate(`/finance/insurance/${Cookies.get("service_id")}`);
        }

      } else {
        // Check if the error message is specific to "contact_no" already exists
        if (res?.code === 404 && res?.message?.contact_no) {
          setError("contact_no", {
            type: "manual",
            message: res.message.contact_no,
          });
        } else {
          // Handle other errors if needed
          console.error("Error:", res?.message);
        }
      }

    } catch (error) {
      console.error(error);
    }
  }

  // const handlePhoneInputChange = (phone) => {
  //   const currentPhoneNumber = getValues("contact_no");
  //   console.log("currentPhoneNumber", currentPhoneNumber);
  //   console.log("newNum", phone);
  //   if (phone !== currentPhoneNumber) {
  //     setValue("contact_no", phone); // Update form value
  //     // setMobileVerify(false); // Set only when phone number changes
  //   }
  // };

  // const validateDateOfBirth = (date) => {
  //   const today = new Date();
  //   const birthDate = new Date(date);
  //   const age = today.getFullYear() - birthDate.getFullYear();
  //   const monthDiff = today.getMonth() - birthDate.getMonth();
  //   if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
  //     age--;
  //   }
  //   return age >= 18;
  // };

  const handleDateChange = (event) => {
    const value = event.target.value;
    validateAge(value);
  };

  const validateAge = (dob) => {
    console.log("dob", dob);
    const selectedDate = new Date(dob);
    const currentDate = new Date();
    const minAgeDate = new Date();
    minAgeDate.setFullYear(currentDate.getFullYear() - 18);

    if (selectedDate > minAgeDate) {
      console.log("dob", "Age should not be less than 18 years");
      setError("dob", { type: "manual", message: "Age should not be less than 18 years" });
      return "Age should not be less than 18 years";
    }
    console.log("dob", "done");
    clearErrors("dob")
    return true;
  };


  return (
    <>
      {/* loan-insurance-modal */}
      <div className="loan-insurance-modal">
        <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="loan-insurance-modal content-body-holder"
        >
          <div className="modal-content-background">
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                {currentStep == 1 ? (
                  <span onClick={() => props.onHide()}>
                    <FontAwesomeIcon
                      className="me-2"
                      icon="fa-solid fa-angle-left"
                    />
                    Back
                  </span>
                ) : (
                  <span onClick={handleBack}>
                    <FontAwesomeIcon
                      className="me-2"
                      icon="fa-solid fa-angle-left"
                    />
                    Back
                  </span>
                )}
                <h4>{props.serviceName}</h4>
              </Modal.Title>
              {/* <h4 className="subtitle">{subtitle}</h4> */}
            </Modal.Header>
            <div className="step-ten-eleven-background-color content-body">
              <Modal.Body>
                {/* <div className="Home_Loan_Step">
                  {React.createElement(steps[currentStep - 1].component)}
                </div> */}
                <div className="StepOneUnlockBestHome form-style">
                  <div className="contact-number">
                    <Form onSubmit={handleSubmit(handleVerify)}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Your Name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          name="name"
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          {...register("name", {
                            required: "Name is required",
                          })}
                          onKeyDown={(event) => {
                            if (!/[A-Z-a-z ]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                        {errors?.name && (
                          <span className="text-danger">
                            {errors?.name.message}
                          </span>
                        )}
                      </Form.Group>

                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Date of Birth</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Enter Date Of Birthday"
                          name="dob"
                          max={new Date().toISOString().split('T')[0]}
                          {...register("dob", {
                            required: "date required",

                          })}
                          className={classNames("", {
                            "is-invalid": errors?.dob,
                          })}
                          onChange={handleDateChange}
                        />
                        {errors?.dob && (
                          <span className="text-danger">
                            {errors?.dob?.message}
                          </span>
                        )}
                      </Form.Group>

                      <Form.Group className="" controlId="formBasicEmail">
                        <Form.Label>Mobile Number</Form.Label>
                        <div className="input-span-holder">
                          <div className="country-coder">
                            <Controller
                              control={control}
                              name="contact_no"

                              rules={{
                                required: "Contact number is required",
                                validate: (value) => {
                                  const phoneNumber = parsePhoneNumberFromString(value, 'IN');
                                  if (!phoneNumber || !phoneNumber.isValid()) {
                                    return "Invalid phone number";
                                  }
                                  return true;
                                },
                              }}
                              render={({ field }) => (
                                <PhoneInput
                                  disabled={mobileVerify}
                                  defaultCountry="in"
                                  value={field.value}
                                  // onChange={(phone) => {
                                  //   if (field.value !== phone) {
                                  //     field.onChange(phone);
                                  //     // setMobileVerify(false); // Set only when phone number changes
                                  //   }
                                  // }}
                                  // onChange={(phone) => handlePhoneInputChange(phone)}
                                  placeholder="Enter Your Contact Number"
                                  {...register("contact_no", {
                                    required: "Conact No is required",
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.contact_no,
                                  })}
                                />
                              )}
                            />
                          </div>
                          {errors?.contact_no && (
                            <span className="text-danger">
                              {errors?.contact_no?.message}
                            </span>
                          )}
                          <span className="span-holder">0/10 Digits</span>
                        </div>
                        <Form.Text className="text-muted">
                          We will check offers against your number
                        </Form.Text>
                      </Form.Group>

                      <Modal.Footer>
                        <Button
                          className="submit-btn"
                          type="submit"
                        // onClick={handleProceed}
                        >
                          Proceed{" "}
                          <FontAwesomeIcon
                            className="right-angle-arrow "
                            icon="fa-solid fa-angles-right"
                          />
                        </Button>

                        <div className="Terms-And-Conditions-Text">
                          <p>
                            By clicking on Proceed, you have read and agree to the
                            Profcyma{" "}
                            <span>
                              Credit Report Terms of Use. Profcyma Terms of Use &
                              Privacy Policy
                            </span>
                          </p>
                        </div>
                      </Modal.Footer>
                    </Form>
                  </div>
                </div>
              </Modal.Body>
              {/* <Modal.Footer>
                <Button className="submit-btn" onClick={handleProceed}>
                  Proceed{" "}
                  <FontAwesomeIcon
                    className="right-angle-arrow "
                    icon="fa-solid fa-angles-right"
                  />
                </Button>

                <div className="Terms-And-Conditions-Text">
                  <p>
                    By clicking on Proceed, you have read and agree to the
                    Profcyma{" "}
                    <span>
                      Credit Report Terms of Use. Profcyma Terms of Use &
                      Privacy Policy
                    </span>
                  </p>
                </div>
              </Modal.Footer> */}
            </div>
          </div>
        </Modal>
      </div>

      {/* VerifyMobileNumber modal */}

      <div className="VerifyMobileNumber-modal">
        <Modal
          {...props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="loan-insurance-modal VerifyMobileNumber-modal content-body-holder"
          show={showVerifyMobile}
          onHide={() => setShowVerifyMobile(false)}
        >
          <div className=" content-body">
            <Modal.Header>
              <FontAwesomeIcon
                icon="fa-solid fa-circle-xmark"
                onClick={() => setShowVerifyMobile(false)}
              />
            </Modal.Header>

            <Modal.Body>
              <VerifyMobileNumber
                contact_no={getValues("contact_no")}
                onOtpChange={handleOtpChange}
                otpError={otpErr}
                setOtpErr={setOtpErr}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button className="submit-btn" onClick={() => handleVerifyAndLogin(getValues("contact_no"))}>
                Verify & Login
              </Button>
              <div className="get-notify">
                <Form className="get-notify-switch">
                  <div className="whatsapp-icon-holder">
                    <img
                      className="whatsapp-icon"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Finance/Image/icons/whatsapp.png"
                      }
                      alt="whatsapp-icon"
                    />
                  </div>
                  <span>Get regular Credit Report updates via Whatsapp</span>
                  <Form.Check // prettier-ignore
                    type="switch"
                  />
                </Form>
              </div>

              <div className="Terms-And-Conditions-Text">
                <p>
                  By logging in, you agree to the following
                  <span>
                    Credit Report Terms of Use,Terms of Use and Privacy Policy
                  </span>
                </p>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </>
  );
}

export default ServiceFormModal;