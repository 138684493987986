import { React, useRef, useEffect, useState, useContext } from "react";
import { Form } from "react-bootstrap";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from "../../../../../../../utils/context";
import Select from "react-select";
import classNames from "classnames";

function StepElevenGetBestOffers({ setUserData, loanFormData }) {
  const [modalShow, setModalShow] = useState(false);
  const [phone, setPhone] = useState("in");

  useEffect(() => {
    reset(loanFormData);
    console.log("from step 10 :- ", loanFormData);
  }, [loanFormData]);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset
  } = useForm();
  const [pincode, setPincode] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);

  const [formData, setFormData] = useState({
    gender: "",
    address: "",
    pincode_id: "",
    pan_no: ""
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const GetAllPincode = async () => {
    const response = await getData("/without-login/master/all-pincodes");

    if (response.success) {
      setPincode(await Select2Data(response?.data, "pincode_id"));
    }
  };

  const fetchEmploymentTypes = async () => {
    try {
      const response = await getData("/without-login/master/all-employment-type");
      if (response.success) {
        // Extracting only the "name" property from each object
        const names = response.data?.map(employmentType => employmentType.name);
        setEmploymentTypes(names);
      } else {
        // Handle error when response is not successful
        console.error('Error fetching employment types:', response.error);
      }
    } catch (error) {
      // Handle fetch error
      console.error('Error fetching employment types:', error);
    }
  };



  useEffect(() => {
    GetAllPincode();
    fetchEmploymentTypes();
  }, []);

  const onSubmit = async () => {
    try {
      // Convert the pincode_id value to match the expected format
      formData.pincode_id = formData.pincode_id?.value;

      // Call setUserData with the form data
      setUserData(formData);

    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {

    onSubmit();
  }, [formData]);



  const CityType = async () => {
    try {
      return await getData(`/without-login/master/all-cities`);
    } catch (error) {
      console.log(error);
    }
  };
  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
  const [cityTypes, setCityType] = useState([]);
  const getMasters = async () => {
    {
      const res = await CityType();
      if (res?.success) {
        const data = await Select2Data(res.data, "city_id", false);
        setCityType(data);
      }
    }
  };
  useEffect(() => {
    getMasters();
  }, []);
  return (
    <>
      <div className="StepTenGetBestOffers ">

        <Form className="StepTenGetBestOffers-form">
          <Form.Group className="mb-3" controlId="">
            <Form.Label>Gender</Form.Label>
            <div className="Gender-holder">
              <Form.Check
                inline
                label="Male"
                name="gender"
                type="radio"
                value="male"
                {...register("gender", {
                  required: "",
                })}
                className={classNames("", {
                  "is-invalid": errors?.gender,
                })}
                onChange={handleChange}
              />
              <Form.Check
                inline
                label="Female"
                name="gender"
                type="radio"
                value="female"
                {...register("gender", {
                  required: "",
                })}
                className={classNames("", {
                  "is-invalid": errors?.gender,
                })}
                onChange={handleChange}
              />
            </div>
          </Form.Group>

          <Form.Group className="mb-3" controlId="">
            <Form.Label>Address</Form.Label>
            <Form.Control type="text" name="address" placeholder="Enter Address" {...register("address", {
              required: "Address is required",
            })}
              className={classNames("", {
                "is-invalid": errors?.address,
              })}
              onKeyDown={(event) => {
                if (!/[A-Z-a-z ]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={handleChange}

            />
            {errors?.address && (
              <span className="text text-danger">
                {errors.address.message}
              </span>
            )}
          </Form.Group>

          <Form.Group className="mb-3" controlId="">
            <Form.Label>Pincode</Form.Label>
            <Controller
              name="pincode_id" // name of the field
              {...register("pincode_id", {
                required: "Pincode is required",
              })}
              control={control}
              render={({ field }) => (
                <Select
                  styles={{
                    control: (baseStyles) => ({
                      ...baseStyles,
                      borderColor: errors.pincode_id
                        ? "red"
                        : baseStyles,
                    }),
                  }}
                  {...field}
                  options={pincode}
                  // isMult
                  isSearchable

                  onChange={(value) => {
                    handleChange({
                      target: {
                        name: "pincode_id",
                        value: value
                      }
                    });
                  }}

                />
              )}
            />
            {errors?.pincode_id && (
              <span className="text text-danger">
                {errors.pincode_id.message}
              </span>
            )}
          </Form.Group>

          <Form.Group className="" controlId="">
            <Form.Label>Pan Number</Form.Label>
            <Form.Control
              type="text"
              name="pan_no"
              placeholder="Enter PAN number"
              {...register("pan_no", {
                required: "PAN Number is required",
                pattern: {
                  value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                  message: "Invalid PAN Number",
                },
              })}
              className={classNames("", {
                "is-invalid": errors?.pan_no,
              })}
              onChange={handleChange}
            />

            {errors.pan_no && (
              <span className="text-danger">{errors.pan_no.message}</span>
            )}
          </Form.Group>
        </Form>
      </div>
    </>
  );
}

export default StepElevenGetBestOffers;
