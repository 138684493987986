import React, { useState,useContext } from "react";
import "./PostBanner.css";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Exlamentry from "../../../JP-Common-Elements/Jp-modals/Exlamentry_Modal/Exlamentry";
import { Context } from "../../../../../utils/context";
import { useNavigate } from "react-router-dom";


const PostBanner = () => {

  const navigate = useNavigate();

  const {signin,usertype} = useContext(Context)

  const [modalShow, setModalShow] = useState(false);
  const openmodal = () => {
    if(signin){
      navigate("/job-portal/employer/stepform")
    }else{      
      setModalShow(true);
      setTimeout(() => {
        navigate("/job-portal/sign-in");
        setModalShow(false);
      }, 3000);
    }
  };
  return (
    <>
      <section className="Postbanner">
        <div className="container">
          <div className="col-lg-8 mx-auto">
            <div className="row postbannerRow">
              <div className="col-lg-8">
                <div className="bannerHead">
                  <h2>
                    <span>Let's hire next talent, </span>

                    <span> Quickly!</span>
                  </h2>
                  <div>
                    <div className="apply-btn-holder">
                      {/* <Link to={"/job-portal/employer/stepform"}> */}
                        <Button className="apply_now_btn" onClick={openmodal}>
                          Post Job
                        </Button>
                      {/* </Link> */}
                    </div>

                    <Exlamentry
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      text={"Your Need to Log In to Employer to Post Job!"}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div>
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Job-portal/Images/employer/FeaturedJob/OBJECTS.png"
                    }
                    className="bannerImg"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PostBanner;
