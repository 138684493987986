import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./FeedbackForm.css";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import Select from "react-select";
import classNames from "classnames";
import SuccessForm from "../SuccessForm/SuccessForm";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

function FeedbackForm() {
  const [startDate, setStartDate] = useState();
  const [modalShow, setModalShow] = useState(false);
  const [phone, setPhone] = useState("in");
  const [submitmodalShow, setSubmitModalShow] = useState(false);


  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset
  } = useForm();
  const [pincode, setPincode] = useState([]);



  const GetAllPincode = async () => {
    const response = await getData("/without-login/master/all-pincodes");

    if (response.success) {
      setPincode(await Select2Data(response?.data, "pincode_id"));
    }
  };

  useEffect(() => {
    GetAllPincode();
  }, []);

  const onSubmit = async (d) => {
    //alert('ss');
    try {
      const res = await postData(`/finance/forms/feedback-form`, d);
      if (res?.success) {
        console.log(res, 'submit');
        setModalShow(true);

        setTimeout(() => {
          // window.location.reload();
          reset()
          setModalShow(false);
        }, 2000)
      }
    } catch (error) {
      console.error(error);
    }
  }



  const CityType = async () => {
    try {
      return await getData(`/without-login/master/all-cities`);
    } catch (error) {
      console.log(error);
    }
  };
  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
  const [cityTypes, setCityType] = useState([]);
  const getMasters = async () => {
    {
      const res = await CityType();
      if (res?.success) {
        const data = await Select2Data(res.data, "city_id", false);
        setCityType(data);
      }
    }
  };
  useEffect(() => {
    getMasters();
  }, []);

  return (
    <>
      <div className="feedback-form-container">
        <h4>Feedback Form</h4>

        <div className="feedback-form">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-xl-8 col-md-12">
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    name="name"
                    className={classNames("", {
                      "is-invalid": errors?.name,
                    })}
                    type="text"
                    {...register("name", {
                      required: "Name is required",
                      pattern: {
                        value: /^[A-Za-z ]*$/,
                        message: "Name must contain only letters",
                      },
                    })}
                    onKeyDown={(event) => {
                      if (!/[A-Z-a-z ]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                    placeholder="Enter Full Name"
                  />
                  {/* {errors?.name && (
                    <span className="text text-danger">
                      {errors.name.message}
                    </span>
                  )} */}
                </Form.Group>
              </div>

              <div className="col-xl-4 col-md-12">
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Gender</Form.Label>
                  <div className="mb-2">
                    <Form.Check
                      inline
                      label="Male"
                      name="gender"
                      type="radio"
                      value="male"
                      {...register("gender", {
                        required: "Select Gender",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.gender,
                      })}
                    />
                    <Form.Check
                      inline
                      label="Female"
                      name="gender"
                      type="radio"
                      value="female"
                      {...register("gender", {
                        required: "Select Gender",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.gender,
                      })}
                    />
                    {errors?.gender && (
                      <FontAwesomeIcon icon={faExclamationCircle} size="1x" color="red" />

                    )}
                  </div>

                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-8 col-md-12">
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Address</Form.Label>
                  <Form.Control type="text" name="address" placeholder="Enter Address" {...register("address", {
                    required: "Address is required",
                  })}
                    className={classNames("", {
                      "is-invalid": errors?.address,
                    })}
                  // onKeyDown={(event) => {
                  //   if (!/[A-Z-a-z ]/.test(event.key)) {
                  //     event.preventDefault();
                  //   }
                  // }}

                  />
                  {/* {errors?.address && (
                    <span className="text text-danger">
                      {errors.address.message}
                    </span>
                  )} */}
                </Form.Group>
              </div>

              <div className="col-xl-4 col-md-12">
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Pin Code</Form.Label>
                  <Form.Control
                    type="text"
                    name="pincode"
                    placeholder="Enter Pincode"
                    {...register("pincode", {
                      required: "pincode is required",
                      minLength: {
                        value: 6,
                        message: "Pincode must be 6 digits long",
                      },
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.pincode,
                    })}
                    onKeyDown={(event) => {
                      if (!/[0-9]/.test(event.key) && event.key !== "Backspace") {
                        event.preventDefault();
                      }

                      if (event.target.value.length >= 6 && event.key !== "Backspace") {
                        event.preventDefault();
                      }
                    }}
                    inputMode="numeric"
                    pattern="[0-9]*"
                  />
                  {/* {errors?.pincode && (
                    <span className="text text-danger">
                      {errors.pincode.message}
                    </span>
                  )} */}
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-4 col-md-12">
                {/* <Form.Group className="mb-3" controlId="">
                  <Form.Label>Mobile Number</Form.Label>
                  <div className="input-span-holder">
                    <Form.Control type="text" />
                    <span>0/10 Digits</span>
                  </div>
                </Form.Group> */}
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Mobile Number</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      maxLength="10"
                      name="contact_no"
                      placeholder="Mobile number"
                      {...register("contact_no", {
                        required: "Contact is required",
                        minLength: {
                          value: 10,
                          message: "Contact must be 10 digits long",
                        },
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.contact_no,
                      })}
                      onKeyDown={(event) => {
                        if (!/[0-9]/.test(event.key) && event.key !== "Backspace") {
                          event.preventDefault();
                        }

                        if (event.target.value.length >= 10 && event.key !== "Backspace") {
                          event.preventDefault();
                        }
                      }}
                      inputMode="numeric"
                      pattern="[0-9]*"


                    />
                    {/* {errors?.contact_no && (
                      <span className="text text-danger">
                        {errors.contact_no.message}
                      </span>
                    )} */}
                    {/* <InputGroup.Text id="basic-addon1">0/10 Digits</InputGroup.Text> */}
                  </InputGroup>
                </Form.Group>
              </div>

              <div className="col-xl-4 col-md-12">
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control type="text" name="email" placeholder="Email ID"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                        message: "Invalid email address",
                      }
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.email,
                    })}

                  />
                  {/* {errors?.email && (
                    <span className="text text-danger">
                      {errors.email.message}
                    </span>
                  )} */}
                </Form.Group>
              </div>

              <div className="col-xl-4 col-md-12">
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Enter Date Of Birthday"
                    name="dob"

                    {...register("dob", {
                      required: "Date Of Birth Required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.dob,
                    })}
                  />
                  {/* {errors?.dob && (
                    <span className="text text-danger">
                      {errors.dob.message}
                    </span>
                  )} */}

                </Form.Group>

              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                {/* <Form.Group className="mb-3" controlId="">
                  <Form.Label>Message</Form.Label>

                  <div className="input-span-holder">
                    <Form.Control as="textarea" rows={3} />
                    <span>0/150</span>
                  </div>
                </Form.Group> */}
                <Form.Group className="mb-3" controlId="">
                  <Form.Label>Message</Form.Label>
                  <Form.Control type="text" placeholder="Enter Message"{...register("message", {
                    required: "Message is required",
                  })}
                    className={classNames("", {
                      "is-invalid": errors?.message,
                    })}
                  // onKeyDown={(event) => {
                  //   if (!/[A-Z-a-z ]/.test(event.key)) {
                  //     event.preventDefault();
                  //   }
                  // }}

                  />
                  {/* {errors?.message && (
                    <span className="text text-danger">
                      {errors.message.message}
                    </span>
                  )} */}
                  {/* <Form.Control type="text" /> */}
                  <InputGroup className="mb-3">

                    {/* <Form.Control type="text" /> */}
                    {/* <InputGroup.Text id="basic-addon1">0/150</InputGroup.Text> */}
                  </InputGroup>
                </Form.Group>
              </div>
            </div>

            <div className="row">
              <div className="col-12">
                <button className="send-button" type="submit" onClick={() => setSubmitModalShow(true)}>
                  Send Message
                </button>
                <SuccessForm
                  show={modalShow}
                  onHide={() => setSubmitModalShow(false)}
                />
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
}

export default FeedbackForm;
