import React, { useState, useEffect, useContext } from "react";
import Jphomesearch from './jphomesearch/Jphomesearch'
import Jpbrowsecategory from './jpbrowse-category/Jpbrowsecategory'
import Jpmakediffrencebanner from './Jpmakediffbanner/Jpmakediffrencebanner'
import Jp_home_recent_jobs from './Jp-home-recent-jobs/Jp_home_recent_jobs'
import Jphomecounter from './jp-home-counter/Jphomecounter'
import Jp_home_testimonials from './jp-home-testimonials/Jp_home_testimonials'
import Jp_latest_update from './jp-home-latest-update/Jp_latest_update'
import JpTestimonials from './JpTestimonials/JpTestimonials'
import { useLocation } from 'react-router-dom';

const Jphome = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [job_type_id, setJobTypeId] = useState();
  const [city_id, setCityId] = useState();
  const [job_title, setJobTitle] = useState();


  // const query = queryParams.get('query');
  // const jobTypeId = queryParams.get('job_type_id');
  // const cityId = queryParams.get('city_id');
  // const companyName = queryParams.get('company_name');
  console.log(city_id, 'cityId: ');
  console.log(job_title, 'companyName: ');
  console.log(job_type_id, 'jobTypeId: ');
  return (
    <>
      <Jphomesearch
        setJobTypeId={setJobTypeId}
        setCityId={setCityId}
        setJobTitle={setJobTitle}
      />
      <Jpbrowsecategory />
      <Jpmakediffrencebanner />
      <Jp_home_recent_jobs
        job_type_id={job_type_id}
        city_id={city_id}
        job_title={job_title}
      />
      <Jphomecounter />
      {/* <Jp_home_testimonials /> */}
      <JpTestimonials />
      <Jp_latest_update />
    </>
  )
}

export default Jphome