import React, { useRef, useState, useContext, useEffect } from "react";
import './ed_tech_welcome.css'
import Autosuggest from 'react-autosuggest';
import { Swiper, SwiperSlide } from "swiper/react";
import classNames from "classnames";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Form from 'react-bootstrap/Form';
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../utils/context";
import Main_junction from "../../main-junction/Main_junction";
import AutoSuggest from "react-autosuggest";


const Ed_tech_welcome = () => {
    const { IMG_URL, getData } = useContext(Context);
    const [value, setValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [data, setData] = useState([]);
    const [courseTypes, setCourseTypes] = useState([]);
    const navigate = useNavigate();
    const [searchResult, setSearchResult] = useState([]);


    const getalluniversity = async () => {
        try {
            const res = await getData(`/without-login/ed-tech/university/common/all-university`);
            if (res?.success) {
                setSearchResult(res?.data?.data);

            } else {
                setSearchResult(null);

            }
        } catch (error) {
            console.error("Error fetching search results:", error);
        }

    }

    function getSuggestions(value) {
        return lowerCasedCompanies?.filter(company =>
            company?.name.includes(value.trim().toLowerCase())
        );
    }

    const homemenus = [
        {
            name: "Free Counselling",
            imgSrc: "/assets/images/home-menu/operator.png",
            link: "/comming-soon",
        },
        {
            name: "College Compare",
            imgSrc: "/assets/images/home-menu/exchange-horizontal.png",
            link: "/comming-soon",
        },
        {
            name: "Job Portal",
            imgSrc: "/assets/images/home-menu/suitcase.png",
            link: "/job-portal/home",
            // link: "/comming-soon",
        },
        {
            name: "Finance",
            imgSrc: "/assets/images/home-menu/rupee.png",
            link: "/finance/home",
            // link: "/comming-soon",
        },
        {
            name: "Ed-Tech",
            imgSrc: "/assets/images/home-menu/mortarboard.png",
            link: "/ed-tech/home",
        },
    ];

    const courses = [
        {
            name: "BBA",
            link: "",
        },
        {
            name: "MBA",
        },
        {
            name: "MCA",
        },
        {
            name: "BBA",
        },
        {
            name: "MBA",
        },
        {
            name: "MCA",
        },
    ];

    const homebannerbackground = [
        {
            video: "/assets/videos/home/banner/banner1.mp4",
        },
    ];



    const lowerCasedCompanies = searchResult?.map(company => {
        return {
            id: company?.id,
            name: company?.name?.toLowerCase()
        };
    });

    console.log(lowerCasedCompanies, "iddddddddd");

    const handleNavigate = (id) => {
        navigate(`/ed-tech/university/common-university/${id}`)
    }


    // const [courses, setCourses] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(
                `/without-login/ed-tech/university/common/all-university`
            );
            // const response2 = await getData(
            //   `/without-login/ed-tech/courses/common/common-type/courses-type`
            // );
            const response3 = await getData(
                `/without-login/ed-tech/courses/common/common-type/courses`
            );


            setData(response?.data);
            // setCourseTypes(response2?.data);
            setCourseTypes(response3?.data);
            console.log(response3?.data, "datatatta");
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data);
    const [selectedSuggestion, setSelectedSuggestion] = useState(null);




    useEffect(() => {
        getDataAll();
        getalluniversity()
    }, []);

    return (
        <section className="welcome-banner-section">
            <div className="container-fluid p-0">
                {homebannerbackground?.map((background, index) => (
                    <div className="video-main">
                        <video class="video-bg" loop autoPlay muted>
                            <source src={background.video} type="video/mp4" />
                        </video>
                        <div className="black-overlay"></div>
                    </div>
                ))}

                <div className=" overflow-content">
                    <div className="container">
                        <div className="logo-div">
                            <img
                                className="logoimg"
                                src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/logo/profcyma_logo.png"
                                }
                            />
                        </div>

                        <div className="content-div">
                            <h1 className="heding title">Elevate You with Online Education!</h1>
                            <p className="subtitle">
                                Embark on your journey to success with Profcyma
                            </p>
                        </div>

                        <div className="search-div">
                            <AutoSuggest
                                suggestions={suggestions}
                                onSuggestionsClearRequested={() => setSuggestions([])}
                                onSuggestionsFetchRequested={({ value }) => {
                                    console.log(value);
                                    setValue(value);
                                    setSuggestions(getSuggestions(value));
                                }}
                                onSuggestionSelected={(_, { suggestion }) => {
                                    console.log("Selected: " + suggestion?.id);
                                    setSelectedSuggestion(suggestion?.id);
                                }}
                                getSuggestionValue={suggestion => suggestion?.name}
                                renderSuggestion={suggestion => <span>{suggestion?.name}</span>}
                                inputProps={{
                                    className: "form-control",
                                    placeholder: "Search Universities Name",
                                    value: value,
                                    onChange: (_, { newValue }) => {
                                        setValue(newValue);
                                    }
                                }}
                                highlightFirstSuggestion={true}
                            />
                            {/* <Link to="/ed-tech/university/common-university/5"> */}
                            <button className="next-btn btn" onClick={() => handleNavigate(selectedSuggestion)}>
                                <img
                                    className="next-icon"
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/search-next.png"
                                    }
                                />
                            </button>
                            {/* </Link> */}
                            <p className="trending-p">Our Trending Searches</p>

                            <Swiper
                                pagination={{
                                    dynamicBullets: true,
                                }}
                                slidesPerView={3}
                                centeredSlides={false}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}
                                navigation={true}
                                modules={[Autoplay, Pagination, Navigation]}
                                breakpoints={{
                                    "0": {
                                        slidesPerView: 3,
                                        spaceBetween: 0,
                                    },
                                    "500": {
                                        slidesPerView: 3,
                                        spaceBetween: 0,
                                    },
                                    "991": {
                                        slidesPerView: 3,
                                        spaceBetween: 0,
                                    },
                                    "1400": {
                                        slidesPerView: 3,
                                        spaceBetween: 0,
                                    },
                                }}
                                className="mySwiper"
                            >
                                {courseTypes?.map((name, index) => (

                                    <SwiperSlide>
                                        <Link to={`/ed-tech/ed-tech/courses/common-courses/${name?.id}`}>
                                            <div className="trending-box">
                                                <img
                                                    className="grad-icon1"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        "/assets/images/icons/mortarboard2.png"
                                                    }
                                                />
                                                <img
                                                    className="grad-icon2"
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        "/assets/images/icons/mortarboard1.png"
                                                    }
                                                />
                                                <p className="course-name">{name?.name}</p>
                                            </div>
                                        </Link>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>

                        <div className="web-link-div">
                            <Link className="webb" to="/">
                                www.profcyma.com
                            </Link>
                        </div>
                    </div>
                </div>



                <Main_junction />

            </div>
        </section>
    )
}

export default Ed_tech_welcome