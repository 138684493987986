import React, { useContext, useEffect, useState } from "react";
import "./Jp_saved_jobs_components.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Context } from "../../../../../utils/context";
import { savedUserJob } from "../../../../../utils/apis/common/Common";
import { useParams, useNavigate } from "react-router-dom";

library.add(fas);

const Jp_saved_jobs_components = () => {
  const navigate = useNavigate();
  const [getSaveUserJob, setSaveJob] = useState();
  const { getData, signin, IMG_URL } = useContext(Context);

  const getproject = async () => {
    const res = await savedUserJob();
    console.log(res.data.jobSeekerSaveJobs, 'ygdjhdgjhdjjhgsdhgfjhg');
    if (res?.success) {
      setSaveJob(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getproject();
  }, []);
  const savedJobs = [
    {
      id: 1,
      companyName: "Profcyma Solutions Pvt. Ltd.",
      image: "/assets/Job-portal/Images/job/IT/Job_IT_img_1.png",
      designation: "UI / UX Designer",
      employmentType: "Full Time",
      education: "Graduated, Certification in UI / UXdesign",
      experience: "Experienced Min. 3 Yr.",
      skills: "Adobe XD, Figma",
      additionalSkills: "Photoshop, Illustrator, Corel Draw",
      salary: "3.4 Lac Per Annum",
      reviews: 5,
      postedDate: "5/10/2023"
    },

    {
      id: 2,
      companyName: "Profcyma Solutions Pvt. Ltd.",
      image: "/assets/Job-portal/Images/job/IT/Job_IT_img_1.png",
      designation: "UI / UX Designer",
      employmentType: "Full Time",
      education: "Graduated, Certification in UI / UXdesign",
      experience: "Experienced Min. 3 Yr.",
      skills: "Adobe XD, Figma",
      additionalSkills: "Photoshop, Illustrator, Corel Draw",
      salary: "3.4 Lac Per Annum",
      reviews: 5,
      postedDate: "5/10/2023"
    },




  ];
  return (
    <div className="save-job-card">
      <div className="row">
        {getSaveUserJob?.map((job) => (
          <div className="col-lg-4 col-md-6">
            <div className="save-job-card-holder">
              <div className="img-header">
                <div className="heading-img-holder">
                  <img
                    className="company-img"
                    src={IMG_URL + job?.job_portal_job?.company_logo}
                    alt={job?.job_portal_job?.company?.name}
                  />
                </div>
                <div className="heading-holder">
                  <h1>{job?.job_portal_job?.company?.name}</h1>
                </div>
              </div>

              <div className="designation_info">
                <div className="heading-holder">
                  <h6>{job?.job_portal_job?.job_title?.name}</h6>
                </div>
                <div className="designation-info-content">
                  <div className="info-holder">
                    <div className="icon-holder">
                      <FontAwesomeIcon className="info-icon" icon={["fas", "clock"]} />
                    </div>
                    <div className="text-holder">
                      <span>
                        {job?.job_portal_job?.job_type_details?.map((sahitya) => (
                          <span>{sahitya?.job_type?.name}, </span>
                        ))}
                      </span>
                    </div>
                  </div>

                  <div className="info-holder">
                    <div className="icon-holder">
                      <FontAwesomeIcon className="info-icon" icon={["fas", "graduation-cap"]} />
                    </div>
                    <div className="text-holder">
                      <span>{job?.job_portal_job?.qualification?.name}</span>
                    </div>
                  </div>

                  <div className="info-holder">
                    <div className="icon-holder">
                      <FontAwesomeIcon className="info-icon" icon={["fas", "briefcase"]} />
                    </div>
                    <div className="text-holder">
                      <span>Experienced Min. {job?.job_portal_job?.experience_min} Yr.</span>
                    </div>
                  </div>

                  <div className="info-holder">
                    <div className="icon-holder">
                      <FontAwesomeIcon className="info-icon" icon={["fas", "desktop"]} />
                    </div>
                    <div className="text-holder">
                      <span>
                        {job?.job_portal_job?.skills_details?.map((sahitya) => (
                          <span>{sahitya?.skill?.name}, </span>
                        ))}
                      </span>
                    </div>
                  </div>

                  {/* <div className="info-holder">
                    <div className="icon-holder">
                      <FontAwesomeIcon className="info-icon" icon={["fas", "desktop"]} />
                    </div>
                    <div className="text-holder">
                      <span>{job.additionalSkills}</span>
                    </div>
                  </div> */}
                </div>
              </div>

              <div className="per-annum">
                <div className="per-annum-content">
                  <div className="per-annum-info-holder">
                    <div className="per-annum-icon-holder">
                      <FontAwesomeIcon className="info-icon" icon={["fas", "sack-dollar"]} />
                    </div>
                    <div className="text-holder">
                      <h6>{job?.job_portal_job?.salary_max} Lac Per Annum</h6>
                    </div>
                  </div>

                  <div className="review-star">
                    <h6>Reviews:</h6>
                    {[...Array(4)].map((_, index) => (
                      <FontAwesomeIcon key={index} className="star-icon" icon={["fas", "star"]} />
                    ))}
                    <div className="job-posted-date">
                      <p>Job Posted on: {job?.createdAt && (
                        <>
                          {new Date(job?.createdAt).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric'
                          })}
                        </>
                      )}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="btn-holder">
                {/* <Link to={"/job-portal/search/job-description"}>
                  <Button className="apply-btn">Apply</Button>
                </Link> */}

                {signin ? (
                  <Button className="apply-btn" onClick={() => navigate(`/job-portal/search/job-description/${job?.job_id}`)}>Apply</Button>
                ) : (
                  <Button className="apply-btn" onClick={() => navigate("/job-portal/sign-in")}>Apply</Button>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Jp_saved_jobs_components;
