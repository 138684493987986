import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "./SubmitSteps.css";

const SubmitSteps = ({ loan_form_id }) => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/finance/loan/f-loan-steps/${loan_form_id}`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data, "dsdsdsdsd");

  useEffect(() => {
    getDataAll();
  }, [loan_form_id]);

  const StepCardsDetails = [
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Finance/Image/HomeLoan/Steps/Step1.png",
      content: "Pledge your Fixed Deposit as security.",
      no: "1.",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Finance/Image/HomeLoan/Steps/step2.png",
      content: "Secure a loan, up to 90%-95% of the FD amount",
      no: "2.",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Finance/Image/HomeLoan/Steps/step3.png",
      content:
        "Enjoy a secured loan against your fixed deposit.",
      no: "3.",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Finance/Image/HomeLoan/Steps/Step1.png",
      content: "Pledge your Fixed Deposit as security.",
      no: "1.",
    },
  ];

  return (
    <>
      {data && data?.length > 0 &&
        <section className="Submit-Step">
          <Container>
            <Row>
              <Col xxl={3} xl={3} lg={3}>
                <div className="Step-content" >
                  <h1 className="fianace-titleeee second-title-finan">Submit your <br></br> application</h1>
                  <p className="text">in just three simple steps</p>

                </div>
                <div className="border-text"></div>
              </Col>
              <Col xxl={9} xl={9} lg={9}>
                <div className="container">
                  <Swiper
                    spaceBetween={30}
                    pagination={{ clickable: true }}
                    // initialSlide={1}
                    // centeredSlides={true}
                    breakpoints={{
                      0: { slidesPerView: 1 },
                      768: { slidesPerView: 2 },
                      1024: { slidesPerView: 3 },
                      1200: { slidesPerView: 3 },
                    }}
                    className="mySwiper"
                  >
                    {data?.map((item, index) => (
                      <SwiperSlide key={index}>
                        <div className="Steps-Card">
                          <div className="Card-Inner">
                            <img src={IMG_URL + item?.image} className="img-one" />
                          </div>
                          <div className="overlay"></div>
                          <div className="steps-content-card">
                            <div>
                              <p className="No">{item?.step}</p>
                              <p className="content-text">{item?.name}</p>
                            </div>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      }
    </>
  );
};

export default SubmitSteps;
