import React, { useState, useContext, useEffect } from "react";
import DatePicker from "react-datepicker";
import Form from "react-bootstrap/Form";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./InterviewSchedule.css";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames"
import Select from "react-select";
import ResetModal from "../../../../JP-Common-Elements/Jp-modals/ResetModal/ResetModal";
import { Context } from "../../../../../../utils/context";
import { allCity, allInterviewType } from "../../../../../../utils/apis/common/Common";
import { useNavigate } from "react-router-dom";

// import { TimePicker } from "antd";
function InterviewSchedule({ onCancel, applicantData }) {
  const [startDate, setStartDate] = useState(new Date());
  const [videoLink, setVideoLink] = useState("");
  const [buttonText, setButtonText] = useState("Save");
  const [showBeforeSchedule, setShowBeforeSchedule] = useState(true);
  const [showAfterSchedule, setShowAfterSchedule] = useState(false);
  const [modalEmail, setModalEmail] = useState(false);
  const options1 = [
    { value: "pune", label: "pune" },
    { value: "Mumbai", label: "Mumbai" },
    { value: "hydrabad", label: "hydrabad" },
  ];
  const openEmail = (redirectUrl) => {
    setModalEmail(true);
    setTimeout(() => {
      window.location = redirectUrl;
      setModalEmail(false);
    }, 3000)
  }

  const navigate = useNavigate();
  const { postData, getData, Select2Data, IMG_URL } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();


  const [jobCityData, setCityData] = useState();
  const getCityData = async () => {
    const res = await allCity();
    if (res?.success) {
      setCityData(await Select2Data(res?.data, "city_id"));
    }
  };

  const [jobInterviewTypeData, setInterviewTypeData] = useState();
  const getInterviewTypeData = async () => {
    const res = await allInterviewType();
    if (res?.success) {
      setInterviewTypeData(res?.data);
    }
  };

  console.log("applicantData", applicantData);

  const onSubmit = async (data) => {
    data.jobdetail_id = applicantData?.jobdetail_id;
    data.jobseeker_id = applicantData?.jobseeker_id;
    data.city_id = data?.city_id?.value;
    data.email = applicantData?.job_portal_user?.email;

    const res = await postData("/job-portal/employer/dashboard/interview-schedule/job", data);
    if (res?.success) {
      setModalEmail(true);
      setTimeout(() => {
        // window.location = "/job-portal/employer/dashboard";
        setModalEmail(false);
      }, 3000)
      // navigate("/job-portal/employer/dashboard")
    }
  }


  const jobssekarID = applicantData?.jobseeker_id;
  const getInterviewDetails = async (jobssekarID) => {
    const res = await getData(`/job-portal/employer/dashboard/interview-schedule/job/${jobssekarID}`);
    if (res?.success) {
      console.log(res?.data?.data, "dfdsugfdisfigbfsdigb");
      reset(res?.data?.data)
    }
  }
  const handleSave = () => {
    // setVideoLink("https://meet.google.com/");
    // setValue()
    // setButtonText(
    //   <span className="noti-btn" onClick={() => openEmail("/job-portal/employer/dashboard")}>
    //     <FontAwesomeIcon icon="fa-solid fa-paper-plane" /> Send Notification to Candidate
    //   </span>
    // );
    setShowBeforeSchedule(false);
    setShowAfterSchedule(true);
  };

  const handleEdit = () => {
    setShowBeforeSchedule(true);
    setShowAfterSchedule(false);
  };

  useEffect(() => {
    getCityData();
    getInterviewTypeData();
    getInterviewDetails(jobssekarID);
  }, [])
  return (
    <>
      <section className="Emp-interview-schedule">
        <div className="container">
          <div className="main-card">
            <Form>
              <div className="row">
                <div className="col-xxl-4 col-xl-4 col-lg-5 col-12 ">
                  <div className="left-card">
                    {showBeforeSchedule && (
                      <div className="before_schedule">
                        <div className="row mb-3">
                          <div className=" col-xxl-1 col-xl-1 col-lg-1  col-md-1 col-2">
                            <FontAwesomeIcon
                              icon="fa-solid fa-calendar-days"
                              className="me-3"
                            />
                          </div>
                          <div className=" col-xxl-11 col-xl-11 col-lg-11 col-md-11 col-10">
                            {/* <DatePicker
                              selected={startDate}
                              onChange={(date) => setStartDate(date)}
                            /> */}
                            <Form.Control
                              type="date"
                              placeholder="Enter Date Of Interview"
                              name={"date"}
                              {...register("date", {
                                required: "Date is Required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.date,
                              })}
                              min={new Date().toISOString().split("T")[0]}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xxl-1 col-xl-1 col-lg-1  col-md-1 col-2">
                            <FontAwesomeIcon
                              icon="fa-solid fa-clock"
                              className="me-3 "
                            />
                          </div>
                          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-4">
                            <Form.Group
                              className="mb-3 "
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Control
                                type="time"
                                placeholder="Interview Start Time"
                                name={"s_time"}
                                {...register("s_time", {
                                  required: "Start Date is Required",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.s_time,
                                  // "is-valid": getValues("company_name"),
                                })}
                              />
                            </Form.Group>
                          </div>
                          <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-1"></div>
                          <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-4">
                            <Form.Group
                              className="mb-3"
                              controlId="exampleForm.ControlInput1"
                            >
                              <Form.Control
                                type="time"
                                placeholder="Interview End Time"
                                name={"e_time"}
                                {...register("e_time", {
                                  required: "End Time is Required",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.e_time,
                                  // "is-valid": getValues("company_name"),
                                })}
                              />
                            </Form.Group>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xxl-1 col-xl-1 col-lg-1  col-md-1 col-2">
                            <FontAwesomeIcon
                              icon="fa-solid fa-location-dot"
                              className="me-3"
                            />
                          </div>
                          <div className="col-xxl-11 col-xl-11 col-lg-11 col-md-11 col-10">
                            <Form.Group
                              className="mb-3 "
                              controlId="exampleForm.ControlInput1"
                            >
                              {/* <Form.Control
                                type="text"
                                placeholder="Enter-location"
                              /> */}
                              {/* <Controller
                                name="city_id"
                                {...register("city_id", {
                                  required: "Current Location is required",
                                })}
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    styles={{
                                      control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: errors.city_id
                                          ? "red"
                                          : baseStyles,
                                      }),
                                    }}
                                    {...field}
                                    options={jobCityData}
                                  />
                                )}
                              /> */}
                              <Controller
                                name="city_id"
                                {...register("city_id", {
                                  required: "city_id required",
                                })}
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    styles={{
                                      control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: errors.city_id
                                          ? "red"
                                          : baseStyles,
                                      }),
                                    }}
                                    {...field}
                                    options={jobCityData}
                                  />
                                )}
                              />
                            </Form.Group>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xxl-1 col-xl-1 col-lg-1  col-md-1 col-2">
                            <FontAwesomeIcon
                              icon="fa-solid fa-user"
                              className="me-3"
                            />
                          </div>
                          <div className="col-xxl-11 col-xl-11 col-lg-11 col-md-11 col-10">
                            <div className=" search-select-to">
                              {/* <Select
                                placeholder="Job Title"
                                options={options1}
                              /> */}
                              <Form.Control
                                type="text"
                                placeholder="Round Name "
                                name="round_name"
                                {...register("round_name", {
                                  required: "Round Name is Required",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.round_name,
                                  // "is-valid": getValues("company_name"),
                                })}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {showAfterSchedule && (
                      <div className="after_schedule">
                        <div className="edit_schedule" onClick={handleEdit}>
                          <FontAwesomeIcon icon="fa-solid fa-pen" /> Edit
                        </div>
                        <div className="right-card p-0">
                          <div className="intervie-divv mb-3">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="">
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-calendar-days"
                                    className=" me-3"
                                  />

                                  <span>{getValues("date")}</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="intervie-divv mb-3">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="">
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-clock"
                                    className="me-3 "
                                  />
                                  <span>{getValues("s_time")} - {getValues("e_time")}</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="intervie-divv mb-3">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="">
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-location-dot"
                                    className=" me-3"
                                  />

                                  <span>{getValues("city_id")?.label}</span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="intervie-divv mb-3">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="">
                                  <FontAwesomeIcon
                                    icon="fa-solid fa-user-gear"
                                    className=" me-3"
                                  />

                                  <span>{getValues("round_name")}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xxl-8 col-xl-8 col-lg-7 col-12 ">
                  <div className="right-card">
                    <h4>Position: {applicantData?.job_portal_user?.job_seeker_personal_detail?.job_profile_title}</h4>
                    <div className="intervie-divv">
                      <div className="me-3">
                        <FontAwesomeIcon
                          icon="fa-solid fa-message"
                          className=" me-3"
                        />
                        <span>Interview Type</span>
                      </div>
                      <div>
                        {jobInterviewTypeData?.map((data, index) => (
                          <button className="radio-btns me-3" type="button">
                            <Form.Check
                              inline
                              type="radio"
                              name="i_type"
                              aria-label="radio 1"
                              value={data?.id}
                              label={data?.name}
                              {...register("i_type", {
                                required: "Interview Type is Required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.i_type,
                                // "is-valid": getValues("company_name"),
                              })}

                            />
                          </button>
                        ))}
                        {/* <button className="radio-btns me-3">
                          <Form.Check
                            inline
                            type="radio"
                            name="name"
                            aria-label="radio 2"
                            label="Telephonic"
                          />
                        </button>
                        <button className="radio-btns">
                          <Form.Check
                            inline
                            type="radio"
                            name="name"
                            aria-label="radio 3"
                            label="Video"
                          />
                        </button> */}
                      </div>
                    </div>

                    <Form.Group
                      className="my-3 "
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Paste a video link for an interview"
                        name="test_schedule"
                        {...register("test_schedule", {
                          required: "Round Name is Required",
                        })}
                        className={classNames("", {
                          "is-invalid": errors?.test_schedule,
                          // "is-valid": getValues("company_name"),
                        })}
                      />
                    </Form.Group>
                    <div className="buttons-div text-center">
                      <button className="can-save-btn" type="button" onClick={onCancel}>
                        Cancel
                      </button>
                      {showBeforeSchedule ? (
                        <button type="button" className="can-save-btn" onClick={handleSave}>Save</button>
                      ) : (
                        <button type="button" className="can-save-btn" onClick={handleSubmit(onSubmit)}>Send Notification to Candidate</button>
                      )}
                      {/* // <button type="button"
                      //   className={`can-save-btn ${showBeforeSchedule
                      //     ? ""
                      //     : buttonText === "Send Notification to Candidate"
                      //       ? "send-notification-btn"
                      //       : ""
                      //     }`}
                      //   onClick={handleSave}
                      // >
                      //   {showBeforeSchedule ? "Save" : buttonText}
                      // </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </div >
        <ResetModal show={modalEmail}
          onHide={() => setModalEmail(false)}
          text={"Interview Has been Schedule Successfully!"}
        />
      </section >
    </>
  );
}

export default InterviewSchedule;
