import React,{useEffect,useContext,useState} from 'react'
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import "./JpDashboardbanner.css"
import { Context } from '../../../../utils/context';

function JpDashboardbanner() {

    const { getData, postData, IMG_URL } = useContext(Context);


    const [profileData, setProfileData] = useState();

    const getProfileData = async () => {
        const res = await getData("/job-portal/employer/company-details/company-edit/profile-image-company/data");
        if (res?.success) {
            setProfileData(res?.data);
        } else {
            console.error("Error fetching  data:", res?.error);
        }
    };

    useEffect(() => {
        getProfileData();
    }, []);

    return (
        <>
            <section className='Jp-dashboard-banner'>
                <div className='container'>
                    <div className='box'>
                        <div className='row'>
                            <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 text-center justify-content-center d-flex'>
                                <div className='img-div'>
                                    <img className='jb-dash-logo' src={IMG_URL + profileData?.image} />
                                </div>
                            </div>
                            <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-5'>
                                <div className='info-div'>
                                    <h2>{profileData?.company_name}</h2>
                                    <p>{profileData?.industry?.name}</p>
                                </div>
                            </div>
                            <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-3  col-sm-3 col-4'>
                                <div className='btn-div'>
                                    <Link to="/job-portal/employer/companyprofile">
                                        <Button className='edit-btn' >Edit Profile</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default JpDashboardbanner