import React, { useRef, useState } from "react";
import "./Banner.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectFade, Autoplay } from "swiper/modules";

import "swiper/css";
import "swiper/css/effect-fade";
import Landing_Page_Sliders from "./Landing_Page_Sliders/Landing_Page_Sliders";
import Landing_Page_Sliders_Two from "./Landing_Page_Sliders_Two/Landing_Page_Sliders_Two";
import Landing_Page_Sliders_Three from "./Landing_Page_Sliders_Three/Landing_Page_Sliders_Three";


function Banner() {


  const sliders = [
    <Landing_Page_Sliders
      key={1}
      heading={
        <>
          Elevate With <br /> Online Education !
        </>
      }
      subText={
        <>
          Unlocking Knowledge Gates in <br /> The Digital Realm - Your Path
          <br /> To Success Begin Online
        </>
      }
      image=" /assets/Job-portal/Images/home/bg-banner/banner.png"
      link="/ed-tech/welcome"
    />,

    <Landing_Page_Sliders_Two
      key={2}
      heading={
        <>
          Elevate Your Career <br /> With Best Job !
        </>
      }
      subText={
        <>
          We Make Things Happen, <br /> For You , For Everyone
        </>
      }
      image=" /assets/Job-portal/Images/home/bg-banner/banner.png"
      link="/job-portal/welcome"
    />,

    <Landing_Page_Sliders
      key={3}
      heading={
        <>
          {" "}
          Get a Solution To <br /> All Your Financial Need
        </>
      }
      subText={
        <>
          {" "}
          When All The Doors Are Closed, <br /> You Will Find Our's Open !
        </>
      }
      image=" /assets/Job-portal/Images/home/bg-banner/banner.png"
      link="/finance/welcome"
    />,


    // <Landing_Page_Sliders_Three key={4} />,
  ];




  return (
    // <section className="welcome-banner-section">
    //   <Link to='/ed-tech/welcome'>Ed Tech</Link><br></br>
    //   <Link to='/finance/welcome'>Finance</Link><br></br>
    //   <Link to='/job-portal/welcome'>Job Portal</Link><br></br>
    //   <Link to='/college-compare/welcome'>College Compare</Link><br></br>
    //   <Link to='/free-counselling/welcome'>Free Counselling</Link><br></br>
    // </section>



    <div className="LandingPage">
      <div className="container-fluid p-0">
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          modules={[EffectFade, Autoplay]}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
            pauseOnMouseEnter: false,
            loop: true,
          }}
          effect="fade"
          fadeEffect={{
            crossFade: true,
            crossFadeTransitionDuration: 3000, 
            duration: 3000,
          }}
          className="mySwiper "
        >
          {sliders.map((slider, index) => (

            <SwiperSlide key={index}>{slider}</SwiperSlide>

          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default Banner;
