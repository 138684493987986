import { useState, useEffect, useContext } from "react";
import "./JobSeekerStepformcss/JobSeekerStepform.css";
import "./JpJobSeekerEditProfileSTepForm.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faLightbulb,
  faGraduationCap,
  faBriefcase,
  faGear,
} from "@fortawesome/free-solid-svg-icons"; // Import the icons you want to use
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Stepone from "./Stepone/Stepone";
import Steptwo from "./Steptwo/Steptwo";
import Stepthree from "./Stepthree/Stepthree";
import Stepfour from "./Stepfour/Stepfour";
import Stepfive from "./Stepfive/Stepfive";
import { Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Context } from "../../../../../utils/context";
import Cookies from "js-cookie";

function JpJobSeekerEditProfileSTepForm({ onNext, onPrev }) {
  const [startDate, setStartDate] = useState();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  //For manageing state of multi steps Form
  const [page, setPage] = useState(0);

  const percentage = {
    step_one: 28,
    step_two: 46,
    step_three: 64,
    step_four: 82,
    step_five: 100,
  }

  const nextStep = () => {
    getJobSeekarDetails();
    window.scrollTo(0, 0); // Scroll to the top of the page
    setPage((currPage) => currPage + 1);
    if (page === 5 && onNext) {
      onNext(); // Call the callback function passed from Stepform2
    }
  };

  const prevStep = () => {
    getJobSeekarDetails();
    window.scrollTo(0, 0); // Scroll to the top of the page
    setPage((currPage) => currPage - 1);
    if (page === 0 && onPrev) {
      onPrev(); // Call the callback function passed from Stepform2
    }
  };

  const calculatePercentage = () => {
    return ((page + 0) / 5) * 130; // Assuming there are 5 steps
  };

  const { getData, postData, IMG_URL } = useContext(Context);
  // const [userDetails, setUserDetails] = useState();
  const [jobSeekarDetails, setJobSeekarDetails] = useState();

  const getJobSeekarDetails = async () => {
    const res = await getData(
      `/job-portal/job-seeker/profile/profile-details/${Cookies.get(
        "jobseekar_id"
      )}`
    );
    if (res?.success) {
      setJobSeekarDetails(res?.data);
      Cookies.set("profile_percentage", res?.data?.profile_percentage)
    }
  };

  useEffect(() => {
    getJobSeekarDetails();
    window.scrollTo(0, 0);
  }, []);

  const PageDisplay = () => {
    switch (page) {
      case 0:
        return (
          <Stepone
            nextStep={nextStep}
            jobSeekarDetails={jobSeekarDetails}
            percentage={percentage}
          />
        );
      case 1:
        return (
          <Steptwo
            nextStep={nextStep}
            prevStep={prevStep}
            jobSeekarDetails={jobSeekarDetails}
            percentage={percentage}
          />
        );
      case 2:
        return (
          <Stepthree setPage={setPage} jobSeekarDetails={jobSeekarDetails} percentage={percentage} />
        );
      case 3:
        return (
          <Stepfour
            nextStep={nextStep}
            prevStep={prevStep}
            jobSeekarDetails={jobSeekarDetails}
            percentage={percentage}
          />
        );
      case 4:
        return (
          <Stepfive
            nextStep={nextStep}
            prevStep={prevStep}
            jobSeekarDetails={jobSeekarDetails}
            percentage={percentage}
          />
        );
      default:
        return null;
    }
  };

  const stepNames = [
    "Personal Details",
    "Skill Sets",
    "Educational Details",
    "Employment Details",
    "Career Preferance",
  ];

  return (
    <>
      <div className="ProgressBar">
        <ProgressBar
          percent={calculatePercentage()}
          filledBackground="#EE9723"
          height="3px"
          style={{ margin: "auto" }}
        >
          {[faUser, faLightbulb, faGraduationCap, faBriefcase, faGear].map(
            (icon, index) => (
              <Step key={index}>
                {({ accomplished }) => (
                  <div
                    style={{
                      backgroundColor: accomplished ? "#fff" : "#EFEFEF",
                      color: accomplished ? "#EE9723" : "#d1cccc",
                      border: accomplished
                        ? "1px solid #EE9723"
                        : "1px solid #EFEFEF", // Border for unfilled steps
                    }}
                    className={`step ${accomplished ? "completed" : null} ${index < page ? "previous-step" : ""
                      }`}
                  >
                    <FontAwesomeIcon icon={icon} />
                    <div className="step-name">{stepNames[index]}</div>{" "}
                    {/* Add this line */}
                  </div>
                )}
              </Step>
            )
          )}
        </ProgressBar>
      </div>
      <div className="userForm">
        <div className="personal-details-form step-Form-Conteiner ">
          {/* form-heading-holder */}
          <div className="form-header-holder">
            <div className="row">
              <div className="col-md-4 col-sm-4 col-4">
                {page !== 0 && (
                  <div className="Back-span-holder">
                    <span onClick={() => prevStep()}>Back</span>
                  </div>
                )}
              </div>
              <div className="col-md-4 col-sm-4 col-4">
                <div className="heading-holder">
                  <h6>
                    {page === 0
                      ? "Personal Details"
                      : page === 1
                        ? "Skill Sets"
                        : page === 2
                          ? "Educational Details"
                          : page === 3
                            ? "Employment Details"
                            : page === 4
                              ? "Career Preferance"
                              : "Skill Sets"}
                  </h6>
                </div>
              </div>
              <div className="col-md-4 col-sm-4 col-4">
                <div className="span-holder">
                  {/* <Link to={"/job-portal/jobseeker/myprofile"}>
                    <span>Save & Close</span>
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
          <div className="">{PageDisplay()}</div>
          {/* <div
            className="SaveAndNext apply-btn-holder"
            style={{ textAlign: "center" }}
          >
            {page !== 2 &&
            page !== 4 && ( // Check if it's not step three or step five
                <Button
                  onClick={() => nextStep()}
                  className="apply_now_btn tabs-btn"
                >
                  Save & Next
                </Button>
              )}
            {page === 4 && ( // Check if it's step five
              <Link to={"/job-portal/jobseeker/myprofile"}>
                <Button className="apply_now_btn tabs-btn">Done</Button>
              </Link>
            )}
          </div> */}
        </div>

        {/* for step three (educational details) */}
        {/* Conditionally render education card based on page */}
        {/* {page === 2 && (
          <div className="education-card">
            <div className="row">
              <div className="col-md-3 col-sm-6 col-6">
                <div className="card-holder">
                  <h6>Higher Education</h6>
                  <div className="card-info-holder">
                    <h4>N.Afghdfgbdfgb.</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}

        {/* Employment Details award form */}
        {/* {page === 3 && ( */}
        {/* // <div className="Employment_Details_award_form">
          //   <div className="personal-details-form step-Form-Conteiner "> */}
        {/* form-heading-holder */}
        {/* <div className="form-header-holder">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-12">
                    <div className="heading-holder">
                      <h6>Award</h6>
                    </div>
                  </div>
                </div>
              </div> */}

        {/* <div className="personal-details-form "> */}
        {/* form-holder */}
        {/* <div className="details-form-holder">
                  <div className="form-container"> */}
        {/*Previous Job*/}
        {/* <div className="Percentages-Add-Education">
                      <div className="row justify-content-between">
                        <div className="col-md-3">
                          <Form className="form-field">
                            <Form.Group controlId="">
                              <div className="previous-job-details">
                                <div className="job-content-holder">
                                  <h1>Graphic Designer</h1>
                                  <p>Career Infotech Pvt. Ltd.</p>
                                  <p>2019- 2020</p>
                                </div>
                              </div>
                            </Form.Group>
                          </Form>
                        </div> */}

        {/* <div className="col-md-6">
                          <div className="Add-education-btn-holder form-field">
                            <div className="apply-btn-holder">
                              <Button className="apply_now_btn">
                                Add Award
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}

        {/* Company Name*/}
        {/* <div className=" ">
                      <div className="row">
                        <div className="col-md-12">
                          <Form className="form-field">
                            <Form.Group controlId="">
                              <Form.Label className="">Company Name</Form.Label>

                              <Form.Control
                                type="text"
                                name=""
                                placeholder="Enter Company Name"
                              />
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                    </div> */}

        {/*Name of Award */}
        {/* <div className="">
                      <div className="row">
                        <div className="col-md-12">
                          <Form className="form-field">
                            <Form.Group controlId="">
                              <Form.Label className="">
                                Name of Award
                              </Form.Label>

                              <Form.Control
                                type="text"
                                name=""
                                placeholder="Enter Name of Award"
                              />
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                    </div> */}

        {/*Award Year */}
        {/* <div className="">
                      <div className="row">
                        <div className="col-md-12">
                          <Form className="form-field">
                            <Form.Group controlId="">
                              <Form.Label className="">Award Year</Form.Label>

                              <div>
                                <DatePicker
                                  placeholderText="DD/MM/YYYY"
                                  selected={startDate}
                                  onChange={(date) => setStartDate(date)}
                                />
                              </div>
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                    </div> */}

        {/*Save */}
        {/* <div className="form-field">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="apply-btn-holder">
                            <Button className="apply_now_btn">Save</Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        {/* )} */}
      </div>
    </>
  );
}

export default JpJobSeekerEditProfileSTepForm;
