import React from "react";
import "./PaginationSection.css"
import Pagination from "react-bootstrap/Pagination";

function PaginationSection() {
  return (
    <>
      <div className="navigation">
        <Pagination>
          <Pagination.Prev className="next-prev">{"Previous"}</Pagination.Prev>
          <Pagination.Item active>{1}</Pagination.Item>
          <Pagination.Item>{2}</Pagination.Item>
          <Pagination.Item>{3}</Pagination.Item>
          <Pagination.Item>{4}</Pagination.Item>
          <Pagination.Item>{5}</Pagination.Item>
          <Pagination.Item>{6}</Pagination.Item>
          <Pagination.Item>{7}</Pagination.Item>
          <Pagination.Item>{8}</Pagination.Item>
          <Pagination.Next className="next-prev">{"Next"}</Pagination.Next>
        </Pagination>
      </div>
    </>
  );
}

export default PaginationSection;
