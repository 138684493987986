import React from "react";
import "./UpdateAccountAddvertise.css";
import { Link } from "react-router-dom";

function UpdateAccountAddvertise() {
  return (
    <>
      <div className="addvertise_card">
      <div className="addvertise_card">
        <div className="addvertisement-div">
          <img
            className="add-banner"
            src={
              process.env.PUBLIC_URL +
              "/assets/Job-portal/Images/advertisement/ban1.png"
            }
          />
          <Link to={"/job-portal/jobseeker/myprofile"} >
          <button className="know-more-bt">Create Account</button>
          </Link>
        </div>
      </div>
      </div>
    </>
  );
}

export default UpdateAccountAddvertise;
