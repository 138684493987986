import React, { useState, useEffect } from "react";
import "./App.css";
import "./index.css";
import Loader from "./components/loader/Loader";
import { useLocation, Link, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollTotop/ScrollTotop";
import Comming_soon from "./components/comming-soon/Comming_soon";

import Welcome from "./components/welcomepage/Welcome";
import Ed_tech_welcome from "./components/welcomepage/ed_tech_welcome/Ed_tech_welcome";
import Finance_welcome from "./components/welcomepage/finance_welcome/Finance_welcome";
import Job_portal_welcome from "./components/welcomepage/job_portal_welcome/Job_portal_welcome";
import Free_councelling_welcome from "./components/welcomepage/free_councelling_welcome/Free_councelling_welcome";
import College_compare_welcome from "./components/welcomepage/college_compare_welcome/College_compare_welcome";


// Start ED Tech imports
import Header from "./components/Ed-tech/header/Header";
import Footer from "./components/Ed-tech/footer/Footer";
// End ED Tech imports

import EdtechRoutes from "./components/Ed-tech/EdtechRoutes/EdtechRoutes";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

// Start Job Portal imports
import Jpheader from "./components/Job-Portal/header/Jpheader";
import Jpfooter from "./components/Job-Portal/footer/Jpfooter";
import JPROutes from "./components/Job-Portal/JPRoutes/JPROutes";
import FinanceRoutes from "./components/Finance/FinanceRoutes/FinanceRoutes";

import FHeader from "./components/Finance/F-Header/FHeader";
import FFooter from "./components/Finance/F-Footer/FFooter";
// End Job Portal imports


function App() {
  const [isFetching, setIsFetching] = useState(true);
  const { pathname } = useLocation();
  const isEdTechRoute = pathname.includes("/ed-tech");
  const isJobPortalRoute = pathname.includes("/job-portal");
  const isFinanceRoute = pathname.includes("/finance");

  const [showHeaderFooter, setShowHeaderFooter] = useState(false);


  useEffect(() => {
    setTimeout(function () {
      // setIsFetching(false);
    }, 1000);

    setShowHeaderFooter(true);
  }, []);
  // if (isFetching) {
  //   return (
  //     <Loader />
  //   );
  // }

  return (
    <>
      <TawkMessengerReact
        propertyId="6603be18a0c6737bd12550fa"
        widgetId="1hpv9t413"
      />
      <ScrollToTop />
      {showHeaderFooter && (
        <>
          {isEdTechRoute && pathname !== "/ed-tech/welcome" && <Header />}
          {isJobPortalRoute && pathname !== "/job-portal/welcome" && <Jpheader />}
          {isFinanceRoute && pathname !== "/finance/welcome" && <FHeader />}
        </>
      )}

      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/ed-tech/welcome" element={<Ed_tech_welcome />} />
        <Route path="/job-portal/welcome" element={<Job_portal_welcome />} />
        <Route path="/finance/welcome" element={<Finance_welcome />} />
        <Route path="/free-counselling/welcome" element={<Free_councelling_welcome />} />
        <Route path="/college-compare/welcome" element={<College_compare_welcome />} />
        <Route path="/comming-soon" element={<Comming_soon />} />

      </Routes>

      {isJobPortalRoute && pathname !== "/job-portal/welcome" && (
        <>
          <JPROutes />
          {showHeaderFooter && <Jpfooter />}
        </>
      )}
      {isEdTechRoute && pathname !== "/ed-tech/welcome" && (
        <>
          <EdtechRoutes />
          {showHeaderFooter && <Footer />}
        </>
      )}
      {isFinanceRoute && pathname !== "/finance/welcome" && (
        <>
          <FinanceRoutes />
          {showHeaderFooter && <FFooter />}
        </>
      )}
    </>
  );
}

export default App;