import { useState, useEffect, useContext } from 'react';
import { Form } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import 'font-awesome/css/font-awesome.min.css';
import '../SixthTab.css'
import classNames from "classnames";

const FinanceLoanStep2 = ({ register, errors, setValue, setError, clearErrors }) => {

    const validateAge = (dob) => {
        console.log("dob", dob);
        const selectedDate = new Date(dob);
        const currentDate = new Date();
        const minAgeDate = new Date();
        minAgeDate.setFullYear(currentDate.getFullYear() - 18);

        if (selectedDate > minAgeDate) {
            console.log("dob", "Age should not be less than 18 years");
            setError("dob", { type: "manual", message: "Age should not be less than 18 years" });
            return "Age should not be less than 18 years";
        }
        console.log("dob", "done");
        clearErrors("dob")
        return true;
    };

    const handleDateChange = (event) => {
        const value = event.target.value;
        validateAge(value);
    };

    return (

        <>
            <section className='step2'>
                <Form.Label>Gender</Form.Label>
                <div className='mb-3 d-flex'>

                    {['radio'].map((type) => (
                        <div key={`inline-${type}`} className="mb-0">
                            <Form.Check
                                inline
                                label="Male"
                                name="group1"
                                type={type}
                                id={`inline-${type}-1`}
                                className="me-5"
                                onChange={(e) => setValue("gender", e.target.value)}
                                value="Male"
                            />
                            <Form.Check
                                inline
                                label="Female"
                                name="group1"
                                type={type}
                                id={`inline-${type}-2`}
                                className="me-5"
                                onChange={(e) => setValue("gender", e.target.value)}
                                value="Female"
                            />
                            <Form.Check
                                inline
                                label="Other"
                                name="group1"
                                type={type}
                                id={`inline-${type}-3`}
                                className="me-5"
                                onChange={(e) => setValue("gender", e.target.value)}
                                value="Other"
                            />
                        </div>
                    ))}
                    {errors.gender && (
                        <span className="text-danger">
                            {errors.gender.message}
                        </span>
                    )}
                </div>

                <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control
                        type="date"
                        placeholder="DD-MM-YYYY"
                        name="dob"
                        {...register("dob", {
                            required: "date required",
                        })}
                        className={classNames("", {
                            "is-invalid": errors?.dob,
                        })}
                        onChange={handleDateChange}
                    />
                    {errors.dob && (
                        <span className="text-danger">
                            {errors.dob.message}
                        </span>
                    )}

                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Email address</Form.Label>
                    {/* <Form.Control type="email" placeholder="Valid email address" /> */}
                    <Form.Control
                        className={classNames("", {
                            "is-invalid": errors?.email,
                        })}
                        type="text"
                        {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                                message: "Invalid E-Mail Address",
                            },
                        })}
                        placeholder="Email"
                    />
                    {errors.email && (
                        <span className="text-danger">
                            {errors.email.message}
                        </span>
                    )}
                </Form.Group>

                <div className='row'>
                    <div className='col-xxl-4 col-xl-4 col-md-5 col-6'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Pin Code</Form.Label>
                            <Form.Control
                                type="text"
                                name="pincode"
                                maxLength={6}
                                placeholder="Enter Pincode"
                                {...register("pincode", {
                                    required: "Pincode is required",
                                    pattern: {
                                        value: /^[0-9]{6}$/,
                                        message: "Please enter a valid 6-digit pincode",
                                    },
                                })}
                                className={classNames("", {
                                    "is-invalid": errors?.pincode,
                                })}
                                onKeyDown={(event) => {
                                    if (!/^[0-9]$/.test(event.key) && event.key !== "Backspace" && event.key !== "Tab") {
                                        event.preventDefault();
                                    }
                                    if (event.target.value.length >= 6 && event.key !== "Backspace") {
                                        event.preventDefault();
                                    }
                                }}
                            />

                            {errors.pincode && (
                                <span className="text-danger">
                                    {errors.pincode.message}
                                </span>
                            )}
                            {/* <Form.Control type="Pin Code" placeholder="Valid email address" /> */}
                        </Form.Group>
                    </div><div className='col-xxl-4 col-xl-4 col-md-5 col-6'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>City</Form.Label>
                            <Form.Control
                                className={classNames("", {
                                    "is-invalid": errors?.city,
                                })}
                                type="text"
                                {...register("city", {
                                    required: "city is required",
                                    pattern: {
                                        value: /^[A-Za-z ]*$/,
                                        message: "Title must contain only letters and spaces",
                                    },
                                })}
                                placeholder="city"
                                onChange={(e) => {
                                    const newValue = e.target.value.replace(/\d/g, ''); // Remove any digits
                                    e.target.value = newValue;
                                }}
                            />
                            {errors.city && (
                                <span className="text-danger">
                                    {errors.city.message}
                                </span>
                            )}
                            {/* <Form.Control type="Pin Code" placeholder="Enter your city" /> */}
                        </Form.Group>
                    </div>
                </div>

            </section>
        </>
    )
}

export default FinanceLoanStep2