import React, { useState, useEffect, useContext } from 'react';
import "./Jpheader.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { Button } from "react-bootstrap";
import JpEmpSearch from "../JP-Common-Elements/Jp-modals/JpSearch/JpEmpSearch";
import JpJobseekerSearch from "../JP-Common-Elements/Jp-modals/JpSearch/JpJobseekerSearch";
import LogoutModal from "../JP-Common-Elements/Jp-modals/LogoutModal/LogoutModal";
import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { Context } from '../../../utils/context';
import ResetModal from "../JP-Common-Elements/Jp-modals/ResetModal/ResetModal";
import { getProfilePicture } from '../../../utils/apis/jobseekar/jobseekar';
import Main_junction from '../../main-junction/Main_junction';
const Jpheader = () => {



  const [modalreset, setModalReset] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  // const [usertype, setUsertype] = useState("Employer");
  const [modalShow, setModalShow] = useState(false);
  const [userDetails, setUserDetails] = useState();
  const [modalShowone, setModalShowone] = useState(false);
  const [modalShowtwo, setModalShowtwo] = useState(false);
  const location = useLocation();
  const [activeLink, setActiveLink] = useState('Home');

  const { getData, postData, IMG_URL, signin, usertype, userdata } = useContext(Context);

  const navigate = useNavigate();

  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveLink(path || 'Home');
  }, [location.pathname]);

  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);
    const navbarToggle = document.querySelector('.navbar-toggler');
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };

  const [companyProfileData, setCompanyProfileData] = useState();

  const getCompanyProfileData = async () => {
    const res = await getData("/job-portal/employer/company-details/company-edit/profile-image-company/data");
    if (res?.success) {
      await setCompanyProfileData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [jobSeekarProfileData, setJobSeekarProfiledata] = useState();

  console.log("jobSeekarProfileData :--", jobSeekarProfileData);

  const getJobSeekarProfileData = async () => {
    const res = await getProfilePicture();
    if (res?.success) {
      await setJobSeekarProfiledata(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };


  const [employernotificationData, setEmployerNotificationDataCount] = useState();

  const getEmployerNotificationData = async () => {
    const res = await getData("/job-portal/notification/employer");
    if (res?.success) {
      setEmployerNotificationDataCount(res?.data?.length);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };


  const [jobseekarnotificationData, setjobseekarNotificationDataCount] = useState();

  const getJobSeekarNotificationData = async () => {
    const res = await getData("/job-portal/notification/jobseeker");
    if (res?.success) {
      setjobseekarNotificationDataCount(res?.data?.length);
      console.log(res?.data?.length, "datatatatatat");
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getCompanyProfileData();
    getJobSeekarProfileData();
    getEmployerNotificationData();
    getJobSeekarNotificationData();
  }, []);
  return (
    <>
      <section className="jp-header-section">
        <Main_junction />


        <Navbar expand="lg" className="main-navbar">
          <Container>
            <Navbar.Brand>
              <div className="">
                <Link to="">
                  <img
                    className="heder-logoo"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Job-portal/Images/Logo/profcyma.png"
                    }
                  />
                </Link>
              </div>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
              {signin ? (
                <>
                  <Nav className="ms-auto">
                    {usertype === "Jobseeker" && (
                      <>
                        <NavLink to="/job-portal/home" className="nav-link" onClick={handleNavItemClick} >
                          Home
                        </NavLink>
                        <NavLink to="/job-portal/job" className="nav-link" onClick={handleNavItemClick}>

                          Job
                        </NavLink>
                        <NavLink to="/job-portal/company" className="nav-link" onClick={handleNavItemClick}>

                          Companies
                        </NavLink>
                        <NavLink to="/job-portal/blog" className="nav-link" onClick={handleNavItemClick}>
                          Blog
                        </NavLink>
                        <NavLink to="/job-portal/contact" className="nav-link" onClick={handleNavItemClick}>
                          Contact
                        </NavLink>

                        {/*start after jobseeker login menus  */}
                        <div className="d-flex">
                          <Nav
                            to={""}
                            className="searchico-btn"
                            onClick={() => setModalShowtwo(true)}

                          >
                            <img
                              className="iccoo"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/Job-portal/Images/icons/search.png"
                              }
                            />
                          </Nav>

                          <Link
                            to={"/job-portal/jobseeker/notification"}
                            className="searchico-btn"
                          >
                            <Nav.Link href="/job-portal/jobseeker/notification">
                              <img
                                className="iccoo me-4"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/Job-portal/Images/icons/notification.png"
                                }
                              />
                              <div className="not-badge">
                                <span className="text">{jobseekarnotificationData}</span>
                              </div>
                            </Nav.Link>
                          </Link>

                          <Nav to={""} className="searchico-btn">
                            <Dropdown>
                              <Dropdown.Toggle className="dropbtt" >
                                <img className="emppp" src={IMG_URL + jobSeekarProfileData?.job_seeker_image?.image} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={handleNavItemClick} href="#/action-1" as={Link} to={"/job-portal/jobseeker/myprofile"}>My Profile</Dropdown.Item>
                                <Dropdown.Item onClick={handleNavItemClick} href="/job-portal/jobseeker/dashboard">Dashboard</Dropdown.Item>
                                <Dropdown.Item onClick={handleNavItemClick} href="#/action-2">Saved Jobs</Dropdown.Item>
                                <Dropdown.Item onClick={() => { handleNavItemClick(); navigate("/job-portal/jobseeker/setting"); }}>Settings</Dropdown.Item>
                                <Dropdown.Item onClick={handleNavItemClick} href="/job-portal/helpcenter">Help Center</Dropdown.Item>
                                <Dropdown.Item onClick={handleNavItemClick} href="/job-portal/jobseeker/privacypolicy">Privacy Policy</Dropdown.Item>
                                <Dropdown.Item href="#/action-3" onClick={() => setModalShow(true)}>
                                  Logout
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>


                            <LogoutModal
                              show={modalShow}
                              onHide={() => setModalShow(false)}
                              text={"Are you sure, you want to Log Out?"}
                              label={
                                "Save your Password for next time you Log In"
                              }
                              setModalReset={setModalReset}
                              heading={"Log Out"}
                              imgSource={"/assets/Job-portal/Images/employer/Logout/Object.png"}
                              showCheckbox={true}
                            />

                          </Nav>
                        </div>
                        {/*end after jobseeker login menus  */}
                      </>
                    )}

                    {usertype === 'Employer' && (
                      <>


                        <NavLink to="/job-portal/employer/companyprofileupdate" className="nav-link" onClick={handleNavItemClick}>Company Profile </NavLink>
                        <NavLink to="/job-portal/blog" className="nav-link" onClick={handleNavItemClick}> Blog </NavLink>
                        <NavLink to="/job-portal/employer/contact" className="nav-link" onClick={handleNavItemClick}> Contact </NavLink>

                        {/*start after employer login menus  */}

                        <div className="d-flex">
                          <Nav to={""} className="searchico-btn" onClick={() => setModalShowone(true)}>
                            <img className="iccoo" src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/icons/search.png'} />
                          </Nav>

                          <Link
                            to={"/job-portal/employer/notification"}
                            className="searchico-btn">
                            <Nav.Link href="/job-portal/employer/notification">
                              <img className="iccoo me-4" src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/icons/notification.png'} />
                              <div className="not-badge"><span className="text">{employernotificationData}</span></div>
                            </Nav.Link>
                          </Link>

                          <Nav to={""} className="searchico-btn">
                            <Dropdown>
                              <Dropdown.Toggle className="dropbtt">
                                <img className="emppp" src={IMG_URL + companyProfileData?.image} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={handleNavItemClick} href="/job-portal/employer/companyprofileupdate" >Company Profile</Dropdown.Item>
                                <Dropdown.Item onClick={handleNavItemClick} href="/job-portal/employer/my-packages">My Package</Dropdown.Item>
                                <Dropdown.Item onClick={handleNavItemClick} href="/job-portal/employer/all-packages">Packages</Dropdown.Item>
                                <Dropdown.Item onClick={() => { handleNavItemClick(); navigate("/job-portal/employer/setting"); }}>Settings</Dropdown.Item>
                                <Dropdown.Item onClick={handleNavItemClick} href="/job-portal/helpcenter">Help Center</Dropdown.Item>
                                <Dropdown.Item onClick={handleNavItemClick} href="/job-portal/employer/privacypolicy">Privacy Policy</Dropdown.Item>
                                <Dropdown.Item href="#/action-3" onClick={() => setModalShow(true)}>
                                  Logout
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>

                            {modalShow && (
                              <LogoutModal
                                show={modalShow}
                                onHide={() => setModalShow(false)}
                                text={"Are you sure, you want to Log Out?"}
                                label={
                                  "Save your Password for next time you Log In"
                                }
                                setModalReset={setModalReset}
                                heading={"Log Out"}
                                imgSource={"/assets/Job-portal/Images/employer/Logout/Object.png"}
                                showCheckbox={true}
                              />
                            )}


                          </Nav>
                        </div>
                        {/*end after jobseeker login menus  */}
                        <div className="apply-btn-holder">
                          <Link to={"/job-portal/employer/postjob"} onClick={handleNavItemClick}>
                            <Button className="apply_now_btn">Post Job</Button>
                          </Link>
                        </div>
                      </>
                    )}

                    {/* <div className="apply-btn-holder">
                      <Link to={"/job-portal/employer/postjob"} onClick={handleNavItemClick}>
                        <Button className="apply_now_btn">Post Job</Button>
                      </Link>
                    </div> */}
                  </Nav>
                </>
              ) : (
                <>
                  <Nav className="ms-auto">
                    <NavLink to="/job-portal/home" className="nav-link" onClick={handleNavItemClick}>

                      Home
                    </NavLink>
                    <NavLink to="/job-portal/job" className="nav-link" onClick={handleNavItemClick}>

                      Jobs
                    </NavLink>
                    <NavLink to="/job-portal/company" className="nav-link" onClick={handleNavItemClick}>

                      Companies
                    </NavLink>
                    <NavLink to="/job-portal/blog" className="nav-link" onClick={handleNavItemClick}>

                      Blog
                    </NavLink>
                    <NavLink to="/job-portal/contact" className="nav-link" onClick={handleNavItemClick}>

                      Contact
                    </NavLink>
                    <NavLink to="/job-portal/register" className="nav-link register-link" onClick={handleNavItemClick}>
                      Register
                    </NavLink>

                    <div className="apply-btn-holder ">
                      <Link to={"/job-portal/sign-in"} onClick={handleNavItemClick}>
                        <Button className="apply_now_btn">Sign In</Button>
                      </Link>
                    </div>

                    <div className="apply-btn-holder">
                      <Link to={"/job-portal/employer/postjob"} onClick={handleNavItemClick}>
                        <Button className="apply_now_btn">Post Job</Button>
                      </Link>
                    </div>
                  </Nav>
                </>
              )}
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <JpEmpSearch
          show={modalShowone}
          onHide={() => setModalShowone(false)}
        />
        <JpJobseekerSearch
          show={modalShowtwo}
          onHide={() => setModalShowtwo(false)}
        />
        <ResetModal
          show={modalreset}
          onHide={() => setModalReset(false)}
          text={"You have been log out Successfully"}
        />
      </section>
    </>
  );
};

export default Jpheader;
