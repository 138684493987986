import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import './HomeLoanBanner.css'
import { Container } from "react-bootstrap";

const HomeLoanBanner = ({ loan_form_id }) => {
    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/finance/loan/f-loan-banner/${loan_form_id}`);
            await setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data, "dsdsdsdsd");

    useEffect(() => {
        getDataAll();
        console.log("loan_form_id", loan_form_id);
    }, [loan_form_id]);
    console.log(loan_form_id, "loan_form_id loan_form_id");
    return (
        <>
            <section className='F-homeloanbanner'>
                <Swiper
                    spaceBetween={30}
                    pagination={{
                        clickable: true,
                    }}
                    modules={[Pagination]}
                    className="mySwiper"
                >
                    {data?.map((image, index) => (
                        <SwiperSlide>
                            <>
                                {/* <div className='container'>
                                    <div className='row'>
                                        <div className='col-lg-6 col-md-8'>




                                            <div className='hedaing-holder'>
                                                <h1>{image?.name}</h1>
                                                <p className='my-3'>{image?.sub_name}</p>
                                            </div>
                                            <div className='button-holder'>
                                                <button className='btn btn-apply'>Apply Now</button>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className='container-fluid p-0'>
                                    {/* <div className='row'>
                                        <div className='col-lg-6 col-md-8'> */}
                                    <div className="bannermaindiv">
                                        <div className="banimagemain">
                                            <img src={IMG_URL + image.image} className="banimg" />
                                        </div>

                                        <div className="baner_text_div ">
                                            <Container>
                                                {/* <div className="heading_div ">
                                                    <div className='hedaing-holder '>
                                                        <h1>{image?.name}</h1>
                                                        <p className='my-3'>{image?.sub_name}</p>
                                                    </div>
                                                    <div className='button-holder'>
                                                        <button className='btn btn-apply'>Apply Now</button>
                                                    </div>
                                                        
                                                </div> */}
                                            </Container>
                                        </div>
                                        {/* </div>
                                        </div> */}
                                    </div>
                                </div>
                            </>
                        </SwiperSlide>
                    ))}
                </Swiper>
            </section>

        </>
    )
}

export default HomeLoanBanner