import React, { useState, useEffect, useContext } from 'react'
import "./JpForgetPass.css"
import Form from 'react-bootstrap/Form';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ForgetPass from '../ForgetPassAnimation/ForgetPass';
import ResetPass from './ResetPass/ResetPass';
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { Context } from '../../../../utils/context';
import VerifyEmail from "./VerifyEmail/VerifyEmail";
import { getValue } from '@testing-library/user-event/dist/utils';
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const JpForgetPass = () => {
    const [reseted, setReseted] = useState(false)
    const [showVerifyEmail, setShowVerifyEmail] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);

    const [emailerror, setemailErrors] = useState("");
    const [emailID, setEmailID] = useState("");

    const [otp, setOtp] = useState("");
    const [otpErr, setOtpErr] = useState("");

    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm();

    const { getData, postData, IMG_URL } = useContext(Context);

    const handleOtpChange = (otpValue) => {
        setOtp(otpValue);
        // handleVerifyAndLogin();
    };

    const onSubmit = async (data) => {
        if (data) {
            const res = await postData(`/job-portal/customer-management/users-sing-in/forgot-password`, data);
            if (res?.success) {
                // setReseted(true);
                setEmailID(res?.data?.email);
                handleGetOTP(res?.data?.email)
            }
            else {
                setemailErrors(res?.message);
            }
        }
    }

    const handleGetOTP = async (email) => {
        if (email) {
            const res = await postData(`/without-login/verify-otp/email-otp-send`, { email });
            if (res?.success) {
                console.log(res, "otp");
                setOtpErr("");
                setShowVerifyEmail(true);
            } else {
                console.error("Failed to send OTP:", res.message);
            }
        } else {
            console.error("Valid Email is required");
        }
    };

    const handleVerifyOtp = async () => {
        if (!otp) {
            setOtpErr("Please enter OTP");
        } else if (otp.length !== 4) {
            setOtpErr("Please enter complete OTP");
        } else {
            const res = await postData(`/without-login/verify-otp/verify-email-otp`, { email: getValues("email"), otp: otp });
            if (res?.success) {
                setReseted(true);
                setShowVerifyEmail(false);
            } else {
                setOtpErr(res?.message);
            }
        }
    };


    if (reseted) {
        return <ResetPass emailID={emailID} />;
    }
    return (
        <>
            <section className='JpForgetPass form-container'>
                <div className='row'>
                    <div className='col-lg-8 mx-auto'>
                        <ForgetPass />
                        <div className='card forgetPass'>
                            <div className='forgetHead'>
                                <h3 className='heading'>Forgot Your Password?</h3>
                            </div>

                            <div className='row'>
                                <div className='col-lg-6 mx-auto'>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Form.Group className="mb-3" controlId="formBasicEmail">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="Email"
                                                placeholder="Enter Your Email Id"
                                                {...register("email", {
                                                    required: "Email Id required",
                                                    pattern: {
                                                        value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                                                        message: "Email Id Invalid",
                                                    },
                                                })}
                                                className={classNames("", {
                                                    "is-invalid": errors?.email,
                                                })}
                                            />
                                            {emailerror && (
                                                <span className="text text-danger">{emailerror}</span>
                                            )}

                                        </Form.Group>
                                        <div className='text-center'>
                                            <Button type='submit' className="reset_btn" >Reset Password </Button>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="loan-insurance-modal VerifyMobileNumber-modal content-body-holder"
                show={showVerifyEmail}
                onHide={() => setShowVerifyEmail(false)}
            >
                <div className=" content-body">
                    <Modal.Header>
                        <FontAwesomeIcon
                            icon="fa-solid fa-circle-xmark"
                            onClick={() => setShowVerifyEmail(false)}
                        />
                    </Modal.Header>

                    <Modal.Body>
                        <VerifyEmail
                            email={emailID}
                            setShowVerifyEmail={setShowVerifyEmail}
                            onOtpChange={handleOtpChange}
                            otpErr={otpErr}
                            setOtpErr={setOtpErr}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="submit-btn" onClick={() => handleVerifyOtp()}>
                            Verify & Login
                        </Button>

                        {/* <div className="Terms-And-Conditions-Text">
                            <p>
                                By logging in, you agree to the following
                                <span>
                                    Credit Report Terms of Use,Terms of Use and Privacy Policy
                                </span>
                            </p>
                        </div> */}
                    </Modal.Footer>
                </div>
            </Modal >
        </>
    )
}

export default JpForgetPass