import React, { useRef, useState, useEffect, useContext } from 'react'
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import "react-phone-input-2/lib/style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./JpEmpCompanyProfile.css"
import classNames from "classnames";
import { useForm, useFieldArray } from "react-hook-form";
import { Context } from "../../../../../utils/context";
import { Form, Button } from "react-bootstrap";
import Cookies from "js-cookie";
function JpEmpCompanyProfile(companyId) {

    const { postData, getData, Select2Data, IMG_URL, getDimension } = useContext(Context);
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset, watch,
        trigger,
        setValue,

    } = useForm();

    const { append, fields, remove } = useFieldArray({
        control,
        name: "sociallinks",
    });

    const [data, setData] = useState([]);
    const getDataAll = async () => {
        const response = await getData(
            `/job-portal/employer/company-details/social-network/${decodedToken.user}`
        );
        if (response?.success) {
            setData(response?.data);
            const sociallinks = response.data.map(link => ({
                name: link.name,
                url: link.url,
                image: link.image,
            }));
            reset({ sociallinks });
        }
    };


    useEffect(() => {
        getDataAll();
    }, []);


    console.log(Cookies.get('llp_web_security'), 'ppppppppppppp');

    const token = Cookies.get("llp_web_security");
    let decodedToken;
    if (token) {
        decodedToken = JSON.parse(atob(token.split(".")[1]));
    } else {
        console.error('Token is undefined');
        // Handle the case where token is not available
    }


    const onSubmit = async (data) => {
        console.log("thi data ", data)
        const fromData = new FormData();

        const sociallinks = [];

        data.sociallinks.forEach((link, index) => {
            sociallinks.push({
                name: link?.name,
                url: link?.url,
                // image: link?.image[0],
            });
        });
        fromData.append("sociallinks", JSON.stringify(sociallinks));

        try {
            const response = await postData(`/job-portal/employer/company-details/social-network`, fromData);
            if (response?.success) {
                console.log(response);

                await getDataAll();
                reset();
            } else {

                console.error('Submission was not successful:', response);
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        }

    };


    return (
        <><Form onSubmit={handleSubmit(onSubmit)}>
            <div className='btn-div'>
                <button className='add-btn' type='button' onClick={handleSubmit(onSubmit)}>Save</button>
            </div>
            <div className='row '>
                {data?.map((val, index) =>
                    <div className='col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-12 '>
                        <div className='person-detail-card d-flex'>
                            {/* <div className='person-img-div me-4'>
                              
                                <img class="person-img" src={IMG_URL + val?.image} />
                            </div> */}

                            <div className='mt-3'>
                                <h5 className='award-name'>{val?.name} </h5>
                                <p className='year-text'>{val?.url}</p>
                            </div>

                            {/* <div className='edit-icon'>
                              
                                <FontAwesomeIcon icon="fa-solid fa-pencil" className='pencil-icon' />
                            </div> */}
                        </div>
                    </div>
                )}
            </div>

            {fields.map((field, index) => (
                <div key={field.id}>
                    <div className='row mt-4'>
                        <div className='col-xxl-4 xol-xl-4 col-lg-4 col-md-4 col-12'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Enter URL Name</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1">  </InputGroup.Text>
                                    <Form.Control
                                        placeholder="eg.Facebook"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"

                                        {...register(`sociallinks.${index}.name`, {
                                            required: "Award name required",
                                        })}
                                        className={classNames("", {
                                            "is-invalid": errors?.sociallinks?.[index]?.name,
                                        })}
                                        onKeyDown={(event) => {
                                            if (!/^[a-zA-Z\s]*$/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                    />
                                    {errors.sociallinks?.[index]?.name && <p className="text-danger">{errors.sociallinks[index].name.message}</p>}
                                </InputGroup>
                            </Form.Group>
                        </div>
                        <div className='col-xxl-4 xol-xl-4 col-lg-4 col-md-4 col-12'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Enter URL </Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="basic-addon1"> </InputGroup.Text>
                                    <Form.Control
                                        placeholder="https://"
                                        aria-label="Username"
                                        aria-describedby="basic-addon1"
                                        {...register(`sociallinks.${index}.url`, {
                                            required: "URL required",
                                        })}
                                        className={classNames("", {
                                            "is-invalid": errors?.sociallinks?.[index]?.url,
                                        })}
                                    />
                                </InputGroup>
                            </Form.Group>
                        </div>

                        {/* <div className='col-xxl-4 xol-xl-4 col-lg-4 col-md-4 col-12'>
                            <Form.Label column sm={3} className="text-center">
                                Image
                            </Form.Label>
                            <Col sm={9}>
                                <Form.Group>

                                    <Form.Control
                                        className={classNames("", {
                                            "is-invalid": errors?.sociallinks?.[index]?.image,
                                        })}
                                        type="file"
                                        {...register(`sociallinks.${index}.image`, {
                                            // required: "Image is required",
                                            validate: async (value) => {
                                                if (value && value[0]) {
                                                    const fileTypes = ["jpg", "png", "jpeg"];
                                                    const fileType = value[0]?.name?.split(".").pop().toLowerCase();

                                                    if (!fileTypes.includes(fileType)) {
                                                        return `Please upload a valid file format (${fileTypes.join(", ")}).`;
                                                    }

                                                    const sizes = await getDimension(value[0]);
                                                    if (sizes.width !== 20 || sizes.height !== 20) {
                                                        return "Image width and height must be 20 px each.";
                                                    }

                                                    const fileSize = Math.round(value[0].size / 1024);
                                                    if (fileSize > 500) {
                                                        return "File size must be less than 500KB.";
                                                    }
                                                }
                                            },
                                        })}
                                        accept=".jpg, .jpeg, .png"
                                    />
                                    {errors.sociallinks?.[index]?.image && (
                                        <span className="text-danger">
                                            {errors.sociallinks[index].image.message}
                                        </span>
                                    )}
                                </Form.Group>
                                {errors.image && (
                                    <span className="text-danger">
                                        {errors.image.message}
                                    </span>
                                )}
                            </Col>
                        </div> */}
                        {index !== 0 && (
                            <div className="addremoveadress">
                                <div className="text-end Addnewadresstxt">
                                    <p className="" onClick={() => remove(index)}>
                                        Remove Link{" "}
                                        {/* <FontAwesomeIcon icon="fa-solid fa-minus" /> */}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ))}
            <div className="AddMoreLanguage">
                <span onClick={() => append({ name: "", url: "", image: "" })}>
                    Add More Links
                </span>
            </div>


        </Form>
        </>
    )
}

export default JpEmpCompanyProfile