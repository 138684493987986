import React from 'react'
import { Modal } from 'react-bootstrap'

const CommonModal = ({ show, onHide, children }) => {
    return (
        <Modal show={show} onHide={onHide} size="lg"
            className="Jobseeker_step_modal_section"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            {children}
        </Modal>
    )
}

export default CommonModal