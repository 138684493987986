import { useState, useEffect, useContext } from 'react';
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faUser, faBriefcase } from '@fortawesome/free-solid-svg-icons';
import { Context } from "../../../../utils/context";
import Modal from "react-bootstrap/Modal";
import FinanceLoanStep1 from '../SixthTab/FinanceLoanStep1/FinanceLoanStep1';
import FinanceLoanStep2 from '../SixthTab/FinanceLoanStep2/FinanceLoanStep2';
import FinanceLoanStep3 from '../SixthTab/FinanceLoanStep3/FinanceLoanStep3';
import VerifyMobileNumber from "../../F-Common/VerifyMobileNumber/VerifyMobileNumber";
import '../SixthTab/SixthTab.css';
import CreditCard from "./CreditCard/CreditCard"
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const SixthTab = () => {

  const [current, setCurrent] = useState(1);
  const stepIcons = [faMobile, faUser, faBriefcase];
  const stepLabels = ["Verification", "Personal Info", "Professional Info"]; // Define custom labels for each step
  const navigate = useNavigate();

  const handleNext = () => {
    if (current < 4) {
      setCurrent(current + 1);
    }
  };

  const handlePrevious = () => {
    if (current > 1) {
      setCurrent(current - 1);
    }
  };

  const isStepCompleted = (step) => {
    return step < current;
  };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    reset
  } = useForm();

  const { postData, IMG_URL } = useContext(Context);

  const [mobileVerify, setMobileVerify] = useState(false);
  const [showVerifyMobile, setShowVerifyMobile] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState("");



  const onSubmit = async (data) => {
    if (current === 1 && !mobileVerify) {
      handleGetOTP(data?.contact_no)
    } else if (current === 1 && !mobileVerify && showVerifyMobile) {
      handleVerifyAndLogin(data?.contact_no)
    } else if (current === 2) {
      handleNext()
    } else if (current === 3) {
      handleSubmitNewUser();
    } else if (current === 4) {
      navigate('/finance/home');
    }
  };

  const handleOtpChange = (otpValue) => {
    setOtp(otpValue);
    // handleVerifyAndLogin();
  };


  const handleGetOTP = async (contact_no) => {
    if (contact_no) {
      let sanitizedContactNo = contact_no.replace(/^(\+91)?/, '');
      // Remove any dashes (-)
      sanitizedContactNo = sanitizedContactNo.replace(/-/g, '');
      const res = await postData(`/without-login/verify-otp/otp-send`, { contact_no: sanitizedContactNo });
      if (res?.success) {
        console.log(res, "otp");
        setOtpErr();
        setShowVerifyMobile(true);
      } else {
        // Handle error response here
        console.error("Failed to send OTP:", res.message);
      }
    } else {
      // Handle case when contact_no is not available
      console.error("Contact number is required");
      // setContactError("Contact number is required");
    }
  };

  const handleVerifyAndLogin = async (contact_no) => {

    if (!otp) {
      setOtpErr("please Enter Otp");
    } else if (otp.length !== 4) {
      setOtpErr("please Enter Complete Otp");
    }
    if (contact_no && otp) {
      let sanitizedContactNo = contact_no.replace(/^(\+91)?/, '');
      // Remove any dashes (-)
      sanitizedContactNo = sanitizedContactNo.replace(/-/g, '');
      const res = await postData(`/without-login/verify-otp/verify-otp`, { contact_no: sanitizedContactNo, otp: otp });
      if (res?.success) {
        setShowVerifyMobile(false)
        setMobileVerify(true)

        handleNext()
      } else {
        setOtpErr(res?.message);
      }
    }
  };

  const handleSubmitNewUser = async (data) => {
    console.log("Final Data", getValues());
    try {

      const res = await postData(`/finance/forms/credit-card-form/step1`, getValues());
      if (res?.success) {
        handleNext()

      } else {
        // Check if the error message is specific to "contact_no" already exists
        if (res?.code === 404 && res?.message?.contact_no) {
          setError("contact_no", {
            type: "manual",
            message: res.message.contact_no,
          });
        } else {
          // Handle other errors if needed
          console.error("Error:", res?.message);
        }
      }

    } catch (error) {
      console.error(error);
    }
  }

  console.log("getValues", getValues());

  return (
    <section className="credit-eligibility-step-form">

      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className={`${current === 4 ? "displaycon" : "signup-form "}`}>
          <h4 className='stepform-heading'>Best Credit Card in a few clicks</h4>
          <div className="progress-bar-container">
            <div className="step-row">
              {stepIcons.map((icon, index) => (
                <div key={index + 1} className={`step-container ${index + 1 === current ? "active" : ""}`}>
                  <div className='circle-center'>
                    <div className={`circle ${isStepCompleted(index + 1) ? "completed" : ""}`}>
                      <FontAwesomeIcon icon={icon} />
                    </div>
                  </div>
                  <div>
                    <span className="step-text">{stepLabels[index]}</span>
                  </div>
                  {index < stepIcons.length - 1 && (
                    <div className={`line-right ${isStepCompleted(index + 1) ? "completed" : ""}`}></div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="wizard-form mt-3">
          {current === 1 &&
            <FinanceLoanStep1
              register={register}
              errors={errors}
            />}
          {current === 2 && (
            <FinanceLoanStep2
              register={register}
              errors={errors}
              setError={setError}
              clearErrors={clearErrors}
              setValue={setValue}

            />
          )}
          {current === 3 && (
            <FinanceLoanStep3
              register={register}
              errors={errors}
              setValue={setValue}
            />
          )}
          {current === 4 && (
            <>
              <CreditCard handleNext={handleNext} />
            </>
          )}


        </div>
        <div className="save-btn-div ">
          <button
            className="save-btn"
            type='submit'
          >
            Next
          </button>
        </div>
      </Form>


      {/* VerifyMobileNumber modal */}

      <div className="VerifyMobileNumber-modal">
        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="loan-insurance-modal VerifyMobileNumber-modal content-body-holder"
          show={showVerifyMobile}
          onHide={() => setShowVerifyMobile(false)}
        >
          <div className=" content-body">
            <Modal.Header>
              <FontAwesomeIcon
                icon="fa-solid fa-circle-xmark"
                onClick={() => setShowVerifyMobile(false)}
              />
            </Modal.Header>

            <Modal.Body>
              <VerifyMobileNumber
                contact_no={getValues("contact_no")}
                onOtpChange={handleOtpChange}
                otpError={otpErr}
                setOtpErr={setOtpErr}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button className="submit-btn" onClick={() => handleVerifyAndLogin(getValues("contact_no"))}>
                Verify & Login
              </Button>
              <div className="get-notify">
                <Form className="get-notify-switch">
                  <div className="whatsapp-icon-holder">
                    <img
                      className="whatsapp-icon"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Finance/Image/icons/whatsapp.png"
                      }
                      alt="whatsapp-icon"
                    />
                  </div>
                  <span>Get regular Credit Report updates via Whatsapp</span>
                  <Form.Check // prettier-ignore
                    type="switch"
                  />
                </Form>
              </div>

              <div className="Terms-And-Conditions-Text">
                <p>
                  By logging in, you agree to the following
                  <span>
                    Credit Report Terms of Use,Terms of Use and Privacy Policy
                  </span>
                </p>
              </div>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    </section>

  );

}

export default SixthTab;
