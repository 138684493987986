import React, { useState, useContext, useEffect } from "react";
import "../../Jp-Jobseeker/jobseeker_notification/jobseeker_notification.css";

import { Link } from "react-router-dom";
import JpDashboardbanner from "../JpDashboardbanner/JpDashboardbanner";
import { Context } from "../../../../utils/context";

const Jp_empolyer_notification = () => {


  const { getData, postData, IMG_URL, signin, usertype, userdata } = useContext(Context);

  const [notificationData, setNotificationDataCount] = useState();

  const getNotificationData = async () => {
    const res = await getData("/job-portal/notification/employer");
    if (res?.success) {
      setNotificationDataCount(res?.data);
      console.log(res?.data, "datatatatatat");
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getNotificationData();
  }, []);


  const [timeDifferences, setTimeDifferences] = useState([]);

  useEffect(() => {
    // Calculate time differences when notificationData changes
    const calculateTimeDifferences = () => {
      const currentTime = new Date();

      const differences = notificationData?.map((dataItem) => {
        const updateTime = new Date(dataItem.updatedAt);
        const difference = Math.abs(currentTime.getTime() - updateTime.getTime());
        const minutes = Math.floor(difference / (1000 * 60));
        const hours = Math.floor(minutes / 60);
      
        if (hours >= 1) {
          if (hours === 1) {
            return '1 hour ago';
          } else {
            return `${hours} hours ago`;
          }
        } else {
          if (minutes === 1) {
            return '1 minute ago';
          } else {
            return `${minutes} minutes ago`;
          }
        }
      });

      setTimeDifferences(differences || []); // Handle case where differences is null or undefined
    };

    calculateTimeDifferences();
  }, [notificationData]);

  return (
    <>

      <section className="Jobseeker_notification_section">
        <div className="notfication-title-banner">
          <h6 className="nottban-tti">Notifications</h6>
        </div>

        <div className="noification-main">
          <div className="container">
            <div className="BacktoCompanies">
              <Link to={"/job-portal/employer/dashboard"}>
                <p className="">Back to Dashboard</p>
              </Link>
            </div>
            {notificationData?.map((data, index) => (
              <div key={index} className="interview-div">
                <div className="row">
                  <div className="col-9">
                    <p className="notti-text">
                      In {data?.comapny_name}{" "} Company
                      {/* <Link className="linkktext" to={"/job-portal/employer/notification_inner"}> */}
                      {data?.message} ({data?.count})
                      {/* </Link>{" "} */}
                      {" "} for {data?.job_portal_job?.job_title?.name} Job
                    </p>
                  </div>
                  <div className="col-3 text-end">
                    {timeDifferences && timeDifferences.length > index && (
                      // <p className="notti-text">  Updated {timeDifferences[index]} {timeDifferences[index] === 1 ? 'hour' : 'minutes'} ago</p>
                      <p className="notti-text">  Updated {timeDifferences[index]}</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {/* <div className="interview-div">
              <div className="row">
                <div className="col-9">
                  <p className="notti-text">
                    Surajkumar Rao{" "}
                    <Link className="linkktext" to={"/job-portal/employer/notification_inner"}>
                      accepted Interview schedule
                    </Link>{" "}
                    for Graphic & UI / UX Designer Job
                  </p>
                </div>
                <div className="col-3 text-end">
                  <p className="notti-text">14 Hrs Ago</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Jp_empolyer_notification;
