
import React, { useState, useEffect, useContext } from "react";
import "../FirstCredit/FirstCredit.css";
import "../../F-Enquirymodal/FEnquiryModal.css";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SixthCredit from "../SixthCredit/SixthCredit";
import ReactSpeedometer from "react-d3-speedometer";
import './FifthCredit.css';
import SeventhCredit from "../SeventhCredit/SeventhCredit";
import html2pdf from 'html2pdf.js';
import { Context } from "../../../../../utils/context";
import Cookies from "js-cookie";

const FifthCredit = ({ getValues, name, ...props }) => {
  const [sixthModalShow, setSixthModalShow] = useState(false);
  const [seventhModalShow, setSeventhModalShow] = useState(false);
  const [closeTimerStarted, setCloseTimerStarted] = useState(false);


  const handleCloseFifthModal = () => {
    generatePdf();
    props.onHide();
    setSixthModalShow(true);
    setCloseTimerStarted(true);
  };

  useEffect(() => {
    let timer;
    if (closeTimerStarted) {
      timer = setTimeout(() => {
        setSixthModalShow(false);
        setSeventhModalShow(true);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [closeTimerStarted]);

  const handleCloseSixthModal = () => {
    setSixthModalShow(false);
    setSeventhModalShow(true);
  };

  const handleCloseSeventhModal = () => {
    setSeventhModalShow(false);
    props.onHide();
  };

  const segmentColors = ["#EC2128", "#F79321", "#D8DF27", "#109F4F"];
  const { postData, IMG_URL, getData } = useContext(Context);
  const [scoreFromId, setScoreFromId] = useState(null);
  useEffect(() => {
    setScoreFromId(Cookies.get("score_form_id"));
    console.log("score_form_id", scoreFromId);
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 because months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
  };
  const generatePdf = async () => {
    try {
      const response = await getData(`/finance/forms/free-credit-score-form/${scoreFromId}`);
      console.log(response);
      const dataPDF = response.data;
      console.log(dataPDF);

      // await getDataAllPDF();

      // console.log(dataPDF);
      const content = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Document</title>

    <style>
        .GTMain {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }

        .GT {
            border: 1px solid #000;
            width: 400px;
            height: auto;
            padding: 10px;
            box-sizing: border-box;
        }

        .GT p {
            font-weight: 600;
            font-size: 12px;
            margin: 2px 0;
        }

        .GT .logo_main {
            overflow: hidden;
        }

        .GT .logo_main .first {
            width: 100%;
            float: left;
        }

        .GT .logo_main .first .img_logo {
            width: 172px;
            object-fit: cover;
        }

        .GT .logo_main .second {
            height: 83px;
            float: right;
            margin-top: -14%;
            margin-right: 5%;
        }

        .GT .logo_main .second .img_qr {
            width: 85px;
            height: 85px;
            object-fit: cover;
        }

        .GT .card-image-class {
            margin-top: 1%;
            width: 100%;
            text-align: center;
        }

        .GT .card-image-class .gt-logo {
            width: 165px;
            object-fit: cover;
        }

        .GT .content {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .GT .content .name-info {
            width: 100%;
        }

        .GT .content .name-info h4 {
            text-align: center;
            margin: 10px 0;
        }

        .GT .content .name-info p {
            margin-left: 9px;
        }

        .GTMain .imaegSeecMIn {
            border: 1px solid #000;
            width: 400px;
            height: 265px;
            margin-bottom: 10px;
            text-align: center;
            display: flex;
            justify-content: center;
        }

        .GTMain .imgMainlogo {
            width: 300px;
            height: 200px;
            object-fit: contain;
            margin: auto;
        }
    </style>
</head>

<body>
    <section class="GTMain">
        <div class="GT">
            <div class="logo_main">
                <div class="content">
                    <div class="name-info">
                        <h4>Credit Score Report</h4>
                        <p>Name: ${dataPDF.name}</p>
                        <p>Contact No: ${dataPDF.contact_no}</p>
                        <p>Email: ${dataPDF.email}</p>
                        <p>Gender: ${dataPDF.gender}</p>
                        <p>Pan: ${dataPDF.pan}</p>
                        <p>Pincode: ${dataPDF.pincode}</p>
                        <p>Date Of Birth: ${formatDate(dataPDF.dob) || 'N/A'}</p>
                         <p>Eligibility: ${(dataPDF.eligiblity) || 'N/A'}</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</body>

</html>
`;

      const options = {
        margin: 10,
        filename: `${dataPDF.name} Card.pdf`,
        image: { type: 'jpeg', quality: 0.98 },
        output: 'save',
        // pagebreak: { mode: 'avoid-all' }, // This can help avoid unnecessary page breaks
        // jsPDF: { format: 'a4' },

        //id card format
        jsPDF: {
          format: [126, 92],
          orientation: 'landscape'
        },
      };

      html2pdf().from(content).set(options).save();
    } catch (error) {
      console.error("Error fetching or generating PDF:", error);
    }

  };
  return (
    <section className="first-credit">
      <Modal
        className="fenquiry-modal Speed modal-dialog-scrollable"
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="graph-title">
            Hey {name}, Here's your score for Mar 24
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="Speed">
            <ReactSpeedometer
              maxValue={500}
              value={473}
              needleColor="black"
              startColor="orange"
              segments={4}
              endColor="green"
              segmentColors={segmentColors}
              needleHeightRatio={0.8}
              customSegmentLabels={[
                { text: "Poor", position: "INSIDE", color: "#FFFFFF" },
                { text: "Average", position: "INSIDE", color: "#FFFFFF" },
                { text: "Good", position: "INSIDE", color: "#FFFFFF" },
                { text: "Excellent", position: "INSIDE", color: "#FFFFFF" },
              ]}
            />
          </div>
          <p className="keep">Keep up the effort!</p>
          <p className="text-credit">
            You are not far from a strong credit score. For the best offers, you
            should work on improving your score. Do not miss any payments of your
            credit card bills or EMIs. Also, do not apply for any new loan or
            credit card till you improve your score.
          </p>
          <Button type="button" variant="primary" onClick={handleCloseFifthModal}>
            <FontAwesomeIcon className="download-icon me-2" icon=" fa-solid fa-download" />
            Download Report
          </Button>
        </Modal.Body>
      </Modal>
      <SixthCredit show={sixthModalShow} onHide={handleCloseSixthModal} />
      <SeventhCredit show={seventhModalShow} onHide={handleCloseSeventhModal} />
    </section>
  );
};

export default FifthCredit;


