import React, { useEffect, useState, useContext } from "react";
import "./FmainBanner.css";
import FEnquiryModal from "../F-Enquirymodal/FEnquiryModal";
import { Context } from "../../../../utils/context";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay'; // Import Swiper CSS for autoplay
import { EffectFade, Pagination, Autoplay } from 'swiper/modules'; // Import Autoplay module
import Cookies from "js-cookie";
import { ShimmerThumbnail } from 'react-shimmer-effects';
import { Container } from "react-bootstrap";

const FmainBanner = () => {
  const [modalShow, setModalShow] = useState(false);
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      const inquirySubmitted = Cookies.get('FEnquirySubmitted');
      if (!inquirySubmitted) {
        setModalShow(true);
      }
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  const getDataAll = async () => {
    try {
      const response = await getData(`/finance/home/f-banner`);
      setData(response?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const loadTimer = setTimeout(() => {
      getDataAll();
    }, 2000);

    return () => clearTimeout(loadTimer);
  }, []);

  return (
    <>
      <section className="Finance-main-banner">
        <Container>

        </Container>
        {loading ? (
          <ShimmerThumbnail height={550} rounded />
        ) : (
          <Swiper
            spaceBetween={30}
            effect={'fade'}
            autoplay={{
              delay: 3000, 
              disableOnInteraction: false,
            }}
            // pagination={{
            //   clickable: true,
            // }}
            modules={[Pagination, EffectFade, Autoplay]} 
            className="mySwiper"
          >
            {data?.map((image, index) => (
              <SwiperSlide key={index}>
                <div className="f-ban-div">
                  <img
                    className="fban-img"
                    src={IMG_URL + image?.image}
                    alt="Banner"
                  />
                </div>
                <div className="overlay-contt">
                  <div className="container">
                    <div className="row justify-content-end">
                      <div className="col-xl-5 col-lg-7 col-md-10">
                        {/* <h1 className='bant-title'>{image?.name}</h1> */}
                        {/* <p className="subconttt">{image?.sub_name}</p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </section>

      <FEnquiryModal show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default FmainBanner;
