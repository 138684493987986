import React, { useState, useEffect, useContext } from "react";
import Tab from "react-bootstrap/Tab";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import { Nav } from "react-bootstrap";
import ITCard from "../../../../JP-Common-Elements/JP-Cards/JP_Job_Card/JobITCard/ITCard";
import JobsJobFilter from "../../../../JP-Common-Elements/JP-Job-Filter/Jobs_Job_Filter/JobsJobFilter";
import CreateAccountAddvertise from "../../../../JP-Common-Elements/JP-Addvertise/JP-Create-Account-Addvertise/Create-Account-Addvertise/CreateAccountAddvertise";
import PaginationSection from "../../../../JP-Common-Elements/JP-Pegination/PaginationSection/PaginationSection";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import Jpcompany_banner from "../../../Jp-comp-banner/Jpcompany_banner";
import { Context } from "../../../../../../utils/context";
import JPcompanyjobFilter from "./jpcompanyjobFilter"
import { useLocation } from 'react-router-dom';
import Jp_recent_job_card from '../../../../JP-Common-Elements/JP-Cards/Jp_applied_job_cards/Jp_recent_job_card/Jp_recent_job_card';
const JpPopularCompanyJob = () => {
  // Array of objects representing Nav items
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // const query = queryParams.get('query');
  const experience = queryParams.get('experienceNumber');
  const city = queryParams.get('city');
  const max_experience = queryParams.get('max_experience');
  const min_experience = queryParams.get('min_experience');
  const maxSalary = queryParams.get('maxSalary');
  const minSalary = queryParams.get('minSalary');
  const title = queryParams.get('title');
  const selectedJobType = queryParams.get('selectedJobType');
 
  const category = queryParams.get('category');
  const date = queryParams.get('date');


  const { id } = useParams();
  const { getData, postData, IMG_URL, signin, usertype, userdata, setUsertype } = useContext(Context);
  const [industryData, setIndustry] = useState();


  const getIndustry = async () => {
    try {
      const res = await getData("/without-login/master/all-industry");
      if (res?.success) {
        setIndustry(res?.data)
        console.log(res?.data, "res?.datares?.data");
      } else {
        console.log("Error");
      }
    } catch (error) {

    }
  }
  const navItems = [
    { eventKey: "Edtech", label: "Edtech" },
    { eventKey: "it", label: "I.T." },
    { eventKey: "BankigFinance", label: "Banking & Finance" },
    { eventKey: "HealthCare", label: "Health Care" },
    { eventKey: "Telecommunication", label: "Telecommunication" },
    { eventKey: "Management", label: "Management" },
    { eventKey: "Fintech", label: "Fintech" },
    { eventKey: "Manufacturing", label: "Manufacturing" },
  ];
  const [selectedId, setSelectedId] = useState();
  const handleNavItemClick = (id) => {
    // Do something with the selected id, such as setting it to state
    // alert(id, "icdsbjdbhdbhs")
    setSelectedId(id);
  };

  const handleAllClick = () => {
    getcompany();
  };

  const [recentJobData, setRecentJobdData] = useState();

  const getRecentJob = async (selectedId) => {
    try {
      if (selectedId) {
        const res = await getData(`/job-portal/jobs/industry/${selectedId}`)
        if (res?.success) {
          setRecentJobdData(res?.data)
        } else {
          console.log("Error");
        }
      }
      else {
        const res = await getData(`/job-portal/jobs/search-data/data`)
        if (res?.success) {
          setRecentJobdData(res?.data)
        } else {
          console.log("Error");
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  const getcompany = async (selectedId) => {
    try {
      if (selectedId) {
        console.log(selectedId, "selectedIdselectedIdselectedIdselectedId");
        const res = await getData(`/job-portal/companies/industry-company/${selectedId}`);

        if (res?.success) {
          setRecentJobdData(res?.data);
          console.log(res?.data, "res?.data?.companyres?.data?.companyres?.data?.company");
        } else {
          console.log("Error");
        }
      }
      else {
        console.log("gsksdd");
        const res = await getData(`/job-portal/companies`);

        if (res?.success) {
          setRecentJobdData(res?.data)
        } else {
          console.log("Error");
        }
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    getIndustry();
  }, []);
  return (
    <div>
      <Jpcompany_banner />
      <section className="JPforyou">
        <div className="container">
          <div className="heading-holder">
            <div className="row">
              <div className="col-xl-2 col-md-3">
                <div className="BacktoCompanies">
                  <Link to={"/job-portal/jobseeker/dashboard"}>
                    <p className="">Back to Dashboard</p>
                  </Link>
                </div>
              </div>
              <div className="col-xl-8 col-md-6">
                <div>
                  <h1 className="jpsect_title">Popular Companies</h1>
                  <p className="jpsect_subtitle ">Popular Companies for You</p>
                </div>
              </div>
            </div>
          </div>

          {/*JPforyou tab section start */}
          <div className="JPforyou-tabs">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <div className="row">
                <div className="col-md-12  ">
                  <Nav variant="pills" className="">
                    <Swiper
                      modules={[Navigation, Autoplay]}
                      pagination={false}
                      navigation={false}
                      // loop={true}
                      breakpoints={{
                        320: {
                          slidesPerView: 2,
                        },
                        768: {
                          slidesPerView: 3,
                        },
                        992: {
                          slidesPerView: 5,
                        },
                        1024: {
                          slidesPerView: 5,
                        },
                        1600: {
                          slidesPerView: 5,
                        },
                      }}
                      className="suportres-slider"
                    >
                      <SwiperSlide className="swiper-slide1">
                        <Nav.Item >
                          <Nav.Link eventKey="first">
                            All Jobs
                          </Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>

                      {industryData?.map((navItems, index) => (
                        <SwiperSlide className="swiper-slide1">
                          <Nav.Item key={index}>
                            <Nav.Link eventKey={navItems?.name + navItems?.id} onClick={() => handleNavItemClick(navItems?.id)}>
                              {navItems.name}
                            </Nav.Link>
                          </Nav.Item>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Nav>
                </div>
              </div>

              <div className="row tabs-content">
                <Link to={"/job-portal/company"}>
                  <div className="BacktoCompanies">
                    <p className="">Back to Companies</p>
                  </div>
                </Link>
                <div className=" col-xl-9 col-md-8 col-12">
                  <Tab.Content>
                    {/* <Tab.Pane eventKey="alljobs" >
                      <div className='recentjob-data'>
                        <div className='overflow-data'>
                          <div className='main-card'>
                            <Jp_recent_job_card recentJobData={recentJobData} />
                          </div>
                        </div>
                        <div className='view-job-div'>
                          <Link to="/job-portal/job"><button className='view-more-btn'>View More Jobs</button></Link>
                        </div>
                        {/* <div className='view-job-div'>
                                                        <Link to={"/job-portal/job/1"}><button className='view-more-btn'>View More Jobs</button></Link>
                    //                                 </div> */}
                    {/* //   </div>
                    // </Tab.Pane>  */}
                    <Tab.Pane eventKey="first">
                      <div className="jobs_tab">
                        <div>
                          <ITCard 
                            
                            max_experience={max_experience}
                            min_experience={min_experience}
                            minSalary={minSalary}
                            maxSalary={maxSalary}
                            category={category}
                            date={date}
                            experience={experience}
                            title={title}
                            city={city}
                            selectedJobType={selectedJobType}
                          />
                        </div>

                        <PaginationSection />
                      </div>
                    </Tab.Pane>
                    {industryData?.map((navItems, index) => (
                      <Tab.Pane eventKey={navItems?.name + navItems?.id}>
                        <div className="jobs_tab">
                          <div>
                            <ITCard
                              selectedId={selectedId}
                              selectedJobType={selectedJobType}
                              max_experience={max_experience}
                              min_experience={min_experience}
                              category={category}
                              date={date}
                              experience={experience}
                              title={title}
                              minSalary={minSalary}
                              maxSalary={maxSalary}
                              city={city}
                            />
                            {/* <Opportunities />
                            <ITCard /> */}
                          </div>
                          <PaginationSection />
                        </div>
                      </Tab.Pane>
                    ))}



                    {/* <Tab.Pane eventKey="it">
                      <div className="jobs_tab">
                        <div>
                          <ITCard />
                        </div>

                        <PaginationSection />
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="BankigFinance">
                      <div className="jobs_tab">
                        <div>
                          <ITCard />
                        </div>

                        <PaginationSection />
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="HealthCare">
                      <div className="jobs_tab">
                        <div>
                          <ITCard />
                        </div>

                        <PaginationSection />
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="Telecommunication">
                      <div className="jobs_tab">
                        <div>
                          <ITCard />
                        </div>

                        <PaginationSection />
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="Management">
                      <div className="jobs_tab">
                        <div>
                          <ITCard />
                        </div>

                        <PaginationSection />
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="Fintech">
                      <div className="jobs_tab">
                        <div>
                          <ITCard />
                        </div>

                        <PaginationSection />
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="Manufacturing">
                      <div className="jobs_tab">
                        <div>
                          <ITCard />
                        </div>

                        <PaginationSection />
                      </div>
                    </Tab.Pane> */}
                  </Tab.Content>
                </div>
                <div className="col-xl-3 col-md-4 col-12">
                  <div>
                    <JPcompanyjobFilter />
                    <CreateAccountAddvertise />
                  </div>
                </div>
              </div>
            </Tab.Container>
          </div>
          {/*JPforyou tab section end */}
        </div>
      </section>
    </div>
  );
};

export default JpPopularCompanyJob;
