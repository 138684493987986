import React, { useState, useContext, useEffect } from "react";
import "./jobseeker_notification.css";

import { Link } from "react-router-dom";
import JpDashboardbanner from "../../Jp-Employer/JpDashboardbanner/JpDashboardbanner";
import { Context } from "../../../../utils/context";

const Jobseeker_notification = () => {

  const { getData, postData, IMG_URL, signin, usertype, userdata } = useContext(Context);

  const [notificationData, setNotificationDataCount] = useState();

  const getNotificationData = async () => {
    const res = await getData("/job-portal/notification/employer");
    if (res?.success) {
      setNotificationDataCount(res?.data);
      console.log(res?.data, "datatatatatat");
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getNotificationData();
  }, []);


  const [timeDifferences, setTimeDifferences] = useState([]);

  useEffect(() => {
    // Calculate time differences when notificationData changes
    const calculateTimeDifferences = () => {
      const currentTime = new Date();

      const differences = notificationData?.map((dataItem) => {
        const updateTime = new Date(dataItem.updatedAt);
        const difference = Math.abs(currentTime.getTime() - updateTime.getTime());
        let timeDiff;
        if (difference < 3600000) { // Less than 1 hour
          // Convert difference to minutes
          timeDiff = Math.floor(difference / (1000 * 60));
        } else {
          // Convert difference to hours
          timeDiff = Math.floor(difference / (1000 * 60 * 60));
        }
        return timeDiff;
      });

      setTimeDifferences(differences);
    };

    calculateTimeDifferences();
  }, [notificationData]);
  return (
    <>

      <section className="Jobseeker_notification_section">
        <div className="notfication-title-banner">
          <h6 className="nottban-tti">Notifications</h6>
        </div>

        <div className="noification-main">
          <div className="container">
            <div className="BacktoCompanies">
              <Link to={"/job-portal/jobseeker/dashboard"}>
                <p className="">Back to Dashboard</p>
              </Link>
            </div>
            {notificationData?.map((data, index) => (
              <div className="interview-div">
                <div className="row">
                  <div className="col-9">
                    <p className="notti-text">
                      {data?.message}{" "}
                      {/* <Link className="linkktext" to={"/job-portal/employer/notification_inner"}> */}
                      {/* accepted Interview schedule */}
                      {/* </Link>{" "} */}
                      for {data?.job_portal_job?.job_title?.name}
                    </p>
                  </div>
                  <div className="col-3 text-end">
                    {timeDifferences && timeDifferences.length > index && (
                      <p className="notti-text">  Updated {timeDifferences[index]} {timeDifferences[index] === 1 ? 'hour' : 'minutes'} ago</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
            {/* <div className="interview-div">
              <div className="row">
                <div className="col-9">
                  <p className="notti-text">
                    Surajkumar Rao{" "}
                    <Link className="linkktext" to={"/job-portal/employer/notification_inner"}>
                      accepted Interview schedule
                    </Link>{" "}
                    for Graphic & UI / UX Designer Job
                  </p>
                </div>
                <div className="col-3 text-end">
                  <p className="notti-text">14 Hrs Ago</p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Jobseeker_notification;
