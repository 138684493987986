import React, { useState, useEffect, useContext } from "react";
import "../FirstCredit/FirstCredit.css";
import "../../F-Enquirymodal/FEnquiryModal.css";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SeventhCredit from "../SeventhCredit/SeventhCredit";

const SixthCredit = (props) => {
    const [SeventhModalShow, setSeventhModalShow] = useState(false);
    const handleClose = () => {
        props.onHide();
        setSeventhModalShow(true);
    };
    
   
   
    
    return (
        <section className="first-credit">
            <Modal
                className="fenquiry-modal modal-dialog-scrollable"
                {...props}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter "
                centered
            >
                <Modal.Body>
                    <div className="text-center">
                        <FontAwesomeIcon icon="fa-solid fa-file-pdf" className=" pdf-icon" />
                        <p className="credit-score-text"  >Your Credit Score Report Is Downloaded</p>
                    </div>
                </Modal.Body>
               
            </Modal>
            <SeventhCredit show={SeventhModalShow} onHide={() => setSeventhModalShow(false)} />
        </section>
    );
};

export default SixthCredit;




