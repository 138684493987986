import React, { useState, useEffect, useContext } from "react";
import Form from "react-bootstrap/Form";
import Select from "react-select";
import "../../JP-Common-Elements/Jp-Forms/JPForm.css";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ResetModal from "../../JP-Common-Elements/Jp-modals/ResetModal/ResetModal";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../utils/context";
import { useNavigate } from "react-router-dom";
import { allCity, postContactUs } from "../../../../utils/apis/common/Common";

function JpEmpContactForm() {
  const [selectedOption, setSelectedOption] = useState(Array(7).fill(null));
  const [modalShow, setModalShow] = useState(false);
  const [phone, setPhone] = useState("in");
  const [city, setAllCity] = useState();

  const { getData, IMG_URL, Select2Data, signin } = useContext(Context);

  const optionsArray = [
    [
      { value: "Pune", label: "Pune" },
      { value: "Nagpur", label: "Nagpur" },
      { value: "Mumbai", label: "Mumbai" },
    ],
  ];

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue,
  } = useForm();

  const navigate = useNavigate();

  const openmodal = () => {
    setModalShow(true);

    setTimeout(() => {
      navigate("/job-portal/home");
      setModalShow(false);
    }, 3000);
  };

  const getAllCity = async () => {
    const res = await allCity();
    if (res?.success) {
      setAllCity(await Select2Data(res.data, "city_id"));
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const onSubmit = async (data) => {
    const res = await postContactUs(data);
    if (res?.success) {
      openmodal(true);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getAllCity();
  }, []);

  return (
    <>
      <div className="form-container">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <div className="row">
              <div className="col-md-12">
                <Form className="">
                  <Form.Group controlId="Full_Name">
                    <Form.Label className="required">Full Name</Form.Label>

                    <Form.Control
                      type="text"
                      name="name"
                      placeholder="Enter Name"
                      {...register("name", {
                        required: "Name is required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.name,
                      })}
                      onKeyDown={(event) => {
                        if (!/[A-Z-a-z ]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>

          <div>
            <div className="row">
              <div className="col-md-12">
                <Form className="form-field">
                  <Form.Group controlId="">
                    <Form.Label className="required">Email</Form.Label>

                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="E-mail Address"
                      {...register("email", {
                        required: "Email Id required",
                        pattern: {
                          value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                          message: "Email Id Invalid",
                        },
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.email,
                      })}
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>

          <div>
            <div className="row">
              <div className="col-md-6">
                <Form className="form-field">
                  <Form.Group controlId="Contact_Number">
                    <Form.Label className="required">Contact Number</Form.Label>

                    {/* <PhoneInput
                        country={"in"}
                        value={phone}
                        onChange={setPhone}
                        defaultFlag={"🇮🇳"}
                      /> */}
                    <Form.Control
                      type="text"
                      name="phone"
                      placeholder="Phone Number"
                      {...register("phone", {
                        required: "Phone is required",
                        pattern: {
                          value: /^\d{10}$/,
                          message: "Phone number must be a 10-digit number",
                        },
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.phone,
                      })}
                      onKeyDown={(event) => {
                        const key = event.key;
                        const inputValue = event.target.value;

                        // Allow only digits and specified control keys
                        if (
                          !/^\d$/.test(key) &&
                          ![
                            "Backspace",
                            "Delete",
                            "ArrowLeft",
                            "ArrowRight",
                            "Tab",
                            "Home",
                            "End",
                          ].includes(key)
                        ) {
                          event.preventDefault();
                        }

                        // Allow Backspace, Delete, ArrowLeft, ArrowRight, Tab, Home, End
                        if (
                          (inputValue.length >= 10 &&
                            ![
                              "Backspace",
                              "Delete",
                              "ArrowLeft",
                              "ArrowRight",
                              "Tab",
                              "Home",
                              "End",
                            ].includes(key)) ||
                          (inputValue.length === 10 && /^\d$/.test(key))
                        ) {
                          event.preventDefault();
                        }
                      }}
                    />
                  </Form.Group>
                </Form>
              </div>

              <div className="col-md-6">
                <Form className="form-field">
                  <Form.Group controlId="Living_City">
                    <Form.Label className="required">Living City</Form.Label>
                    <Controller
                      name="city_id"
                      control={control}
                      {...register("city_id", {
                        required: "City is required",
                      })}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={city}
                          value={city?.find(
                            (option) => option.value === field.value
                          )}
                          onChange={(selectedOption) =>
                            field.onChange(selectedOption.value)
                          }
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              borderColor: errors.city_id
                                ? "red"
                                : baseStyles.borderColor,
                            }),
                          }}
                        />
                      )}
                    />
                    {/* {optionsArray.map((options, index) => (
                        <Select
                          key={index}
                          defaultValue={selectedOption[index]}
                          onChange={(selectedOption) => selectedOption}
                          options={options}
                        />
                      ))} */}
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>

          <div>
            <div className="row">
              <div className="col-md-12">
                <Form className="form-field">
                  <Form.Group controlId="Message">
                    <Form.Label className="required">
                      Your Message or Query
                    </Form.Label>

                    {/* <Form.Control
                        as="textarea"
                        rows={3}
                        type="text"
                        name="Message"
                        placeholder="Enter Your Message or Query"
                      /> */}
                    <Form.Control
                      as="textarea"
                      name="message"
                      type="text"
                      rows={3}
                      placeholder="Enter Message"
                      {...register("message", {
                        required: "Message required",
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.message,
                      })}
                    />
                  </Form.Group>
                </Form>
              </div>
            </div>
          </div>

          <div className="form-field">
            <div className="apply-btn-holder ">
              <Button className="apply_now_btn " type="submit">
                Submit{" "}
              </Button>
            </div>

            <ResetModal show={modalShow} onHide={() => setModalShow(false)} />
          </div>
        </Form>
      </div>
    </>
  );
}

export default JpEmpContactForm;
