import React from "react";
import "./EducationalDetailsModal.css";
import "../../Jp_modal_common.css";
import { Button, Modal } from "react-bootstrap";
// import "./../../JP-Jobseeker-Step_modal/JobseekerStepModal.css";
import '../../JP-Jobseeker-Step_modal/JobseekerStepModal.css'
import CommonModal from "../../CommonModal/CommonModal";
import EducationalDetaildStepModal from "./EducationalDetaildStepModal/EducationalDetaildStepModal";
import { Link } from "react-router-dom";
const EducationalDetailsModal = ({ show, onHide, jobSeekarDetailsID }) => {
  return (
    <>
      <CommonModal show={show} onHide={onHide}>
        <div className="Jobseeker_step_modal_section">
          <Modal.Body>
            <EducationalDetaildStepModal onHide={onHide} jobSeekarDetailsID={jobSeekarDetailsID} />

            {/* close save btn */}
            {/* <div className="close-save-btn-holder form-field">
              <div className="Close-btn-holder">
                <Button className="close-btn" onClick={onHide}>
                  Cancel
                </Button>
              </div>

              <div className="apply-btn-holder">
              <Link to={"/job-portal/jobseeker/jobseeker-edit-profile"}>
                <Button className="apply_now_btn">Save & Close</Button>
              </Link>
              </div>
            </div> */}
          </Modal.Body>
        </div>
      </CommonModal>
    </>
  );
};

export default EducationalDetailsModal;
