import React, { useState, useEffect, useContext } from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller } from "react-hook-form";
import "./StepOneUnlockBestHome.css";
import { Form } from "react-bootstrap";
import { Context } from "../../../../../../../utils/context";
import { useNavigate } from "react-router-dom";

function StepOneUnlockBestHome({
  contact_no,
  setContact_no,
  loanFormData,
  setMobileVerify,
  mobileVerify,
  name,
  dob,
  setName,
  setDob,
  contactErr,
  dobErr,
  nameErr
}) {

  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);

  useEffect(() => {
    if (loanFormData?.id) {
      setMobileVerify(true);
    }
    reset(loanFormData);
    console.log("from step 1 :- ", loanFormData);
  }, [loanFormData]);



  const {
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  // useEffect(() => {
  //   setValue("contact_no", contact_no)
  //   console.log("contact_no");
  // }, [contact_no]);

  const handlePhoneNumberChange = (value) => {
    setContact_no(value);
    setMobileVerify(false);
    setValue("contact_no", value)
    console.log(value, "valuesss");
    if (value.length >= 12 && value.length <= 12) {
      setIsPhoneNumberValid(true);
    } else {
      setIsPhoneNumberValid(false);
    }
  };

  return (
    <>
      <div className="StepOneUnlockBestHome form-style">
        {/* <div className="StepOneUnlockBestHome-content">
          <ul className="Best-Home-List">
            <li>
              <FontAwesomeIcon className="correct-icon" icon={faCircleCheck} />
              Hand-picked offers from 20+ lenders
            </li>
            <li>
              <FontAwesomeIcon className="correct-icon" icon={faCircleCheck} />
              Money in mins via Pre-Approved loans
            </li>
            <li>
              <FontAwesomeIcon className="correct-icon" icon={faCircleCheck} />
              Instant sanctions and disbursal
            </li>
            <li>
              <FontAwesomeIcon className="correct-icon" icon={faCircleCheck} />
              Contact-less processes
            </li>
          </ul>
        </div> */}

        <div className="contact-number">
          <Form>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Your Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                name="name"
                value={name}
                onKeyDown={(event) => {
                  if (!/[A-Z-a-z ]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => {
                  setName(e.target.value)
                }}
              />
              {nameErr && (
                <span className="text text-danger">
                  {nameErr}
                </span>
              )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>Date of Birth</Form.Label>
              <div>
                <Form.Control
                  type="date"
                  placeholder="Enter Date Of Birthday"
                  name="dob"
                  value={dob}
                  onChange={(e) => {
                    setDob(e.target.value)
                  }}
                />
                {dobErr && (
                  <span className="text text-danger">
                    {dobErr}
                  </span>
                )}
              </div>
            </Form.Group>

            <Form.Group className="" controlId="formBasicEmail">
              <Form.Label>Mobile Number</Form.Label>
              <div className="input-span-holder">
                <div className="country-coder">
                  <Controller
                    name="contact_no"
                    control={control}
                    render={({ field }) => (
                      <PhoneInput
                        defaultCountry="in"  // Use uppercase for country code if necessary
                        value={contact_no}
                        onChange={(value) => {
                          field.onChange(value);
                          handlePhoneNumberChange(value);
                        }}
                        placeholder="Enter your contact number"  // Placeholder text
                        styles={{
                          control: (baseStyles) => ({
                            ...baseStyles,
                            borderColor: errors.contact_no ? "red" : baseStyles.borderColor,
                          }),
                        }}
                      // disabled={mobileVerify}
                      />
                    )}
                    rules={{ required: "Mobile Number is required" }}
                  />

                </div>
                {contactErr && (
                  <span className="text text-danger">
                    {contactErr}
                  </span>
                )}
              </div>
            </Form.Group>

          </Form>
        </div>
      </div>
    </>
  );
}

export default StepOneUnlockBestHome;
