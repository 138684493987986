import React from 'react'
import './JPBlogBanner.css'
const JPBlogBanner = () => {
  return (
    <>
      <section className='jp-blog-banner'>
        <div className='image-holder-banner'>
          <img className='bannner-img' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/blog/banner-img.png'} />
          <div className='color-overlay'></div>
          <div className='heading-holder'>
            <h1>Stay Updated with Latest Blogs</h1>
            <p>Find Here the Best Relatables Blogs</p>
          </div>
        </div>
      </section>
    </>
  )
}

export default JPBlogBanner