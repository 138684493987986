import React, { useRef, useState, useEffect, useContext } from 'react'
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import JoditEditor from "jodit-react";
import "react-phone-input-2/lib/style.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select from "react-select";
import "./JpEmpCompanyProfile.css"
import classNames from "classnames";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Context } from "../../../../../utils/context";
import { Form, Button } from "react-bootstrap";
import Cookies from "js-cookie";
function JpEmpCompanyProfile(companyId) {

    const { postData, getData, Select2Data, IMG_URL, getDimension } = useContext(Context);
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset, watch,
        trigger,
        setValue,

    } = useForm();

    const { append, fields, remove } = useFieldArray({
        control,
        name: "locations",
    });

    const [data, setData] = useState([]);

    const [CountryData, setcountryData] = useState([]);
    const GetAllCountry = async () => {
        const response = await getData("/without-login/master/all-country");

        if (response.success) {
            setcountryData(await Select2Data(response?.data, "country_id"));
        }
    };

    const [states, setStates] = useState([]);
    const GetAllStates = async (id) => {
        const response = await getData(`/without-login/master/all-state/${id}`);
        if (response?.success) {
            setStates(await Select2Data(response?.data, "state_id"));
        }
    };

    const [city, setCity] = useState([]);
    const GetAllCities = async (id) => {

        const response = await getData(`/without-login/master/all-city/${id}`);
        if (response?.success) {
            setCity(await Select2Data(response?.data, "city_id"));
        }
    };

    const getDataAll = async () => {
        const response = await getData(
            `/job-portal/employer/company-details/branch-locations/${decodedToken.user}`
        );
        if (response?.success) {
            setData(response?.data);
            const locations = response.data.map(location => ({
                name: location.name,
                address: location.address,
                landmark: location.landmark,
                country_id: location.country_id,
                state_id: location.state_id,
                city_id: location.city_id,
                pincode: location.pincode,
            }));
            reset({ locations });
        }
    };


    useEffect(() => {
        GetAllCountry();
        getDataAll();
    }, []);


    console.log(Cookies.get('llp_web_security'), 'ppppppppppppp');

    const token = Cookies.get("llp_web_security");
    let decodedToken;
    if (token) {
        decodedToken = JSON.parse(atob(token.split(".")[1]));
    } else {
        console.error('Token is undefined');
        // Handle the case where token is not available
    }


    const onSubmit = async (data) => {
        console.log("thi data ", data)
        const fromData = new FormData();

        const locations = [];

        data.locations.forEach((location, index) => {
            locations.push({
                name: location.name,
                address: location.address,
                landmark: location.landmark,
                country_id: location.country_id?.value,
                state_id: location.state_id?.value,
                city_id: location.city_id?.value,
                pincode: location.pincode,
            });
        });
        fromData.append("locations", JSON.stringify(locations));

        try {
            const response = await postData(`/job-portal/employer/company-details/branch-locations`, fromData);
            if (response?.success) {
                console.log(response);

                await getDataAll();
                reset();
            } else {

                console.error('Submission was not successful:', response);
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        }

    };


    return (
        <><Form onSubmit={handleSubmit(onSubmit)}>
            <div className='btn-div'>
                <button className='add-btn' type='button' onClick={handleSubmit(onSubmit)}>Save</button>
            </div>
            <div className='row '>
                {data?.map((val, index) =>
                    <div className='col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-12 '>
                        <div className='person-detail-card d-flex'>
                            {/* <div className='person-img-div me-4'>
                              
                                <img class="person-img" src={IMG_URL + val?.image} />
                            </div> */}

                            <div className='mt-3'>
                                {/* <FontAwesomeIcon icon="fa-solid fa-location" className='pencil-icon' /> */}
                                <h5 className='award-name'>{val?.name} </h5>
                                <p className='year-text'>{val?.address} , {val?.landmark} , {val?.city_id?.label},
                                    ,{val?.state_id?.label} , {val?.country_id?.label} , {val?.pincode}
                                </p>

                            </div>

                            {/* <div className='edit-icon'>
                              
                                <FontAwesomeIcon icon="fa-solid fa-pencil" className='pencil-icon' />
                            </div> */}
                        </div>
                    </div>
                )}
            </div>

            {fields.map((field, index) => (
                <div key={field.id}>
                    <div className='row mt-4'>
                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                            <Form.Group className="mb-3" controlId={`formAwardName-${index}`}>
                                <Form.Label>
                                    Address Name:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Address Name"
                                    {...register(`locations.${index}.name`, {
                                        required: "Address name required",
                                    })}
                                    className={classNames({
                                        "is-invalid": errors.locations?.[index]?.name,
                                    })}

                                />
                                {errors.locations?.[index]?.name && <p className="text-danger">{errors.locations[index].name.message}</p>}
                            </Form.Group>
                        </div>
                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12'>
                            <Form.Group  className="mb-3" controlId={`formAwardYear-${index}`}>
                                <Form.Label >
                                    Landmark:
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Landmark"
                                    {...register(`locations.${index}.landmark`, {
                                        // required: "Landmark required",
                                    })}
                                    className={classNames({
                                        "is-invalid": errors.locations?.[index]?.landmark,
                                    })}
                                />
                                {errors.locations?.[index]?.landmark && <p className="text-danger">{errors.locations[index].landmark.message}</p>}
                            </Form.Group>
                        </div>

                        <Form.Group className="mb-3" controlId={`formAwardDescription-${index}`}>
                            <Form.Label >
                                Address:
                            </Form.Label>
                            <Form.Control
                                as="textarea"
                                placeholder="Enter Address"
                                {...register(`locations.${index}.address`, {
                                    required: "Address required",
                                })}
                                className={classNames({
                                    "is-invalid": errors.locations?.[index]?.address,
                                })}

                            />
                            {errors.locations?.[index]?.address && <p className="text-danger">{errors.locations[index].address.message}</p>}
                        </Form.Group>

                        <div className="col-xxl-3 col-xl-3 col-lg-8 col-md-8 col-12">
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Country</Form.Label>
                                <Controller

                                    {...register(`locations.${index}.country_id`, {
                                        required: "country required",
                                    })}
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: errors.locations?.[index]?.country_id
                                                        ? "red"
                                                        : baseStyles,
                                                }),
                                            }}
                                            {...field}
                                            options={CountryData}
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption);
                                                GetAllStates(selectedOption.value);
                                                setValue(`locations.${index}.country_id`, selectedOption);
                                                setValue(`locations.${index}.state_id`, "");
                                                setValue(`locations.${index}.city_id`, "");

                                            }}
                                        />
                                    )}
                                />
                                {errors.locations?.[index]?.country_id
                                    && <p className="text-danger">{errors.locations[index].country_id.message}</p>}
                            </Form.Group>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-8 col-md-8 col-12">
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>State</Form.Label>
                                <Controller
                                    {...register(`locations.${index}.state_id`, {
                                        required: "state required",
                                    })}
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: errors.locations?.[index]?.state_id
                                                        ? "red"
                                                        : baseStyles,
                                                }),
                                            }}
                                            {...field}
                                            options={states}
                                            onChange={(selectedOption) => {
                                                field.onChange(selectedOption);
                                                GetAllCities(selectedOption.value);
                                                setValue(`locations.${index}.state_id`, selectedOption);
                                                setValue(`locations.${index}.city_id`, "");

                                            }}
                                        />
                                    )}
                                />
                                {errors.locations?.[index]?.state_id
                                    && <p className="text-danger">{errors.locations[index].state_id.message}</p>}
                            </Form.Group>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-8 col-md-8 col-12">
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>City</Form.Label>
                                <Controller
                                    {...register(`locations.${index}.city_id`, {
                                        required: "city required",
                                    })}
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            styles={{
                                                control: (baseStyles) => ({
                                                    ...baseStyles,
                                                    borderColor: errors.locations?.[index]?.city_id
                                                        ? "red"
                                                        : baseStyles,
                                                }),
                                            }}
                                            {...field}
                                            options={city}

                                        />
                                    )}
                                />
                                {errors.locations?.[index]?.city_id
                                    && <p className="text-danger">{errors.locations[index].city_id.message}</p>}
                            </Form.Group>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-8 col-md-8 col-12">
                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label>Pincode</Form.Label>

                                <Form.Control
                                    type="text"
                                    maxLength={6}
                                    placeholder="Enter Pincode"
                                    {...register(`locations.${index}.pincode`, {
                                        required: "Pincode required",
                                        pattern: {
                                            value: /^\d{6}$/,
                                            message: "Pincode must be exactly 6 digits",
                                        },
                                        validate: {
                                            startsWithZero: value => value.toString().charAt(0) !== '0' || "Pincode cannot start with 0"
                                        }
                                    })}
                                    className={classNames({
                                        "is-invalid": errors.locations?.[index]?.pincode,
                                    })}
                                    onKeyDown={(event) => {
                                        if (!/[0-9]/.test(event.key) && event.key !== "Backspace") {
                                            event.preventDefault();
                                        }

                                        if (event.target.value.length >= 6 && event.key !== "Backspace") {
                                            event.preventDefault();
                                        }
                                    }}
                                    inputMode="numeric"
                                    pattern="[0-9]*"
                                />
                                {errors.locations?.[index]?.pincode
                                    && <p className="text-danger">{errors.locations[index].pincode.message}</p>}
                            </Form.Group>
                        </div>



                        {index !== 0 && (
                            <div className="addremoveadress">
                                <div className="text-end Addnewadresstxt">
                                    <p className="addmore" onClick={() => remove(index)} >
                                        Remove Address{" "}
                                        {/* <FontAwesomeIcon icon="fa-solid fa-minus" /> */}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ))}
            <div className="AddMoreLanguage">
                <p onClick={() => append({ name: "", url: "", image: "" })} className='addmoreee'>
                    Add More Links
                </p>
            </div>


        </Form>
        </>
    )
}

export default JpEmpCompanyProfile