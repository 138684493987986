import React from 'react'
import { Link } from "react-router-dom";
import './EmpFormConform.css'
import '../../EmpStepForm/EmpstepForm.css'
import Success from '../../../JP-Common-Elements/Animation/Success';
import EmpSmBannerCommon from '../../EmpSmBannerCommon/EmpSmBannerCommon'
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
function EmpFormConform() {
    const { id } = useParams();
    const handleClick = () => {
        Cookies.remove("job_id");
    };
  

    return (
        <section className='form-submit-conform StepForm-Employer'>
            <div>
                <EmpSmBannerCommon />
            </div>
            <div className='container'>
                <div className='confirm-card'>

                    <div className='heading'>
                        <h6>Job Post Confirmation</h6>
                    </div>
                    <div className='sucess-text'>
                        <Success />
                        <h1>Your Plan has been updated Successfully</h1>
                        <p>If you need assistance, please contact us by email at support@profcyma.com.</p>
                    </div>
                </div>
                <div className="save-btn-div ">
                    {/* <Link to="/job-portal/employer/reviewjob"> */}
                    <Link to={`/job-portal/employer/dashboard`}>
                        <button className="save-btn" onClick={handleClick}>Ok</button>
                    </Link>
                </div>
            </div>
        </section >
    )
}

export default EmpFormConform