
import { React, useRef, useEffect, useState, useContext } from "react";
import "../FirstCredit/FirstCredit.css";
import "../../F-Enquirymodal/FEnquiryModal.css";
import { Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ForthCredit from "../FourthCredit/ForthCredit";
import FifthCredit from "../FifthCredit/FifthCredit";
import InputGroup from 'react-bootstrap/InputGroup';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { Context } from "../../../../../utils/context";
import Cookies from "js-cookie";

const ThirdCredit = (props) => {
  const [scoreFromId, setScoreFromId] = useState(null);
  useEffect(() => {
    setScoreFromId(Cookies.get("score_form_id"));
    console.log("score_form_id", scoreFromId);
  }, []);
  console.log("score_form_id", scoreFromId);
  const [ForthModalShow, setForthModalShow] = useState(false);
  const [FifthModalShow, setFifthModalShow] = useState(false);
  const { postData, IMG_URL } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    reset
  } = useForm();

  const handleUppercase = (e) => {
    const uppercasedValue = e.target.value.toUpperCase();
    setValue('pan', uppercasedValue, { shouldValidate: true });
  };

  const validateAge = (dob) => {
    console.log("dob", dob);
    const selectedDate = new Date(dob);
    const currentDate = new Date();
    const minAgeDate = new Date();
    minAgeDate.setFullYear(currentDate.getFullYear() - 18);

    if (selectedDate > minAgeDate) {
      console.log("dob", "Age should not be less than 18 years");
      setError("dob", { type: "manual", message: "Age should not be less than 18 years" });
      return "Age should not be less than 18 years";
    }
    console.log("dob", "done");
    clearErrors("dob")
    return true;
  };

  const handleDateChange = (event) => {
    const value = event.target.value;
    validateAge(value);
  };

  const onSubmit = async (d) => {
    console.log("datadatdata", d);
    try {

      d.eligiblity = "Excellent"
      d.id = Cookies.get("score_form_id");
      console.log("data3", d);
      const res = await postData(`/finance/forms/free-credit-score-form`, d);
      if (res?.success) {
        console.log(res, 'submit');
        handleClose()

      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleClose = () => {
    props.onHide();
    // setForthModalShow(true);
    setFifthModalShow(true);
  };
  return (
    <>
      <section className="first-credit">
        <Modal
          className="fenquiry-modal modal-dialog-scrollable"
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter "
          centered
          scrollable
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Lifetime Free Credit Score
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-sec-start form-sec-start-two">
              <p className="first-line-third-credit">You are just 1 step away from</p>
              <p className="second-line-third-credit">We need a few more details to access your Credit Report</p>
              <Form onSubmit={handleSubmit(onSubmit)} >
                <Form.Group controlId="pan">
                  <Form.Label>PAN</Form.Label>
                  <InputGroup className="">
                    <Form.Control type="text"
                      name="pan"
                      placeholder="As per your bank records"
                      {...register("pan", {
                        required: "PAN is required",
                        pattern: {
                          value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                          message: "Invalid Pan No.",
                        },

                      }
                      )}
                      className={classNames("", {
                        "is-invalid": errors?.pan,
                      })}
                      onChange={handleUppercase}
                      maxLength={10}
                    />
                    <InputGroup.Text id="basic-addon1">10/10 Characters</InputGroup.Text>

                  </InputGroup>
                  {errors.pan && (<span className="text-danger">{errors.pan.message}</span>)}
                </Form.Group>

                <Form.Group controlId="pincode">
                  <Form.Label>Pin Code</Form.Label>
                  <InputGroup className="">
                    <Form.Control
                      type="number"
                      name="pincode"
                      placeholder="Enter Pincode"
                      {...register("pincode", {
                        required: "pincode is required",
                        minLength: {
                          value: 6,
                          message: "Pincode must be 6 digits long",
                        },
                      })}
                      className={classNames("", {
                        "is-invalid": errors?.pincode,
                      })}
                      onKeyDown={(event) => {
                        if (!/[A-Za-z0-9 ]/.test(event.key)) {
                          event.preventDefault();
                        }
                        if (event.target.value.length >= 6 && event.key !== "Backspace") {
                          event.preventDefault();
                        }
                      }}
                    />
                    <InputGroup.Text id="basic-addon1">6/6 Digits</InputGroup.Text>

                  </InputGroup>
                  {errors.pincode && (<span className="text-danger">{errors.pincode.message}</span>)}
                </Form.Group>

                <Form.Group controlId="dateofbirth">
                  <Form.Label>Date of Birth</Form.Label>
                  <InputGroup className="">
                    <Form.Control
                      type="date"
                      placeholder="Enter Date Of Birthday"
                      name="dob"
                      max={new Date().toISOString().split('T')[0]}
                      {...register("dob", {
                        required: "date required",

                      })}
                      className={classNames("", {
                        "is-invalid": errors?.dob,
                      })}
                      onChange={handleDateChange}
                    />
                    <InputGroup.Text id="basic-addon1">Digits only</InputGroup.Text>
                  </InputGroup>
                  <p className="modal-note">Note : As per your PAN Card</p>
                  {errors?.dob && (
                    <span className="text text-danger">
                      {errors.dob.message}
                    </span>
                  )}
                </Form.Group>
                <div className="text-center">
                  <Button
                    className="free-credit-btn"
                    type="submit"
                  // onClick={handleClose}
                  >
                    Proceed
                    <FontAwesomeIcon
                      icon="fa-solid fa-angles-right"
                      className="right-angle"
                    />
                  </Button>
                </div>

              </Form>
            </div>
          </Modal.Body>

        </Modal>
        {/* <ForthCredit show={ForthModalShow} onHide={() => setForthModalShow(false)} /> */}
        <FifthCredit
          show={FifthModalShow}
          onHide={() => setFifthModalShow(false)}
          getValues={getValues}
          name={props.name}
        />
      </section>
    </>
  );
};

export default ThirdCredit;