import React from "react";
import "./JPSignIn.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import JPSignInForm from "../JP-Common-Elements/Jp-Forms/JP-Sing-In-Form/JPSignInForm";
library.add(fas);

function JPSignIn() {
  return (
    <>
      <div className="JPContactForm sign-in-form">
        <div className="container">
          <div className="heading-holder">
            <div className="row justify-content-end">
              <div className="col-xl-7 col-lg-6  col-md-12 ">
                <div>
                  <h1 className="jpsect_title">Sign In</h1>
                </div>
              </div>
            </div>
          </div>

          {/* laptop view contact form start */}
          <div className="Jp-contact-form-container">
            <div className="bg-img-container">
              <div className="container">
                <div className="row me-0 ms-0">
                  <div className="col-md-12 p-0">
                    <div className="jp-contact-form-bg">
                      <div className="row ">
                        <div className="col-md-7">
                          <img
                            className="contact-bg-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Job-portal/Images/contact/contact_bg_img.png"
                            }
                          />
                        </div>
                      </div>

                      <div className="overlay"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="Jp-contact-form-content">
              <div className="container">
                <div className="row me-0 ms-0">
                  <div className=" col-xl-5 col-md-5">
                    <div className="text-holder">
                      <div className="Jp-contact-heading">
                        <h1>Discover</h1>
                        <h4>Your Desired Job </h4>
                        <h5>in Just a Matter of Clicks!</h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-7 col-md-7">
                    <div className="jp-contact-form">
                      <JPSignInForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* laptop view contact form  end*/}

          {/* mobile view contact form start */}
          <div className="mobile-view-form-container">
            <div className="bg-img-container">
              <div className="container">
                <div className="row me-0 ms-0">
                  <div className="col-md-12 p-0">
                    <div className="jp-contact-form-bg">
                      <div className="row me-0 ms-0">
                        <div className="col-md-12 p-0">
                          <img
                            className="contact-bg-img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Job-portal/Images/contact/contact_bg_img.png"
                            }
                          />
                        </div>
                      </div>

                      <div className="overlay"></div>

                      <div className="Jp-contact-banner-content">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="text-holder">
                                <div className="Jp-contact-heading">
                                  <h1>Discover</h1>
                                  <h4>Your Desired Job </h4>
                                  <h5>in Just a Matter of Clicks!</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="Jp-contact-form-content">
              <div className="container">
                <div className="row">
                  <div className="col-md-12">
                    <div className="jp-contact-form">
                      <JPSignInForm />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* mobile view contact form end */}
        </div>
      </div>
    </>
  );
}

export default JPSignIn;
