import React, { useState, useEffect, useContext } from "react";
import "../../MainDashboard/JpMainDashboard.css";
import Nav from 'react-bootstrap/Nav';
import { Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';
import { Form } from "react-bootstrap";
import Select from 'react-select'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import LogoutModal from '../../../JP-Common-Elements/Jp-modals/LogoutModal/LogoutModal';
import { Context } from '../../../../../utils/context';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import Delete_JobModel from '../../../JP-Common-Elements/Jp-modals/Delete_JobModel/Delete_JobModel';
import ResetModal from '../../../JP-Common-Elements/Jp-modals/ResetModal/ResetModal'



function ManageTab({ jobStatusData }) {

  const { postData, getData, Select2Data, IMG_URL } = useContext(Context);
  const [manageData, setManageData] = useState();
  const [draftData, setDraftdata] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowone, setModalShowone] = useState(false);
  const [activeButtons, setActiveButtons] = useState({});
  const [modalreset, setModalReset] = useState(false);
  const {
    control,
    register,
    formState: { errors },
  } = useForm();


  const navigate = useNavigate();

  const handleClick = async (id, job_status_id) => {
    try {
      const res = await postData(`/job-portal/employer/dashboard/${id}`, { job_status_id })
      if (res?.success) {
        setActiveButtons(prevState => ({
          ...prevState,
          [id]: job_status_id
        }));
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.log(error.message);
    }
  };


  const [statusCountData, setStatusCountData] = useState();

  const statusCount = async () => {
    try {
      const res = await getData(`/job-portal/employer/dashboard/count-status`)
      if (res?.success) {
        setStatusCountData(res?.data?.count);
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleViewJobDetails = (jobId) => {
    navigate(`/job-portal/employer/jobdetails/${jobId}`); // Navigate to the specified URL
  };

  const handleEditStepJobDetails = (jobId) => {
    navigate(`/job-portal/employer/stepform/${jobId}`); // Navigate to the specified URL
  };

  const handleEditJobDetails = (jobId) => {
    navigate(`/job-portal/employer/editjobdetails/${jobId}`); // Navigate to the specified URL
  };

  useEffect(() => {
    if (manageData) {
      const initialActiveButtons = {};
      manageData?.forEach(card => {
        initialActiveButtons[card?.id] = card?.job_status_id; // Assuming card.id is unique identifier for each card
      });
      setActiveButtons(initialActiveButtons);
    }
  }, [manageData]);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}/${month}/${day}`;
  }

  const options1 = [


  ];
  const options2 = [
    { value: 'pune', label: 'pune' },
    { value: 'Mumbai', label: 'Mumbai' },
    { value: 'hydrabad', label: 'hydrabad' },

  ];
  const options3 = [
    { value: '13/2/202', label: '13/2/202' },
    { value: '3/5/2022', label: '3/5/2022' },
    { value: '13/2/2024', label: '13/2/2024' },

  ];
  const options4 = [
    { value: 'ASC', label: 'assending' },
    { value: 'DESC', label: 'desending' },
  ];

  const [showMoreCollab, setShowMoreCollab] = useState(false);
  const [showCountCollab, setShowCountCollab] = useState(2);

  const handleToggleCollab = () => {
    setShowMoreCollab(!showMoreCollab);
    setShowCountCollab(showMoreCollab ? 2 : manageData?.length);
  };
  const [showMorelist, setShowMorelist] = useState(false);
  const [showCountlist, setShowCountlist] = useState(2);

  const handleTogglelist = () => {
    setShowMorelist(!showMorelist);
    setShowCountlist(showMorelist ? 2 : manageData?.length);
  }

  const [JobTitleValue, setJobTitleValue] = useState();

  const [cityValue, setCityValue] = useState();

  const [dateValue, setDateValue] = useState();

  const [orderTitle, setOrderValue] = useState(null);

  const [searchJobId, setSearchJobId] = useState();
  const [searchCity, setSearchCity] = useState();

  const getDataFilter = async () => {
    try {

      const res = await getData(`/job-portal/employer/dashboard/manage-job?searchJobId=${searchJobId?.value || ""}` +
        `&searchCity=${searchCity?.value || ""}&date=${dateValue || ""}&order=${orderTitle?.value || ""}`)

      if (res?.success) {
        setManageData(res?.data?.data?.rows)
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.log(error.message);
    }

  }

  useEffect(() => {
    getDataFilter();
  }, [searchJobId, searchCity, dateValue, orderTitle]);

  // const getManageJobs = async () => {
  //   if (JobTitleValue) {
  //     try {

  //       const res = await getData(`/job-portal/employer/dashboard/manage-job?job_title=${JobTitleValue}`)
  //       if (res?.success) {
  //         setManageData(res?.data?.data?.rows)
  //       } else {
  //         console.log("Error");
  //       }
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   } else if (cityValue) {
  //     try {

  //       const res = await getData(`/job-portal/employer/dashboard/manage-job?city=${cityValue}`)
  //       if (res?.success) {
  //         setManageData(res?.data?.data?.rows)
  //       } else {
  //         console.log("Error");
  //       }
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   } else if (dateValue) {
  //     try {

  //       const res = await getData(`/job-portal/employer/dashboard/manage-job?date=${dateValue}`)
  //       if (res?.success) {
  //         setManageData(res?.data?.data?.rows)
  //       } else {
  //         console.log("Error");
  //       }
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   } else if (orderTitle) {
  //     try {

  //       const res = await getData(`/job-portal/employer/dashboard/manage-job?order=${orderTitle}`)
  //       if (res?.success) {
  //         setManageData(res?.data?.data?.rows)
  //       } else {
  //         console.log("Error");
  //       }
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   }
  //   else {
  //     try {

  //       const res = await getData(`/job-portal/employer/dashboard/manage-job`)
  //       if (res?.success) {
  //         setManageData(res?.data?.data?.rows)
  //       } else {
  //         console.log("Error");
  //       }
  //     } catch (error) {
  //       console.log(error.message);
  //     }
  //   }

  // }


  useEffect(() => {

    GetAllJobTitle();

  }, []);
  const [city, setCity] = useState([]);
  const GetAllCities = async (id) => {

    const response = await getData(`/without-login/master/all-cities`);
    if (response?.success) {
      setCity(await Select2Data(response?.data, "state_id"));
    }
  };
  const [jobTitle, setJobTitle] = useState(null);
  const GetAllJobTitle = async () => {
    const response = await getData("/without-login/master/all-job-title");
    if (response.success) {
      setJobTitle(await Select2Data(response?.data, "job_title_id"));
    }
  };

  const [draftJobData, setDraftJobData] = useState();
  const getAllDraftJob = async () => {
    const response = await getData("/job-portal/employer/dashboard/draft-job");
    if (response.success) {
      setDraftdata();
      setDraftJobData(response?.data?.data);
    }
  };

  useEffect(() => {
    // GetAllJobTitle();
    GetAllCities();
    // getManageJobs();
    getAllDraftJob();
    getDataFilter();
  }, []);;

  useEffect(() => {
    statusCount();
  }, [jobStatusData])



  function formatDate(dateString) {
    if (!dateString) return ""; // Handle cases where dateString is undefined or null

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);

    return `${day}-${month}-${year}`;
  }




  //   const getsearchJob = async (selectedId) => {
  //     try {
  //         if (selectedId) {
  //             const res = await getData(`/job-portal/jobs/industry/${selectedId}?jobTypeId=${TypeId}&cityId=${cityId}&company_name=${company_name}`)
  //             if (res?.success) {
  //               setJobData(res?.data)
  //             } else {
  //                 console.log("Error");
  //             }
  //         }
  //         else {
  //             const res = await getData(`/job-portal/jobs/search-data/data?jobTypeId=${TypeId}&cityId=${cityId}&company_name=${company_name}`)
  //             if (res?.success) {
  //               setJobData(res?.data)
  //             } else {
  //                 console.log("Error");
  //             }
  //         }
  //     } catch (error) {
  //         console.log(error.message);
  //     }
  // }

  // useEffect(() => {

  //   if ( TypeId || cityId || company_name) {
  //     getsearchJob(selectedId);
  //   } else {
  //     // Handle the case where any of the required variables is not defined or falsy
  //     console.log("Required variables are not defined");
  //   }
  // }, [selectedId, TypeId, cityId, company_name]);



  const [jobID, setJobID] = useState();
  const deleteJOB = (jobId) => {
    setJobID(jobId);
    setModalShow(true)
  }

  return (
    <>
      <section className='jp-employer-second-managejob'>
        <div className='container'>
          {
            !draftData ? (
              <>
                <div>
                  <div className='row'>
                    <div className='col-xxl-8 col-xl-8 col-sm-8 col-12'>
                      {statusCountData?.map((data) => (
                        <button className='open-btn me-2'>{data?.name} ({data?.count})</button>
                      ))}
                      {/* <button className='paused-btn me-2'>Paused (3)</button>
                        <button className='close-btn'>closed (3)</button> */}
                    </div>
                    <div className='col-xxl-4 col-xl-4 col-sm-4 col-6 '>
                      <div className='draft-div '>
                        <button className='draft-btn' onClick={() => setDraftdata(true)}>Draft Jobs ({draftJobData?.count})</button>
                      </div>
                    </div>
                  </div>
                  <Tab.Container id="left-tabs-example" defaultActiveKey="first" >
                    <div className='heading'>
                      <div className='row'>
                        <div className='col-xxl-11 col-xl-11 col-lg-10 col-md-10 col-8 d-flex'>
                          <h3>List of Till Date Posted Jobs</h3>
                          <div className="orange-line-two"></div>
                        </div>
                        <div className='col-xxl-1 col-xl-1 col-lg-2 col-md-2 col-4 d-flex justify-content-end text-end'>
                          <div className=''>
                            <Nav variant="pills" className="d-flex">
                              <Nav.Item>
                                <Nav.Link eventKey="first">
                                  <img className='grid-img' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/icons/gridone.png'} />
                                </Nav.Link>
                              </Nav.Item>
                              <Nav.Item>
                                <Nav.Link eventKey="second">
                                  <img className='grid-img' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/icons/gridtwo.png'} />
                                </Nav.Link>
                              </Nav.Item>
                            </Nav>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className='row mt-4'>
                          {/* cards colm sec start */}
                          <div className=' col-xl-9 col-lg-8 col-md-6 col-12 '>
                            <Tab.Content>
                              {/* grid view section start */}
                              <Tab.Pane eventKey="first">
                                <div className='card-sec'>
                                  <div className='row'>
                                    {manageData?.slice(0, showCountCollab).map((card, index) => (
                                      <div key={index} className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-12'>
                                        <div className='manage-card'>
                                          <div className='row'>
                                            <div className='col-xxl-10 col-10'>
                                              <h6 className='graphic-text'>{card?.job_title?.name}</h6>
                                            </div>
                                            <div className='col-xxl-2 col-2'>
                                              <div className='icon'>
                                                <Dropdown>
                                                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    <FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} />
                                                  </Dropdown.Toggle>

                                                  <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => handleViewJobDetails(card?.id)}>View Job Details</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => handleEditJobDetails(card?.id)}>Edit Job Details</Dropdown.Item>
                                                    <Dropdown.Item href="#/action-3" onClick={() => deleteJOB(card?.id)}>Delete job</Dropdown.Item>
                                                  </Dropdown.Menu>
                                                </Dropdown>
                                              </div>
                                            </div>
                                          </div >
                                          <Delete_JobModel
                                            show={modalShow}
                                            jobID={jobID}
                                            onHide={() => setModalShow(false)}
                                            text={"Are you sure, you want to delete job?"}
                                            heading={"Delete Job"}
                                            setModalShow={setModalShow}
                                            setModalReset={setModalReset}
                                            imgSource={"/assets/Job-portal/Images/employer/Logout/deleteaward.png"}
                                          />
                                          <div className='row'>
                                            <div className='col-xxl-12 col-12'>
                                              <div className=' info-div d-flex'>
                                                <FontAwesomeIcon icon="fa-solid fa-calendar-days" />
                                                <p>Posted on {formatDate(card?.createdAt)}</p>
                                              </div>
                                              <div className='info-div d-flex'>
                                                <FontAwesomeIcon icon="fa-solid fa-location-dot" />
                                                <p>{card?.city?.name}</p>
                                              </div>
                                              {/* <div className='info-div d-flex'>
                                                <FontAwesomeIcon icon="fa-solid fa-star" />
                                                <p>{card.star}</p>
                                              </div> */}
                                            </div>
                                            {/* <div className='col-xxl-12 col-12 d-flex counts'>
                                              <div >
                                                <p>Active</p>
                                                <p className='orange-text'>{card.counts.active}</p>
                                              </div>
                                              <div>
                                                <p>New</p>
                                                <p>{card.counts.new}</p>
                                              </div>
                                              <div>
                                                <p>Contacting</p>
                                                <p>{card.counts.contacting}</p>
                                              </div>
                                              <div>
                                                <p>Hired</p>
                                                <p>{card.counts.hired}</p>
                                              </div>
                                            </div> */}
                                            <div className=' col-xxl-12 col-12 btns-div mt-2'>
                                              {jobStatusData?.map((jobstatus) => (
                                                <button
                                                  key={jobstatus.id}
                                                  className={`open-btn me-2 ${activeButtons[card.id] === jobstatus.id ? 'active' : ''}`}
                                                  onClick={() => { handleClick(card.id, jobstatus.id); statusCount(); }}
                                                >
                                                  {jobstatus?.name}
                                                  {activeButtons[card.id] === jobstatus.id && '(Active)'}
                                                </button>
                                              ))}
                                              {/* <button className='open-btn-inactive me-2'>Open (3)</button>
                                              <button className='paused-btn-inactive me-2'>Paused (3)</button>
                                              <button className='close-btn-inactive'>closed (3)</button> */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                                <div className='text-center'>
                                  <button className='show-more-btn' onClick={handleToggleCollab}>
                                    {showMoreCollab ? 'Show Less' : 'Show More'}
                                    <FontAwesomeIcon icon={showMoreCollab ? faAngleUp : faAngleDown} className='ms-3' />
                                  </button>
                                </div>
                              </Tab.Pane>
                              {/* grid view section end */}

                              {/* list view section start */}
                              <Tab.Pane eventKey="second">
                                <div className='card-sec'>
                                  {manageData?.slice(0, showCountlist).map((card, index) => (
                                    <div key={index} className='manage-card'>
                                      <div className='row'>
                                        <div className='col-xxl-10  col-10'>
                                          <h6 className='graphic-text'>{card?.job_title?.name}</h6>
                                        </div>
                                        <div className='col-xxl-2 col-2'>
                                          <div className='icon'>
                                            <Dropdown>
                                              <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                <FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => handleViewJobDetails(card?.id)}>View Job Details</Dropdown.Item>
                                                <Dropdown.Item onClick={() => handleEditJobDetails(card?.id)}>Edit Job Details</Dropdown.Item>
                                                <Dropdown.Item href="#/action-3">Delete Job</Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='row'>
                                        <div className='col-xxl-3 col-xl-6 col-lg-6 col-md-12   col-12'>
                                          <div className=' info-div d-flex'>
                                            <FontAwesomeIcon icon="fa-solid fa-calendar-days" />
                                            <p>Posted on {formatDate(card?.createdAt)}</p>
                                          </div>
                                          <div className='info-div d-flex'>
                                            <FontAwesomeIcon icon="fa-solid fa-location-dot" />
                                            <p>{card?.city?.name}</p>
                                          </div>
                                          <div className='info-div d-flex'>
                                            <FontAwesomeIcon icon="fa-solid fa-star" />
                                            <p>{card.star}</p>
                                          </div>
                                        </div>
                                        {/* <div className='col-xxl-5 col-xl-6 col-lg-6 col-md-12  col-12 d-flex counts'>
                                          <div >
                                            <p>Active</p>
                                            <p className='orange-text'>{card.counts.active}</p>
                                          </div>
                                          <div>
                                            <p>New</p>
                                            <p>{card.counts.new}</p>
                                          </div>
                                          <div>
                                            <p>Contacting</p>
                                            <p>{card.counts.contacting}</p>
                                          </div>
                                          <div>
                                            <p>Hired</p>
                                            <p>{card.counts.hired}</p>
                                          </div>
                                        </div> */}
                                        {/* <div className=' col-xxl-4 col-xl-12 col-lg-12 col-md-12 col-12 btns-div mt-2'>
                                          <button className='open-btn-inactive me-2'>Open (3)</button>
                                          <button className='paused-btn-inactive me-2'>Paused (3)</button>
                                          <button className='close-btn-inactive'>closed (3)</button> */}
                                        <div className=' col-xxl-12 col-12 btns-div mt-2'>
                                          {/* {jobStatusData?.map((jobstatus) => (
                                            <button
                                              key={jobstatus.id}
                                              className={`open-btn me-2 ${activeButtons[card.id] === jobstatus.id ? 'active' : ''}`}
                                              onClick={() => handleClick(card.id, jobstatus.id)}
                                            >
                                              {jobstatus?.name}
                                              {activeButtons[card.id] === jobstatus.id && '(Active)'}
                                            </button>
                                          ))} */}
                                          {jobStatusData?.map((jobstatus) => (
                                            <button
                                              key={jobstatus.id}
                                              className={`open-btn me-2 ${activeButtons[card.id] === jobstatus.id ? 'active' : ''}`}
                                              onClick={() => { handleClick(card.id, jobstatus.id); statusCount(); }}
                                            >
                                              {jobstatus?.name}
                                              {activeButtons[card.id] === jobstatus.id && '(Active)'}
                                            </button>
                                          ))}
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                                <div className='text-center'>
                                  <button className='show-more-btn' onClick={handleTogglelist}>
                                    {showMorelist ? 'Show Less' : 'Show More'}
                                    <FontAwesomeIcon icon={showMorelist ? faAngleUp : faAngleDown} className='ms-3' />
                                  </button>
                                </div>
                              </Tab.Pane>
                              {/* list view section end */}
                            </Tab.Content>
                          </div>
                          {/* cards colm sec end */}
                          {/* search job colm sec start */}
                          <div className='col-xl-3 col-lg-4 col-md-6 col-12'>
                            <div className='search-job-sec'>
                              <div className='search-heading'>
                                <h4 className='search'>Search Posted Job</h4>
                              </div>
                              <div className='bottom-sec'>
                                <div >
                                  <Form.Label>Search By Keywords</Form.Label>
                                  <div className=' search-select-to'>
                                    {/* <Select placeholder="Job Title" options={options1} /> */}
                                    {/* <Controller
                                      name="job_title_id"
                                      {...register("job_title_id", {
                                        required: "job_title_id required",
                                      })}
                                      control={control}
                                      render={({ field }) => (
                                        <Select
                                          styles={{
                                            control: (baseStyles) => ({
                                              ...baseStyles,
                                              borderColor: errors.job_title_id
                                                ? "red"
                                                : baseStyles,
                                            }),
                                          }}
                                          {...field}
                                          options={jobTitle}
                                          onChange={val => {
                                            field.onChange(val);
                                            setSearchJobId(val);
                                            getManageJobs();
                                          }}
                                        />
                                      )}
                                    /> */}
                                    <Select
                                      isSearchable
                                      options={jobTitle}
                                      value={searchJobId}
                                      placeholder="Job Title"
                                      onChange={(e) => {
                                        setSearchJobId(e);
                                      }}
                                    />
                                    <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" className='fa-icc me-2' />
                                  </div>
                                </div>
                                <div >
                                  <Form.Label>Search By Location</Form.Label>
                                  <div className=' search-select-to'>
                                    {/* <Select placeholder="City or postcode " options={options2} /> */}
                                    {/* <Controller
                                      name="city_id"
                                      {...register("city_id", {
                                        required: "city_id required",
                                      })}
                                      control={control}
                                      render={({ field }) => (
                                        <Select
                                          styles={{
                                            control: (baseStyles) => ({
                                              ...baseStyles,
                                              borderColor: errors.city_id
                                                ? "red"
                                                : baseStyles,
                                            }),
                                          }}
                                          {...field}
                                          options={city}
                                          onChange={val => {
                                            field.onChange(val);
                                            setSearchCity(val?.value);
                                            getManageJobs();
                                          }}
                                        />
                                      )}
                                    /> */}
                                    <Select
                                      isSearchable
                                      options={city}
                                      value={searchCity}
                                      placeholder="City"
                                      onChange={(e) => {
                                        setSearchCity(e);
                                      }}
                                    />
                                    <FontAwesomeIcon icon="fa-solid fa-location-dot" className='fa-icc me-2' />
                                  </div>
                                </div>
                                <div >
                                  <Form.Label>Sort By</Form.Label>
                                  <div className=' search-select-to'>
                                    {/* <Select placeholder="Posting Date " options={options3} /> */}
                                    <div>
                                      <Form.Control
                                        type="date"
                                        placeholder="Enter "
                                        name="date"

                                        {...register("date", {
                                          // required: "deadline required",
                                        })}
                                        className={classNames("", {
                                          "is-invalid": errors?.deadline,
                                        })}
                                        onChange={(e) => {
                                          setDateValue(e.target.value);
                                          // getManageJobs();
                                        }}
                                      />
                                    </div>
                                    <FontAwesomeIcon icon="fa-solid fa-calendar-days" className='fa-icc me-2' />
                                  </div>
                                </div>
                                <div >
                                  <Form.Label>Order</Form.Label>
                                  <div className=' search-select-to'>
                                    {/* <Select placeholder="Ascending " options={options4} /> */}
                                    {/* <Controller
                                      name="order"
                                      {...register("order", {
                                        // required: "city_id required",
                                      })}
                                      control={control}
                                      render={({ field }) => (
                                        <Select
                                          styles={{
                                            control: (baseStyles) => ({
                                              ...baseStyles,
                                              borderColor: errors.order
                                                ? "red"
                                                : baseStyles,
                                            }),
                                          }}
                                          {...field}
                                          options={options4}
                                          onChange={val => {
                                            field.onChange(val);
                                            setOrderValue(val?.value);
                                            getManageJobs();
                                          }}
                                        />
                                      )}
                                    /> */}
                                    <Select
                                      isSearchable
                                      options={options4}
                                      value={orderTitle}
                                      placeholder="Order"
                                      onChange={(e) => {
                                        setOrderValue(e);
                                      }}
                                    />
                                    <FontAwesomeIcon icon="fa-solid fa-calendar-days" className='fa-icc me-2' />
                                  </div>
                                </div>
                              </div>
                              <div className='manage-banner mt-5'>
                                <div>
                                  <img className='banner-img' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/employer/dashboard/bann.png'} />
                                  <div className='overlay-color' />
                                </div>
                                <div className='overlay-text'>
                                  <h4>Acquire New Talent Efficiently!</h4>
                                  <p>Wondering How to Acquire New Talent? </p>
                                  <p>Here are the efficient tips guide for you...</p>
                                  <Link to="/job-portal/blog"><button className='know-more-btn'>Know More</button></Link>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* search job colm sec end */}
                        </div>
                      </div>
                    </div>
                  </Tab.Container>
                </div>
              </>
            ) : (
              <>
                <div className='Draft-page'>

                  <div className='top-div'>
                    <Link onClick={() => setDraftdata(false)}>Back to Manage Jobs</Link>
                    <div className='heading'>
                      <h3 >
                        Drafted Job/s
                      </h3>
                    </div>
                  </div>
                  {draftJobData?.rows?.map((data) => (
                    <div className='draft-card'>
                      <div className='applicant-card'>
                        <div className='upper-div'>
                          <h6 className='graphic-text mt-2'>{data?.job_title?.name}</h6>
                          <div className='d-flex counts justify-content-md-end justify-content-sm-start justify-content-start'>
                            <div className='exclamatry-bg'>
                              <FontAwesomeIcon icon="fa-solid fa-exclamation" />
                            </div>
                            <p className='mt-2 me-0'>Your job posting is incomplete</p>

                          </div>
                        </div>
                        <div className='row my-2'>
                          <div className=' col-lg-6 col-md-6 col-12'>
                            <div className=' info-div d-flex'>
                              <FontAwesomeIcon icon="fa-solid fa-calendar-days" />
                              <p>Posted on {formatDate(data?.updatedAt)}</p>
                            </div>
                            <div className='info-div d-flex'>
                              <FontAwesomeIcon icon="fa-solid fa-location-dot" />
                              <p>{data?.city?.name}</p>
                            </div>
                          </div>
                          <div className=' col-lg-6 col-md-6 col-12 text-end'>
                            <div className='info-div d-flex justify-content-md-end justify-content-sm-start justify-content-start mt-3'>
                              <button className='finish-btn' onClick={() => handleEditStepJobDetails(data?.id)}>Finish job posting</button>
                              <div className='trash' onClick={() => deleteJOB(data?.id)}>
                                <FontAwesomeIcon icon="fa-solid fa-trash" />
                              </div>

                              {/* <LogoutModal
                              show={modalShow}
                              onHide={() => setModalShow(false)}
                              text={"Are you sure, you want to delete job?"}
                              heading={"Delete Draft"}
                              imgSource={"/assets/Job-portal/Images/employer/Logout/deleteaward.png"}
                            /> */}
                              <Delete_JobModel
                                show={modalShow}
                                jobID={jobID}
                                onHide={() => setModalShow(false)}
                                text={"Are you sure, you want to delete job?"}
                                heading={"Delete Job"}
                                setModalShow={setModalShow}
                                setModalReset={setModalReset}
                                imgSource={"/assets/Job-portal/Images/employer/Logout/deleteaward.png"}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )
          }
        </div>
        <ResetModal
          show={modalreset}
          onHide={() => setModalReset(false)}
          text={"Job Deleted Successfully"}
        />
      </section>
    </>
  );
}

export default ManageTab;
