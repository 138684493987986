import React, { useEffect, useState, useContext } from "react";

import '../JPblog-innerpage/JpBlogInner.css'
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import ResetModal from '../../JP-Common-Elements/Jp-modals/ResetModal/ResetModal';
import { Context } from "../../../../utils/context.js";
import { param } from "jquery";
import { useParams } from "react-router-dom";
import { getBlog, allState, allCourse, selectedCity } from "../../../../utils/apis/common/Common.js";

const JPBlogInnerpage = () => {
    const [modalShow, setModalShow] = useState(false);
    const openModalWithTimeout = () => {
        setModalShow(true);
        setTimeout(() => {
            window.location.href = '/job-portal/blog/innerpage';
        }, 3000);
    };

    const { getData, IMG_URL } = useContext(Context);

    const [blogs, setBlog] = useState();

    const [state, setState] = useState();

    const [course, setCourse] = useState();

    const { id } = useParams();

    const getproject = async () => {

        const res = await getBlog();
        if (res?.success) {
            setBlog(res.data)
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    const getState = async () => {

        const res = await allState();
        if (res?.success) {
            setState(res.data)
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    const getCourse = async () => {

        const res = await allCourse();
        if (res?.success) {
            setCourse(res.data)
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getproject();
        getState();
        getCourse();
    }, []);

    function isToday(someDate) {
        const today = new Date();
        return someDate.getDate() === today.getDate() &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear();
    }
    function chooseCity(selectedValue) {
        console.log(selectedValue, 'sfdgfdgfdg');
    }
    return (
        <>
            <section className='jpblog-innerpage'>
                <div className='container'>
                    <div className='row'>
                        <Link to='/job-portal/blog'>
                            <div className="BacktoCompanies">
                                <p className="">Back to Blog</p>
                            </div>
                        </Link>
                        <div className=' col-lg-7'>
                            <div className='image-holder mb-4'>
                                <img className='bannn-img1' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/blog/blog-main.png'} />
                            </div>
                            <div className='text-holder mb-3'>
                                <h6>Strategies for e-Learning Professionals to Maximize Employee Learning Time</h6>
                                <p>Uploaded : Today</p>
                            </div>
                        </div>
                        <div className=' col-lg-5'>
                            {blogs?.map((data, index) => (
                                <div key={index} className='row'>
                                    {index !== 0 ? (
                                        <>
                                            <div className='col-md-5 col-sm-5'>
                                                <div className='image-holder mb-3'>
                                                    <img className='update-img'
                                                        src={IMG_URL + data?.image} />
                                                </div>
                                            </div>
                                            <div className='col-md-7 col-sm-7'>
                                                <div className='update-content mb-3'>
                                                    <h5>{data?.title}</h5>
                                                    <p>Uploaded: {isToday(new Date(data?.createdAt)) ? "Today" : new Date(data?.createdAt).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: '2-digit' })}</p>
                                                </div>
                                            </div>
                                        </>
                                    ) : null}
                                </div>
                            ))}
                        </div>
                        <div className='col-lg-7 '>
                            <div className='paragraph-holder mt-4'>
                                <p>Learning online through different mediums and mentors has become a new normal post-COVID-19.
                                    The new normal in education is the increased use
                                    of online learning tools,
                                    along with the study materials available in form of pdf, videos, online lectures, tutorials, etc.</p>

                                <p>Learning online through different mediums and mentors has become a new normal post-COVID-19.
                                    The new normal in education is the increased use of online learning tools, along with the study materials
                                    available in form of pdf, videos, online lectures, tutorials, etc....
                                </p>

                                <p>Learning online through different mediums and mentors has become a new normal post-COVID-19.
                                    The new normal in education is the increased use of online learning tools, along with the study
                                    materials available in form of pdf, videos, online lectures, tutorials, etc....</p>

                                <p>Learning online through different mediums and mentors has become a new normal post-COVID-19.
                                    The new normal in education is the increased use of online learning tools,
                                    along with the study materials available in form of pdf, videos, online lectures, tutorials, etc.</p>

                                <p>Learning online through different mediums and mentors has become a new normal post-COVID-19. The new normal in
                                    education is the increased use of online learning tools,
                                    along with the study materials  available in form of pdf, videos, online lectures, tutorials, etc....</p>

                                <p>Learning online through different mediums and mentors has become a new normal post-COVID-19.
                                    The new normal in education is the increased use of online learning tools, along with the study materials
                                    available in form of pdf, videos, online lectures, tutorials, etc....</p>

                                <p>Learning online through different mediums and mentors has become a new normal post-COVID-19.
                                    The new normal in education is the increased use of online learning tools,
                                    along with the study materials available in form of pdf, videos, online lectures, tutorials, etc.</p>

                                <p>Learning online through different mediums and mentors has become a new normal post-COVID-19.
                                    The new normal in education is the increased use of online learning tools, along with the study materials
                                    available in form of pdf, videos, online lectures, tutorials, etc....
                                </p>
                                <p>Learning online through different mediums and mentors has become a new normal post-COVID-19.
                                    The new normal in education is the increased use of online learning tools, along with the study materials
                                    available in form of pdf, videos, online lectures, tutorials, etc....</p>
                            </div>
                        </div>
                        <div className='col-lg-5'>

                            <div className='requst-form mt-4'>
                                <div className='boder-line'></div>
                                <Form className='p-md-3 p-2'>
                                    <div className='heading-holder text-center'>
                                        <h4>Request a Call Back</h4>
                                    </div>
                                    <Form.Group className="mb-3" controlId="formGroupEmail">
                                        <Form.Control type="text" placeholder="Name" />
                                    </Form.Group>
                                    <Form.Group className="mb-3" controlId="formGroupPassword">
                                        <Form.Control type="email" placeholder="E-mail Address" />
                                    </Form.Group>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <Form.Group className="mb-3" controlId="formGroupnumber">
                                                <Form.Control type="text" placeholder="Phone Number" />
                                            </Form.Group>
                                        </div>
                                        <div className='col-md-6'>
                                            <Form.Select aria-label="Default select example">
                                                <option value="">Course</option>
                                                {course?.map((data) => (
                                                    <option key={data?.id} value={data?.id}>{data?.name}</option>
                                                ))}
                                                {/* <option value="1"></option>
                                                <option value="2"></option>
                                                <option value="3"></option> */}
                                            </Form.Select>
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <Form.Select aria-label="Default select example" onChange={(e) => chooseCity(e.target.value)}>
                                                <option value="">State</option>
                                                {state?.map((data) => (
                                                    <option key={data?.id} value={data?.id}>{data?.name}</option>
                                                ))}
                                            </Form.Select>
                                        </div>
                                        <div className='col-md-6'>
                                            <Form.Select aria-label="Default select example">
                                                <option>City</option>
                                                <option value="1"></option>
                                                <option value="2"></option>
                                                <option value="3"></option>
                                            </Form.Select>
                                        </div>
                                    </div>
                                    <div className='button-holder text-center mt-5'>
                                        <Button variant="primary" onClick={openModalWithTimeout}>
                                            Submit
                                        </Button>
                                    </div>

                                    <ResetModal
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                        text={"Your Request has been Sent Successfully!"}
                                    />
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default JPBlogInnerpage