import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom"; // Import useLocation hook
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import "../Jobs_Job_Filter/JobsJobFilter.css";
import { Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../../../utils/context";
import { careerlevel, jobType } from "../../../../../utils/apis/common/Common";
import { useNavigate } from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

library.add(fas);

function JobsJobFilter() {
  const navigate = useNavigate();
  // Use useLocation hook to get the current path
  const location = useLocation();
  const { getData, IMG_URL } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    setValue
  } = useForm();

  // Define initial state and handler for distance slider
  const [distance, setDistance] = useState(0);
  const handleDistanceChange = (value) => {
    setDistance(value);
  };

  // Define initial state and handler for salary slider
  const [level, setLevel] = useState();
  const handleCompanyChange = (event, id) => {
    const companyIdNumber = Number(id);
    const isChecked = event.target.checked;

    if (isChecked) {
      setLevel(prev => [...prev, companyIdNumber]);
    } else {
      setLevel(prev => prev.filter(item => item !== companyIdNumber));
    }
  };
  const [getJobType, setJobType] = useState();
  const handleJobTypeChange = (event, id) => {
    const companyIdNumber = Number(id);
    const isChecked = event.target.checked;

    if (isChecked) {
      setJobType(prev => [...prev, companyIdNumber]);
    } else {
      setJobType(prev => prev.filter(item => item !== companyIdNumber));
    }
  };

  const getAllJobType = async () => {
    const res = await jobType();
    if (res?.success) {
      setJobType(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const getproject = async () => {
    const res = await careerlevel();
    if (res?.success) {
      setLevel(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const onSubmit = (data) => {
    console.log(data, "kkkkkkkkkkkkkkk");
    const selectedCategory = data.category;
    const city = data.city;
    const date = data.date;
    const minSalary = data.minSalary;
    const maxSalary = data.maxSalary;
    const experienceNumber = data.experienceNumber;
    const min_experience = data.min_experience;
    const max_experience = data.max_experience;
    const title = data.title;
    const selectedJobType = data.jobType;

    // Construct query parameters
    const queryParams = new URLSearchParams({
      selectedCategory: selectedCategory,
      city: city,
      date: date,
      experienceNumber: experienceNumber,
      min_experience: min_experience,
      max_experience: max_experience,
      title: title,
      minSalary: minSalary,
      maxSalary: maxSalary,
      selectedJobType: selectedJobType,

    }).toString();

    // Navigate with query parameters
    navigate(`./?${queryParams}`);
  }

  useEffect(() => {
    getproject();
    getAllJobType();
  }, []);

  const minDistance = 0;
  const maxDistance = 500;

  const [value, setPagar] = useState({ min: 0, max: 100 });
  const handleChange = (newValue) => {
    setPagar(newValue);
  };

  const [salary, setSalary] = useState(0);

  const [minSalary, setMinSalary] = useState(0);
  const [maxSalary, setMaxSalary] = useState(500);
  const handleSalaryChange = (value) => {
    setMinSalary(value?.min);
    setMaxSalary(value?.max);
  };
  // const minSalary = 0;
  // const maxSalary = 500;

  useEffect(() => {
    console.log("minSalary", minSalary);
  }, [minSalary]);

  const categories = [
    { id: "Unicorn", label: "Unicorn" },
    { id: "MNC", label: "MNC" },
    { id: "Startup", label: "Startup" },
    { id: "Product_based", label: "Product based" },
    { id: "Service_Based", label: "Service Based" },
  ];

  const JobType = [
    { id: "Full_Time", label: "Full Time" },
    { id: "Part_Time", label: "Part Time" },
    { id: "Internship", label: "Internship" },
    { id: "Freelance", label: "Freelance" },
  ];

  const PostedDate = [
    { id: "all", label: "All" },
    { id: "last_hour", label: "Last Hours" },
    { id: "7_days", label: "Last 7 Days" },
    { id: "14_days", label: "Last 14 Days" },
    { id: "30_days", label: "Last 30 Days" },
  ];

  const Company = [
    { id: "Unicorn", label: "Unicorn" },
    { id: "MNC", label: "MNC" },
    { id: "Startup", label: "Startup" },
    { id: "Product_based", label: "Product based" },
    { id: "Service_Based", label: "Service Based" },
  ];

  const [selectedExperience, setSelectedExperience] = useState(null);
  const Experience_Level = [
    { id: "Fresher", label: "Fresher", min_experience: 0, max_experience: 1 },
    { id: "1_2_Yrs", label: "1 - 2 Yrs", min_experience: 1, max_experience: 2 },
    { id: "2_3_Yrs", label: "2 - 3 Yrs", min_experience: 2, max_experience: 3 },
    { id: "3_4_Yrs", label: "3 - 4 Yrs", min_experience: 3, max_experience: 4 },
  ];

  const handleExperienceLevelChange = (levelId) => {
    const level = Experience_Level.find(level => level.id === levelId);
    if (level) {
      setSelectedExperience(levelId);
      setValue('min_experience', level.min_experience);
      setValue('max_experience', level.max_experience);
    }
  };

  const Location = [
    { id: "Banglore", label: "Banglore" },
    { id: "Chennai", label: "Chennai" },
    { id: "Mumbai", label: "Mumbai" },
    { id: "Pune", label: "Pune" },
    { id: "Thane", label: "Thane" },
    { id: "Vasai", label: "Vasai" },
  ];

  const [showAllLocations, setShowAllLocations] = useState(false);

  const handleShowMoreLocations = () => {
    setShowAllLocations(true);
  };

  return (
    <section className="Job_filter">
      <div className="Job_filter_content">
        <div className="heading-holder">
          <h6>Job Filter</h6>
        </div>

        <div className="form-content">
          {/* for job page job filter */}
          {location.pathname === "/job-portal/job" && (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <>
                {/* Search by Keywords field */}
                <div className="">
                  <Form.Group>
                    <Form.Label>Search by Keywords</Form.Label>

                    <div className="form-control-holder">
                      <Form.Control
                        type="text"
                        name="Search"
                        placeholder="Job Title, Keywords or Comapny"
                        {...register('title')}
                      />
                      <div className="icon-holder">
                        <FontAwesomeIcon
                          className="font-icon"
                          icon="fa-solid fa-magnifying-glass"
                        />
                      </div>
                    </div>
                  </Form.Group>
                </div>

                {/*City or Location  field */}
                <div className="form-field ">
                  <Form.Group>
                    <Form.Label>Location</Form.Label>
                    <div className="form-control-holder">
                      <Form.Control
                        type="text"
                        name="Search"
                        placeholder="City or Location"
                        {...register('city')}
                      />
                      <div className="icon-holder">
                        <FontAwesomeIcon
                          className="font-icon"
                          icon="fa-solid fa-location-dot"
                        />
                      </div>
                    </div>
                  </Form.Group>
                </div>

                {/* distance rang slider  */}
                <div className="form-field">
                  <Form.Group>
                    <div className="range-value-holder">
                      <span>0 km</span>
                      <span>100 km</span>
                    </div>
                    <RangeSlider
                      value={distance}
                      onChange={handleDistanceChange}
                      min={minDistance}
                      max={maxDistance}
                      className=""

                    />
                  </Form.Group>
                </div>

                {/* Category field */}
                <div className="form-field check_box">
                  <Form.Group>
                    <Form.Label>Category</Form.Label>
                    <div>
                      {/* {categories.map((category) => (
                      <Form.Check
                        key={category.id}
                        type="checkbox"
                        id={category.id}
                        label={category.label}
                        name={category.id}
                      />
                    ))} */}
                      {level?.map((category, index) => (
                        <Form.Check
                          key={index}
                          value={category?.id}
                          type="checkbox"
                          id={category?.id}
                          label={category?.name}
                          name={category?.id}
                          onChange={(event) => handleCompanyChange(event, category?.id)}
                          {...register('category')}
                        />
                      ))}
                    </div>
                  </Form.Group>
                </div>

                {/* Job Type field */}
                <div className="form-field switch_box">
                  <Form.Group>
                    <Form.Label>Job Type</Form.Label>
                    <div>
                      {getJobType?.map((jobType, index) => (
                        <Form.Check
                          key={index}
                          value={jobType?.id}
                          type="switch"
                          id={jobType?.id}
                          label={jobType?.name}
                          name={jobType?.id}
                          onChange={(event) => handleJobTypeChange(event, jobType?.id)}
                          {...register('jobType')}
                        />
                      ))}
                    </div>
                  </Form.Group>
                </div>

                {/* Posted Date field  */}
                <div className="form-field radio_box">
                  <Form.Group>
                    <Form.Label>Posted Date</Form.Label>
                    <div>
                      {PostedDate.map((PostedDate) => (
                        <Form.Check
                          key={PostedDate?.id}
                          value={PostedDate?.id}
                          type="radio"
                          id={PostedDate?.id}
                          label={PostedDate?.label}
                          name={PostedDate?.id}
                          {...register('date')}
                        />
                      ))}
                    </div>
                  </Form.Group>
                </div>

                {/* Experience Level field */}
                <div className="form-field switch_box">
                  <Form.Group>
                    <Form.Label>Experience Level</Form.Label>
                    <div>
                      {Experience_Level?.map((level, index) => (
                        <Form.Check
                          key={index}
                          type="switch"
                          id={level?.id}
                          value={selectedExperience === level?.id ? level?.max_experience : level?.min_experience}
                          label={level?.label}
                          checked={selectedExperience === level?.id}
                          onChange={() => handleExperienceLevelChange(level?.id)}
                        />
                      ))}

                    </div>
                  </Form.Group>
                </div>

                {/* Search by Experience field  */}
                <div className="form-field">
                  <Form.Group>
                    <div className="form-control-holder">
                      <Form.Control
                        type="text"
                        name="Search"
                        placeholder="Search by Experience"
                        {...register('experienceNumber')}
                      />
                      <div className="icon-holder">
                        <FontAwesomeIcon
                          className="font-icon"
                          icon="fa-solid fa-magnifying-glass"
                        />
                      </div>
                    </div>
                  </Form.Group>
                </div>

                {/* Salary Range slider field */}
                <div className="form-field">
                  <Form.Group>
                    <Form.Label> Salary Range</Form.Label>

                    {/* <RangeSlider
                      value={salary}
                      onChange={handleSalaryChange}
                      min={minSalary}
                      max={maxSalary}
                    />
                    <div className="Salary_Range">
                      <p className="text">Selected Range: Min: {minSalary} & Max: {maxSalary} Lac (in ₹)</p>
                    </div> */}

                    <InputRange
                      minValue={0}
                      maxValue={500}
                      value={value}
                      onChange={handleChange}
                    />
                    <div className="Salary_Range">
                      <p className="text">Min: {value.min} & Max: {value.max}</p>
                    </div>
                    <input type="hidden" {...register('minSalary', { value: value.min })} />
                    <input type="hidden" {...register('maxSalary', { value: value.max })} />
                  </Form.Group>
                </div>

                {/*  Submit button */}
                <div className="submit-btn">
                  <Button className="submit" type="submit">
                    Submit
                  </Button>
                </div>
              </>
            </Form>
          )}

          {/* for company page job filter */}
          {location.pathname === "/job-portal/company" && (
            <>
              {/* Type of Company field */}
              <Form className="check_box">
                <Form.Group>
                  <Form.Label>Type of Company</Form.Label>
                  <div>
                    {Company.map((Company) => (
                      <Form.Check
                        key={Company.id}
                        type="checkbox"
                        id={Company.id}
                        label={Company.label}
                        name={Company.id}
                      />
                    ))}
                  </div>
                </Form.Group>
              </Form>

              {/* Search a Location field */}
              <Form className="form-field">
                <Form.Group>
                  <Form.Label>Location</Form.Label>

                  <div className="form-control-holder">
                    <Form.Control
                      type="text"
                      name="Search"
                      placeholder="Search a Location"
                    />
                    <div className="icon-holder">
                      <FontAwesomeIcon
                        className="font-icon"
                        icon="fa-solid fa-location-dot"
                      />
                    </div>
                  </div>

                  <div className="form-field location-scroll check_box">
                    {Location.map((Location, index) => (
                      <Form.Check
                        key={Location.id}
                        type="checkbox"
                        id={Location.id}
                        label={Location.label}
                        name={Location.id}
                        style={{
                          display:
                            showAllLocations || index < 4 ? "block" : "none",
                        }}
                      />
                    ))}
                    {!showAllLocations && (
                      <div className="">
                        <p
                          className="show-more"
                          onClick={handleShowMoreLocations}
                        >
                          +{Location.length - 4} more
                        </p>
                      </div>
                    )}
                  </div>
                </Form.Group>
              </Form>

              {/* Experience Level field */}
              <Form className="form-field switch_box">
                <Form.Group>
                  <Form.Label>Experience Level</Form.Label>
                  <div>
                    {Experience_Level.map((Experience_Level) => (
                      <Form.Check
                        key={Experience_Level.id}
                        type="switch"
                        id={Experience_Level.id}
                        label={Experience_Level.label}
                        name={Experience_Level.id}
                      />
                    ))}
                  </div>
                </Form.Group>
              </Form>

              {/* Search by Experience field */}
              <Form className="form-field">
                <Form.Group>
                  <div className="form-control-holder">
                    <Form.Control
                      type="text"
                      name="Search"
                      placeholder="Search by Experience"
                    />
                    <div className="icon-holder">
                      <FontAwesomeIcon
                        className="font-icon"
                        icon="fa-solid fa-magnifying-glass"
                      />
                    </div>
                  </div>
                </Form.Group>
              </Form>

              {/* Salary Range slider field */}
              <Form className="form-field">
                <Form.Group>
                  <Form.Label> Salary Range</Form.Label>

                  <RangeSlider
                    value={salary}
                    onChange={handleSalaryChange}
                    min={minSalary}
                    max={maxSalary}
                  />
                  <div className="Salary_Range">
                    <p className="text">{salary} Lac (in ₹)</p>
                  </div>
                </Form.Group>
              </Form>

              {/* Submit button */}
              <Form className="submit-btn">
                <Button className="submit" type="button">
                  Submit
                </Button>
              </Form>
            </>
          )}

          {/* for company job page job filter  */}
          {location.pathname === "/job-portal/company/Company-Job" && (
            <>
              {/* Search by Keywords field */}
              <Form className="">
                <Form.Group>
                  <Form.Label>Search by Keywords</Form.Label>
                  <div className="form-control-holder">
                    <Form.Control
                      type="text"
                      name="Search"
                      placeholder="Job Title, Keywords or Comapny"
                    />
                    <div className="icon-holder">
                      <FontAwesomeIcon
                        className="font-icon"
                        icon="fa-solid fa-magnifying-glass"
                      />
                    </div>
                  </div>
                </Form.Group>
              </Form>

              {/*City or Location  field */}
              <Form className="form-field ">
                <Form.Group>
                  <Form.Label>Location</Form.Label>
                  <div className="form-control-holder">
                    <Form.Control
                      type="text"
                      name="Search"
                      placeholder="City or Location"
                    />
                    <div className="icon-holder">
                      <FontAwesomeIcon
                        className="font-icon"
                        icon="fa-solid fa-location-dot"
                      />
                    </div>
                  </div>
                </Form.Group>
              </Form>

              {/* distance rang slider  */}
              <Form className="form-field">
                <Form.Group>
                  <div className="range-value-holder">
                    <span>0 km</span>
                    <span>100 km</span>
                  </div>
                  <RangeSlider
                    value={distance}
                    onChange={handleDistanceChange}
                    min={minDistance}
                    max={maxDistance}
                  />
                </Form.Group>
              </Form>

              {/* Category field */}
              <Form className="form-field check_box">
                <Form.Group>
                  <Form.Label>Category</Form.Label>
                  <div>
                    {/* {categories.map((category) => (
                      <Form.Check
                        key={category.id}
                        type="checkbox"
                        id={category.id}
                        label={category.label}
                        name={category.id}
                      />
                    ))} */}

                    {level.map((category) => (
                      <Form.Check
                        key={category?.id}
                        type="checkbox"
                        id={category?.id}
                        label={category?.name}
                        name={category?.id}
                      />
                    ))}
                  </div>
                </Form.Group>
              </Form>

              {/* Job Type field */}
              <Form className="form-field switch_box">
                <Form.Group>
                  <Form.Label>Job Type</Form.Label>
                  <div>
                    {JobType.map((JobType) => (
                      <Form.Check
                        key={JobType.id}
                        type="switch"
                        id={JobType.id}
                        label={JobType.label}
                        name={JobType.id}
                      />
                    ))}
                  </div>
                </Form.Group>
              </Form>

              {/* Posted Date field  */}
              <Form className="form-field radio_box">
                <Form.Group>
                  <Form.Label>Posted Date</Form.Label>
                  <div>
                    {PostedDate.map((PostedDate) => (
                      <Form.Check
                        key={PostedDate.id}
                        type="radio"
                        id={PostedDate.id}
                        label={PostedDate.label}
                        name={PostedDate.id}
                      />
                    ))}
                  </div>
                </Form.Group>
              </Form>

              {/* Experience Level field */}
              <Form className="form-field switch_box">
                <Form.Group>
                  <Form.Label>Experience Level</Form.Label>
                  <div>
                    {Experience_Level.map((Experience_Level) => (
                      <Form.Check
                        key={Experience_Level.id}
                        type="switch"
                        id={Experience_Level.id}
                        label={Experience_Level.label}
                        name={Experience_Level.id}
                      />
                    ))}
                  </div>
                </Form.Group>
              </Form>

              {/* Search by Experience field  */}
              <Form className="form-field">
                <Form.Group>
                  <div className="form-control-holder">
                    <Form.Control
                      type="text"
                      name="Search"
                      placeholder="Search by Experience"
                    />
                    <div className="icon-holder">
                      <FontAwesomeIcon
                        className="font-icon"
                        icon="fa-solid fa-magnifying-glass"
                      />
                    </div>
                  </div>
                </Form.Group>
              </Form>

              {/* Salary Range slider field */}
              <Form className="form-field">
                <Form.Group>
                  <Form.Label> Salary Range</Form.Label>

                  <RangeSlider
                    value={salary}
                    onChange={handleSalaryChange}
                    min={minSalary}
                    max={maxSalary}
                  />
                  <div className="Salary_Range">
                    <p className="text">{salary} Lac (in ₹)</p>
                  </div>
                </Form.Group>
              </Form>

              {/*  Submit button */}
              <Form className="submit-btn">
                <Button className="submit" type="submit">
                  Submit
                </Button>
              </Form>
            </>
          )}

          {/* for job all search page job filter  */}
          {location.pathname === "/job-portal/search/all-jobs-search" && (
            <>
              {/* Search by Keywords field */}
              <Form className="">
                <Form.Group>
                  <Form.Label>Search by Keywords</Form.Label>
                  <div className="form-control-holder">
                    <Form.Control
                      type="text"
                      name="Search"
                      placeholder="Job Title, Keywords or Comapny"
                    />
                    <div className="icon-holder">
                      <FontAwesomeIcon
                        className="font-icon"
                        icon="fa-solid fa-magnifying-glass"
                      />
                    </div>
                  </div>
                </Form.Group>
              </Form>

              {/*City or Location  field */}
              <Form className="form-field ">
                <Form.Group>
                  <Form.Label>Location</Form.Label>
                  <div className="form-control-holder">
                    <Form.Control
                      type="text"
                      name="Search"
                      placeholder="City or Location"
                    />
                    <div className="icon-holder">
                      <FontAwesomeIcon
                        className="font-icon"
                        icon="fa-solid fa-location-dot"
                      />
                    </div>
                  </div>
                </Form.Group>
              </Form>

              {/* distance rang slider  */}
              <Form className="form-field">
                <Form.Group>
                  <div className="range-value-holder">
                    <span>0 km</span>
                    <span>100 km</span>
                  </div>
                  <RangeSlider
                    value={distance}
                    onChange={handleDistanceChange}
                    min={minDistance}
                    max={maxDistance}
                  />
                </Form.Group>
              </Form>

              {/* Category field */}
              <Form className="form-field check_box">
                <Form.Group>
                  <Form.Label>Category</Form.Label>
                  <div>
                    {categories.map((category) => (
                      <Form.Check
                        key={category.id}
                        type="checkbox"
                        id={category.id}
                        label={category.label}
                        name={category.id}
                      />
                    ))}
                  </div>
                </Form.Group>
              </Form>

              {/* Job Type field */}
              <Form className="form-field switch_box">
                <Form.Group>
                  <Form.Label>Job Type</Form.Label>
                  <div>
                    {JobType.map((JobType) => (
                      <Form.Check
                        key={JobType.id}
                        type="switch"
                        id={JobType.id}
                        label={JobType.label}
                        name={JobType.id}
                      />
                    ))}
                  </div>
                </Form.Group>
              </Form>

              {/* Posted Date field  */}
              <Form className="form-field radio_box">
                <Form.Group>
                  <Form.Label>Posted Date</Form.Label>
                  <div>
                    {PostedDate.map((PostedDate) => (
                      <Form.Check
                        key={PostedDate.id}
                        type="radio"
                        id={PostedDate.id}
                        label={PostedDate.label}
                        name={PostedDate.id}
                      />
                    ))}
                  </div>
                </Form.Group>
              </Form>

              {/* Experience Level field */}
              <Form className="form-field switch_box">
                <Form.Group>
                  <Form.Label>Experience Level</Form.Label>
                  <div>
                    {Experience_Level.map((Experience_Level) => (
                      <Form.Check
                        key={Experience_Level.id}
                        type="switch"
                        id={Experience_Level.id}
                        label={Experience_Level.label}
                        name={Experience_Level.id}
                      />
                    ))}
                  </div>
                </Form.Group>
              </Form>

              {/* Search by Experience field  */}
              <Form className="form-field">
                <Form.Group>
                  <div className="form-control-holder">
                    <Form.Control
                      type="text"
                      name="Search"
                      placeholder="Search by Experience"
                    />
                    <div className="icon-holder">
                      <FontAwesomeIcon
                        className="font-icon"
                        icon="fa-solid fa-magnifying-glass"
                      />
                    </div>
                  </div>
                </Form.Group>
              </Form>

              {/* Salary Range slider field */}
              <Form className="form-field">
                <Form.Group>
                  <Form.Label> Salary Range</Form.Label>

                  <RangeSlider
                    value={salary}
                    onChange={handleSalaryChange}
                    min={minSalary}
                    max={maxSalary}
                  />
                  <div className="Salary_Range">
                    <p className="text">{salary} Lac (in ₹)</p>
                  </div>
                </Form.Group>
              </Form>

              {/*  Submit button */}
              <Form className="submit-btn">
                <Button className="submit" type="submit">
                  Submit
                </Button>
              </Form>
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default JobsJobFilter;
