import React, { useState, useEffect, useContext } from "react";
import "./Jpmakedifferencebanner.css";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Context } from "../../../../utils/context";
import parse from "html-react-parser";
import AOS from "aos";
import "aos/dist/aos.css";
const Jpmakediffrencebanner = () => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const getDataAll = async () => {
      try {
          const response = await getData(`/job-portal/home/banner-two`);
          setData(response?.data);
      } catch (error) {
          console.error("Error fetching data:", error);
      }
  };


  useEffect(() => {
      getDataAll();
      
  }, []);

  useEffect(() => {
    AOS.init({ once: true, disable: 'mobile' });
  }, []);


  return (
    <>
      <section className="Jpmakediffrencebanner-section">
        {data?.map((val)=>
          <div className="bg-imgg" data-aos="fade-up"
            data-aos-duration="2000">
          <img
            className="banner-img"
            // src={
            //   process.env.PUBLIC_URL +
            //   "/assets/Job-portal/Images/home/bg-banner/banner.png"
            // }
            src={
              IMG_URL +
             val?.image
            }
          />
          <div className="overlay-color"></div>

          <div className="container">
            <div className="overlay-content">
              <div>
                <div className="ban-t-div">
                  <h1 className="jpsect_title coloor">
                    <span className="spanbdd">{val?.title}</span> 
                    
                  </h1>
                  <p className="take-p">{parse(val?.description) || ""}</p>

                  <div className="yellow_create-Account_btn">
                    <div className="btn-holder">
                      <Link to={"/job-portal/register"}>
                        <Button className="Create_Account_btn">
                          Create Account
                        </Button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        )}
      </section>
    </>
  );
};

export default Jpmakediffrencebanner;
