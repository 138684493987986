import React, { useEffect, useRef, useState, useContext } from 'react';
import "react-international-phone/style.css";

import "./StepFourSelectPrimaryBankAccount.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Form, FormControl, Offcanvas } from "react-bootstrap";
import { Context } from '../../../../../../../utils/context';
import { useForm, Controller, SubmitHandler } from "react-hook-form";

function StepFourSelectPrimaryBankAccount({ setBank_id, loanFormDetail }) {

  useEffect(() => {
    reset(loanFormDetail);
    console.log("loanFormDetail form 4:-", loanFormDetail);
    setSelected(loanFormDetail?.bank_id?.value)
  }, [loanFormDetail]);

  const [selected, setSelected] = useState([false, false, false]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const options = [
    [
      {
        description: "HDFC",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/HDFC.png",
      },
      {
        description: "Kotak",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/Kotak.png",
      },
    ],
    [
      {
        description: "ICICI ",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/ICICI.png",
      },
      {
        description: "BOB",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/BOB.png",
      },
    ],
    [
      {
        description: "SBI",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/SBI.png",
      },
      {
        description: "Yes Bank",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/Yes_Bank.png",
      },
    ],
    [
      {
        description: "Axis",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/Axis.png",
      },
      {
        description: "BOI",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/BOI.png",
      },
    ],
  ];

  const optionsTwo = [
    [
      {
        description: "HDFC",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/HDFC.png",
      },
      {
        description: "Kotak",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/Kotak.png",
      },
      {
        description: "ICICI ",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/ICICI.png",
      },
      {
        description: "BOB",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/BOB.png",
      },
      {
        description: "SBI",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/SBI.png",
      },
      {
        description: "Yes Bank",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/Yes_Bank.png",
      },
      {
        description: "Axis",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/Axis.png",
      },
      {
        description: "BOI",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/BOI.png",
      },
      {
        description: "HDFC",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/HDFC.png",
      },
      {
        description: "Kotak",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/Kotak.png",
      },
      {
        description: "ICICI ",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/ICICI.png",
      },
      {
        description: "BOB",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/BOB.png",
      },
      {
        description: "SBI",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/SBI.png",
      },
      {
        description: "Yes Bank",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/Yes_Bank.png",
      },
      {
        description: "Axis",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/Axis.png",
      },
      {
        description: "BOI",
        logo: "/assets/Finance/Image/F-service/Home_loan_stepform/BOI.png",
      },
    ],
  ];

  const handleToggle = (index) => {
    const updatedSelected = Array(options.length).fill(false); // Initialize all options as unselected
    updatedSelected[index] = true; // Select the clicked option
    setSelected(updatedSelected);
  };

  const [modalShow, setModalShow] = useState(false);

  const [bank_id, selectedBankTypeID] = useState(null);
  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
  const [data, setData] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset
  } = useForm();


  console.log(bank_id, 'empppppp');
  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/master/all-banks`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  useEffect(() => {
    getDataAll();
  }, []);

  const handleOptionSelect = (id) => {
    console.log(id, "id_bank");
    setBank_id(id);
  };


  return (
    <>
      <div className="StepFourSelectPrimaryBankAccount ">
        <Form >
          <div className="row">
            {data?.map((bank, index) => (
              <div className="col-md-6" key={index}>
                {/* {bankGroup.map((bank, subIndex) => ( */}
                <div className="EmploymentType-content">
                  <div className="text-holder">
                    <div className="bank-logo-img">
                      <img
                        className="bank-logo"
                        src={IMG_URL + bank?.image}
                        alt="bank-logo"
                      />
                    </div>
                    <p>{bank?.name}</p>
                  </div>
                  <div className="circle-icon-holder">
                    <Form.Check
                      inline
                      name="bank_id"
                      type="radio"
                      value={bank_id}
                      // checked={selected === index}
                      {...register('bankType', { required: true })}
                      onChange={() => handleOptionSelect(bank.id)}
                      checked={selected === bank.id}
                    />
                  </div>
                </div>
                {/* ))} */}
              </div>
            ))}
          </div>
        </Form>

        <div className="view-more-btn">
          <p onClick={handleShow}>
            View More{" "}
            <FontAwesomeIcon
              className="right-arrow"
              icon="fa-solid fa-angle-right"
            />
            <FontAwesomeIcon
              className="right-arrow"
              icon="fa-solid fa-angle-right"
            />
            <FontAwesomeIcon
              className="right-arrow"
              icon="fa-solid fa-angle-right"
            />
          </p>
        </div>
      </div>

      <div className="side-off-sec ">
        <Offcanvas
          className="side-off-sec "
          placement="end"
          show={show}
          onHide={handleClose}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <div className="search-bar-input">
                <Form.Control
                  className="search-bar"
                  type="text"
                  placeholder="Search your bank"
                />
                <div className="search-icon-holder">
                  <FontAwesomeIcon
                    className="search-icon"
                    icon="fa-solid fa-magnifying-glass"
                  />
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="heading-holder">
              <h6>All Banks</h6>
            </div>
            <div className="StepFourSelectPrimaryBankAccount StepFour_offcanvas_sec">
              <div className="row me-0 ms-0">
                <div className="col-md-12 p-0">
                  <Form>
                    {data?.map((bank, index) => (
                      <div className="EmploymentType-content">
                        <div className="text-holder">
                          <div className="bank-logo-img">
                            <img
                              className="bank-logo"
                              src={IMG_URL + bank?.image}
                              alt="bank-logo"
                            />
                          </div>
                          <p>{bank.name}</p>
                        </div>
                        <div className="circle-icon-holder">
                          <Form.Check inline name="name" type="radio" />
                        </div>
                      </div>
                    ))}
                  </Form>
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
}

export default StepFourSelectPrimaryBankAccount;
