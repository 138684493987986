import React from "react";
import "./JphomeRegister.css";
import JpRegisterForm from "../JP-Common-Elements/Jp-Forms/JP-Register_form/JpRegisterForm";

function JpHomeRegister() {
  return (
    <>
      <section className="JpHomeRegisterform-sec">
        <div className="container">
          <div className="heading-holder">
            <div>
              <h1 className="jpsect_title">Register here as...</h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-9 col-md-9">
              <div className="jp-contact-form">
                <JpRegisterForm />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default JpHomeRegister;
