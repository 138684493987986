import React, { useState, useContext, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import '../EmpAccountManagement.css';
import ResetModal from '../../../../JP-Common-Elements/Jp-modals/ResetModal/ResetModal';
import "react-phone-input-2/lib/style.css";
import { Context } from '../../../../../../utils/context';
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";

const ChangeAccount = ({ toggleBack }) => {

  const [modalEmail, setModalEmail] = useState(false);
  const [emailerror, setemailErrors] = useState("");
  
  const { getData, postData, IMG_URL, signin, usertype, userdata, setUsertype } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  const onAccountChange = async (data) => {
    const user_id = userdata?.id;
    const res = await postData(`/job-portal/employer/setting/switch-account/${user_id}`, data)
    if (res?.success) {
      setModalEmail(true);
      setUsertype(res?.data?.users_type?.name);
      setTimeout(() => {
        setModalEmail(false);
        toggleBack();
      }, 3000)
    } else {
      setemailErrors(res?.message);
    }
  }
  console.log(usertype, "usertypeusertypeusertype");

  useEffect(() => {
  }, []);
  return (
    <>
      <Form className="form-field" onSubmit={handleSubmit(onAccountChange)}>
        <div className='account-type '>
          <div>
            <div className='row justify-content-center p-4'>
              {usertype === "Employer" ? (
                <div className='col-xxl-4 col-xl-4 col-md-4 col-6'>
                  <Form.Check
                    inline
                    type="radio"
                    label="Job Seeker"
                    name="radioGroup"
                    value="1"
                    id="1"
                    {...register("user_type_id", { required: "Please select a Account" })}
                  // {...register("user_type_id")}
                  />
                  <p className='ms-4'>(looking for a job)</p>
                  {/* <p className='ms-4'>(hiring, sourcing candidates, or advertising jobs)</p> */}
                </div>
              ) : (
                <div className='col-xxl-4 col-xl-4 col-md-4 col-6'>
                  <Form.Check
                    inline
                    type="radio"
                    label="Employer"
                    name="radioGroup"
                    value="2"
                    id="2"
                    {...register("user_type_id", { required: "Please select a Account" })}
                  />
                  {/* <p className='ms-4'>(looking for a job)</p> */}
                  <p className='ms-4'>(hiring, sourcing candidates, or advertising jobs)</p>
                </div>
              )}
              {errors.user_type_id && (
                <span className="text-danger">{errors.user_type_id.message}</span>
              )}
              {emailerror && (
                <span className="text text-danger">{emailerror}</span>
              )}
            </div>
          </div>
          <div className='buttons-div'>
            <button type='button' className='cancel-btn' onClick={toggleBack}>Cancel</button>
            <button type='submit' className='save-btn' >Save</button>
            {/* <button type='button' className='save-btn' onClick={() => navigate("/job-portal/sign-in")}>Save</button> */}
          </div>

          <ResetModal show={modalEmail}
            onHide={() => setModalEmail(false)}
            text={"Your Account has been Switched Successfully!"}
          />
        </div>
      </Form>
    </>
  );
}

export default ChangeAccount;
