import React, { useContext, useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation, } from "swiper/modules";
import "./FTestimonials.css";
import { Context } from "../../../../utils/context";
import AOS from "aos";
import "aos/dist/aos.css";
const FTestimonials = () => {
  const [swiperInstance, setSwiperInstance] = useState(null);
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/finance/home/f-client-satisfaction`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);

  useEffect(() => {
    getDataAll();
  }, []);

  useEffect(() => {
    AOS.init({ once: true });
  }, [])

  const TestimonialData = [
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Finance/Image/Homepage/FTestimonial/person3.png",
      name: "Paresh Kumar",
      content:
        "Profcyma Solutions made getting a loan a breeze! Their team's dedication and the easy-to-use online platform saved me time and stress. Highly recommended!",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Finance/Image/Homepage/FTestimonial/person2.png",
      name: "Shubham Kumar",
      content:
        "I'm grateful for the peace of mind that Profcyma Solutions' Child Insurance Plan provides. The comprehensive coverage and hassle-free process ensure my child's future is secure.",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Finance/Image/Homepage/FTestimonial/person1.png",
      name: "Kiran Kumar",
      content:
        "Navigating the complex world of finance became simple with Profcyma Solutions. Their EMI Calculator tool is a game-changer, helping me plan my payments effortlessly.",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Finance/Image/Homepage/FTestimonial/person3.png",
      name: "Paresh Kumar",
      content:
        "Profcyma Solutions made getting a loan a breeze! Their team's dedication and the easy-to-use online platform saved me time and stress. Highly recommended!",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Finance/Image/Homepage/FTestimonial/person2.png",
      name: "Shubham Kumar",
      content:
        "I'm grateful for the peace of mind that Profcyma Solutions' Child Insurance Plan provides. The comprehensive coverage and hassle-free process ensure my child's future is secure.",
    },
    {
      image:
        process.env.PUBLIC_URL +
        "/assets/Finance/Image/Homepage/FTestimonial/person1.png",
      name: "Kiran Kumar",
      content:
        "Navigating the complex world of finance became simple with Profcyma Solutions. Their EMI Calculator tool is a game-changer, helping me plan my payments effortlessly.",
    },

  ];

  return (
    <>
      {data && data?.length > 0 &&
        <section className="FTestimonial">
          <div className="Title-section">
            <p className="text">Testimonial</p>
            <div className="border-text"></div>
          </div>
          <h1 className="heading">Our Clients Satisfaction</h1>

          <div className="container" data-aos="fade-left" data-aos-duration="3000">
            <Swiper
              slidesPerView={3}
              // spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              navigation={true} modules={[Navigation]}
              initialSlide={1}
              centeredSlides={true}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },

                768: {
                  slidesPerView: 2,
                },

                1024: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 3,
                },
              }}
              className="mySwiper"
              onSwiper={(swiper) => setSwiperInstance(swiper)}
            >
              {/* {TestimonialData.map((item, index) => ( */}
              {data?.map((testominal, index) => (
                <SwiperSlide key={index}>
                  <div className="Our-Client_Card">
                    <div className="Content">
                      <p className="profcyma">{htmlToReactParser.parse(testominal?.description)}</p>
                      <h4 className="name">{testominal?.name}</h4>
                    </div>

                    <div className="Person-Image">
                      <img src={IMG_URL + testominal?.image} className="img-one" />

                    </div>
                  </div>
                </SwiperSlide>
              ))}

            </Swiper>
            <div className="silder-btn">
              <div
                className="back-btn"
                onClick={() => handleMainSliderClick("prev")}
              ></div>
              <div
                className="next-btn"
                onClick={() => handleMainSliderClick("next")}
              ></div>
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default FTestimonials;
