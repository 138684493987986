import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import './FinancePartner.css';
import { React, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import AOS from "aos";
import "aos/dist/aos.css";

const FinancePartner = () => {
    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);
    const [partnerdata, setPartnerData] = useState([]);
    const [selectedPartnerTypeId, setSelectedPartnerTypeId] = useState(null);

    const getDataAll = async () => {
        try {
            const response = await getData(`/finance/home/f-partner-type`);
            if (response?.success) {
                setData(response?.data);
                if (response?.data.length > 0) {
                    const defaultPartnerTypeId = response?.data[0]?.id;
                    setSelectedPartnerTypeId(defaultPartnerTypeId);
                    partnergetDataAll(defaultPartnerTypeId);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const partnergetDataAll = async (partnerTypeId) => {
        try {
            const response = await getData(`/finance/home/f-our-partner?partner_type_id=${partnerTypeId}`);
            if (response?.success) {
                setPartnerData(response?.data);
            } else {
                console.log("unsuccessful");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleTabSelect = (key) => {
        setSelectedPartnerTypeId(key);
        partnergetDataAll(key);
    };

    useEffect(() => {
        getDataAll();
    }, []);

    useEffect(() => {
        AOS.init({ once: true });
    }, [])

    return (
        <>
            {data && data?.length > 0 &&
                <section className='finance-partner-sec'>
                    <div className='container' data-aos="fade-up"
                        data-aos-anchor-placement="bottom-bottom" data-aos-duration="3000">
                        <h1>Our Partners</h1>
                        <Tab.Container id="left-tabs-example" activeKey={selectedPartnerTypeId} onSelect={handleTabSelect}>
                            <Nav variant="pills" className="main-tabs">
                                {data?.map((item) => (
                                    <Nav.Item key={item.id}>
                                        <Nav.Link eventKey={item.id}>{item.name}</Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>

                            <Tab.Content className='my-3'>
                                {data?.map((item) => (
                                    <Tab.Pane eventKey={item.id} key={item.id}>
                                        <div className='f-partner-main-card'>
                                            {partnerdata
                                                .filter(partner => partner.partner_type_id === item.id)
                                                .map((partner, index) => (
                                                    <div className='partner-card' key={index}>
                                                        <img
                                                            className="logos"
                                                            src={IMG_URL + partner?.image}
                                                            alt={partner?.name}
                                                        />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </section>
            }
        </>
    );
};

export default FinancePartner;
