import React from 'react'
import './Jp_applied_component.css'

import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';

import Jp_applied_job_cards from '../../../JP-Common-Elements/JP-Cards/Jp_applied_job_cards/Jp_applied_job_cards';

import Jp_employers_action_cards from '../../../JP-Common-Elements/JP-Cards/Jp_applied_job_cards/Jp_employers_action_cards';

import ITCard from '../../../JP-Common-Elements/JP-Cards/JP_Job_Card/JobITCard/ITCard';

import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Jp_applied_component = () => {
  return (
    <>
      <section className='Jp_applied_component_sec'>
        <div className='row'>

          <div className='col-xl-9 col-lg-8'>

            <div className='tabs-section'>
              <Tab.Container id="left-tabs-example" defaultActiveKey="Employers" >
                <Nav variant="pills" className="main-tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="Employers" >Employer's Action</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="Applied">You Applied</Nav.Link>
                  </Nav.Item>
                </Nav>


                <Tab.Content>
                  <Tab.Pane eventKey="Employers">
                      <Jp_employers_action_cards/>                      
                  </Tab.Pane>

                  <Tab.Pane eventKey="Applied">
                    <Jp_applied_job_cards />
                  </Tab.Pane>
                </Tab.Content>

              </Tab.Container>
            </div>
          </div>



          <div className='col-xl-3 col-lg-4 col-md-6 col-sm-8 mx-auto'>
            <div className='addvertisement-div mb-4'>
              <img className='add-banner' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/advertisement/ban1.png'} />
              <button className='know-more-bt'>Know More</button>
            </div>
            <div className='addvertisement-div'>
              <img className='add-banner' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/advertisement/ban1.png'} />
              <button className='know-more-bt'>Know More</button>
            </div>
          </div>
          
        </div>


        <div className='company-like-apply'>
            <div className='row'>
                <div className='col-md-8 mx-auto'>
                    <div className='title-div text-center mb-5 mt-2'>
                        <h1 className='jpsect_title'>Companies, you would like to apply</h1>
                    </div>
                    <ITCard />

                    <div className='show-more-div text-center mt-4'>
                      <Link to='/job-portal/job'><button className='show-more-btn'>Show More <span className='fafa-div'><FontAwesomeIcon icon="fa-solid fa-chevron-down" className="fafa" /></span></button></Link>
                    </div>
                </div>
            </div>
        </div>
      </section>
    </>
  )
}

export default Jp_applied_component