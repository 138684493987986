import React, { useState, useEffect, useContext } from "react";
import './followed_company.css'
import { Context } from "../../../../../../utils/context";

const Followed_company = ({ jobsData, unfollowCompany }) => {

  const { IMG_URL } = useContext(Context);
  return (
    <>
      <section className='Followed_company_section'>
        <div className='companeis-div'>
          <h4 className='sugg-title'>Companies You Followed</h4>
        </div>

        <div className="followed-div">
          {jobsData?.map((data) => (
            <div className="follow-card">
              <div className='row'>
                <div className='col-md-9 '>
                  <div className='d-flex'>
                    <div className='comp-im-div'>
                      <img className='cmp-img' src={IMG_URL + data?.company_logo} />
                    </div>
                    <div className='comp-details'>
                      <h6 className='comp-name'>{data?.company_name}</h6>
                      <div className='row'>
                        <div className='col-md-6'>
                          <p className='addp'><img className='loc-fafa' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/icons/location.png'} />{data?.city?.name}, {data?.state?.name}</p>
                        </div>
                        <div className='col-md-6'>
                          <p className='addp'><img className='loc-fafa' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/icons/vacancy.png'} />Openings: {data?.number_of_vacancy}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-3 text-end'>
                  <div className='unf-bt-div'>
                    <button className='unf-bt' onClick={() => {
                      unfollowCompany(data?.company_id);
                    }}>Unfollow</button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  )
}

export default Followed_company