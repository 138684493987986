import React, { useState, useEffect, useContext } from "react";
import "react-phone-input-2/lib/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import CommonModal from "../../CommonModal/CommonModal";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useForm, Controller, SubmitHandler, useFieldArray, } from "react-hook-form";
import classNames from "classnames"
import { RegxExpression } from "../../../../../../utils/apis/common/Common";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../../../../utils/context";
import { CarrerPost, getCarrer } from "../../../../../../utils/apis/jobseekar/jobseekar";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { allJobTitle, allJobType, allCity, allIndustries, allShift, allSkills, allModeWork } from "../../../../../../utils/apis/common/Common";



library.add(fas);
const CareerPrefModal = ({ show, onHide, jobSeekarDetailsID }) => {
  // const [selectedOptions, setSelectedOptions] = useState([]);
  // const [isYesActive, setIsYesActive] = useState(true); // State to track the active state of "Yes" button
  // const [startDate, setStartDate] = useState();

  const handleSelectChange = (selectedOption) => {
    setSelectedOptions([...selectedOptions, selectedOption]);
  };

  const handleCloseButtonClick = (optionValue) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option.value !== optionValue
    );
    setSelectedOptions(updatedOptions);
  };

  const options = [
    { value: " Pune", label: " Pune" },
    { value: "Mumbai (All areas)", label: "Mumbai (All areas)" },
    { value: "Bangalore", label: "Bangalore" },
  ];

  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isYesActive, setIsYesActive] = useState(1);
  const buyoutHandleClick = () => {
    if (isYesActive == 1) {
      setIsYesActive(2);
    } else {
      setIsYesActive(1);
    }
  };

  const { postData, getData, Select2Data, IMG_URL } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
  } = useForm();


  const [jobTypeData, setJobTypeData] = useState();
  const getJobTypeData = async () => {
    const res = await allJobType();
    if (res?.success) {
      setJobTypeData(await Select2Data(res?.data, "job_type_id"));
    }
  };

  const [jobTitleData, setJobTitleData] = useState();
  const getJobTitleData = async () => {
    const res = await allJobTitle();
    if (res?.success) {
      setJobTitleData(await Select2Data(res?.data, "job_title_id"));
    }
  };


  const [jobIndustriesData, setJobIndustriesData] = useState();
  const getIndustriesData = async () => {
    const res = await allIndustries();
    if (res?.success) {
      setJobIndustriesData(await Select2Data(res?.data, "industry_id"));
    }
  };

  const [jobCityData, setCityData] = useState();
  const getCityData = async () => {
    const res = await allCity();
    if (res?.success) {
      setCityData(await Select2Data(res?.data, "city_id"));
    }
  };

  const [jobShiftData, setShiftData] = useState();
  const getShiftData = async () => {
    const res = await allShift();
    if (res?.success) {
      setShiftData(await Select2Data(res?.data, "shift_id"));
    }
  };


  const [jobSkillData, setSkillData] = useState();
  const getSkillData = async () => {
    const res = await allSkills();
    if (res?.success) {
      setSkillData(await Select2Data(res?.data, "skill_id"));
    }
  };

  const { append, fields, remove } = useFieldArray({
    control,
    name: "data",
  });

  const [jobWorkData, setWorkData] = useState();
  const getWorkData = async () => {
    const res = await allModeWork();
    if (res?.success) {
      setWorkData(await Select2Data(res?.data, "mode_of_work_id"));
    }
  };




  const [selectedSkills, setSelectedSkills] = useState([]);

  // Function to handle skill selection
  const handleSkillChange = (index, selectedOption) => {
    // Update the selected skill for the specific index
    setSelectedSkills((prevSelectedSkills) => {
      const newSelectedSkills = [...prevSelectedSkills];
      newSelectedSkills[index] = selectedOption;
      return newSelectedSkills;
    });
  };

  const getCarrerdetails = async () => {
    console.log("hereherehere");
    const res = await getData(`/job-portal/job-seeker/profile/career-preference/${jobSeekarDetailsID}`);
    if (res?.success) {
      setCarrerId(res?.data?.id)
      reset(res?.data)
      const CarrerData = res?.data
      setValue('job_type_id', { value: CarrerData?.job_type_id, label: CarrerData?.job_type?.name },)
      setValue('desired_job_type_id', { value: CarrerData?.desired_job_type_id, label: CarrerData?.desired_job_type?.name },)
      setValue('mode_of_work_id', { value: CarrerData?.mode_of_work?.id, label: CarrerData?.mode_of_work?.name });
      setValue('job_title_id', { value: CarrerData?.job_title?.id, label: CarrerData?.job_title?.name });
      setValue('industry_id', { value: CarrerData?.industry?.id, label: CarrerData?.industry?.name });
      setValue('shift_id', { value: CarrerData?.shift?.id, label: CarrerData?.shift?.name });
      setValue('city_id', { value: CarrerData?.city?.id, label: CarrerData?.city?.name });


      if (CarrerData?.job_seeker_c_p_locations?.length > 0) {
        var preferred_City = [];
        CarrerData?.job_seeker_c_p_locations?.forEach((city, index) => {
          preferred_City.push({ value: city?.city_id, label: city?.city?.name });
          setValue('preferred_city_id', preferred_City);
        });
      }

      if (CarrerData?.job_seeker_c_p_skills?.length > 0) {
        CarrerData?.job_seeker_c_p_skills?.forEach((item, index) => {
          append({
            skill_id: { value: item?.skill_id, label: item?.skill_type?.name },
            beginer: item?.beginer == 1 ? '1' : '',
            proficient: item?.proficient == 1 ? '1' : '',
            expert: item?.expert == 1 ? '1' : '',
          });
        });
      } else {
        append({
          skill_id: "",
          beginer: "",
          proficient: "",
          expert: "",
        });
      }
    }
  }


  const [carrerId, setCarrerId] = useState();
  const onSubmit = async (d) => {
    if (carrerId) {
      d.career_preference_id = carrerId;
    }
    d.buyout_option = isYesActive;
    d.personal_details_id = jobSeekarDetailsID;
    const res = await CarrerPost(d);
    if (res?.success) {
      setCarrerId(res?.data?.id)
      // navigate("/job-portal/jobseeker/jobseeker-edit-profile")
      onHide();
    }
  }

  useEffect(() => {
    console.log("i am in useEffect :- ", jobSeekarDetailsID);
    getWorkData();
    getSkillData();
    getJobTitleData();
    getJobTypeData();
    getIndustriesData();
    getCityData();
    getShiftData();
    getCarrerdetails();
    window.scrollTo(0, 0);
    // Scroll to the top of the page
  }, [show]);

  // useEffect(() => {

  // }, [jobSeekarDetailsID]);
  return (
    <>
      <CommonModal show={show} onHide={onHide}>
        <Modal.Body >
          <div className="heading-holder">
            <h6>Career Preferance</h6>
          </div>

          <Form>
            <div className="personal-details-form ">
              {/* form-holder */}
              <div className="details-form-holder">
                <div className="form-container">
                  {/* Current Industry , Job Title*/}
                  <div className=" ">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="">
                          <Form.Group controlId="">
                            <Form.Label className="required">
                              Current Industry
                            </Form.Label>

                            <div>
                              {/* <Select options={options} /> */}
                              <Controller
                                name={"industry_id"}
                                {...register("industry_id", {
                                  required: "Current Industry is required",
                                })}
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    styles={{
                                      control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: errors.industry_id
                                          ? "red"
                                          : baseStyles,
                                      }),
                                    }}
                                    {...field}
                                    options={jobIndustriesData}
                                  />
                                )}
                              />
                            </div>
                          </Form.Group>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="">
                          <Form.Group controlId="">
                            <Form.Label className="required">Job Title</Form.Label>

                            <div>
                              {/* <Select options={options} /> */}
                              <Controller
                                name={"job_title_id"}
                                {...register("job_title_id", {
                                  required: "Job Title is required",
                                })}
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    styles={{
                                      control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: errors.job_title_id
                                          ? "red"
                                          : baseStyles,
                                      }),
                                    }}
                                    {...field}
                                    options={jobTitleData}
                                  />
                                )}
                              />
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Job Type: , Department: */}
                  <div className=" ">
                    <div className="row">
                      <div className="col-md-6">
                        <div className=" form-field">
                          <Form.Group controlId="">
                            <Form.Label className="">Job Type:</Form.Label>

                            <div>
                              {/* <Select options={options} /> */}
                              <Controller
                                name={"job_type_id"}
                                {...register("job_type_id", {
                                  required: "Job Type is required",
                                })}
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    styles={{
                                      control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: errors.job_type_id
                                          ? "red"
                                          : baseStyles,
                                      }),
                                    }}
                                    {...field}
                                    options={jobTypeData}
                                  />
                                )}
                              />
                            </div>
                          </Form.Group>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-field">
                          <Form.Group controlId="">
                            <Form.Label className="">Department:</Form.Label>

                            <Form.Control
                              type="text"
                              name="department"
                              placeholder="Enter Department"
                              {...register("department", {
                                required: "Department is Required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.department,
                                "is-valid": getValues("department"),
                              })}
                              onKeyDown={(event) => {
                                if (!RegxExpression.name.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Total Experience , Current Location: */}
                  <div className=" ">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-field">
                          <Form.Group controlId="">
                            <Form.Label className="">Total Experience:</Form.Label>

                            <Form.Control
                              type="text"
                              name="total_experience"
                              placeholder="Enter Total Experience"
                              {...register("total_experience", {
                                required: "Total Experience is Required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.total_experience,
                                "is-valid": getValues("total_experience"),
                              })}
                              // onKeyDown={(event) => {
                              //   if (!RegxExpression.phone.test(event.key)) {
                              //     event.preventDefault();
                              //   }
                              // }}
                              maxLength={2}
                            />
                          </Form.Group>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-field">
                          <Form.Group controlId="">
                            <Form.Label className="">Current Location:</Form.Label>

                            <div>
                              {/* <Select options={options} /> */}
                              <Controller
                                name={"city_id"}
                                {...register("city_id", {
                                  required: "Current Location is required",
                                })}
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    styles={{
                                      control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: errors.city_id
                                          ? "red"
                                          : baseStyles,
                                      }),
                                    }}
                                    {...field}
                                    options={jobCityData}
                                  />
                                )}
                              />
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Preffered Locations: */}
                  <div className="">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-field">
                          <Form.Group controlId="">
                            <Form.Label className="">Preffered Locations:</Form.Label>

                            <div className="select-option-array">
                              {/* {selectedOptions.map((option) => (
                            <Button
                              key={option.value}
                              className="select-option-buttons"
                            >
                              {option.label}{" "}
                              <span
                                className="select-item-close-btn"
                                onClick={() =>
                                  handleCloseButtonClick(option.value)
                                }
                              >
                                <FontAwesomeIcon icon="fa-solid fa-xmark" />
                              </span>
                            </Button>
                          ))} */}

                            </div>
                            <div>
                              {/* <Select
                            placeholder="Tell us your preferred locations to work (upto 5)"
                            options={options}
                            onChange={handleSelectChange}
                          /> */}
                              <Controller
                                name={"preferred_city_id"}
                                {...register("preferred_city_id", {
                                  required: "Current Location is required",
                                })}
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    isMulti
                                    styles={{
                                      control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: errors.preferred_city_id
                                          ? "red"
                                          : baseStyles,
                                      }),
                                    }}
                                    {...field}
                                    options={jobCityData}
                                  />
                                )}
                              />
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Current Salary(₹) , Expected Salary(₹) */}
                  <div className=" ">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-field">
                          <Form.Group controlId="">
                            <Form.Label className="required">
                              Current Salary(₹):
                            </Form.Label>

                            <Form.Control
                              type="text"
                              name="current_salary"
                              placeholder="Enter Current Salary(₹)"
                              {...register("current_salary", {
                                required: "Current Salary is Required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.current_salary,
                                "is-valid": getValues("current_salary"),
                              })}
                              onKeyDown={(event) => {
                                if (!RegxExpression.phone.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </Form.Group>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-field">
                          <Form.Group controlId="">
                            <Form.Label className="required">
                              Expected Salary(₹)
                            </Form.Label>

                            <Form.Control
                              type="text"
                              name="expected_salary"
                              placeholder="Enter Expected Salary(₹)"
                              {...register("expected_salary", {
                                required: "Expected Salary is Required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.expected_salary,
                                "is-valid": getValues("expected_salary"),
                              })}
                              onKeyDown={(event) => {
                                if (!RegxExpression.phone.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*Skills: */}
                  <div className="">
                    <div className="row">
                      {fields?.map((item, index) => (
                        <>
                          <div className="col-md-6">
                            <div className="form-field">
                              <Form.Group controlId="">
                                <Form.Label className="">Skills:</Form.Label>

                                {/* <Controller
                                  name={`data[${index}].skill_id`}
                                  {...register(`data[${index}].skill_id`, {
                                    required: "Skills is required",
                                  })}
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      styles={{
                                        control: (baseStyles) => ({
                                          ...baseStyles,
                                          borderColor: errors.skill_id
                                            ? "red"
                                            : baseStyles,
                                        }),
                                      }}
                                      {...field}
                                      options={jobSkillData?.filter(
                                        (option) => !selectedSkills.includes(option)
                                      )}
                                      onChange={(selectedOption) =>
                                        handleSkillChange(index, selectedOption)
                                      }
                                    />
                                  )}
                                /> */}

                                <Controller
                                  name={`data[${index}].skill_id`}
                                  {...register(`data[${index}].skill_id`, {
                                    required: "Skill is required",
                                  })}
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      styles={{
                                        control: (baseStyles) => ({
                                          ...baseStyles,
                                          borderColor: errors.skill_id
                                            ? "red"
                                            : baseStyles,
                                        }),
                                      }}
                                      {...field}
                                      options={jobSkillData?.filter(
                                        (option) => !selectedSkills.includes(option)
                                      )}
                                    />
                                  )}
                                />

                                <div className="form-field">
                                  <Form className="Languages-tick-holder check_box">
                                    <Form.Group>
                                      <div className="Languages-tick">
                                        <Form.Check
                                          type="checkbox"
                                          label="Begineer"
                                          name="Begineer"
                                          {...register(`data[${index}].beginer`)}
                                        />
                                        <Form.Check
                                          type="checkbox"
                                          label="Proficient"
                                          name="Proficient"
                                          {...register(`data[${index}].proficient`)}
                                        />
                                        <Form.Check
                                          type="checkbox"
                                          label="Expert"
                                          name="Expert"
                                          {...register(`data[${index}].expert`)}
                                        />
                                      </div>
                                    </Form.Group>
                                  </Form>
                                </div>
                              </Form.Group>
                            </div>
                          </div>

                          <div className="col-md-6"></div>
                          {index !== 0 && (
                            <div className="addremoveadress">
                              <div className="text-end Addnewadresstxt">
                                <p className="" onClick={() => remove(index)}>
                                  Remove Award{" "}
                                  {/* <FontAwesomeIcon icon="fa-solid fa-minus" /> */}
                                </p>
                              </div>
                            </div>
                          )}
                        </>
                      ))}
                      <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                          <div className="Add-education-btn-holder form-field">
                            <div className="apply-btn-holder">
                              <Button className="apply_now_btn" type="button" onClick={() => {
                                append({
                                  skill_id: "",
                                  beginer: "",
                                  proficient: "",
                                  expert: "",
                                }); alert("");
                              }
                              }>
                                Add Skills
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Mode of Work: , Desired Shift:*/}
                  <div className=" ">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-field">
                          <Form.Group controlId="">
                            <Form.Label className="">Mode of Work:</Form.Label>

                            <div>
                              {/* <Select options={options} /> */}
                              <Controller
                                name={"mode_of_work_id"}
                                {...register("mode_of_work_id", {
                                  required: "Mode of Work is required",
                                })}
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    styles={{
                                      control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: errors.mode_of_work_id
                                          ? "red"
                                          : baseStyles,
                                      }),
                                    }}
                                    {...field}
                                    options={jobWorkData}
                                  />
                                )}
                              />
                            </div>
                          </Form.Group>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-field">
                          <Form.Group controlId="">
                            <Form.Label className="">Desired Shift:</Form.Label>

                            <div>
                              {/* <Select options={options} /> */}
                              <Controller
                                name={"shift_id"}
                                {...register("shift_id", {
                                  required: "Desired Shift is required",
                                })}
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    styles={{
                                      control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: errors.shift_id
                                          ? "red"
                                          : baseStyles,
                                      }),
                                    }}
                                    {...field}
                                    options={jobShiftData}
                                  />
                                )}
                              />
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Holding CTC(₹): , Desired Job type: */}
                  <div className=" ">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-field">
                          <Form.Group controlId="">
                            <Form.Label className="">Holding CTC(₹):</Form.Label>

                            <Form.Control
                              type="text"
                              name="holding_ctc"
                              placeholder="Enter Holding CTC(₹):"
                              {...register("holding_ctc", {
                                required: "Holding CTC is Required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.holding_ctc,
                                "is-valid": getValues("holding_ctc"),
                              })}
                              onKeyDown={(event) => {
                                if (!RegxExpression.phone.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </Form.Group>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-field">
                          <Form.Group controlId="">
                            <Form.Label className="">Desired Job type:</Form.Label>

                            <div>
                              {/* <Select options={options} /> */}
                              <Controller
                                name={"desired_job_type_id"}
                                {...register("desired_job_type_id", {
                                  required: "Desired Job type is required",
                                })}
                                control={control}
                                render={({ field }) => (
                                  <Select
                                    styles={{
                                      control: (baseStyles) => ({
                                        ...baseStyles,
                                        borderColor: errors.desired_job_type_id
                                          ? "red"
                                          : baseStyles,
                                      }),
                                    }}
                                    {...field}
                                    options={jobTypeData}
                                  />
                                )}
                              />
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*Notice Period: , Last Working Date: */}
                  <div className=" ">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-field">
                          <Form.Group controlId="">
                            <Form.Label className="">Notice Period:</Form.Label>

                            <div>
                              {/* <Select options={options} /> */}
                              <Form.Control
                                type="text"
                                name="notice_period"
                                placeholder="Enter Notice Period"
                                {...register("notice_period", {
                                  required: "Notice Period is Required",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.notice_period,
                                  "is-valid": getValues("notice_period"),
                                })}
                                onKeyDown={(event) => {
                                  if (!RegxExpression.phone.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                              />
                            </div>
                          </Form.Group>
                        </div>
                      </div>



                      <div className="col-md-6">
                        <div className="form-field">
                          <Form.Group controlId="">
                            <Form.Label className="">Last Working Date:</Form.Label>

                            <div>
                              {/* <DatePicker placeholderText="DD/MM/YYYY" selected={startDate} onChange={(date) => setStartDate(date)} /> */}
                              <Form.Control
                                type="date"
                                placeholder="Enter Date Of Award"
                                name={"last_working_date"}
                                {...register("last_working_date", {
                                  required: "Name of Award is Required",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.last_working_date,
                                  // "is-valid": getValues("company_name"),
                                })}
                              />
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*Notice Period: , Last Working Date: */}
                  <div className=" ">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-field">
                          <Form.Group controlId="">
                            <div className="Buyout_Option">
                              <div>
                                <Form.Label className="">Buyout Option:</Form.Label>
                              </div>
                              <div className="yes-no-btn-holder">
                                <Button
                                  className={`Yes-no-btn ${isYesActive == 1 ? 'active' : ''}`} // Dynamically apply 'active' class
                                  onClick={buyoutHandleClick} // Activate "Yes" button
                                >
                                  Yes
                                </Button>
                                <Button
                                  className={`Yes-no-btn ${isYesActive == 2 ? 'active' : ''}`} // Dynamically apply 'active' class
                                  onClick={buyoutHandleClick} // Activate "No" button
                                >
                                  No
                                </Button>
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* close save btn */}
            <div className="close-save-btn-holder form-field">
              <div className="Close-btn-holder">
                <Button className="close-btn" onClick={onHide}>
                  Cancel
                </Button>
              </div>

              <div className="apply-btn-holder">
                {/* <Link to={"/job-portal/jobseeker/jobseeker-edit-profile"}> */}
                <Button className="apply_now_btn" onClick={handleSubmit(onSubmit)} type="submit">Save & Close</Button>
                {/* </Link> */}
              </div>
            </div>
          </Form>
        </Modal.Body>
      </CommonModal>
    </>
  );
};

export default CareerPrefModal;
