import React, { useState, useEffect, useContext } from "react";
import '../Suggetions-company/Suggetions_company.css'
import './Suggetions_company.css'
import { Context } from "../../../../../../utils/context";

const Suggetions_company = ({ companyFollow, unfollowCompany }) => {

  const { IMG_URL } = useContext(Context);
  return (
    <>
      <section className='Suggetions_company_div'>
        <div className='suggetion-div'>
          <h4 className='sugg-title'>Suggestions for You</h4>

          <div className='suggg-bg'>
            {companyFollow?.map((data) => (
              <div className='follow-card'>
                <div className='row'>
                  <div className='col-7'>
                    <img className='comp-img' src={IMG_URL + data?.company_logo} />
                  </div>
                  <div className='col-5'>
                    <button className='follow-btn' onClick={() => { unfollowCompany(data?.company?.id); }}>Follow</button>
                  </div>
                </div>
                <p className='comp-name'>{data?.company_name}</p>
                <p className='addd'><img className='loccc' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/Footer/location.png'} />{data?.city?.name}, {data?.state?.name}</p>
              </div>
            ))}
            {/* static suggestioon */}
            {/* <div className='follow-card'>
              <div className='row'>
                <div className='col-7'>
                  <img className='comp-img' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/companynames/Profcyma.png'} />
                </div>
                <div className='col-5'>
                  <button className='follow-btn'>Follow</button>
                </div>
              </div>
              <p className='comp-name'>IT and Digital Marketing Soutions</p>
              <p className='addd'><img className='loccc' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/Footer/location.png'} />Bavdhan, Pune</p>
            </div>
            <div className='follow-card'>
              <div className='row'>
                <div className='col-7'>
                  <img className='comp-img' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/companynames/Profcyma.png'} />
                </div>
                <div className='col-5'>
                  <button className='follow-btn'>Follow</button>
                </div>
              </div>
              <p className='comp-name'>IT and Digital Marketing Soutions</p>
              <p className='addd'><img className='loccc' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/Footer/location.png'} />Bavdhan, Pune</p>
            </div>
            <div className='follow-card'>
              <div className='row'>
                <div className='col-7'>
                  <img className='comp-img' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/companynames/Profcyma.png'} />
                </div>
                <div className='col-5'>
                  <button className='follow-btn'>Follow</button>
                </div>
              </div>
              <p className='comp-name'>IT and Digital Marketing Soutions</p>
              <p className='addd'><img className='loccc' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/Footer/location.png'} />Bavdhan, Pune</p>
            </div>
            <div className='follow-card'>
              <div className='row'>
                <div className='col-7'>
                  <img className='comp-img' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/companynames/Profcyma.png'} />
                </div>
                <div className='col-5'>
                  <button className='follow-btn'>Follow</button>
                </div>
              </div>
              <p className='comp-name'>IT and Digital Marketing Soutions</p>
              <p className='addd'><img className='loccc' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/Footer/location.png'} />Bavdhan, Pune</p>
            </div>
            <div className='follow-card'>
              <div className='row'>
                <div className='col-7'>
                  <img className='comp-img' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/companynames/Profcyma.png'} />
                </div>
                <div className='col-5'>
                  <button className='follow-btn'>Follow</button>
                </div>
              </div>
              <p className='comp-name'>IT and Digital Marketing Soutions</p>
              <p className='addd'><img className='loccc' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/Footer/location.png'} />Bavdhan, Pune</p>
            </div> */}
            {/* static suggestioon */}
          </div>

        </div>
      </section>
    </>
  )
}

export default Suggetions_company