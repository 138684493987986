import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import JpDashboardbanner from '../../JpDashboardbanner/JpDashboardbanner';
import "./EmployerMyPackages.css";
import { Context } from "../../../../../utils/context";
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import moment from 'moment';
const packageData = [
  {
    name: 'Silver Plan',
    price: '₹999'
  },
  {
    name: 'Gold Plan',
    price: '₹1999'
  },
  {
    name: 'Platinum Plan',
    price: '₹3999'
  },
  {
    name: 'Business Plan',
    price: '₹5999'
  }
];

function EmployerMyPackages() {
  const [activePackage, setActivePackage] = useState(null);
  const { getData, IMG_URL } = useContext(Context);
  const [dynamicId, setDynamicId] = useState("");
  const [packages, setpackage] = useState("");
  const [data, setData] = useState([]);
  const [activeHour, setActiveHour] = useState([]);
  const [days, setDays] = useState("");
  const getDataAll = async () => {
    try {
      const response = await getData(`/job-portal/packages/mypackages`);
      setData(response?.data);
      // setActivePackage(response?.data[0])
      if (response.data && response.data.length > 0) {
        setActivePackage(response.data[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  console.log(data,"data activePackage");
  console.log(activePackage,"activePackage activePackagehhhhhhhhhhhhhhhhhhhhh");

  useEffect(() => {
    getDataAll();
  }, []);

  const handlePackageClick = (packageItem) => {
    setActivePackage(packageItem);
  };
  console.log(packages, "packages activePackage");

 
  const [remainingTime, setRemainingTime] = useState('');

  useEffect(() => {
    if (activePackage) {
      const endDate = new Date(activePackage.end_date);
      console.log(endDate,"activePackage.end_date");
      const currentDate = new Date();
      console.log(currentDate,"currentDate");

      let differenceInMillis = endDate - currentDate;
      console.log(differenceInMillis,"differenceInMillis");
      const updateTimer = () => {
        differenceInMillis -= 1000;

        if (differenceInMillis < 0) {
          clearInterval(timer);
          setRemainingTime('00:00:00 hrs');
        } else {
          const days = Math.floor(differenceInMillis / (1000 * 60 * 60 * 24));
          const hours = Math.floor(differenceInMillis / (1000 * 60 * 60));
          const minutes = Math.floor((differenceInMillis % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((differenceInMillis % (1000 * 60)) / 1000);
          setDays(days);
          setActiveHour(hours);
          console.log(hours,"hours");
          const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} hrs`;
          setRemainingTime(formattedTime);
        }
      };

      const timer = setInterval(updateTimer, 1000);
      updateTimer();

      return () => clearInterval(timer);
    }
  }, [activePackage]);
  
 
  console.log(activePackage?.plan?.end_date, "activePackage?.plan?.end_date");
  console.log(activeHour, "activeHour activeHour");
  
  return (
    <section className='emp-my-packages'>
      <JpDashboardbanner />
      <div className='container'>
        <div className='row justify-content-center my-5'>
          <div className='col-xxl-6 col-xl-7 col-lg-8 col-md-10  col-12'>
            <div className='row'>
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12'>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      {/* <div className='my-package-card'>
                        <div className='main-card'>
                          <p className='red-text'>05:20:54 hrs </p>
                          <p className='red-text'>left to expire your subscription</p>
                        </div>
                        <div className='main-inner-card'>

                          <div className='header-card-heading'>
                            <h5>{data[0]?.plan?.name}</h5>
                            <p>{data[0]?.plan?.price}</p>
                          </div>

                          <div className='package-descripn'>
                            <div className='col-xxl-6 col-6'>
                              <p>{data[0]?.plan?.job_post} Job post</p>
                              <p>{data[0]?.plan?.application} Applications</p>
                              <p>{data[0]?.plan?.featured_jobs} Featured job</p>
                              <p>{data[0]?.plan?.days_validity} Days validity</p>
                            </div>
                          </div>
                          <div className='renew-btn-div'>
                            <Link to="/job-portal/employer/all-packages"><button className='renew-btn'>Renew Plan</button></Link>
                          </div>
                        </div>
                      </div> */}

                      <div className='my-package-card'>

                        <div className='main-card'>
                          {/* <p className='red-text'>05:20:54 hrs </p> */}
                          {/* <p className='red-text'>{formattedTimeRemaining} hrs</p> */}
                          {/* <React.Fragment>
                            {formattedTimeRemaining && <p className='red-text'>{formattedTimeRemaining}</p>}
                          </React.Fragment> */}
                          {activeHour <= 24 ? (
                            <>
                              {/* <p className='red-text'>05:20:54 hrs </p> */}
                              {/* <p className='red-text'>
                                {remainingTime.hours.toString().padStart(2, '0')}:
                                {remainingTime.minutes.toString().padStart(2, '0')}:
                                {remainingTime.seconds.toString().padStart(2, '0')} hrs
                              </p> */}
                              <p className='red-text'>{remainingTime}</p>
                              <p className='red-text'>left to expire your subscription</p>
                            </>
                          ) : null}
                        </div>
                        <div className='main-inner-card'>
                          <div className='header-card-heading'>
                            <h5>{activePackage?.name}</h5>
                            <p>{activePackage?.price}</p>
                          </div>
                          <div className='package-descripn'>
                            <div className='col-xxl-6 col-6'>
                              <p>{activePackage?.job_portal_job?.company?.name}</p>
                              <p>{activePackage?.plan?.job_post} Job post</p>
                              <p>{activePackage?.plan?.application} Applications</p>
                              <p>{days} Days validity</p>
                            </div>
                          </div>
                          <div className='renew-btn-div'>
                            {/* <Link to="/job-portal/employer/all-packages"> */}
                            {activeHour <= 24 ? (
                            <Link to={`/job-portal/employer/emp-payment/${activePackage?.id}`}>
                              <button className='renew-btn'>Renew Plan</button>
                            </Link>
                             ) : null}
                          </div>
                        </div>

                      </div>
                    </Tab.Pane>

                  </Tab.Content>

                </div>
                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4  col-sm-4 col-12'>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">
                        <div className='small-package-card'>
                          {data?.map((packageItem, index) => (
                            <div className='mini-card' onClick={() => handlePackageClick(packageItem)} key={index}>
                              <h6>{packageItem?.plan?.name}</h6>
                              <p>{packageItem?.plan?.price}</p>
                            </div>
                          ))}
                          {/* <div>
                            <Link to={`/job-portal/employer/all-packages/${dynamicId}`}><button className='more-btn'>See More Plans</button></Link>
                          </div> */}
                        </div>
                      </Nav.Link>
                    </Nav.Item>

                  </Nav>

                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default EmployerMyPackages;
