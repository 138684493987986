import React from 'react'
import { Routes, Route } from "react-router-dom";
import FHomePage from '../F-HomePage/FHomePage'
import FPrivacyPolicy from '../F-PrivacyPolicy/FPrivacyPolicy';
import FBlogInnerPage from '../F-Blog/F-BlogInnerPage/FBlogInnerPage';
import FBlog from '../F-Blog/FBlog';
import FContact from '../F-Contact/FContact';
import FAbout from '../F-About/FAbout'
import FinanceLoanCalci from '../FinanceLoanCalci/FinanceLoanCalci';
import FHomeLoan from '../F-HomeLoan/FHomeLoan';
import FHomeInsuranceModal from '../F-Service/F-Insurance/FHomeInsuranceModal/FHomeInsuranceModal';
import FHomeLoanModal from '../F-Service/F-Loan/FHomeLoanModal/FHomeLoanModal';
import FInsuranceComman from '../F-insurance-comman/FInsuranceComman';


const FinanceRoutesMain = () => {
    return (
        <Routes>
            <Route path="/home" element={<FHomePage />} />
            <Route path="/privacy" element={<FPrivacyPolicy />} />
            <Route path="/bloginner/:id" element={<FBlogInnerPage />} />
            <Route path="/blog" element={<FBlog />} />
            <Route path="/contact-us" element={<FContact />} />
            <Route path="/aboutus" element={<FAbout />} />
            <Route path="/loancalculator" element={<FinanceLoanCalci />} />
            <Route path="/home-loan/:id" element={<FHomeLoan />} />
            <Route path="/homeInsurance-step-form" element={<FHomeInsuranceModal />} />
            <Route path="/homeLoan-step-form" element={<FHomeLoanModal />} />
            <Route path="/insurance/:id" element={<FInsuranceComman />} />
        </Routes>
    )
}

export default FinanceRoutesMain