import React, { useState, useEffect, useContext } from "react";
import './jphomesearch.css';
import Jpsearchbar from '../Jpsearchbar/Jpsearchbar';
import Nav from 'react-bootstrap/Nav';
import { Context } from "../../../../utils/context";
import { ShimmerThumbnail } from "react-shimmer-effects";
import parse from "html-react-parser";

const Jphomesearch = ({ setJobTypeId, setCityId, setJobTitle }) => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/job-portal/home/banner`);
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataAll();
  }, []);

  return (
    <section className='jp-homesearch-section'>
      <div className='container-fluid p-0'>
        {data.length === 0 ? (
          <ShimmerThumbnail height={400} rounded />
        ) : (
          data.map((val) => (
            <div className='sarch-main-div' key={val.id}>
              <div className='image-overlay'></div>
              <img className='banner-imggg' src={IMG_URL + val?.image} alt="banner" />
              <div className='overlay-ban-cont'>
                <div className='container'>
                  <h2 className='bann-title'>{val?.title}</h2>
                  <div className='ban-title-subb-div'>
                    <p className='ban-title-subb'>{parse(val?.description) || ""}</p>
                  </div>
                  <Jpsearchbar
                    setJobTypeId={setJobTypeId}
                    setCityId={setCityId}
                    setJobTitle={setJobTitle}
                  />
                  <div className='pop-ser-div'>
                    <Nav className='justify-content-center'>
                      <Nav.Item>
                        <Nav.Link className='pop-serach-text'>Popular Searches:</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link href="#">Content Writer</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link href="#">Finance</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link href="#">Human Resource</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link href="#">Management</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link className='clearallbtn'>Clear All</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </section>
  );
};

export default Jphomesearch;
