import React from 'react'
import './Jp_jobseeker_dashboard.css'
import Jp_Profile_banner from './Jp-profile-banner/Jp_Profile_banner'


import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

import Jp_dash_component from './Jp-dahboard/Jp_dash_component';
import Jp_saved_jobs_components from './Jp-saved-jobs/Jp_saved_jobs_components';
import Jp_applied_component from './Jp-applied/Jp_applied_component';

import Jp_shortlisted_component from './Jp-shortlisted/Jp_shortlisted_component';
import Jp_interview_component from './Jp-interview/Jp_interview_component';

const Jp_jobseeker_dashboard = () => {
  return (
    <>
      <section className='Jp_jobseeker_dashboard-section'>
        <Jp_Profile_banner />

        <div className='container'>
          <section className='tab-sec'>
            <div className='container'>

              <Tab.Container id="left-tabs-example" defaultActiveKey="Dashboard" >
                <Row>
                  <Nav variant="pills" className="main-tabs">

                    <Nav.Item>
                      <Nav.Link eventKey="Dashboard" >Dashboard</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Applied">Applied</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Shortlisted">Shortlisted</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Interview">Interview Results</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="Saved">Saved Jobs</Nav.Link>
                    </Nav.Item>

                  </Nav>

                  <Tab.Content>
                    <Tab.Pane eventKey="Dashboard">
                      <Jp_dash_component />
                    </Tab.Pane>

                    <Tab.Pane eventKey="Applied">
                      <Jp_applied_component />
                    </Tab.Pane>

                    <Tab.Pane eventKey="Shortlisted">
                      <Jp_shortlisted_component />
                    </Tab.Pane>

                    <Tab.Pane eventKey="Interview">
                      <Jp_interview_component />
                    </Tab.Pane>

                    <Tab.Pane eventKey="Saved">
                      <Jp_saved_jobs_components />
                    </Tab.Pane>
                    
                  </Tab.Content>

                </Row>

              </Tab.Container>
            </div>
          </section>
        </div>
      </section>
    </>
  )
}

export default Jp_jobseeker_dashboard