import React, { useState, useEffect } from "react";
import "./FPrivacyPolicy.css";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ShimmerPostDetails } from "react-shimmer-effects";

function FPrivacyPolicy() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadTimer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(loadTimer);
  }, []);

  return (
    <>
      {loading ? (
        <Container className="mt-5 mb-5">
          <ShimmerPostDetails card cta variant="SIMPLE" />
        </Container>
      ) : (
        <section className="privacy-section">
            <Container>
              <div className="privacy-main">
                <div className="bottomspace">
                  <h1>Privacy Policy</h1>
                  <p className="time">Last updated: February 20, 2024</p>
                  <p className="font1">
                    This Privacy Policy describes Our policies and procedures on the
                    collection, use, and disclosure of Your information when You use
                    the Service and tells You about Your privacy rights and how the
                    law protects You. <br />
                    We use Your data to provide and improve the Service. By using
                    the Service, You agree to the collection and use of information
                    by this Privacy Policy.
                  </p>
                </div>
                <div className="bottomspace">
                  <div className="privacytoptxt">
                    <p>Interpretation and Definitions</p>
                    <div className="privacyborder"></div>
                  </div>
                  <h2>Interpretation</h2>
                  <p className="font1">
                    The words of which the initial letter is capitalized have
                    meanings defined under the following conditions. <br />
                    The following definitions shall have the same meaning regardless
                    of whether they appear in singular or plural.
                  </p>

                  <div className="mt-5">
                    <p className="defination-text">Definitions</p>
                    <p>For this Privacy Policy:</p>
                    <ul>
                      <li> <span className="text-accont me-1">Account</span>
                        means a unique account created for You to access our Service
                        or parts of our Service.</li>
                      <li> <span className="text-accont me-1">Affiliate</span>
                        means an entity that controls, is controlled by, or is under
                        common control with a party, where "control" means ownership
                        of 50% or more of the shares, equity interest, or other
                        securities entitled to vote for the election of directors or
                        other managing authority.</li>
                      <li><span className="text-accont me-1">Application</span>
                        refers to profcyma Finance, the software program provided by
                        the Company.</li>
                      <li><span className="text-accont me-1"> Company</span>
                        (referred to as either "the Company", "We", "Us" or "Our" in
                        this Agreement) refers to Profcyma Finance.</li>
                      <li><span className="text-accont me-1"> Cookies are</span>
                        small files that are placed on Your computer, mobile device,
                        or any other device by a website, containing the details of
                        Your browsing history on that website among its many uses.</li>
                      <li><span className="text-accont me-1"> Country</span>
                        refers to Maharashtra, India</li>
                      <li><span className="text-accont me-1">Device</span>
                        means any device that can access the Service such as a
                        computer, a cellphone, or a digital tablet.</li>
                      <li><span className="text-accont me-1">Personal</span>
                        Data is any information that relates to an identified or
                        identifiable individual.</li>
                      <li><span className="text-accont me-1">Service</span>
                        refers to the Application the Website or both.</li>
                      <li><span className="text-accont me-1">Service</span>
                        Provider means any natural or legal person who processes the
                        data on behalf of the Company. It refers to third-party
                        companies or individuals employed by the Company to facilitate
                        the Service, to provide the Service on behalf of the Company,
                        to perform services related to the Service, or to assist the
                        Company in analyzing how the Service is used.</li>
                      <li><span className="text-accont me-1">Usage Data</span>
                        refers to data collected automatically, either generated by
                        the use of the Service or from the Service infrastructure
                        itself (for example, the duration of a page visit).</li>
                      <li><span className="text-accont me-1">Website</span>
                        refers to Profcyma Finance, accessible from
                        <a
                          className="prof-link"
                          href="https://profcyma.com/about-us"
                          target="blank"
                        >
                          {" "}
                          https://profcyma.com/about-us
                        </a></li>
                      <li><span className="text-accont me-1">You means</span>
                        the individual accessing or using the Service, or the company,
                        or other legal entity on behalf of which such individual is
                        accessing or using the Service, as applicable.</li>
                    </ul>

                  </div>
                </div>
                <div className="bottomspace">
                  <div>
                    <div className="privacytoptxt">
                      <p>Collecting and Using Your Data</p>
                      <div className="privacyborder"></div>
                    </div>
                    <h2 className="mb-5">Types of Data Collected</h2>
                  </div>

                  <div className="bold-data ">
                    <h6>Personal Data</h6>
                    <p>
                      While using Our Service, We may ask You to provide Us with
                      certain personally identifiable information that can be used
                      to contact or identify You. Personally identifiable
                      information may include, but is not limited to:
                    </p>
                    <p>
                      Email address / First name and last name / Phone number /
                      Usage Data
                    </p>
                  </div>

                  <div className="bold-data bg-color p-3" >
                    <h6>Usage Data</h6>
                    <ul>
                      <li>Usage Data
                        is collected automatically when using the Service.</li>
                      <li>Usage Data
                        may include information such as Your Device's Internet
                        Protocol address (e.g. IP address), browser type, browser
                        version, the pages of our Service that You visit, the time and
                        date of Your visit, the time spent on those pages, unique
                        device identifiers and other diagnostic data.</li>
                      <li>When You
                        access the Service by or through a mobile device, We may
                        collect certain information automatically, including, but not
                        limited to, the type of mobile device You use, Your mobile
                        device's unique ID, the IP address of Your mobile device, Your
                        mobile operating system, the type of mobile Internet browser
                        You use, unique device identifiers and other diagnostic data.</li>
                      <li>We may also
                        collect information that Your browser sends whenever You visit
                        our Service or when You access the Service by or through a
                        mobile de</li>
                    </ul>

                  </div>

                  <div className="bold-data mt-5">
                    <h6>Information Collected While Using The Application</h6>
                    <p>
                      While using Our Application, to provide features of Our
                      Application, We may collect, with Your prior permission:
                    </p>
                    <p><b>Information regarding your location</b></p>
                    <p>
                      We use this information to provide features of Our Service and
                      to improve and customize Our Service. The information may be
                      uploaded to the Company's servers and/or a Service Provider's
                      server or it may be simply stored on Your device.
                    </p>
                    <p>
                      You can enable or disable access to this information at any
                      time, through Your Device settings.
                    </p>
                  </div>
                </div>
                <div className="bottomspace">
                  <div>
                    <h2>Tracking Technologies and Cookies</h2>
                  </div>
                  <p className="mb-5">
                    We use Cookies and similar tracking technologies to track the
                    activity on Our Service and store certain information.
                    Tracking technologies used are <br />
                    beacons, tags, and scripts to collect and track information
                    and to improve and analyze Our Service. The technologies We
                    use may include:
                  </p>


                  <div className="bold-data mb-5">
                    <h6>Cookies Or Browser Cookies.</h6>
                    <p>
                      A cookie is a small file placed on Your Device. You can
                      instruct Your browser to refuse all Cookies or to indicate
                      when a cookie is being sent. However, if You do not accept
                      Cookies, You may not be able to use some parts of our Service.
                      Unless you have adjusted Your browser setting so that it will
                      refuse cookies, our Service may use Cookies.
                    </p>
                  </div>

                  <div className="bold-data bg-color mb-5">
                    <h6>Web Beacons.</h6>
                    <p>
                      Certain sections of our Service and our emails may contain
                      small electronic files known as web beacons (also referred to
                      as clear gifs, pixel tags, and single-pixel gifs) that permit
                      the Company, for example, to count users who have visited
                      those pages or opened an email and for other related website
                      statistics (for example, recording the popularity of a certain
                      section and verifying system and server integrity). <br />
                      Cookies can be "Persistent" or "Session" Cookies. Persistent
                      Cookies remain on Your personal computer or mobile device when
                      You go offline, while Session Cookies are deleted as soon as
                      You close Your web browser. We use both Session and Persistent
                      Cookies for the purposes set out below:
                    </p>
                  </div>

                  <div className="bold-data mb-5">
                    <h6>Necessary / Essential Cookies</h6>
                    <p><span className="text-accont me-1">Type :</span>Session Cookies<br />
                      <span className="text-accont me-1">Administered by :</span>Us<br />
                      <span className="text-accont me-1">Purpose :</span>
                      These Cookies are
                      essential to provide You with services available through the
                      Website and to enable You to use some of its features. They
                      help to authenticate users and prevent fraudulent use of user
                      accounts. Without these Cookies, the services that You have
                      asked for cannot be provided, and We only use these Cookies to
                      provide You with those services.
                    </p>
                  </div>

                  <div className="bold-data bg-color mb-5">
                    <h6>Cookies Policy / Notice Acceptance Cookies</h6>
                    <p>
                      <span className="text-accont me-1">Type :</span> Persistent Cookies<br />
                      <span className="text-accont me-1">Administered by :</span> Us<br />
                      <span className="text-accont me-1">Purpose :</span>These Cookies identify
                      if users have accepted the use of cookies on the Website.
                    </p>
                  </div>

                  <div className="bold-data mb-5">
                    <h6>Functionality Cookies</h6>
                    <p><span className="text-accont me-1">Type :</span>Persistent Cookies <br />
                      <span className="text-accont me-1">Administered by :</span>Us<br />
                      <span className="text-accont me-1">Purpose :</span>
                      These Cookies allow us to remember choices You make when You use the Website, such as remembering your
                      login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to
                      avoid having to re-enter your preferences every time You use the Website.
                    </p>
                    <p>For more information about the cookies we use and your choices regarding cookies, please visit our Cookies Policy or the Cookies
                      section of our Privacy Policy.</p>
                  </div>
                </div>

                <div>
                  <div>
                    <h2>Use of Your Data</h2>
                  </div>
                  <p>
                    The Company may use Personal Data for the following purposes:
                  </p>
                </div>

                <div className="bold-data mb-5">
                  <h6>To Provide And Maintain Our Service</h6>
                  <p>Including monitoring the usage of our Service.</p>
                </div>

                <div className="bold-data bg-color mb-5">
                  <h6>To Manage Your Requests</h6>
                  <p>To attend and manage Your requests to Us.</p>
                </div>

                <div className="bold-data mb-5">
                  <h6>For The Performance Of A Contract</h6>
                  <p>
                    The development, compliance, and undertaking of the purchase
                    contract for the products, items, or services You have purchased
                    or of any other contract with Us through the Service.
                  </p>
                </div>

                <div className="bold-data mb-5">
                  <h6>To Provide You</h6>
                  <p>
                    With news, special offers, and general information about other
                    goods, services, and events that we offer that are similar to
                    those that you have already purchased or enquired about unless
                    You have opted not to receive such information.
                  </p>
                </div>

                <div className="bold-data mb-5">
                  <h6>To Manage Your Account</h6>
                  <p>
                    To manage Your registration as a user of the Service. The
                    Personal Data You provide can give You access to different
                    functionalities of the Service that are available to You as a
                    registered user.
                  </p>
                </div>

                <div className="bold-data bg-color mb-5">
                  <h6>For Other Purposes</h6>
                  <p>
                    We may use Your information for other purposes, such as data
                    analysis, identifying usage trends, determining the
                    effectiveness of our promotional campaigns, and evaluating and
                    improving our Service, products, services, marketing, and your
                    experience.
                  </p>
                </div>

                <div className="bold-data mb-5">
                  <h6>For Business Transfers</h6>
                  <p>
                    We may use Your information to evaluate or conduct a merger,
                    divestiture, restructuring, reorganization, dissolution, or
                    other sale or transfer of some or all of Our assets, whether as
                    a going concern or as part of bankruptcy, liquidation, or
                    similar proceeding, in which Personal Data held by Us about our
                    Service users is among the assets transferred.
                  </p>
                </div>

                <div className="bold-data mb-5">
                  <h6>To Contact You</h6>
                  <p>
                    To contact You by email, telephone calls, SMS, or other
                    equivalent forms of electronic communication, such as a mobile
                    application's push notifications regarding updates or
                    informative communications related to the functionalities,
                    products, or contracted services, including the security
                    updates, when necessary or reasonable for their implementation.
                  </p>
                </div>

                <div className="bold-data mb-5">
                  <h6>
                    We May Share Your Personal Information In The Following
                    Situations:
                  </h6>
                  <p>
                    <span className="type">With Service Providers : </span> We may
                    share Your personal information with Service Providers to
                    monitor and analyze the use of our Service, and to contact You.
                  </p>

                  <p>
                    <span className="type">For business transfers: </span> We may
                    share or transfer Your personal information in connection with,
                    or during negotiations of, any merger, sale of Company assets,
                    financing, or acquisition of all or a portion of Our business to
                    another company.
                  </p>

                  <p>
                    <span className="type">With Affiliates : </span> We may share
                    Your information with Our affiliates, in which case we will
                    require those affiliates to honor this Privacy Policy.
                    Affiliates include Our parent company and any other
                    subsidiaries, joint venture partners, or other companies that We
                    control or that are under common control with Us.
                  </p>

                  <p>
                    <span className="type">With business partners : </span> We may
                    share Your information with Our business partners to offer You
                    certain products, services, or promotions.
                  </p>

                  <p>
                    <span className="type">With other users : </span> When you share
                    personal information or otherwise interact in public areas with
                    other users, such information may be viewed by all users and may
                    be publicly distributed outside.
                  </p>

                  <p>
                    <span className="type">With Your consent : </span> We may
                    disclose Your personal information for any other purpose with
                    Your consent.
                  </p>
                </div>

                <div className="bottomspace">
                  <div>
                    <h2>Retention of Your Data</h2>
                  </div>
                  <p>
                    The Company will retain Your Data only for as long as is
                    necessary for the purposes set out in this Privacy Policy. We
                    will retain and use Your Data to the extent necessary to comply
                    with our legal obligations (for example, if we are required to
                    retain your data to comply with applicable laws), resolve
                    disputes, and enforce our legal agreements and policies. <br />
                    <br />
                    The Company will also retain Usage Data for internal analysis
                    purposes. Usage Data is generally retained for a shorter period,
                    except when this data is used to strengthen the security or to
                    improve the functionality of Our Service, or We are legally
                    obligated to retain this data for longer periods.
                  </p>
                </div>

                <div className="bottomspace">
                  <div>
                    <h2>Delete Your Data</h2>
                  </div>
                  <p>
                    You have the right to delete or request that We assist in
                    deleting the Personal Data that We have collected about You.{" "}
                    <br />
                    <br />
                    Our Service may give You the ability to delete certain
                    information about You from within the Service.
                    <br />
                    <br />
                    You may update, amend, or delete Your information at any time by
                    signing in to Your Account if you have one, and visiting the
                    account settings section that allows you to manage Your personal
                    information. You may also contact Us to request access to,
                    correct, or delete any personal information that You have
                    provided to Us.
                    <br />
                    <br />
                    Please note, however, that We may need to retain certain
                    information when we have a legal obligation or lawful basis to
                    do so.
                  </p>
                </div>

                <div className=" bold-data bottomspace">
                  <div>
                    <h2>Disclosure of Your Data</h2>
                  </div>
                  <h6>Business Transactions</h6>
                  <p>
                    If the Company is involved in a merger, acquisition, or asset
                    sale, Your Data may be transferred. We will provide notice
                    before <br />
                    Your Personal Data is transferred and becomes subject to a
                    different Privacy Policy.
                    <br />
                    <br />
                    <br />
                  </p>
                  <h6>Law enforcement</h6>
                  <p>
                    Under certain circumstances, the Company may be required to
                    disclose Your Data if required to do so by law or in <br />
                    response to valid requests by public authorities (e.g. a court
                    or a government agency).
                    <br />
                    <br />
                    <br />
                  </p>

                  <h6>Other legal requirements</h6>
                  <p>
                    The Company may disclose Your Data in the good faith belief that
                    such action is necessary to:
                  </p>
                  <p>
                    <FontAwesomeIcon icon="fa-solid fa-caret-right" className="icn-space" />
                    Comply with a legal obligation
                  </p>
                  <p>
                    <FontAwesomeIcon icon="fa-solid fa-caret-right" className="icn-space" />
                    Protect and defend the rights or property of the Company
                  </p>
                  <p>
                    <FontAwesomeIcon icon="fa-solid fa-caret-right" className="icn-space" /> Prevent or
                    investigate possible wrong doing in connection with the Service
                  </p>
                  <p>
                    <FontAwesomeIcon icon="fa-solid fa-caret-right" className="icn-space" /> Protect the
                    personal safety of Users of the Service or the public
                  </p>
                  <p>
                    <FontAwesomeIcon icon="fa-solid fa-caret-right" className="icn-space" />
                    Protect against legal liability
                  </p>
                </div>

                <div className="bottomspace">
                  <div>
                    <h2>Transfer of Your Data</h2>
                  </div>
                  <p className="font1">
                    Your information, including Personal Data, is processed at the
                    Company's operating offices and in any other places where the
                    parties involved in the processing are located. It means that
                    this information may be transferred to — and maintained on —
                    computers located outside of Your state, province, country, or
                    other governmental jurisdiction where the data protection laws
                    may differ from those from Your jurisdiction. <br />
                    <br />
                    Your consent to this Privacy Policy followed by Your submission
                    of such information represents Your agreement to that transfer.{" "}
                    <br />
                    <br />
                    The Company will take all steps reasonably necessary to ensure
                    that Your Data is treated securely and by this Privacy Policy
                    and no transfer of Your Data will take place to an organization
                    or a country unless there are adequate controls in place
                    including the security of Your Data and other personal
                    information.
                  </p>
                </div>

                <div className="bottomspace">
                  <div>
                    <h2>Security of Your Data</h2>
                  </div>
                  <p className="font1">
                    The security of Your Data is important to Us, but remember that
                    no method of transmission over the Internet, or method of
                    electronic storage is 100% secure. While We strive to use
                    commercially acceptable means to protect Your Data, We cannot
                    guarantee its absolute security.
                  </p>
                </div>

                <div className="bottomspace">
                  <div>
                    <h2>Links to Other Websites</h2>
                  </div>
                  <p className="font1">
                    Our Service may contain links to other websites that are not
                    operated by Us. If You click on a third-party link, You will be
                    directed to that third-party's site. We strongly advise You to
                    review the Privacy Policy of every site You visit. <br />
                    We have no control over and assume no responsibility for the
                    content, privacy policies, or practices of any third-party sites
                    or services.
                  </p>
                </div>

                <div className="bottomspace">
                  <div>
                    <h2>Children's Privacy</h2>
                  </div>
                  <p className="font1">
                    Our Service does not address anyone under the age of 13. We do
                    not knowingly collect personally identifiable information from
                    anyone under the age of 13. If You are a parent or guardian and
                    You are aware that Your child has provided Us with Personal
                    Data, please contact Us. If We become aware that We have
                    collected Personal Data from anyone under the age of 13 without
                    verification of parental consent, We take steps to remove that
                    information from Our servers. <br />
                    <br />
                    If We need to rely on consent as a legal basis for processing
                    Your information and Your country requires consent from a
                    parent, We may require Your parent's consent before We collect
                    and use that information.
                  </p>
                </div>

                <div className="bottomspace">
                  <div>
                    <h2>Changes to this Privacy Policy</h2>
                  </div>
                  <p className="font1">
                    We may update Our Privacy Policy from time to time. We will
                    notify You of any changes by posting the new Privacy Policy on
                    this page. <br />
                    <br />
                    We will let You know via email and/or a prominent notice on Our
                    Service, before the change becomes effective and update the
                    "Last updated" date at the top of this Privacy Policy. <br />
                    <br />
                    You are advised to review this Privacy Policy periodically for
                    any changes. Changes to this Privacy Policy are effective when
                    they are posted on this page.
                  </p>
                </div>
              </div>
            </Container>
        </section>
      )}
    </>
  );
}

export default FPrivacyPolicy;

