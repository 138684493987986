import React from 'react'
import './FFooter.css'
import { Container, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Nav from 'react-bootstrap/Nav';
import { NavLink, Link } from 'react-router-dom';

const FFooter = () => {

  return (
    <section className='finance-footer'>
      <Container>
        <Row>
          <Col lg={5} md={4} sm={3} >
            <div className='f-blck-border'></div>
          </Col>
          <Col lg={2} md={4} sm={6}>
            <div className='social-logo'>
              <Link to="https://www.facebook.com/ProfcymaCareerSolutions/" target="_blank">
                <img className='footer-social-icon' src={process.env.PUBLIC_URL + '/assets/Finance/Image/Footer/f_1_.png'} alt="Facebook Logo" />
              </Link>
              <Link to="https://twitter.com/" target="_blank">
                <img className='footer-social-icon' src={process.env.PUBLIC_URL + '/assets/Finance/Image/Footer/twitter.png'} alt="Twitter Logo" />
              </Link>
              <Link to="https://www.instagram.com/profcyma_careersolutions/" target="_blank">
                <img className='footer-social-icon' src={process.env.PUBLIC_URL + '/assets/Finance/Image/Footer/insta.png'} alt="Instagram Logo" />
              </Link>
              <Link to="https://www.linkedin.com/profcyma_careersolutions/" target="_blank">
                <img className='footer-social-icon' src={process.env.PUBLIC_URL + '/assets/Finance/Image/Footer/link.png'} alt="LinkedIn Logo" />
              </Link>
            </div>
          </Col>
          <Col lg={5} md={4} sm={3} >
            <div className='f-blck-border'></div>
          </Col>
        </Row>
        <Row>
          <div className='text-center'>
            <img className='footerlogo' src={process.env.PUBLIC_URL + '/assets/Finance/Image/Footer/Profcyma-LOGO_white.png'} alt="Logo" />
          </div>
        </Row>
        <Row>
          <div className=' link-text justify-content-center mt-3'>
            <Nav variant="underline" defaultActiveKey="/finance/home" className='justify-content-center'>
              <Nav.Item>
                <NavLink exact to="/finance/home" activeClassName="active" className='navlink-footer no-boder'>Home</NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink exact to="/finance/aboutus" activeClassName="active" className='navlink-footer'>About Us</NavLink>
              </Nav.Item>
              {/* <Nav.Item>
                <NavLink exact to="/finance/home-loan" activeClassName="active" className='navlink-footer'>Services</NavLink>
              </Nav.Item> */}
              <Nav.Item>
                <NavLink exact to="/finance/blog" activeClassName="active" className='navlink-footer'>Blog</NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink exact to="/finance/privacy" activeClassName="active" className='navlink-footer'>Privacy Policy</NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink exact to="/finance/loancalculator" activeClassName="active" className='navlink-footer'>Loan Calculator</NavLink>
              </Nav.Item>
            </Nav>
          </div>
          {/* <div className='blk-footer-border'></div> */}
        </Row>
        <Row>
          <div className='text-center'>
            <Link to="https://profcymaglobal.com/" target='___blank'><p className='last-footer-text'>© 2024 All Rights Reserved Terms Of Use | Privacy Policy Developed by <span className='cmpny-name'> Profcyma</span> </p></Link>
          </div>
        </Row>
      </Container>
    </section>
  )
}

export default FFooter