import React, { useState, useContext, useEffect } from 'react';

import { Link } from 'react-router-dom'
import './Step2.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Form from 'react-bootstrap/Form';
import { FormGroup } from 'react-bootstrap';
import { Context } from "../../../../../utils/context";
import { useForm, useFieldArray } from 'react-hook-form';
import { postData } from '../../../../../utils/api';
import Cookies from "js-cookie";
function Step2({ handleNext, handlePrevious }) {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValue: {
      data: [],
    },
  });
 
  const [ID, setID] = useState();
  const [userDetails, setUserDetails] = useState();
  const [activeButtons, setActiveButtons] = useState([]);
  const [questionId, setQuestionId] = useState();
 
  const tooltip = (
    <Tooltip id="tooltip">Delete Question</Tooltip>
  );
  const tooltipone = (
    <Tooltip id="tooltipone">Add Question</Tooltip>
  );

  const { fields, append ,remove} = useFieldArray({
    control,
    name: "data"
  });

  const [question, setquestion] = useState([]);
  const handleButtonClick = async (id) => {
    try {
      setQuestionId(id);
      setActiveButtons(prevActiveButtons =>
        prevActiveButtons.includes(id)
          ? prevActiveButtons.filter(buttonId => buttonId !== id)
          : [...prevActiveButtons, id]
      );
      let questionData;
      let questionDataGet;

      if (ID) {
        questionDataGet = await getData(
          `/job-portal/employer/job-post/additional-question/${Cookies.get('job_id')}/${id}`);
        console.log(questionDataGet, "questionDataGet questionDataGet");
      } else {
        questionData = await getData(`/admin/all-additional-question/${id}`);

      }

      if (questionData?.success) {
        // Flatten the response data and format it for appending
        const formattedData = questionData?.data?.flatMap(value => ({
          question_id: value.id,
          question: value.name,
          screening_question_id: value.screening_question_id,
          status: 1,
          answerData: value.additional_question_answers.map(answer => ({
            answer_one: answer.answer_one,
            answer_two: answer.answer_two,
            answer_one_value: answer.answer_one_value,
            answer_two_value: answer.answer_two_value,
          }))
        }));

        console.log(formattedData, 'llll');

        const existingData = getValues('data');
        const newDataToAppend = formattedData.filter(entry => {
          return !existingData.some(field => field.question_id === entry.question_id);
        });
        append(newDataToAppend);
      } else {
        console.log("after get");
        const formattedData = questionDataGet?.data?.flatMap(value => ({
          question_id: value.id,
          question: value.question,
          screening_question_id: value.type_id,
          status: 1,
          answerData: value?.job_question_answers?.map(answer => ({
            answer_one: answer.question_one,
            answer_two: answer.question_two,
            answer_one_value: answer.ans_one,
            answer_two_value: answer.ans_two,
          }))
        }));

        const existingData = getValues('data');
        const newDataToAppend = formattedData.filter(entry => {
          return !existingData.some(field => field.question_id === entry.question_id);
        });
        append(newDataToAppend);

      }
    } catch (error) {
      console.error("Error handling button click:", error);
    }
  };
  useEffect(() => {
    if (questionId) {
      handleButtonClick(questionId);
    }
  }, [questionId]);

  // const handleDelete = async (index) => {
  //   console.log(getValues(`data[${index}].status`));
  //   await setValue(`data[${index}].status`, 0);
  // }
  // const handleDelete = async (index) => {
    
  //   await setValue(`data[${index}].status`, 0);
  //   // remove(index);
  // }
  const handleDelete = async (index) => {
    await setValue(`data[${index}].status`, 0);
    const updatedData = getValues().data.filter((_, i) => i !== index);
    setValue('data', updatedData);
}

  console.log(fields, "fields, fields");
  const { getData, IMG_URL } = useContext(Context);

  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/admin/all-screening-question`
      );
      setData(response?.data);
      setQuestionId(response?.data[0]?.id);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (!ID) {
      getDataAll();
    }
  }, [ID]);



  const getDataAllGET = async () => {
    try {
      const response = await getData(
        `/job-portal/employer/job-post/additional-question/screening-question/data/${Cookies.get('job_id')}`
      );
      setData(response?.data?.count);
      setQuestionId(response?.data?.rows?.[0]?.type_id || null);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (ID) {
      getDataAllGET();
    }
  }, [ID]);


  const onSubmit = async (d) => {
    console.log(d, "form");
    d.job_id = Cookies.get('job_id');
    // if()
    const res = await postData(`/job-portal/employer/job-post/additional-question`, d);
    if (res?.success) {
      console.log(res?.data?.job_id, "res?.data");

      handleNext();
    }
  };


  const [activeButtonsfields, setActiveButtonsfields] = useState([]);
  useEffect(() => {
    if (fields && fields.length > 1) {
      setActiveButtonsfields([fields[0].id]); // Preselect the button corresponding to the second item
    }
  }, [fields]);

  const getDataAllReset = async () => {
    try {
      const response = await getData(
        `/job-portal/employer/job-post/additional-question/all/data/${Cookies.get('job_id')}`
      );
      setID(response?.data?.id);
      console.log(response?.data, "bbbbbbbbbbbbbbbbbbbbbbbbb");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataAllReset();
  }, [Cookies.get('job_id')]);


  return (
    <>
      <Form onSubmit={() => handleSubmit(onSubmit)} role="form">

        <section className='step2-question'>
          <div className='container'>
            {/* {fields?.map((field, index) => ( */}
            <>

              <div className='top-div '>
                <div>
                  <Link onClick={handlePrevious}>Back </Link>
                </div>
                <div className='heading-div'>
                  <h6>Additional Questions</h6>
                </div>
                <div>
                  <Link onClick={handleNext}>Skip</Link>
                </div>
              </div>

              <div className='screening-que boxes'>
                <div className='row justify-content-center'>
                  <div className='col-xxl-10 col-md-10 col-12'>
                    <div className='subtitle ms-3'>
                      <h6>Add Screening questions :</h6>
                    </div>
                    <div className="btns-div d-contents ">
                      {/* {
                        data?.map((val, index) => (
                          <button
                            key={index}
                            type="button"
                            className={`screening-btns ${activeButtons.includes(index) ? 'screening-btns-active' : ''}`}
                            onClick={() => handleButtonClick(val?.id)}
                          >
                            {val?.name}
                          </button>
                        ))} */}
                      {data?.map((val, index) => (
                        <button
                          key={index}
                          type="button"
                          className={`screening-btns ${index === 0 ? 'screening-btns-active' : ''}`}
                          onClick={() => handleButtonClick(val?.id ?? val?.type_id)}
                        >
                          {val?.name}
                        </button>
                      ))}
                    </div>
                  </div>
                </div>
              </div>


              <div className='boxes form-container'>
                {fields?.map((val, index) => (
                  (val?.screening_question_id === questionId || val?.type_id === questionId) && (
                    val?.status == 1 && (
                      <div className='small-cards' key={index}>
                        <div className='row'>
                          <div className='col-xxl-10 col-10 d-flex'>
                            <p className='me-3'>{index + 1}</p>
                            <p>{val?.question} {val?.status}</p>
                          </div>
                          <div className='col-xxl-2 col-2 text-end d-flex justify-content-end'>
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              overlay={tooltip}
                            >
                              {/* <button className="mt-3" type="button" onClick={() => remove(index)}>Remove Variant</button> */}
                              <div className='delete' onClick={() => handleDelete(index)}>
                              {/* <div className='delete'  onClick={() => remove(index)}> */}
                                <FontAwesomeIcon icon="fa-solid fa-trash" />
                              </div>
                            </OverlayTrigger>
                          </div>
                        </div>
                        {val?.answerData?.map((answer, answerIndex) => (
                          <div className='row ms-3' key={answerIndex}>
                            <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-4 col-12 '>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{answer?.answer_one}</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Name of address"
                                  defaultValue={answer?.answer_one_value}
                                  {...register(`data[${index}].answerData[${answerIndex}].answer_one_value`, {
                                    required: "Name of address is required",
                                  })}
                                />
                              </Form.Group>
                            </div>
                            <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-12'>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>{answer?.answer_two}</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Enter Name of address"
                                  defaultValue={answer?.answer_two_value}
                                  {...register(`data[${index}].answerData[${answerIndex}].answer_two_value`, {
                                    required: "Name of address is required",
                                  })}
                                />
                              </Form.Group>
                            </div>
                            <div className='col-xxl-6 col-xl-6 col-lg-5 col-md-5 col-12'>
                              <div className='ckeck-boxs'>
                                <Form.Check aria-label="option 1" inline label="Must have qualification" />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )


                  )
                ))}
              </div>


            </>


            <div className="save-btn-div ">
              <button className="back-btn" onClick={handlePrevious}>Back</button>
              <button className="save-btn"
                // onClick={handleNext}
                onClick={handleSubmit(onSubmit)}
                type="submit"
              >Save & Next</button>
            </div>
          </div>

        </section>
      </Form>
    </>
  )
}

export default Step2