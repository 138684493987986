import React, { useState, Component } from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';
import '../FinanceLoanCalci.css'
import CanvasJSReact from '@canvasjs/react-charts';

const EightTab = () => {
    var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;
    const [monthlyInvestment, setMonthlyInvestment] = useState(500000);
    const [interestRate, setInterestRate] = useState(5);
    const [timePeriod, setTimePeriod] = useState(20);
    const handleChange1 = (event) => {
        let input = event.target.value;
        if (/^\d*$/.test(input)) {
            if (input > 10000000) {
                input = 10000000;
            }
            setMonthlyInvestment(input);
        }
        // setMonthlyInvestment(event.target.value);
    };

    // const handleChange2 = (event) => {
    //     let input = event.target.value;
    //     if (/^\d*$/.test(input)) {
    //         if (input > 20) {
    //             input = 20;
    //         }
    //         setInterestRate(input);
    //     }
    //     // setInterestRate(event.target.value);
    // };
    const handleChange2 = (event) => {
        let input = event.target.value;
        if (/^\d*\.?\d*$/.test(input)) {
            if (input > 15) {
                input = 15;
            }
            setInterestRate(input);
        }
        // setInterestRate(event.target.value);
    };

    const handleChange3 = (event) => {
        let input = event.target.value;
        if (/^\d*$/.test(input)) {
            if (input > 25) {
                input = 25;
            }
            setTimePeriod(input);
        }
        // setTimePeriod(event.target.value);
    };
    const percentageComplete1 = (monthlyInvestment / 10000000) * 98;
    // const percentageComplete2 = (interestRate / 20) * 98;
    const percentageComplete2 = ((interestRate - 1) / (15 - 1)) * 100;
    const percentageComplete3 = ((timePeriod - 1) / (25 - 1)) * 98;

    // const investedAmount = monthlyInvestment * timePeriod * 12;
    // const totalInterest = (monthlyInvestment * timePeriod * 12 * (interestRate / 100)).toFixed(2);
    // const estimatedReturns = (parseFloat(investedAmount) + parseFloat(totalInterest)).toFixed(2);

    const calculateMaturityAmount = (P, R, N, t) => {
        let maturityAmount = 0;
        const monthlyRate = R / (N * 100); // Convert annual rate to monthly rate
        for (let i = 0; i < t * 12; i++) {
            maturityAmount += P * Math.pow((1 + monthlyRate), (N * (t - i / 12)));
        }
        return maturityAmount.toFixed(1);
    };

    // Constants for RD calculation
    const N = 4; // Quarterly compounding
    const investedAmount = (monthlyInvestment * timePeriod * 12).toFixed(1);
    const estimatedReturns = calculateMaturityAmount(monthlyInvestment, interestRate, N, timePeriod);

    // Prepare data for pie chart
    const totalInterest = (estimatedReturns - investedAmount).toFixed(1);

    const paychartinvestedAmount = (investedAmount / estimatedReturns).toFixed(2);
    const paycharttotalInterest = (totalInterest / estimatedReturns).toFixed(2);

    const options = {
        height: 200,
        width: 200,
        data: [{
            type: "pie",
            showInLegend: true,
            legendText: "{label}",
            indexLabel: "{y}%",
            indexLabelPlacement: "inside",
            indexLabelFontColor: "white",
            dataPoints: [
                { y: parseFloat(paychartinvestedAmount) * 100, label: "Invested Amount", color: '#FF8600' },
                { y: parseFloat(paycharttotalInterest) * 100, label: "Total Interest", color: '#3D4D10' },
            ]
        }]
    };

    const toString = (value) => {
        return (value).toLocaleString();
    }
    return (
        <>
            <section className='RD-intrest'>
                <div>
                    <div className="range-div">
                        <div className="main-ranges-div">
                            <div className="price-div">
                                <p>Monthly investment</p>
                                <div>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            placeholder="50000"
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                            value={monthlyInvestment}
                                            onChange={handleChange1}
                                        />
                                        <InputGroup.Text id="basic-addon2">₹</InputGroup.Text>
                                    </InputGroup>
                                </div>
                            </div>
                            <div className="price-div mt-5">
                                <p>₹5000</p> <p>₹10000000</p>
                            </div>
                            <div className="range-bar-container">
                                <p
                                    className="range-value"
                                    style={{
                                        // left: `calc(${percentageComplete1}% )`,
                                        left: `calc(${percentageComplete1}% - 30px)`,
                                    }}
                                >
                                    {/* {monthlyInvestment} */}
                                </p>
                                <input
                                    type="range"
                                    min="5000"
                                    max="10000000"
                                    step={10000}
                                    value={monthlyInvestment}
                                    onChange={handleChange1}
                                    className="range-bar"
                                />
                                <div
                                    className="range-bar-color"
                                    style={{
                                        width: `${percentageComplete1}%`,

                                    }}
                                ></div>
                            </div>

                        </div>
                        <div className="main-ranges-div">
                            <div className="price-div mt-5">
                                <p>Rate of interest (p.a)</p>
                                <div>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            placeholder="9"
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                            value={interestRate}
                                            onChange={handleChange2}
                                        />
                                        <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                    </InputGroup>
                                </div>
                            </div>
                            <div className="price-div">
                                <p>1%</p> <p>15%</p>
                            </div>
                            <div className="range-bar-container">
                                <p
                                    className="range-value"
                                    style={{
                                        left: `calc(${percentageComplete2}% )`,
                                    }}
                                >
                                    {/* {interestRate} */}
                                </p>
                                <input
                                    type="range"
                                    min="1"
                                    max="15"
                                    step="0.1"
                                    value={interestRate}
                                    onChange={handleChange2}
                                    className="range-bar"
                                />
                                <div
                                    className="range-bar-color"
                                    style={{
                                        width: `${percentageComplete2}%`,

                                    }}
                                ></div>
                            </div>

                        </div>
                        <div className="main-ranges-div">
                            <div className="price-div mt-5">
                                <p>Time period</p>
                                <div>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            placeholder="9"
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                            value={timePeriod}
                                            onChange={handleChange3}
                                        />

                                        <InputGroup.Text id="basic-addon2">Yr</InputGroup.Text>
                                    </InputGroup>
                                </div>
                            </div>
                            <div className="price-div">
                                <p>1</p> <p>25</p>
                            </div>
                            <div className="range-bar-container">
                                <p
                                    className="range-value"
                                    style={{
                                        left: `calc(${percentageComplete3}% )`,
                                    }}
                                >
                                    {/* {timePeriod} */}
                                </p>
                                <input
                                    type="range"
                                    min="1"
                                    max="25"
                                    value={timePeriod}
                                    onChange={handleChange3}
                                    className="range-bar"
                                />
                                <div
                                    className="range-bar-color"
                                    style={{
                                        width: `${percentageComplete3}%`,

                                    }}
                                ></div>
                            </div>

                        </div>

                    </div>


                    <div className="calculation-div mt-5">
                        <div className="row">
                            <div className="col-xxl-9 col-xl-8 col-lg-12 col-md-12 ">
                                <div className="loan-table">
                                    <Table >
                                        <thead>
                                            <tr>
                                                <th>Invested amount</th>
                                                <th>Est. returns</th>
                                                <th>Total Value</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>₹{parseInt(investedAmount).toLocaleString()}</td>
                                                <td>₹{Math.floor(totalInterest).toLocaleString()}</td>
                                                <td>₹{parseInt(estimatedReturns).toLocaleString()}</td>


                                            </tr>


                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-4 col-lg-12 col-md-12 ">
                                <div className="pie-chart-div mx-auto" >
                                    <div className="second-chart">
                                        <CanvasJSChart options={options} className="pie" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="btn-div ">
                        <button className="apply-btn">Apply Now</button>
                    </div> */}
                </div>

            </section>
        </>
    )
}

export default EightTab