import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./TopServicesLoan.css";
import FHomeLoanModal from "../../F-Service/F-Loan/FHomeLoanModal/FHomeLoanModal";
import ServiceFormModal from "../../F-Service/Service_form/F-Insurance/FHomeInsuranceModal/ServiceFormModal";
import AOS from "aos";
import "aos/dist/aos.css";
import Cookies from "js-cookie";

const TopServicesLoan = () => {
  const [loanModalShow, setLoanModalShow] = useState(false);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [serviceId, setServiceId] = useState(null);
  const [serviceName, setServiceName] = useState("");
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  const [modalShow, setModalShow] = useState(false);
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const getDataAll = async () => {
    try {
      const response = await getData(`/finance/service/f-service/1`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // console.log(data, 'loannnn');

  const setService = (id) => {
    Cookies.set("service_id", id, {
      expires: 1,
    });
    Cookies.set("service_type_id", 1, {
      expires: 1,
    });
    // setTypeId(id);
  };

  const handleKnowMoreClick = async (id, name) => {
    await setService(id)
    await setServiceId(id); // Set the service_id
    await setServiceName(name)
    setLoanModalShow(true); // Open the modal
  };


  useEffect(() => {
    getDataAll();
  }, []);

  useEffect(() => {
    AOS.init({ once: true });
  }, [])

  return (
    <>
      {data && data?.length > 0 &&
        <section className="finance-top-loan">
          <div className="container">
            <p className="sub-heading">Loan</p>
            <h4 className="heading">Top Services for Loan</h4>

            <div className="loan-swiper" data-aos="fade-up" data-aos-duration="3000">
              <Swiper
                breakpoints={{
                  576: {
                    slidesPerView: 2,
                  },

                  768: {
                    slidesPerView: 2,
                  },

                  1024: {
                    slidesPerView: 3,
                  },
                  1200: {
                    slidesPerView: 4,
                  },
                }}
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
                onSwiper={(swiper) => setSwiperInstance(swiper)}
              >
                {/* Map over the slidesData array to generate SwiperSlide components */}
                {data?.map((image, index) => (
                  <SwiperSlide key={index}>
                    <div className="loancard">
                      <img className="loan-img" src={IMG_URL + image?.image} alt="Banner" />
                      <div className="fhsuihiuh"></div>
                      <div className="bold-text">
                        <h3>{image?.name}</h3>
                      </div>
                      <div className="hover-text">
                        <div className="hover-desc">
                          <p>{htmlToReactParser.parse(image.description) || ""}</p>
                        </div>
                        <div className="hover-btn">
                          {/* <button
                        className="know-more"
                        onClick={() => setLoanModalShow(true)}
                      >
                        Know More
                        <FontAwesomeIcon
                          icon="fa-solid fa-angles-right"
                          className="arrow-icon "
                        />
                      </button> */}
                          <button
                            className="know-more"
                            onClick={() => {
                              handleKnowMoreClick(image?.id, image?.name)
                            }}
                          >
                            Know More
                            <FontAwesomeIcon
                              icon="fa-solid fa-angles-right"
                              className="arrow-icon "
                            />
                          </button>
                        </div>
                      </div>
                      <div className="overlay-color"></div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

              <ServiceFormModal
                show={loanModalShow}
                onHide={() => setLoanModalShow(false)}
                serviceId={serviceId}
                serviceName={serviceName}
              //serviceId={serviceId}
              />

              <div className="silder-btn">
                <div
                  className="back-btn"
                  onClick={() => handleMainSliderClick("prev")}
                ></div>
                <div
                  className="next-btn"
                  onClick={() => handleMainSliderClick("next")}
                ></div>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  );
};

export default TopServicesLoan;
