import React, { useState, useEffect, useContext } from "react";
import JPbanner from './JPbanner/JPbanner'
import JPforyou from './JPforyou/JPforyou'
import { useLocation } from 'react-router-dom';
const Jpjobs = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const [job_type_id, setJobTypeId] = useState();
  const [city_id, setCityId] = useState();
  const [job_title, setJobTitle] = useState();

  const [max_experience, setMaxExperience] = useState();
  const [min_experience, setMinExperience] = useState();
  const [maxSalary, setMaxSalary] = useState();
  const [minSalary, setMinSalary] = useState();
  const [category, setCategory] = useState();
  const [date, setDate] = useState();

  const [from, setFrom] = useState();

  const query = queryParams.get('query');

  const jobtitle = queryParams.get('job_title');
  const cityId = queryParams.get('city');

  // if (jobtitle) {
  //   setJobTitle(jobtitle);
  // }
  // if (cityId) {
  //   setCityId(cityId);
  // }

  // const jobTypeId = queryParams.get('job_type_id');
  // const companyName = queryParams.get('company_name');
  // const joblocation = queryParams.get('joblocation');
  // const experience = queryParams.get('experienceNumber');
  // const city = queryParams.get('city');
  // const max_experience = queryParams.get('max_experience');
  // const min_experience = queryParams.get('min_experience');
  // const maxSalary = queryParams.get('maxSalary');
  // const minSalary = queryParams.get('minSalary');
  // const title = queryParams.get('title');
  // const selectedJobType = queryParams.get('selectedJobType');

  // const category = queryParams.get('category');
  // const date = queryParams.get('date');

  console.log("data.job_type_id", job_type_id);
  console.log("data.city_id", city_id);
  console.log("data.job_title", job_title);
  return (
    <>
      <JPbanner
        setJobTypeId={setJobTypeId}
        setCityId={setCityId}
        setJobTitle={setJobTitle}
        setMaxExperience={setMaxExperience}
        setMinExperience={setMinExperience}
        setMaxSalary={setMaxSalary}
        setMinSalary={setMinSalary}
        setCategory={setCategory}
        setDate={setDate}
        setFrom={setFrom}
      />
      <JPforyou
        job_type_id={job_type_id}
        city_id={city_id}
        job_title={job_title}

        max_experience={max_experience}
        min_experience={min_experience}
        maxSalary={maxSalary}
        minSalary={minSalary}
        category={category}
        date={date}
        from={from}

        setJobTypeId={setJobTypeId}
        setCityId={setCityId}
        setJobTitle={setJobTitle}
        setFrom={setFrom}

        setMaxExperience={setMaxExperience}
        setMinExperience={setMinExperience}
        setMaxSalary={setMaxSalary}
        setMinSalary={setMinSalary}
        setCategory={setCategory}
        setDate={setDate}

      />
    </>
  )
}

export default Jpjobs



