import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';
import '../FinanceLoanCalci.css';
import CanvasJSReact from '@canvasjs/react-charts';

const NinthTab = () => {
    var CanvasJS = CanvasJSReact.CanvasJS;
    var CanvasJSChart = CanvasJSReact.CanvasJSChart;
    const [principalAmount, setPrincipalAmount] = useState(500000);
    const [interestRate, setInterestRate] = useState(9);
    const [timePeriod, setTimePeriod] = useState(20);
    const [compoundingFrequency, setCompoundingFrequency] = useState(4); // Quarterly compounding

    const handleChange1 = (event) => {
        let input = event.target.value;
        if (/^\d*$/.test(input)) {
            if (input > 10000000) {
                input = 10000000;
            }
            setPrincipalAmount(input);
        }
    };

    const handleChange2 = (event) => {
        let input = event.target.value;
        if (/^\d*\.?\d*$/.test(input)) {
            if (input > 15) {
                input = 15;
            }
            setInterestRate(input);
        }
    };

    const handleChange3 = (event) => {
        let input = event.target.value;
        if (/^\d*$/.test(input)) {
            if (input > 25) {
                input = 25;
            }
            setTimePeriod(input);
        }
    };

    const percentageComplete1 = (principalAmount / 10000000) * 98;
    const percentageComplete2 = ((interestRate - 1) / (15 - 1)) * 100;
    const percentageComplete3 = ((timePeriod - 1) / (25 - 1)) * 98;

    // Calculate FD maturity amount
    const principal = parseFloat(principalAmount);
    const rate = parseFloat(interestRate) / 100;
    const years = parseFloat(timePeriod);
    const frequency = parseInt(compoundingFrequency);

    const maturityAmount = (principal * Math.pow((1 + rate / frequency), frequency * years)).toFixed(2);
    const totalInterest = (maturityAmount - principal).toFixed(2);

    const paychartInvestedAmount = (principal / maturityAmount * 100).toFixed(2);
    const paychartTotalInterest = (totalInterest / maturityAmount * 100).toFixed(2);

    const options = {
        height: 200,
        width: 200,
        data: [{
            type: "pie",
            showInLegend: true,
            legendText: "{label}",
            indexLabel: "{y}%",
            indexLabelPlacement: "inside",
            dataPoints: [
                { y: parseFloat(paychartInvestedAmount), label: "Invested Amount", color: '#FF8600' },
                { y: parseFloat(paychartTotalInterest), label: "Total Interest", color: '#3D4D10' },
            ]
        }]
    };

    return (
        <>
            <section className='RD-intrest'>
                <div>
                    <div className="range-div">
                        <div className="main-ranges-div">
                            <div className="price-div">
                                <p>Principal Amount</p>
                                <div>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            placeholder="50000"
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                            value={principalAmount}
                                            onChange={handleChange1}
                                        />
                                        <InputGroup.Text id="basic-addon2">₹</InputGroup.Text>
                                    </InputGroup>
                                </div>
                            </div>
                            <div className="price-div mt-5">
                                <p>₹5000</p> <p>₹10000000</p>
                            </div>
                            <div className="range-bar-container">
                                <p
                                    className="range-value"
                                    style={{
                                        left: `calc(${percentageComplete1}% - 30px)`,
                                    }}
                                >
                                    {/* ₹{parseInt(principalAmount).toLocaleString()} */}
                                </p>
                                <input
                                    type="range"
                                    min="5000"
                                    max="10000000"
                                    step={10000}
                                    value={principalAmount}
                                    onChange={handleChange1}
                                    className="range-bar"
                                />
                                <div
                                    className="range-bar-color"
                                    style={{
                                        width: `${percentageComplete1}%`,
                                    }}
                                ></div>
                            </div>
                        </div>
                        <div className="main-ranges-div ">
                            <div className="price-div mt-5">
                                <p>Interest Rate</p>
                                <div>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            placeholder="5"
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                            value={interestRate}
                                            onChange={handleChange2}
                                        />
                                        <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                                    </InputGroup>
                                </div>
                            </div>
                            <div className="price-div">
                                <p>1%</p> <p>15%</p>
                            </div>
                            <div className="range-bar-container">
                                <p
                                    className="range-value"
                                    style={{
                                        left: `calc(${percentageComplete2}% )`,
                                    }}
                                >
                                    {/* {interestRate}% */}
                                </p>
                                <input
                                    type="range"
                                    min="1"
                                    max="15"
                                    step="0.1"
                                    value={interestRate}
                                    onChange={handleChange2}
                                    className="range-bar"
                                />
                                <div
                                    className="range-bar-color"
                                    style={{
                                        width: `${percentageComplete2}%`,
                                    }}
                                ></div>
                            </div>
                        </div>
                        <div className="main-ranges-div">
                            <div className="price-div mt-5">
                                <p>Time period</p>
                                <div>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            placeholder="9"
                                            aria-label="Recipient's username"
                                            aria-describedby="basic-addon2"
                                            value={timePeriod}
                                            onChange={handleChange3}
                                        />
                                        <InputGroup.Text id="basic-addon2">Yr</InputGroup.Text>
                                    </InputGroup>
                                </div>
                            </div>
                            <div className="price-div">
                                <p>1</p> <p>25</p>
                            </div>
                            <div className="range-bar-container">
                                <p
                                    className="range-value"
                                    style={{
                                        left: `calc(${percentageComplete3}% )`,
                                    }}
                                >
                                    {/* {timePeriod} */}
                                </p>
                                <input
                                    type="range"
                                    min="1"
                                    max="25"
                                    value={timePeriod}
                                    onChange={handleChange3}
                                    className="range-bar"
                                />
                                <div
                                    className="range-bar-color"
                                    style={{
                                        width: `${percentageComplete3}%`,
                                    }}
                                ></div>
                            </div>
                        </div>
                    </div>

                    <div className="calculation-div mt-5">
                        <div className="row">
                            <div className="col-xxl-9 col-xl-8 col-lg-12 col-md-12 ">
                                <div className="loan-table">
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th>Invested amount</th>
                                                <th>Est. returns</th>
                                                <th>Total Value</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>₹{parseInt(principalAmount).toLocaleString()}</td>
                                                <td>₹{parseInt(totalInterest).toLocaleString()}</td>
                                                <td>₹{parseInt(maturityAmount).toLocaleString()}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            <div className="col-xxl-3 col-xl-4 col-lg-12 col-md-12 ">
                                <div className="pie-chart-div mx-auto">
                                    <div className="second-chart">
                                        <CanvasJSChart options={options} className="pie" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default NinthTab;
