import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import Animation from "../../../Animation/Animation";

const SeventhCredit = (props) => {
    useEffect(() => {
        let timer;
        if (props.show) {
            timer = setTimeout(() => {
                props.onHide();
            }, 3000);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [props.show, props.onHide]);

    return (
        <section className="first-credit">
            <Modal
                className="fenquiry-modal modal-dialog-scrollable"
                {...props}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Body>
                    <div className="text-center">
                        <Animation />
                        <p className="credit-score-text">Downloaded Successfully</p>
                    </div>
                </Modal.Body>
            </Modal>
        </section>
    );
};

export default SeventhCredit;



