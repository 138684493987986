import React, { useState, useEffect } from "react";
import "./EnquiryForm.css";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FirstCredit from "../CreditReportModal/FirstCredit/FirstCredit";
// import AOS from "aos";
// import "aos/dist/aos.css";

const EnquiryForm = (props) => {
  const [modalShow, setModalShow] = useState(false);
  // useEffect(() => {
  //   AOS.init({ once: true });
  // }, [])

  return (
    <>
      <section className="enquriyform-section" >
        <Container>
          <div className="enquiry-main" >
            <Row>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="my-auto"
              >
                <div className="enquiry-txt">
                  <div className="top-txt">
                    <p className="enqur-txt">Inquiry Form</p>
                    <div className="right-line"></div>
                  </div>
                  <h2>
                    Monitor Your Financial Health :<br />
                    Free Credit Score.
                  </h2>
                  <p className="gain">
                    Gain financial insight, your key to informed decisions.
                  </p>
                </div>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="enquiry-txt">
                  <div className="enquiryimgs">
                    <img
                      className="enquiry-img"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Finance/Image/Homepage/enuiryform.png"
                      }
                      alt="Logo"
                    />
                  </div>
                  <div className="getfree">
                    <button
                      className="getfreebutton"
                      onClick={() => setModalShow(true)}
                    >
                      Get Free Credit Score
                      <FontAwesomeIcon
                        icon="fa-solid fa-angles-right"
                        className="arrow-icon "
                      />
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <FirstCredit show={modalShow} onHide={() => setModalShow(false)} />
    </>
  );
};

export default EnquiryForm;
