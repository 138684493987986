import { React, useRef, useEffect, useState, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Navigation } from "swiper/modules";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Link } from "react-router-dom";
import "../HeaderTabs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Context } from "../../../../utils/context";

const CommonCourses = ({ handleLinkClick, type_id }) => {

  const { IMG_URL, getData } = useContext(Context);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const handleMainSliderClick = (direction) => {
    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };
  const [courseId, setCourseId] = useState(null);
  const [courses, setCourses] = useState([]);
  const [universityId, setUniversityId] = useState(null);
  const [specialization, setSpecialization] = useState([]);
  const [activeCourseKey, setActiveCourseKey] = useState(null);
  const [activeUniversityKey, setActiveUniversityKey] = useState(null);

  const getDataAll = async () => {
    try {
      const response = await getData(
        `/without-login/ed-tech/courses/common/common-type/courses/${type_id}`
      );

      setCourses(response?.data);
      if (response?.data.length > 0) {
        setCourseId(response?.data[0].id);
        setActiveCourseKey(response?.data[0].id);
        if (response?.data[0]?.cource_universities?.length > 0) {
          setUniversityId(response?.data[0]?.cource_universities[0]?.university?.id);
          setActiveUniversityKey(response?.data[0]?.cource_universities[0]?.university?.id);
        }
      }

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataAll();
  }, [type_id]);

  const getSpecializationAll = async () => {
    try {
      const response = await getData(`/without-login/ed-tech/courses/common/common-type/specialization?course_id=${courseId}&university_id=${universityId}`);
      setSpecialization(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (courseId && universityId) {
      getSpecializationAll();
    }
  }, [courseId, universityId]);

  const handleIdCourse = (id) => {
    setCourseId(id);
    setActiveCourseKey(id); // Setting active course key
    const selectedCourse = courses.find(course => course.id === id);
    if (selectedCourse && selectedCourse.cource_universities.length > 0) {
      setUniversityId(selectedCourse.cource_universities[0].university.id);
      setActiveUniversityKey(selectedCourse.cource_universities[0].university.id);
    }
  };

  const handleIdSpecialization = (id) => {
    setUniversityId(id);
    setActiveUniversityKey(id); // Setting active university key
  };

  return (
    <>
      <section>
        <div className="pg-course-tab">
          <Tab.Container
            id="left-tabs-example"
            activeKey={activeCourseKey}
          >
            <Nav variant="pills" className="">
              {courses?.map((val) => (
                <Nav.Item key={val?.id}>
                  <Nav.Link
                    eventKey={val?.id}
                    onClick={() => {
                      handleIdCourse(val?.id);
                    }}
                  >
                    {val?.name}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>

            <Tab.Content className="inner-tab-sec">
              {courses?.map((val) => (
                <Tab.Pane eventKey={val?.id} key={val?.id}>
                  <div className="row card-row ">
                    <div className="link-div">
                      <Link
                        to={`/ed-tech/ed-tech/courses/common-courses/${val?.id}`}
                        onClick={handleLinkClick}
                      >
                        Go To Course
                        <FontAwesomeIcon
                          className="arrow-icon"
                          icon="fa-solid fa-arrow-left"
                        />
                      </Link>
                    </div>

                    <Tab.Container
                      id="left-tabs-example"
                      activeKey={activeUniversityKey}
                    >
                      <Nav variant="pills" className="university-tabs">
                        <Swiper
                          slidesPerView={2}
                          spaceBetween={30}
                          navigation={{
                            clickable: true,
                          }}
                          autoplay={{
                            clickable:3000,
                          }}
                          modules={[Navigation, Autoplay]}
                          breakpoints={{
                            992: {
                              slidesPerView: 2,
                            },
                            1200: {
                              slidesPerView: 3,
                            },
                            1800: {
                              slidesPerView: 3,
                            },
                          }}
                          className="mySwiper"
                          onSwiper={(swiper) => setSwiperInstance(swiper)}
                        >
                          {val?.cource_universities?.map((val1) =>
                            <SwiperSlide key={val1?.id}>
                              <Nav.Item>
                                <Nav.Link
                                  eventKey={val1?.university?.id}
                                  onClick={() => {
                                    handleIdSpecialization(val1?.university?.id);
                                  }}
                                >
                                  <div className="img-div">
                                    <img
                                      className="logo"
                                      src={`${IMG_URL}${val1?.university?.image}`}
                                      alt="Logo"
                                    />
                                    <h6>{val1?.university?.name}</h6>
                                  </div>
                                </Nav.Link>
                              </Nav.Item>
                            </SwiperSlide>
                          )}
                        </Swiper>
                      </Nav>

                      <Tab.Content className="universitycontents">
                        {val?.cource_universities?.map((val1) => (
                          <Tab.Pane
                            eventKey={val1?.university?.id}
                            key={val1?.id}
                          >
                            <div className="row">
                              {specialization?.map((specialization) => (
                                <div className="col-lg-4" key={specialization?.id}>
                                  <Link
                                    to={`/ed-tech/specialization/${specialization?.id}`}
                                    onClick={handleLinkClick}
                                  >
                                    <div className="specilization-card">
                                      <img
                                        className="logo"
                                        src={`${IMG_URL}${specialization?.image}`}
                                        alt="Logo"
                                      />
                                      <p>{specialization?.name}</p>
                                    </div>
                                  </Link>
                                </div>
                              ))}
                            </div>
                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </Tab.Pane>
              ))}
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default CommonCourses;
