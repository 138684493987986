import React, { useContext, useEffect, useState, useRef } from "react";
import Question1 from "./Question1";
import { Link, useNavigate } from "react-router-dom";
import "./AllQuestion.css";
import Question2 from "./Question2";
import Question3 from "./Question3";
import Question4 from "./Question4";
import FirstStep from "./First_step/FirstStep";
import { Button } from "react-bootstrap";
import ResetModal from "../../Jp-modals/ResetModal/ResetModal";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from "../../../../../utils/context";
import { applyPost } from "../../../../../utils/apis/common/Common";
import { useParams } from "react-router-dom";

const SkillQuestions = () => {

  const { id } = useParams();
  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    watch,
    trigger,
    setValue
  } = useForm();


  const [currentStep, setCurrentStep] = useState("first");
  const [modalShow, setModalShow] = useState(false);

  const [resume, setResume] = useState();

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };

  const openModal = () => {
    setModalShow(true);

    setTimeout(() => {
      setModalShow(false);
      window.location.pathname = "/job-portal/jobseeker/dashboard"
    }, 3000);
  };


  const onSubmit = async (data) => {
    openModal(true);

  }


  const [jobApplyID, setJobApplyID] = useState();

  const handleJobApply = async () => {
    const data = new FormData();
    if (resume) {
      data.append('resume', resume);
    } 
    data.append("jobdetail_id", id);
    const res = await applyPost(data);
    if (res?.success) {
      await setJobApplyID(res?.data?.id);
      nextStep();
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }

  const [getQuestionsData, setQuestionsDataData] = useState();
  const getQuestions = async (id) => {
    const res = await getData(`/job-portal/job-seeker/applied-job/get-quetion/${id}`);
    if (res?.success) {
      setQuestionsDataData(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  let [questionIndex, setQuestionIndex] = useState(0);

  const totalSteps = getQuestionsData?.length;

  var nextStep = async () => {
    if (currentStep == "first") {
      var curret = questionIndex;
    } else {
      var curret = questionIndex + 1;
      questionIndex = questionIndex + 1;
    }
    await setQuestionIndex(questionIndex);
    setCurrentStep(curret);
    renderStep();
  };

  var backStep = async () => {
    if (currentStep == 0) {
      var curret = "first";
    } else {
      var curret = questionIndex - 1;
      questionIndex = questionIndex - 1;
    }
    await setQuestionIndex(questionIndex);
    setCurrentStep(curret);
    renderStep();
  };


  useEffect(() => {
    getQuestions(id);
  }, [])

  const renderStep = () => {
    switch (currentStep) {
      case "first":
        return <FirstStep setResume={setResume} nextStep={nextStep} handleJobApply={handleJobApply} />;
      case questionIndex:
        return <Question1 getQuestionsData={getQuestionsData[questionIndex]} jobApplyID={jobApplyID} nextStep={nextStep} totalSteps={totalSteps} backStep={backStep} questionIndex={questionIndex} />;
      default:
        return null;
      // case 3:
      //   return <Question2 />;
      // case 4:
      //   return <Question3 />;
      // case 5:
      //   return <Question4 />;
    }
  };



  // const renderNavigationButtons = () => {
  //   if (currentStep == 1) {
  //     return (

  //     );
  //   } else if (currentStep > 1 && currentStep < totalSteps) {
  //     return (
  //       <>
  //       </>
  //     );
  //   } else if (currentStep === totalSteps) {
  //     return (
  //       <div className="navigation-buttons">
  //         <div className="apply-btn-holder">

  //           <Button className="apply_now_btn" onClick={handleSubmit(onSubmit)}>Done</Button>

  //         </div>

  //         <ResetModal
  //           show={modalShow}
  //           onHide={() => setModalShow(false)}
  //           text={"Your Application has been Submitted Successfully!"}
  //         />
  //       </div>


  //     );
  //   } else if (currentStep === 2) {

  //   }
  // };

  const renderHeadingText = () => {
    switch (currentStep) {
      case "first":
        return "Attach Resume for Better Outcomes";
      case questionIndex:
        return "Question " + (currentStep + 1) + "/" + (totalSteps);

      // case 3:
      //   return "Question 2 / 4";
      // case 4:
      //   return "Question 3 / 4";
      // case 5:
      //   return "Question 4 / 4";
      default:
        return "Attach Resume for Better Outcomes";
    }
  };

  return (
    <div className="QuestionsStepFrom">
      <div className="container">
        <div className="StepForm">
          {/* Common Header */}
          <div className="heading-holder">
            <div className="row me-0 ms-0">
              <div className="col-md-4">
                <Link to={`/job-portal/search/job-description/${id}`}>
                  <div className="BacktoCompanies">
                    <p className="">Back to Job description</p>
                  </div>
                </Link>
              </div>
              <div className="col-md-4">
                <div className="heading-text">
                  <h6>{renderHeadingText()}</h6>
                </div>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>

          <div className="shadow-container">
            <div className="progress-indicator">
              {/* Progress Indicator */}
              <div
                className="progress-style"
                style={{
                  width: `${currentStep * 20}%`,
                  height: "7px",
                  background: "#E88501",
                  transition: "width 0.3s ease",
                }}
              />
            </div>

            {/* Render current step */}
            {renderStep()}
            {/* {renderNavigationButtons()} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillQuestions;
