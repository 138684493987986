import React, { useRef, useState } from 'react'

import JoditEditor from "jodit-react";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import './EmpAwardEditModal.css'

const EmpAwardEditModal = (props) => {
    const [show, setShow] = useState(false);
    const editor = useRef(null);
    const [content, setContent] = useState("");
    return (
        <>

            <section className='Emp-edit-award'>
                <Modal
                    {...props}
                    size="lg"
                    className="EmpAwardEditModal-section form-container"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header >
                        <Modal.Title id="contained-modal-title-vcenter">Edit Award</Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className='row'>
                            <div className='col-xxl-10 col-xl-10 col-lg-9 col-md-9 col-12'>
                                <Form>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Award Name</Form.Label>
                                        <Form.Control type="text" placeholder="Best Achiever of Year" />
                                    </Form.Group>

                                </Form>
                            </div>
                            <div className='col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-12'>
                                <Form>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Award Year</Form.Label>
                                        <Form.Control type="text" placeholder="2017 - 18" />
                                    </Form.Group>

                                </Form>
                            </div>
                            <div className='col-12'>
                                <Form.Label>Description</Form.Label>
                                <JoditEditor
                                    ref={editor}
                                    value={content}
                                    tabIndex={1}
                                    onBlur={(newContent) =>
                                        setContent(newContent)
                                    }
                                    onChange={(newContent) => { }}
                                />
                            </div>
                        </div>
                        <div className='button-divvvs text-center mt-3'>
                            <button className='cancel-btn' onClick={props.onHide}>Close</button>
                            <button className='save-btn' >Save</button>
                        </div>
                    </Modal.Body>
                </Modal>
            </section></>
    )
}

export default EmpAwardEditModal