import React, { useState, useContext, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VerifyMobileNumber from "../../../F-Service/F-Loan/FHomeLoanModal/HomeLoanSteps/StepOneUnlockBestHome/VerifyMobileNumber/VerifyMobileNumber";
import ThirdCredit from "../ThirdCredit/ThirdCredit";
import { Context } from "../../../../../utils/context";
import Cookies from "js-cookie";

const SecondCredit = ({ setVerified, getValues, onHide, handleSwitchChange, errors, setError, ...props }) => {
  const { postData, IMG_URL } = useContext(Context);
  const [thirdModalShow, setThirdModalShow] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState();


  const handleOtpChange = (otpValue) => {
    console.log("otpValue :-", otpValue);
    setOtp(otpValue);
    // handleVerifyAndLogin();

  };

  const handleVerifyAndLogin = async () => {
    if (!otp) {
      setError("otp", { type: "manual", message: "OTP Required" });
    } else if (otp && otp?.length !== 4) {
      setError("otp", { type: "manual", message: "Complete OTP Required" });
    } else {

      const contact_no = getValues("contact_no");
      console.log("contact", contact_no);
      if (contact_no) {
        const res = await postData(`/without-login/verify-otp/verify-otp`, { contact_no, otp });
        if (res?.success) {

          try {
            const formdata = new FormData();
            formdata.append("name", getValues("name"));
            formdata.append("email", getValues("email"));
            formdata.append("contact_no", getValues("contact_no"));
            formdata.append("gender", getValues("gender"));
            formdata.append("future_updates", getValues("future_updates"));
            if (Cookies.get("score_form_id")) {
              formdata.append("id", Cookies.get("score_form_id"));
            }
            const res = await postData(`/finance/forms/free-credit-score-form`, formdata);
            if (res?.success) {
              console.log(res, 'submit');
              Cookies.set("score_form_id", res.data.id, {
                expires: 1,
              });
              handleClose();
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          setError("otp", { type: "manual", message: res?.message });
        }
      }
      console.error("Contact number is required");
    }

  };
  console.log("otp", otp);
  console.log("otpErr", otpErr);
  console.log("Errors", errors);

  const handleClose = () => {
    onHide();
    setThirdModalShow(true);
  };
  return (
    <>
      <section className="first-credit">
        <div className="VerifyMobileNumber-modal">
          <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="loan-insurance-modal VerifyMobileNumber-modal"
          >
            <div className="content-body">
              <Modal.Header>
                <FontAwesomeIcon
                  icon="fa-solid fa-circle-xmark"
                  onClick={() => onHide()}
                />
              </Modal.Header>
              <Modal.Body>
                <VerifyMobileNumber
                  onOtpChange={handleOtpChange}
                  contact_no={getValues("contact_no")}
                  otpErr={otpErr}
                  setOtpErr={setOtpErr}
                  errors={errors}
                />
              </Modal.Body>
              <Modal.Footer>
                {/* <button className="submit-btn" onClick={handleClose}> */}
                <button className="submit-btn" onClick={handleVerifyAndLogin}>
                  Verify & Login
                </button>
                {/* <div className="get-notify">
                  <Form className="get-notify-switch">
                    <div className="whatsapp-icon-holder">
                      <img
                        className="whatsapp-icon"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Finance/Image/icons/whatsapp.png"
                        }
                        alt="whatsapp-icon"
                      />
                    </div>
                    <span>Get regular Credit Report updates via Whatsapp</span>
                    <Form.Check
                      type="switch"
                      checked={getValues("future_updates")}
                      onChange={handleSwitchChange}
                    />
                  </Form>
                </div> */}
              </Modal.Footer>
            </div>
          </Modal>
        </div>
      </section>
      <ThirdCredit
        show={thirdModalShow}
        name={getValues("name")}
        onHide={() => setThirdModalShow(false)}
      />
    </>
  );
};

export default SecondCredit;
