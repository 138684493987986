import './FBlogBanner.css'
import ImageContext from '../../../Utils/MyContext/MyContext';
import { React, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import { Container } from 'react-bootstrap';

const FBlogBanner = () => {
  const { finbanners } = useContext(ImageContext);
  const [modalShow, setModalShow] = useState(false);
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const getDataAll = async () => {
    try {
      const response = await getData(`/finance/blogs/f-blog-banner`);
      setData(response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const loadTimer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    getDataAll();

    return () => clearTimeout(loadTimer);
  }, []);

  return (
    <>
      {loading ? (
        <Container>
          <ShimmerThumbnail height={450} rounded />
        </Container>
      ) : (
        <>
          {data && data?.length > 0 &&
            <section className="f-blog-banner">
              <Container>
                <Swiper
                  spaceBetween={30}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  {data?.map((image, index) => (
                    <SwiperSlide key={index}>
                      <>
                        <img
                          className="banner-img mb-5"
                          src={IMG_URL + image?.image}
                          alt="Banner"
                        />
                      </>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Container>
            </section>
          }
        </>
      )}
    </>
  );
};

export default FBlogBanner;
