import React, { useState, useContext, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import "./ViewListmodal.css"
import Table from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import LogoutModal from '../LogoutModal/LogoutModal';
import { Context } from '../../../../../utils/context';


const ViewlistModal = (props) => {

    const { getData } = useContext(Context);

    const [applicantShow, setApplicantShow] = useState(false);

    const deleteApplicant = async (jobseekar_id) => {
        try {
            const res = await getData(`/job-portal/employer/dashboard//interview-schedule/delete/${jobseekar_id}`)
            if (res?.success) {
                setApplicantShow(true);
                // setApplicationDetails(res?.data?.data?.rows)
            } else {
                console.log("Error");
            }
        } catch (error) {
            console.log(error.message);
        }
    }
    const handleScheduleInterview = () => {
        props.setShowInterviewSchedule(true)
        props.onHide();
        // props.setContent(false);
    };


    function formatDate(dateString) {
        if (!dateString) return "";
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = String(date.getFullYear()).slice(-2);

        return `${day}-${month}-${year}`;
    }

    const onClickFunction = async (id, jobid) => {
        {
            if (id) {
                try {
                    const res = await getData(`/job-portal/employer/dashboard/applied-job-application-details/${id}/${jobid}`)
                    if (res?.success) {
                        props.setApplicantData(res?.data?.data)
                        props.handleModalClose();
                    } else {
                        console.log("Error");
                    }
                } catch (error) {
                    console.log(error.message);
                }
            }
        }
    }


    // useEffect(() => {
    //     getApplication();
    // }, [props.jobid])

    return (
        <>
            <section className='ViewListmodal'>
                <Modal
                    {...props}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className='ViewListmodal'
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Applicant List

                        </Modal.Title>
                        <button type='button' className='backBtn' onClick={props.onHide}>Back</button>

                    </Modal.Header>
                    <Modal.Body>
                        <div className='applicantTable'>
                            <Table>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Designation</th>
                                        <th>Applied on</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.apllicationDetails?.map((data, index) => (
                                        <tr>
                                            <td>{data?.job_portal_user?.first_name}</td>
                                            <td>{data?.job_portal_user?.job_portal_users_detail?.job_profile}</td>
                                            <td>{formatDate(data?.createdAt)}</td>
                                            <td>{props.status}</td>
                                            <td>
                                                <div className="mb-2">

                                                    <DropdownButton
                                                        id={`dropdown-button-drop-start`}
                                                        drop="start"
                                                        title={<FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" />}
                                                        className='dotsIcon'
                                                        type='button'
                                                    >
                                                        {/* props.setJobSeekarID(data?.job_portal_user?.user_id); props.handleModalClose();  }} */}
                                                        <Dropdown.Item eventKey="1" onClick={() => { onClickFunction(data?.job_portal_user?.id, data?.jobdetail_id) }}>View Resume</Dropdown.Item>
                                                        <Dropdown.Item eventKey="2" onClick={handleScheduleInterview}>Schedule Interview</Dropdown.Item>
                                                        <Dropdown.Item eventKey="3" onClick={() => { deleteApplicant(data?.jobseeker_id) }}>Delete Applicant</Dropdown.Item>

                                                    </DropdownButton>

                                                    <LogoutModal show={applicantShow}
                                                        onHide={() => setApplicantShow(false)}
                                                        text={"Are you sure, you want to Delete Applicant?"}
                                                        heading={"Delete Applicant"}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Modal.Body>

                </Modal>

            </section>

        </>
    )
}

export default ViewlistModal