import React, { useRef, useState, useEffect, useContext } from 'react'
import Col from 'react-bootstrap/Col';
import JoditEditor from "jodit-react";
import Row from 'react-bootstrap/Row';
import "react-phone-input-2/lib/style.css";
import "./JpEmpCompanyProfile.css"
import classNames from "classnames";
import { useForm, useFieldArray, } from "react-hook-form";
import { Context } from "../../../../../utils/context";
import { Form, Button } from "react-bootstrap";
import parse from "html-react-parser";
import Cookies from "js-cookie";

function AwardAchivement(companyId) {

    const { postData, getData, Select2Data } = useContext(Context);
    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
        reset, watch,
        trigger,
        setValue
    } = useForm();

    const { append, fields, remove } = useFieldArray({
        control,
        name: "awards",
    });

    const token = Cookies.get("llp_web_security");
    let decodedToken;
    if (token) {
        decodedToken = JSON.parse(atob(token.split(".")[1]));
    } else {
        console.error('Token is undefined');
    }

    const descriptionValue = watch("description");
    const [companyTypes, setCompanyTypes] = useState([]);
    const [data, setData] = useState([]);
    const GetAllCompanyatype = async () => {
        const response = await getData("/without-login/master/all-company");

        if (response?.success) {
            setCompanyTypes(response.data);
        }
    };
    // const [companyId, setCompanyId] = useState(null);
    // setCompanyId(companyIddata)
    const [industry, setIndustry] = useState([]);
    const GetAllIndustry = async () => {
        const response = await getData("/without-login/master/all-industry");

        if (response.success) {
            setIndustry(await Select2Data(response?.data, "industry_id"));
        }
    };

    const GetId = async () => {
        const response = await getData("/without-login/master/all-industry");

        if (response.success) {
            setIndustry(await Select2Data(response?.data, "industry_id"));
        }
    };

    const getDataAll = async () => {
        const response = await getData(`/job-portal/employer/company-details/award-achivement/${decodedToken.user}`);
        if (response?.success) {
            setData(response?.data);
            const awards = response.data.map(award => ({
                name: award.name,
                year: award.year,
                description: award.description,
            }));
            reset({ awards });
        }
    };

    console.log("datdatdataattat", data);

    useEffect(() => {
        getDataAll();
        GetAllCompanyatype();
        GetAllIndustry();
        GetId();

    }, []);



    const onSubmit = async (data) => {

        try {
            const response = await postData(`/job-portal/employer/company-details/award-achivement`, data);
            if (response?.success) {
                // Fetch the latest data after successful submission
                await getDataAll();
                reset();
            } else {
                // Handle the case where the submission wasn't successful
                console.error('Submission was not successful:', response);
            }
        } catch (error) {
            console.error('Error submitting data:', error);
        }
    };




    return (
        <>
            <Form onSubmit={(e) => handleSubmit(onSubmit(e))}>
                <div className='btn-div'>
                    <button className='add-btn' type='button' onClick={handleSubmit(onSubmit)} >Save</button>
                </div>

                <div className='award-card-div'>
                    <div className='row'>
                        {data?.map((award, index) => (
                            <div key={index} className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12'>
                                <div className='award-card'>
                                    <div className='row'>
                                        <div className='col-8'>
                                            <h5 className='award-name'>{award?.name}</h5>
                                            <p className='year-text'>{award?.year}</p>
                                        </div>
                                        {/* <div className='col-4 text-end'>
                                            <FontAwesomeIcon icon="fa-solid fa-trash " onClick={() => setModalShowtwo(true)} className='me-2 pencil-icon' />
                                            <FontAwesomeIcon icon="fa-solid fa-pencil" className='pencil-icon' />
                                        </div> */}
                                    </div>
                                    <p className='desc'>{parse(award?.description) || ""}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>


                {fields.map((field, index) => (
                    <div key={field.id}>
                        <div className='row'>
                            <div className='col-xxl-8 col-xl-7 col-lg-6 col-md-12 col-12'>
                                <Form.Group as={Row} className="mb-3" controlId={`formAwardName-${index}`}>
                                    <Form.Label column xxl="2" xl="3" lg="4" md="3" xs="5">
                                        Award Name:
                                    </Form.Label>
                                    <Col xxl="10" xl="9" lg="8" md="9" xs="7">
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Award Name"
                                            {...register(`awards.${index}.name`, {
                                                required: "Award name required",
                                            })}
                                            className={classNames({
                                                "is-invalid": errors.awards?.[index]?.name,
                                            })}
                                            onKeyDown={(event) => {
                                                if (!/^[a-zA-Z\s]*$/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                        {errors.awards?.[index]?.name && <p className="text-danger">{errors.awards[index].name.message}</p>}
                                    </Col>
                                </Form.Group>
                            </div>
                            <div className='col-xxl-4 col-xl-5 col-lg-6 col-md-12 col-12'>
                                <Form.Group as={Row} className="mb-3" controlId={`formAwardYear-${index}`}>
                                    <Form.Label column xxl="4" md="3" lg="4" xs="5">
                                        Year of Award:
                                    </Form.Label>
                                    <Col xxl="8" lg="8" md="9" xs="7">
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Year"
                                            {...register(`awards.${index}.year`, {
                                                required: "Year required",
                                            })}
                                            className={classNames({
                                                "is-invalid": errors.awards?.[index]?.year,
                                            })}
                                            maxLength={4}
                                        />
                                        {errors.awards?.[index]?.year && <p className="text-danger">{errors.awards[index].year.message}</p>}
                                    </Col>
                                </Form.Group>
                            </div>
                        </div>
                        <Form.Group className="mb-3 d-flex" controlId={`formAwardDescription-${index}`}>
                            <Form.Label className='me-5'>
                                Description:
                            </Form.Label>
                            <JoditEditor
                                value={field.description || ''}
                                onBlur={(newContent) => {
                                    setValue(`awards.${index}.description`, newContent);
                                    trigger(`awards.${index}.description`);
                                }}
                            />
                            {errors.awards?.[index]?.description && <p className="text-danger">{errors.awards[index].description.message}</p>}
                        </Form.Group>
                        {index !== 0 && (
                            <div className="addremoveadress">
                                <div className="text-end Addnewadresstxt">
                                    <p className="" onClick={() => remove(index)}>
                                        Remove Award{" "}
                                        {/* <FontAwesomeIcon icon="fa-solid fa-minus" /> */}
                                    </p>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
                <div className="AddMoreLanguage">
                    <span onClick={() => append({ name: "", year: "", description: "" })}>
                        Add More Awards
                    </span>
                </div>


            </Form>


        </>
    )
}

export default AwardAchivement