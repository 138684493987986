import { React, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
// import './Step3.css'
import { postData, getData } from '../../../../../utils/api';
import Cookies from "js-cookie";
import useRazorpay from "react-razorpay";
import { useNavigate } from 'react-router-dom';
function EmpPayment({ handleNext, handlePrevious, job_id }) {

const { id } = useParams();
const navigate = useNavigate();
console.log(id,"package_id")
    const [Razorpay, openRazorpay] = useRazorpay();
    const [planid, setplan_id] = useState();
    const [razorid, setRazorId] = useState();
    const [amount, setAmount] = useState();
    const handleRazorpay = async (storedValue, id) => {
        console.log(storedValue, "ghjgfjh");
        const options: RazorpayOptions = {
            key: "rzp_test_I05KCFb1v5JJRc",
            amount: storedValue * 100,
            currency: "INR",
            name: "Hiclousia",
            description: "Test Transaction",
            image:
                "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",

            handler: async (res) => {
                await setRazorId(res.razorpay_payment_id);
                finalFunction(res.razorpay_payment_id, id);
                console.log(res, "ttttttttttttttttttttttttttttt");
            },
            prefill: {
                name: "Piyush Garg",
                email: "youremail@example.com",
                contact: "9999999999",
            },
            notes: {
                address: "Razorpay Corporate Office",
            },
            theme: {
                color: "#052c65",
            },
        };

        const rzpay = new Razorpay(options);
        rzpay.open();
    };

    console.log(planid, "plan_id");

    
    
    const finalFunction = async (razorpayid, plan_id) => {

        //   paydata.plan_id=plan_id
        //   console.log(paydata,"paydata paydata");
        try {
            const paydata = {
                plan_id: plan_id,
                razorpay_id: razorpayid,
                job_id: Cookies.get('job_id'),
            };
            const response = await postData(`/job-portal/employer/job-post/plan-subscriber/plan-subscriber-payment/${id}`, paydata);
            if (response?.success) {
                console.log("before");
                paymentHistory(Cookies.get('job_id'), plan_id, id);
                console.log("after");
                console.log(response);
                // handleNext();
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const paymentHistory = async (Job_id, plan_id, payment_id) => {

        //   paydata.plan_id=plan_id
        console.log("paydata paydata");
        try {
            const paydata = {
                payment_id: payment_id,
                job_id: Job_id,
                plan_id: plan_id,
            };
            const response = await postData(`/job-portal/employer/job-post/plan-subscriber/plan-subscriber/${id}`, paydata);
            if (response?.success) {
                console.log("before");
                postDataAll(Cookies.get('job_id'));
                console.log("after");
                console.log(response, "paymentHistory");
                navigate(`/job-portal/employer/payment-conformation`)
                // handleNext();
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const postDataAll = async (Job_id) => {
        console.log("status change");
        try {
            const paydata = {
                status: "Done",
            };

            const response = await postData(`/job-portal/employer/job-post/job/status-update/data/${Job_id}`, paydata);
            if (response?.success) {

                console.log(response, "kalyani");
            }

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleamountid = async (price, id) => {

        await setplan_id(id);
        handleRazorpay(price, id);
    };

    const [data, setData] = useState([]);
    const getDataAll = async (id) => {
        try {
            const response = await getData(`/admin/all-plans`);
            setData(response?.data);
            setplan_id(response?.data?.id)

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data);
    // console.log(Question, "add question");

    useEffect(() => {
        getDataAll();
        // getDataQuestion();
    }, []);


    return (
        <>
            <section className='Emp-all-packages step3-packages'>

                <div className='container'>
                    <div className='main-all-pac-card'>
                        <div className='top-div '>
                            <div>
                                <Link onClick={handlePrevious}>Back </Link>
                            </div>
                            <div className='heading-div'>
                                <h6>Package & Payment</h6>
                            </div>
                        </div>
                        <div className='row justify-content-center cards-row'>
                            {data?.map((pkg, index) => (
                                <div key={index} className='col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12 loop-card'>
                                    <div className='all-mini-card'>
                                        <div className='top-pac'>
                                            <h6>{pkg.name}</h6>
                                            <p>{pkg.price}</p>
                                        </div>
                                        <div className='bot-pac'>
                                            {/* {pkg.map((feature, index) => (
                                                <p key={index}>{feature}</p>

                                            ))} */}
                                            <p>{pkg.job_post} Job post</p>
                                            <p>{pkg.application} application</p>
                                            <p>{pkg.featured_jobs} featured jobs</p>
                                            <p>{pkg.days_validity} days validity</p>
                                        </div>
                                        <div className='plan-btn-div'>
                                            <buttton className="plan-btn" onClick={() => handleamountid(pkg.price, pkg.id)} >Choose Plan</buttton>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="save-btn-div ">
                        {/* <button className="back-btn" onClick={handlePrevious}>Back</button> */}
                        {/* <button className="save-btn" onClick={handleNext}>Save & Next</button> */}

                        {/* <Link to={`/job-portal/employer/conformation/${id ? id : job_id}`}>
                            <button className="save-btn">Ok</button></Link> */}
                    </div>
                </div>
            </section>

        </>
    )
}

export default EmpPayment