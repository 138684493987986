import React from 'react'
import { Routes, Route } from "react-router-dom";
import FinanceRoutesMain from './FinanceRoutesMain';

const FinanceRoutes = () => {
    return (

        <Routes>
            <Route path={`/finance/*`} element={<FinanceRoutesMain />} />
        </Routes>
        
    )
}

export default FinanceRoutes