import React, { useState, useEffect } from 'react';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';
import Step4 from './Step4/Step4';
import Form from 'react-bootstrap/Form';
import './EmpstepForm.css';
import Cookies from "js-cookie";
import { getData } from '../../../../utils/api';
import { useParams } from "react-router-dom";


function EmpStepForm() {
  const [current, setCurrent] = useState(1);

  const { jobid } = useParams();
  console.log(jobid, "jobidjobidjobidjobidjobid");
  const stepDescriptions = [
    " Job Details",
    " Additional Questions",
    " Job Post Overview",
    " Package & Payment",
  ];
  const [userDetails, setUserDetails] = useState();

  // const getusersDetails = async () => {
  //   console.log(Cookies.get('user_id'), 'llllllll');
  //   const res = await getData(`/without-login/job-portal/employer/job/${Cookies.get('user_id')}`);
  //   if (res?.success) {
  //     console.log(res, 'users');
  //     setUserDetails(res?.data);
  //   }
  // }

  const getusersDetails = async () => {
    const res = await getData(`/job-portal/employer/job-post/job/${Cookies.get('job_id')}`);
    if (res?.success) {
      setUserDetails(res?.data);
    }
  };


  const job_id = Cookies.get('job_id');

  useEffect(() => {
    getusersDetails();
    window.scrollTo(0, 0);
  }, []);

  const handleNext = () => {
    if (current < 4) {
      setCurrent(current + 1);
    }
  };

  const handlePrevious = () => {
    if (current > 1) {
      setCurrent(current - 1);
    }
  };

  const isStepCompleted = (step) => {
    return step < current;
  };

  const getIconForStep = (step) => {
    return step;
  };

  return (
    <>
      <section className="StepForm-Employer">
        <div className="container">
          <Form >
            <div className="signup-form">
              <div className="progress-bar-container">
                <div className="step-row">
                  {[1, 2, 3, 4].map((step) => (
                    <div key={step} className={`step-container ${step === current ? "active" : ""}`}>
                      <div
                        className={`circle ${isStepCompleted(step) ? "completed" : ""}`}>
                        {getIconForStep(step)}
                      </div>
                      <span className="step-text">{stepDescriptions[step - 1]}</span>
                      {step <= 3 && (
                        <div
                          className={`line-right ${isStepCompleted(step) ? "completed" : ""}`}></div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="wizard-form mt-3">
              {current === 1 && <Step1 handleNext={handleNext} userDetails={userDetails} />}
              {current === 2 && (
                <Step2
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                  userDetails={userDetails}
                  job_id={job_id}
                />
              )}
              {current === 3 && (
                <Step4
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                  userDetails={userDetails}
                  job_id={job_id}
                />

              )}
              {current === 4 && (
                <Step3
                  handleNext={handleNext}
                  handlePrevious={handlePrevious}
                  userDetails={userDetails}
                  job_id={job_id}
                />
              )}
            </div>
          </Form>
        </div>
      </section>
    </>
  )
}

export default EmpStepForm;
