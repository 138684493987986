import React, { useState, useContext, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { InputGroup, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './EmpAccountManagement.css';
import Collapse from 'react-bootstrap/Collapse';
import ResetModal from '../../../JP-Common-Elements/Jp-modals/ResetModal/ResetModal';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Context } from '../../../../../utils/context';
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { useNavigate } from 'react-router-dom';
import { ChnagePassword } from '../../../../../utils/apis/employer';
import ChangeAccount from './changes/ChangeAccount'
import ChangeEmail from './changes/ChangeEmail'
import ChangePassword from './changes/ChangePassword'
import ChangePhone from './changes/ChangePhone'
import DeleteAccount from './changes/DeleteAccount'


function EmpAccountManagement() {
  const [showForm, setShowForm] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [headingText, setHeadingText] = useState('Account Management');
  const [selectedContent, setSelectedContent] = useState(null);
  const [deletedreason, setDeletedReason] = useState("");


  const { getData, postData, IMG_URL, signin, usertype, userdata } = useContext(Context);
  console.log(userdata, "userdata userdata");
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const getdeletereason = async () => {
    const res = await getData("/without-login/master/all-delete-resonse")
    if (res?.success) {
      setDeletedReason(res?.data);
      console.log(res.data);
    }
  }

  const toggleBack = () => {
    setShowForm(true);
    setHeadingText('Account Management');
    setSelectedContent(null);
  };

  const goToContent = (content) => {
    setShowForm(false);
    setHeadingText(content.name);
    setSelectedContent(content);
  };

  useEffect(() => {
    getdeletereason();
  }, []);

  const renderInnerContent = () => {
    switch (selectedContent.name) {
      case 'Switch Account Type':
        return (
          <ChangeAccount toggleBack={toggleBack}></ChangeAccount>
        );
      case ' Email':
        return (
          <>
            <ChangeEmail toggleBack={toggleBack}></ChangeEmail>
          </>
        );
      case 'Change Password':
        return (
          <>
            <ChangePassword toggleBack={toggleBack}></ChangePassword>
          </>
        );
      case ' Phone Number':
        return (
          <>
            <ChangePhone toggleBack={toggleBack}></ChangePhone>
          </>
        );
      case 'Delete Account':
        return (
          <>
            <DeleteAccount deletedreason={deletedreason} toggleBack={toggleBack}></DeleteAccount>
          </>
        );

      default:
        return null;
    }
  };

  const contents = [
    { name: 'Switch Account Type', formControl: 'text', placeholder: usertype, btnname: 'Switch to Account Type' },
    { name: ' Email', formControl: 'email', placeholder: userdata?.email, btnname: 'Email Settings' },
    { name: 'Change Password', type: "password", placeholder: 'Change Password', formControl: 'password', btnname: 'Change Password' },
    { name: ' Phone Number', formControl: 'text', placeholder: userdata?.job_portal_users_detail?.contact_no, btnname: 'Change Phone Number' },
    { name: 'Delete Account', formControl: 'text', placeholder: '(Permanently Delete Acc.)', btnname: 'Delete Account' }
  ];

  return (
    <>
      <div className='Emp-Acc-manage form-container'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-xxl-8 col-xl-8 col-lg-10 col-md-11 col-12'>
              <div className='acc-card'>
                <div className='heading'>
                  <h5>{headingText}</h5>
                </div>

                {showForm ? (
                  <div className='setting-form'>
                    <Form>
                      {contents.map((content, index) => (
                        <Form.Group key={index} className="form-div mb-4" controlId={`content-${index}`}>
                          <Form.Label>{content.name}</Form.Label>
                          <Form.Control type={content.formControl} placeholder={content.placeholder} value={content.placeholder} readOnly />
                          {/* <Form.Control type={content.formControl} placeholder={content.placeholder} readOnly /> */}
                          <div className='button-div'>
                            <button className='setting-btn' onClick={() => goToContent(content)}>{content.btnname}</button>
                          </div>
                        </Form.Group>
                      ))}
                    </Form>
                  </div>
                ) : renderInnerContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EmpAccountManagement;
