import './FBloginnerBanner.css'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import ImageContext from '../../../../Utils/MyContext/MyContext';
import React, { useEffect, useState, useContext } from 'react';
import { Context } from '../../../../../utils/context'
import { useParams } from 'react-router-dom';
library.add(fas);
const FBloginnerBanner = ({ data }) => {
    const { IMG_URL } = useContext(Context);
    return (
        <>
            {data && data?.banner_image &&
                <section className='blog-inner-banner'>
                    <div className='container'>
                        <div className='row blog-row '>
                            <div className='col-md-10 col-sm-10'>
                                <div className='heading-holder'>
                                    <h1>{data?.name}</h1>
                                    <p>{data?.created_at}</p>
                                </div>
                            </div>
                            <div className='col-md-2 col-sm-2'>
                                <Link to="/finance/blog">
                                    <div className='back-text text-md-end text-sm-end text-start'>
                                        <FontAwesomeIcon icon="fa-solid fa-angle-left" className='arrow-left me-1' /><span>Back</span>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <div className='image-holder my-2'>
                                {/* <img className='bannn-img' src={process.env.PUBLIC_URL + '/assets/Finance/Image/Blog/blog-inner.png'} /> */}
                                <img src={IMG_URL + data?.banner_image} className='banner-img' />
                            </div>
                        </div>
                    </div >
                </section>
            }
        </>
    )
}

export default FBloginnerBanner