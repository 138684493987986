import React, { useState, useContext, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./CompanyItCard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Exlamentry from "../../../Jp-modals/Exlamentry_Modal/Exlamentry";
import { Context } from "../../../../../../utils/context";
import { logDOM } from "@testing-library/react";
import { useNavigate } from "react-router-dom";

library.add(fas);

function CompanyItCard({
  selectedId,
  job_title,
  city_id,
  max_experience,
  min_experience,
  maxSalary,
  minSalary,
  category,
}) {

  const { getData, postData, IMG_URL, signin, usertype, userdata, setUsertype } = useContext(Context);
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [companyData, setCompanyData] = useState([]);

  const getRecentJob = async (selectedId) => {
    try {
      if (selectedId) {
        const res = await getData(`/job-portal/companies/industry-company/${selectedId}?city_id=${city_id?.value || ""}` +
          `&min_experience=${min_experience || ""}&max_experience=${max_experience || ""}&job_title=${job_title || ""}` +
          `&maxSalary=${maxSalary || ""}&minSalary=${minSalary || ""}&category=${category || ""}`)
        if (res?.success) {
          setCompanyData(res?.data)

        } else {
          console.log("Error");
        }
      }
      else {
        const res = await getData(`/job-portal/companies?city_id=${city_id?.value || ""}` +
          `&min_experience=${min_experience || ""}&max_experience=${max_experience || ""}&job_title=${job_title || ""}` +
          `&maxSalary=${maxSalary || ""}&minSalary=${minSalary || ""}&category=${category || ""}`)
        if (res?.success) {
          setCompanyData(res?.data)

        } else {
          console.log("Error");
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  }
  useEffect(() => {
    getRecentJob(selectedId);
  }, [selectedId, city_id, min_experience, max_experience, job_title, maxSalary, minSalary, category]);

  useEffect(() => {
    getRecentJob(selectedId);
  }, []);

  console.log("CompanyData :----", companyData);

  return (
    <section className="IT_Tab_content">
      <div className="IT-Cards">
        <div className="row">
          {
            companyData &&

            companyData?.rows?.map((company, index) => (
              <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 ">
                <div key={index} className="company_it-job-card">
                  <div className="row card-content me-0 ms-0">
                    <div className="col-md-3 col-3">
                      <div className="img-holder">
                        <img
                          src={IMG_URL + company?.company_logo || company?.company?.company_logo}
                          className="logo-img"
                          alt={`company_logo_`}
                        />
                      </div>
                    </div>

                    <div className="col-md-9 col-9">
                      <div className="text-holder">
                        <Card.Title>{company?.name || company?.company?.name}</Card.Title>
                        <Card.Text>

                          <div className="view_more">
                            {signin ? (
                              company?.company?.id ? (
                                <p className="" onClick={() => navigate(`/job-portal/company/Company-Job/${company.company.id}`)}>View Jobs</p>
                              ) : (
                                <p className="" onClick={() => navigate(`/job-portal/company/Company-Job/${company.id}`)}>View Jobs</p>
                              )
                            ) : (
                              <p className="" onClick={() => navigate("/job-portal/sign-in")}>View Jobs</p>
                            )}
                          </div>

                        </Card.Text>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>

        <Exlamentry
          show={modalShow}
          onHide={() => setModalShow(false)}
          text={"Your Need to Log In to JobSeeker to Apply for the Job!"}
        />
      </div>
    </section>
  );
}

export default CompanyItCard;
