
import React, { useState, useEffect, useContext } from "react";
import "./VerifyMobileNumber.css";
import { Form } from "react-bootstrap";
import { Context } from "../../../../utils/context";

function VerifyMobileNumber({ onOtpChange, contact_no, otpError, setOtpErr }) {

    const { postData, IMG_URL } = useContext(Context);
    const [otp, setOtp] = useState("");
    const [timer, setTimer] = useState(30);
    const [isResendEnabled, setIsResendEnabled] = useState(false);

    // Function to handle OTP input change
    const handleOtpInputChange = (e) => {
        const { value } = e.target;
        setOtp(value);
        onOtpChange(value);
    };

    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            deletOtp()
            setIsResendEnabled(true);
        }
    }, [timer]);

    const deletOtp = async () => {
        if (contact_no) {
            let sanitizedContactNo = contact_no.replace(/^(\+91)?/, '');
            // Remove any dashes (-)
            sanitizedContactNo = sanitizedContactNo.replace(/-/g, '');
            const res = await postData(`/without-login/verify-otp/delete`, { contact_no: sanitizedContactNo });
            if (res?.success) {

                console.log("OTP DELETED", res?.data);
            } else {
                console.error("Failed to delete OTP:", res.message);
            }
        } else {
            console.error("Valid Contact number is required");
            // setContactError("Contact number is required");
        }
    };

    const handleGetOTP = async () => {
        if (contact_no) {
            let sanitizedContactNo = contact_no.replace(/^(\+91)?/, '');
            // Remove any dashes (-)
            sanitizedContactNo = sanitizedContactNo.replace(/-/g, '');
            const res = await postData(`/without-login/verify-otp/otp-send`, { contact_no: sanitizedContactNo });
            if (res?.success) {
                console.log(res, "otp");
                setIsResendEnabled(false);
                setTimer(30);
                setOtp("")
                setOtpErr()
                // handleClose();
            } else {
                console.error("Failed to send OTP:", res.message);
            }
        } else {
            console.error("Valid Contact number is required");
            // setContactError("Contact number is required");
        }
    };
    return (
        <>
            <div className="VerifyMobileNumber-content">
                <div className="heading-holder">
                    <h6>Verify Mobile Number</h6>
                    <p>We have sent the OTP to {contact_no}</p>
                </div>

                <div className="otp-input-holder">
                    <div className="row justify-content-center">
                        <div className="col-md-8">
                            <Form className="otp-input">
                                <Form.Group className="mb-3">
                                    <Form.Control
                                        maxLength={4}
                                        placeholder="●●●●"
                                        value={otp}
                                        onChange={handleOtpInputChange}
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                        {otpError && (
                            <span className="text-danger">
                                {otpError}
                            </span>
                        )}
                    </div>

                    {isResendEnabled ?
                        <p className="submit-btn" onClick={handleGetOTP}>
                            Resend OTP
                        </p> :
                        <span>Resend OTP in {timer} seconds</span>
                    }

                </div>
            </div>
        </>
    );
}

export default VerifyMobileNumber;
