import React, { useEffect, useState,useContext } from "react";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import "./LogoutModal.css";
import ResetModal from "../ResetModal/ResetModal";
import Cookies from 'js-cookie';
import { Context } from "../../../../../utils/context";
import { useNavigate } from "react-router-dom";

const LogoutModal = (props) => {
  

  const { setSignin } = useContext(Context);
  const navigate = useNavigate();

  const handleHide = () => {
    props.onHide();
    props.setModalReset(true);
    Cookies.remove('llp_web_security');
    Cookies.remove('jobseekar_id');
    setSignin(false);
    setTimeout(() => {
      props.setModalReset(false);
      navigate("/job-portal/home");
    }, 3000)
  }


  
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="LogoutModal"
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.heading}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-content text-center">
            <div>
              <img
                src={process.env.PUBLIC_URL + props.imgSource}
                className="logoutimg"
              />
            </div>
            <p>{props.text}</p>
            <div className="Jobseeker_step_modal_section">
              <div className="close-save-btn-holder form-field">
                <div className="Close-btn-holder">
                  <Button
                    className="close-btn"
                    type="button"
                    onClick={props.onHide}
                  >
                    No
                  </Button>
                </div>

                <div className="apply-btn-holder">
                  <Button onClick={handleHide} className="apply_now_btn" type="button">
                    Yes
                  </Button>
                </div>

               
              </div>
            </div>
            {props.showCheckbox && (
              <Form>
                <div>
                  <Form.Check type={props.type} label={props.label} />
                </div>
              </Form>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LogoutModal;
