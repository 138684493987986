import React, { useEffect, useState, useContext, useRef } from "react";
import "./JpMyprofile.css";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import EducationalDetailsModal from "../../JP-Common-Elements/Jp-modals/JP-Jobseeker-Step_modal/EducationalDetailsModal/EducationalDetailsModal";
import SkillModal from "../../JP-Common-Elements/Jp-modals/JP-Jobseeker-Step_modal/SkillModal/SkillModal";
import EmploymentModal from "../../JP-Common-Elements/Jp-modals/JP-Jobseeker-Step_modal/EmploymentModal/EmploymentModal";
import PersonalModal from "../../JP-Common-Elements/Jp-modals/JP-Jobseeker-Step_modal/PersonalModal/PersonalModal";
import CareerPrefModal from "../../JP-Common-Elements/Jp-modals/JP-Jobseeker-Step_modal/CareerPrefModal/CareerPrefModal";
import ProfileSumModal from "../../JP-Common-Elements/Jp-modals/JP-Jobseeker-Step_modal/ProfileSumModal/ProfileSumModal";
import UploadLinkModal from "../../JP-Common-Elements/Jp-modals/Upload_Link_modal/UploadLinkModal";
import { Context } from "../../../../utils/context";
import Cookies from "js-cookie";
import { getProfilePicture, getSkills, getProfilePercentage } from "../../../../utils/apis/jobseekar/jobseekar";
import { applyPost, employeeResume } from "../../../../utils/apis/common/Common";
import { useNavigate } from "react-router-dom"
import { error } from "jquery";

const JpMyprofile = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [modalShow3, setModalShow3] = useState(false);
  const [modalShow4, setModalShow4] = useState(false);
  const [modalShow5, setModalShow5] = useState(false);
  const [modalShow6, setModalShow6] = useState(false);

  const items = [
    { label: "Skill Set" },
    { label: "Educational Details" },
    { label: "Employment Details" },
    { label: "Personal Details" },
    { label: "Profile Summary" },
    { label: "Career Preference" },
  ];

  const [showModal, setShowModal] = useState(null);

  const handleOpenModal = (modalName) => {
    setShowModal(modalName);
  };

  const certifications = [
    {
      title: "Certification in Digital Marketing",
      location:
        "MAEER'S MIT College of Fine Arts & Technology, Erandwane Pune, 38",
      date: "2020-22",
    },
    {
      title: "Bachelor in Fine Arts",
      location:
        "MAEER'S MIT College of Fine Arts & Technology, Erandwane Pune, 38",
      date: "2019-23",
    },
    {
      title: "HSC",
      location:
        "MAEER'S MIT College of Fine Arts & Technology, Erandwane Pune, 38",
      date: "2019-23",
    },
    {
      title: "SSC",
      location:
        "MAEER'S MIT College of Fine Arts & Technology, Erandwane Pune, 38",
      date: "2016-17",
    },
  ];

  const careerPreference = [
    {
      industryHeadone: "Current Industry",
      industryone: "IT",
      industryHeadtwo: "Job Title",
      industrytwo: "Graphic & UI Designer",
    },
    {
      industryHeadone: "Job Type",
      industryone: "IT",
      industryHeadtwo: "Job Title",
      industrytwo: "Graphic & UI Designer",
    },
    {
      industryHeadone: "Total Experience:",
      industryone: "IT",
      industryHeadtwo: "Job Title",
      industrytwo: "Graphic & UI Designer",
    },
    {
      industryHeadone: "Current Location:",
      industryone: "IT",
      industryHeadtwo: "Job Title",
      industrytwo: "Graphic & UI Designer",
    },

    {
      industryHeadone: "Current Location:",
      industryone: "IT",
      industryHeadtwo: "Job Title",
      industrytwo: "Graphic & UI Designer",
    },

    {
      industryHeadone: "Current Location:",
      industryone: "IT",
      industryHeadtwo: "Job Title",
      industrytwo: "Graphic & UI Designer",
    },
  ];
  const value = 0.3
  const radius = 50;
  const distance = 70;
  const angle = value * 360;

  const textPositionX = Math.cos((angle - 90) * (Math.PI / 180)) * (radius + distance) + radius;
  const textPositionY = Math.sin((angle - 90) * (Math.PI / 180)) * (radius + distance) + radius;

  function formatDate(dateString) {
    if (!dateString) return ''; // Return empty string if dateString is not provided
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
    return `${day}/${month}/${year}`;
  }

  function getFileNameFromPath(filePath) {
    if (!filePath) return ''; // Return empty string if filePath is not provided
    const parts = filePath.split('/');
    return parts[parts.length - 1];
  }

  // const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Make sure fileInputRef.current is not null before accessing its properties
    }
  };



  const [jobApplyID, setJobApplyID] = useState();
  // const [resume, setResume] = useState();



  const { getData, postData, IMG_URL } = useContext(Context);

  const [jobSeekarDetails, setJobSeekarDetails] = useState();
  const [jobSeekarDetailsID, setJobSeekarDetailsID] = useState();
  const [skillsData, setSkillsData] = useState([]);
  const [linksData, setLinksData] = useState([]);
  const [educationData, setEducationData] = useState([]);
  const [languageData, setLanguageData] = useState([]);
  const [carrerData, setCarrerData] = useState();

  const getJobSeekarDetails = async () => {
    const res = await getData(`/job-portal/job-seeker/profile/profile-details/${Cookies.get(
      "jobseekar_id"
    )}`);
    if (res?.success) {
      setJobSeekarDetails(res?.data);
      console.log(res?.data, "ffffff");
      setSkillsData(res?.data?.job_seeker_personal_detail?.job_seeker_skill_sets);
      setLinksData(res?.data?.skills);
      setEducationData(res?.data?.job_seeker_personal_detail?.job_seeker_educations);
      setCarrerData(res?.data?.job_seeker_personal_detail?.job_seeker_career_preference);
      setLanguageData(res?.data?.job_seeker_personal_detail?.job_seeker_p_details_languages);
      setJobSeekarDetailsID(res?.data?.personal_details_id);
      console.log(res?.data?.personal_details_id, "setJobSeekarDetails");
    }
  };

  const navigate = useNavigate();
  const [resumeData, setEmployeeResumeData] = useState();

  const [resume, setResume] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    const maxSize = 2 * 1024 * 1024; // 2MB
    if (file.size > maxSize) {
      alert('Please select a file smaller than 2MB.');
      return;
    } else {
      setResume(file);
      await submitApplication(file);
    }
  };

  const submitApplication = async (resumeFile) => {
    const data = new FormData();
    data.append('resume', resumeFile);

    try {
      const res = await applyPost(data); // Assuming applyPost is a function for submitting the resume
      if (res?.success) {
        navigate("job-portal/jobseeker/myprofile"); // Assuming navigate is defined elsewhere
      } else {
        console.error('Error applying for the job:', res?.error);
      }
    } catch (error) {
      console.error('Error applying for the job:', error);
    }
  };



  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    const data = new FormData();
    if (file) {
      data.append('resume', file);
    }

    const res = await postData("/job-portal/job-seeker/profile/profile-details/update-resume", data);
    if (res?.success) {
      getResume();
    }
    else {
      console.log(error);
    }
  };

  // const handleFileInputChange = async (event) => {
  //   const file = event.target.files[0];
  //   const maxSize = 2 * 1024 * 1024;
  //   if (file.size > maxSize) {
  //     alert('Please select a file smaller than 2MB.');
  //     return;
  //   } else {
  //     const res = await applyPost(event);
  //     setResume(file);
  //   }
  // };

  // const handleJobApply = async () => {
  //   const data = new FormData();
  //   if (resume) {
  //     data.append('resume', resume);
  //   }
  //   const res = await applyPost(data);
  //   if (res?.success) {
  //     navigate("job-portal/jobseeker/myprofile")
  //   } else {
  //     console.error('Error fetching  data:', res?.error);
  //   }
  // }

  const getResume = async () => {
    const res = await employeeResume();
    if (res?.success) {
      setEmployeeResumeData(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [profileImage, setProfileImage] = useState();


  const getProfileData = async () => {
    const res = await getProfilePicture();
    if (res?.success) {
      setProfileImage(res?.data);
      console.log(res?.data, "dfdsfsdfsdfdsf");
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [profile_percentage, setProfile_percentage] = useState();
  const [completionData, setCompletionData] = useState();
  const getPercentage = async () => {
    const res = await getProfilePercentage();
    if (res?.success) {
      setCompletionData(res.data);
      setProfile_percentage(res.data?.rating)
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getPercentage()
  }, [modalShow, modalShow1, modalShow2, modalShow3, modalShow4, modalShow5, modalShow6])

  useEffect(() => {
    getJobSeekarDetails();
    getResume();
    getProfileData();
    getPercentage()

  }, [])



  useEffect(() => {
    getPercentage()
  }, [Cookies.get("profile_percentage")]);

  return (
    <>
      <section className="JpMyprofile">
        <div className="container">
          <div className="row profileRow">
            <div className="col-lg-3">
              <div className="profileleft">
                <div className="card profilecardone">
                  <div className="barimgmainsec">
                    <div className="CircularProgressbar">
                      <CircularProgressbar
                        value={profile_percentage}
                        maxValue={100}
                        strokeWidth={3}
                      />
                      <div style={{ left: textPositionX, top: textPositionY }} className="progress-text">
                        {profile_percentage}%
                      </div>

                      {/* <CircularProgressbar
                        value={profile_percentage}
                        maxValue={100}
                        strokeWidth={3}
                      />
                      <div style={{ left: textPositionX, top: textPositionY }} className="progress-text">
                        {profile_percentage}%
                      </div> */}

                    </div>

                    <div className="progreebarSec">
                      <img
                        src={IMG_URL + profileImage?.job_seeker_image?.image}
                        className="profileImg"
                      />
                    </div>
                  </div>
                </div>

                <div className="card profilesidebarthree">
                  {items?.map((item, index) => (
                    <div className="row" key={index}>
                      <div className="col-lg-8 col-sm-6 col-8">
                        <div className="secondpararr">
                          <p>{item.label}</p>
                        </div>
                      </div>
                      <div className="col-lg-4 col-sm-6 col-4">
                        <div className="text-center">
                          <FontAwesomeIcon
                            icon="fa-solid fa-pen "
                            onClick={() => handleOpenModal(item.label)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <SkillModal
                  show={showModal === "Skill Set"}
                  onHide={() => setShowModal(null)}
                  jobSeekarDetailsID={jobSeekarDetailsID}
                />
                <EducationalDetailsModal
                  show={showModal === "Educational Details"}
                  onHide={() => setShowModal(null)}
                  jobSeekarDetailsID={jobSeekarDetailsID}
                />
                <EmploymentModal
                  show={showModal === "Employment Details"}
                  onHide={() => setShowModal(null)}
                  jobSeekarDetailsID={jobSeekarDetailsID}
                />
                <PersonalModal
                  show={showModal === "Personal Details"}
                  onHide={() => setShowModal(null)}
                  jobSeekarDetailsID={jobSeekarDetailsID}
                />
                <ProfileSumModal
                  show={showModal === "Profile Summary"}
                  onHide={() => setShowModal(null)}
                  jobSeekarDetailsID={jobSeekarDetailsID}
                />
                <CareerPrefModal
                  show={showModal === "Career Preference"}
                  onHide={() => setShowModal(null)}
                  jobSeekarDetailsID={jobSeekarDetailsID}
                />
              </div>
            </div>
            <div className="col-lg-9">
              {/* {jobSeekarDetails?.map((data) => ( */}
              <div className="profileright">
                <div className="card profilecardtwo">
                  <div className="namedashrow">
                    <div className="row ">
                      <div className="col-lg-7">
                        <div className="nameHeading">
                          <h3>{jobSeekarDetails?.first_name} {jobSeekarDetails?.last_name}</h3>
                          <p>{jobSeekarDetails?.Profile_Title}</p>
                        </div>
                      </div>
                      <div className="col-lg-5">
                        <div className="dashProfilesec">
                          <div className="BacktoCompanies">
                            <Link to={"/job-portal/jobseeker/dashboard"}>
                              <p className="">Back to Dashboard</p>
                            </Link>
                          </div>
                          <div className="apply-btn-holder">
                            <Link
                              to={
                                "/job-portal/jobseeker/jobseeker-edit-profile"
                              }
                            >
                              <Button className="apply_now_btn">
                                Edit Profile
                              </Button>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="col-lg-6">
                        <div className="location ">

                          <FontAwesomeIcon
                            icon="fa-solid fa-location-dot"
                            className="me-2 fafa-icon"
                          />
                          <p>{jobSeekarDetails?.current_address}</p>
                        </div>

                        <div className="location ">
                          <FontAwesomeIcon icon="fa-solid fa-briefcase" className="me-2 fafa-icon" />

                          <p>{jobSeekarDetails?.career_level}</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="location ">
                          <FontAwesomeIcon
                            icon="fa-solid fa-phone"
                            className="me-2"
                          />
                          <p>{jobSeekarDetails?.contact_no}</p>
                        </div>

                        <div className="location ">
                          <FontAwesomeIcon icon="fa-solid fa-envelope" className="me-2 fafa-icon" />
                          {/* <FontAwesomeIcon
                            icon="fa-solid fa-location-dot"
                            className="me-2"
                          /> */}
                          <p>{jobSeekarDetails?.email}</p>
                        </div>
                      </div>

                      <div className="preferncesummary">
                        <h3>Other Links</h3>
                        <div className="row">
                          {jobSeekarDetails?.link?.map((data) => (
                            <div className="col-lg-6">
                              <Link to={data?.link} target="_blank">
                                <p>{data?.link}</p>
                              </Link>
                            </div>
                          ))}
                          {/* <div className="col-lg-6">
                            <Link to={"https://linkedin.com/in/Surajkumar Rao"} target="_blank">
                              <p>https://linkedin.com/in/Surajkumar Rao</p>
                            </Link>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card profilecardfour QuestionsStepFrom">
                  <div className="StepForm">
                    <div className="StepForm-holder ">
                      <div className="text-holder">
                        <h6>Resume:</h6>
                      </div>

                      <div className="resume">
                        <div className="resume-text-holder">
                          <p>
                            Resume is the most important document recruiters
                            look for. Recruiters generally do not look at
                            profiles without resumes.
                          </p>
                        </div>
                      </div>

                      <div className="compress_pdf">
                        <div className="row me-0 ms-0">
                          <div className="col-md-9 p-0">
                            <div className="text-holder">
                              <p>resume {resumeData?.resume && getFileNameFromPath(resumeData.resume)}</p>
                              <span>Uploaded on {formatDate(resumeData?.updatedAt)}</span>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="download-btn">
                              <div className="down-icon">
                                <div className="">

                                  <a
                                    className="download-link"
                                    href={IMG_URL + (resumeData?.resume)}
                                    download
                                  >
                                    <FontAwesomeIcon
                                      className="download-icon"
                                      icon="fa-solid fa-download"
                                    />

                                  </a>

                                </div>
                                <div className="text-bg">
                                  <span className="hover-text">Click here to download</span>
                                </div>
                              </div>
                              <div className="down-icon">
                                <div className="">
                                  <FontAwesomeIcon
                                    className="download-icon"
                                    icon="fa-solid fa-trash"
                                  />

                                </div>
                                <div className="text-bg">
                                  <span className="hover-text">Click here to delete</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="upload-file">
                        <div className="row me-0 ms-0">
                          <div className="col-xxl-7 col-xl-7 col-lg-8 col-md-8 p-0">
                            <div className="upload-btn-holder">
                              {/* <div className="apply-btn-holder">
                                <Button className="apply_now_btn" onClick={handleJobApply}>
                                  Update Resume
                                </Button>
                                <input
                                  type="file"
                                  ref={fileInputRef}
                                  style={{ display: 'none' }}
                                  onChange={handleFileInputChange}
                                  accept=".pdf"
                                />
                              </div> */}
                              <div className="apply-btn-holder">
                                <label htmlFor="upload-photo" className="apply_now_btn">
                                  Update Resume
                                </label>
                                <input
                                  id="upload-photo"
                                  type="file"
                                  style={{ display: "none" }}
                                  onChange={handleFileChange}
                                  accept=".pdf"
                                />
                              </div>
                              <div>
                                <p>
                                  Supported Formats: doc, docx, rtf, pdf, upto 2
                                  MB
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="col-xxl-5 col-xl-5 col-lg-4 col-md-4">
                            <div
                              className="upload_resume"
                              onClick={() => setModalShow(true)}
                            >
                              <p>
                                Upload Resume Link{" "}
                                <FontAwesomeIcon
                                  className="upload-icon"
                                  icon="fa-solid fa-arrow-up-from-bracket"
                                />
                              </p>
                            </div>

                            <UploadLinkModal
                              show={modalShow}
                              onHide={() => setModalShow(false)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card profilecardfour">
                  <div className="resumeHeadtxt">
                    <div className="d-flex skillsSetMian">
                      <h3>Resume Headline :</h3>
                      <div>
                        <FontAwesomeIcon
                          icon="fa-solid fa-pen"
                          onClick={() => setModalShow1(true)}
                        />
                      </div>

                      <PersonalModal
                        show={modalShow1}
                        onHide={() => setModalShow1(false)}
                        jobSeekarDetailsID={jobSeekarDetailsID}
                      />
                    </div>
                    <p>
                      {jobSeekarDetails?.resume_headline}
                    </p>
                  </div>
                </div>

                <div className="card profilecardfour">
                  <div className="d-flex skillsSetMian">
                    <h3>Skills Sets:</h3>
                    <div>
                      <FontAwesomeIcon
                        icon="fa-solid fa-pen"
                        onClick={() => setModalShow2(true)}
                      />
                    </div>

                    <SkillModal
                      show={modalShow2}
                      onHide={() => setModalShow2(false)}
                      jobSeekarDetailsID={jobSeekarDetailsID}
                    />
                  </div>

                  <div className=" skillcontent">
                    {jobSeekarDetails?.skills?.map((data) => (
                      <div>{data?.name}</div>
                    ))}

                    {/* <div>Figma</div>
                      <div>Adobe Illustrator</div>
                      <div>Adobe Photoshop</div> */}
                  </div>
                </div>

                <div className="card profilecardfour">
                  <div className="d-flex skillsSetMian">
                    <h3 className="mb-4">Educational Details :</h3>
                    <div>
                      <FontAwesomeIcon
                        icon="fa-solid fa-pen"
                        onClick={() => setModalShow3(true)}
                      />
                    </div>

                    <EducationalDetailsModal
                      show={modalShow3}
                      onHide={() => setModalShow3(false)}
                      jobSeekarDetailsID={jobSeekarDetailsID}
                    />
                  </div>

                  {/* {certifications?.map((certification, index) => (
                    <div className="educationHead" key={index}>
                      <h3>{certification.title}</h3>
                      <p>{certification.location}</p>
                      <p>{certification.date}</p>
                    </div>
                  ))} */}
                  {jobSeekarDetails?.eduactions?.map((certification, index) => (
                    <div className="educationHead" key={index}>
                      <h3>{certification.education_level}</h3>
                      <p>{certification.collage}</p>
                      <p>{certification.awarded_year}</p>
                    </div>
                  ))}
                </div>

                <div className="card profilecardfour">
                  <div className="d-flex skillsSetMian">
                    <h3>Profile Summary:</h3>
                    <div>
                      <FontAwesomeIcon
                        icon="fa-solid fa-pen"
                        onClick={() => setModalShow4(true)}
                      />
                    </div>

                    <ProfileSumModal
                      show={modalShow4}
                      onHide={() => setModalShow4(false)}
                      jobSeekarDetailsID={jobSeekarDetailsID}
                    />
                  </div>

                  <p className="summarytxt">
                    {" "}
                    {/* {jobSeekarDetails?.job_seeker_profile_summary} */}
                    Your Profile Summary should mention the highlights of your
                    career and education, what your professionnal interests are,
                    and what kind of a career you are looking for. Write a
                    meaningful summary of more than 50 characters.{" "}
                  </p>
                </div>

                <div className="card profilecardfour">
                  <div className="d-flex skillsSetMian">
                    <h3>Career Preference:</h3>
                    <div>
                      <FontAwesomeIcon
                        icon="fa-solid fa-pen"
                        onClick={() => setModalShow5(true)}
                      />
                    </div>

                    <CareerPrefModal
                      show={modalShow5}
                      onHide={() => setModalShow5(false)}
                      jobSeekarDetailsID={jobSeekarDetailsID}
                    />
                  </div>

                  {/* {careerPreference.map((preference, index) => (
                    <div className="row preferenceRowmin" key={index}>
                      <div className="col-lg-6">
                        <div className="preferncesummary">
                          <h3>{preference.industryHeadone}</h3>
                          <p>{preference.industryone}</p>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="preferncesummary">
                          <h3>{preference.industryHeadtwo}</h3>
                          <p>{preference.industrytwo}</p>
                        </div>
                      </div>
                    </div>
                  ))}  */}
                  {/* {/* {carrerData.map((preference, index) => ( */}
                  <div className="row preferenceRowmin">
                    <div className="col-lg-6">
                      <div className="preferncesummary">
                        <h3>Current Industry</h3>
                        <p>{carrerData?.industry?.name}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="preferncesummary">
                        <h3>Job Title</h3>
                        <p>{carrerData?.job_title?.name}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="preferncesummary">
                        <h3>Job Type</h3>
                        <p>{carrerData?.job_type?.name}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="preferncesummary">
                        <h3>Department</h3>
                        <p>{carrerData?.department}</p>
                      </div>
                    </div>
                    {/* <div className="col-lg-6">
                        <div className="preferncesummary">
                          <h3>Current Location</h3>
                          <p>{carrerData?.department}</p>
                        </div>
                      </div> */}
                    <div className="col-lg-6">
                      <div className="preferncesummary">
                        <h3>Total Experience</h3>
                        <p>{carrerData?.total_experience}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="preferncesummary">
                        <h3>Current Salary</h3>
                        <p>{carrerData?.current_salary}</p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="preferncesummary">
                        <h3>Last Working Date</h3>
                        <p>{carrerData?.last_working_date}</p>
                      </div>
                    </div><div className="col-lg-6">
                      <div className="preferncesummary">
                        <h3>Mode Of Work</h3>
                        <p>{carrerData?.mode_of_work?.name}</p>
                      </div>
                    </div>
                  </div>
                  {/* ))} */}
                </div>

                <div className="card profilecardfour">
                  <div className="d-flex skillsSetMian">
                    <h3>Personal Details:</h3>
                    <div>
                      <FontAwesomeIcon
                        icon="fa-solid fa-pen"
                        onClick={() => setModalShow6(true)}
                      />
                    </div>

                    <PersonalModal
                      show={modalShow6}
                      onHide={() => setModalShow6(false)}
                      jobSeekarDetailsID={jobSeekarDetailsID}
                    />
                  </div>

                  <div className="row personalDetRowmain">
                    <div className="col-lg-6">
                      <div className="personalDetails">
                        <h3>Date of Birth</h3>
                        <p>{jobSeekarDetails?.date_of_birth}</p>
                      </div>

                      <div className="personalDetails">
                        <h3>Personal Status:</h3>
                        <p>{jobSeekarDetails?.personal_status_id?.label}</p>
                      </div>

                      <div className="personalDetails">
                        <h3>Current Address:</h3>
                        <p>
                          {jobSeekarDetails?.current_address}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="personalDetails">
                        <h3>Gender</h3>
                        <p>{jobSeekarDetails?.gender}</p>
                      </div>

                      <div className="personalDetails">
                        <h3>Differently abled</h3>
                        <p>{jobSeekarDetails?.differently_abled}</p>
                      </div>

                      <div className="personalDetails">
                        <h3>Permanent Address:</h3>
                        <p>
                          {jobSeekarDetails?.permenent_address}
                        </p>
                      </div>
                    </div>

                    <div className="tableMain">
                      <div className="tableHead">
                        <h3>Language :</h3>
                      </div>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>Language</th>
                            <th>Level</th>
                            <th>Read</th>
                            <th>Write</th>
                            <th>Speak</th>
                          </tr>
                        </thead>
                        <tbody>
                          {jobSeekarDetails?.data?.map((data) => (
                            <tr>
                              <td>{data?.language_id?.label}</td>
                              <td>{data?.proficiency_id?.label}</td>
                              <td>
                                <Form>
                                  <Form.Check type="checkbox" defaultChecked={data?.read == 1} />
                                </Form>
                              </td>
                              <td>
                                <Form>
                                  <div className="mb-3">
                                    <Form.Check type="checkbox" defaultChecked={data?.write == 1} />
                                  </div>
                                </Form>
                              </td>
                              <td>
                                <Form>
                                  <div className="mb-3">
                                    <Form.Check type="checkbox" defaultChecked={data?.speak == 1} />
                                  </div>
                                </Form>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
              </div>
              {/* ))} */}
            </div>
          </div>
        </div>
      </section >
    </>
  );
};

export default JpMyprofile;
