import React, { useState, useEffect, useContext } from "react";
import Tab from "react-bootstrap/Tab";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "./JPPopularCompanies.css";
import { Nav, } from "react-bootstrap";
import CreateAccount from "./JPPopularCompaniesTabContent/JPPopularCompaniesITTab/CreateAccount/CreateAccount";
import CreateAccountAddvertise from "../../JP-Common-Elements/JP-Addvertise/JP-Create-Account-Addvertise/Create-Account-Addvertise/CreateAccountAddvertise"
import CompanyItCard from "../../JP-Common-Elements/JP-Cards/JP_Company_card/CompanyItCard/CompanyItCard";
import JobsJobFilter from "../../JP-Common-Elements/JP-Job-Filter/Jobs_Job_Filter/JobsJobFilter";
import PaginationSection from "../../JP-Common-Elements/JP-Pegination/PaginationSection/PaginationSection";
import { Link } from "react-router-dom";
import JPCompaniesFilter from "./JPCompaniesFilter"
import UpdateAccountAddvertise from "../../JP-Common-Elements/JP-Addvertise/JP-Update-Account-Addvertise/Update-Account-Addvertise/UpdateAccountAddvertise";

import { Context } from "../../../../utils/context";


const JPPopularCompanies = ({ job_title }) => {
  // Array of objects representing Nav items

  const { getData, postData, IMG_URL, signin, usertype, userdata, setUsertype } = useContext(Context);
  const [industryData, setIndustry] = useState([]);
  const [selectedId, setSelectedId] = useState();

  const [city_id, setCityId] = useState();
  const [max_experience, setMaxExperience] = useState();
  const [min_experience, setMinExperience] = useState();
  const [maxSalary, setMaxSalary] = useState();
  const [minSalary, setMinSalary] = useState();
  const [category, setCategory] = useState();

  const getIndustry = async () => {
    try {
      const res = await getData("/without-login/master/all-industry");
      if (res?.success) {
        setIndustry(res?.data);
      } else {
        console.log("Error");
      }
    } catch (error) {

    }
  }
  const handleNavItemClick = (id) => {
    // Do something with the selected id, such as setting it to state
    setSelectedId(id);
  };

  const navItems = [
    { eventKey: "Edtech", label: "Edtech" },
    { eventKey: "it", label: "I.T." },
    { eventKey: "BankigFinance", label: "Banking & Finance" },
    { eventKey: "HealthCare", label: "Health Care" },
    { eventKey: "Telecommunication", label: "Telecommunication" },
    { eventKey: "Management", label: "Management" },
    { eventKey: "Fintech", label: "Fintech" },
    { eventKey: "Manufacturing", label: "Manufacturing" },
  ];

  useEffect(() => {
    getIndustry();
  }, []);
  return (
    <div>
      <section className="JPforyou">
        <div className="container">
          <div className="heading-holder">
            <div className="row">

              <div className="col-xl-2 col-md-3">
                <div className="BacktoCompanies">

                  <Link to={signin ? "/job-portal/jobseeker/dashboard" : "/job-portal/sign-in"}>
                    <p className="">Back to Dashboard</p>
                  </Link>

                </div>

              </div>


              <div className="col-xl-8 col-md-6">
                <div>
                  <h1 className="jpsect_title">Popular Companies</h1>
                  <p className="jpsect_subtitle ">Popular Companies for You</p>
                </div>
              </div>
            </div>
          </div>

          {/*JPforyou tab section start */}
          <div className="JPforyou-tabs">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <div className="row">
                <div className="col-md-12  ">
                  <Nav variant="pills" className="">

                    <Swiper
                      modules={[Navigation, Autoplay]}
                      pagination={false}
                      navigation={false}
                      slidesPerView={5}
                      loop={true}
                      breakpoints={{
                        320: {
                          slidesPerView: 2,
                        },
                        768: {
                          slidesPerView: 3,
                        },
                        992: {
                          slidesPerView: 5,
                        },
                        1024: {
                          slidesPerView: 5,
                        },
                        1600: {
                          slidesPerView: 5,
                        }
                      }}
                      className="suportres-slider"
                    >
                      <SwiperSlide className="swiper-slide1">
                        <Nav.Item onClick={() => setSelectedId()}>
                          <Nav.Link eventKey="first">
                            All Companies
                          </Nav.Link>
                        </Nav.Item>
                      </SwiperSlide>

                      {industryData?.map((navItems, index) => (
                        <SwiperSlide className="swiper-slide1">
                          <Nav.Item key={index}>
                            <Nav.Link eventKey={navItems?.name + navItems?.id} onClick={() => handleNavItemClick(navItems.id)}>
                              {navItems.name}
                            </Nav.Link>
                          </Nav.Item>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Nav>
                </div>
              </div>
              <div className="row tabs-content">
                <div className=" col-xl-9 col-md-8 col-12">
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="jobs_tab">
                        <div>
                          <CompanyItCard
                            job_title={job_title}
                            city_id={city_id}
                            max_experience={max_experience}
                            min_experience={min_experience}
                            maxSalary={maxSalary}
                            minSalary={minSalary}
                            category={category}
                          />
                          <CreateAccount />
                          {/* <CompanyItCard /> */}
                        </div>

                        <PaginationSection />
                      </div>
                    </Tab.Pane>

                    {industryData?.map((navItems, index) => (
                      <Tab.Pane eventKey={navItems?.name + navItems?.id}>
                        <div className="jobs_tab">
                          <div>
                            <CompanyItCard
                              selectedId={selectedId}
                              job_title={job_title}
                              city_id={city_id}
                              max_experience={max_experience}
                              min_experience={min_experience}
                              maxSalary={maxSalary}
                              minSalary={minSalary}
                              category={category}
                            />
                            <CreateAccount />
                            {/* <CompanyItCard /> */}
                          </div>

                          <PaginationSection />
                        </div>
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </div>
                <div className="col-xl-3 col-md-4 col-12">
                  <div>
                    <JPCompaniesFilter
                      setCityId={setCityId}
                      setMaxExperience={setMaxExperience}
                      setMinExperience={setMinExperience}
                      setMaxSalary={setMaxSalary}
                      setMinSalary={setMinSalary}
                      setCategory={setCategory}
                    />
                    {/* <UpdateAccountAddvertise/> */}
                    <CreateAccountAddvertise />
                  </div>
                </div>
              </div>
            </Tab.Container>
          </div>
          {/*JPforyou tab section end */}
        </div>
      </section>
    </div>
  );
};

export default JPPopularCompanies;
