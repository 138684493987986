import React, { useState, useContext } from 'react';
import "../../MainDashboard/JpMainDashboard.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'react-bootstrap/Dropdown';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import Delete_JobModel from '../../../JP-Common-Elements/Jp-modals/Delete_JobModel/Delete_JobModel';
import { Context } from '../../../../../utils/context';
import { useNavigate } from 'react-router-dom';
import ResetModal from '../../../JP-Common-Elements/Jp-modals/ResetModal/ResetModal'

export default function DashboardTab({ dashbordData }) {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalreset, setModalReset] = useState(false);
  const navigate = useNavigate(); 
  const { IMG_URL } = useContext(Context);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}/${month}/${day}`;
  }
  const [showMoreCollab, setShowMoreCollab] = useState(false);
  const [showCountCollab, setShowCountCollab] = useState(8);

  const handleToggleCollab = () => {
    setShowMoreCollab(!showMoreCollab);
    setShowCountCollab(showMoreCollab ? 8 : dashbordData?.length);
  };

  
  const handleViewJobDetails = (jobId) => {
    navigate(`/job-portal/employer/jobdetails/${jobId}`); // Navigate to the specified URL
  };

  const handleEditJobDetails = (jobId) => {
    navigate(`/job-portal/employer/editjobdetails/${jobId}`); // Navigate to the specified URL
  };

  const [jobID, setJobID] = useState();
  const deleteJOB = (jobId) =>{
    setJobID(jobId);
    setModalShow(true)
  }

  return (
    <>
      <section className='Jp-employeer-first-dashboard'>
        <div className='container'>
          <div className='heading'>
            <h2>My Posted Jobs</h2>
          </div>
        </div>
        <div className='row'>
          <>
            {dashbordData?.slice(0, showCountCollab).map((job, index) => (
              <>
                <div key={job?.id} className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12'>
                  <div className='posted-job-card'>
                    <div className='row'>
                      <div className='col-xxl-4 col-md-4 col-4'>
                        <div className='img-div'>
                          <img className='company-img' src={IMG_URL + job?.company_logo} alt={job?.companyName} />
                        </div>
                      </div>
                      <div className='col-xxl-7 col-md-7 col-7 p-0'>
                        <div className='company-name'>
                          <p>{job?.company?.name}</p>
                        </div>
                      </div>
                      <div className='col-xxl-1 col-md-1 col-1 p-0'>
                        <div className='icon'>
                          <Dropdown>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                              <FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => handleViewJobDetails(job?.id)}>View Job Details</Dropdown.Item>
                              <Dropdown.Item onClick={() => handleEditJobDetails(job?.id)}>Edit Job Details</Dropdown.Item>
                              <Dropdown.Item href="#/action-3" onClick={() => deleteJOB(job?.id)}>Delete job</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                    <div className='positon-name'>
                      <h5>{job?.job_title?.name}</h5>
                      <p>Job Posted on {formatDate(job?.createdAt)}</p>
                    </div>
                  </div>
                </div >
              </>
            ))}
          </>
        </div>
        <div className='text-center'>
          <button className='show-more-btn' onClick={handleToggleCollab}>
            {showMoreCollab ? 'Show Less' : 'Show More'}
            <FontAwesomeIcon icon={showMoreCollab ? faAngleUp : faAngleDown} className='ms-3' />
          </button>
        </div>
        <div className='bottom-div text-center'>
          <span className='with-text'>With Every Click, </span> <span className='add-text'>Add Up Bests to Your Team</span>
          <div className='bottom-card'>
            <Link to="/job-portal/employer/stepform"><button className='create-btn'>
              Create job
            </button></Link>
          </div>
        </div>
        <Delete_JobModel
          show={modalShow}
          jobID={jobID}
          onHide={() => setModalShow(false)}
          text={"Are you sure, you want to delete job?"}
          heading={"Delete Job"}
          setModalShow={setModalShow}
          setModalReset={setModalReset}
          imgSource={"/assets/Job-portal/Images/employer/Logout/deleteaward.png"}
        />
         <ResetModal
          show={modalreset}
          onHide={() => setModalReset(false)}
          text={"Job Deleted Successfully"}
        />
      </section >
    </>
  );
}
