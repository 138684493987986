import React, { useContext, useEffect, useState, useRef } from "react";
import "./FirstStep.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from "../../../../../../utils/context";
import { applyPost, employeeResume } from "../../../../../../utils/apis/common/Common";
import { useParams } from "react-router-dom";

library.add(fas);

function FirstStep({ setResume,handleJobApply,nextStep }) {
  const { control, register, handleSubmit, getValues, setError, clearErrors, formState: { errors }, reset, watch, trigger, setValue } = useForm();
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click(); // Make sure fileInputRef.current is not null before accessing its properties
    }
  };


  const handleFileInputChange = async (event) => {
    const file = event.target.files[0];
    const maxSize = 2 * 1024 * 1024;
    if (file.size > maxSize) {
      alert('Please select a file smaller than 2MB.');
      return;
    } else {
      const res = await applyPost(event);
      setResume(file);
    }
  };

  const [getResume, setEmployeeResume] = useState();
  const { getData, IMG_URL } = useContext(Context);

  const getproject = async () => {
    const res = await employeeResume();
    if (res?.success) {
      setEmployeeResume(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };


  

  useEffect(() => {
    getproject();
  }, []);

  return (
    <>
      <search className="QuestionsStepFrom">
        <div className="container p-0">
          <div className="StepForm-holder">
            <div className="text-holder">
              <h6>Resume:</h6>
            </div>

            <div className="resume">
              <div className="resume-text-holder">
                <p>
                  Resume is the most important document recruiters look for.
                  Recruiters generally do not look at profiles without resumes.
                </p>
              </div>
            </div>

            <div className="compress_pdf">
              <div className="row me-0 ms-0">
                <div className="col-md-11 p-0">
                  <div className="text-holder">
                    <p>resume {getResume?.resume && (
                      <>
                        {getResume.resume.substring(36)}
                      </>
                    )}</p>
                    <span>Uploaded on {getResume?.updatedAt && (
                      <>
                        {new Date(getResume?.updatedAt).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'long',
                          day: '2-digit'
                        })}
                      </>
                    )}</span>
                  </div>
                </div>
                <div className="col-md-1">
                  <div className="download-btn">
                    <span>
                      <a
                        className="download-link"
                        href={IMG_URL + (getResume?.resume)}
                        download
                      >
                        <FontAwesomeIcon
                          className="download-icon"
                          icon="fa-solid fa-download"
                        />
                      </a>
                    </span>
                    <span>
                      <FontAwesomeIcon
                        className="download-icon"
                        icon="fa-solid fa-trash"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="upload-file">
              <div className="row me-0 ms-0">
                <div className="col-md-7 p-0">
                  <div className="upload-btn-holder">
                    <div className="apply-btn-holder">
                      <Button className="apply_now_btn" onClick={handleButtonClick}>Update Resume</Button>
                      {/* Hidden file input */}
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleFileInputChange}
                        accept=".pdf"
                      />
                    </div>

                    <div>
                      <p>Supported Formats: doc, docx, rtf, pdf, upto 2 MB</p>
                    </div>
                  </div>
                </div>

                <div className="col-md-5">
                  <div className="upload_resume">
                    <p>Upload Resume Link <FontAwesomeIcon className="upload-icon" icon="fa-solid fa-arrow-up-from-bracket" /></p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </search>
      <div className="navigation-buttons">
        
          <div className="apply-btn-holder">
            <Button className="apply_now_btn" onClick={() => handleJobApply()}>
              Next
            </Button>
          </div>
        
      </div>
    </>
  );
}

export default FirstStep;
