import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../utils/context";
import { Link, useLocation } from "react-router-dom";
import "./FHeader.css";
import Nav from "react-bootstrap/Nav";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { NavDropdown, Col, Row, Dropdown, Tab, Card } from "react-bootstrap";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import Accordion from "react-bootstrap/Accordion";
import FHomeLoanModal from "../F-Service/F-Loan/FHomeLoanModal/FHomeLoanModal";
import FHomeInsuranceModal from "../F-Service/F-Insurance/FHomeInsuranceModal/FHomeInsuranceModal";
import ServiceFormModal from "../F-Service/Service_form/F-Insurance/FHomeInsuranceModal/ServiceFormModal";
import { useParams } from 'react-router-dom';
import Cookies from "js-cookie";
import MobileOtpModal from "../../../utils/Mobile_otp_modal/MobileOtpModal";

import {
  sendMobileOTP,
  verifyMobileOTP,
} from "../../../utils/common";

const FHeader = () => {
  const [loanModalShow, setLoanModalShow] = useState(false);
  const [insuranceModalShow, setInsuranceModalShow] = useState(false);
  const [serviceModalShow, setServiceModalShow] = useState(false);
  const [typeId, setTypeId] = useState(null);
  const location = useLocation();
  const { IMG_URL, getData } = useContext(Context);

  const isActive = (path) => {
    return location.pathname === path;
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const toggleOffcanvas = () => setShow(!show);

  const [selectedOption, setSelectedOption] = useState(null);
  const handleShow = () => setShow(true);

  const [serviceTypes, setServiceTypes] = useState([]);
  const [services, setService] = useState([]);
  const [serviceId, setServiceIdState] = useState("");
  const [serviceName, setServiceName] = useState("");

  const { id } = useParams();

  const getDataAll = async () => {
    try {
      const response = await getData(`/finance/service/f-service-type`);
      if (response?.success) {
        setServiceTypes(response?.data);
        console.log(response?.data, 'service types');
        getRelatedServices(response?.data[0]?.id);
        setServiceId(response?.data[0]?.id);
        setTypeId(response?.data[0]?.id)
      }

    } catch (error) {
      console.error("Error fetching service types:", error);
    }
  };

  const getRelatedServices = async (ServicetypeId) => {
    console.log("getting", ServicetypeId);
    try {
      const response = await getData(`/finance/service/f-service/${ServicetypeId}`);
      setService(response?.data);
      console.log(response?.data, 'related services for type id:', ServicetypeId);
    } catch (error) {
      console.error("Error fetching related courses:", error);
    }
  };

  useEffect(() => {
    getDataAll();
    getRelatedServices(typeId);
  }, []);

  const setServiceId = (id) => {
    Cookies.set("service_id", id, {
      expires: 1,
    });
    Cookies.set("service_type_id", typeId, {
      expires: 1,
    });
    // setTypeId(id);
  };

  const handleServiceTypeClick = (typeId) => {
    // setServiceId(typeId);
    setTypeId(typeId)
    getRelatedServices(typeId);
  };

  const handleLoanClick = async (id, name) => {
    await setServiceId(id);
    await setServiceName(name);
    setServiceModalShow(true)
  };

  const handleInsuranceClick = async (id, name) => {
    await setServiceId(id);
    await setServiceName(name);
    setServiceModalShow(true)
  };



  console.log("serviceName", serviceName);
  console.log("serviceId", serviceId);


  const handleSelect = (eventKey) => {
    setSelectedOption(eventKey);
  };

  const [activeLink, setActiveLink] = useState("Home");
  useEffect(() => {
    const path = location.pathname.substring(1);
    setActiveLink(path || "Home");
  }, [location.pathname]);


  const handleNavItemClick = (selectedLink) => {
    setActiveLink(selectedLink);
    const navbarToggle = document.querySelector(".navbar-toggler");
    if (navbarToggle && window.innerWidth <= 992) {
      navbarToggle.click();
    }
  };

  return (
    <section className="Finance-Header-section-main">
      <Container>
        <div className="Finance-Header-section">
          <Navbar collapseOnSelect expand="lg">
            <Navbar.Brand href="/finance/home">
              <img
                className="headlogo ms-md-5 ms-sm-3 ms-3"
                src={process.env.PUBLIC_URL + "/assets/images/logo/Profcyma-logotwo.png"} alt="Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ms-auto nav-bar">
                <NavLink
                  to="/finance/home"
                  className="nav-link home-tab"
                  active={isActive("/finance/home")}
                  onClick={handleNavItemClick}
                >
                  Home
                  <div className="border_effect-tringle"></div>
                </NavLink>
                <NavLink
                  to="/finance/aboutus"
                  className="nav-link"
                  onClick={handleNavItemClick}
                >
                  About Us
                  <div className="border_effect-tringle"></div>
                </NavLink>
                {/* Mobile view dropdown Services start*/}
                <Dropdown className="mobile-view-sevices">
                  <Dropdown.Toggle
                    as={NavLink}
                    className="nav-link "
                    id="dropdown-services"
                  >
                    
                    Services
                    <FontAwesomeIcon icon={faAngleDown} className="pt-1" />
                    <div className="border_effect-tringle"></div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-menu">
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Loan</Accordion.Header>
                        <Accordion.Body>
                          <div className="scroller">
                            <Link>
                              <p onClick={() => setLoanModalShow(true)}>
                                Home Loan
                              </p>

                            </Link>
                            <Link>
                              <p onClick={() => setLoanModalShow(true)}>
                                Gold Loan
                              </p>

                            </Link>
                            <Link>
                              <p onClick={() => setLoanModalShow(true)}>
                                Bike Loan
                              </p>

                            </Link>
                            <Link>
                              <p onClick={() => setLoanModalShow(true)}>
                                Car Loan
                              </p>

                            </Link>
                            <Link>
                              <p onClick={() => setLoanModalShow(true)}>
                                Personal Loan
                              </p>

                            </Link>
                            <Link>
                              <p onClick={() => setLoanModalShow(true)}>
                                Business Loan
                              </p>

                            </Link>
                            <Link>
                              <p onClick={() => setLoanModalShow(true)}>
                                Education Loan
                              </p>

                            </Link>
                            <Link>
                              <p onClick={() => setLoanModalShow(true)}>
                                Loan Against Property (LAP)
                              </p>

                            </Link>
                            <Link>
                              <p onClick={() => setLoanModalShow(true)}>
                                Loans Against Insurance Policies
                              </p>

                            </Link>
                            <Link>
                              <p onClick={() => setLoanModalShow(true)}>
                                Loans Against Mutual Funds And Shares
                              </p>

                            </Link>
                            <Link>
                              <p onClick={() => setLoanModalShow(true)}>
                                Loans Against Fixed Deposit
                              </p>

                            </Link>
                            <Link>
                              <p onClick={() => setLoanModalShow(true)}>
                                Loans For Electronics
                              </p>

                            </Link>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Insurance</Accordion.Header>
                        <Accordion.Body>
                          <div className="scroller">
                            <Link>
                              <p onClick={() => setInsuranceModalShow(true)}>
                                Home Insurance
                              </p>
                            </Link>
                            <Link>
                              <p onClick={() => setInsuranceModalShow(true)}>
                                Life Insurance
                              </p>
                            </Link>
                            <Link>
                              <p onClick={() => setInsuranceModalShow(true)}>
                                Travel Insurance
                              </p>
                            </Link>
                            <Link>
                              <p onClick={() => setInsuranceModalShow(true)}>
                                Term Insurance
                              </p>
                            </Link>
                            <Link>
                              <p onClick={() => setInsuranceModalShow(true)}>
                                Health Insurance
                              </p>
                            </Link>
                            <Link>
                              <p onClick={() => setInsuranceModalShow(true)}>
                                Commercial Insurance
                              </p>
                            </Link>
                            <Link>
                              <p onClick={() => setInsuranceModalShow(true)}>
                                Child Insurance Plans
                              </p>
                            </Link>
                            <Link>
                              <p onClick={() => setInsuranceModalShow(true)}>
                                Retirement Plans
                              </p>
                            </Link>
                            <Link>
                              <p onClick={() => setInsuranceModalShow(true)}>
                                Maternity Health Insurance
                              </p>
                            </Link>
                            <Link>
                              <p onClick={() => setInsuranceModalShow(true)}>
                                Senior Citizen Health Insurance
                              </p>
                            </Link>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Dropdown.Menu>
                </Dropdown>

                <div className="mobile-view-sevices">
                  <NavLink
                    to="/finance/blog"
                    className="nav-link home-tab"
                    active={isActive("/finance/home")}
                    onClick={handleNavItemClick}
                  >
                    Blog
                    <div className="border_effect-tringle"></div>
                  </NavLink>
                  <NavLink
                    to="/finance/privacy"
                    className="nav-link"
                    onClick={handleNavItemClick}
                  >
                    Privacy Policy
                    <div className="border_effect-tringle"></div>
                  </NavLink>
                  <NavLink
                    to="/finance/contact-us"
                    className="nav-link"
                    onClick={handleNavItemClick}
                  >
                    Contact Us
                    <div className="border_effect-tringle"></div>
                  </NavLink>
                </div>
                {/* Mobile view dropdown Services end*/}
                <NavDropdown
                  title={
                    <span>
                      Services <FontAwesomeIcon icon={faAngleDown} />
                      {/* <div className="border_effect-tringle"></div> */}
                    </span>
                  }
                  id="collapsible-nav-dropdown"
                  className="header-lists"
                >
                  <div className="dropdown-mains">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey={serviceTypes[0]?.id}
                    >
                      <Row>
                        <Col xxl={3} xl={3} lg={2} md={3} sm={12} xs={12}>
                          <Nav
                            variant="pills"
                            className="flex-column loan-nav-class"
                          >
                            {serviceTypes?.map((val) => (
                              <Nav.Item key={val?.id}>
                                <Nav.Link eventKey={val?.id} onClick={() => handleServiceTypeClick(val?.id)}  >
                                  {val?.name}
                                </Nav.Link>
                              </Nav.Item>
                            ))}
                          </Nav>
                        </Col>
                        <Col xxl={9} xl={9} lg={10} md={9} sm={12} xs={12}>
                          <div className="scroll-tabbb">
                            <Tab.Content>
                              <Swiper
                                spaceBetween={50}
                                slidesPerView={1}
                                onSlideChange={() =>
                                  console.log("slide change")
                                }
                                onSwiper={(swiper) => console.log(swiper)}
                                pagination={{
                                  clickable: true,
                                }}
                                modules={[Pagination]}
                              >
                                {typeId === 2 && (
                                  <SwiperSlide eventKey={serviceTypes[0]?.id}>
                                    <Row>
                                      {services?.map((service) => (
                                        <Col key={service.id} md={3}>
                                          <Card
                                            className="services-card"
                                            onClick={() => {
                                              handleInsuranceClick(service.id, service?.name)
                                            }}

                                          >
                                            <Card.Img
                                              variant="top"
                                              src={IMG_URL + service?.image}
                                            />
                                            <div className="header-arrow-text">
                                              <p className="card-text">
                                                {service?.name}
                                              </p>
                                              <FontAwesomeIcon
                                                icon="fa-solid fa-arrow-right-long"
                                                className="text-end"
                                              />
                                            </div>
                                          </Card>
                                        </Col>
                                      ))}
                                    </Row>
                                  </SwiperSlide>
                                )}

                                {typeId === 1 && (
                                  <SwiperSlide eventKey={serviceTypes[0]?.id}>
                                    <Row>
                                      {services?.map((service) => (
                                        <Col key={service.id} md={3}>
                                          <Card
                                            className="services-card"
                                            onClick={() => {
                                              handleLoanClick(service.id, service?.name)
                                            }}

                                          >
                                            <Card.Img
                                              variant="top"
                                              src={IMG_URL + service?.image}
                                            />
                                            <div className="header-arrow-text">
                                              <p className="card-text">
                                                {service?.name}
                                              </p>
                                              <FontAwesomeIcon
                                                icon="fa-solid fa-arrow-right-long"
                                                className="text-end"
                                              />
                                            </div>
                                          </Card>
                                        </Col>
                                      ))}
                                    </Row>
                                  </SwiperSlide>
                                )}
                              </Swiper>

                            </Tab.Content>
                        </div>
                        
                        </Col>
                      </Row>
                    </Tab.Container>
                  </div>
                </NavDropdown>
                <NavLink
                  to="/finance/loancalculator"
                  className="nav-link"
                  onClick={handleNavItemClick}
                >
                  Loan Calculator
                  <div className="border_effect-tringle"></div>
                </NavLink>
                <p className="nav-link ms-4 ms-lg-3">
                  <img
                    className="head-social-icon me-2"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Finance/Image/Header/mail.png"
                    }
                  />
                  inquiry@profcyma.com
                </p>
                <Dropdown>
                  <Dropdown.Toggle
                    className=" btn-dark toogle-btn-class ms-0  me-5"
                    id="dropdown-basic"
                  >
                    <FontAwesomeIcon icon={faBars} className="bars me-0 ms-0" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="drop-menu-class-second">
                    <Dropdown.Item

                      className="drop-item-class"
                    >
                      <Link to={'/finance/blog'}>
                        <img
                          className="drop-icon me-1"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Finance/Image/icons/blog1.png"
                          }
                        />
                        <span>Blog</span>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item

                      className="drop-item-class"
                    >
                      <Link to={'/finance/privacy'}>
                        <img
                          className="drop-icon me-1"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Finance/Image/icons/privacy1.png"
                          }
                        />
                        <span>Privacy Policy</span>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item

                      className="drop-item-class"
                    >
                      <Link to={'/finance/contact-us'}>
                        <img
                          className="drop-icon me-1"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Finance/Image/icons/contact1.png"
                          }
                        />
                        <span>Contact Us</span>
                      </Link>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </div>
      </Container>
      <FHomeLoanModal
        show={loanModalShow}
        onHide={() => setLoanModalShow(false)}
        serviceId={serviceId}
        serviceName={serviceName}
      />
      <FHomeInsuranceModal
        show={insuranceModalShow}
        onHide={() => setInsuranceModalShow(false)}
        serviceId={serviceId}
        serviceName={serviceName}
      />

      <ServiceFormModal
        show={serviceModalShow}
        onHide={() => setServiceModalShow(false)}
        serviceId={serviceId}
        serviceName={serviceName}
      />
    </section>
  );
};

export default FHeader;