import React from 'react'
import { Modal } from 'react-bootstrap'
import Success from '../../../Ed-tech/Admission/E-brochure/Animation/Success'

const SuccessForm = (props) => {
  return (
      <Modal
          {...props}
          size="sm"
          aria-labelledby="contained-modal-title-vcenter"
          centered
      >
          <Modal.Body>
              <div>
                  <Success />
                  <p className='mt-3 text-center'>Message Send Successfully....!</p>
              </div>
          </Modal.Body>
      </Modal>
  )
}

export default SuccessForm