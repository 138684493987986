import React from "react";
import SubmitSteps from "./SubmitSteps/SubmitSteps";
import "./FHomeLoan.css";
import ChooseFinance from "./ChooseFinance/ChooseFinance";
import Apply from "./Apply/Apply";
import Score from "./Score/Score";
import OurPartner from "./OurPartner/OurPartner";
import LoanTestimonial from "./LoanTestimonial/LoanTestimonial";
import ApplyLoan from "./ApplyLoan/ApplyLoan";
import HomeLoanBanner from "./HomeLoanBanner/HomeLoanBanner";
import { useParams } from 'react-router-dom';
const FHomeLoan = () => {
  const { id } = useParams();
  console.log(id, "idididididdi")
  return (
    <>
      <HomeLoanBanner loan_form_id={id} />
      <SubmitSteps loan_form_id={id} />
      <ChooseFinance loan_form_id={id} />
      <Apply loan_form_id={id} />
      <Score loan_form_id={id} />
      <OurPartner loan_form_id={id} />
      <LoanTestimonial loan_form_id={id} />
      <ApplyLoan loan_form_id={id} />
    </>
  );
};

export default FHomeLoan;
