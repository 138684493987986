import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import button from "react-bootstrap/button";
import "../../MainDashboard/JpMainDashboard.css";
import ViewlistModal from "../../../JP-Common-Elements/Jp-modals/ViewlistModal/ViewlistModal";
import Button from "react-bootstrap/button";
import InterviewSchedule from "../ApplicantTab/InterviewSchedule/InterviewSchedule";
import { Context } from "../../../../../utils/context";

export default function ShortlishTab() {
  const [modalShow, setModalShow] = useState(false);
  const [content, setContent] = useState(true);
  const [showInterviewSchedule, setShowInterviewSchedule] = useState(false);


  const { getData, postData, IMG_URL } = useContext(Context);

  const handleModalClose = () => {
    setModalShow(false);
    setContent(false);
  };


  function formatDate(dateString) {
    if (!dateString) return ""; // Handle cases where dateString is undefined or null

    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear()).slice(-2);

    return `${day}-${month}-${year}`;
  }


  const handleCancelInterview = () => {
    setShowInterviewSchedule(false);
    setContent(false);
  };
  const [shortListData, setShortListData] = useState();
  const getShortListJobs = async () => {
    try {
      const res = await getData("/job-portal/employer/dashboard/shortlist-student")
      if (res?.success) {
        setShortListData(res?.data?.data)
      } else {
        console.log("Error");
      }
    } catch (error) {
      console.log(error.message);
    }
  }


  const [apllicationDetails, setApplicationDetails] = useState();
  const getShortListedApplication = async (jobid) => {
    if (jobid) {
      try {
        const res = await getData(`/job-portal/employer/dashboard/shortlist-resume-application/${jobid}`)
        if (res?.success) {
          setModalShow(true);
          setApplicationDetails(res?.data?.data?.rows)
        } else {
          console.log("Error");
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  }


  console.log(apllicationDetails, "apllicationDetailsapllicationDetails");
  const [applicantData, setApplicantData] = useState();

  useEffect(() => {
    getShortListJobs();
    getShortListedApplication();
  }, []);
  return (
    <>
      <section className="jp-employer-forth-shortlist applicantShort">
        <div className="heading d-flex">
          <h3>Shortlisted Resume</h3>
          <div className="orange-line"></div>
        </div>

        {showInterviewSchedule ? (
          <InterviewSchedule onCancel={handleCancelInterview} applicantData={applicantData} />
        ) : (
          <>
            {content ? (
              <>
                {shortListData?.rows?.map((val) =>
                  <div className="shortlist-card">
                    <div className="applicant-card">
                      <div className="upper-div">
                        <h6 className="graphic-text mt-2">{val?.job_title?.name}</h6>
                        <div className="d-flex counts justify-content-end">
                          <p className="mt-2 ">Job Status</p>
                          <button className="open-btn ">{val?.job_status?.name}</button>
                        </div>
                      </div>
                      <div className="row my-2">
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-12">
                          <div className=" info-div d-flex">
                            <FontAwesomeIcon icon="fa-solid fa-calendar-days" />
                            <p>Posted on : {formatDate(val?.createdAt)}</p>
                          </div>
                          <div className="info-div d-flex">
                            <FontAwesomeIcon icon="fa-solid fa-location-dot" />
                            <p>{val?.city?.name}</p>
                          </div>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-8 col-12">
                          <div className=" counts  second-col">
                            <div className="d-flex me-3">
                              <p>Active</p>
                              <p className="orange-text">50</p>
                            </div>
                            <div className="d-flex me-3">
                              <p>New</p>
                              <p>14</p>
                            </div>
                            <div className="d-flex me-3">
                              <p>Contacting</p>
                              <p>50</p>
                            </div>
                            <div className="d-flex me-3">
                              <p>Hired</p>
                              <p>0</p>
                            </div>
                          </div>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-12 text-end">
                          <div className="info-div d-flex justify-content-end mt-3">
                            <FontAwesomeIcon icon="fa-solid fa-star" />
                            <p>Bronze</p>
                          </div>
                          <button
                            className="list-btn"
                            onClick={() => getShortListedApplication(val?.id)}
                          >
                            View List
                          </button>
                        </div>
                      </div>
                      {/* <ViewlistModal
                        show={modalShow}
                        handleModalClose={handleModalClose}
                        onHide={() => setModalShow(false)}
                        setShowInterviewSchedule={setShowInterviewSchedule}
                      /> */}
                      <ViewlistModal
                        jobid={val?.id}
                        show={modalShow}
                        apllicationDetails={apllicationDetails}
                        setApplicantData={setApplicantData}
                        handleModalClose={handleModalClose}
                        onHide={() => setModalShow(false)}
                        setShowInterviewSchedule={setShowInterviewSchedule}
                      />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="secondddMain">
                  <div className="backapplicant">
                    <p onClick={() => setContent(true)}>Back to Applicant</p>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="applicantName">
                        <h3>
                          Name : {applicantData?.job_portal_user?.job_seeker_personal_detail?.first_name} {applicantData?.job_portal_user?.job_seeker_personal_detail?.last_name}<span> ({applicantData?.job_portal_user?.job_seeker_personal_detail?.job_profile_title})</span>
                        </h3>
                        <p>Current Address : {applicantData?.job_portal_user?.job_seeker_personal_detail?.current_address}</p>
                        <p>Permanent Address : {applicantData?.job_portal_user?.job_seeker_personal_detail?.permenent_address}</p>
                        <p>{applicantData?.job_portal_user?.job_seeker_personal_detail?.email}</p>
                        <p>{applicantData?.job_portal_user?.job_seeker_personal_detail?.contact_no}</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 text-end">
                      <div className="aappliedOntxt">
                        <h3>Applied on : {formatDate(applicantData?.createdAt)}</h3>
                      </div>
                      <div>
                        {/* <p>Status</p>
                          <div className="shhorebtn">
                            <button type="button" className="shortlistbtn btnnn">
                              Shortlist
                            </button>
                            <button type="button" className="holdbtn btnnn">
                              On Hold
                            </button>
                            <button type="button" className="rejectbtn btnnn">
                              Reject
                            </button>
                          </div> */}
                        <div>
                          <button
                            type="button"
                            className="scheduleInbtn"
                            onClick={() => setShowInterviewSchedule(true)}
                          >
                            Schedule Interview
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="card additionalQuesmain">
                      <div className="additional">
                        <h3>Additional Questions' Answers</h3>
                      </div>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="questionOne">
                            <p>
                              {" "}
                              1. How much experience do you have as a UI/UX
                              designer?
                            </p>
                            <span className="year-text">2 Years</span>
                          </div>
                          <div className="questionOne">
                            <p>
                              2. How much experience do you have as a UI/UX
                              designer?
                            </p>
                            <span className="year-text">2 Years</span>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="questionOne">
                            <p>
                              3. How much experience do you have as a UI/UX
                              designer?
                            </p>
                            <span className="year-text">2 Years</span>
                          </div>
                          <div className="questionOne">
                            <p>
                              4. How much experience do you have as a UI/UX
                              designer?
                            </p>
                            <span className="year-text">2 Years</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row postfolioMain">
                    <div className="col-lg-7 col-md-6">
                      <div className="resumeback">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Job-portal/Images/employer/Portfolio/demoResume.png"
                          }
                        />
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-6 text-end my-auto">
                      <div className="uploadDownMain">
                        <div>
                          <Button>
                            {" "}
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/Job-portal/Images/employer/Portfolio/filepdf.png"
                              }
                              className="pdfImg"
                            />
                            View Portfolio PDF
                          </Button>
                        </div>
                        <div>
                          <Button>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/Job-portal/Images/employer/Portfolio/Iconfeather.png"
                              }
                              className="pdfImg"
                            />{" "}
                            View Resume Link
                          </Button>
                        </div>
                        <div>
                          <Button className="downlod-resume-btn">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/Job-portal/Images/employer/Portfolio/Iconawesome.png"
                              }
                              className="pdfImg"
                            />
                            Download Resume
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </section>
    </>
  );
}
