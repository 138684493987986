import React from 'react'
import SkillQuestions from '../JP-Common-Elements/JP-Quetions_stepForm/SkillQuestions/SkillQuestions'

function JPFewClickAway() {
  return (
    <>
        <SkillQuestions/>
    </>
  )
}

export default JPFewClickAway