import React, { useContext, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import "./JpEmpUpdateprofile.css"
import Cookies from "js-cookie";
import { Context } from "../../../../../utils/context";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
function JpEmpUpdateprofile() {
    const { id } = useParams();

    console.log(Cookies.get('llp_web_security'), 'ppppppppppppp');

    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();

    const token = Cookies.get("llp_web_security");
    let decodedToken;
    if (token) {
        decodedToken = JSON.parse(atob(token.split(".")[1]));
    } else {
        console.error('Token is undefined');
        // Handle the case where token is not available
    }
    console.log(decodedToken, "decodedToken");
    const { postData, getData, Select2Data, IMG_URL } = useContext(Context);
    // const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);
    const getDataAll = async () => {
        try {
            const response = await getData(
                `/job-portal/employer/company-details/company-edit`
            );
            setData(response?.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data, "comppany edit ");
    // console.log(Question, "add question");

    const [companyProfileData, setCompanyProfileData] = useState();

    const getCompanyProfileData = async () => {
        const res = await getData("/job-portal/employer/company-details/company-edit/profile-image-company/data");
        if (res?.success) {
            setCompanyProfileData(res?.data);
        } else {
            console.error("Error fetching  data:", res?.error);
        }
    };

    useEffect(() => {

        getCompanyProfileData();
        getDataAll();
        // getDataQuestion();
    }, []);
    return (
        <>
            <section className='JpEmp-update-profile'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-12'>
                            <div className='company-card'>
                                <div class="company-img-div">
                                    <img class="com-logo" src={IMG_URL + companyProfileData?.image} />
                                </div>
                            </div>
                        </div>
                        <div className='col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-12'>
                            <div className='details-od-company'>
                                <div className='top-company-name'>
                                    <div className='heading '>
                                        <h5 className='top-heading '>{data?.company_name}</h5>
                                    </div>
                                    <div>
                                        <Link to="/job-portal/employer/dashboard"><button className='back-btn me-2'>Back to Dashboard</button></Link>
                                        <Link to="/job-portal/employer/companyprofile"><button className='edit-btn'>Edit Profile</button></Link>
                                    </div>
                                </div>
                                <h6 className='titles'> {data?.industry?.name}</h6>

                                <div className='row'>
                                    <div className='col-xxl-6 col-xl-6 col-md-6 col-sm-6 col-12'>
                                        <div className='d-flex my-3'>
                                            <div className='icon-bg-div'>
                                                <FontAwesomeIcon icon="fa-solid fa-location-dot" className='fa-info-icon' />
                                            </div>
                                            <span className='text-span'>{data?.headquarter}</span>
                                        </div>
                                        <div className='d-flex my-3'>
                                            <div className='icon-bg-div'>
                                                <FontAwesomeIcon icon="fa-solid fa-phone" className='fa-info-icon' />
                                            </div>
                                            <span className='text-span'>{data?.contact_no}</span>
                                        </div>
                                    </div>
                                    <div className='col-xxl-6 col-xl-6 col-md-6 col-sm-6 col-12'>
                                        <div className='d-flex my-3'>
                                            <div className='icon-bg-div'>
                                                <FontAwesomeIcon icon="fa-solid fa-envelope" className='fa-info-icon' />

                                            </div>
                                            <span className='text-span'>{data?.email}</span>
                                        </div>
                                        <div className='d-flex my-3'>
                                            <div className='icon-bg-div'>
                                                <FontAwesomeIcon icon="fa-solid fa-earth-americas" className='fa-info-icon' />
                                            </div><span className='text-span'>{data?.company_website}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className='social-links'>

                                    <p className='social-text'>Social Links</p>
                                    {data?.social_networks?.map((val) =>
                                        <Link to={val?.url}> {val?.url} </Link>
                                    )}
                                    {/* <Link to="https://profcymaglobal.com/"> https://linkedin.com/in/profcymasolutions </Link>
                                <Link to="https://profcymaglobal.com/"> https://linkedin.com/in/profcymasolutions </Link> */}
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='details-card'>
                        {/* <div className='row main-row'>
                {data.map((item, index) => (
                    <div key={index} className='col-xxl-2 col-xl-2 col-lg-3 col-md-4 col-sm-6 col-6'>
                        <h6 className='titles'>{item.title}</h6>
                        <p className='descriptn'>{item.description}</p>
                    </div>
                ))}
            </div> */}
                    </div>
                    <div className='about-us'>
                        <div className='heading text-center'>
                            <h5 className='top-heading m-0'>About Us</h5>
                        </div>
                        <div className='about-descripn'>
                            <p className='descriptn'>{data?.about_us}</p>
                            {/* <p className='descriptn'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p> */}
                        </div>
                    </div>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-8 col-xl-8 col-lg-8 col-11 '>

                            <video className='video upload-company-img' controls autoPlay loop mute >
                                <source class="upload-company-img" src={IMG_URL + data?.video}></source>
                            </video>
                        </div>
                    </div>
                    <div className='achivment-sec'>
                        <div className='heading text-center '>
                            <h5 className='bottom-heading m-0'>Awards & Achievements</h5>
                        </div>
                        <div className='row justify-content-center'>
                            <div className='col-xxl-9 col-xl-9 col-lg-9 col-11'>
                                <div className='row award-row'>
                                    {data?.award_achivements?.map((achievement, index) => (
                                        <div key={index} className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12'>
                                            <div className='achievement-card'>
                                                <h4>{achievement.name}</h4>
                                                <p className='years'>{achievement?.year}</p>
                                                <p className='overview'>{htmlToReactParser.parse(achievement?.description)}</p>
                                                <div className='troffy'>
                                                    <img className="troffy-img" src="/assets/Job-portal/Images/employer/companyform/troffy.png" alt="trophy" />
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='team-members'>
                        <div className='heading text-center '>
                            <h5 className='bottom-heading m-0'>Team members</h5>
                        </div>
                        <div className='row justify-content-center'>
                            <div className='col-xxl-8 col-xl-8 col-lg-8 col-11 '>
                                <div className='row team-row'>
                                    {data?.team_members?.map((member, index) => (
                                        <div key={index} className='col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12'>
                                            <div className='team-mamber-card'>
                                                <div className='upper-border'></div>
                                                <img className="owner-img" src={IMG_URL + member?.image} alt="trophy" />
                                                <div className='all-member-names'>
                                                    <div className='hover-text'>
                                                        <p className='memb-name'>{member?.name}</p>
                                                        <p className='position'>{member?.designation}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='achivment-sec'>
                        <div className='heading text-center '>
                            <h5 className='bottom-heading m-0'>Branchs & Locations</h5>
                        </div>
                        <div className='row justify-content-center'>
                            <div className='col-xxl-9 col-xl-9 col-lg-9 col-11'>
                                <div className='row award-row'>
                                    {data?.locations?.map((location, index) => (
                                        <div key={index} className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-12'>
                                            <div className='achievement-card'>
                                                <h4>{location.name}</h4>
                                                <p className='overview'>{location?.address}</p>
                                                <p className='years'>{location?.city_id?.label} , {location?.state_id?.label} , {location?.country_id?.label}</p>
                                                <p className='years'>{location?.pincode}</p>
                                                {/* <div className='troffy'>
                                                    <img className="troffy-img" src="/assets/Job-portal/Images/employer/companyform/troffy.png" alt="trophy" />
                                                </div> */}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default JpEmpUpdateprofile