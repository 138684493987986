import React, { useEffect, useRef, useState, useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import Container from "react-bootstrap/Container";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import "./FinancialResiLience.css";
import { Context } from '../../../../utils/context';
import AOS from "aos";
import "aos/dist/aos.css";
const FinancialResiLience = ({ title, imageSrc, description }) => {
  const FinanceDetails = [
    {
      headingname: "STREAMLINED PROCESSES",
      text:
        "Experience swift approvals and hassle-free transactions, ensuring efficiency in obtaining loans and insurance through Profcyma.",
      no: "01",
    },
    {
      headingname: "COMPETITIVE RATES",
      text:
        "Benefit from our industry-leading rates, ensuring that you receive the most cost-effective loan and insurance solutions available in the market.",
      no: "02",
    },
    {
      headingname: "PERSONALIZED GUIDANCE",
      text:
        "Enjoy personalized financial guidance from our experts, tailor-ing loan and insurance options to match your unique needs and aspirations.",
      no: "03",
    },
    {
      headingname: "CUTTING-EDGE TECHNOLOGY",
      text:
        "Harness the power of advanced technology for a seamless and user-friendly experience in managing and accessing your loan and insurance details.",
      no: "04",
    },
  ];

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const getDataAll = async () => {
    try {
      const response = await getData(`/finance/home/f-advantages`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data, "sssss");

  useEffect(() => {
    getDataAll();
  }, []);

  useEffect(() => {
    AOS.init({ once: true });
  }, [])

  return (
    <>
      {data && data?.length > 0 &&
        <section className="Financial-ResiLience">
          <Container fluid>
            <div className="content-Financial">
              <div className="main">
                <p className="text">
                  Advantages of Getting Loan/Insurance From Profcyma
                </p>
                <div className="border-text"></div>
              </div>
              <h1 className="title">Your path to financial resilience</h1>
              <Container data-aos="fade-right" data-aos-duration="3000">

                {/* <Row className="justify-content-center">
                {FinanceDetails.map((item, index) => (
                  <Col xxl={3} xl={3} lg={4} sm={6} key={index}>
                    <div className="Main-card">
                      <div className="main-inner">
                        <div className="inner-section">
                          <div className="content-inner-section">
                            <h4 className="heading">{item.headingname}</h4>
                            <p className="text-main">{item.text}</p>
                          </div>
                        </div>
                        <div className="no-inner">
                          <div className="circle">
                            <p className="No">{item.no}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row> */}

                <Swiper
                  spaceBetween={30}
                  pagination={
                    {
                      clickable: true,
                      dynamicBullets: true,

                    }}
                  modules={[Pagination]}
                  breakpoints={{
                    576: { slidesPerView: 2 },
                    768: { slidesPerView: 2 },
                    1024: { slidesPerView: 3 },
                    1200: { slidesPerView: 4 },
                  }}
                  className="mySwiper "
                >
                  {/* // {FinanceDetails.map((item, index) => ( */}
                  {data?.map((finance, index) => (
                    <SwiperSlide key={index}>
                      <div className="Main-card mb-5">
                        <div className="main-inner">
                          <div className="inner-section">
                            <div className="content-inner-section">
                              <h4 className="heading">{finance.name}</h4>
                              <p className="text-main">{htmlToReactParser.parse(finance.description)}</p>
                            </div>
                          </div>
                          <div className="no-inner">
                            <div className="circle">
                              <p className="No">{index + 1}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                {/* ))} */}
              </Container>

            </div>
          </Container>
        </section>
      }
    </>
  );
};

export default FinancialResiLience;
