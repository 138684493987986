import React, { useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";
import { RegxExpression } from "../../../../../../../utils/apis/common/Common";
import classNames from "classnames"
import { Context } from "../../../../../../../utils/context";
import { AwardPost, getAward } from "../../../../../../../utils/apis/jobseekar/jobseekar";


const Award = ({ jobSeekarDetailsID, setAwardCount }) => {

  useEffect(() => {
    reset();
    window.scrollTo(0, 0);
  }, []);


  const { postData, getData, Select2Data, IMG_URL } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      data: [
        {
          award_company_name: "",
          name_of_award: "",
          award_year: ""
        },
      ],
    },
  });

  const { append, fields, remove } = useFieldArray({
    control,
    name: "data",
  });

  const [data, setData] = useState([]);

  const getAwardDetails = async () => {
    const res = await getAward(jobSeekarDetailsID);
    if (res?.success) {
      if (res?.data?.length > 0) {
        reset({ data: res?.data });
        setData(res?.data)
      } else {
        append({
          education_level: "",
          awarded_year: "",
          descipline: "",
          authority: "",
          degree_name: "",
          collage: "",
          percentage: "",
        });
      }
    }
  };

  useEffect(() => {
    getAwardDetails();
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const onSubmit = async (d) => {
    d.personal_details_id = jobSeekarDetailsID
    console.log(d, "wdhdsasasa");
    const res = await AwardPost(d);
    if (res?.success) {
      console.log(res, "addresspost");
    }
  }


  return (
    <>
      <div className="Employment_Details_award_form">
        <Form>
          <div className="personal-details-form step-Form-Conteiner ">
            {/* form-heading-holder */}
            <div className="form-header-holder">
              <div className="row">
                <div className="col-md-12 col-sm-12 col-12">
                  <div className="heading-holder">
                    <h6>Award</h6>
                  </div>
                </div>
              </div>
            </div>

            <div className="personal-details-form ">
              {/* form-holder */}
              <div className="details-form-holder">
                <div className="form-container">
                  {/*Previous Job*/}
                  <div className="Percentages-Add-Education">
                    <div className="row justify-content-between">
                      <div className="col-md-3">
                        <div className="form-field">
                          <Form.Group controlId="">
                            <div className="previous-job-details">
                              <div className="job-content-holder">
                                <h1>{data && data[0]?.award_company_name}</h1>
                                <p>{data && data[0]?.name_of_award}</p>
                                <p>
                                  {data && data[0]?.award_year && new Date(data[0].award_year).getFullYear()}
                                </p>
                              </div>
                            </div>
                          </Form.Group>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="Add-education-btn-holder form-field">
                          <div className="apply-btn-holder">
                            <Button className="apply_now_btn" type="button" onClick={() =>
                              append({
                                award_company_name: "",
                                name_of_award: "",
                                award_year: ""
                              })
                            }>
                              Add Award
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {fields?.map((item, index) => (
                    <>
                      {/* Company Name*/}
                      <div className=" ">
                        {index !== 0 && (
                          <div className="addremoveadress">
                            <div className="text-end Addnewadresstxt">
                              <p className="" onClick={() => remove(index)}>
                                Remove Award{" "}
                                {/* <FontAwesomeIcon icon="fa-solid fa-minus" /> */}
                              </p>
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-field">
                              <Form.Group controlId="">
                                <Form.Label className="">Company Name</Form.Label>

                                <Form.Control
                                  type="text"
                                  name={`data[${index}].award_company_name`}
                                  defaultValue={item.award_company_name}
                                  {...register(`data[${index}].award_company_name`, {
                                    required: "Enter Company Name is Required",
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.award_company_name,
                                    // "is-valid": getValues("award_company_name"),
                                  })}
                                  onKeyDown={(event) => {
                                    if (!RegxExpression.name.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/*Name of Award */}
                      <div className="">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-field">
                              <Form.Group controlId="">
                                <Form.Label className="">
                                  Name of Award
                                </Form.Label>

                                <Form.Control
                                  type="text"
                                  name={`data[${index}].name_of_award`}
                                  placeholder="Enter Name of Award"
                                  defaultValue={item.name_of_award}
                                  {...register(`data[${index}].name_of_award`, {
                                    required: "Name of Award is Required",
                                  })}
                                  className={classNames("", {
                                    "is-invalid": errors?.name_of_award,
                                    // "is-valid": getValues("company_name"),
                                  })}
                                  onKeyDown={(event) => {
                                    if (!RegxExpression.name.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/*Award Year */}
                      <div className="">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-field">
                              <Form.Group controlId="">
                                <Form.Label className="">Award Year</Form.Label>

                                <div>
                                  <Form.Control
                                    type="date"
                                    placeholder="Enter Date Of Award"
                                    name={`data[${index}].award_year`}
                                    defaultValue={item.award_year}
                                    {...register(`data[${index}].award_year`, {
                                      required: "Name of Award is Required",
                                    })}
                                    className={classNames("", {
                                      "is-invalid": errors?.award_year,
                                      // "is-valid": getValues("company_name"),
                                    })}
                                  />
                                </div>
                              </Form.Group>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                  {/*Save */}
                  <div className="form-field">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="apply-btn-holder">
                          <Button className="apply_now_btn" type="button" onClick={handleSubmit(onSubmit)}>Save</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default Award;
