import React, { useEffect, useRef, useState, useContext } from 'react';
import "react-international-phone/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { Context } from '../../../../../../../utils/context';
import { useForm, Controller, SubmitHandler } from "react-hook-form";

function StepEightSelectResidenceType({ setResidence_type_id, loanFormDetail }) {
  const [selected, setSelected] = useState([false, false, false]);

  useEffect(() => {
    reset(loanFormDetail);
    console.log("loanFormDetail form 8:-", loanFormDetail);
    setSelected(loanFormDetail?.bank_id?.value)
  }, [loanFormDetail]);

  const [modalShow, setModalShow] = useState(false);
  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
  const [data, setData] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset
  } = useForm();

  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/master/all-residence-type`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataAll();
  }, []);

  const handleOptionSelect = (id) => {
    setSelected(id)
    setResidence_type_id(id);
  };

  return (
    <>
      <div className="StepTwoEmploymentType">
        <Form>
          {data?.map((residence, index) => (
            <div className="EmploymentType-content" key={index}>
              <div className="text-holder">
                <p>{residence?.name}</p>
              </div>
              <div className="circle-icon-holder">
                <Form.Check
                  inline
                  name="residence_type_id"
                  type="radio"
                  value={residence.id}
                  checked={selected === residence.id}
                  {...register('residence_type_id', { required: true })}
                  onChange={() => handleOptionSelect(index, residence.id)}
                />
              </div>
            </div>
          ))}
        </Form>
      </div>
    </>
  );
}

export default StepEightSelectResidenceType;
