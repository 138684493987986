import { React, useRef, useEffect, useState, useContext } from "react";
import "./Footer.css";
import Container from 'react-bootstrap/Container';
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from 'react-router-dom';
import { footer_university } from "../../../utils/apis/common/Common";

import { Context } from "../../../utils/context";

library.add(fas);
const Footer = ({ handleLinkClick, type_id }) => {
  const { pathname } = useLocation();
  const location = useLocation();
  console.log(location, "loccc");
  const email = 'inquiry@profcyma.com';

  const year = new Date().getFullYear();

  const { IMG_URL, getData } = useContext(Context);



  const [alluniversity, setAlluniversity] = useState([]);

  const getAllUniversity = async () => {
    const res = await footer_university();
    console.log(res, "aditya univerity")

    if (res?.success) {
      setAlluniversity(res?.data);
    }
  };

  useEffect(() => {
    getAllUniversity();
  }, []);

  const handleHomeClick = () => {
    if (pathname === '/ed-tech/home') {
      window.scrollTo(0, 0);
    }
  };

  return (



    <>
      <section className="footer-section">
        <Container>
          <div className="row   justify-content-center">

            <div className="col-md-12 col-12">
              <div className="row">

                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mb-4">
                  <div className="logo-content">
                    <Link to="/ed-tech/home"><img className='footerlogo' src={process.env.PUBLIC_URL + '/assets/images/logo/Profcyma-LOGO_-for-website.png'} alt="Logo" /></Link>
                    <p className="descript title">
                      Upgrade Your Management Skills by Joining a community of learners focused on upskilling themselves,
                      with capabilities required to meet global workplace challenges.
                    </p>


                    <div className="social-icons-div">
                      <a
                        href="https://www.facebook.com/ProfcymaCareerSolutions/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="icons"
                          src={process.env.PUBLIC_URL + "/assets/images/icons/fb.png"}
                          alt="Facebook Logo"
                        />
                      </a>
                      <a
                        href="https://twitter.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="icons"
                          src={process.env.PUBLIC_URL + "/assets/images/icons/twitter.png"}
                          alt="Twitter Logo"
                        />
                      </a>
                      <a
                        href="https://www.instagram.com/profcyma_careersolutions/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          className="icons"
                          src={process.env.PUBLIC_URL + "/assets/images/icons/insta.png"}
                          alt="Instagram Logo"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 mt-3  mb-4">
                  <div className="links-sec">
                    <h3 className="title">Quick Links</h3>
                    <div className="row">
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-6">
                        <div className='foot-menu title'>
                          <NavLink to="/" activeClassName="active" onClick={handleHomeClick}> Home </NavLink>
                        </div>
                        <div className='foot-menu title'>
                          <NavLink to="/ed-tech/about" activeClassName="active"> About Us </NavLink>
                        </div>
                        <div className='foot-menu title'>
                          <NavLink to="/ed-tech/admission" activeClassName="active"> Admission </NavLink>
                        </div>
                        <div className='foot-menu title'>
                          <NavLink to="/ed-tech/blog" activeClassName="active"> Blogs</NavLink>
                        </div>
                        {/* <div className='foot-menu title'>
                          <NavLink to="/pg-courses/mba" activeClassName="active"> Courses</NavLink>
                        </div>
                        <div className='foot-menu title'>
                          <NavLink to="/ed-tech/university/dy-patil" activeClassName="active"> Universities</NavLink>
                        </div> */}
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6">
                        <div className='foot-menu title'>
                          <NavLink to="/ed-tech/contact-us" activeClassName="active"> Contact Us</NavLink>
                        </div>
                        <div className='foot-menu title'>
                          <NavLink to="/ed-tech/privacy-policy" activeClassName="active"> Privacy policy</NavLink>
                        </div>
                        <div className='foot-menu title'>
                          <NavLink to="/ed-tech/terms-and-condition" activeClassName="active"> Terms & Condition</NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12  mt-3  mb-4">
                  <div className="contact-sec">
                    <h3 className="title px-2">Contact</h3>
                    <div>
                      <div className="d-flex mb-2">
                        <div className="icons-bg ">
                          <FontAwesomeIcon icon="fa-solid fa-location-dot" className="footer-icon-location" />
                        </div>
                        <p className="title">2nd floor, Office No 3, Opp high-class soc, M.no 734 Near Suryadutta College, Bavdhan Khurd, Pune 411021.</p>
                      </div>
                      <div className="d-flex mb-2">
                        <div className="icons-bg ">
                          <FontAwesomeIcon icon="fa-solid fa-location-dot" className="footer-icon-location" />
                        </div>
                        <p className="title">Office No: 510 5th floor, Sky Max Mall, Datta Mandir Chowk, Viman Nagar Pune, 411014.</p>
                      </div>

                      <div className="d-flex mb-2">
                        <div className="icons-bg">
                          <FontAwesomeIcon icon="fa-solid fa-phone" className="footer-icon" />
                        </div>
                        <p className="title">1800-267-8961 / +91 96077 55557</p>
                      </div>
                      <div className="d-flex mb-2">

                        <div className="icons-bg">
                          <FontAwesomeIcon icon="fa-solid fa-envelope" className="footer-icon" />
                        </div>
                        <div>
                          <a href={`https://mail.google.com/mail/?view=cm&fs=1&to=${email}`} target="_blank" className="mail-text title" rel="noopener noreferrer">
                            {email}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12  mb-4">
                  <div className="links-sec">
                    <h3 className="title">Specializations</h3>
                    <div className="row">
                      {alluniversity.map((data, index) => (
                        <div className="col-lg-3 col-md-6 col-6">
                          <h6 className="footuniname"><img className="logo-icons me-2" src={IMG_URL + data?.image} alt="Logo" /></h6>
                          {data?.cource_universities?.map((course, index) => (
                            <div className="mb-4">
                              <h6 className="footuniname ">{course?.ed_tech_course?.name}</h6>
                              {course?.specializations?.map((specialization, index) => (
                                <div className={`foot-menu title ${location.pathname === `/ed-tech/specialization/${specialization?.id}` ? "activeclass" : ""}`}><Link to={`/ed-tech/specialization/${specialization?.id}`}>{specialization?.name}</Link></div>
                              ))}
                            </div>
                          ))}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border"></div>
          <div className='copyrights-div text-center'>
            <Link to="https://profcymaglobal.com/" target="_blank" rel="noopener noreferrer">
              <p className='title'>© {year} All Rights Reserved Terms Of Use | Privacy Policy Developed by <span className='profcyma-text'>Profcyma.</span></p>
            </Link>
          </div>


        </Container>
      </section>
    </>
  );
};

export default Footer;
