
import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import JpDashboardbanner from '../../JpDashboardbanner/JpDashboardbanner';
import "../../EmployerProfile/EmployerMyPackages/EmployerMyPackages.css";
import "./EmployerPackage.css"
import { Context } from "../../../../../utils/context";
function EmployerPackage() {
    const packageData = [
        {
            name: 'Silver Plan',
            price: '₹999'
        },
        {
            name: 'Gold Plan',
            price: '₹1999'
        },
        {
            name: 'Platinum Plan',
            price: '₹3999'
        },
        {
            name: 'Business Plan',
            price: '₹5999'
        }
    ];

    const { getData, IMG_URL } = useContext(Context);

    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/job-portal/packages`);
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data);

    useEffect(() => {
        getDataAll();
    }, []);

    return (
        <>
            <section className='emp-my-packages  emp-package'>
                <JpDashboardbanner />
                <div className='container'>
                    <div className='row justify-content-center my-5'>
                        <div className='col-xxl-6 col-xl-7 col-lg-8 col-md-11  col-12'>
                            <div className='row'>
                                <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-12'>
                                    <div className='my-package-card  package-card-two'>
                                        <div className='main-card'>
                                            <p className='red-text'>your subscription has been ended </p>
                                        </div>
                                        {/* {data?.map(()=>
                                        <div className='main-inner-card'>
                                            <div className='header-card-heading'>
                                                <h5>Bronze Plan</h5>
                                                <p>Free</p>
                                            </div>
                                            <div className='package-descripn'>
                                                <div className='row'>
                                                    <div className='col-xxl-6 col-6'>
                                                        <p>1 Job post</p>
                                                        <p>20 Applications</p>
                                                        <p>Featured job</p>
                                                        <p>10 Days validity</p>
                                                    </div>
                                                    <div className='col-xxl-6 col-6'>
                                                        <p>1 Job post</p>
                                                        <p>20 Applications</p>
                                                        <p>Featured job</p>
                                                        <p>10 Days validity</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='renew-btn-div'>
                                            <Link to="/job-portal/employer/all-packages"><button className='renew-btn'>Renew Plan</button></Link>
                                        </div>
                                        </div>
                                        )} */}
                                        <div className='main-inner-card'>
                                            <div className='header-card-heading'>
                                                <h5>{data[0]?.name}</h5>
                                                <p>{data[0]?.price}</p>
                                            </div>
                                            <div className='package-descripn'>
                                                <div className='row'>
                                                    <div className='col-xxl-6 col-6'>
                                                        <p>{data[0]?.job_post} Job post</p>
                                                        <p>{data[0]?.application} Applications</p>
                                                        <p>{data[0]?.featured_jobs} Featured job</p>
                                                        <p>{data[0]?.days_validity} Days validity</p>
                                                    </div>
                                                    {/* <div className='col-xxl-6 col-6'>
                                                        <p>{data[1]?.job_post} Job post</p>
                                                        <p>{data[1]?.application} Applications</p>
                                                        <p>{data[1]?.featured_jobs} Featured job</p>
                                                        <p>{data[1]?.days_validity} Days validity</p>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className='renew-btn-div'>
                                                <Link to="/job-portal/employer/all-packages"><button className='renew-btn'>Renew Plan</button></Link>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4  col-sm-4 col-12'>
                                    <div className='small-package-card'>
                                        {data?.map((packageItem, index) => (
                                            <div className='mini-card' key={index}>
                                                <h6>{packageItem?.name}</h6>
                                                <p>{packageItem?.price}</p>
                                            </div>
                                        ))}
                                        <div>
                                            <Link to="/job-portal/employer/all-packages"><button className='more-btn'>See More Plans</button></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section></>
    )
}

export default EmployerPackage