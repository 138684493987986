import React from "react";
import { Button } from "react-bootstrap";
import "./Opportunities.css";

function Opportunities() {
  return (
    <section className="opportunity">
      <div className="container">
        <div className="content-holder">
          <div className="col-md-2">
            <div className="heading-holder">
              <h6>Opportunities</h6>
              <h1>OPEN</h1>
            </div>
          </div>

          <div className="col-md-4">
            <div className="text-holder">
              <p>Explore New Opportunities in Your Desired Field</p>
            </div>
          </div>

          <div className="col-md-2 ">
            <div className="apply-btn-holder">
              <Button className="apply_now_btn">Apply Now</Button>
            </div>
          </div>

          <div className="col-md-3 ">
            <div className="img-holder">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/Job-portal/Images/job/IT/Opportunity_img_1.png"
                }
                className="opportunity_img"
                alt="..."
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Opportunities;
