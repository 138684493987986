import React, { useState } from "react";
import { Button } from "react-bootstrap";
import JPEmployerSignInForm from "./JP-Employer-Sign-In-Form/JPEmployerSignInForm";
import JPJobSeekerSignInForm from "./JP_JobSeeker_sign_in_form/JPJobSeekerSignInForm";

function JPSignInForm() {
  const [showEmpoyerSignIn, setShowEmpoyerSignIn] = useState(1);

  const handleJobSeekerClick = () => {
    if(showEmpoyerSignIn == 1){      
      setShowEmpoyerSignIn(2);
      }else{
        setShowEmpoyerSignIn(1);
      }
  };

  return (
    <>
      <div className="form-container">
        <div className="tabs-btn">
          <div className="">
            <div className="apply-btn-holder ">
              <Button
                className={`apply_now_btn tabs-btn ${showEmpoyerSignIn == 1  ? "active" : ""
                  }`}
                onClick={handleJobSeekerClick}
              >
                Jobseeker
              </Button>
            </div>
          </div>
          <div className="">
            <div className="apply-btn-holder">
              <Button
                className={`apply_now_btn tabs-btn ${showEmpoyerSignIn == 2 ? "active" : ""
                  }`}
                onClick={handleJobSeekerClick}
              >
                Employer
              </Button>
            </div>
          </div>
        </div>

        <div>
          {showEmpoyerSignIn == 1? (
            <JPJobSeekerSignInForm showEmpoyerSignIn={showEmpoyerSignIn} />
          ) : (
            <JPEmployerSignInForm showEmpoyerSignIn={showEmpoyerSignIn} />
          )}
        </div>
      </div>
    </>
  );
}

export default JPSignInForm;
