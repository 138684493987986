import { useState, useEffect } from "react";
import "./EducationalDetaildStepModal.css";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import PostGraduation from "./Educational-Details-Tab-Forms/PostGraduation/PostGraduation";
import HigherEducation from "./Educational-Details-Tab-Forms/HigherEducation/HigherEducation";
import { Button } from "react-bootstrap";
import Graduation from "./Educational-Details-Tab-Forms/Graduation/Graduation";
import SSC from "./Educational-Details-Tab-Forms/SSC/SSC";
import HSC from "./Educational-Details-Tab-Forms/HSC/HSC";
import Certifications from "./Educational-Details-Tab-Forms/Certifications/Certifications";

function EducationalDetaildStepModal({ setPage, jobSeekarDetailsID, onHide }) {

  const [activeTab, setActiveTab] = useState("Higher_Education"); // State to keep track of active tab

  const [EduID, setEduId] = useState(1);


  const handleSaveAndNext = () => {
    // Find the index of the current active tab
    const currentIndex = navItems.findIndex((item) => item.eventKey === activeTab);
    // If currentIndex is not the last index, move to the next tab
    if (currentIndex < navItems.length - 1) {
      const nextTab = navItems[currentIndex + 1].eventKey;
      const ID = currentIndex + 2;
      setEduId(ID)
      setActiveTab(nextTab);
    }
  };

  const handleBack = () => {
    // Find the index of the current active tab
    const currentIndex = navItems.findIndex((item) => item.eventKey === activeTab);
    // If currentIndex is not the first index, move to the previous tab
    if (currentIndex > 0) {
      const prevTab = navItems[currentIndex - 1].eventKey;
      setActiveTab(prevTab);
      const ID = currentIndex;
      setEduId(ID)
    }
  };



  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const navItems = [
    { eventKey: "Higher_Education", label: "Higher Education" },
    { eventKey: "Post_Graduation", label: "Post Graduation" },
    { eventKey: "Graduation", label: "Graduation" },
    { eventKey: "HSC", label: "HSC" },
    { eventKey: "SSC", label: "SSC" },
    { eventKey: "Certifications", label: "Certifications" },
  ];

  const isFirstTab = activeTab === navItems[0].eventKey;
  const isLastTab = activeTab === navItems[navItems.length - 1].eventKey;



  return (
    <>
      <div className="personal-details-form ">
        <div className="heading-holder">
          <div className="row me-0 ms-0">
            <div className="col-md-4">
              {/* Conditionally render Back button only if it's not the first tab */}

              <div className="back back-next-btn-holder" onClick={onHide}>
                <span>Cancel</span>
              </div>

            </div>

            <div className="col-md-4">
              <h6>Educational details</h6>
            </div>

            <div className="col-md-4">
              {/* Conditionally render Save & Next button only if it's not the last tab */}
              {/* {!isLastTab && (
                <div className="next back-next-btn-holder" onClick={handleSaveAndNext}>
                  <span>Save & Next</span>
                </div>
              )} */}
            </div>
          </div>
        </div>
        {/* form-holder */}
        <div className="">
          <div className="form-container">
            <div className="tab-form-container">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="Higher_Education"
                activeKey={activeTab} // Set active tab
              >
                <div className="row">
                  <div className="col-md-12  ">
                    <div className="nav-tab-holder">
                      <Nav variant="pills">
                        <Swiper
                          modules={[Navigation, Autoplay]}
                          pagination={false}
                          navigation={false}
                          breakpoints={{
                            320: {
                              slidesPerView: 2,
                            },
                            768: {
                              slidesPerView: 3,
                            },
                            992: {
                              slidesPerView: 5,
                            },
                            1024: {
                              slidesPerView: 5,
                            },
                            1600: {
                              slidesPerView: 5,
                            },
                          }}
                          className="suportres-slider"
                        >
                          {navItems.map((item) => (
                            <SwiperSlide className="swiper-slide1">
                              <Nav.Item key={item.eventKey}>
                                <Nav.Link eventKey={item.eventKey}>
                                  {item.label}
                                </Nav.Link>
                              </Nav.Item>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </Nav>
                    </div>
                  </div>
                </div>

                <Tab.Content className="details-form-holder">
                  {navItems.map((item) => (
                    <Tab.Pane eventKey={item.eventKey} key={item.eventKey}>
                      {item.eventKey === "Higher_Education" &&
                        <HigherEducation jobSeekarDetailsID={jobSeekarDetailsID} EduID={EduID} onHide={onHide}
                          handleSaveAndNext={handleSaveAndNext} activeTab={activeTab} navItems={navItems} />}


                      {item.eventKey === "Post_Graduation" &&
                        <PostGraduation jobSeekarDetailsID={jobSeekarDetailsID} EduID={EduID} onHide={onHide}
                          handleSaveAndNext={handleSaveAndNext} activeTab={activeTab} navItems={navItems} handleBack={handleBack} />}


                      {item.eventKey === "Graduation" &&
                        <Graduation jobSeekarDetailsID={jobSeekarDetailsID} EduID={EduID} onHide={onHide}
                          handleSaveAndNext={handleSaveAndNext} activeTab={activeTab} navItems={navItems} handleBack={handleBack} />}


                      {item.eventKey === "HSC" &&
                        <HSC jobSeekarDetailsID={jobSeekarDetailsID} EduID={EduID} onHide={onHide}
                          handleSaveAndNext={handleSaveAndNext} activeTab={activeTab} navItems={navItems} handleBack={handleBack} />}


                      {item.eventKey === "SSC" &&
                        <SSC jobSeekarDetailsID={jobSeekarDetailsID} EduID={EduID} onHide={onHide}
                          handleSaveAndNext={handleSaveAndNext} activeTab={activeTab} navItems={navItems} handleBack={handleBack} />}


                      {item.eventKey === "Certifications" &&
                        <Certifications jobSeekarDetailsID={jobSeekarDetailsID} EduID={EduID} onHide={onHide}
                          handleSaveAndNext={handleSaveAndNext} activeTab={activeTab} navItems={navItems} handleBack={handleBack} />}

                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>






            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EducationalDetaildStepModal;