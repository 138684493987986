
import './FaboutCard.css'
import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import parse from 'html-react-parser'
import AOS from "aos";
import "aos/dist/aos.css";


const FaboutCard = () => {
    const [modalShow, setModalShow] = useState(false);
    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/finance/about-us/f-card-first`);
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data);


    useEffect(() => {
        getDataAll();
    }, []);

    useEffect(() => {
        AOS.init({ once: true });
    }, [])

    return (
        <>
            {data && data?.length > 0 &&
                <section className='fabout-card'>

                    <div className='container' data-aos="fade-up" data-aos-duration="3000">
                        <div className='row ' >
                            <Swiper
                                spaceBetween={30}
                                pagination={{
                                    clickable: true,

                                }}
                                modules={[Pagination]}
                                className="mySwiper"
                                breakpoints={{
                                    576: { slidesPerView: 1 },
                                    768: { slidesPerView: 2 },
                                    1024: { slidesPerView: 2 },
                                    1200: { slidesPerView: 2 },
                                }}
                            >

                                <div className=' col-md-6 col-12'>
                                    {data?.map((image, index) => (
                                        <SwiperSlide>
                                            <div className='f-main-card mb-5 mb-md-0'>
                                                <img
                                                    className="fcard-img"
                                                    // src={
                                                    //     process.env.PUBLIC_URL +
                                                    //     "/assets/Finance/Image/about/card2.png"
                                                    // }
                                                    src={IMG_URL + image?.image}
                                                    alt="Banner"
                                                />

                                                <div className='overlay-title'>
                                                    <h5>{image?.name}</h5>
                                                </div>
                                                <div className='overlay-descr'>
                                                    <p className='hoverdescrptn'>{parse(image?.description) || ""}</p>
                                                </div>
                                                <div className='overlay-color' />


                                            </div>
                                        </SwiperSlide>
                                    ))}
                                </div>

                            </Swiper>
                        </div>
                    </div>


                </section>
            }
        </>
    )
}

export default FaboutCard