import { useState, useEffect } from "react";
import "./Stepthree.css";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import "swiper/swiper-bundle.css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import PostGraduation from "./Educational-Details-Tab-Forms/PostGraduation/PostGraduation";
import HigherEducation from "./Educational-Details-Tab-Forms/HigherEducation/HigherEducation";
import { Button } from "react-bootstrap";
import Graduation from "./Educational-Details-Tab-Forms/Graduation/Graduation";
import SSC from "./Educational-Details-Tab-Forms/SSC/SSC";
import HSC from "./Educational-Details-Tab-Forms/HSC/HSC";
import Certifications from "./Educational-Details-Tab-Forms/Certifications/Certifications";
import { Link } from "react-router-dom";
import { getEducation } from "../../../../../../utils/apis/jobseekar/jobseekar";
import { useForm, Controller, SubmitHandler, useFieldArray, } from "react-hook-form";


function Stepthree({ setPage, onPrev, onBack, jobSeekarDetails }) {

  const [EduID, setEduId] = useState(1);
  const handleSaveAndNext = () => {
    // Find the index of the current active tab
    const currentIndex = navItems.findIndex(
      (item) => item.eventKey === activeTab
    );
    // If currentIndex is not the last index, move to the next tab
    if (currentIndex < navItems.length - 1) {
      const nextTab = navItems[currentIndex + 1].eventKey;
      const ID = currentIndex + 2;
      setActiveTab(nextTab);
      setEduId(ID)
    } else {
      // Redirect to step 4 when the last tab is active
      setPage(3);
    }
  };

  // Function to handle clicking on the "Back" button
  const handleBack = () => {
    const currentIndex = navItems.findIndex(
      (item) => item.eventKey === activeTab
    );
    if (currentIndex > 0) {
      const previousTab = navItems[currentIndex - 1].eventKey;
      const ID = currentIndex;
      setActiveTab(previousTab);
      setEduId(ID)
    }
  };

  const [activeTab, setActiveTab] = useState("Higher_Education"); // State to keep track of active tab

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [userID, setUserId] = useState();
  useEffect(() => {
    setUserId(jobSeekarDetails?.personal_details_id)
  }, [])
  const navItems = [
    { eventKey: "Higher_Education", label: "Higher Education" },
    { eventKey: "Post_Graduation", label: "Post Graduation" },
    { eventKey: "Graduation", label: "Graduation" },
    { eventKey: "HSC", label: "HSC" },
    { eventKey: "SSC", label: "SSC" },
    { eventKey: "Certifications", label: "Certifications" },
  ];

  return (
    <>
      <div className="personal-details-form ">
        {/* form-holder */}
        <div className="">
          <div className="form-container">
            <div className="tab-form-container">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="Higher_Education"
                activeKey={activeTab} // Set active tab
              >
                <div className="row">
                  <div className="col-md-12  ">
                    <div className="nav-tab-holder">
                      <Nav variant="pills">
                        <Swiper
                          modules={[Navigation, Autoplay]}
                          pagination={false}
                          navigation={false}
                          breakpoints={{
                            320: {
                              slidesPerView: 2,
                            },
                            768: {
                              slidesPerView: 3,
                            },
                            992: {
                              slidesPerView: 5,
                            },
                            1024: {
                              slidesPerView: 5,
                            },
                            1600: {
                              slidesPerView: 5,
                            },
                          }}
                          className="suportres-slider"
                        >
                          {navItems.map((item) => (
                            <SwiperSlide className="swiper-slide1">
                              <Nav.Item key={item.eventKey}>
                                <Nav.Link eventKey={item.eventKey}>
                                  {item.label}
                                </Nav.Link>
                              </Nav.Item>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </Nav>
                    </div>
                  </div>
                </div>

                <Tab.Content className="details-form-holder">
                  {navItems.map((item) => (
                    <Tab.Pane eventKey={item.eventKey} key={item.eventKey}>
                      {item.eventKey === "Higher_Education" && (
                        <HigherEducation
                          activeTab={activeTab} navItems={navItems} userID={userID} EduID={EduID}
                          handleBack={handleBack} handleSaveAndNext={handleSaveAndNext} />
                      )}


                      {item.eventKey === "Post_Graduation" && (
                        <PostGraduation
                          activeTab={activeTab} navItems={navItems} userID={userID} EduID={EduID}
                          handleBack={handleBack} handleSaveAndNext={handleSaveAndNext} />
                      )}


                      {item.eventKey === "Graduation" && (
                        <Graduation
                          activeTab={activeTab} navItems={navItems} userID={userID} EduID={EduID}
                          handleBack={handleBack} handleSaveAndNext={handleSaveAndNext} />
                      )}


                      {item.eventKey === "HSC" && (
                        <HSC
                          activeTab={activeTab} navItems={navItems} userID={userID} EduID={EduID}
                          handleBack={handleBack} handleSaveAndNext={handleSaveAndNext} />
                      )}


                      {item.eventKey === "SSC" && (
                        <SSC
                          activeTab={activeTab} navItems={navItems} userID={userID} EduID={EduID}
                          handleBack={handleBack} handleSaveAndNext={handleSaveAndNext} />
                      )}


                      {item.eventKey === "Certifications" && (
                        <Certifications
                          activeTab={activeTab} navItems={navItems} userID={userID} EduID={EduID}
                          handleBack={handleBack} handleSaveAndNext={handleSaveAndNext} />
                      )}
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Tab.Container>

              {/* <div className="education-step-save-and-next">
                <div
                  className="apply-btn-holder"
                  style={{ textAlign: "center" }}
                >
                  {activeTab !== navItems[0].eventKey && (
                    <Button className="apply_now_btn mx-2" onClick={handleBack}>
                      Back
                    </Button>
                  )}
                  <Button className="apply_now_btn mx-2" onClick={handleSaveAndNext}>
                    Save & Next
                  </Button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Stepthree;