import React, { useState, useEffect, useContext } from "react";
import CommonModal from '../../CommonModal/CommonModal';
import "./../../JP-Jobseeker-Step_modal/JobseekerStepModal.css";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import { allSkills } from "../../../../../../utils/apis/common/Common";
import { getSkills, skillsPost } from "../../../../../../utils/apis/jobseekar/jobseekar";
import { Context } from "../../../../../../utils/context";
import { useNavigate } from 'react-router-dom';

library.add(fas);

const SkillModal = ({ show, onHide, props, jobSeekarDetailsID }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleCloseButtonClick = (optionValue) => {
    const updatedOptions = selectedOptions.filter(
      (option) => option.value !== optionValue
    );
    setSelectedOptions(updatedOptions);
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedOptions([...selectedOptions, selectedOption]);
  };



  const handleSuggestedSkillClick = (label) => {
    const newOption = { value: label, label: label };
    setSelectedOptions([...selectedOptions, newOption]);
  };

  const options = [
    { value: " XD", label: " XD" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const { postData, getData, Select2Data, IMG_URL } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();

  const [skillsData, setSkillsData] = useState([]);
  const getSkillsMaster = async () => {
    const res = await allSkills();
    if (res?.success) {
      setSkillsData(await Select2Data(res?.data, "skill_id"));
    }
  };

  const onSkillsSubmit = async (data) => {
    data.personal_details_id = jobSeekarDetailsID;
    const res = await skillsPost(data);
    if (res?.success) {
      // navigate("/job-portal/jobseeker/jobseeker-edit-profile");
      onHide();
    }
  }


  const getsSkills = async () => {
    const res = await getSkills(jobSeekarDetailsID);
    if (res?.success) {
      const skillsDetails = res?.data;
      if (skillsDetails?.length > 0) {
        var skills_id = [];
        skillsDetails?.forEach((skills, index) => {
          skills_id.push({ value: skills?.skill_id, label: skills?.skill_type?.name });
          setValue('skill_id', skills_id);
        });
      }
    }
  };


  useEffect(() => {
    getsSkills();
    getSkillsMaster();
  }, [jobSeekarDetailsID])

  return (
    <CommonModal show={show} onHide={onHide} >
      <Modal.Body>
        <div className="heading-holder">
          <h6>Skill Sets</h6>
        </div>
        <div className="personal-details-form ">
          {/* form-holder */}
          <Form onSubmit={handleSubmit(onSkillsSubmit)}>
            <div className="details-form-holder">
              <div className="form-container">
                {/*  Tell recruiters */}
                <div className="">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="Tell_recruiters">
                        <h1>
                          Tell recruiters what you know or what you are known
                          for e.g. Direct Marketing, Oracle, Java etc. We will
                          send you job recommendations based on these skills.
                          Each skill is separated by a comma.
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Skills */}
                <div className="">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="">
                        <Form.Group controlId="Skills">
                          <Form.Label className="">Skills</Form.Label>

                          <div>
                            <Controller
                              name="skill_id"
                              {...register("skill_id", {
                                required: "Skills is required",
                              })}
                              control={control}
                              render={({ field }) => (
                                <Select
                                  isMulti
                                  styles={{
                                    control: (baseStyles) => ({
                                      ...baseStyles,
                                      borderColor: errors.skill_id ? "red" : baseStyles,
                                    }),
                                  }}
                                  {...field}
                                  options={skillsData}
                                />
                              )}
                            />
                          </div>
                          <div>
                          </div>
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>

                <div></div>
                {/* close save btn */}
                <div className="close-save-btn-holder form-field">
                  <div className="Close-btn-holder">
                    <Button className="close-btn" onClick={onHide}>Cancel</Button>
                  </div>

                  <div className="apply-btn-holder">
                    {/* <Link to={"/job-portal/jobseeker/jobseeker-edit-profile"}> */}
                    <Button className="apply_now_btn" type="submit">Save & Close</Button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </Modal.Body>
    </CommonModal>
  );
}

export default SkillModal;