import React from 'react'
import Lottie from 'react-lottie';
import "../Animation/Animation.css"
import * as animationData from '../Animation/Success.json';
function Animation() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <section className='success-lottie'>
                <div className='lottie-holder'>
                    <Lottie className=""
                        options={defaultOptions}
                        
                    />
                </div>
            </section>
        </>
    )
}

export default Animation