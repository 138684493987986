import React from "react";
import { Route, Routes } from "react-router-dom";
import Jphome from "../homepage/Jphome";
import JPBlog from "../JP-Blog/JPBlog";
import JPBlogInnerpage from "../JP-Blog/JPblog-innerpage/JPBlogInnerpage";
import JPjobs from "../JPjobs/JPjobs"
import JPcompanies from "../JP-Companies/JPcompanies";
import JpHelpCenter from "../Jp-Help-center/JpHelpCenter";
import JPFewClickAway from "../JP-Few_click_away_stepform/JPFewClickAway";
import JPSearch from "../JP-Search/JPSearch";
import JpJobDescription from "../JP-Search/JP-Job-description/JpJobDescription";
import JpPopularCompanyJob from "../JP-Companies/JPPopularCompanies/JPPopularCompaniesTabContent/JP_Popular_Company_job/JpPopularCompanyJob";
import JpHomeRegister from "../JP-Home-Register/JpHomeRegister";
import JpDashboard from "../Jp-Employer/JpDashboard";
import JpEmpCompanyProfile from "../Jp-Employer/Jp-Employer-Edit-Profile/JpEmpCompanyProfile/JpEmpCompanyProfile";
import EmpViewJobDetails from "../Jp-Employer/EmpViewJobDetails/EmpViewJobDetails";
import EmpEditJobDetails from "../Jp-Employer/EmpEditJobDetails/EmpEditJobDetails";
import Jp_browse_cat_inner from "../homepage/jpbrowse-category/Jp_browse_cat_inner/Jp_browse_cat_inner";
import JpjobseekerTerms from "../Jp-Jobseeker/JpjobseekerTerms/JpjobseekerTerms";
import JpEmpTerms from "../Jp-Employer/JpEmpTerms/JpEmpTerms";
import Jp_employers_posted_jobs from "../Jp-Employer/Jp-employer-posted-jobs/Jp_employers_posted_jobs";
import Jp_empolyer_notification from "../Jp-Employer/Jp-employer_notification/Jp_empolyer_notification";
import Jobseeker_notification from "../Jp-Jobseeker/jobseeker_notification/Jobseeker_notification";
import JpEmpContactBody from "../Jp-Employer/JpEmpContactForm/JpEmpContactBody";
import EmpFormConform from "../Jp-Employer/EmpStepForm/EmpFormConform/EmpFormConform";
import PostJob from "../Jp-Employer/PostJob/PostJob";
import EmpStepForm from "../Jp-Employer/EmpStepForm/EmpStepForm";
import EmployerPackage from "../Jp-Employer/EmployerProfile/EmployerPackage/EmployerPackage";
import JPSignIn from "../JP-Sign-In/JPSignIn";
import JPContactForm from "../JP-contact/JPContactForm";
import EmployerAllPackages from "../Jp-Employer/EmployerProfile/EmployerAllPackages/EmployerAllPackages";
import EmployerMyPackages from "../Jp-Employer/EmployerProfile/EmployerMyPackages/EmployerMyPackages";
import JpEmpUpdateprofile from "../Jp-Employer/Jp-Employer-Edit-Profile/JpEmpUpdateprofile/JpEmpUpdateprofile";
import EmpSetting from "../Jp-Employer/EmpSetting/EmpSetting";
import JpPrivacy from "../Jp-Privacy/JpPrivacy";
import JpEmpPrivacy from "../Jp-Employer/JpEmpPrivacy/JpEmpPrivacy";
import JpForgetPass from "../JP-Sign-In/JpForgetPass/JpForgetPass";
import Jp_jobseeker_dashboard from "../Jp-Jobseeker/Jp-dashboard/Jp_jobseeker_dashboard";
import JpMyprofile from "../Jp-Jobseeker/JpMyprofile/JpMyprofile";
import JpJobSeekerEditProfile from "../Jp-Jobseeker/JP-Jobseeker-Edit-Profile-StepForm/JpJobSeekerEditProfile";
import JobseekersSetting from "../Jp-Jobseeker/jobseekerSetting/JobseekerSetting";
import ApplicantTab from "../Jp-Employer/MainDashboard/ApplicantTab/ApplicantTab";
import ReviewJob from "../Jp-Employer/EmpStepForm/Reviewjob/ReviewJob";
import EmpPayment from "../Jp-Employer/EmpStepForm/EmpPayment/EmpPayment";
import EmpPaymentConformation from "../Jp-Employer/EmpStepForm/EmpFormConform/paymentConform";

const JPRoutesMain = () => {
  return (
    <Routes>
      <Route path="/home" element={<Jphome />} />
      <Route path="/blog" element={<JPBlog />} />
      <Route path="/blog/innerpage" element={<JPBlogInnerpage />} />
      <Route path="/job" element={<JPjobs />} />
      <Route path="/company" element={<JPcompanies />} />
      <Route path="/helpcenter" element={<JpHelpCenter />} />
      <Route path="/few-click-away-stepform/:id" element={<JPFewClickAway />} />
      <Route path="/search/all-jobs-search" element={<JPSearch />} />
      <Route path="/search/job-description/:id" element={<JpJobDescription />} />
      <Route path="/company/Company-Job/:id" element={<JpPopularCompanyJob />} />
      <Route path="/register" element={<JpHomeRegister />} />
      <Route path="/employer/dashboard" element={<JpDashboard />} />
      <Route path="/employer/notification_inner" element={<ApplicantTab />} />

      <Route path="/employer/companyprofile" element={<JpEmpCompanyProfile />} />
      <Route path="/employer/jobdetails" element={<EmpViewJobDetails />} />
      <Route path="/employer/editjobdetails/:id" element={<EmpEditJobDetails />} />
      <Route path="/employer/jobdetails/:id" element={<EmpViewJobDetails />} />
      {/* <Route path="/employer/editjobdetails/:id" element={<EmpEditJobDetails />} /> */}
      <Route path="/employer/editjobdetails" element={<EmpEditJobDetails />} />
      <Route
        path="/employer/companyprofileupdate/:id"
        element={<JpEmpUpdateprofile />}
      />
      <Route
        path="/employer/companyprofileupdate"
        element={<JpEmpUpdateprofile />}
      />
      <Route path="/employer/my-packages" element={<EmployerMyPackages />} />
      <Route path="/employer/all-packages/:id" element={<EmployerAllPackages />} />
      <Route path="/employer/all-packages/:package_id" element={<EmployerAllPackages />} />
      <Route path="/employer/all-packages" element={<EmployerAllPackages />} />
      <Route path="/contact" element={<JPContactForm />} />
      <Route path="/sign-in" element={<JPSignIn />} />
      <Route path="/jobseeker/privacypolicy" element={<JpPrivacy />} />
      <Route path="/employer/privacypolicy" element={<JpEmpPrivacy />} />
      <Route path="/jpforget" element={<JpForgetPass />} />
      <Route path="/jobseeker/dashboard" element={<Jp_jobseeker_dashboard />} />
      <Route path="/jobseeker/myprofile" element={<JpMyprofile />} />
      <Route
        path="/jobseeker/jobseeker-edit-profile"
        element={<JpJobSeekerEditProfile />}
      />

      <Route path="/employer/setting" element={<EmpSetting />} />
      <Route path="/jobseeker/setting" element={<JobseekersSetting />} />

      <Route path="/employer/job-portal/employer/all-packages" element={<EmployerPackage />} />
      <Route path="/employer/stepform" element={<EmpStepForm />} />
      <Route path="/employer/stepform/:id" element={<EmpStepForm />} />

      <Route path="/employer/postjob" element={<PostJob />} />
      {/* <Route path="/employer/conformation" element={<EmpFormConform />} /> */}
      <Route path="/employer/conformation/:id" element={<EmpFormConform />} />
      <Route path="/employer/payment-conformation/" element={<EmpPaymentConformation />} />
      <Route path="/employer/contact" element={<JpEmpContactBody />} />
      <Route path="/employer/reviewjob/:id" element={<ReviewJob />} />

      <Route
        path="/jobseeker/notification"
        element={<Jobseeker_notification />}
      />

      <Route
        path="/employer/notification"
        element={<Jp_empolyer_notification />}
      />

      <Route
        path="/employer/posted-job"
        element={<Jp_employers_posted_jobs />}
      />
      <Route path="/employer/terms&condition" element={<JpEmpTerms />} />
      <Route path="/jobseeker/terms&condition" element={<JpjobseekerTerms />} />
      <Route path="/employer/emp-payment/:id" element={<EmpPayment />} />
      {/* <Route path="/employer/emp-payment" element={<EmpPayment />} /> */}

      <Route
        path="/jobseeker/browse-category/:id" element={<Jp_browse_cat_inner />}
      />
    </Routes>
  );
};

export default JPRoutesMain;
