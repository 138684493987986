import { React, useRef, useEffect, useState, useContext } from "react";
import { Form } from "react-bootstrap";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Context } from "../../../../../../../utils/context";
import classNames from "classnames";

function StepTenGetBestOffers({ setName, setEmail, loanFormData }) {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset
  } = useForm();
  const [pincode, setPincode] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    reset(loanFormData);
    console.log("from step 10 :- ", loanFormData);
  }, [loanFormData]);


  const fetchEmploymentTypes = async () => {
    try {
      const response = await getData("/without-login/master/all-employment-type");
      if (response.success) {
        // Extracting only the "name" property from each object
        const names = response.data?.map(employmentType => employmentType.name);
        setEmploymentTypes(names);
      } else {
        // Handle error when response is not successful
        console.error('Error fetching employment types:', response.error);
      }
    } catch (error) {
      // Handle fetch error
      console.error('Error fetching employment types:', error);
    }
  };



  useEffect(() => {

    fetchEmploymentTypes();
  }, []);

  const onSubmit = async (d) => {
    //alert('ss');
    try {
      console.log(d);
      // d.pincode_id = d.pincode_id?.value;

      const res = await postData(`/finance/forms/loan-form`, d);
      if (res?.success) {
        console.log(res, 'submit');
        setModalShow(true);

        setTimeout(() => {
          window.location.reload();
          setModalShow(false);
        }, 2000)
      }
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      <div className="StepTenGetBestOffers ">
        <Form className="StepTenGetBestOffers-form" onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Full Name</Form.Label>
            <Form.Control type="text" placeholder="Enter full name" name="name"
              {...register("name", {
                required: "Name is required",
              })}
              className={classNames("", {
                "is-invalid": errors?.name,
              })}
              onKeyDown={(event) => {
                if (!/[A-Z-a-z ]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => {
                setName(e.target.value)
              }}
            />
            {errors?.name && (
              <span className="text text-danger">
                {errors.name.message}
              </span>
            )}
          </Form.Group>


          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email Address</Form.Label>
            <Form.Control type="text" name="email" placeholder="Enter Email"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                  message: "Invalid email address",
                },
              })}
              className={classNames("", {
                "is-invalid": errors?.email,
              })}
              onChange={(e) => {
                setEmail(e.target.value)
              }}

            />
            {errors?.email && (
              <span className="text text-danger">
                {errors.email.message}
              </span>
            )}
          </Form.Group>
        </Form>
      </div>
    </>
  );
}

export default StepTenGetBestOffers;
