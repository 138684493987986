import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./FHomeBanner.css";
import { Link } from "react-router-dom";
import { React, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import FEnquiryModal from "../F-Enquirymodal/FEnquiryModal";
import { ShimmerButton, ShimmerSectionHeader, ShimmerThumbnail } from "react-shimmer-effects";
import { Col, Container, Row } from "react-bootstrap";

const FHomeBanner = () => {
  const [value1, setValue1] = useState(250000);
  const [value2, setValue2] = useState(6);
  const [data, setData] = useState([]);
  const [introData, setIntroData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);

  const { getData, IMG_URL } = useContext(Context);

  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  useEffect(() => {
    const loadTimer = setTimeout(() => {
      getDataAll();
      getIntroDataAll();
    }, 2000);

    return () => clearTimeout(loadTimer);
  }, []);

  const handleChange1 = (event) => {
    setValue1(event.target.value);
  };

  const handleChange2 = (event) => {
    setValue2(event.target.value);
  };

  const percentageComplete1 = (value1 / 500000) * 96;
  const percentageComplete2 = (value2 / 12) * 96;

  const calculateMonthlyEMI = (principal, months, annualInterestRate) => {
    const monthlyInterestRate = annualInterestRate / 12 / 100;
    return (
      (principal * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, months)) /
      (Math.pow(1 + monthlyInterestRate, months) - 1)
    );
  };

  const monthly = calculateMonthlyEMI(value1, value2, 11.15);
  const totalPayBack = monthly * value2;

  const getDataAll = async () => {
    try {
      const response = await getData(`/finance/home/f-banner`);
      setData(response?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const getIntroDataAll = async () => {
    try {
      const response = await getData(`/finance/about-us/f-who-we-are`);
      setIntroData(response?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  

  return (
    <>
      {loading ? (
        <Container>
          <Row>
            <Col md={7} sm={12} xs={12} className="mt-5">
              <ShimmerSectionHeader />
              <ShimmerButton size="md" />
            </Col>
            <Col md={5} sm={12} xs={12}>
              <ShimmerThumbnail height={450} rounded />;
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          {data && data?.length > 0 &&
            <section className="finance-home-banner">
              <div className="fhome-content">
                <div className="container">
                  <div className="row">
                    {introData?.map((image, index) => (
                      <div key={index} className="col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-12">
                        <div className="left-sec">
                          <p className="sub-heading">Company Introductions</p>
                          <>
                            <h4 className="heading">{image?.name}</h4>
                            <p className="descrptn">
                              {htmlToReactParser.parse(image?.description) || ""}
                            </p>
                          </>
                          <Link to="/finance/aboutus">
                            <button className="readmore-btn">
                              Read More
                              <FontAwesomeIcon
                                icon="fa-solid fa-angles-right"
                                className="arrow-icon "
                              />
                            </button>
                          </Link>
                        </div>
                      </div>
                    ))}
                    <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12">
                      <div className="right-sec">
                        <div className="main-card">
                          <div className="much-need-div">
                            <h3>How Much You Need</h3>
                            <div className="triangle"></div>
                          </div>
                          <div className="range-div">
                            <div className="price-div">
                              <p>₹10K</p> <p>₹50L</p>
                            </div>
                            <div className="range-bar-container">
                              <p
                                className="range-value"
                                style={{
                                  left: `calc(${percentageComplete1}% - 12px)`,
                                  fontSize: '14px'
                                }}
                              >
                                {value1}
                              </p>
                              <input
                                type="range"
                                min="10000"
                                max="500000"
                                step={10000}
                                value={value1}
                                onChange={handleChange1}
                                className="range-bar"
                              />
                              <div
                                className="range-bar-color"
                                style={{
                                  width: `${percentageComplete1}%`,
                                }}
                              ></div>
                            </div>
                            <div className="price-div mt-4">
                              <p>1 Month</p> <p>12 Month</p>
                            </div>
                            <div className="range-bar-container">
                              <p
                                className="range-value"
                                style={{
                                  left: `calc(${percentageComplete2}% - 2px)`,
                                  fontSize: '14px'
                                }}
                              >
                                {value2}
                              </p>
                              <input
                                type="range"
                                min="0"
                                max="12"
                                value={value2}
                                onChange={handleChange2}
                                className="range-bar"
                              />
                              <div
                                className="range-bar-color"
                                style={{
                                  width: `${percentageComplete2}%`,
                                }}
                              ></div>
                            </div>
                            <div className="price-div mt-4">
                              <p>Pay Monthly</p> <p>₹{monthly.toFixed(2)}</p>
                            </div>
                            <div className="price-div mt-4">
                              <p>Terms of Use</p> <p>{value2} months</p>
                            </div>
                            <div className="price-div mt-4">
                              <p>Total Pay Back</p> <p>₹ {totalPayBack.toFixed(2)}</p>
                            </div>
                            <div className="text-center">
                              <button className="readmore-btn" onClick={() => setShowModal(true)}>
                                Apply For Loan
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          }
        </>
      )}

      <FEnquiryModal
        show={showModal}
        onHide={() => setShowModal(false)}
        setModalShow={setShowModal}
        modalShow={showModal}
      />

      <div></div>
    </>
  );
};

export default FHomeBanner;
