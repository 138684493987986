import React, { useState } from "react";
import "./OfferTableModal.css";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import ReactSpeedometer from "react-d3-speedometer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function OfferTableModal() {
  const segmentColors = [
    "#FAD808",
    "#FCBC04",
    "#22C269",
    "#0F9452",
    "#EE0033",
    "#C1001F",
  ];
  return (
    <>
      <div className="OfferTableModal">
        <div className="table-sec">
          <div className="row">
            <div className="col-lg-6 col-md-12">
              <div className="progress-bar-sec">
                <div className="text-holder slider-bar-text-holder">
                  <p>Eligible Loan Amount: Rs. 10 lacs</p>
                  <p>Requested Loan Amount: Rs. 7 Lacs</p>
                </div>
                <div className="rang-bar-slider">
                  <RangeSlider
                    className="single-thumb"
                    defaultValue={[0, 50]}
                    thumbsDisabled={[true, false]}
                    rangeSlideDisabled={true}
                  />
                  <div className="range-value-holder text-holder">
                    <p>Min: Rs. 0.1 lacs</p>
                    <p>Max: Rs. 10 lacs</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="progress-bar-sec">
                <div className="text-holder slider-bar-text-holder">
                  <p>Tenure: 1 years</p>
                </div>

                <div className="rang-bar-slider">
                  <RangeSlider
                    className="single-thumb"
                    defaultValue={[0, 50]}
                    thumbsDisabled={[true, false]}
                    rangeSlideDisabled={true}
                  />
                  <div className="range-value-holder text-holder">
                    <p>Min: ≤ 1 years</p>
                    <p>Max: 5 years</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="table-sec">
          <div className="row">
            <div className="col-md-12">
              <div className="progress-bar-sec">
                <div className="text-holder">
                  <p>Offers are sorted by Chances of Approval</p>
                  <p>Powered by Profcyma</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Curated-Offers">
          <div className="Curated-Offers-heading">
            <div className="logo-holder">
              <img
                className="logo-img"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/Finance/Image/F-service/Home_loan_stepform/discount.png"
                }
                alt="Kotak-img"
              />
            </div>
            <div className="discount-text-holder">
              <h1>Curated Offers</h1>
              <p>Sorted by Chances of Approval</p>
            </div>
          </div>

          <div className="table-sec">
            <div className="row">
              <div className="col-lg-1 p-0">
                <div className="progress-bar-sec border-right">
                  <div className="text-holder">
                    <p>Bank</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 p-0">
                <div className="progress-bar-sec border-right">
                  <div className="text-holder">
                    <p>Interest Rate</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 p-0">
                <div className="progress-bar-sec border-right">
                  <div className="text-holder">
                    <p>Processing fees</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 p-0">
                <div className="progress-bar-sec border-right">
                  <div className="text-holder">
                    <p>EMI</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 p-0">
                <div className="progress-bar-sec border-right">
                  <div className="text-holder">
                    <p>Prepayment charges</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 p-0">
                <div className="progress-bar-sec ">
                  <div className="text-holder">
                    <p >Your chances of Loan Approval</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="scrollable-discount-table">
            <div className="table-sec">
              <div className="row">
                <div className="col-lg-1 p-0">
                  <div className="progress-bar-sec border-right">
                    <div className="bank-logo">
                      <div className="logo-holder">
                        <img
                          className="logo-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Finance/Image/F-service/Home_loan_stepform/Axis.png"
                          }
                          alt="Axis-img"
                        />
                      </div>
                      <p>Axis</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 p-0">
                  <div className="progress-bar-sec border-right">
                    <div className="text-holder">
                      <p>11.75</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 p-0">
                  <div className="progress-bar-sec border-right">
                    <div className="text-holder">
                      <p>₹ 9,900</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 p-0">
                  <div className="progress-bar-sec border-right">
                    <div className="text-holder">
                      <p>₹ 26,211</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 p-0">
                  <div className="progress-bar-sec border-right">
                    <div className="text-holder">
                      <p>
                        1-12 Month- 5%, 13-24 Months- 4%, 25-36 Months- 3%, {">"} 37
                        Months- 2%
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 p-0">
                  <div className="progress-bar-sec ">
                    <div className="text-holder">
                      <div className="Speed">
                        <ReactSpeedometer
                          maxValue={500}
                          value={473}
                          width={150}
                          height={100}
                          needleColor="#FAD808"
                          startColor="orange"
                          segments={6}
                          endColor="green"
                          segmentColors={segmentColors}
                          customSegmentLabels={[
                            {
                              color: " #FAD808",
                            },
                            {
                              color: " #FCBC04",
                            },
                            {
                              color: " #22C269",
                            },
                            {
                              color: " #0F9452",
                            },
                            {
                              color: " #EE0033",
                            },
                            {
                              color: " #C1001F",
                            },
                          ]}
                          ringWidth={15}
                          needleHeightRatio={0.5} // Adjust this value to change the needle size
                        />

                        <div className="speedometer-point">
                          <FontAwesomeIcon
                            className="point-icon"
                            icon="fa-solid fa-circle"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="table-sec">
              <div className="row">
                <div className="col-lg-1 p-0">
                  <div className="progress-bar-sec border-right">
                    <div className="bank-logo">
                      <div className="logo-holder">
                        <img
                          className="logo-img"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Finance/Image/F-service/Home_loan_stepform/Kotak.png"
                          }
                          alt="Kotak-img"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 p-0">
                  <div className="progress-bar-sec border-right">
                    <div className="text-holder">
                      <p>11.75</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 p-0">
                  <div className="progress-bar-sec border-right">
                    <div className="text-holder">
                      <p>₹ 9,900</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 p-0">
                  <div className="progress-bar-sec border-right">
                    <div className="text-holder">
                      <p>₹ 26,211</p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 p-0">
                  <div className="progress-bar-sec border-right">
                    <div className="text-holder">
                      <p>
                        1-12 Month- 5%, 13-24 Months- 4%, 25-36 Months- 3%, {">"} 37
                        Months- 2%
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-lg-2 p-0">
                  <div className="progress-bar-sec ">
                    <div className="text-holder">
                      <div className="Speed">
                        <ReactSpeedometer
                          maxValue={500}
                          value={473}
                          width={150}
                          height={100}
                          needleColor="#FAD808"
                          startColor="orange"
                          segments={6}
                          endColor="green"
                          segmentColors={segmentColors}
                          customSegmentLabels={[
                            {
                              color: " #FAD808",
                            },
                            {
                              color: " #FCBC04",
                            },
                            {
                              color: " #22C269",
                            },
                            {
                              color: " #0F9452",
                            },
                            {
                              color: " #EE0033",
                            },
                            {
                              color: " #C1001F",
                            },
                          ]}
                          ringWidth={15}
                          needleHeightRatio={0.5} // Adjust this value to change the needle size
                        />
                        <div className="speedometer-point">
                          <FontAwesomeIcon
                            className="point-icon"
                            icon="fa-solid fa-circle"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OfferTableModal;
