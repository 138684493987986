import './FBlogCard.css'
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context"
import AOS from 'aos';
import 'aos/dist/aos.css';

const blogData = [
  {
    title: "Is College Worth the Expense? Yes, It Is",
    date: "20 NOV, 2022",
    imgSrc: "/assets/Finance/Image/Blog/1.png",
    text: "The policy question should not be weighed just by the issue of debt. There are other relevant arguments, some for, and some against, the question of expense. For example, is the cost of college rising by more than its affordability for the average student?",
  },
  {
    title: "Making Colleges More Affordable",
    date: "20 NOV, 2022",
    imgSrc: "/assets/Finance/Image/Blog/2.png",
    text: "Perhaps the most complex proposal is to tie funding to a college's performance as measured by “college value.” This is not simply a matter of identifying merit-based outcomes such as graduation rates and the transfer rate between high school and college",
  },
  {
    title: "Reframing the 'Free College' Debate",
    date: "20 NOV, 2022",
    imgSrc: "/assets/Finance/Image/Blog/3.png",
    text: "At the heart of the “free college” movement is the idea that everyone needs a four-year college degree. Yet this fails to address two critical problems with the higher education system in America",
  },
  {
    title: "Cost of College in the United States",
    date: "20 NOV, 2022",
    imgSrc: "/assets/Finance/Image/Blog/4.png",
    text: "For middle- to lower-income families in the U.S., in particular, the costs associated with attending a four-year university are becoming nearly",
  },
  {
    title: "Cost of College in the United States",
    date: "20 NOV, 2022",
    imgSrc: "/assets/Finance/Image/Blog/5.png",
    text: "Tuition subsidies may encourage institutions to raise tuition, since the government would foot the bill. One possible solution: develop and",
  },
  {
    title: "The High Cost of Free College",
    date: "20 NOV, 2022",
    imgSrc: "/assets/Finance/Image/Blog/6.png",
    text: "The policy question should not be weighed just by the issue of debt. There are other relevant arguments, some for, and some against, the question of expense. For example, is the cost of college rising by more than its affordability for the average student?",
  }
];

const FBlogCard = () => {
  const [modalShow, setModalShow] = useState(false);
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const getDataAll = async () => {
    try {
      const response = await getData(`/finance/blogs/f-blog`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data);


  useEffect(() => {
    getDataAll();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 because months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;
  };

  useEffect(() => {
    AOS.init({ once: true });
  }, []);

  return (
    <>
      {data && data?.length > 0 &&
        <section className='blog-card'>
          <Container>
            <Row>
              {data?.map((image, index) => (
                <Col key={index} xl={4} lg={4} md={6} sm={12}>
                  <Link to={`/finance/bloginner/${image?.id}`}>
                    <div className='finnnn-divv'>
                      <Card className='finance-blog-card mt-5' data-aos="fade-up" data-aos-duration="3000">
                        <Card.Img variant="top" src={IMG_URL + image?.image} className="blog-img" alt="..." />
                        <Card.Body>
                          <Card.Title className='blog-title'>{image?.name}</Card.Title>
                          <p className='blog-date'>By {image?.creator} on {formatDate(image?.createdAt)}</p>
                          <Card.Text className='blog-text'>{htmlToReactParser.parse(image?.description)}</Card.Text>
                          <Row>
                            <Col xl={9} lg={7} md={8} sm={8} xs={8} className='g-0'>
                              <div className='main-border-class'>
                                <div className='orange-dot'> </div>
                                <div className='grey-border-blog mt-2' ></div>
                                <div className='orange-border-blog ' ></div>
                              </div>
                            </Col>
                            <Col xl={3} lg={5} md={4} sm={4} xs={4} className='text-xl-start text-lg-start text-md-start text-sm-start '>
                              <Link to={`/finance/bloginner/${image?.id}`}><a className='blog-read-more'>Read More</a></Link>
                            </Col>
                          </Row>

                        </Card.Body>
                      </Card>
                    </div>
                  </Link>
                </Col>
              ))}
            </Row>
          </Container>
        </section >
      }
    </>
  )
}

export default FBlogCard;
