import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import './FInsuranceDocument.css'
import Card from 'react-bootstrap/Card';
import AOS from "aos";
import "aos/dist/aos.css";
const FInsuranceDocument = ({ service_id }) => {

    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();
    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/finance/insurance/f-i-documents/${service_id}`);
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data, "dsdsdsdsd");

    useEffect(() => {
        getDataAll();
    }, [service_id]);

    useEffect(() => {
        AOS.init({ once: true });
    }, [])
    return (
        <>
            {data && data?.length > 0 &&
                <section className='f-insurance-document'>
                    <div className='container' data-aos="fade-up"
                        data-aos-duration="3000">
                        <div className='row'>
                            <div className='hedaing-holder mb-5'>
                                <h2>Documents</h2>
                            </div>
                        </div>
                        {/* <div className='row justify-content-center align-items-center'>
                        {documentData.map((icon, index) => (
                            <div key={index} className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6 '>
                                <Card className='mb-5'>
                                    <Card.Img className="fcard-img" src={process.env.PUBLIC_URL + icon.imageSrc} alt="Card image" />
                                    <Card.ImgOverlay>
                                        <Card.Title>{icon.heading}</Card.Title>
                                        <Card.Text>
                                            {icon.text}
                                        </Card.Text>
                                    </Card.ImgOverlay>
                                    <div className='overlay-color'></div>
                                </Card>

                            </div>
                        ))}
                    </div> */}
                        <Swiper
                            spaceBetween={30}
                            pagination={{
                                clickable: true,
                                dynamicBullets: true,
                            }}
                            modules={[Pagination]}
                            breakpoints={{
                                576: { slidesPerView: 2 },
                                768: { slidesPerView: 2 },
                                1024: { slidesPerView: 3 },
                                1200: { slidesPerView: 4 },
                            }}
                            className="mySwiper"
                        >
                            {data?.map((image, index) => (
                                <SwiperSlide key={index}>
                                    <div className=' '>
                                        <Card className='mb-5'>
                                            <Card.Img
                                                className="fcard-img"
                                                src={IMG_URL + image?.image} alt="Card image"
                                            />
                                            <Card.ImgOverlay>

                                                <Card.Title>{image?.name}</Card.Title>
                                                <Card.Text>{htmlToReactParser.parse(image?.description)}</Card.Text>

                                            </Card.ImgOverlay>
                                            <div className='overlay-color'></div>
                                        </Card>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </section>
            }
        </>
    )
}

export default FInsuranceDocument