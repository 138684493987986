import { Context } from '../../../../../utils/context'
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import './FBlogDescription.css';
const FBlogDescription = ({ data }) => {
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();
    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 because months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        return `${day}-${month}-${year}`;
    };
    return (
        <>
            {data && data?.description &&
                <section className='blog-description'>
                    <div className='container'>
                        <div className='row'>
                            <div className="name-holder mb-4">
                                <h2>by {data?.creator}</h2>
                                <p>{formatDate(data?.createdAt)}</p>
                            </div>
                            <div className='text-holder'>
                                <p>{htmlToReactParser.parse(data?.description)}</p>
                            </div>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}

export default FBlogDescription