import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';
import '../FinanceLoanCalci.css'
import CanvasJSReact from '@canvasjs/react-charts';

const ThirdTab = () => {
  const CanvasJSChart = CanvasJSReact.CanvasJSChart;
  const [loanAmount, setLoanAmount] = useState(100000);
  const [interestRate, setInterestRate] = useState(1);
  const [loanTerm, setLoanTerm] = useState({ value: 1, unit: 'year' });

  const handleLoanAmountChange = (event) => {
    let input = event.target.value;
    if (/^\d*$/.test(input)) {
      if (input > 100000000) {
        input = 100000000;
      }
      setLoanAmount(parseInt(input, 10) || 0);
    }
  };


  const handleInterestRateChange = (event) => {
    let input = event.target.value;
    if (/^\d*\.?\d*$/.test(input)) {
      if (input > 30) {
        input = 30;
      }
      setInterestRate(parseFloat(input) || 0);
    }
  };

  const handleLoanTermChange = (event) => {
    let input = event.target.value;
    if (/^\d*$/.test(input)) {
      if (loanTerm.unit === 'year') {
        if (input > 30) {
          input = 30;
        }
      } else {
        if (input > 360) {
          input = 360;
        }
      }
      setLoanTerm({ ...loanTerm, value: parseInt(input, 10) || 0 });
    }
  };

  const handleLoanTermUnitChange = (unit) => {
    let adjustedValue = loanTerm.value;
    if (unit === 'year') {
      adjustedValue = (adjustedValue / 12);
      document.getElementById("basic-addon13").style.backgroundColor = "#ff8600bf";
      document.getElementById("basic-addon23").style.backgroundColor = "";
    } else {
      adjustedValue = adjustedValue * 12;
      document.getElementById("basic-addon13").style.backgroundColor = "";
      document.getElementById("basic-addon23").style.backgroundColor = "#ff8600bf";
    }
    setLoanTerm({ value: adjustedValue, unit });
  };

  const monthlyInterestRate = interestRate / 100 / 12;
  const numberOfPayments = loanTerm.unit === 'year' ? loanTerm.value * 12 : loanTerm.value;
  const emi = (loanAmount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));

  const [emiValue, setEmiValue] = useState(emi);
  const [totalInterest, setTotalInterest] = useState(0);
  const [principalAmount, setPrincipalAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    const monthlyInterestRate = interestRate / 100 / 12;
    const numberOfPayments = loanTerm.unit === 'year' ? loanTerm.value * 12 : loanTerm.value;
    const emiValue = (loanAmount * monthlyInterestRate) / (1 - Math.pow(1 + monthlyInterestRate, -numberOfPayments));
    setEmiValue(emiValue);

    const totalInterest = emiValue * numberOfPayments - loanAmount;
    setTotalInterest(totalInterest);

    setPrincipalAmount(loanAmount);
    const totalAmount = loanAmount + totalInterest;
    setTotalAmount(totalAmount);
  }, [loanAmount, interestRate, loanTerm]);

  const percentageComplete1 = ((loanAmount / 100000000) * 100).toFixed(1);
  const percentageComplete2 = ((interestRate - 1) / (30 - 1)) * 100;
  const percentageComplete3 = loanTerm.unit === 'year' ? (((loanTerm.value - 1) / (30 - 1)) * 98).toFixed(1) : (((loanTerm.value - 1) / (360 - 1)) * 98).toFixed(1);


  const percentagePrincipal = (principalAmount / totalAmount) * 100;
  const percentageInterest = (totalInterest / totalAmount) * 100;

  const options = {
    height: 200,
    data: [{
      type: "pie",
      showInLegend: true,
      legendText: "{label}",
      indexLabel: "{y}%",
      indexLabelPlacement: "inside",
      dataPoints: [
        { y: (percentagePrincipal).toFixed(2), label: "Principal Loan Amount", color: '#3D4D10' },
        { y: (percentageInterest).toFixed(2), label: "Total Interest", color: '#FF8600' },
      ]
    }]
  };

  return (
    <>
      <section className='EMI-Calci'>
        <div>
          <div className="range-div">
            <div className="main-ranges-div">
              <div className="price-div">
                <p>Loan Amount</p>
                <div>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="10000"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={loanAmount}
                      onChange={handleLoanAmountChange}
                      max={100000000}
                    />
                    <InputGroup.Text id="basic-addon2">₹</InputGroup.Text>
                  </InputGroup>
                </div>
              </div>
              <div className="price-div mt-4">
                <p>₹1L</p> <p>₹10CR</p>
              </div>
              <div className="range-bar-container">
                <p
                  className="range-value"
                  style={{
                    left: `calc(${percentageComplete1}% - 0px)`,
                  }}
                >
                  {/* ₹ {loanAmount} */}
                </p>
                <input
                  type="range"
                  min="100000"
                  max="100000000"
                  step={10000}
                  value={loanAmount}
                  onChange={handleLoanAmountChange}
                  className="range-bar"
                />
                <div
                  className="range-bar-color"
                  style={{
                    width: `${percentageComplete1}%`,
                  }}
                ></div>
              </div>
            </div>
            <div className="main-ranges-div ">
              <div className="price-div mt-5">
                <p>Interest Rate</p>
                <div>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="9"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={interestRate}
                      onChange={handleInterestRateChange}
                    />
                    <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                  </InputGroup>
                </div>
              </div>
              <div className="price-div">
                <p>1</p> <p>30</p>
              </div>
              <div className="range-bar-container">
                <p
                  className="range-value"
                  style={{
                    left: `calc(${percentageComplete2}% )`,
                  }}
                >
                  {/* {interestRate} % */}
                </p>
                <input
                  type="range"
                  min="1"
                  max="30"
                  step="0.1"
                  value={interestRate}
                  onChange={handleInterestRateChange}
                  className="range-bar"
                />
                <div
                  className="range-bar-color"
                  style={{
                    width: `${percentageComplete2}%`,
                  }}
                ></div>
              </div>
            </div>
            <div className="main-ranges-div">
              <div className="price-div mt-5">
                <p>Loan Tenure</p>
                <div>
                  <InputGroup className="mb-3">
                    <Form.Control
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      className='month-control'
                      value={loanTerm.value}
                      min={1}
                      max={30}
                      onChange={handleLoanTermChange}
                    />
                    {/* <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                      <InputGroup.Text id="basic-addon2" className="yr-span">
                        <li className="nav-item" role="presentation">
                          <button
                            className={`nav-link ${loanTerm.unit === 'year' ? 'active' : ''}`}
                            data-bs-toggle="pill"
                            type="button"
                            onClick={() => handleLoanTermUnitChange('year')}
                            role="tab"
                            aria-selected={loanTerm.unit === 'year' ? 'true' : 'false'}
                          >
                            yr
                          </button>
                        </li>
                      </InputGroup.Text>
                      <InputGroup.Text id="basic-addon2" className="yr-span">
                        <li className="nav-item" role="presentation">
                          <button
                            className={`nav-link ${loanTerm.unit === 'month' ? 'active' : ''}`}
                            data-bs-toggle="pill"
                            type="button"
                            onClick={() => handleLoanTermUnitChange('month')}
                            role="tab"
                            aria-selected={loanTerm.unit === 'month' ? 'true' : 'false'}
                          >
                            mo
                          </button>
                        </li>
                      </InputGroup.Text>
                    </ul> */}
                    <InputGroup.Text id="basic-addon13" onClick={() => handleLoanTermUnitChange('year')}>Yr</InputGroup.Text>
                    <InputGroup.Text id="basic-addon23" onClick={() => handleLoanTermUnitChange('month')}>Mo</InputGroup.Text>
                  </InputGroup>
                </div>
              </div>
              <div className="price-div">
                <p>1</p> <p>{loanTerm.unit === 'year' ? "30" : "360"}</p>
              </div>
              <div className="range-bar-container">
                <p
                  className="range-value"
                  style={{
                    left: `calc(${percentageComplete3}% )`,
                  }}
                >
                  {/* {loanTerm.value} {loanTerm.unit} */}
                </p>
                <input
                  type="range"
                  min="1"
                  max={loanTerm.unit === 'year' ? "30" : "360"}
                  value={loanTerm.value}
                  onChange={handleLoanTermChange}
                  className="range-bar"
                />
                <div
                  className="range-bar-color"
                  style={{
                    width: `${percentageComplete3}%`,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="calculation-div mt-5">
            <div className="row">
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 ">
                <div className="loan-table">
                  <Table responsive border>
                    <thead>
                      <tr>
                        <th>Loan EMI</th>
                        <th>Total Interest Payable</th>
                        <th>Principal Amount</th>
                        <th>Total Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>₹{parseInt(emiValue).toLocaleString()}</td>
                        <td>₹{Math.floor(totalInterest).toLocaleString()}</td>
                        <td>₹{parseInt(principalAmount).toLocaleString()}</td>
                        <td>₹{parseInt(totalAmount).toLocaleString()}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-12 col-md-12 ">
                <div className="pie-chart-div ">
                  <p>Break-up of Total Payment</p>
                  <CanvasJSChart options={options} className="pie" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ThirdTab;
