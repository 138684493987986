import React, { useEffect, useRef, useState, useContext } from 'react';
import "react-international-phone/style.css";

import "./StepTwoEmploymentType.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { Context } from '../../../../../../../utils/context';
import { useForm, Controller, SubmitHandler } from "react-hook-form";

function StepTwoEmploymentType({ setDropCount, setEmployment_type_id, loanFormDetail }) {

  useEffect(() => {
    reset(loanFormDetail);
    console.log("loanFormDetail form 2:-", loanFormDetail);
    setEmploymentTypeId(loanFormDetail?.employment_type_id?.value)
  }, [loanFormDetail]);


  const [selected, setSelected] = useState(null);
  const [modalShow, setModalShow] = useState(false);

  const [employmentTypeId, setEmploymentTypeId] = useState(null);
  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
  const [data, setData] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset
  } = useForm();


  // console.log(employment_type_id, 'empppppp');
  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/master/all-employment-type`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    getDataAll();
  }, []);

  const handleOptionSelect = (index, id) => {
    setEmploymentTypeId(id);
    setEmployment_type_id(id);
    // Pass the employment_type_id to the parent
  };


  return (
    <>
      <div className="StepTwoEmploymentType">
        <Form >
          {data?.map((option, index) => (
            <div className="EmploymentType-content" key={index}>
              <div className="text-holder">
                <h6>{option?.name}</h6>
                <p>{option?.sub_name}</p>
              </div>
              <div className="circle-icon-holder">
                <Form.Check
                  inline
                  name="employment_type_id"
                  type="radio"
                  // value={employment_type_id}
                  // checked={selected === index}
                  {...register('employment_type_id', { required: true })}
                  onChange={() => handleOptionSelect(index, option.id)}
                  checked={employmentTypeId === option.id}
                />
              </div>
            </div>
          ))}
          {errors.employment_type_id && <p className="text-danger">Employment Type is required</p>} {/* Error message for required validation */}
        </Form>
      </div>
    </>
  );
}

export default StepTwoEmploymentType;
