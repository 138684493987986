import React, { useState, useEffect, useContext } from "react";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import { Button, Form } from "react-bootstrap";
import {
    useForm,
    Controller,
    SubmitHandler,
    useFieldArray,
} from "react-hook-form";
import { RegxExpression } from "../../../../../../../utils/apis/common/Common";
import classNames from "classnames"
import { Context } from "../../../../../../../utils/context";

const SocialMediaLink = ({ register, control, errors, getValues, userData }) => {



    const { append, fields, remove } = useFieldArray({
        control,
        name: "link",
    });

    // const getJobSeekarDetails = async () => {
    //     if (userData?.job_seeker_personal_details_links.length > 0) {
    //         userData?.job_seeker_personal_details_links?.forEach((item, index) => {
    //             append({
    //                 link: item?.link,
    //             });
    //         });
    //     } else {
    //         append({
    //             link: "",
    //         });
    //     }
    // }



    useEffect(() => {
        // getJobSeekarDetails();
        window.scrollTo(0, 0);
    }, [userData]);
    return (
        <>
            <div className="personal-details-form ">
                {/* Social Media Links: */}
                <div className="Social_Media_Links_holder">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-field ">
                                <Form.Group controlId="">
                                    <div className="Social_Media_Links">

                                        <Form.Label className="">Social Media Links:</Form.Label>
                                        <div>
                                            <h6 onClick={() => append({ link: "" })}>Add Link/s</h6>
                                        </div>
                                    </div>
                                    <div className="links-holder">
                                        {fields.map((item, index) => (
                                            <div key={index} className="input-group mb-3">
                                                <Form.Control
                                                    type="text"
                                                    name={`link[${index}].link`}
                                                    placeholder="Enter Social Media Link"
                                                    // value={item?.name}
                                                    {...register(`link[${index}].link`, {
                                                        required: "Social Media Link is Required",
                                                        pattern: {
                                                            value: /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
                                                            message: "Please enter a valid URL",
                                                        },
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid":
                                                            errors.link &&
                                                            errors.link[index] &&
                                                            errors.link[index].link,
                                                        "is-valid": getValues(`link[${index}].link`),
                                                    })}
                                                // onKeyDown={(event) => {
                                                //     if (!RegxExpression.name.test(event.key)) {
                                                //         event.preventDefault();
                                                //     }
                                                // }}
                                                />
                                                {index !== 0 && (
                                                    <div className="input-group-append">
                                                        <p className="" onClick={() => remove(index)}>
                                                            Remove Link{" "}
                                                            {/* <FontAwesomeIcon icon="fa-solid fa-minus" /> */}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    </div>

                                </Form.Group>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SocialMediaLink;
