import React, { useEffect, useRef, useState, useContext } from 'react';
import "react-international-phone/style.css";

import "./StepThreeSelectYearlyIncome.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Form, Button } from "react-bootstrap";
import { Context } from '../../../../../../../utils/context';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
// import { CancelButton, SaveButton } from "../../../../../../../utils/common";

function StepThreeSelectYearlyIncome({ setYearlyIncomeId, loanFormDetail }) {
  const [selected, setSelected] = useState(null); // Will store the index of the selected option

  const [modalShow, setModalShow] = useState(false);

  const [yearly_income_id, selectedYearID] = useState(null);
  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
  const [data, setData] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset
  } = useForm();

  useEffect(() => {
    reset(loanFormDetail);
    console.log("loanFormDetail form 3:-", loanFormDetail);
    setSelected(loanFormDetail?.yearly_income_id?.value)
  }, [loanFormDetail]);

  console.log(yearly_income_id, 'empppppp');
  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/master/all-yearly-income`);
      setData(response?.data);
      console.log("yearly income data", response?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getDataAll();
  }, []);

  const handleOptionSelect = (id) => {
    setSelected(id);
    setYearlyIncomeId(id);
  };


  return (
    <>
      <div className="StepTwoEmploymentType">
        <Form >
          {/* Mapping over the options array */}
          {data?.map((year, index) => (
            <div className="EmploymentType-content" key={index}>
              <div className="text-holder">
                <p>{"₹"}{year?.min}{"  "}-{"  "}{year?.max}{"  "}lakh</p>
              </div>
              <div className="circle-icon-holder">
                {/* For each option, render a radio button */}
                <Form.Check
                  inline
                  name="yearly_income_id"
                  type="radio"
                  value={year.id}
                  // checked={selected === index}
                  {...register('yearly_income_id', { required: true })}
                  onChange={() => handleOptionSelect(year.id)}
                  checked={selected === year.id}
                />
              </div>

            </div>
          ))}
        </Form >
      </div>
    </>
  );
}

export default StepThreeSelectYearlyIncome;
