import React from 'react'
import JpDashboardbanner from './JpDashboardbanner/JpDashboardbanner'
import MainDashboard from './MainDashboard/MainDashboard'

function JpDashboard() {
  return (
    <>
      <JpDashboardbanner />
      <MainDashboard />
    </>
  )
}

export default JpDashboard