import React from "react";
import { Link } from "react-router-dom";

function Landing_Page_Sliders({heading, subText, image ,link}) {
  return (
    <>
      
        <div className="landing_page_sliders">

          <div className="slider-bg">
            <div className="row me-0 ms-0">
              <div className="col-md-8 col-sm-8 p-0">
                <div className="white-bg"></div>
              </div>
              <div className="col-md-4 col-sm-4 p-0">
                <div className="orange-bg"></div>
              </div>
            </div>
          </div>

          <div className="slider_content">
            <div className="container-fluid p-0">
              <div className="logo-holder">
                <img
                  className="logo"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/logo/Profcyma-logotwo.png"
                  }
                  alt="logo-holder"
                />
              </div>

              <div className="img_text_content_holder">
                <div className="row me-0 ms-0">
                  <div className="col-xxl-7  col-xl-5 col-md-5 col-sm-5 col-12 p-0">
                    <div className="heading-text-holder">
                      <div className="text-holder">
                        <div className="heading-holder">
                          <h1>
                          {heading}
                        
                          </h1>
                        </div>

                        <div className="content-holder">
                          <p>
                            {subText}
                          
                          </p>
                          <Link to={link}><button className="explore-btn">Explore</button></Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-5  col-xl-7 col-md-7 col-sm-7 col-12 p-0">
                    <div className="content-img-holder">
                      <div className="image-holder">
                        <img
                          className="slider_banner_img"
                          src={image}
                          alt="slider_banner_img"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      
    </>
  );
}

export default Landing_Page_Sliders;
