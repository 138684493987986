import React from 'react'
import EmpsettingBanner from './EmpsettingBanner/EmpsettingBanner'
import EmpAccountManagement from './AccountManagement/EmpAccountManagement'

function EmpSetting() {
  return (
   <>
   <EmpsettingBanner/>
   <EmpAccountManagement/>
   </>
  )
}

export default EmpSetting