import React, { useState, useEffect, useContext } from 'react';
// import "./EmpsettingBanner.css"
import '../../../Jp-Employer/EmpSetting/EmpsettingBanner/EmpsettingBanner.css'
import { Context } from '../../../../../utils/context';

function JobseekersettingBanner() {



    const { getData, postData, IMG_URL, signin, usertype, userdata } = useContext(Context);

    const [companyProfileData, setCompanyProfileData] = useState();

    const getCompanyProfileData = async () => {
        const res = await getData("/job-portal/employer/company-details/company-edit/profile-image-company/data");
        if (res?.success) {
            setCompanyProfileData(res?.data);
        } else {
            console.error("Error fetching  data:", res?.error);
        }
    };
    useEffect(() => {
        getCompanyProfileData();
    }, []);
    return (
        <>
            <div className='setting-banner'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-xxl-10 col-xl-10 col-lg-10 col-12'>
                            <div className='setting-card'>
                                <div className='set-div'>
                                    <div class="company-img-div">
                                        <img class="com-logo"  src={IMG_URL + companyProfileData?.image} />
                                    </div>
                                </div>
                                <h5>Account Settings</h5>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default JobseekersettingBanner