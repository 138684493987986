import React, { useState, useContext } from "react";
import "./Jp_recent_job_card.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Exlamentry from "../../../Jp-modals/Exlamentry_Modal/Exlamentry";
import { Context } from "../../../../../../utils/context";
import { useParams, useNavigate } from "react-router-dom";
import { saveJobPost } from "../../../../../../utils/apis/common/Common";

const Jp_recent_job_card = ({ recentJobData, selectedId }) => {

  const { IMG_URL, signin } = useContext(Context);
  const [modalShow, setModalShow] = useState(false);
  const [modalsaveShow, setsaveModalShow] = useState(false);
  const [isActive, setIsActive] = useState(false);


  const navigate = useNavigate();

  const openmodal = () => {
    setModalShow(true);
    setTimeout(() => {
      setModalShow(false);
    }, 3000);
  };


  const companyId = async (company_id) => {
    const data = new FormData();
    data.append("job_id", company_id);
    const res = await saveJobPost(data)
    if (res?.success) {
      setsaveModalShow(true);
      setIsActive(true);
      setTimeout(() => {
        setsaveModalShow(false);
      }, 3000)
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear().toString().slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}/${month}/${day}`;
  }
  // console.log(recentJobData,"recentJobDatarecentJobDatarecentJobData");

  // console.log(recentJobData,"industryTypeindustryTypeindustryType");

  // const recentjobdata = [
  //   {
  //     comapnylogo:
  //       "/assets/Job-portal/Images/home/recent-jobs/company/profcyma.png",
  //     comapnyname: "Profcyma Solutions Pvt. Ltd.",
  //     ratestar: "/assets/Job-portal/Images/home/recent-jobs/icons/star.png",
  //     ratings: "4.6",
  //     reviews: "8",
  //     time: "Full Time",
  //     jobposition: "Content Writer",
  //     experience: "1-2",
  //     Posted: "Just now",
  //     location: "Bavdhan,Pune",
  //     openings: "2",
  //   },
  //   {
  //     comapnylogo:
  //       "/assets/Job-portal/Images/home/recent-jobs/company/wipro.png",
  //     comapnyname: "Wipro",
  //     ratestar: "/assets/Job-portal/Images/home/recent-jobs/icons/star.png",
  //     ratings: "4.6",
  //     reviews: "8",
  //     time: "Full Time",
  //     jobposition: "Content Writer",
  //     experience: "1-2",
  //     Posted: "Just now",
  //     location: "Bavdhan,Pune",
  //     openings: "2",
  //   },
  //   {
  //     comapnylogo:
  //       "/assets/Job-portal/Images/home/recent-jobs/company/wipro.png",
  //     comapnyname: "Wipro",
  //     ratestar: "/assets/Job-portal/Images/home/recent-jobs/icons/star.png",
  //     ratings: "4.6",
  //     reviews: "8",
  //     time: "Full Time",
  //     jobposition: "Content Writer",
  //     experience: "1-2",
  //     Posted: "Just now",
  //     location: "Bavdhan,Pune",
  //     openings: "2",
  //   },
  //   {
  //     comapnylogo:
  //       "/assets/Job-portal/Images/home/recent-jobs/company/wipro.png",
  //     comapnyname: "Wipro",
  //     ratestar: "/assets/Job-portal/Images/home/recent-jobs/icons/star.png",
  //     ratings: "4.6",
  //     reviews: "8",
  //     time: "Full Time",
  //     jobposition: "Content Writer",
  //     experience: "1-2",
  //     Posted: "Just now",
  //     location: "Bavdhan,Pune",
  //     openings: "2",
  //   },
  //   {
  //     comapnylogo:
  //       "/assets/Job-portal/Images/home/recent-jobs/company/wipro.png",
  //     comapnyname: "Wipro",
  //     ratestar: "/assets/Job-portal/Images/home/recent-jobs/icons/star.png",
  //     ratings: "4.6",
  //     reviews: "8",
  //     time: "Full Time",
  //     jobposition: "Content Writer",
  //     experience: "1-2",
  //     Posted: "Just now",
  //     location: "Bavdhan,Pune",
  //     openings: "2",
  //   },
  //   {
  //     comapnylogo:
  //       "/assets/Job-portal/Images/home/recent-jobs/company/wipro.png",
  //     comapnyname: "Wipro",
  //     ratestar: "/assets/Job-portal/Images/home/recent-jobs/icons/star.png",
  //     ratings: "4.6",
  //     reviews: "8",
  //     time: "Full Time",
  //     jobposition: "Content Writer",
  //     experience: "1-2",
  //     Posted: "Just now",
  //     location: "Bavdhan,Pune",
  //     openings: "2",
  //   },
  // ];

  return (
    <>
      <section className="Jp_recent_job_card_section">
        <div className="overflow-data">
          <div className="main-card">
            {recentJobData?.map((data, index) => (
              <div className="job-list-card-div">
                <div className="mob-change-order">
                  <div className="company-logo-div">
                    <img className="comp-img" src={IMG_URL + data?.company_logo} />
                  </div>
                  <div className="company-cont-div">
                    <h6 className="comp-name">{data?.company?.name}</h6>

                    <div className="ratings-divv">
                      <div className="d-flex">
                        <div className="rate-div">
                          <p className="first ">
                            <img className="rate-star" src={data.ratestar} />
                            &nbsp;{" "}
                            <span className="bd-riht">{data.ratings}</span>{" "}
                            &nbsp; <span>{data.reviews} Reviews </span>
                          </p>
                        </div>
                        <div className="job-type-div">
                          <div className="d-flex">
                            {data?.job_type_details?.map((jobTypeDetail) => (
                              <button key={jobTypeDetail.id} className="btn full-time">
                                {jobTypeDetail?.job_type?.name}
                              </button>
                            ))}

                            {signin ? (
                              <FontAwesomeIcon
                                className={data?.jobseeker_save_job?.save === 1 ? "bookmarkk-icc active" : "bookmarkk-icc"}
                                icon="fa-solid fa-bookmark"
                                onClick={() => { companyId(data?.id); }}
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="bookmarkk-icc"
                                icon="fa-solid fa-bookmark"
                                onClick={() => { setModalShow(true); navigate("/job-portal/sign-in") }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6 className="job-position">{data?.job_title?.name}</h6>
                    <div className="row">
                      <div className="col-xl-2 col-md-3  mb-2">
                        <div className="job-dis-div">
                          <div className=" d-flex">
                            <div>
                              <img
                                className="icc"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/Job-portal/Images/home/recent-jobs/icons/year.png"
                                }
                              />
                            </div>
                            <div className="my-auto">
                              <p className="cont">{data?.experience_min} - {data?.experience_max} </p>
                            </div>
                            <div className="my-auto">
                              <p className="cont">&nbsp;Years</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3  col-md-3  mb-2">
                        <div className="job-dis-div">
                          <div className=" d-flex">
                            <div>
                              <img
                                className="icc"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/Job-portal/Images/home/recent-jobs/icons/posted.png"
                                }
                              />
                            </div>
                            <div className="my-auto">
                              <p className="cont">Posted: </p>
                            </div>
                            <div className="my-auto">
                              <p className="cont">{" " + formatDate(data?.createdAt)} </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-3  col-md-3  mb-2">
                        <div className="job-dis-div">
                          <div className=" d-flex">
                            <div>
                              <img
                                className="icc"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/Job-portal/Images/home/recent-jobs/icons/location.png"
                                }
                              />
                            </div>
                            <div className="my-auto">
                              <p className="cont">{data?.address}, {data?.city?.name}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2  col-md-3  mb-2">
                        <div className="job-dis-div">
                          <div className=" d-flex">
                            <div>
                              <img
                                className="icc"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/Job-portal/Images/home/recent-jobs/icons/openings.png"
                                }
                              />
                            </div>
                            <div className="my-auto">
                              <p className="cont">Openings: </p>
                            </div>
                            <div className="my-auto">
                              <p className="cont">{data?.number_of_vacancy} </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-2 mb-2">
                        <div className="view-det-div">
                          {signin ? (
                            // <button className="view-btn" onClick={openmodal}>
                            <button className="view-btn" onClick={() => navigate(`/job-portal/search/job-description/${data?.id}`)} >
                              View Details
                            </button>
                          ) : (
                            // <Button className="apply-btn" onClick={() => navigate("/job-portal/sign-in")}>Explore</Button>
                            // <button className="view-btn" onClick={openmodal}>
                            <button className="view-btn" onClick={() => { setModalShow(true); navigate("/job-portal/sign-in") }}>
                              View Details
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <Exlamentry
              show={modalShow}
              onHide={() => setModalShow(false)}
              text={"Your Need to Log In to Job Seeker to Apply for the Job!"}
            />

            <Exlamentry
              show={modalsaveShow}
              onHide={() => setsaveModalShow(false)}
              text={"Your Job Save SuccessFully!"}
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Jp_recent_job_card;
