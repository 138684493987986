import React, { useState, useEffect, useContext } from "react";
import './Jp_browse_cat_inner.css'
import Jp_recent_job_card from '../../../JP-Common-Elements/JP-Cards/Jp_applied_job_cards/Jp_recent_job_card/Jp_recent_job_card'
import CreateAccount from '../../../JP-Companies/JPPopularCompanies/JPPopularCompaniesTabContent/JPPopularCompaniesITTab/CreateAccount/CreateAccount'
import { Link } from 'react-router-dom'
import { useParams } from "react-router-dom";
import { Context } from "../../../../../utils/context";

const Jp_browse_cat_inner = () => {

    const {id} = useParams();

    const { getData } = useContext(Context);

    const [recentJobData, setRecenData] = useState();

    const getCategory = async () => {
        try {
            const res = await getData(`/job-portal/home/categary/${id}`)
            if (res?.success) {
                setRecenData(res?.data)
            } else {
                console.log("Error");
            }
        } catch (error) {
            console.log(error.message);
        }
    }
    
    useEffect(() => {
        getCategory();
    }, []);

    return (
        <>
            <section className='Jp_browse_cat_inner_section'>

                <div className='topbannnn'>
                    <h5 className='titlleeecsjg'>Result from,Browse by Category</h5>
                </div>
         

                <div className='container'>
       
                    <div className='row'>

                        <div className='col-xxl-8 col-xl-10 mx-auto'>
                           <div className="BacktoCompanies my-4">
                            <Link to={"/job-portal/home"}>
                                <p className="">Back to Home</p>
                            </Link>
                            </div>
                            <Jp_recent_job_card recentJobData={recentJobData} />
                            <CreateAccount />
                            <Jp_recent_job_card />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Jp_browse_cat_inner