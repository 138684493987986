// import React from 'react'

// function StepSevenSelectResidenceCity() {
//   return (
//     <div>StepSevenSelectResidenceCity</div>
//   )
// }

// export default StepSevenSelectResidenceCity
import React, { useEffect, useRef, useState, useContext } from 'react';
import "react-international-phone/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCheck } from "@fortawesome/free-solid-svg-icons";
import { Form, FormControl, Offcanvas } from "react-bootstrap";
import { Context } from '../../../../../../../utils/context';
import { useForm, Controller, SubmitHandler } from "react-hook-form";

function StepSevenSelectResidenceCity({ setCity_id, loanFormDetail }) {


  useEffect(() => {
    reset(loanFormDetail);
    console.log("loanFormDetail form 7:-", loanFormDetail);
    setSelected(loanFormDetail?.city_id?.value)
  }, [loanFormDetail]);

  const [selected, setSelected] = useState([false, false, false]);
  const [selectedTwo, setSelectedTwo] = useState([false, false, false]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const options = [
    [
      {
        description: "Delhi",
      },
      {
        description: "Mumbai",
      },
    ],
    [
      {
        description: "Pune ",
      },
      {
        description: "Chennai",
      },
    ],
    [
      {
        description: "Bengaluru",
      },
      {
        description: "Hyderabad",
      },
    ],
    [
      {
        description: "Kolkata",
      },
      {
        description: "Jaipur",
      },
    ],
  ];

  const optionsTwo = [
    [
      {
        description: "Delhi",
      },
      {
        description: "Mumbai",
      },
      {
        description: "Pune ",
      },
      {
        description: "Chennai",
      },
      {
        description: "Bengaluru",
      },
      {
        description: "Hyderabad",
      },
      {
        description: "Kolkata",
      },
      {
        description: "Jaipur",
      },
      {
        description: "Delhi",
      },
      {
        description: "Mumbai",
      },
      {
        description: "Pune ",
      },
      {
        description: "Chennai",
      },
      {
        description: "Bengaluru",
      },
      {
        description: "Hyderabad",
      },
      {
        description: "Kolkata",
      },
      {
        description: "Jaipur",
      },
    ],
  ];

  const handleToggle = (index) => {
    const updatedSelected = Array(options.length).fill(false); // Initialize all options as unselected
    updatedSelected[index] = true; // Select the clicked option
    setSelected(updatedSelected);
  };

  const handleToggleTwo = (index) => {
    const updatedSelectedTwo = Array(options.length).fill(false); // Initialize all options as unselected
    updatedSelectedTwo[index] = true; // Select the clicked option
    setSelected(updatedSelectedTwo);
  };
  const [modalShow, setModalShow] = useState(false);

  const [city_id, selectedCityTypeID] = useState(null);
  const { getData, postData, IMG_URL, Select2Data } = useContext(Context);
  const [data, setData] = useState([]);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset
  } = useForm();


  console.log(city_id, 'empppppp');
  const getDataAll = async () => {
    try {
      const response = await getData(`/without-login/master/all-cities`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };



  useEffect(() => {
    getDataAll();
  }, []);

  const handleOptionSelect = (index, id) => {
    console.log(id, "ididdidi");
    selectedCityTypeID(id);
    setCity_id(id);
  };

  return (
    <>
      <div className="StepFourSelectPrimaryBankAccount ">
        <Form >
          <div className="row">
            {data?.map((city, index) => (
              <div className="col-md-6" key={index}>
                {/* {row.map((city, cityIndex) => ( */}
                <div className="EmploymentType-content">
                  <div className="text-holder">
                    <p>{city.name}</p>
                  </div>
                  <div className="circle-icon-holder">
                    <Form.Check
                      inline
                      name="city_id"
                      type="radio"
                      value={city.id}
                      // checked={selected === index}
                      {...register('city_id', { required: true })}
                      onChange={() => { handleOptionSelect(city.id) }}
                      checked={selected === city.id}
                    />
                  </div>
                </div>
                {/* ))} */}
              </div>
            ))}
          </div>
        </Form>

        <div className="view-more-btn">
          <p onClick={handleShow}>View All Cities </p>
        </div>
      </div>

      <div className="side-off-sec ">
        <Offcanvas
          className="side-off-sec "
          placement="end"
          show={show}
          onHide={handleClose}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              <div className="search-bar-input">
                <Form.Control
                  className="search-bar"
                  type="text"
                  placeholder="Search your city"
                />
                <div className="search-icon-holder">
                  <FontAwesomeIcon
                    className="search-icon"
                    icon="fa-solid fa-magnifying-glass"
                  />
                </div>
              </div>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="heading-holder">
              <h6>All Cities</h6>
            </div>
            <div className="StepFourSelectPrimaryBankAccount StepFour_offcanvas_sec ">
              <div className="row me-0 ms-0">
                <div className="col-md-12 p-0">
                  <Form>
                    {data?.map((city, index) => (
                      <div className="EmploymentType-content" key={index}>
                        <div className="text-holder">
                          <p>{city?.name}</p>
                        </div>
                        <div className="circle-icon-holder">
                          <Form.Check
                            inline
                            name="cityType"
                            type="radio"
                            value={city.id}
                            // checked={selected === index}
                            {...register('cityType', { required: true })}
                            onChange={() => handleOptionSelect(city.id)}
                          />
                        </div>
                      </div>
                    ))}
                  </Form>
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </>
  );
}

export default StepSevenSelectResidenceCity;
