import React from 'react'
import "./FeaturedJobs.css";

const FeaturedJobs = () => {

    const featuredCard = [
        { id: 1, text: "More Visibility", imgSource: process.env.PUBLIC_URL + "/assets/Job-portal/Images/employer/FeaturedJob/ability.png" },
        { id: 1, text: "Quality Applicants", imgSource: process.env.PUBLIC_URL + "/assets/Job-portal/Images/employer/FeaturedJob/Layer1.png" },
        { id: 1, text: "Abilities Verfication", imgSource: process.env.PUBLIC_URL + "/assets/Job-portal/Images/employer/FeaturedJob/quality.png" },
        { id: 1, text: "Organising Candidates", imgSource: process.env.PUBLIC_URL + "/assets/Job-portal/Images/employer/FeaturedJob/ability.png" },
    ]
    return (
        <>
            <section className='FeaturedJobs'>
                <div className='container'>
                    <div className='featuredHead'>
                        <h3>Features</h3>
                    </div>
                    <div className='row'>
                        {
                            featuredCard.map((item, index) => (
                                <>
                                    <div className='col-lg-3 col-md-6'>
                                        <div className='card featuredCard'>
                                            <div className='d-flex'>
                                                <div className='my-auto'>
                                                    <img src={item.imgSource} className='featuredImg1' />
                                                </div>
                                                <div className='featuredtxt'>
                                                    <h3>{item.text}</h3>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </>
                            ))
                        }



                    </div>
                </div>
            </section>


        </>
    )
}

export default FeaturedJobs