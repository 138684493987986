import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Table from 'react-bootstrap/Table';
import '../FinanceLoanCalci.css';

const SeventhTab = () => {
  const [initialInvestment, setInitialInvestment] = useState(0);
  const [monthlyContribution, setMonthlyContribution] = useState(0);
  const [interestRate, setInterestRate] = useState(15);
  const [loanTerm, setLoanTerm] = useState({ value: 12, unit: 'year' });

  const handleChange1 = (event) => {
    let input = parseInt(event.target.value);
    if (!isNaN(input)) {
      if (input > 2000000) {
        input = 2000000;
      }
      setInitialInvestment(input);
    }
  };

  const handleChange2 = (event) => {
    let input = parseInt(event.target.value);
    if (!isNaN(input)) {
      if (input > 2000000) {
        input = 2000000;
      }
      setMonthlyContribution(input);
    }
  };

  const handleChange3 = (event) => {
    let input = parseFloat(event.target.value);
    if (!isNaN(input)) {
      if (input > 30) {
        input = 30;
      }
      setInterestRate(input);
    }
  };

  const handleLoanTermChange = (event) => {
    let input = parseInt(event.target.value);
    if (!isNaN(input)) {
      if (loanTerm.unit === 'year') {
        // If the unit is 'year', limit input to 40 years
        if (input > 40) {
          input = 40;
        }
      } else {
        // If the unit is 'month', limit input to 480 months (40 years * 12 months/year)
        if (input > 480) {
          input = 480;
        }
      }
      setLoanTerm({ ...loanTerm, value: input });
    }
  };

  const handleLoanTermUnitChange = (unit) => {
    let adjustedValue = loanTerm.value;
    if (unit === 'year') {
      adjustedValue = parseFloat(adjustedValue / 12);
      document.getElementById("basic-addon31").style.backgroundColor = "#ff8600bf";
      document.getElementById("basic-addon32").style.backgroundColor = "";
    }
    setLoanTerm({ value: adjustedValue, unit });
    if (unit === 'month') {
      adjustedValue = parseInt(adjustedValue * 12);
      document.getElementById("basic-addon31").style.backgroundColor = "";
      document.getElementById("basic-addon32").style.backgroundColor = "#ff8600bf";
    }
    setLoanTerm({ value: adjustedValue, unit });
  };

  const percentageComplete1 = (initialInvestment / 2000000) * 98;
  const percentageComplete2 = (monthlyContribution / 2000000) * 98;
  const percentageComplete3 = ((interestRate - 1) / (30 - 1)) * 98;
  const percentageComplete4 = loanTerm.unit === 'year' ? ((loanTerm.value / 40) * 98).toFixed(1) : ((loanTerm.value / 480) * 98).toFixed(1);

  const [totalInvestment, setTotalInvestment] = useState(0);
  const [totalSaving, setTotalSaving] = useState(0);
  const [totalInterest, setTotalInterest] = useState(0);

  useEffect(() => {
    const calculateTotalInvestments = () => {
      const totalInvestments = parseFloat(initialInvestment) + parseFloat(monthlyContribution) * 12 * (loanTerm.unit === 'year' ? parseFloat(loanTerm.value) : parseFloat(loanTerm.value) / 12);
      return totalInvestments.toLocaleString('en-IN', { maximumFractionDigits: 2 });
    };
    setTotalInvestment(calculateTotalInvestments());

    // const calculateTotalSavings = () => {
    //   const totalInvestments = parseFloat(initialInvestment) + parseFloat(monthlyContribution) * 12 * (loanTerm.unit === 'year' ? parseFloat(loanTerm.value) : parseFloat(loanTerm.value) / 12);
    //   const monthlyInterestRate = interestRate / 12 / 100;
    //   const totalSavings = totalInvestments * Math.pow(1 + monthlyInterestRate, (loanTerm.unit === 'year' ? loanTerm.value : loanTerm.value / 12) * 12);
    //   return totalSavings.toLocaleString('en-IN', { maximumFractionDigits: 2 });
    // };
    // setTotalSaving(calculateTotalSavings());

    const calculateTotalSavings = () => {
      const initialInvestmentFloat = parseFloat(initialInvestment);
      const monthlyContributionFloat = parseFloat(monthlyContribution);
      const annualInterestRateFloat = parseFloat(interestRate) / 100;
      const loanTermValueFloat = parseFloat(loanTerm.value);
      const n = 12; // Number of compounding periods per year

      const t = loanTerm.unit === 'year' ? loanTermValueFloat : loanTermValueFloat / 12;
      const monthlyInterestRate = annualInterestRateFloat / n;

      const A1 = initialInvestmentFloat * Math.pow(1 + monthlyInterestRate, n * t);
      const A2 = monthlyContributionFloat * (Math.pow(1 + monthlyInterestRate, n * t) - 1) / monthlyInterestRate;
      const totalSavings = A1 + A2;

      setTotalSaving(totalSavings.toLocaleString('en-IN', { maximumFractionDigits: 2 }));
      return totalSavings.toLocaleString('en-IN', { maximumFractionDigits: 2 });
    };

    const calculateTotalInterest = () => {
      const totalInvestments = parseFloat(initialInvestment) + parseFloat(monthlyContribution) * 12 * (loanTerm.unit === 'year' ? parseFloat(loanTerm.value) : parseFloat(loanTerm.value) / 12);
      const totalSavings = parseFloat(calculateTotalSavings().replace(/,/g, ''));
      return (totalSavings - totalInvestments).toLocaleString('en-IN', { maximumFractionDigits: 2 });
    };
    setTotalInterest(calculateTotalInterest());
  }, [initialInvestment, monthlyContribution, interestRate, loanTerm]);

  return (
    <>
      <section className='monthly-saving'>
        <div>
          <div className="range-div">
            <div className="main-ranges-div">
              <div className="price-div">
                <p>Initial Investment</p>
                <div>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="number"
                      placeholder="4000000"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={initialInvestment}
                      onChange={handleChange1}
                    />
                    <InputGroup.Text id="basic-addon2">₹</InputGroup.Text>
                  </InputGroup>
                </div>
              </div>
              <div className="price-div mt-5">
                <p>₹10000</p><p>₹2,000,000</p>
              </div>
              <div className="range-bar-container">
                <p
                  className="range-value"
                  style={{
                    left: `calc(${percentageComplete1}% - 30px)`,
                  }}
                >
                  {/* ₹ {initialInvestment} */}
                </p>
                <input
                  type="range"
                  min="10000"
                  max="2000000"
                  step={10000}
                  value={initialInvestment}
                  onChange={handleChange1}
                  className="range-bar"
                />
                <div
                  className="range-bar-color"
                  style={{
                    width: `${percentageComplete1}%`,
                  }}
                ></div>
              </div>
            </div>
            <div className="main-ranges-div">
              <div className="price-div mt-5">
                <p>Monthly contribution amount</p>
                <div>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="number"
                      placeholder="629235"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={monthlyContribution}
                      onChange={handleChange2}
                    />
                    <InputGroup.Text id="basic-addon2">₹</InputGroup.Text>
                  </InputGroup>
                </div>
              </div>
              <div className="price-div mt-5">
                <p>₹5000</p><p>₹2,000,000</p>
              </div>
              <div className="range-bar-container">
                <p
                  className="range-value"
                  style={{
                    left: `calc(${percentageComplete2}% - 30px)`,
                  }}
                >
                  {/* ₹ {monthlyContribution} */}
                </p>
                <input
                  type="range"
                  min="5000"
                  max="2000000"
                  value={monthlyContribution}
                  onChange={handleChange2}
                  className="range-bar"
                />
                <div
                  className="range-bar-color"
                  style={{
                    width: `${percentageComplete2}%`,
                  }}
                ></div>
              </div>
            </div>
            <div className="main-ranges-div mt-4">
              <div className="price-div mt-5">
                <p>Rate of Interest</p>
                <div>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="number"
                      placeholder="15"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={interestRate}
                      onChange={handleChange3}
                    />
                    <InputGroup.Text id="basic-addon2">%</InputGroup.Text>
                  </InputGroup>
                </div>
              </div>
              <div className="price-div">
                <p>1</p> <p>30</p>
              </div>
              <div className="range-bar-container">
                <p
                  className="range-value"
                  style={{
                    left: `calc(${percentageComplete3}% - 30px)`,
                  }}
                >
                  {/* {interestRate}% */}
                </p>
                <input
                  type="range"
                  min="1"
                  max="30"
                  step='0.1'
                  value={interestRate}
                  onChange={handleChange3}
                  className="range-bar"
                />
                <div
                  className="range-bar-color"
                  style={{
                    width: `${percentageComplete3}%`,
                  }}
                ></div>
              </div>
            </div>
            <div className="main-ranges-div">
              <div className="price-div mt-5">
                <p>Loan Term</p>
                <div>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="number"
                      placeholder="12"
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      value={loanTerm.value}
                      onChange={handleLoanTermChange}
                    />
                    {/* <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${loanTerm.unit === 'year' ? 'active' : ''}`}
                          onClick={() => handleLoanTermUnitChange('year')}
                        >
                          yrs
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className={`nav-link ${loanTerm.unit === 'month' ? 'active' : ''}`}
                          onClick={() => handleLoanTermUnitChange('month')}
                        >
                          mos
                        </button>
                      </li>
                    </ul> */}
                    <InputGroup.Text id="basic-addon31" onClick={() => handleLoanTermUnitChange('year')}>Yr</InputGroup.Text>
                    <InputGroup.Text id="basic-addon32" onClick={() => handleLoanTermUnitChange('month')}>Mo</InputGroup.Text>
                  </InputGroup>
                </div>
              </div>
              <div className="price-div">
                <p>1</p> <p>{loanTerm.unit === 'year' ? '40' : '480'}</p>
              </div>
              <div className="range-bar-container">
                <p
                  className="range-value"
                  style={{
                    left: `calc(${percentageComplete4}% - 30px)`,
                  }}
                >
                  {/* {loanTerm.value} {loanTerm.unit} */}
                </p>
                <input
                  type="range"
                  min="1"
                  max={loanTerm.unit === 'year' ? '40' : '480'}
                  value={loanTerm.value}
                  onChange={handleLoanTermChange}
                  className="range-bar"
                />
                <div
                  className="range-bar-color"
                  style={{
                    width: `${percentageComplete4}%`,
                  }}
                ></div>
              </div>
            </div>
          </div>
          <div className="calculation-div mt-5">
            <div className="row">
              <div className="col-xxl-9 col-xl-9 col-lg-12 col-md-12">
                <div className="loan-table">
                  <Table>
                    <thead>
                      <tr>
                        <th>Total Investments</th>
                        <th>Total Savings accumulated</th>
                        <th>Total Interest earned</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>₹{totalInvestment}</td>
                        <td>₹{totalSaving}</td>
                        <td>₹{totalInterest}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SeventhTab;
