import React, { useState } from 'react';
import "./UploadLinkModal.css";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import ResetModal from '../../../JP-Common-Elements/Jp-modals/ResetModal/ResetModal';

library.add(fas);

function UploadLinkModal(props) {
  const [modalEmail, setModalEmail] = useState(false);
  const openEmail = (redirectUrl) => {
    setModalEmail(true);

    setTimeout(() => {
      window.location = redirectUrl;
      setModalEmail(false);
    }, 3000)
  }
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="UplaodLink"
      >
        <Modal.Body>
          <div className="form-field">
            <div className="heading-holder">
              <h4 className="">Upload Link</h4>
            </div>
          </div>

          <div className="form-container">
            <div className="">
              <div className="row">
                <div className="col-md-12">
                  <Form className="">
                    <Form.Group controlId="">
                      <div className="form-control-holder">
                        <Form.Control
                          as="textarea"
                          rows={1}
                          type="text"
                          name="Message"
                          placeholder="Attach Link"
                        />
                        <div className="icon-holder">
                          <FontAwesomeIcon className="font-icon" icon="fa-solid fa-paperclip" />
                        </div>
                      </div>
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>

            <div>
              <div className="row">
                <div className="col-md-12">
                  <Form className="form-field">
                    <Form.Group controlId="">
                      <Form.Control type="text" name="" placeholder="Title" />
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>

            <div>
              <div className="row">
                <div className="col-md-12">
                  <Form className="form-field">
                    <Form.Group controlId="">
                      <Form.Control
                        as="textarea"
                        rows={1}
                        type="text"
                        name="Message"
                        placeholder="Type note if any"
                      />
                    </Form.Group>
                  </Form>
                </div>
              </div>
            </div>

            <div className="Jobseeker_step_modal_section">
              <div className="close-save-btn-holder form-field">
                <div className="Close-btn-holder me-lg-0 me-md-2 me-sm-2 me-2">
                  <Button
                    className="close-btn"
                    type="button"
                    onClick={props.onHide}
                  >
                    Cancel
                  </Button>
                </div>

                <div className="apply-btn-holder">
                  <Button className="apply_now_btn" type="button" onClick={() => { openEmail("/job-portal/jobseeker/myprofile"); props.onHide(); }} >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>

        </Modal.Body>

      </Modal>
      <ResetModal show={modalEmail}
        onHide={() => setModalEmail(false)}
        text={"Link Uploaded Successfully!"}
      />
    </>
  );
}

export default UploadLinkModal;
