
import './Fteam.css'
import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import parse from 'html-react-parser'
const Fteam = () => {
    const [modalShow, setModalShow] = useState(false);
    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/finance/about-us/f-team`);
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data);


    useEffect(() => {
        getDataAll();
    }, []);
    return (
        <>
            {data && data?.length > 0 &&
                <section className='finance-team'>
                    <div className='container'>
                        <div className='heading text-center'>
                            <p className='sub-titles mt-4'>Teams</p>
                            <h5 className='titles mb-4'>Follow the Leader</h5>
                            <p className='descrptn'>Check out our vibrant teams committed to making your financial path easier. Our Insurance Team makes sure you're insured with customized plans, and our Loans Team specializes in creating solutions to match your borrowing needs. Count on us to provide thorough financial support, paving the way for a safe and secure transition to financial well-being. </p>
                        </div>


                        <Swiper
                            spaceBetween={100}
                            pagination={{
                                clickable: true,

                            }}
                            modules={[Pagination]}
                            className="mySwiper"
                            breakpoints={{
                                576: { slidesPerView: 1 },
                                768: { slidesPerView: 2 },
                                1024: { slidesPerView: 2 },
                                1200: { slidesPerView: 2 },
                            }}
                        >
                            {data?.map((image, index) => (
                                <SwiperSlide>
                                    {/* <div className='row teamcard-row'>
                                    <div className='col-xxl-10 col-xl-10 col-lg-10 col-md-5 col-12'> */}
                                    <div className='team-card'>
                                        <img
                                            className="member-img"
                                            // src={
                                            //     process.env.PUBLIC_URL +
                                            //     "/assets/Finance/Image/about/sir.png"
                                            // }
                                            src={IMG_URL + image?.image}
                                            alt="Banner"
                                        />

                                        <div className='overlay-content'>
                                            <h3 className='member-name'>{image?.name}</h3>
                                            <p className='position'>{image?.designation}</p>
                                            <p className='hoverdescrptn'>{parse(image?.description) || ""}</p>
                                        </div>
                                    </div>
                                    {/* </div>
                                </div> */}
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        {/* <div className='col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-12'>
                            <div className='team-card'>
                                <img
                                    className="member-img"
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assets/Finance/Image/about/nitin.png"
                                    }
                                    alt="Banner"
                                />
                                <div className='overlay-content'>
                                    <h3 className='member-name title'>Mr. Nitin Kangude</h3>
                                    <p className='position'>(COO), Founder</p>
                                    <p className='hoverdescrptn'>Mr. Nitin Kangude is the Chief Operating Officer and founder at Profcyma who is highly dedicated to his work. His genius is in the simplicity that he brings to the work. He has been working in this field for about more than 5 years now. He looks after the day-to-day operations of this vastly growing industry.</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </section>
            }
        </>
    )
}

export default Fteam