
import './FaboutVison.css'
import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import parse from 'html-react-parser'
import AOS from "aos";
import "aos/dist/aos.css";

const FaboutVison = () => {
    const [modalShow, setModalShow] = useState(false);
    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/finance/about-us/f-card-second`);
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data);


    useEffect(() => {
        getDataAll();
    }, []);

    useEffect(() => {
        AOS.init({ once: true });
    }, [])
    return (
        <>
            {data && data?.length > 0 &&
                <section className='f-about-vision-card'>
                    {data?.map((image, index) => (
                        <div className='container'>
                            <div className={`row ${index % 2 === 0 ? '' : 'flex-row-reverse'}`}>
                                <div className='col-xxl-6 col-xl-6 col-md-6 col-12 ' >
                                    <div className='vision-img-div' data-aos="fade-right" data-aos-duration="3000">
                                        <img
                                            className="vision-img"
                                            src={IMG_URL + image?.image}
                                            alt="Banner"
                                        />
                                    </div>
                                </div>
                                <div className='col-xxl-6 col-xl-6 col-md-6 col-12 '>
                                    <div className='vision-card-div' data-aos="fade-left" data-aos-duration="3000">
                                        <p className='sub-titles'>{image?.sub_name}</p>
                                        <h5 className='titles'>{image?.name}</h5>
                                        <p className='hoverdescrptn'>{parse(image?.description) || ""}</p>
                                        <div className='overlay-icon'>
                                            <img
                                                className="vision-icon"
                                                src={process.env.PUBLIC_URL + "/assets/Finance/Image/about/target.png"}
                                                alt="Banner"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </section>
            }
        </>

    )
}

export default FaboutVison