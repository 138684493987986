import React from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Success from '../Animation/Success';
const SubitFormModal = (props) => {
  return (
    <>
      <Modal
        {...props}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Body>
          <div>
            <Success />
            <p className='mt-3 text-center'>Form Submitted Successfully</p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default SubitFormModal