import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import './FInsuranceAdvantage.css'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
const FInsuranceAdvantage = ({ service_id }) => {
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();

    const { getData, IMG_URL } = useContext(Context);
    const [data, setData] = useState([]);

    const getDataAll = async () => {
        try {
            const response = await getData(`/finance/insurance/f-i-advantages/${service_id}`);
            setData(response?.data);

        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    console.log(data, "dsdsdsdsd");

    useEffect(() => {
        getDataAll();
    }, [service_id]);


    return (
        <>
            {data && data?.length > 0 &&
                <section className='f-insurance-advantage'>
                    <div className='container'>
                        <div className='row'>
                            <div className='hedaing-holder mb-5'>
                                <h2>Advantage</h2>
                            </div>
                            <Swiper
                                breakpoints={{
                                    576: {
                                        slidesPerView: 2,
                                    },

                                    768: {
                                        slidesPerView: 2,
                                    },

                                    1024: {
                                        slidesPerView: 3,
                                    },
                                    1200: {
                                        slidesPerView: 4,
                                    },
                                }}
                                spaceBetween={30}
                                pagination={{
                                    dynamicBullets: true,
                                    clickable: true,
                                }}
                                modules={[Pagination]}
                                className="mySwiper"

                            >
                                {/* Map over the slidesData array to generate SwiperSlide components */}
                                {data?.map((image, index) => (
                                    <SwiperSlide key={index}>
                                        <div className="addcard">
                                            <img className="back-img" src={IMG_URL + image?.image} alt="Banner" />
                                            <div className="bold-text">
                                                <h3>{image?.name}</h3>
                                                <p>{htmlToReactParser.parse(image?.description)}</p>
                                            </div>
                                            <div className="circle-holder">
                                                <img className="icon-img" src={IMG_URL + image?.icon} alt="Banner" />
                                            </div>
                                            <div className="overlay-color"></div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>
                </section>
            }
        </>
    )
}

export default FInsuranceAdvantage