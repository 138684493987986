import React from 'react'
import FaboutBanner from './FaboutBanner/FaboutBanner'
import FaboutCard from './FaboutCard/FaboutCard'
import FaboutVison from './FaboutVison/FaboutVison'
import Fteam from './Fteam/Fteam'

const FAbout = () => {
    return (
        <>
            <FaboutBanner/>
            <FaboutCard/>
            <FaboutVison/>
            <Fteam/>
        </>
    )
}

export default FAbout