import React from 'react'
import JPJobSearch from './JP-Job_search/JPJobSearch'
import Jpmakediffrencebanner from '../homepage/Jpmakediffbanner/Jpmakediffrencebanner'

function JPSearch() {
  return (
   <>
   <Jpmakediffrencebanner />
    <JPJobSearch/>
   </>
  )
}

export default JPSearch