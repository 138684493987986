import React, { useContext, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import "./WeStandOut.css";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { Context } from "../../../../utils/context";
import AOS from "aos";
import "aos/dist/aos.css";

const WeStandOut = () => {

  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);
  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const getDataAll = async () => {
    try {
      const response = await getData(`/finance/home/f-stand-out`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data, "sdsdsdsdsd");

  useEffect(() => {
    getDataAll();
  }, []);

  useEffect(() => {
    AOS.init({ once: true });
  }, [])

  return (
    <>
      {data && data?.length > 0 &&
        <section className="WeStandOut" data-aos="zoom-in" >

          <Container fluid className="p-0">
            <div className="main-section">

              <Container>

                {/* {index % 2 === 0 ? ( */}
                <div className="content">
                  <Row>
                    <Col xxl={4} xl={4} lg={4} className="text-center">
                      <h1 className="Title">
                        <span className="title-border">Wh</span>y We Stand
                        <br></br> Out
                      </h1>
                    </Col>
                    <Col xxl={1} xl={1} lg={1} className="line-row">

                      <div>

                        {
                          data && Array((data?.length) * 4).fill().map((_, index) => (

                            index < (4 * data?.length) &&
                            <div key={index} className="horizontal-line" style={{ backgroundImage: "linear-gradient(to bottom, #a3a3a2 40%, transparent 30%)", height: "22px", width: "2px" }}></div>
                          ))
                        }

                      </div>


                    </Col>
                    <Col xxl={7} xl={7} lg={7}>
                      {data?.map((westand, index) => (
                        <>
                          {index % 2 === 0 ? (
                            <>
                              <div className="Notes-Section">
                                <div className="orange-box ">
                                  <div className="orange-box-content">
                                    <h1 className="heading-box">{westand?.name}</h1>
                                    <p className="text-box">
                                      {htmlToReactParser.parse(westand?.description)}
                                    </p>
                                  </div>
                                </div>
                              </div>

                            </>
                          ) : (
                            <>

                              <div className="Notes-Section">
                                <div className="white-box mt-2">
                                  <div className="orange-box-content">
                                    <h1 className="heading-box">{westand?.name}</h1>
                                    <p className="text-box">
                                      {htmlToReactParser.parse(westand?.description)}
                                    </p>
                                    {/* <div class="horizontal-line"></div> */}
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </>
                      ))}

                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          </Container >

        </section >
      }
    </>
  );
};

export default WeStandOut;
