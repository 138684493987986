import React, { useContext, useState, useEffect } from 'react'
import './Jpfooter.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "react-router-dom";
import { Context } from '../../../utils/context';

const Jpfooter = () => {


    const { getData, postData, IMG_URL, signin, usertype, userdata } = useContext(Context);
    console.log(usertype, "footer");

    return (
        <>
            <section className="jpfooter desktop-view-footer">
                <div className="container">
                    <div className="row footer_row_main  justify-content-center pt-4 pb-2">
                        <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12 links">
                            <div className='footer_heading_section'>
                                <Link className='/job-portal' to=""><img className='jb-foot-logo' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/Logo/profcyma.png'} /></Link>
                            </div>
                        </div>
                        <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 links">
                            <div className="text-holder">
                            </div>
                            <div className="row footer_upper_content">
                                <div className=" col-xl-3 col-lg-3 col-sm-6 col-6">
                                    <div className="text-holder">
                                        <h6>Quick Links</h6>
                                        <Link to='/job-portal/home'><p>Home</p></Link>
                                        <Link to='/job-portal/blog'><p>Blogs</p></Link>
                                        <Link to='/'><p>About Us</p></Link>
                                        <Link to='/job-portal/jobseeker/terms&condition'><p>Terms & Conditions</p></Link>
                                        <Link to='/job-portal/employer/privacypolicy'><p>Privacy Policy</p></Link>
                                        <Link to='/job-portal/contact'><p>Contact Us</p></Link>
                                    </div>
                                </div>
                                {/* {usertype === "Employer" ? (
                                <div className=" col-xl-3 col-lg-3 col-sm-6 col-12">
                                    <div className="text-holder">
                                        <h6>Employer</h6>
                                        <Link to='/job-portal/employer/dashboard'><p>Employer Home</p></Link>
                                        <Link to='/job-portal/employer/postjob'><p>Post Job for Free</p></Link>
                                        <Link to='/job-portal/employer/dashboard'><p>Campus Recruitment</p></Link>
                                        <Link to='/job-portal/employer/terms&condition'><p>Terms & Conditions</p></Link>
                                        <Link to='/job-portal/employer/privacypolicy'><p>Privacy Policy</p></Link>
                                        <Link to='/job-portal/employer/contact'><p>Contact Us</p></Link>
                                    </div>
                                </div>
                                
                                ):(
                                <div className=" col-xl-3 col-lg-3 col-sm-6 col-12">
                                    <div className="text-holder mt-xl-0 mt-lg-0 mt-md-3">
                                        <h6>Job Seeker</h6>
                                        <Link to='/job-portal/sign-in'><p>Job Seeker Home</p></Link>
                                        <Link to='/job-portal/jobseeker/myprofile'><p>Upload Your CV</p></Link>
                                        <Link to='/job-portal/jobseeker/dashboard'><p>Popular Jobs</p></Link>
                                        <Link to='/job-portal/jobseeker/terms&condition'><p>Terms & Conditions</p></Link>
                                        <Link to='/job-portal/jobseeker/privacypolicy'><p>Privacy Policy</p></Link>
                                        <Link to='/job-portal/contact'><p>Contact Us</p></Link>
                                    </div>
                                </div>
                                )} */}

                                {/* <div className="row footer_upper_content"> */}
                                {usertype === "Employer" ? (
                                    <div className="col-xl-3 col-lg-3 col-sm-6 col-6">
                                        <div className="text-holder">
                                            <h6>Employer</h6>
                                            <Link to='/job-portal/sign-in'><p>Employer Home</p></Link>
                                            <Link to='/job-portal/employer/postjob'><p>Post Job for Free</p></Link>
                                            <Link to='/job-portal/employer/dashboard'><p>Campus Recruitment</p></Link>
                                            <Link to='/job-portal/employer/terms&condition'><p>Terms & Conditions</p></Link>
                                            <Link to='/job-portal/employer/privacypolicy'><p>Privacy Policy</p></Link>
                                            <Link to='/job-portal/employer/contact'><p>Contact Us</p></Link>
                                        </div>
                                    </div>
                                ) : (
                                    usertype === "Jobseeker" ? (
                                        <div className=" col-xl-3 col-lg-3 col-sm-6 col-12">
                                            <div className="text-holder mt-xl-0 mt-lg-0 mt-md-3">
                                                <h6>Job Seeker</h6>
                                                <Link to='/job-portal/sign-in'><p>Job Seeker Home</p></Link>
                                                <Link to='/job-portal/jobseeker/myprofile'><p>Upload Your CV</p></Link>
                                                <Link to='/job-portal/jobseeker/dashboard'><p>Popular Jobs</p></Link>
                                                <Link to='/job-portal/jobseeker/terms&condition'><p>Terms & Conditions</p></Link>
                                                <Link to='/job-portal/jobseeker/privacypolicy'><p>Privacy Policy</p></Link>
                                                <Link to='/job-portal/contact'><p>Contact Us</p></Link>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            {/* Render Employer links */}
                                            <div className=" col-xl-3 col-lg-3 col-sm-6 col-12">
                                                <div className="text-holder">
                                                    <h6>Employer</h6>
                                                    <Link to='/job-portal/employer/dashboard'><p>Employer Home</p></Link>
                                                    <Link to='/job-portal/employer/postjob'><p>Post Job for Free</p></Link>
                                                    <Link to='/job-portal/employer/dashboard'><p>Campus Recruitment</p></Link>
                                                    <Link to='/job-portal/employer/terms&condition'><p>Terms & Conditions</p></Link>
                                                    <Link to='/job-portal/employer/privacypolicy'><p>Privacy Policy</p></Link>
                                                    <Link to='/job-portal/employer/contact'><p>Contact Us</p></Link>
                                                </div>
                                            </div>

                                            <div className=" col-xl-3 col-lg-3 col-sm-6 col-12">
                                                <div className="text-holder mt-xl-0 mt-lg-0 mt-md-3">
                                                    <h6>Job Seeker</h6>
                                                    <Link to='/job-portal/sign-in'><p>Job Seeker Home</p></Link>
                                                    <Link to='/job-portal/jobseeker/myprofile'><p>Upload Your CV</p></Link>
                                                    <Link to='/job-portal/jobseeker/dashboard'><p>Popular Jobs</p></Link>
                                                    <Link to='/job-portal/jobseeker/terms&condition'><p>Terms & Conditions</p></Link>
                                                    <Link to='/job-portal/jobseeker/privacypolicy'><p>Privacy Policy</p></Link>
                                                    <Link to='/job-portal/contact'><p>Contact Us</p></Link>
                                                </div>
                                            </div>
                                        </>
                                    )
                                )}
                                {/* </div> */}

                                <div className="col-xl-3 col-lg-3 col-sm-6 col-6">
                                    <div className="text-holder mt-xl-0 mt-lg-0 mt-md-3">
                                        <h6> Contact</h6>
                                    </div>
                                    <div className="text-holder">
                                        <div className="text d-flex">
                                            <div className='icc-div'>
                                                <img className='contt-iccc' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/Footer/location.png'} />
                                            </div>
                                            <span>1st Floor, Office No 1, M No. 734, Pashan Rd, opp. High Class Society, near Suryadatta College of Management, Patil Nagar, Bavdhan, Pune, Maharashtra 411021</span>
                                        </div>

                                        <div className="text d-flex">
                                            <div className='icc-div'>
                                                <img className='contt-iccc' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/Footer/location.png'} />
                                            </div>
                                            <span>Office No: 510 5th floor, Sky Max Mall, Datta Mandir Chowk, Viman Nagar Pune, 411014.</span>
                                        </div>

                                        <div className="text d-flex">
                                            <div className='icc-div'>
                                                <img className='contt-iccc' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/Footer/call.png'} />
                                            </div>
                                            <span>000 - 2222222 / 3333333</span>
                                        </div>

                                        <div className="text d-flex">
                                            <div className='icc-div'>
                                                <img className='contt-iccc' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/Footer/email.png'} />
                                            </div>
                                            <span>info@profcymaglobal.com</span>
                                        </div>
                                    </div>

                                    <div className='foot-social-icons'>
                                        <div className='d-flex'>
                                            <div>
                                                <Link to="https://www.linkedin.com/company/profcyma-global-solutions" target="_blank">
                                                    <img className='social-iccc' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/Footer/linkedin.png'} />
                                                </Link>
                                            </div>
                                            <div>
                                                <Link to="https://www.facebook.com/profcymaglobal" target="_blank">
                                                    <img className='social-iccc' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/Footer/facebook.png'} />
                                                </Link>
                                            </div>
                                            <div>
                                                <Link to="https://www.instagram.com/profcymaglobal" target="_blank">
                                                    <img className='social-iccc' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/Footer/instagram.png'} />
                                                </Link>
                                            </div>
                                            <div>
                                                <Link to="https://twitter.com/Profcymaglobal" target="_blank">
                                                    <img className='social-iccc' src={process.env.PUBLIC_URL + '/assets/Job-portal/Images/Footer/twitter.png'} />
                                                </Link>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                        </div>
                        <div className='horizantal_line'></div>
                    </div>
                    <div className="Footer_bottom_info text-center">

                        <span className='copr-ppp'>
                            ©2024. All rights reserved | Developed by
                            
                            <Link to="https://profcymaglobal.com/" target="_blank" rel="noopener noreferrer">
                                    <span className='prof-txtttt'>Profcyma</span>
                            </Link>
                        </span>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Jpfooter