import React from 'react'
import JobseekersettingBanner from './JobseekersettingBanner/JobseekersettingBanner'
import JobseekerAccountManagement from './AccountManagement/JobseekerAccountManagement'

function EmpSetting() {
  return (
   <>
   <JobseekersettingBanner/>
   <JobseekerAccountManagement/>
   </>
  )
}

export default EmpSetting