import React, { useContext, useEffect, useState } from "react";
import "./JPBlogCard.css";
import Card from "react-bootstrap/Card";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import JPOnlineDegree from "../../JP-Common-Elements/JP-Addvertise/Jp-Online-Specilisation-Addvertise/JPOnlineDegree/JPOnlineDegree"
import UpdateAccountAddvertise from "../../JP-Common-Elements/JP-Addvertise/JP-Update-Account-Addvertise/Update-Account-Addvertise/UpdateAccountAddvertise"
import { Link } from "react-router-dom";
import BlogModal from "../../JP-Common-Elements/Jp-modals/BlogModal/BlogModal.js";
import { Context } from "../../../../utils/context";
import { getBlog } from "../../../../utils/apis/common/Common";

library.add(fas);
const JPBlogCard = () => {
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const [blogs, setBlog] = useState();
  const [data, setData] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const { getData, IMG_URL } = useContext(Context);

  const getproject = async () => {
    const res = await getBlog();
    if (res?.success) {
      setBlog(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getproject();
  }, []);

  function isToday(someDate) {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  }
  const cardData = [
    {
      title: "Get New Trending Banking Tips",
      imageSrc: "/assets/Job-portal/Images/blog/card1.png",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare pellentesque sollicitudin. Suspendisse potenti. Fusce ex risus, iaculis sit amet...",
      admin: "Admin",
      date: "Jan 2, 2024",
    },
    {
      title: "21 Job Interview Tips: How To Make a Great Impression",
      imageSrc: "/assets/Job-portal/Images/blog/card2.png",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare pellentesque sollicitudin. Suspendisse potenti. Fusce ex risus, iaculis sit amet...",
      admin: "Admin",
      date: "Jan 2, 2024",
    },
    {
      title: "Get New Market Trending Coding Tips",
      imageSrc: "/assets/Job-portal/Images/blog/card3.png",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare pellentesque sollicitudin. Suspendisse potenti. Fusce ex risus, iaculis sit amet...",
      admin: "Admin",
      date: "Jan 2, 2024",
    },
    {
      title: "21 Job Interview Tips: How To Make a Great Impression",
      imageSrc: "/assets/Job-portal/Images/blog/card4.png",
      content:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque ornare pellentesque sollicitudin. Suspendisse potenti. Fusce ex risus, iaculis sit amet...",
      admin: "Admin",
      date: "Jan 2, 2024",
    },
  ];
  return (
    <>
      <section className="jp-blog-card">
        <div className="container">
          <div className="row">
            <div className="col-lg-9">
              <div className="row">
                {/* {cardData.map((card, index) => ( */}
                {blogs?.map((data) => (
                  <div className="col-lg-6 col-md-6">
                    <Card className="mb-4">
                      <div className="top-card">
                        <Card.Img
                          variant="top"
                          src={IMG_URL + data?.image}
                          className="top-img"
                        />
                      </div>
                      <Card.Body>
                        <Card.Title>{data?.title}</Card.Title>
                        <Card.Text>{htmlToReactParser.parse(data?.description)}</Card.Text>
                        <div className="row">
                          <div className="col-6">
                            <div className="d-flex">
                              <div className="circle-holder me-2">
                                <FontAwesomeIcon
                                  icon="fa-solid fa-user"
                                  className="fa-user-icon"
                                />
                              </div>
                              <div className="admin-user">
                                <p>Admin</p>
                                <span>{" "}
                                  {isToday(new Date(data?.createdAt))
                                    ? "Today"
                                    : new Date(
                                      data?.createdAt
                                    ).toLocaleDateString("en-GB", {
                                      day: "2-digit",
                                      month: "short",
                                      year: "2-digit",
                                    })}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-6">
                            <div className="read-holder text-end">
                              {/* <Link to={"/job-portal/blog/innerpage"}> */}
                              <button className=" btn-read" onClick={() => {
                                setData(data);
                                setModalShow(true);
                              }}>
                                Read article
                              </button>
                              {/* </Link> */}

                            </div>
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
                {/* ))} */}
              </div>
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-6 col-sm-6 mb-5">
                  <UpdateAccountAddvertise />
                </div>

                <div className="col-xl-12 col-lg-12 col-md-6 col-sm-6">
                  <JPOnlineDegree
                    screen={"blog"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <BlogModal show={modalShow} onHide={() => setModalShow(false)} data={data} setData={setData} />
      </section>
    </>
  );
};

export default JPBlogCard;
