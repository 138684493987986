import React, { useEffect, useContext, useState } from "react";
import "./EmploymentModal.css"
import { Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CommonModal from "../../CommonModal/CommonModal";
import Select from "react-select";
import { Link } from "react-router-dom";
import { useForm, Controller, SubmitHandler, useFieldArray, } from "react-hook-form";
import classNames from "classnames"
import { RegxExpression } from "../../../../../../utils/apis/common/Common";
import { allDepartment, allJobType } from "../../../../../../utils/apis/common/Common";
import { Context } from "../../../../../../utils/context";
import { EmploymentPost, getEmployment } from "../../../../../../utils/apis/jobseekar/jobseekar";
import Award from "./CompanyAward/Award";



const EmploymentModal = ({ show, onHide, jobSeekarDetailsID }) => {
  const [startDate, setStartDate] = useState();

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];

  const { postData, getData, Select2Data, IMG_URL } = useContext(Context);
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const { append, fields, remove } = useFieldArray({
    control,
    name: "data",
  });

  const [data, setData] = useState([]);

  const getEmploymentDetails = async () => {
    const res = await getEmployment(jobSeekarDetailsID);
    if (res?.success) {
      reset({ data: [] });
      setData(res?.data)
      if (res?.data?.length > 0) {
        const EmploymentDetails = res?.data;
        EmploymentDetails?.forEach((item, index) => {
          append({
            company_name: item?.company_name,
            designation: item?.designation,
            department_id: { value: item?.department?.id, label: item?.department?.name },
            job_type_id: { value: item?.job_type?.id, label: item?.job_type?.name },
            job_responsibilities: item?.job_responsibilities,
            time_from: item?.time_from,
            time_to: item?.time_to,
            annual_salary: item?.annual_salary,
          });
        });
      } else {
        append({
          company_name: "",
          designation: "",
          department_id: "",
          job_type_id: "",
          job_responsibilities: "",
          time_from: "",
          time_to: "",
          annual_salary: "",
        })
      }
    }
  };

  // useEffect(() => {
  //   getEmploymentDetails();
  //   window.scrollTo(0, 0); // Scroll to the top of the page
  // }, []);

  const [awardCount, setAwardCount] = useState(0);

  const [departmentData, setDepartmentData] = useState([]);
  const getDepartment = async () => {
    const res = await allDepartment();
    if (res?.success) {
      setDepartmentData(await Select2Data(res?.data, "department_id"));
    }
  };


  const [jobTypeData, setJobTypeData] = useState();
  const getJobTypeData = async () => {
    const res = await allJobType();
    if (res?.success) {
      setJobTypeData(await Select2Data(res?.data, "job_type_id"));
    }
  };

  const [error, setErrors] = useState("");

  const handlenext = async () => {
    // nextStep();
  };

  const onSubmit = async (d) => {
    d.personal_details_id = jobSeekarDetailsID
    const res = await EmploymentPost(d);
    if (res?.success) {

    }
  }


  useEffect(() => {
    getDepartment();
    getJobTypeData();
    getEmploymentDetails();
    window.scrollTo(0, 0);
  }, [jobSeekarDetailsID]);


  return (
    <>
      <CommonModal show={show} onHide={onHide}>
        <div className="Jobseeker_step_modal_section ">
          <div className="EmploymentModal">
            <div className="heading-holder">

              <h6>Employment Details</h6>

            </div>

            <div className="personal-details-form  employee-details-step">
              {/* form-holder */}
              <Form onSubmit={handleSubmit(onSubmit)}>
                <div className="details-form-holder">
                  <div className="form-container">
                    {fields?.map((item, index) => (
                      <>
                        {/* Company Name*/}
                        <div className=" ">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="">
                                <Form.Group controlId="">
                                  <Form.Label className="">Company Name</Form.Label>

                                  <Form.Control
                                    type="text"
                                    name={`data[${index}].company_name`}
                                    placeholder="Enter Company Name"
                                    defaultValue={item.company_name}
                                    {...register(`data[${index}].company_name`, {
                                      required: "Education Level is Required",
                                    })}
                                    className={classNames("", {
                                      "is-invalid": errors?.company_name,
                                      // "is-valid": getValues("company_name"),
                                    })}
                                    onKeyDown={(event) => {
                                      if (!RegxExpression.name.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Designation/Job title: */}
                        <div className="">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-field">
                                <Form.Group controlId="">
                                  <Form.Label className="">
                                    Designation/Job title:
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    name={`data[${index}].designation`}
                                    placeholder="Enter Designation/Job title"
                                    defaultValue={item.designation}
                                    {...register(`data[${index}].designation`, {
                                      required: "Education Level is Required",
                                    })}
                                    className={classNames("", {
                                      "is-invalid": errors?.designation,
                                      // "is-valid": getValues("education_level"),
                                    })}
                                    onKeyDown={(event) => {
                                      if (!RegxExpression.name.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Department: , Job Type: */}
                        <div className="">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-field">
                                <Form.Group controlId="">
                                  <Form.Label className="">Department:</Form.Label>
                                  <div>
                                    {/* <Select options={options} /> */}
                                    <Controller
                                      name={`data[${index}].department_id`}
                                      {...register(`data[${index}].department_id`, {
                                        required: "DepartMent is required",
                                      })}
                                      control={control}
                                      render={({ field }) => (
                                        <Select
                                          styles={{
                                            control: (baseStyles) => ({
                                              ...baseStyles,
                                              borderColor: errors.department_id
                                                ? "red"
                                                : baseStyles,
                                            }),
                                          }}
                                          {...field}
                                          options={departmentData}
                                        />
                                      )}
                                    />
                                  </div>
                                </Form.Group>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-field">
                                <Form.Group controlId="">
                                  <Form.Label className="">Job Type:</Form.Label>

                                  <div>
                                    {/* <Select options={options} /> */}
                                    <Controller
                                      name={`data[${index}].job_type_id`}
                                      {...register(`data[${index}].job_type_id`, {
                                        required: "Job Type is required",
                                      })}
                                      control={control}
                                      render={({ field }) => (
                                        <Select
                                          styles={{
                                            control: (baseStyles) => ({
                                              ...baseStyles,
                                              borderColor: errors.job_type_id
                                                ? "red"
                                                : baseStyles,
                                            }),
                                          }}
                                          {...field}
                                          options={jobTypeData}
                                        />
                                      )}
                                    />
                                  </div>
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/*  Job Responsibilities: */}
                        <div className="">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-field ">
                                <Form.Group controlId="">
                                  <Form.Label className="">
                                    Job Responsibilities:
                                  </Form.Label>

                                  <Form.Control
                                    as="textarea"
                                    placeholder="Enter Job Responsibilities"
                                    rows={3}
                                    name={`data[${index}].job_responsibilities`}
                                    defaultValue={item.job_responsibilities}
                                    {...register(`data[${index}].job_responsibilities`, {
                                      required: "Job Responsibilities is Required",
                                    })}
                                    className={classNames("", {
                                      "is-invalid": errors?.job_responsibilities,
                                      // "is-valid": getValues("education_level"),
                                    })}
                                    onKeyDown={(event) => {
                                      if (!RegxExpression.name.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}

                                  />
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Time Period (from): */}
                        <div className="">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-field">
                                <Form.Group controlId="">
                                  <Form.Label className="">Time Period (from):</Form.Label>

                                  <div>
                                    {/* <Select options={options} /> */}
                                    <Form.Control
                                      type="date"
                                      placeholder="Enter Date Of Joining"

                                      name={`data[${index}].time_from`}
                                      {...register(`data[${index}].time_from`, {
                                        required: "Starting Date required",
                                      })}
                                      className={classNames("", {
                                        "is-invalid": errors?.time_from,
                                      })}
                                    />
                                  </div>
                                </Form.Group>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-field">
                                <Form.Group controlId="">
                                  <Form.Label className="">Time Period (To):</Form.Label>

                                  <div>
                                    {/* <Select options={options} /> */}
                                    <Form.Control
                                      type="date"
                                      placeholder="Enter Date Of Joining"
                                      name={`data[${index}].time_to`}
                                      {...register(`data[${index}].time_to`, {
                                        required: "Starting Date required",
                                      })}
                                      className={classNames("", {
                                        "is-invalid": errors?.time_to,
                                      })}
                                    />
                                  </div>
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/*  Annual salary/CTC(₹):*/}
                        <div className="Percentages-Add-Education">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-field">
                                <Form.Group controlId="">
                                  <Form.Label className="">
                                    Annual salary/CTC(₹):
                                  </Form.Label>

                                  <Form.Control
                                    type="text"
                                    placeholder="Enter Annual salary/CTC(₹):"
                                    name={`data[${index}].annual_salary`}
                                    defaultValue={item.annual_salary}
                                    {...register(`data[${index}].annual_salary`, {
                                      required: "Education Level is Required",
                                    })}
                                    className={classNames("", {
                                      "is-invalid": errors?.annual_salary,
                                      // "is-valid": getValues("education_level"),
                                    })}
                                    onKeyDown={(event) => {
                                      if (!RegxExpression.phone.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                  />
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                          {index !== 0 && (
                            <div className="addremoveadress">
                              <div className="text-end Addnewadresstxt">
                                <p className="" onClick={() => remove(index)}>
                                  Remove Job{" "}
                                  {/* <FontAwesomeIcon icon="fa-solid fa-minus" /> */}
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    ))}


                    <div className="Percentages-Add-Education">
                      <div className="row">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                          <div className="Add-education-btn-holder form-field">
                            <div className="apply-btn-holder">
                              <Button className="apply_now_btn" type="button" onClick={() =>
                                append({
                                  company_name: "",
                                  designation: "",
                                  department_id: "",
                                  job_type_id: "",
                                  job_responsibilities: "",
                                  time_from: "",
                                  time_to: "",
                                  annual_salary: "",
                                })
                              }>
                                Add Job
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*Previous Job*/}
                    <div className="Percentages-Add-Education">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-field">
                            <Form.Group controlId="">
                              <Form.Label className="">Previous Job</Form.Label>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*Previous Job card*/}

                    <div className="Percentages-Add-Education">
                      <div className="row">
                        <div className="col-md-3">

                          < div className="form-field">
                            <Form.Group controlId="">
                              <div className="previous-job-details">
                                <div className="job-content-holder">
                                  <h1>{data && data[0]?.designation}</h1>
                                  <p>{data && data[0]?.company_name}</p>
                                  <p>
                                    {data && data[0]?.time_from && new Date(data[0].time_from).getFullYear()} -
                                    {data && data[0]?.time_to && new Date(data[0].time_to).getFullYear()}
                                  </p>
                                </div>
                              </div>
                            </Form.Group>
                          </div>



                        </div >
                      </div >
                    </div >

                    {/*Save */}
                    < div className="form-field" >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="apply-btn-holder">
                            <Button className="apply_now_btn" type="submit">Save</Button>
                          </div>
                        </div>
                      </div>
                    </div >
                  </div >

                  <Award jobSeekarDetailsID={jobSeekarDetailsID} setAwardCount={setAwardCount} />

                  {/* 
                <div
                  className="SaveAndNext apply-btn-holder"
                  style={{ textAlign: "center" }}
                >
                  <Button
                    onClick={handlenext}
                    className="apply_now_btn tabs-btn"
                    style={{ margin: "auto" }}
                    type="button"
                  >
                    Save & Next
                  </Button>
                </div> */}
                </div >
              </Form >
            </div >
          </div >


          {/* close save btn */}
          < div className="close-save-btn-holder form-field" >
            <div className="Close-btn-holder">
              <Button className="close-btn" onClick={onHide}>
                Cancel
              </Button>
            </div>

            <div className="apply-btn-holder">

              <Button className="apply_now_btn" onClick={onHide}>Save & Close</Button>

            </div>
          </div >
        </div >
      </CommonModal >
    </>
  );
};

export default EmploymentModal;
