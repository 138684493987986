


import React, { useState, useEffect } from "react";
import "./CreditScore.css";
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FirstCredit from "../CreditReportModal/FirstCredit/FirstCredit";
import AOS from "aos";
import "aos/dist/aos.css";

const CreditScore = (props) => {
  const [modalShow, setModalShow] = React.useState(false);

  useEffect(() => {
    AOS.init({ once: true });
  }, [])


  return (
    <>
      <section className="CreditScore-section">
        <Container>
          <div className="CreditScore-main">
            <Row>
              <Col
                xxl={6}
                xl={6}
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="my-auto"
              >
                <div className="credit-txt" data-aos="fade-right" data-aos-duration="3000">
                  <div className="tops-txt">
                    <p className="check-txt">Check your Credit score</p>
                    <div className="rights-line"></div>
                  </div>
                  <div>
                    <h2>
                      Monitor Your Financial Health :<br />
                      Check Credit Score.
                    </h2>
                    <p className="discover">
                      Discover financial confidence – check your credit score
                      effortlessly for a secure financial future.
                    </p>
                  </div>
                  <div className="getreport">
                    <button className="getreportbutton" onClick={() => setModalShow(true)}>
                      Get Free Credit Report 
                      <FontAwesomeIcon
                        icon="fa-solid fa-angles-right"
                        className="arrow-icon "
                      />
                    </button>
                    <FirstCredit
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    />
                  </div>
                </div>
              </Col>
              <Col xxl={6} xl={6} lg={6} md={6} sm={12} xs={12}>
                <div className="credutmgs" data-aos="fade-left" data-aos-duration="3000">
                  <img
                    className="crd-img"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/Finance/Image/Homepage/creditscore.png"
                    }
                    alt="Logo"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

    </>
  );
};

export default CreditScore;

