import React, { useState, useEffect, useContext } from "react";
import CommonModal from "../../CommonModal/CommonModal";
import { Button, Form, Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { Link } from "react-router-dom";
import {
  useForm,
  Controller,
  SubmitHandler,
  useFieldArray,
} from "react-hook-form";
import { RegxExpression } from "../../../../../../utils/apis/common/Common";
import classNames from "classnames"
import { Errors, Placeholders } from "../../../../../../utils/errors";
import { allLaguage, allPersonalStatus, allPersonalInfo, allProficiency } from "../../../../../../utils/apis/common/Common";
import { Context } from "../../../../../../utils/context";
import SocialMediaLink from "./SocialMediaLink/SocialMediaLink";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";


const PersonalModal = ({ show, onHide }) => {

  const { postData, getData, Select2Data, IMG_URL } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
    reset
  } = useForm();

  const navigate = useNavigate();


  const { append, fields, remove } = useFieldArray({
    control,
    name: "data",
  });

  const [personalStatusId, setPersonalStatusId] = useState("");

  const handleButtonClick = (statusId) => {
    setValue("personal_status_id", statusId);
    setPersonalStatusId(statusId);
  };


  const [personalMoreInfoId, setPersonalMoreInfoId] = useState("");

  const handleMoreButtonClick = (infoId) => {
    setValue("personal_more_info_id", infoId);
    setPersonalMoreInfoId(infoId);
  };

  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    if (value.length >= 12 && value.length <= 12) {
      setIsPhoneNumberValid(true);
    } else {
      setIsPhoneNumberValid(false);
    }
  };

  const [startDate, setStartDate] = useState(new Date());
  const handleDateChange = (date) => {
    setStartDate(date);
  };

  const [languagetData, setLanguageData] = useState([]);
  const getLaguage = async () => {
    const res = await allLaguage();
    if (res?.success) {
      setLanguageData(await Select2Data(res?.data, "language_id"));
    }
  };


  const [proficiencyData, setProficiencyData] = useState([]);
  const getProficiency = async () => {
    const res = await allProficiency();
    if (res?.success) {
      setProficiencyData(await Select2Data(res?.data, "proficiency_id"));
    }
  };

  const [personalData, setpersonalData] = useState([]);
  const getPersonalStatus = async () => {
    const res = await allPersonalStatus();
    if (res?.success) {
      setpersonalData(res?.data);
    }
  };

  const [personalMoreData, setPersonalMoreData] = useState([]);
  const getPersonalMoreInfo = async () => {
    const res = await allPersonalInfo();
    if (res?.success) {
      setPersonalMoreData(res?.data);
    }
  };

  const [personalID, setPersonalID] = useState();
  const onSubmit = async (data) => {
    console.log("datdatdatdatdatdat", data);
    if (personalID) {
      data.personal_update_id = personalID;
    }
    const res = await postData(`/job-portal/job-seeker/profile/profile-details`, data);
    if (res?.success) {
      onHide()
    }
  };


  const today = new Date();
  const eighteenYearsAgo = new Date(today.getFullYear() - 18, today.getMonth(), today.getDate());


  const [userData, setUserData] = useState();

  const getJobSeekarDetails = async () => {
    const res = await getData(`/job-portal/job-seeker/profile/profile-details/${Cookies.get('jobseekar_id')}`);
    if (res?.success) {
      await reset(res?.data)
      setPersonalID(res?.data?.id);
      setUserData(res?.data);

      setPersonalStatusId(res?.data?.personal_status_id);
      setPersonalMoreInfoId(res?.data?.personal_more_info_id);

    }
  }

  console.log("Final getvalue", getValues());

  useEffect(() => {

    getLaguage();
    getPersonalStatus();
    getPersonalMoreInfo();
    getProficiency();
    getJobSeekarDetails();
  }, [])

  const [isDifferentlyAbled, setIsDifferentlyAbled] = useState(false);
  const handleDifferentlyAbledChange = (event) => {
    setIsDifferentlyAbled(event.target.value === 'Yes');
  };

  return (
    <>
      <CommonModal show={show} onHide={onHide}>
        <Modal.Body>
          <div className="heading-holder">
            <h6>Personal Details</h6>
          </div>
          <Form>
            <div className="personal-details-form ">
              {/* form-holder */}
              <div className="details-form-holder">
                <div className="form-container">
                  {/* First Name , Middle Name , Last Name< */}
                  <div className="">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="field-bottom">
                          <Form.Group controlId="First_Name">
                            <Form.Label className="required">First Name</Form.Label>

                            <Form.Control
                              type="text"
                              name="First_Name"
                              placeholder="Enter First Name"
                              {...register("first_name", {
                                required: "First Name is Required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.first_name,
                                "is-valid": getValues("first_name"),
                              })}
                              onKeyDown={(event) => {
                                if (!RegxExpression.name.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </Form.Group>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="field-bottom">
                          <Form.Group controlId="Middle_Name">
                            <Form.Label className="">Middle Name</Form.Label>

                            <Form.Control
                              type="text"
                              name="Middle_Name"
                              placeholder="Enter Middle Name"
                              {...register("middle_name", {
                                // required: "Middle Name is Required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.middle_name,
                                "is-valid": getValues("middle_name"),
                              })}
                              onKeyDown={(event) => {
                                if (!RegxExpression.name.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </Form.Group>
                        </div>
                      </div>

                      <div className="field-bottom">
                        <Form.Group controlId="Last_Name">
                          <Form.Label className="required">Last Name</Form.Label>

                          <Form.Control
                            type="text"
                            name="Last_Name"
                            placeholder="Enter Last Name"
                            {...register("last_name", {
                              required: "Last Name is Required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.last_name,
                              "is-valid": getValues("last_name"),
                            })}
                            onKeyDown={(event) => {
                              if (!RegxExpression.name.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>

                  {/*   Mobile Number , Date of Birth,  Gender*/}
                  <div className="">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-field">
                          <Form.Group controlId="Mobile_Number">
                            <Form.Label className="required">
                              Mobile Number
                            </Form.Label>
                            <div className="get-otp-text">
                              <Form.Control
                                type="text"
                                placeholder="Enter Number"
                                controlId="exampleForm.ControlInput1"
                                name="contact_no"
                                disabled
                                {...register("contact_no", {
                                  // required: "contact number required",
                                  // pattern: {
                                  //   value: /^\d{10}$/,
                                  //   message: "Phone number must be a 10-digit number",
                                  // },
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.contact_no,
                                  "is-valid": getValues("contact_no"),
                                })}
                                onKeyPress={(event) => {
                                  // Get the character code of the pressed key
                                  const charCode = event.which ? event.which : event.keyCode;
                                  // Allow only numeric characters (0-9) and the backspace key (charCode 8)
                                  if (charCode < 48 || charCode > 57) {
                                    event.preventDefault();
                                  }
                                }}
                                maxLength={10}
                              />
                              {errors.contact_no && (
                                <div className="invalid-feedback">
                                  {errors.contact_no.message}
                                </div>
                              )}
                            </div>
                          </Form.Group>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-field">
                          <Form.Group controlId="">
                            <div>
                              <Form.Label className="required">
                                Date of Birth
                              </Form.Label>
                            </div>
                            <div>
                              <Form.Control
                                type="date"
                                placeholder="Enter Date Of Birthday"
                                name="date_of_birth"
                                max={eighteenYearsAgo.toISOString().split('T')[0]}
                                // {...register("date_of_birth", {
                                //   required: "deadline required",
                                // })}
                                {...register("date_of_birth", {
                                  required: "Date of birth is required",
                                  validate: value => {
                                    const selectedDate = new Date(value);
                                    return selectedDate <= eighteenYearsAgo || "You must be at least 18 years old.";
                                  }
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.date_of_birth,
                                })}
                              />
                            </div>
                          </Form.Group>
                        </div>
                      </div>

                      <div className="col-md-4">
                        <div className="form-field check_box">
                          <Form.Group>
                            <Form.Label>Gender</Form.Label>
                            <div className="gender-checkbox-holder">
                              <Form.Check
                                type="radio"
                                label="Female"
                                name="gender"
                                value="female"
                                {...register("gender", {
                                  required: "Please Select Gender",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.gender,
                                })}

                              />
                              <Form.Check
                                type="radio"
                                label="Male"
                                name="gender"
                                value="male"
                                {...register("gender", {
                                  required: "Please Select Gender",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.gender,
                                })}
                              />
                              <Form.Check
                                type="radio"
                                label="other"
                                name="gender"
                                value="other"
                                {...register("gender", {
                                  required: "Please Select Gender",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.gender,
                                })}
                              />
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Job Profile Title, Last Name< */}
                  <div className="">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-field ">
                          <Form.Group controlId="">
                            <Form.Label className="required">
                              Job Profile Title
                            </Form.Label>

                            <Form.Control
                              type="text"
                              name="Profile_Title"
                              placeholder="Enter Profile Title"
                              {...register("Profile_Title", {
                                required: "Profile Name is Required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.Profile_Title,
                                "is-valid": getValues("Profile_Title"),
                              })}
                              onKeyDown={(event) => {
                                if (!RegxExpression.name.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </Form.Group>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-field  radio_box">
                          <Form.Group controlId="">
                            <Form.Label className="required">Career Level</Form.Label>

                            <div className="Career_Lever">
                              <Form.Check
                                type="radio"
                                label="Experienced"
                                name="career_level"
                                value="Experienced"
                                {...register("career_level", {
                                  required: "Please Select Career Level",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.career_level,
                                })}
                              />
                              <Form.Check
                                type="radio"
                                label="Fresher"
                                name="career_level"
                                value="Fresher"
                                {...register("career_level", {
                                  required: "Please Select Career Level",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.career_level,
                                })}
                              />


                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*   Email Id */}
                  <div className="">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-field ">
                          <Form.Group controlId="">
                            <Form.Label className="required">Email Id</Form.Label>

                            <Form.Control
                              type="text"
                              name="email"
                              placeholder="Enter  Email Id"
                              disabled
                              {...register("email", {
                                required: "Email Id is Required",
                                pattern: {
                                  value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                                  message: "Invalid email address",
                                }
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.email,
                                "is-valid": getValues("email"),
                              })}
                            />
                            {errors.email && (
                              <div className="invalid-feedback">
                                {errors.email.message}
                              </div>
                            )}
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*  Differently abled?*/}
                  <div className="">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-field radio_box">
                          <Form.Group controlId="">
                            <div className="label-input-flex">
                              <Form.Label className="">Differently abled?</Form.Label>
                              <Form.Check
                                type="radio"
                                label="No"
                                name="differently abled"
                                value="No"
                                {...register("differently_abled", {
                                  required: "Please Select Atleast One",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.differently_abled,
                                })}
                                onChange={handleDifferentlyAbledChange}
                              />
                              <Form.Check
                                type="radio"
                                label="Yes"
                                name="differently abled"
                                value="Yes"
                                {...register("differently_abled", {
                                  required: "Please Select Atleast One",
                                })}
                                className={classNames("", {
                                  "is-invalid": errors?.differently_abled,
                                })}
                                onChange={handleDifferentlyAbledChange}
                              />
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*   Type of Differently Abled (if any) */}
                  <div className="">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-field ">
                          <Form.Group controlId="">
                            <Form.Label className="">
                              Type of Differently Abled (if any)
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              name="type_of_differently_abled"
                              placeholder="Please Enter Type of Differently Abled"
                              rows={3}
                              {...register("type_of_differently_abled")}
                              className={classNames("", {
                                "is-invalid": errors?.type_of_differently_abled,
                                "is-valid": getValues("type_of_differently_abled"),
                              })}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*   Resume Headline: */}
                  <div className="">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-field ">
                          <Form.Group controlId="">
                            <Form.Label className="">Resume Headline:</Form.Label>

                            <Form.Control
                              as="textarea"
                              name="Resume_HeadLine"
                              placeholder="Please Enter Resume Headline"
                              rows={3}
                              {...register("resume_headline", {
                                required: "Resume Headline is Required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.resume_headline,
                                "is-valid": getValues("resume_headline"),
                              })}
                            // onKeyDown={(event) => {
                            //   if (!RegxExpression.name.test(event.key)) {
                            //     event.preventDefault();
                            //   }
                            // }}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*     Languages: */}
                  <div className="">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-field ">
                          <Form.Group controlId="">
                            <Form.Label className="">Languages:</Form.Label>
                            <div className="Languages-holder">
                              {fields.map((language, index) => (
                                <div className="Languages-row" key={language.id}>
                                  <div className="row">
                                    <div className="col-md-3 col-6">
                                      <div className="select-to-holder">
                                        <Controller
                                          name={`data[${index}].language_id`}
                                          {...register(`data[${index}].language_id`, {
                                            required: "Language is required",
                                          })}
                                          control={control}
                                          render={({ field }) => (
                                            <Select
                                              styles={{
                                                control: (baseStyles) => ({
                                                  ...baseStyles,
                                                  borderColor: errors.language_id
                                                    ? "red"
                                                    : baseStyles,
                                                }),
                                              }}
                                              {...field}
                                              options={languagetData}
                                            // {...field}
                                            // // options={filteredOptions}
                                            // onChange={(selectedOption) => {
                                            //   // field.onChange(selectedOption.value); // Update form control value
                                            //   handleLanguageChange(selectedOption.value, index);
                                            // }}
                                            />
                                          )}
                                        />


                                      </div>
                                    </div>
                                    <div className="col-md-3 col-6">
                                      <div className="select-to-holder">
                                        {/* <Select options={[{ value: language.name, label: language.name }]} /> */}

                                        <Controller
                                          name={`data[${index}].proficiency_id`}
                                          {...register(`data[${index}].proficiency_id`, {
                                            required: "Proficiency is required",
                                          })}
                                          control={control}
                                          render={({ field }) => (
                                            <Select
                                              styles={{
                                                control: (baseStyles) => ({
                                                  ...baseStyles,
                                                  borderColor: errors.proficiency_id
                                                    ? "red"
                                                    : baseStyles,
                                                }),
                                              }}
                                              {...field}
                                              options={proficiencyData}
                                            />
                                          )}
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="Languages-tick-holder check_box">
                                        <Form.Group>
                                          <div className="Languages-tick">
                                            <Form.Check
                                              type="checkbox"
                                              label="Read"
                                              {...register(`data[${index}].read`, {
                                                // required: "Read is required"
                                              })}
                                              className={errors.read ? "is-invalid" : ""}
                                            />
                                            <Form.Check
                                              type="checkbox"
                                              label="Write"
                                              {...register(`data[${index}].write`, {
                                                // required: "write is required"
                                              })}
                                            />
                                            <Form.Check
                                              type="checkbox"
                                              label="Speak"
                                              {...register(`data[${index}].speak`, {
                                                // required: "speak is required"
                                              })}
                                            />
                                          </div>
                                        </Form.Group>
                                      </div>
                                    </div>
                                  </div>
                                  {index !== 0 && (
                                    <div className="addremoveadress">
                                      <div className="text-end Addnewadresstxt">
                                        <p className="" onClick={() => remove(index)}>
                                          Remove Language{" "}
                                          {/* <FontAwesomeIcon icon="fa-solid fa-minus" /> */}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>

                            <div className="AddMoreLanguage">
                              <span onClick={() => append(
                                {
                                  language_id: "",
                                  proficiency_id: "",
                                  read: "",
                                  write: "",
                                  speak: "",
                                },
                              )
                              }>Add More Language's</span>
                            </div>
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* personal-status-section */}
                  <div className="personal-status-section">
                    {/* Personal Status button */}
                    <div className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-field ">
                            <Form.Group controlId="">
                              <Form.Label className="">Personal Status:</Form.Label>

                              <div className="personal-status-buttons">
                                <div className=" ">
                                  {personalData?.map((status, index) => (
                                    <Button
                                      type="button"
                                      key={index}
                                      name="personal_status_id"
                                      className={`status-buttons ${personalStatusId === status?.id ? "active" : ""}`}
                                      onClick={() => handleButtonClick(status?.id)}
                                      // value={personalStatusId}
                                      {...register("personal_status_id", {
                                        required: "Personal status is Required",
                                      })}
                                    >
                                      {status?.name}
                                    </Button>
                                  ))}
                                  {errors.personal_status_id && (
                                    <span classpersonal_status="text-danger">{errors.personal_status_id.message}</span>
                                  )}

                                </div>
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* More Information: button */}
                    <div className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-field ">
                            <Form.Group controlId="">
                              <Form.Label className="">More Information:</Form.Label>

                              <div className="personal-status-buttons">
                                <div className=" ">
                                  {personalMoreData?.map((info, index) => (
                                    <Button
                                      type="button"
                                      key={index}
                                      name="personal_more_info_id"
                                      className={`status-buttons ${personalMoreInfoId === info.id ? "active" : ""}`}
                                      onClick={() => handleMoreButtonClick(info?.id)}
                                      // value={personalMoreInfoId}
                                      {...register("personal_more_info_id", {
                                        required: "Personal More Info is Required",
                                      })}
                                    >
                                      {info.name}
                                    </Button>
                                  ))}
                                  {errors.personal_more_info_id && (
                                    <span classpersonal_info="text-danger">{errors.personal_more_info_id.message}</span>
                                  )}

                                </div>
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*   Current Address: */}
                  <div className="">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-field">
                          <Form.Group controlId="">
                            <div className="address-heading">
                              <h1>Address:</h1>
                            </div>
                            <Form.Label className="">Current Address:</Form.Label>
                            <Form.Control
                              as="textarea"
                              name="Current_Address"
                              placeholder="Enter Current Address"
                              rows={3}
                              {...register("current_address", {
                                required: "Current Address is Required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.current_address,
                                "is-valid": getValues("current_address"),
                              })}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*   Permanent Address: */}
                  <div className="">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-field ">
                          <Form.Group controlId="">
                            <Form.Label className="">Permanent Address:</Form.Label>

                            <Form.Control
                              as="textarea"
                              name="Permanent_Address"
                              placeholder="Enter Permanent Address"
                              rows={3}
                              {...register("permenent_address", {
                                required: "Permanent Address is Required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.permenent_address,
                                "is-valid": getValues("permenent_address"),
                              })}
                            />
                          </Form.Group>
                        </div>
                      </div>
                    </div>
                  </div>

                  <SocialMediaLink
                    register={register}
                    control={control}
                    errors={errors}
                    getValues={getValues}
                    // reset={reset}
                    userData={userData}
                  />


                </div>
              </div>
            </div>

            {/* close save btn */}
            <div className="close-save-btn-holder form-field">
              <div className="Close-btn-holder">
                <Button className="close-btn" onClick={onHide}>
                  Cancel
                </Button>
              </div>

              <div className="apply-btn-holder">
                {/* <Link to={"/job-portal/jobseeker/jobseeker-edit-profile"}> */}
                <Button className="apply_now_btn" type="submit" onClick={handleSubmit(onSubmit)}>Save & Close</Button>
                {/* </Link> */}
              </div>
            </div>
          </Form>
        </Modal.Body>
      </CommonModal>
    </>
  );
};

export default PersonalModal;
