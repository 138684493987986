import React, { useState, useEffect, useContext } from "react";
import Form from "react-bootstrap/Form";
import "../../../Jp-Forms/JPForm.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import JPEmployerRegister from "../JP-Employer_register_form/JPEmployerRegister";
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";
import { faEye, faEyeLowVision, faEyeSlash, fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Context } from "../../../../../../utils/context";
library.add(fas);

function JPJobSeekerRegister({ showJobSeekerRegister }) {
  const [isWhatsAppChecked, setIsWhatsAppChecked] = useState(false);
  const [resume, setResume] = useState(null);
  const [phone, setPhone] = useState("");
  const [showEmployerRegister, setShowEmployerRegister] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [verifyStat, setVerifyStat] = useState(true);
  const [mobileVerify, setMobileVerify] = useState(false);
  const [otpErr, setOtpErr] = useState("");
  const [contactError, setContactError] = useState();
  const [userError, setUserError] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otpSent, setOtpSent] = useState(false);
  const [otpResendSent, setResendOtpSent] = useState(false);
  const [password, setPassword] = useState("")
  const [passwordAgain, setPasswordAgain] = useState("")

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };


  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm();


  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    if (value.length >= 12 && value.length <= 12) {
      setIsPhoneNumberValid(true);
    } else {
      setIsPhoneNumberValid(false);
    }
  };


  const handleOtpChange = (index, value) => {
    if (!isNaN(value) && value !== "") {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 3) {
        setVerifyStat(true);
        document.getElementById(`otpInput-${index + 1}`).focus();
      } else {
        setVerifyStat(false);
      }
    } else if (value === "") {

      setVerifyStat(true);
      const newOtp = [...otp];
      newOtp[index] = "";
      setOtp(newOtp);

      if (index > 0) {
        document.getElementById(`otpInput-${index - 1}`).focus();
      }
    }
  };

  const verifyOTP = async (otp) => {
    if (phoneNumber) {
      const contact_no = phoneNumber;
      const res = await postData(`/without-login/verify-otp/verify-otp`, { contact_no, otp });
      if (res?.success) {
        setMobileVerify(true);
        setShowOtpInput(false);
        setIsPhoneNumberValid(true);
        clearErrors();
        setSeconds(90);
      } else {
        setOtpErr(res.message);
      }
    }
    else {
      setShowOtpInput(true);
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const handleGetOTP = async () => {
    if (isPhoneNumberValid) {
      if (phoneNumber) {
        const contact_no = phoneNumber;
        const res = await postData(`/without-login/verify-otp/otp-send`, { contact_no });
        if (res?.success) {
          console.log(res, "otp");
          setShowOtpInput(true);
          setSeconds(90);
          setOtpSent(true);
          setContactError("");
          setResendOtpSent(true);
          clearErrors();
        }
      } else {
        setError("contact_no", {
          message: "Please enter Valid mobile number",
        });

      }
    } else {
      setContactError('Please enter a valid phone number');
      setShowOtpInput(false);
    }
  };

  // const [error, setErrors] = useState("");

  const [isChecked, setIsChecked] = useState(false);
  const [isTimerExpired, setIsTimerExpired] = useState(false);

  const [emailerror, setemailErrors] = useState("");

  const handleCheckboxChange = (e) => {
    // setIsChecked(e.target.checked);
  };
  // Effect to update countdown every second
  useEffect(() => {
    const decrementTimer = () => {
      if (seconds > 0) {
        setIsTimerExpired(false);
        setSeconds(seconds - 1);
      } else {
        setIsTimerExpired(true);
      }
    };

    const timerInterval = setInterval(decrementTimer, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  }, [seconds]);

  const handleResendCode = () => {
    setShowOtpInput(true);
  };

  const toggleEmployerRegister = () => {
    setShowEmployerRegister(true);
  };


  const navigate = useNavigate();
  const { getData, postData, IMG_URL } = useContext(Context);

  const onSubmit = async (d) => {
    // if (user) {

    if (isPhoneNumberValid) {
      const data = new FormData();
      data.append('resume', d.resume[0]);
      data.append("Profile_Title", d.job_profile);
      data.append("confirm_password", d.confirm_password);
      data.append("password", d.password);
      // data.append("designation", d.designation);
      data.append("email", d.email);
      data.append("first_name", d.first_name);
      data.append("last_name", d.last_name);
      data.append("contact_no", phoneNumber);
      data.append("users_type_id", showJobSeekerRegister);
      setContactError("");
      if (mobileVerify) {
        if (d.password === d.confirm_password) {
          if (isChecked === true) {
            console.log(data);
            const res = await postData(`/job-portal/customer-management/users`, data);
            if (res?.success) {
              navigate("/job-portal/sign-in");
            } else {
              setemailErrors(res?.message);
            }
          }
          else {
            setError("term", {
              message: "Please Select Terms and Conditions",
            });
          }
        } else {
          setError("password", {
            message: "Password Must Match",
          });
        }
      }
      else {
        if (!mobileVerify) {
          setError("contact_no", {
            message: "varify mobile number",
          });
        }
      }
    } else {
      setContactError('Please enter a valid phone number');
    }
  };

  // If showJobSeekerRegister is true, render JPJobSeekerRegister component
  if (showEmployerRegister) {
    return <JPEmployerRegister />;
  }


  return (
    <>
      <div className="jobseeker-form">
        {/* First Name and  Last Name */}
        <Form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="row">
              <div className="col-md-6">
                <Form.Group controlId="First_Name">
                  <Form.Label className="required">First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="First_Name"
                    placeholder="Enter First Name"
                    {...register("first_name", {
                      required: "First Name is required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.first_name,
                    })}
                    onKeyDown={(event) => {
                      if (!/[A-Z-a-z ]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Group>
              </div>

              <div className="col-md-6">
                <Form.Group controlId="Last_Name">
                  <Form.Label className="required">Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    name=""
                    placeholder="Enter Last Name"
                    {...register("last_name", {
                      required: "Last Name is required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.last_name,
                    })}
                    onKeyDown={(event) => {
                      if (!/[A-Z-a-z ]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </div>

          {/* Email */}<div></div>
          <div className="email-id mt-2">
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="Email">
                  <Form.Label className="required">Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="Email"
                    placeholder="Enter Your Email Id"
                    {...register("email", {
                      required: "Email Id required",
                      pattern: {
                        value: /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
                        message: "Email Id Invalid",
                      },
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.email,
                    })}
                  />
                  {emailerror && (
                    <span className="text text-danger">{emailerror}</span>
                  )}
                  <span>
                    You will be getting alerts about relevant jobs in your mail
                  </span>
                </Form.Group>
              </div>
            </div>
          </div>

          {/* Password */}
          <div>
            <div className="row">
              <div className="col-md-6">
                <Form.Group controlId="Password">
                  <Form.Label className="required">Password</Form.Label>
                  <div className="password-input-container">
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Enter Password"
                      name="password"
                      {...register("password", {
                        required: "Password is required",
                        minLength: {
                          value: 8,
                          message: "Password must be at least 8 characters long",
                        },
                        pattern: {
                          // value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])\S.{8,}$/,
                          value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\w!@#$%^&*()-+=]{8,}$/,
                          message: "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                        }
                      })}
                      onChange={e => setPassword(e.target.value)}
                    />
                    <div
                      className="password-icon-holder"
                      onClick={togglePasswordVisibility}
                    >
                      <FontAwesomeIcon
                        className="password-icon"
                        icon={showPassword ? faEye : faEyeSlash}
                      />
                    </div>
                  </div>
                </Form.Group>
                {errors?.password && (
                  <span className="text text-danger">
                    {errors.password.message}
                  </span>
                )}
                {/* {errors && (
                  <span className="text text-danger">{errors}</span>
                )} */}
              </div>

              <div className="col-md-6">
                <Form.Group controlId="Password">
                  <Form.Label className="">Confirm Password </Form.Label>
                  <div className="password-input-container">
                    <Form.Control
                      type={showConfirmPassword ? "text" : "password"}
                      name="Password"
                      placeholder="Enter Confirm Password"
                      className={classNames("", {
                        "is-invalid": errors?.confirm_password,
                      })}
                      {...register("confirm_password", {
                        required: "Password is required",
                        pattern: {
                          value: /^(?!.*\s).*$/, // Regex to disallow spaces
                          message: "Password cannot contain spaces",
                        },
                      })}
                      onChange={e => setPasswordAgain(e.target.value)}
                    />
                    <div
                      className="password-icon-holder"
                      onClick={toggleConfirmPasswordVisibility}
                    >
                      <FontAwesomeIcon
                        className="password-icon"
                        icon={showConfirmPassword ? faEye : faEyeSlash}
                      />
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>
            <PasswordChecklist
              rules={["minLength", "specialChar", "number", "capital", "match"]}
              minLength={8}
              value={password}
              valueAgain={passwordAgain}
              onChange={(isValid) => { }}
            />
          </div>

          {/* Mobile Number  and Resume */}
          <div className="country-coder">
            <div className="row mt-2">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <Form.Group controlId="Mobile_Number">
                      <Form.Label className="required">
                        Mobile Number
                      </Form.Label>
                      <div className="get-otp-text">
                        <Controller
                          name="contact_no"
                          control={control}
                          render={({ field }) => (
                            <PhoneInput
                              country={"in"}
                              name="contact_no"
                              value={field.value}
                              onChange={(value) => handlePhoneNumberChange(value)}
                              placeholder="Enter Mobile number"
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderColor: errors.contact_no
                                    ? "red"
                                    : baseStyles.borderColor,
                                }),
                              }}
                              disabled={mobileVerify}
                              maxLength={12}
                            />
                          )}
                        />
                        {!otpSent && (
                          <p onClick={handleGetOTP}>Get OTP</p>
                        )}
                      </div>
                    </Form.Group>
                    {errors?.contact_no && (
                      <span className="text text-danger">
                        {errors.contact_no.message}
                      </span>
                    )}
                    <p className="otp text text-danger" style={{ fontSize: '11px' }}>
                      {contactError}
                    </p>
                  </div>

                  {/* get-otp form-field  */}
                  {showOtpInput && (
                    <div className="col-md-12">
                      <div className="get-otp form-field">
                        <div className="otp-container">
                          {/* Input fields for OTP */}
                          {otp.map((digit, index) => (
                            <input
                              className="opt-input"
                              key={index}
                              id={`otpInput-${index}`}
                              type="text"
                              maxLength="1"
                              value={digit}
                              onChange={(e) => handleOtpChange(index, e.target.value)}
                            />
                          ))}
                          <button type="button" className="verifyotp-btnn" onClick={() => verifyOTP(otp.join(""))} disabled={verifyStat || isTimerExpired}>Verify</button>
                        </div>

                        {/* {!showOtpInput && (
                          <p>
                            If you don't receive code,{" "}
                            <span onClick={handleResendCode}>click here</span>
                          </p>
                        )} */}

                        {showOtpInput && (
                          <p>Enter OTP within{" "}
                            {`${Math.floor(seconds / 60)}:${(seconds % 60)
                              .toString()
                              .padStart(2, "0")}`}{" "}
                            seconds</p>
                        )}
                        <p className="otp text text-danger">
                          {otpErr}
                        </p>
                        {/* <p>
                          Didn't get the OTP? <span onClick={handleGetOTP} style={{ cursor: 'pointer' }}> Click to Resend.</span>
                        </p> */}
                        {seconds === 0 && (
                          <p>
                            Resend OTP ? <span onClick={handleGetOTP} style={{ cursor: 'pointer' }}>Click to Resend</span>
                          </p>
                        )}
                        {seconds < 0 && (
                          <p>
                            <>Resend OTP <span onClick={handleGetOTP} style={{ cursor: 'pointer' }}>Click to Resend</span></>
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* Designation */}
              <div className="col-md-6">
                <Form.Group controlId="Resume">
                  <Form.Label className="">Resume<span>(DOC, DOCx, PDF, RTF | Max: 2 MB)</span></Form.Label>
                  <Form.Control
                    type="file"
                    name="Resume"
                    accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    className={classNames("", {
                      "is-invalid": errors?.resume,
                    })}
                    {...register("resume", {
                      required: "Resume is required",
                    })}
                  // onChange={handleFileChange}
                  />
                </Form.Group>
              </div>
            </div>
          </div>

          {/* Your Job Profile */}
          <div>
            <div className="row">
              <div className="col-md-12">
                <Form.Group controlId="Job_Profile">
                  <Form.Label className="required">Your Job Profile</Form.Label>
                  <Form.Control
                    type="text"
                    name="Job_Profile"
                    placeholder="Enter Your Job Profile"
                    {...register("job_profile", {
                      required: "Job Profile is required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.job_profile,
                    })}
                    onKeyDown={(event) => {
                      if (!/[A-Z-a-z ]/.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                </Form.Group>
              </div>
            </div>
          </div>


          {userError && (
            <span className="text text-danger">{userError}</span>
          )}

          {/* get-latest-update on watsapp */}
          <div className="form-field">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="get-latest-update">
                  <div>
                    <Form.Group controlId="">
                      <Form.Check
                        type="switch"
                        label=""
                        name=""
                        checked={isWhatsAppChecked}
                        onChange={() =>
                          setIsWhatsAppChecked(!isWhatsAppChecked)
                        }
                      />
                    </Form.Group>
                  </div>

                  <div>
                    <span>Get latest updates on</span>
                    <img
                      className="watsapp-icon"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/Job-portal/Images/register/whatsapp.png"
                      }
                    />

                    <span
                      className={
                        isWhatsAppChecked
                          ? "whatsapp-text active"
                          : "whatsapp-text"
                      }
                    >
                      WhatsApp
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Terms and Conditions */}
          <div className="form-field">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="terms_condition_checkbox">
                  <div>
                    <Form.Group controlId="">
                      <Form.Check
                        type="checkbox"
                        label="By signing up, I agree to the"
                        name=""
                        className={classNames("", {
                          "is-invalid": errors?.term,
                        })}
                        {...register("term", {
                          required: "Plsease select term",
                        })}
                        onClick={() => setIsChecked(true)}
                      />
                    </Form.Group>
                  </div>
                  {/* <Link to="/job-portal/jobseeker/terms&condition"> */}
                  <span>Terms and Conditions</span>
                  {/* </Link> */}
                </div>
                {errors?.term && (
                  <span className="text text-danger">
                    {errors.term.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          {/* Register  */}
          <div className="form-field ">
            <div className="apply-btn-holder ">
              {/* <Link to={"/job-portal/sign-in"}> */}
              <Button className="apply_now_btn" type="submit">Register </Button>
              {/* </Link> */}
            </div>
          </div>
        </Form>
        {/* Already an User?  */}
        <div className="form-field">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <div className="already-an-user">
                <div>
                  <p>Already an User?</p>
                </div>
                <Link to={"/job-portal/sign-in"}>
                  <span>Sign In</span>
                </Link>
              </div>
            </div>
          </div>
        </div>

        {/* Not a Jobseeker? */}
        <div className="form-field">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="already-an-user">
                <div>
                  <p>Not a Jobseeker?</p>
                </div>
                <span onClick={toggleEmployerRegister}>
                  Register as Employer
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JPJobSeekerRegister;
