import React, { useState, useEffect, useContext } from "react";
import "./VerifyEmail.css";
import { Button, Form } from "react-bootstrap";
import { Context } from "../../../../../utils/context";

function VerifyEmail({ onOtpChange, email, otpErr, setOtpErr }) {
    const { postData, IMG_URL } = useContext(Context);
    const [otp, setOtp] = useState("");
    const [timer, setTimer] = useState(60);
    const [isResendEnabled, setIsResendEnabled] = useState(false);

    // Function to handle OTP input change
    const handleOtpInputChange = (e) => {
        const { value } = e.target;
        setOtp(value);
        onOtpChange(value);
    };

    useEffect(() => {
        if (timer > 0) {
            const interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            return () => clearInterval(interval);
        } else {
            deleteOtp();
            setIsResendEnabled(true);
        }
    }, [timer]);

    const deleteOtp = async () => {
        if (email) {
            const res = await postData(`/without-login/verify-otp/delete-email-otp`, { email });
            if (res?.success) {
                console.log("OTP DELETED", res?.data);
            } else {
                console.error("Failed to delete OTP:", res.message);
            }
        } else {
            console.error("Valid Email is required");
        }
    };

    const handleGetOTP = async () => {
        if (email) {
            const res = await postData(`/without-login/verify-otp/email-otp-send`, { email });
            if (res?.success) {
                console.log(res, "otp");
                setIsResendEnabled(false);
                setTimer(60);
                setOtp("");
                setOtpErr("");
            } else {
                console.error("Failed to send OTP:", res.message);
            }
        } else {
            console.error("Valid Email is required");
        }
    };

    return (
        <div className="VerifyMobileNumber-content">
            <div className="heading-holder">
                <h6>Verify Email</h6>
                <p>We have sent the OTP to {email}</p>
            </div>
            <div className="otp-input-holder">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <Form className="otp-input">
                            <Form.Group className="mb-3">
                                <Form.Control
                                    maxLength={4}
                                    placeholder="●●●●"
                                    value={otp}
                                    onChange={handleOtpInputChange}
                                />
                            </Form.Group>
                        </Form>
                    </div>
                    {otpErr && <span className="text-danger">{otpErr}</span>}
                </div>
                {isResendEnabled ? (
                    <p className="submit-btn" onClick={() => handleGetOTP()}>
                        Resend OTP
                    </p>
                ) : (
                    <span>Resend OTP in {timer} seconds</span>
                )}
            </div>

        </div>
    );
}

export default VerifyEmail;
