import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ResetModal from "../ResetModal/ResetModal";
import Form from "react-bootstrap/Form";
import classNames from "classnames";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../../../../utils/context";
import { getBlog, allState, allCourse, selectedCity, postRequestCallBack } from "../../../../../utils/apis/common/Common.js";
import Select from "react-select";
// onClick={openModalWithTimeout}

const BlogModal = (props) => {
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const [modalShow, setModalShow] = useState(false);
  const openModalWithTimeout = () => {
    setModalShow(true);
    setTimeout(() => {
      window.location.href = "/job-portal/blog";
    }, 3000);
  };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset, watch,
    trigger,
    setValue
  } = useForm();
  const { getData, IMG_URL, Select2Data } = useContext(Context);

  const [blogs, setBlog] = useState();

  const [state, setState] = useState();

  const [course, setCourse] = useState();

  const [chooseSelectedCity, setSelectedCity] = useState();

  const { id } = useParams();


  const getproject = async () => {
    const res = await getBlog();
    if (res?.success) {
      setBlog(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const getState = async () => {
    const res = await allState();
    if (res?.success) {
      setState(await Select2Data(res.data, "state_id"))
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }

  const getCourse = async () => {
    const res = await allCourse();
    if (res?.success) {
      setCourse(await Select2Data(res.data, "course_id"))
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  };
  useEffect(() => {
    getproject();
    getState();
    getCourse();
    chooseCity();
  }, []);

  function isToday(someDate) {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  }
  const chooseCity = async (id) => {
    const res = await selectedCity(id);
    if (res?.success) {
      setSelectedCity(await Select2Data(res.data, "city_id"))
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }
  const onSubmit = async (data) => {
    const res = await postRequestCallBack(data)
    if (res?.success) {
      openModalWithTimeout(true);
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }
  return (
    <div>
      <Modal
        {...props}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="modal-fullscreen"
      >
        <Modal.Body>
          <section className="jpblog-innerpage">
            <div className="container">
              <div className="row">
                <Link to="/job-portal/blog">
                  <div className="BacktoCompanies">
                    <p className="" onClick={props.onHide}>
                      Back to Blog
                    </p>
                  </div>
                </Link>
                <div className=" col-lg-7">
                  <div className="image-holder mb-4">
                    <img
                      className="bannn-img1"
                      src={IMG_URL + props.data?.image}
                    />
                  </div>
                  <div className="text-holder mb-3">
                    <h6>
                      {props.data?.title}
                    </h6>
                    <p>Uploaded:{" "}
                      {isToday(new Date(props.data?.createdAt))
                        ? "Today"
                        : new Date(props.data?.createdAt).toLocaleDateString(
                          "en-GB",
                          {
                            day: "2-digit",
                            month: "short",
                            year: "2-digit",
                          }
                        )}</p>
                  </div>
                </div>
                <div className=" col-lg-5">
                  {blogs?.map((data, index) => (
                    <div key={index} className="row">
                      {index !== 0 ? (
                        <>
                          <div className="col-md-5 col-sm-5">
                            <div className="image-holder mb-3" onClick={() => {
                              props.setData(data);
                            }}>
                              <img
                                className="update-img"
                                src={IMG_URL + data?.image}
                              />
                            </div>
                          </div>
                          <div className="col-md-7 col-sm-7">
                            <div className="update-content mb-3">
                              <h5>{data?.title}</h5>
                              <p>
                                Uploaded:{" "}
                                {isToday(new Date(data?.createdAt))
                                  ? "Today"
                                  : new Date(
                                    data?.createdAt
                                  ).toLocaleDateString("en-GB", {
                                    day: "2-digit",
                                    month: "short",
                                    year: "2-digit",
                                  })}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  ))}
                </div>
                <div className="col-lg-7 ">
                  <div className="paragraph-holder mt-4">

                    {htmlToReactParser.parse(props.data?.description)}

                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="requst-form mt-4">
                    <div className="boder-line"></div>
                    <div className="heading-holder text-center">
                      <h4>Request a Call Back</h4>
                    </div>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Form.Group className="mb-3" controlId="formGroupEmail">
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Enter Name"
                          {...register("name", {
                            required: "Name is required",
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.name,
                          })}
                          onKeyDown={(event) => {
                            if (!/[A-Z-a-z ]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="formGroupPassword"
                      >
                        <Form.Control
                          type="text"
                          name="email"
                          placeholder="E-mail Address"
                          {...register("email", {
                            required: "Email Id required",
                            pattern: {
                              value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/,
                              message: "Email Id Invalid",
                            },
                          })}
                          className={classNames("", {
                            "is-invalid": errors?.email,
                          })}
                        />
                      </Form.Group>
                      <div className="row">
                        <div className="col-md-6">
                          <Form.Group
                            className="mb-3"
                            controlId="formGroupnumber"
                          >
                            <Form.Control
                              type="text"
                              name="phone"
                              placeholder="Phone Number"
                              {...register("phone", {
                                required: "Phone is required",
                                maxLength: 10
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.phone,
                              })}
                              onKeyDown={(event) => {
                                if (!/[0-9 ]/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          </Form.Group>
                        </div>
                        <div className="col-md-6">
                          <Controller
                            name="course_id"
                            control={control}
                            {...register("course_id", {
                              required: "Course is required",
                            })}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={course}
                                value={course?.find(option => option.value === field.value)}
                                onChange={(selectedOption) => field.onChange(selectedOption.value)}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.course_id ? "red" : baseStyles.borderColor,
                                  }),
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <Controller
                            name="state_id"
                            control={control}
                            {...register("state_id", {
                              required: "State is required",
                            })}
                            render={({ field }) => (
                              <Select
                                {...field}
                                onChange={(selectedOption) => {
                                  field.onChange(selectedOption.value);
                                  chooseCity(selectedOption.value);
                                }}
                                options={state}
                                value={state?.find(option => option.value === field.value)}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.state_id ? "red" : baseStyles.borderColor,
                                  }),
                                }}
                              />
                            )}
                          />
                          {/* <Form.Select aria-label="Default select example" id="state_id" onChange={(e) => chooseCity(e.target.value)} {...register("state_id", { required: "State is required" })}>
                            <option value="">State</option>
                            {state?.map((data) => (
                              <option key={data?.id} value={data?.id}>{data?.name}</option>
                            ))}
                          </Form.Select> */}
                        </div>
                        <div className="col-md-6">
                          <Controller
                            name="city_id"
                            control={control}
                            {...register("city_id", {
                              required: "City is required",
                            })}
                            render={({ field }) => (
                              <Select
                                {...field}
                                options={chooseSelectedCity}
                                value={chooseSelectedCity?.find(option => option.value === field.value)}
                                onChange={(selectedOption) => field.onChange(selectedOption.value)}
                                styles={{
                                  control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: errors.city_id ? "red" : baseStyles.borderColor,
                                  }),
                                }}
                              />
                            )}
                          />
                        </div>
                      </div>
                      <div className="button-holder text-center mt-5">
                        <Button variant="primary" type="submit">
                          Submit
                        </Button>
                      </div>
                    </Form>

                    <ResetModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                      text={"Your Request has been Sent Successfully!"}
                    />
                    {/* </Form> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BlogModal;
