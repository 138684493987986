import React, { useState, useEffect } from 'react';
import "./ApplyLoan.css";
import { Col, Container, Row } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import LoanModal from './Loan-modal/LoanModal';
import AOS from "aos";
import "aos/dist/aos.css";


const ApplyLoan = () => {
  const [modalShow, setModalShow] = useState(false);
  useEffect(() => {
    AOS.init({ once: true });
  }, [])
  return (
    <>
      <section className="Apply-Loan">
        <div data-aos="fade-up"
          data-aos-duration="3000">
          <Container>
            <Row className="justify-content-center">
              <Col xxl={7} xl={7} lg={7} md={11}>
                <div className="apply-loan-background">
                  <Row>
                    <Col xxl={5} xl={5} lg={5} md={5}>
                      <div className="image-loan">
                        <img
                          className="loan"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/Finance/Image/HomeLoan/LoanTestimonial/loan.png"
                          }
                          alt="Banner"
                        />
                      </div>
                    </Col>
                    <Col xxl={7} xl={7} lg={7} md={7}>
                      <div className="apply-content">
                        <h1 className="fianace-titleeee apply-titleee">Apply for Loan</h1>
                        <div className="apply-btn">
                          <Button variant="apply" onClick={() => setModalShow(true)}>Apply Now</Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <LoanModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default ApplyLoan;
