
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../SixthTab.css'
import classNames from "classnames";
const FinanceLoanStep1 = ({ register, errors }) => {
    return (
        <>
            <section className='step1'>
                <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                    <Form.Label>PAN Card</Form.Label>
                    <Form.Control type="text"
                        name="pan"
                        placeholder="As per your bank records"
                        maxLength={10}
                        {...register("pan", {
                            required: "PAN is required",
                            pattern: {
                                value: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
                                message: "Invalid E-Mail Address",
                            },

                        })}
                        className={classNames("", {
                            "is-invalid": errors?.pan,
                        })}
                        onChange={(e) => {
                            e.target.value = e.target.value.toUpperCase();
                        }}

                    />
                    {errors.pan && (
                        <span className="text-danger">
                            {errors.pan.message}
                        </span>
                    )}
                    {/* <Form.Control type="text" placeholder="Enter your PAN card number" /> */}
                </Form.Group>
                <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                        className={classNames("", {
                            "is-invalid": errors?.name,
                        })}
                        type="text"
                        {...register("name", {
                            required: "Title is required",
                            pattern: {
                                value: /^[A-Za-z ]*$/,
                                message: "Title must contain only letters and spaces",
                            },
                        })}
                        placeholder="Title"
                        onChange={(e) => {
                            const newValue = e.target.value.replace(/\d/g, ''); // Remove any digits
                            e.target.value = newValue;
                        }}
                    />

                    {/* <Form.Control type="text" placeholder="Enter your name" /> */}
                </Form.Group>
                {errors.name && (
                    <span className="text-danger">
                        {errors.name.message}
                    </span>
                )}
                <Form.Group className="mb-4" controlId="exampleForm.ControlInput1">
                    <Form.Label>Mobile as per Aadhaar</Form.Label>
                    <Form.Control
                        className={classNames("", {
                            "is-invalid": errors?.contact_no,
                        })}
                        type="text"
                        inputMode="numeric"
                        maxLength={10}

                        // onChange={(e) => handleCount(e)}
                        {...register("contact_no", {
                            required: "Contact No is required",
                            pattern: {
                                value: /^\d{10}$/, // Enforce 10-digit format using regular expression
                                message: "Please enter a 10-digit number",
                            },
                        })}
                        onChange={(e) => {
                            const input = e.target.value;
                            const numericInput = input.replace(/\D/g, ''); // Remove any non-numeric characters

                            // Set the input field value to the sanitized numeric input
                            e.target.value = numericInput;
                        }}
                        placeholder="Contact No"
                    />

                </Form.Group>
                {errors.contact_no && (
                    <span className="text-danger">
                        {errors.contact_no.message}
                    </span>
                )}
                <Form.Group className="mb-3 d-flex">
                    <Form.Check
                        required  // Make the checkbox required
                        name="terms"
                        {...register("terms", { required: true })} // Register the checkbox for validation
                        isInvalid={errors.terms} // Apply validation styling based on whether there's an error
                    />
                    <p className='ms-2 term-text'>By clicking here, I acknowledge that I have reviewed and understood the <Link>terms and conditions</Link> and <Link to="/finance/privacy">privacy policy</Link></p>
                </Form.Group>

            </section>

        </>
    )
}

export default FinanceLoanStep1