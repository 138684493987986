import { React, useRef, useEffect, useState, useContext } from "react";
import { Context } from "../../../../utils/context";
import "./LoanTestimonial.css";
import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Pagination } from "swiper/modules";
import parse from "html-react-parser";
import AOS from "aos";
import "aos/dist/aos.css";

const renderStars = (rating) => {
  const stars = [];
  const numericRating = Number(rating); // Ensure the rating is a number
  console.log(numericRating, "numericRating");
  for (let i = 1; i <= numericRating; i++) {
    stars.push(
      <FontAwesomeIcon
        key={i}
        icon="fa-solid fa-star"
      // className={i <= numericRating ? 'filled' : 'unfilled'} 
      />
    );
  }
  return stars;
};
const LoanTestimonial = ({ loan_form_id }) => {
  const { getData, IMG_URL } = useContext(Context);
  const [data, setData] = useState([]);

  const getDataAll = async () => {
    try {
      const response = await getData(`/finance/loan/f-clients-reviews/${loan_form_id}`);
      setData(response?.data);

    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  console.log(data, "dsdsdsdsd");

  useEffect(() => {
    getDataAll();
  }, [loan_form_id]);
  const TestimonialDetails = [
    {
      textcontent:
        "Securing my dream home was a breeze with Profcyma Finance Home Loans! Their team's expertise and seamless process made my home-buying journey stress-free. Highly recommended!",
      name: "Shubham Kumar",
    },
    {
      textcontent:
        "Securing my dream home was a breeze with Profcyma Finance Home Loans! Their team's expertise and seamless process made my home-buying journey stress-free. Highly recommended!",
      name: "Shubham Kumar2",
    },
  ];
  useEffect(() => {
    AOS.init({ once: true });
  }, [])
  return (
    <>
      {data && data?.length > 0 &&
        <section className="Loan-Testimonial">
          <div data-aos="fade-up"
            data-aos-duration="3000">
            <p className="main text">Testimonial</p>
            <h1 className="fianace-titleeee">Review from our happy clients</h1>
            <Container fluid>
              <div className="Background-Testimonial">
                <Container>
                  <Row>
                    <Col xxl={4} xl={4} lg={4} md={12}>
                      <div className="Circle">
                        <div className="background-circle">
                          <div className="Member-image">
                            <img
                              className="Member"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/Finance/Image/HomeLoan/LoanTestimonial/college.png"
                              }
                              alt="Banner"
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col xxl={8} xl={8} lg={8} md={12}>
                      <div className="main-swiper">
                        <Swiper
                          pagination={{
                            clickable: true,
                          }}
                          modules={[Pagination]}
                          className="mySwiper"
                        >
                          {data?.map((item, index) => (
                            <SwiperSlide key={index}>
                              <div className="reviews">
                                {/* <FontAwesomeIcon icon="fa-solid fa-star" />
                            <FontAwesomeIcon icon="fa-solid fa-star" />
                            <FontAwesomeIcon icon="fa-solid fa-star" />
                            <FontAwesomeIcon icon="fa-solid fa-star" />
                            <FontAwesomeIcon icon="fa-solid fa-star" /> */}
                                {renderStars(item?.rating)}
                              </div>

                              <p className="text">{parse(item?.description)}</p>
                              <div className="d-flex mb-5">
                                <h4 className="name">{item?.name}</h4>
                                <div className="quient">
                                  <img
                                    className="quient"
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/Finance/Image/HomeLoan/LoanTestimonial/quetient.png"
                                    }
                                    alt="Banner"
                                  />
                                </div>
                              </div>
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Container>
          </div>
        </section>
      }
    </>
  );
};

export default LoanTestimonial;
