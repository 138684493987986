import React, { useRef, useState, useContext } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import './JpSearch.css'
import { Link } from 'react-router-dom';
import { useForm, Controller, SubmitHandler, useFieldArray } from "react-hook-form";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";


const JpEmpSearch = (props) => {
    const [show, setShow] = useState(false);
    const {
        control,
        register,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm();

    const navigate = useNavigate();

    const onSubmit = async (data) => {
        const job_title = data.job_title;
        const date = data.date;
        // Construct query parameters
        const queryParams = new URLSearchParams({
            job_title: job_title,
            date: date,
        }).toString();
        
        navigate(`/job-portal/employer/posted-job/?${queryParams}`);
        props.onHide();
    }




    return (
        <>
            <section className='search-emp-modal'>
                <Modal
                    {...props}
                    size="lg"
                    className="search-modal-section form-container"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <div className='heading-div'>
                                <h4>Search My Posted Jobs Here</h4>
                                <div className='cross-icon-div' onClick={props.onHide}>
                                    <FontAwesomeIcon icon="fa-solid fa-xmark" className='cross-icon' />
                                </div>
                            </div>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <Form onSubmit={handleSubmit(onSubmit)}>
                            <div className='form-container search-div'>
                                <div>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Job title</Form.Label>
                                        {/* <Form.Control type="text" placeholder="Enter Job Title " /> */}
                                        <Form.Control
                                            type="text"
                                            name="job_title"
                                            placeholder="Enter Job Title"
                                            {...register("job_title", {
                                                // required: "Job Title is required",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.job_title,
                                            })}
                                            onKeyDown={(event) => {
                                                if (!/[A-Z-a-z ]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </Form.Group>

                                </div>
                                <div>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Label>Date</Form.Label>
                                        {/* <Form.Control type="date" placeholder="Enter Date" /> */}
                                        <Form.Control
                                            type="date"
                                            placeholder="Enter Date Of Joining"
                                            name="date"
                                            {...register("date", {
                                                // required: "Starting Date required",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.time_from,
                                            })}
                                            max={new Date().toISOString().split('T')[0]}
                                        />
                                    </Form.Group>
                                </div>
                            </div>
                            <div className='button-divvvs text-center my-3'>
                                {/* <button className='cancel-btn' onClick={props.onHide}>Close</button> */}
                                {/* <Link to="/job-portal/employer/posted-job"><button onClick={props.onHide} className='save-btn' >Search</button></Link> */}
                                <button type="submit" className='save-btn' >Search</button>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            </section>
        </>
    )
}

export default JpEmpSearch