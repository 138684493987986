import React, { useRef, useState, useContext } from "react";
import '../ed_tech_welcome/ed_tech_welcome.css'

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import Menu from "../../Ed-tech/Animation/home/home-menu/Menu";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Link } from "react-router-dom";

const College_compare_welcome = () => {

    const [show, setShow] = React.useState(false);

    const homemenus = [
        {
            name: "Free Counselling",
            imgSrc: "/assets/images/home-menu/operator.png",
            link: "/comming-soon",
        },
        {
            name: "College Compare",
            imgSrc: "/assets/images/home-menu/exchange-horizontal.png",
            link: "/comming-soon",
        },
        {
            name: "Job Portal",
            imgSrc: "/assets/images/home-menu/suitcase.png",
            // link: "/job-portal/home",
            link: "/comming-soon",
        },
        {
            name: "Finance",
            imgSrc: "/assets/images/home-menu/rupee.png",
            // link: "/finance/home",
            link: "/comming-soon",
        },
        {
            name: "Ed-Tech",
            imgSrc: "/assets/images/home-menu/mortarboard.png",
            link: "/ed-tech/home",
        },
    ];

    const courses = [
        {
            name: "Top Online MBA College",
            whiteicon :"/assets/images/icons/college_compare/top_white.png",
            orangeicon : "/assets/images/icons/college_compare/top.png"
        },
        {
            name: "Online Courses College",
            whiteicon :"/assets/images/icons/college_compare/online_couse_white.png",
            orangeicon : "/assets/images/icons/college_compare/online_couse.png"
        },
        {
            name: "Engineering College",
            whiteicon :"/assets/images/icons/college_compare/eng_col_white.png",
            orangeicon : "/assets/images/icons/college_compare/eng_col.png"
        },
        {
            name: "Top Online MBA College",
            whiteicon :"/assets/images/icons/college_compare/top_white.png",
            orangeicon : "/assets/images/icons/college_compare/top.png"
        },
        {
            name: "Online Courses College",
            whiteicon :"/assets/images/icons/college_compare/online_couse_white.png",
            orangeicon : "/assets/images/icons/college_compare/online_couse.png"
        },
        {
            name: "Engineering College",
            whiteicon :"/assets/images/icons/college_compare/eng_col_white.png",
            orangeicon : "/assets/images/icons/college_compare/eng_col.png"
        },

    ];

    const homebannerbackground = [
        {
            video: "/assets/videos/home/banner/banner1.mp4",
        },
    ];

    return (
        <section className="welcome-banner-section">
            <div className="container-fluid p-0">
                {homebannerbackground?.map((background, index) => (
                    <div className="video-main">
                        <video class="video-bg" loop autoPlay muted>
                            <source src={background.video} type="video/mp4" />
                        </video>
                        <div className="black-overlay"></div>
                    </div>
                ))}

                <div className=" overflow-content">
                    <div className="container">
                        <div className="logo-div">
                            <img
                                className="logoimg"
                                src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/logo/profcyma_logo.png"
                                }
                            />
                        </div>

                        <div className="content-div">
                            <h1 className="heding title">Compare Colleges - Reviews and Rankings</h1>
                            <p className="subtitle">
                            Explore top colleges with us for optimal online career discovery.
                            </p>
                        </div>

                        <div className="search-div">
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Search for Job"
                            />
                            <button className="next-btn btn">
                                <img
                                    className="next-icon"
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/search-next.png"
                                    }
                                />
                            </button>
                            <p className="trending-p">Our Trending Searches</p>

                            <Swiper
                                pagination={{
                                    dynamicBullets: true,
                                }}
                                slidesPerView={3}
                                centeredSlides={false}
                                autoplay={{
                                    delay: 3000,
                                    disableOnInteraction: false,
                                }}
                                navigation={true}
                                modules={[Autoplay, Pagination, Navigation]}
                                breakpoints={{
                                    "0": {
                                        slidesPerView: 3,
                                        spaceBetween: 0,
                                    },
                                    "500": {
                                        slidesPerView: 3,
                                        spaceBetween: 0,
                                    },
                                    "991": {
                                        slidesPerView: 3,
                                        spaceBetween: 0,
                                    },
                                    "1400": {
                                        slidesPerView: 3,
                                        spaceBetween: 0,
                                    },
                                }}
                                className="mySwiper"
                            >
                                {courses?.map((name, index) => (
                                    <SwiperSlide>
                                        <div className="trending-box">
                                            <img
                                                className="grad-icon1"
                                                src={name.whiteicon}
                                            />
                                            <img
                                                className="grad-icon2"
                                                src={name.orangeicon}
                                            />
                                            <p className="course-name">{name.name}</p>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>

                        <div className="web-link-div">
                            <Link className="webb" to="/">
                                www.profcyma.com
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="open-menu-div">
                    <div className="icon-div" onClick={() => setShow(!show)}>
                        <Menu />
                    </div>

                    {show && (
                        <div className="menus-list-div">
                            {homemenus?.map((menu, index) => (
                                <div className="list-div" key={index}>
                                    <div className="icom-div">
                                        <Link className="name" to={menu.link}>
                                            <img className="icon" src={menu.imgSrc} />
                                        </Link>
                                    </div>
                                    <div className="menu-name-div ">
                                        <Link className="name" to={menu.link}>
                                            {" "}
                                            {menu.name}
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </section>
    )
}

export default College_compare_welcome