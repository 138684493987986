import React from 'react'

const Comming_soon = () => {
    return (
        <>
            <div className='comsonn-title'>
                <h1 className='pulsate-bck'>Comming Soon...</h1>
            </div>
        </>
    )
}

export default Comming_soon